export const GET_COMPANY_LIST = "GET_COMPANY_LIST"
export const GET_COMPANY_LIST_FAIL = "GET_COMPANY_LIST_FAIL"
export const GET_COMPANY_LIST_SUCCESS = "GET_COMPANY_LIST_SUCCESS"
export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY"
export const ADD_COMPANY_SUCCESS = "ADD_JCOMPANY_SUCCESS"
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL"
export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL"
export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL"

export const GET_PHONECODE_LIST = "GET_PHONECODE_LIST"
export const GET_PHONECODE_LIST_FAIL = "GET_PHONECODE_LIST_FAIL"
export const GET_PHONECODE_LIST_SUCCESS = "GET_PHONECODE_LIST_SUCCESS"

export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST"
export const GET_COUNTRY_LIST_FAIL = "GET_COUNTRY_LIST_FAIL"
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS"

export const GET_STATE_LIST = "GET_STATE_LIST"
export const GET_STATE_LIST_FAIL = "GET_STATE_LIST_FAIL"
export const GET_STATE_LIST_SUCCESS = "GET_STATE_LIST_SUCCESS"

export const GET_CITY_LIST = "GET_CITY_LIST"
export const GET_CITY_LIST_FAIL = "GET_CITY_LIST_FAIL"
export const GET_CITY_LIST_SUCCESS = "GET_CITY_LIST_SUCCESS"



export const GET_BILLING_STATE_LIST = "GET_BILLING_STATE_LIST"
export const GET_BILLING_STATE_LIST_FAIL = "GET_BILLING_STATE_LIST_FAIL"
export const GET_BILLING_STATE_LIST_SUCCESS = "GET_BILLING_STATE_LIST_SUCCESS"

export const GET_BILLING_CITY_LIST = "GET_BILLING_CITY_LIST"
export const GET_BILLING_CITY_LIST_FAIL = "GET_BILLING_CITY_LIST_FAIL"
export const GET_BILLING_CITY_LIST_SUCCESS = "GET_BILLING_CITY_LIST_SUCCESS"

export const GET_COMPANY_DETAILS = "GET_COMPANY_DETAILS"
export const GET_COMPANY_DETAILS_FAIL = "GET_COMPANY_DETAILS_FAIL"
export const GET_COMPANY_DETAILS_SUCCESS = "GET_COMPANY_DETAILS_SUCCESS"
export const GET_COMPANY_CP_SUCCESS = "GET_COMPANY_CP_SUCCESS"


export const APPROVE_COMPANY = "APPROVE_COMPANY"
export const APPROVE_COMPANY_FAIL = "APPROVE_COMPANY_FAIL"
export const APPROVE_COMPANY_SUCCESS = "APPROVE_COMPANY_SUCCESS"

export const  SET_LOADING_INDICATOR_COMPANY = 'SET_LOADING_INDICATOR_COMPANY'





import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  Badge,
  UncontrolledTooltip,
  FormFeedback,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import classnames from "classnames"
import { Link , useNavigate} from "react-router-dom"
import Select from 'react-select';
import Switch from "react-switch";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  addNewUser as onAddNewUser,
  updateCompany as onUpdateCompany,
  deleteCompany as onDeleteCompany,
  getPhonecodeUserList as onGetPhonecodeUserList,
  getCountryUserList as onGetCountryUserList,
  getStateUserList as onGetStateUserList,
  getCityUserList as onGetCityUserList,
  getCompanyUserList as onGetCompanyUserList,
  getDesignationProfile as onGetDesignationProfile,
  getDepartmentProfile as onGetDepartmentProfile,
  getActiveUsersOrg as onGetActiveUsersOrg
} from "store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import { authUser } from "helpers/dataConfig";
import Loader from "components/Common/Loader";

const AddComplaint = () => {

  //meta title
  document.title = "Add New Complaint | Smarteeva";

  const navigate = useNavigate()

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  const [countryOptions, setCountryOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([])


  const [corporateOptions, setCorporateOptions] = useState([])
  const [designationOptions, setDesignationOptions] = useState([])
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [adminOptions, setAdminOptions] = useState([])

  const [company, setCompany] = useState(null);

  const [countryVal, setCountryVal] = useState(null);
  const [stateVal, setStateVal] = useState(null);
  const [cityVal, setCityVal] = useState(null);
  const [corporateVal, setCorporateVal] = useState(null);
  const [designationVal, setDesignationVal] = useState(null);
  const [departmentVal, setDepartmentVal] = useState(null);
  const [adminVal, setAdminVal] = useState(null);


  const [phoneCodeVal, setPhoneCodeVal] = useState(null);

  const [numRegex, setNumRegex] = useState(/^[0-9]*$/)

  const [isLoading, setIsLoading] = useState(false);


  const [open, setOpen] = useState('1');
  const [open2, setOpen2] = useState('2');
  const [open3, setOpen3] = useState('3');
  const [open4, setOpen4] = useState('4');
  const [open5, setOpen5] = useState('5');
  const [open6, setOpen6] = useState('6');
  const [open7, setOpen7] = useState('7');
  const [open8, setOpen8] = useState('8');
  const [open9, setOpen9] = useState('9');



  const dispatch = useDispatch();
  const { phonecode } = useSelector(state => ({
    phonecode: state.user.phonecode,
  }));

  const { country } = useSelector(state => ({
    country: state.user.country,
  }));

  const { states } = useSelector(state => ({
    states: state.user.states,
  }));

  const { city } = useSelector(state => ({
    city: state.user.city,
  }));


  const { companys } = useSelector(state => ({
    companys: state.user.companys,
  }));

  const { active_org_users } = useSelector(state => ({
    active_org_users: state.user.active_org_users,
  }));

  const { designation } = useSelector(state => ({
    designation: state.Profile.designation,
  }));

  const { department } = useSelector(state => ({
    department: state.Profile.department,
  }));


  const { error, loading } = useSelector(state => ({
    error: state.user.error,
    loading : state.user.loading
  }));

  useEffect(() => {
    setIsLoading(loading)
}, [loading]);

  useEffect(() => {
    // console.log(companys)
    if (phonecode && !phonecode.length) {
        dispatch(onGetPhonecodeUserList());
    }
    if (country && !country.length) {
      dispatch(onGetCountryUserList());
  }

}, [dispatch, phonecode, country]);


useEffect(() => {
  // console.log(companys)
if (companys && !companys.length) {
  dispatch(onGetCompanyUserList());
}

}, [dispatch, companys]);


useEffect(() => {
if (designation && !designation.length) {
  dispatch(onGetDesignationProfile());
}

}, [dispatch, designation]);


useEffect(() => {
  if (department && !department.length) {
    dispatch(onGetDepartmentProfile());
  }
  
  }, [dispatch, department]);

  // useEffect(() => {
  //   if (active_org_users && !active_org_users.length) {
  //     dispatch(onGetActiveUsersOrg());
  //   }
    
  //   }, [dispatch, active_org_users]);

useEffect(() => {
    setPhoneCodeOptions(phonecode);
    setCountryOptions(country)
}, [phonecode, country]);

useEffect(() => {
  setStateOptions(states)
}, [states]);

useEffect(() => {
  setCityOptions(city)
}, [city]);

useEffect(() => {
  // console.log(companys)
  setCorporateOptions(companys)
}, [companys]);

useEffect(() => {
  // console.log(designation)
  setDesignationOptions(designation)
}, [designation]);

useEffect(() => {
  // console.log(designation)
  setDepartmentOptions(department)
}, [department]);

useEffect(() => {
  // console.log(active_org_users)
  setAdminOptions(active_org_users)
}, [active_org_users]);



  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        fname: '',
        lname: '',
        phonecode : '',
        phone : '',
        email:'',
        address:'',
        country:'',
        state:'',
        city:'',
        postalcode:''

    },
    validationSchema: Yup.object({
        // Id: Yup.string().matches(
        //     /[0-9\.\-\s+\/()]+/,
        //     "Please Enter Valid Job Id"
        // ).required("Please Enter Your Job Id"),
        // jobTitle: Yup.string().required("Please Enter Your Job Title"),
        fname: Yup.string().required("Please Enter First Name"),
        lname: Yup.string().required("Please Enter Last Name"),
        phonecode: Yup.string().required("Please Select Phone Code"),
        phone: Yup.string().matches(numRegex, 'Please enter a valid number').required("Please Enter Phone Number"),
        email: Yup.string().email('Please enter a valid email').required("Please Enter Email Address"),
        address: Yup.string().required("Please Enter Address"),
        country: Yup.string().required("Please Select Country"),
        state: Yup.string().required("Please Select State"),
        city: Yup.string().required("Please Select City"),
        postalcode: Yup.string().matches(numRegex, 'Please enter a valid postal code').required("Please Enter Postal Code")
    }),
    onSubmit: (values) => {
      toggleTabVertical(activeTabVartical + 1)
    },
});



 // validation
 const validationCorporate = useFormik({
  // enableReinitialize : use this flag when initial values needs to be changed
  enableReinitialize: true,

  initialValues: {
      companyid : '',
      designation:'',
      department:'',
      employeeid:'',
      adminid:'',
      isadmin:0

  },
  validationSchema: Yup.object({
    companyid: Yup.string().required("Please Select Organisation Name"),
    designation: Yup.string().required("Please Select Designation"),
    department: Yup.string().required("Please Select Department"),
    employeeid: Yup.string().required("Please Enter Employee Id"),
    adminid: Yup.string().required("Please Select Admin Name")
  }),
  onSubmit: (values) => {


    // console.log(values)
    // console.log(validation)
    const userObj = { ...validation.values, ...values};
    dispatch(onAddNewUser(userObj, navigate));

      // if (isEdit) {
      //     const updateJobList = {
      //         id: job ? job.id : 0,
      //         jobId: values.jobId,
      //         jobTitle: values.jobTitle,
      //         companyName: values.companyName,
      //         location: values.location,
      //         experience: values.experience,
      //         position: values.position,
      //         type: values.type,
      //         postedDate: "02 June 2021",
      //         lastDate: "25 June 2021",
      //         status: values.status,
      //     };
      //     // update Job
      //     dispatch(onUpdateJobList(updateJobList));
      //     validation.resetForm();
      // } else {
      //     const newJobList = {
      //         id: Math.floor(Math.random() * (30 - 20)) + 20,
      //         jobId: values["jobId"],
      //         jobTitle: values["jobTitle"],
      //         companyName: values["companyName"],
      //         location: values["location"],
      //         experience: values["experience"],
      //         position: values["position"],
      //         type: values["type"],
      //         postedDate: "02 June 2021",
      //         lastDate: "25 June 2021",
      //         status: values["status"],
      //     };
      //     // save new Job
      //     dispatch(onAddNewJobList(newJobList));
      //     validation.resetForm();
      // }
      // toggle();
  },
});

const handleKeyDown = (e) => {
  // console.log(e.key)
  if (e.key.match(/[a-z]/i) && e.key!= 'Backspace') {
    e.preventDefault();
  }
};


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Manage Complaints" breadcrumbItem="Create New Complaint" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New User Profile</CardTitle>
                  {/* <p className="text-muted mb-5">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultricies vel enim in malesuada. Nulla porta tempor placerat. Aenean vitae hendrerit lacus, vitae sollicitudin ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam tristique et mauris nec iaculis.
                  </p> */}
                  <div className="horizontal-wizard wizard clearfix horizontal">
                    <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 1,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 1,
                            })}
                            onClick={() => {
                              toggleTabVertical(1)
                            }}
                            disabled={!(passedStepsVertical || []).includes(1)}
                          >
                            <span className="number">1</span> Information
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 2,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 2,
                            })}
                            onClick={() => {
                              toggleTabVertical(2)
                            }}
                            disabled={!(passedStepsVertical || []).includes(2)}
                          >
                            <span className="number">2</span>{" "}
                            <span>Products & Symptoms</span>
                          </NavLink>

                         
                        </NavItem>

                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 3,
                          })}
                        >
                        <NavLink
                            className={classnames({
                              active: activeTabVartical === 3,
                            })}
                            onClick={() => {
                              toggleTabVertical(3)
                            }}
                            disabled={!(passedStepsVertical || []).includes(3)}
                          >
                            <span className="number">3</span>{" "}
                            <span>Additional Info</span>
                          </NavLink>
                          </NavItem>
                    
                      </ul>
                      </div>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>

<Accordion open={open} toggle={(id)=>{
  if (open === id) {
    setOpen();
  } else {
    setOpen(id);
  }
}} className="mb-3 accordian-complaints">
        <AccordionItem>
          <AccordionHeader targetId="1">General Information</AccordionHeader>
          <AccordionBody accordionId="1">

                                                         <Row>
                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Parent Case</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'phonecode'}
                                //   onChange={(e)=>{
                                //     setPhoneCodeVal(e)
                                //     validation.setFieldValue('phonecode', e.value)
                                //     validation.setFieldError('phonecode',false)
                                //   }}
                                //   onBlur={validation.handleBlur}
                                //   options={phoneCodeOptions}
                                //   value={
                                //     phoneCodeVal || ""
                                // }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Category</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'phonecode'}
                                //   onChange={(e)=>{
                                //     setPhoneCodeVal(e)
                                //     validation.setFieldValue('phonecode', e.value)
                                //     validation.setFieldError('phonecode',false)
                                //   }}
                                //   onBlur={validation.handleBlur}
                                //   options={phoneCodeOptions}
                                //   value={
                                //     phoneCodeVal || ""
                                // }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                    Complaint Title
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Complaint Title"
                                    name="lname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lname || ""}
                                    invalid={
                                      validation.touched.lname && validation.errors.lname ? true : false
                                    }
                                  />
                                  {validation.touched.lname && validation.errors.lname ? (
                                    <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Translation Completed
                                    </Label>

                                    <div className="square-switch"    style={{marginTop : 10}}>
                            <input
                               type="checkbox"
                              //  id="square-switch1"
                               name="isadmin"
                               className="switch switch-bool"
                           
                               onKeyDown={handleKeyDown}
                               onChange={()=>{
                                // console.log('clicked')
                                 if(validationCorporate.values.isadmin == 1){
                                  validationCorporate.setFieldValue('isadmin', 0)
                                 }else{
                                  validationCorporate.setFieldValue('isadmin', 1)
             
                                 }
                               }}
                               onBlur={validationCorporate.handleBlur}
                              //  value={validationCorporate.values.isadmin || ""}
                              checked={validationCorporate.values.isadmin == 0 ? false : true}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label="Yes"
                              data-off-label="No"
                              onClick={()=>{
                                // console.log('clicked')
                                 if(validationCorporate.values.isadmin == 1){
                                  validationCorporate.setFieldValue('isadmin', 0)
                                 }else{
                                  validationCorporate.setFieldValue('isadmin', 1)
             
                                 }
                               }}
                            />
                          </div>
                                   
                                  </FormGroup>
                                </Col>

                                <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Complaint Risk</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'phonecode'}
                                //   onChange={(e)=>{
                                //     setPhoneCodeVal(e)
                                //     validation.setFieldValue('phonecode', e.value)
                                //     validation.setFieldError('phonecode',false)
                                //   }}
                                //   onBlur={validation.handleBlur}
                                //   options={phoneCodeOptions}
                                //   value={
                                //     phoneCodeVal || ""
                                // }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                    No of Days
                                  </Label>
                                  <Input
                                    disabled
                                    type="text"
                                    className="form-control"
                                    placeholder="This field is calculated upon save"
                                    name="lname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lname || ""}
                                    invalid={
                                      validation.touched.lname && validation.errors.lname ? true : false
                                    }
                                  />
                                  {validation.touched.lname && validation.errors.lname ? (
                                    <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                    Score
                                  </Label>
                                  <Input
                                  disabled
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="lname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lname || ""}
                                    invalid={
                                      validation.touched.lname && validation.errors.lname ? true : false
                                    }
                                  />
                                  {validation.touched.lname && validation.errors.lname ? (
                                    <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>


                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                    Decision
                                  </Label>
                                  <Input
                                  // disabled
                                    type="textarea"
                                    className="form-control"
                                    placeholder=""
                                    name="lname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lname || ""}
                                    invalid={
                                      validation.touched.lname && validation.errors.lname ? true : false
                                    }
                                  />
                                  {validation.touched.lname && validation.errors.lname ? (
                                    <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                    Root Cause Code
                                  </Label>
                                  <Input
                                  // disabled
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Root Cause Code"
                                    name="lname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lname || ""}
                                    invalid={
                                      validation.touched.lname && validation.errors.lname ? true : false
                                    }
                                  />
                                  {validation.touched.lname && validation.errors.lname ? (
                                    <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>


                              <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Reportable
                                    </Label>

                                    <div className="square-switch"    style={{marginTop : 10}}>
                            <input
                               type="checkbox"
                              //  id="square-switch1"
                               name="isadmin"
                               className="switch switch-bool"
                           
                               onKeyDown={handleKeyDown}
                               onChange={()=>{
                                // console.log('clicked')
                                 if(validationCorporate.values.isadmin == 1){
                                  validationCorporate.setFieldValue('isadmin', 0)
                                 }else{
                                  validationCorporate.setFieldValue('isadmin', 1)
             
                                 }
                               }}
                               onBlur={validationCorporate.handleBlur}
                              //  value={validationCorporate.values.isadmin || ""}
                              checked={validationCorporate.values.isadmin == 0 ? false : true}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label="Yes"
                              data-off-label="No"
                              onClick={()=>{
                                // console.log('clicked')
                                 if(validationCorporate.values.isadmin == 1){
                                  validationCorporate.setFieldValue('isadmin', 0)
                                 }else{
                                  validationCorporate.setFieldValue('isadmin', 1)
             
                                 }
                               }}
                            />
                          </div>
                                   
                                  </FormGroup>
                                </Col>


                                <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Process Action</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'phonecode'}
                                //   onChange={(e)=>{
                                //     setPhoneCodeVal(e)
                                //     validation.setFieldValue('phonecode', e.value)
                                //     validation.setFieldError('phonecode',false)
                                //   }}
                                //   onBlur={validation.handleBlur}
                                //   options={phoneCodeOptions}
                                //   value={
                                //     phoneCodeVal || ""
                                // }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>


                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Death/Serious Injury
                                    </Label>

                                    <div className="square-switch"    style={{marginTop : 10}}>
                            <input
                               type="checkbox"
                              //  id="square-switch1"
                               name="isadmin"
                               className="switch switch-bool"
                           
                               onKeyDown={handleKeyDown}
                               onChange={()=>{
                                // console.log('clicked')
                                 if(validationCorporate.values.isadmin == 1){
                                  validationCorporate.setFieldValue('isadmin', 0)
                                 }else{
                                  validationCorporate.setFieldValue('isadmin', 1)
             
                                 }
                               }}
                               onBlur={validationCorporate.handleBlur}
                              //  value={validationCorporate.values.isadmin || ""}
                              checked={validationCorporate.values.isadmin == 0 ? false : true}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label="Yes"
                              data-off-label="No"
                              onClick={()=>{
                                // console.log('clicked')
                                 if(validationCorporate.values.isadmin == 1){
                                  validationCorporate.setFieldValue('isadmin', 0)
                                 }else{
                                  validationCorporate.setFieldValue('isadmin', 1)
             
                                 }
                               }}
                            />
                          </div>
                                   
                                  </FormGroup>
                                </Col>

                            </Row>

                                                        </AccordionBody>
                                                        </AccordionItem>
                                                        </Accordion>
                          
                           
                                                        <Accordion open={open2} toggle={(id)=>{
  if (open2 === id) {
    setOpen2();
  } else {
    setOpen2(id);
  }
}} className="mb-3 accordian-complaints">
        <AccordionItem>
          <AccordionHeader targetId="2">Description</AccordionHeader>
          <AccordionBody accordionId="2">
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label  className="required">
                                  Initial Actions
                                  </Label>
                                  <Input
                                    type="textarea"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Initial Actions"
                                    name="address"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.address || ""}
                                    invalid={
                                      validation.touched.address && validation.errors.address ? true : false
                                    }
                                  />
                                  {validation.touched.address && validation.errors.address ? (
                                    <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label  className="required">
                                  Complaint Description
                                  </Label>
                                  <Input
                                    type="textarea"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Complaint Description"
                                    name="address"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.address || ""}
                                    invalid={
                                      validation.touched.address && validation.errors.address ? true : false
                                    }
                                  />
                                  {validation.touched.address && validation.errors.address ? (
                                    <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label  className="required">
                                  Translated Text
                                  </Label>
                                  <Input
                                    type="textarea"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Translated Text"
                                    name="address"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.address || ""}
                                    invalid={
                                      validation.touched.address && validation.errors.address ? true : false
                                    }
                                  />
                                  {validation.touched.address && validation.errors.address ? (
                                    <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label  className="required">
                                  Risk Assessment
                                  </Label>
                                  <Input
                                    type="textarea"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Risk Assessment"
                                    name="address"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.address || ""}
                                    invalid={
                                      validation.touched.address && validation.errors.address ? true : false
                                    }
                                  />
                                  {validation.touched.address && validation.errors.address ? (
                                    <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                           </AccordionBody>
                           </AccordionItem>
                           </Accordion>
                          
                          </Form>
                        </TabPane>

                      

                        <TabPane tabId={2}>
                          <div>
                            <Form>

                            <Accordion open={open3} toggle={(id)=>{
  if (open3 === id) {
    setOpen3();
  } else {
    setOpen3(id);
  }
}} className="mb-3 accordian-complaints">
        <AccordionItem>
          <AccordionHeader targetId="3">Product Information</AccordionHeader>
          <AccordionBody accordionId="3">
                              <Row>

                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Error Causing Part</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'phonecode'}
                                //   onChange={(e)=>{
                                //     setPhoneCodeVal(e)
                                //     validation.setFieldValue('phonecode', e.value)
                                //     validation.setFieldError('phonecode',false)
                                //   }}
                                //   onBlur={validation.handleBlur}
                                //   options={phoneCodeOptions}
                                //   value={
                                //     phoneCodeVal || ""
                                // }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Product Registration</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'phonecode'}
                                //   onChange={(e)=>{
                                //     setPhoneCodeVal(e)
                                //     validation.setFieldValue('phonecode', e.value)
                                //     validation.setFieldError('phonecode',false)
                                //   }}
                                //   onBlur={validation.handleBlur}
                                //   options={phoneCodeOptions}
                                //   value={
                                //     phoneCodeVal || ""
                                // }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                    Serial Number
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Serial Number"
                                    name="lname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lname || ""}
                                    invalid={
                                      validation.touched.lname && validation.errors.lname ? true : false
                                    }
                                  />
                                  {validation.touched.lname && validation.errors.lname ? (
                                    <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                  Product Line
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Product Line"
                                    name="lname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lname || ""}
                                    invalid={
                                      validation.touched.lname && validation.errors.lname ? true : false
                                    }
                                  />
                                  {validation.touched.lname && validation.errors.lname ? (
                                    <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                  Lot Number
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Lot Number"
                                    name="lname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lname || ""}
                                    invalid={
                                      validation.touched.lname && validation.errors.lname ? true : false
                                    }
                                  />
                                  {validation.touched.lname && validation.errors.lname ? (
                                    <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>


                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Category</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'phonecode'}
                                //   onChange={(e)=>{
                                //     setPhoneCodeVal(e)
                                //     validation.setFieldValue('phonecode', e.value)
                                //     validation.setFieldError('phonecode',false)
                                //   }}
                                //   onBlur={validation.handleBlur}
                                //   options={phoneCodeOptions}
                                //   value={
                                //     phoneCodeVal || ""
                                // }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              
                              
                              </Row>
                              </AccordionBody>
                              </AccordionItem>
                              </Accordion>


                              <Accordion open={open4} toggle={(id)=>{
  if (open4 === id) {
    setOpen4();
  } else {
    setOpen4(id);
  }
}} className="mb-3 accordian-complaints">
        <AccordionItem>
          <AccordionHeader targetId="4">Symptom/Cause Codes</AccordionHeader>
          <AccordionBody accordionId="4">
            <Row>

            <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Symptom Codes</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  isMulti
                                  name={'phonecode'}
                                //   onChange={(e)=>{
                                //     setPhoneCodeVal(e)
                                //     validation.setFieldValue('phonecode', e.value)
                                //     validation.setFieldError('phonecode',false)
                                //   }}
                                //   onBlur={validation.handleBlur}
                                //   options={phoneCodeOptions}
                                //   value={
                                //     phoneCodeVal || ""
                                // }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col> 
                              
                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Cause Codes</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  isMulti
                                  name={'phonecode'}
                                //   onChange={(e)=>{
                                //     setPhoneCodeVal(e)
                                //     validation.setFieldValue('phonecode', e.value)
                                //     validation.setFieldError('phonecode',false)
                                //   }}
                                //   onBlur={validation.handleBlur}
                                //   options={phoneCodeOptions}
                                //   value={
                                //     phoneCodeVal || ""
                                // }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
            </Row>
            </AccordionBody>
                              </AccordionItem>
                              </Accordion>

                           
                            </Form>
                          </div>
                        </TabPane>


                        <TabPane tabId={3}>
                          <div>
                            <Form>

                            <Accordion open={open5} toggle={(id)=>{
  if (open5 === id) {
    setOpen5();
  } else {
    setOpen5(id);
  }
}} className="mb-3 accordian-complaints">
        <AccordionItem>
          <AccordionHeader targetId="5">Complaint Products</AccordionHeader>
          <AccordionBody accordionId="5">
                              <Row>
                                </Row></AccordionBody></AccordionItem>
                                </Accordion>

                            <Accordion open={open6} toggle={(id)=>{
  if (open6 === id) {
    setOpen6();
  } else {
    setOpen6(id);
  }
}} className="mb-3 accordian-complaints">
        <AccordionItem>
          <AccordionHeader targetId="6">Dates</AccordionHeader>
          <AccordionBody accordionId="6">
                              <Row>

                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Event Date</Label>
                                  <Flatpickr
                  // value={Date.parse(pick_date)}
                  className="form-control d-block"
                  placeholder="Pick a date"
                  options={{
                    altInput: true,
                    dateFormat: "d-m-y"
                  }}
                 
                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>

                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Alert Date</Label>
                                  <Flatpickr
                  // value={Date.parse(pick_date)}
                  className="form-control d-block"
                  placeholder="Pick a date"
                  options={{
                    altInput: true,
                    dateFormat: "d-m-y"
                  }}
                 
                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              
                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Aware Date</Label>
                                  <Flatpickr
                  // value={Date.parse(pick_date)}
                  className="form-control d-block"
                  placeholder="Pick a date"
                  options={{
                    altInput: true,
                    dateFormat: "d-m-y"
                  }}
                 
                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              

                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Date Opened</Label>
                                  <Flatpickr
                  // value={Date.parse(pick_date)}
                  className="form-control d-block"
                  placeholder="Pick a date"
                  options={{
                    altInput: true,
                    dateFormat: "d-m-y"
                  }}
                 
                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              

                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Manufacturing Date</Label>
                                  <Flatpickr
                  // value={Date.parse(pick_date)}
                  className="form-control d-block"
                  placeholder="Pick a date"
                  options={{
                    altInput: true,
                    dateFormat: "d-m-y"
                  }}
                 
                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              

                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Date Submitted</Label>
                                  <Flatpickr
                  // value={Date.parse(pick_date)}
                  className="form-control d-block"
                  placeholder="Pick a date"
                  options={{
                    altInput: true,
                    dateFormat: "d-m-y"
                  }}
                 
                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              

                              <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>
                                    Is Reportable For PSUR/MIR?
                                    </Label>

                                    <div className="square-switch"    style={{marginTop : 10}}>
                            <input
                               type="checkbox"
                              //  id="square-switch1"
                               name="isadmin"
                               className="switch switch-bool"
                           
                               onKeyDown={handleKeyDown}
                               onChange={()=>{
                                // console.log('clicked')
                                 if(validationCorporate.values.isadmin == 1){
                                  validationCorporate.setFieldValue('isadmin', 0)
                                 }else{
                                  validationCorporate.setFieldValue('isadmin', 1)
             
                                 }
                               }}
                               onBlur={validationCorporate.handleBlur}
                              //  value={validationCorporate.values.isadmin || ""}
                              checked={validationCorporate.values.isadmin == 0 ? false : true}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label="Yes"
                              data-off-label="No"
                              onClick={()=>{
                                // console.log('clicked')
                                 if(validationCorporate.values.isadmin == 1){
                                  validationCorporate.setFieldValue('isadmin', 0)
                                 }else{
                                  validationCorporate.setFieldValue('isadmin', 1)
             
                                 }
                               }}
                            />
                          </div>
                                   
                                  </FormGroup>
                                </Col>
                              

                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Due Date</Label>
                                  <Flatpickr
                  // value={Date.parse(pick_date)}
                  className="form-control d-block"
                  placeholder="Pick a date"
                  options={{
                    altInput: true,
                    dateFormat: "d-m-y"
                  }}
                 
                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              

                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Received Date</Label>
                                  <Flatpickr
                  // value={Date.parse(pick_date)}
                  className="form-control d-block"
                  placeholder="Pick a date"
                  options={{
                    altInput: true,
                    dateFormat: "d-m-y"
                  }}
                 
                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              
                              </Row>
                              </AccordionBody>
                              </AccordionItem>
                              </Accordion>


                              <Accordion open={open7} toggle={(id)=>{
  if (open7 === id) {
    setOpen7();
  } else {
    setOpen7(id);
  }
}} className="mb-3 accordian-complaints">
        <AccordionItem>
          <AccordionHeader targetId="7">Issue Management</AccordionHeader>
          <AccordionBody accordionId="7">
                              <Row>
                                </Row></AccordionBody></AccordionItem>
                                </Accordion>

                              <Accordion open={open8} toggle={(id)=>{
  if (open8 === id) {
    setOpen8();
  } else {
    setOpen8(id);
  }
}} className="mb-3 accordian-complaints">
        <AccordionItem>
          <AccordionHeader targetId="8">Customer Information</AccordionHeader>
          <AccordionBody accordionId="8">
            <Row>

            <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Account</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  isMulti
                                  name={'phonecode'}
                                //   onChange={(e)=>{
                                //     setPhoneCodeVal(e)
                                //     validation.setFieldValue('phonecode', e.value)
                                //     validation.setFieldError('phonecode',false)
                                //   }}
                                //   onBlur={validation.handleBlur}
                                //   options={phoneCodeOptions}
                                //   value={
                                //     phoneCodeVal || ""
                                // }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col> 
                              
                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Country Of Incident</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  isMulti
                                  name={'phonecode'}
                                //   onChange={(e)=>{
                                //     setPhoneCodeVal(e)
                                //     validation.setFieldValue('phonecode', e.value)
                                //     validation.setFieldError('phonecode',false)
                                //   }}
                                //   onBlur={validation.handleBlur}
                                //   options={phoneCodeOptions}
                                //   value={
                                //     phoneCodeVal || ""
                                // }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                              <FormGroup className="mb-3">
                                  <Label  className="required">Contact</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  isMulti
                                  name={'phonecode'}
                                //   onChange={(e)=>{
                                //     setPhoneCodeVal(e)
                                //     validation.setFieldValue('phonecode', e.value)
                                //     validation.setFieldError('phonecode',false)
                                //   }}
                                //   onBlur={validation.handleBlur}
                                //   options={phoneCodeOptions}
                                //   value={
                                //     phoneCodeVal || ""
                                // }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
            </Row>
            </AccordionBody>
                              </AccordionItem>
                              </Accordion>

                              <Accordion open={open9} toggle={(id)=>{
  if (open9 === id) {
    setOpen9();
  } else {
    setOpen9(id);
  }
}} className="mb-3 accordian-complaints">
        <AccordionItem>
          <AccordionHeader targetId="9">Owner Information</AccordionHeader>
          <AccordionBody accordionId="9">
            <Row>

            <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                  Created By
                                  </Label>
                                  <Input
                                    disabled
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="lname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lname || ""}
                                    invalid={
                                      validation.touched.lname && validation.errors.lname ? true : false
                                    }
                                  />
                                  {validation.touched.lname && validation.errors.lname ? (
                                    <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>


                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                  Owner
                                  </Label>
                                  <Input
                                    disabled
                                    type="text"
                                    className="form-control"
                                    placeholder=""
                                    name="lname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lname || ""}
                                    invalid={
                                      validation.touched.lname && validation.errors.lname ? true : false
                                    }
                                  />
                                  {validation.touched.lname && validation.errors.lname ? (
                                    <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>

              </Row>
              </AccordionBody>
              </AccordionItem>
              </Accordion>

                           
                            </Form>
                          </div>
                        </TabPane>


                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTabVartical === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTabVertical(activeTabVartical - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={
                            activeTabVartical === 3 ? "next disabled" : "next"
                          }
                        >
                          <Link
                            // to="#"
                            onClick={(e) => {
                              toggleTabVertical(activeTabVartical + 1)
                              // e.preventDefault();
                              // if(activeTabVartical ==1){
                              //   validation.handleSubmit();
                              // }
                              // return false;
                            }}
                            type="submit"
                          >
                            Next
                          </Link>
                        </li>

                        {/* <li
                        style={{float  : 'left'}}
                          className={
                            activeTabVartical < 3 ? "next disabled" : "next"
                          }
                        >
                        
                          <Link
                            to="#"
                            onClick={() => {
                              validationBilling.handleSubmit();
                            }}
                          >
                            Skip & Save
                          </Link>
                        </li> */}

                        <li
                         style={{float  : 'left'}}
                          className={
                            activeTabVartical < 2 ? "next disabled" : "next"
                          }
                        >
                        
                          <Link
                            to="#"
                            onClick={() => {
                              // validationCorporate.handleSubmit();
                            }}
                          >
                           Save
                          </Link>
                        </li>

                        <li
                         style={{float  : 'left'}}
                          className={ "next"}
                        >
                        
                          <Link
                           to="/corporate-list"
                            className="danger-btn"
                            // onClick={() => {
                            //   navigate('/corporate-list')
                            // }}
                          >
                           Cancel
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Loader isLoading={isLoading} /> 

    </React.Fragment>
  )
}

export default AddComplaint

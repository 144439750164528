import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  Badge,
  UncontrolledTooltip,
  FormFeedback,
Button,
Modal,
ModalHeader,
ModalBody,
} from "reactstrap"

import classnames from "classnames"
import { Link , useNavigate} from "react-router-dom"
import Select from 'react-select';
import Switch from "react-switch";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getTabDataEvent as onGetTabDataEvent,
  getActiveModules as onGetActiveModules,
  updateEventDT as onUpdateEvent,
  approveEventDT as onApproveEventDT,
  saveTabAnswersData,
  getModuleScoreById as onGetModuleScoreById
} from "store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import { authUser } from "helpers/dataConfig";
import { useLocation } from 'react-router-dom';
import ApproveModal from "components/Common/ApproveModal";
import Loader from "components/Common/Loader";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { FormControl, InputGroup, Tab, Nav, ModalTitle } from 'react-bootstrap';
import PerformDecisionTree from "./PerformDecisionTree";
import moment from "moment";
import ViewDecisionTree from "./ViewDecisionTree";

const AlterEvent = (props) => {

  //meta title
  document.title = "Alter Event | Smarteeva";

  const navigate = useNavigate()
  const location = useLocation();
  const dispatch = useDispatch();

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  const [typeOptions, setTypeOptions] = useState([
    {label : 'CVR Decision Tree', value : 'CVR Decision Tree'},
    {label : 'MIR Decision Tree', value : 'MIR Decision Tree'},
    {label : 'MDR Decision Tree', value : 'MDR Decision Tree'}

]);

const [typeVal, setTypeVal] = useState('');
const [moduleValDT, setModuleValDT] = useState('');
const [complaintVal, setComplaintVal] = useState('');
  const [numRegex, setNumRegex] = useState(/^[0-9]*$/)

  const [userDetails, setUserDetails] = useState([]);
  const [isEditable, setIsEditable] = useState(true);
  const [approveModal, setApproveModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [eventData, setEventData] = useState('');
  const [complaintOptions, setComplaintOptions] = useState([])
  const [moduleOptions, setModuleOptions] = useState([])

  const [tabData, setTabData] = useState([])
  const [tabDataRef, setTabDataRef] = useState([])

  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [selectedTab, setSelectedTab] = useState({})
  const [selectedTabRef, setSelectedTabRef] = useState({})

  const [moduleScoreing, setModuleScores] = useState([])

  const [showViewModal ,setShowViewModal] = useState(false)

  
const { error, loading, tabDataEvent, addEventSuccessMsg, activeModulesData, moduleScores  } = useSelector(state => ({
  error: state.DecisionTree.error,
  loading : state.DecisionTree.loading,
  tabDataEvent : state.DecisionTree.tabDataEvent,
  addEventSuccessMsg: state.DecisionTree.addEventSuccessMsg,
  activeModulesData: state.DecisionTree.activeModulesData,
  moduleScores: state.DecisionTree.moduleScores,

}));

useEffect(() => {
  setIsLoading(loading)
}, [loading]);

useEffect(() => {
  if(location.state != null){
    // console.log(location.state.state)
    let data = location.state.state
    setEventData(data)
    setIsEditable(data.isEditable)

    setTypeVal({label : data.FLD_TYPE, value : data.FLD_TYPE})
    setComplaintVal('')
  }
}, []);

useEffect(() => {
  if (activeModulesData && !activeModulesData.length) {
      dispatch(onGetActiveModules());
}
}, [dispatch, activeModulesData]);


useEffect(() => {
  if (moduleScores && !moduleScores.length) {
      dispatch(onGetModuleScoreById(location.state.state.FLD_MODULEID));
}
}, [dispatch, moduleScores]);

useEffect(() => {
  setModuleScores(moduleScores)
  // console.log(moduleScores)
 }, [moduleScores]);

useEffect(() => {
  setModuleOptions(activeModulesData);
  setModuleValDT(activeModulesData.filter(val => val.value === location.state.state.FLD_MODULEID)[0])
 }, [activeModulesData]);
 


useEffect(() => {
  if (tabDataEvent && !tabDataEvent.length) {
    // console.log(location.state.state.FLD_MODULEID)
      dispatch(onGetTabDataEvent(location.state.state));
}
}, [dispatch, tabDataEvent]);

useEffect(() => {
  setTabData(tabDataEvent)
  setTabDataRef(tabDataEvent)
  // console.log(tabDataEvent)
 }, [tabDataEvent]);

// useEffect(() => {
//   // console.log(modulesDT)

//   setEventList(eventsDT);
//   // setIsRight(false)
// }, [eventsDT]);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: eventData.FLD_TITLE,
      type : eventData.FLD_TYPE,
      complaintid : eventData.FLD_COMPLAINTID,
      moduleid: eventData.FLD_MODULEID,
      duedate : eventData.FLD_DUEDATE,
      status: eventData.FLD_STATUS,

  },
  validationSchema: Yup.object({
       type: Yup.string().required("Please Enter Event Type"),
       moduleid: Yup.string().required("Please Select Module Type")
  }),
    onSubmit: (values) => {
      // toggleTabVertical(activeTabVartical + 1)
      // console.log(values)

      values.eventid = eventData.FLD_EVENTID
      values.eventno = eventData.FLD_EVENTNO
       // console.log(values)

       let val = {...values, complaintid : values.complaintid == '' ? 0 : values.complaintid}

       dispatch(onUpdateEvent(val));
    },
});


const handleKeyDown = (e) => {
  // console.log(e.key)
  if (e.key.match(/[a-z]/i) && e.key!= 'Backspace') {
    e.preventDefault();
  }
};

const handleApproveEvent = () =>{
  if (eventData && eventData.FLD_EVENTID) {
    dispatch(onApproveEventDT(eventData.FLD_EVENTID));
    setApproveModal(false);
}
}


const onChangeAnswers = (tabindex, quesindex, type, answerData, choiceIndex) =>{
let tabAnsData = [...tabData]

// if(type == 'multiplechoice'){
//   tabAnsData[tabindex].questionData[quesindex].answer.push(answerData)
// }else{
  if(tabAnsData[tabindex].questionData[quesindex].choices[choiceIndex]){
    tabAnsData[tabindex].questionData[quesindex].choices[choiceIndex].answer = answerData
  }else{
    tabAnsData[tabindex].questionData[quesindex].choices.push({answer : answerData})
    // tabAnsData[tabindex].questionData[quesindex].choices[choiceIndex].answer = answerData
  }
// }
// console.log(tabAnsData)
setTabData(tabAnsData)

}

const onChangeAnswersChoice = (tabindex, quesindex, type, answerData, choiceIndex) =>{
  let tabAnsData = [...tabData]
  
    tabAnsData[tabindex].questionData[quesindex].choices[choiceIndex].answer = answerData
  // console.log(tabAnsData)
  setTabData(tabAnsData)
  
  }

const onRemoveAnswers = (tabindex, quesindex, type, answerData, choiceIndex) =>{
  let tabAnsData = [...tabData]
  
  tabAnsData[tabindex].questionData[quesindex].choices[choiceIndex].answer = ''
  
  // console.log(tabAnsData)
  setTabData(tabAnsData)
  
  }

  const onSaveAnswers = (answerData) =>{
    setShowPreviewModal(false)
    dispatch(saveTabAnswersData(tabData,eventData, navigate, moduleScores));
  }

  const onChangeComments = (tabindex, quesindex, answerData) =>{
    let tabAnsData = [...tabData]
    tabAnsData[tabindex].questionData[quesindex].comments = answerData
    setTabData(tabAnsData)
  }


  const onActivateSelectedTab = (tab) =>{
    setSelectedTab(tab)
  }


const previewDecisionTree = () =>{
  return(
  <Modal size="md" isOpen={showPreviewModal} toggle={onClosePreviewModalClick} centered={true}>
  <div className="modal-content">
    <ModalHeader>
      <Row>
        <Col lg='2'>
      <div className="avatar-sm mx-auto">
        <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
          <i className="mdi mdi-eye-outline"></i>
        </div>
      </div>
      </Col>
      <Col lg='8' style={{margin : 'auto', marginLeft : 0}}>
      <ModalTitle>{eventData.FLD_TYPE}</ModalTitle>
      </Col>
    
      </Row>

      <button type="button" onClick={onClosePreviewModalClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
   
    </ModalHeader>
    <ModalBody className="text-center model-body" style={{padding: '22px 30px'}}>
     

    <Tab.Container defaultActiveKey="1">
          <Nav className="tabGroup " style={{marginTop : 5}}>
            {tabData.map((tab, index)=>{

               // Ensure the ref array is populated
      // if (!editRefs.current[index]) {
      //   editRefs.current[index] = React.createRef();
      // }
      return(
              <Nav.Item className='nav-item-dt' key={index}>
              <Nav.Link eventKey="1" className='nav-link-dt'>
                <div className="flex-centre01">
                  <div className="flex-centre02" onClick={()=>{
    
                    onActivateSelectedTab(tab)
                  }}>
                    <div className="number-tab" style={{backgroundColor : tabData.indexOf(selectedTab) == index ? '#369bff' : '#ADB5BD'}}>{index+1}</div>
                    <div className="tabtext" >
                      {/* {console.log(tab.tabnameedit)} */}
                   {tab.FLD_TABNAMEEDIT ?
                   <input className='tab-edit-input'
                   type="text"
                   value = {tab.FLD_TABNAME}
                   onChange={(e)=>{
                    OnTabNameChange(e.target.value, index)
                   }}
                  //  onKeyDown={(e)=>{
                  //   console.log(e)
                  //  }}
                   />
                   : <span className="form-name"  style={{color : tabData.indexOf(selectedTab) == index ? '#369bff' : '#ADB5BD'}}>{tab.FLD_TABNAME == '' ? `Tab ${index+1}` : tab.FLD_TABNAME}</span>}
                     {/* <span className="edit-icon"
                      onClick={()=>{
                        // console.log(editRefs)
                        pOnEditTabName(true,index)
                      }}
                      >
                        <i className="mdi mdi-lead-pencil" 
                        style={{marginLeft : 10, fontSize : 15}}
                        ></i>
                      </span> */}
                    </div>
                  </div>
                  <div className="top01" style={{display : tabData.length == index+1 ? 'none' :''}}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                    >
                      <path
                        d="M1 13L7 7L1 1"
                        stroke="#ADB5BD"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
      )
})}
            
         

            {/* <div className='addbutton' onClick={addNewTabs}>
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                <path d="M9.5 3.75V14.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.25 9H14.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div> */}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="1">

              <div className="draggableWrapper">
            
               
              {selectedTab.questionData && selectedTab.questionData.map((qData, indx)=>(
                
               <PerformDecisionTree 
    key={indx}
    quesIndex={indx}
    tabIndex={tabData.indexOf(selectedTab)}
    qData={qData}
    questionData={selectedTab.questionData}
    answerData={qData.answer && qData.answer}
    onRemoveAnswers={onRemoveAnswers}
              //  selectedQuestion={selectedQuestion} 
               tabData={tabData}
               selectedTab={selectedTab}
               onChangeAnswers={onChangeAnswers}
               onChangeComments={onChangeComments}
               onChangeAnswersChoice={onChangeAnswersChoice}
              //  addNewTabs={addNewTabs}
              //  onShowNextTab={onShowNextTab}
              //  onShowPrevioustab={onShowPrevioustab}
               onActivateSelectedTab={onActivateSelectedTab}
              //  questionTypeOptions={questionTypeOptions}
              //  renderFieldViewTab={renderFieldViewTab}
              //  removeQuestionFromTab={removeQuestionFromTab}
              //  onChangeTabQuestionType={onChangeTabQuestionType}
              //  configError={configError}
              //  fieldTypeOptions={fieldTypeOptions}
              //  index={indx}
              //  moveCard={moveCard}
    />
        
              ))}
              </div>

            </Tab.Pane>
            {/* <Tab.Pane eventKey="2">Tab 2 Content</Tab.Pane> */}
          </Tab.Content>
        </Tab.Container>
      {/* <p className="text-muted font-size-16 mb-4">{deleteMsg}</p> */}

      <div className="card-navigator" style={{ display: 'flex' }}>
      <div className={tabData.indexOf(selectedTab) > 0 ? "previous active" : "previous disabled" } id="previousButton" onClick={onShowPrevioustab}>
          <span style={{ marginRight: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
            <path d="M15.5 28C22.4036 28 28 22.4036 28 15.5C28 8.59644 22.4036 3 15.5 3C8.59644 3 3 8.59644 3 15.5C3 22.4036 8.59644 28 15.5 28Z" stroke={tabData.indexOf(selectedTab) > 0 ? '#369bFF' : "#ADB5BD"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 10.3333L10 15.5L15 20.6667" stroke={tabData.indexOf(selectedTab) > 0 ? '#369bFF' : "#ADB5BD"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 15.5H10" stroke={tabData.indexOf(selectedTab) > 0 ? '#369bFF' : "#ADB5BD"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg></span>
          <span className='correctsubmit'>Previous</span>
        </div>
       
        <div className={tabData.indexOf(selectedTab) < tabData.length-1 ? "next active" : "next disabled" } onClick={onShowNextTab}>
          <span style={{ marginRight: '10px' }} className='correctsubmit' id="submitButton">Next</span>
          <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
            <path d="M15 28C21.9036 28 27.5 22.4036 27.5 15.5C27.5 8.59644 21.9036 3 15 3C8.09644 3 2.5 8.59644 2.5 15.5C2.5 22.4036 8.09644 28 15 28Z" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 20.5L20 15.5L15 10.5" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 15.5H20" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg></span>
        </div>
      </div>

      <div className="hstack gap-2 justify-content-end mb-0">
        <button type="button" className="btn btn-secondary" onClick={onClosePreviewModalClick}>Close</button>
        <button type="button" className="btn btn-primary" onClick={onSaveAnswers}>Save</button>

      </div>
    </ModalBody>
  </div>
</Modal>
  )
}


const viewDecisionTree = () =>{
  return(
  <Modal size="md" isOpen={showViewModal} toggle={onCloseViewModalClick} centered={true}>
  <div className="modal-content">
    <ModalHeader>
      <Row>
        <Col lg='2'>
      <div className="avatar-sm mx-auto">
        <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
          <i className="mdi mdi-eye-outline"></i>
        </div>
      </div>
      </Col>
      <Col lg='8' style={{margin : 'auto', marginLeft : 0}}>
      <ModalTitle>{eventData.FLD_TYPE}</ModalTitle>
      </Col>
    
      </Row>

      <button type="button" onClick={onCloseViewModalClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
   
    </ModalHeader>
    <ModalBody className="text-center model-body" style={{padding: '22px 30px'}}>
     

    <Tab.Container defaultActiveKey="1">
          <Nav className="tabGroup " style={{marginTop : 5}}>
            {tabDataRef.map((tab, index)=>{

              // console.log(tab)
               // Ensure the ref array is populated
      // if (!editRefs.current[index]) {
      //   editRefs.current[index] = React.createRef();
      // }
      return(
              <Nav.Item className='nav-item-dt' key={index}>
              <Nav.Link eventKey="1" className='nav-link-dt'>
                <div className="flex-centre01">
                  <div className="flex-centre02" onClick={()=>{
    
                  setSelectedTabRef(tab)
                  }}>
                    <div className="number-tab" style={{backgroundColor : tabDataRef.indexOf(selectedTabRef) == index ? '#369bff' : '#ADB5BD'}}>{index+1}</div>
                    <div className="tabtext" >
                      {/* {console.log(tab.tabnameedit)} */}
                   {tab.FLD_TABNAMEEDIT ?
                   <input className='tab-edit-input'
                   type="text"
                   value = {tab.FLD_TABNAME}
                   onChange={(e)=>{
                    // OnTabNameChange(e.target.value, index)
                   }}
                  //  onKeyDown={(e)=>{
                  //   console.log(e)
                  //  }}
                   />
                   : <span className="form-name"  style={{color : tabDataRef.indexOf(selectedTabRef) == index ? '#369bff' : '#ADB5BD'}}>{tab.FLD_TABNAME == '' ? `Tab ${index+1}` : tab.FLD_TABNAME}</span>}
                     {/* <span className="edit-icon"
                      onClick={()=>{
                        // console.log(editRefs)
                        pOnEditTabName(true,index)
                      }}
                      >
                        <i className="mdi mdi-lead-pencil" 
                        style={{marginLeft : 10, fontSize : 15}}
                        ></i>
                      </span> */}
                    </div>
                  </div>
                  <div className="top01" style={{display : tabData.length == index+1 ? 'none' :''}}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                    >
                      <path
                        d="M1 13L7 7L1 1"
                        stroke="#ADB5BD"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>
              </Nav.Link>
            </Nav.Item>
      )
})}
            
         

            {/* <div className='addbutton' onClick={addNewTabs}>
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                <path d="M9.5 3.75V14.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.25 9H14.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div> */}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="1">

              <div className="draggableWrapper">
            
               
              {selectedTabRef.questionData && selectedTabRef.questionData.map((qData, indx)=>(
                
               <ViewDecisionTree 
    key={indx}
    quesIndex={indx}
    tabIndex={tabDataRef.indexOf(selectedTabRef)}
    qData={qData}
    questionData={selectedTabRef.questionData}
    answerData={qData.answer && qData.answer}
    onRemoveAnswers={onRemoveAnswers}
              //  selectedQuestion={selectedQuestion} 
               tabData={tabDataRef}
               selectedTab={selectedTabRef}
               onChangeAnswers={onChangeAnswers}
               onChangeComments={onChangeComments}
               onChangeAnswersChoice={onChangeAnswersChoice}
              //  addNewTabs={addNewTabs}
              //  onShowNextTab={onShowNextTab}
              //  onShowPrevioustab={onShowPrevioustab}
               onActivateSelectedTab={onActivateSelectedTab}
              //  questionTypeOptions={questionTypeOptions}
              //  renderFieldViewTab={renderFieldViewTab}
              //  removeQuestionFromTab={removeQuestionFromTab}
              //  onChangeTabQuestionType={onChangeTabQuestionType}
              //  configError={configError}
              //  fieldTypeOptions={fieldTypeOptions}
              //  index={indx}
              //  moveCard={moveCard}
    />
        
              ))}
              </div>

            </Tab.Pane>
            {/* <Tab.Pane eventKey="2">Tab 2 Content</Tab.Pane> */}
          </Tab.Content>
        </Tab.Container>
      {/* <p className="text-muted font-size-16 mb-4">{deleteMsg}</p> */}

      <div className="card-navigator" style={{ display: 'flex' }}>
      <div className={tabDataRef.indexOf(selectedTabRef) > 0 ? "previous active" : "previous disabled" } id="previousButton" 
      onClick={()=>{
  const currentIndex = tabDataRef.indexOf(selectedTabRef);
  const nextIndex = (currentIndex - 1) % tabDataRef.length;  
  // console.log(questionData[nextIndex])
  if(currentIndex > 0){
    setSelectedTabRef(tabDataRef[nextIndex])
  }}}>
          <span style={{ marginRight: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
            <path d="M15.5 28C22.4036 28 28 22.4036 28 15.5C28 8.59644 22.4036 3 15.5 3C8.59644 3 3 8.59644 3 15.5C3 22.4036 8.59644 28 15.5 28Z" stroke={tabDataRef.indexOf(selectedTabRef) > 0 ? '#369bFF' : "#ADB5BD"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 10.3333L10 15.5L15 20.6667" stroke={tabDataRef.indexOf(selectedTabRef) > 0 ? '#369bFF' : "#ADB5BD"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20 15.5H10" stroke={tabDataRef.indexOf(selectedTabRef) > 0 ? '#369bFF' : "#ADB5BD"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg></span>
          <span className='correctsubmit'>Previous</span>
        </div>
       
        <div className={tabDataRef.indexOf(selectedTabRef) < tabDataRef.length-1 ? "next active" : "next disabled" } onClick={()=>{
          {
            const currentIndex = tabDataRef.indexOf(selectedTabRef);
            const nextIndex = (currentIndex + 1) % tabDataRef.length;
                // console.log(questionData[nextIndex])
                if(currentIndex < tabDataRef.length-1){
                    setSelectedTabRef(tabDataRef[nextIndex])
                }
            }
        }}>
          <span style={{ marginRight: '10px' }} className='correctsubmit' id="submitButton">Next</span>
          <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
            <path d="M15 28C21.9036 28 27.5 22.4036 27.5 15.5C27.5 8.59644 21.9036 3 15 3C8.09644 3 2.5 8.59644 2.5 15.5C2.5 22.4036 8.09644 28 15 28Z" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 20.5L20 15.5L15 10.5" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 15.5H20" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg></span>
        </div>
      </div>

      <div className="hstack gap-2 justify-content-end mb-0">
        <button type="button" className="btn btn-secondary" onClick={onCloseViewModalClick}>Close</button>
        {/* <button type="button" className="btn btn-primary" onClick={onSaveAnswers}>Save</button> */}

      </div>
    </ModalBody>
  </div>
</Modal>
  )
}


const onClosePreviewModalClick = () =>{
  setShowPreviewModal(!showPreviewModal)
}

const onCloseViewModalClick = () =>{
  setShowViewModal(!showViewModal)
}


const onShowPrevioustab = ()=>{
  const currentIndex = tabData.indexOf(selectedTab);
  const nextIndex = (currentIndex - 1) % tabData.length;  
  // console.log(questionData[nextIndex])
  if(currentIndex > 0){
    setSelectedTab(tabData[nextIndex])
  }
  }

  const onShowNextTab = ()=>{
    const currentIndex = tabData.indexOf(selectedTab);
    const nextIndex = (currentIndex + 1) % tabData.length;
        // console.log(questionData[nextIndex])
        if(currentIndex < tabData.length-1){
            setSelectedTab(tabData[nextIndex])
        }
    }




    const onClickNewAnswers = () =>{


      // let tabAnsData = [...tabData]

      let tabAnsData = JSON.parse(JSON.stringify(tabData));


      for(let i= 0;i<tabAnsData.length;i++){

        for(let j= 0;j<tabAnsData[i].questionData.length;j++){
        
          for(let k= 0;k<tabAnsData[i].questionData[j].choices.length;k++){
        
            tabAnsData[i].questionData[j].choices[k].answer = ''

          }
        }

      }
      
      
      // tabAnsData[tabindex].questionData[quesindex].choices[choiceIndex].answer = ''
      
      // console.log(tabDataEvent)
      setTabData(tabAnsData)
      setSelectedTab(tabAnsData[0])
      setShowPreviewModal(true)
      
      }

  return (
    <React.Fragment>
      {previewDecisionTree()}
      {viewDecisionTree()}
<ApproveModal
                show={approveModal}
                approveMsg={'Are you sure you want to approve event.'}
                onApproveClick={handleApproveEvent}
                onCloseClick={() => setApproveModal(false)}
            />

      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="MDR Data" breadcrumbItem="Alter Event" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row>
                  <Col lg='7'>
                  <CardTitle className="mb-4">{eventData.FLD_EVENTNO}</CardTitle>
                  </Col>
                  <Col lg='5'>
                  <Row style={{justifyContent : 'flex-end'}}>
                            

                            <Col lg="8" style={{textAlign : 'right', 
                            display : eventData.FLD_APPROVAL_STATUS == 'Pending' ? '' : 'none'
                            }}>

                            <button
                              className="btn btn-success btn-block "
                              // type="submit"
                              onClick={()=>{
                                setApproveModal(true)
                              }}
                            >
                              Approve Event
                            </button>
                            </Col>

                            <Col lg="4" style={{textAlign : 'right',
                            display : !isEditable ? '' : 'none'
                      
                          }}>
                            <button
                              className="btn btn-primary btn-block "
                              // type="submit"
                              onClick={()=>{
                                setIsEditable(true)
                              }}
                            >
                              Edit Event
                            </button>
                            </Col>
                    
                          </Row>
              
                  </Col>
                  </Row>
                  {/* <CardTitle className="mb-4">Alter User Profile</CardTitle> */}
                  {/* <p className="text-muted mb-5">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultricies vel enim in malesuada. Nulla porta tempor placerat. Aenean vitae hendrerit lacus, vitae sollicitudin ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam tristique et mauris nec iaculis.
                  </p> */}
                  <div className="horizontal-wizard wizard clearfix horizontal">
                    <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 1,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 1,
                            })}
                            onClick={() => {
                              toggleTabVertical(1)
                            }}
                            disabled={!(passedStepsVertical || []).includes(1)}
                          >
                            <span className="number">1</span> General Info
                          </NavLink>
                        </NavItem>
                       
                    
                      </ul>
                      </div>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                            <Row>

                            <Col lg="6">
                            <div className="mb-3">
                                        <Row>
                                        <Col lg="10">
                                            <Label className="form-label"> Event Title</Label>
                                            </Col>
                                            <Col lg="2" style={{marginTop : -10, display : isEditable ? 'none' : ''}}>
                                   
                                   <Link
                                       onClick={()=>{
                                           
                                           setIsEditable(true)
                                         
                                       }}
                                       className="btn btn-sm btn-soft-info"
                                      
                                   >
                                       <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                       <UncontrolledTooltip placement="top" target="edittooltip">
                                           Edit
                                       </UncontrolledTooltip>
                                   </Link>
                            
                                           </Col>
                                            </Row>
                                            <Input
                                                disabled={!isEditable}
                                                name="title"
                                                type="text"
                                                placeholder="Enter Event Title"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.title || ""}
                                                invalid={
                                                    validation.touched.title && validation.errors.title ? true : false
                                                }
                                            />
                                            {validation.touched.title && validation.errors.title ? (
                                                <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                            ) : null}
                                        </div>
                                        </Col>
                                      
                                     
                                        <Col lg="6">
                            <div className="mb-3">
                                            <Label className="form-label required">Event Type</Label>
                                            <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'type'}
                                  isDisabled={!isEditable}
                                  isClearable
                                  isSearchable
                                  options={typeOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setTypeVal(e)
                                    validation.setFieldValue('type', e.value)
                                    validation.setFieldError('type',false)
                                    }else{
                                        setTypeVal('')
                                      validation.setFieldValue('type', '')
                                      validation.setFieldError('type',true)
                                    }
                                }}
                                  
                                  onBlur={validation.handleBlur}
                                  value={
                                    typeVal || ""
                                }
                                invalid={
                                  validation.touched.type && validation.errors.type
                                        ? true
                                        : false
                                }
                                className={validation.touched.type && validation.errors.type ? 'invalid-select' : ''}

                                />
                                  {validation.touched.type && validation.errors.type ? (
                                    <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                  ) : null}
                                        </div>
                                        </Col>

                                        <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">Related Complaint</Label>
                                            <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'complaintid'}
                                  isDisabled={!isEditable}
                                  isClearable
                                  isSearchable
                                  options={complaintOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setComplaintVal(e)
                                    validation.setFieldValue('complaintid', e.value)
                                    validation.setFieldError('complaintid',false)
                                    }else{
                                        setComplaintVal('')
                                      validation.setFieldValue('complaintid', '')
                                      validation.setFieldError('complaintid',true)
                                    }
                                }}
                                  
                                  onBlur={validation.handleBlur}
                                  value={
                                    complaintVal || ""
                                }
                                invalid={
                                  validation.touched.complaintid && validation.errors.complaintid
                                        ? true
                                        : false
                                }
                                className={validation.touched.complaintid && validation.errors.complaintid ? 'invalid-select' : ''}

                                />
                                  {validation.touched.complaintid && validation.errors.complaintid ? (
                                    <FormFeedback type="invalid">{validation.errors.complaintid}</FormFeedback>
                                  ) : null}
                                        </div>
                                        </Col>

                                        <Col lg="6">
                                        <div className="mb-3">
                                         
                                            <Label className="form-label required">Module Name (Decision Tree)</Label>
                                            <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'moduleid'}
                                  isDisabled
                                  isClearable
                                  isSearchable
                                  options={moduleOptions}
                                  onChange={(e)=>{
                                    if(e){
                                        console.log(e)
                                        console.log(moduleValDT)
                                    
                                    //     setModuleValDT(e)
                                    // validation.setFieldValue('moduleid', e.value)
                                    // validation.setFieldError('moduleid',false)
                                    // }else{
                                    //     setModuleValDT('')
                                    //   validation.setFieldValue('moduleid', '')
                                    //   validation.setFieldError('moduleid',true)
                                    }
                                }}
                                  
                                  onBlur={validation.handleBlur}
                                //   value={
                                //     event && activeModulesData.filter(val => val.value == event.FLD_MODULEID)[0] || ""
                                // }
                                value={moduleValDT}
                                invalid={
                                  validation.touched.moduleid && validation.errors.moduleid
                                        ? true
                                        : false
                                }
                                className={validation.touched.moduleid && validation.errors.moduleid ? 'invalid-select' : ''}

                                />
                                  {validation.touched.moduleid && validation.errors.moduleid ? (
                                    <FormFeedback type="invalid">{validation.errors.moduleid}</FormFeedback>
                                  ) : null}
                                        </div>
                                        </Col>

                                        <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">Due Date</Label>
                             <Flatpickr
                                    // value={Date.parse(pick_date)}
                                    
                                    disabled={!isEditable}
                                    className="form-control d-block"
                                    placeholder="Pick a date"
                                    options={{
                                        altInput: true,
                                        dateFormat: "d-m-y"
                                    }}
                                    //   onChange={(e) => updateValue(groupId, rule.id, moment(new Date(e)).format('MM/DD/YYYY'))
                                    //   onChange={validation.handleChange}
                                    onChange={(e)=>{
                                        if(e){
                                       
                                        validation.setFieldValue('duedate', moment(new Date(e)).format('MM/DD/YYYY'))
                                        validation.setFieldError('duedate',false)
                                        }else{
                                        
                                          validation.setFieldValue('duedate', '')
                                          validation.setFieldError('duedate',true)
                                        }
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={Date.parse(validation.values.duedate) || ""}
                                    // invalid={
                                    //     validation.touched.duedate && validation.errors.duedate ? true : false
                                    // }
                                />
                                {validation.touched.duedate && validation.errors.duedate ? (
                                    <FormFeedback type="invalid">{validation.errors.duedate}</FormFeedback>
                                ) : null}
                                    
             
                                            {/* <Input
                                            disabled={!isEditable}
                                                name="key"
                                                type="text"
                                                placeholder="Enter Key"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.key || ""}
                                                invalid={
                                                    validation.touched.key && validation.errors.key ? true : false
                                                }
                                            />
                                            {validation.touched.key && validation.errors.key ? (
                                                <FormFeedback type="invalid">{validation.errors.key}</FormFeedback>
                                            ) : null} */}
                                        </div>
                                        </Col>

                                        <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label required">Is Active</Label>
                                            <div className="square-switch"    style={{marginTop : 10}}>

                            <input
                                // disabled
                               type="checkbox"
                               id="square-switch1"
                               name="status"
                               className="switch switch-bool"
                                                
                               onKeyDown={handleKeyDown}
                               onChange={()=>{
                                // console.log('clicked')
                                 if(validation.values.status == 'Active'){
                                    validation.setFieldValue('status', 'Archived')
                                 }else{
                                    validation.setFieldValue('status', 'Active')
             
                                 }
                               }}
                               onBlur={validation.handleBlur}
                              //  value={validationCorporate.values.isadmin || ""}
                              checked={validation.values.status == 'Archived' ? false : true}
                            />
                            
                            <label
                                disabled={!isEditable}
                              htmlFor="square-switch1"
                              data-on-label="Yes"
                              data-off-label="No"
                              onClick={()=>{
                                // console.log('clicked')
                                 if(validation.values.status == 'Active'){
                                    validation.setFieldValue('status', 'Archived')
                                 }else{
                                    validation.setFieldValue('status', 'Active')
             
                                 }
                               }}
                            />
                          </div>
                                        </div>
                                        </Col>
                                      
                           
                           
                      
                            </Row>
                         
                          </Form>
                        </TabPane>

                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                       

                        {/* <li
                        style={{float  : 'left'}}
                          className={
                            activeTabVartical < 3 ? "next disabled" : "next"
                          }
                        >
                        
                          <Link
                            to="#"
                            onClick={() => {
                              validationBilling.handleSubmit();
                            }}
                          >
                            Skip & Save
                          </Link>
                        </li> */}

                        <li
                         style={{float  : 'right'}}
                          // className={
                          //   activeTabVartical < 2 ? "next disabled" : "next"
                          // }
                        >
                        
                          <Link
                          hidden={!isEditable}
                            to="#"
                            onClick={() => {
                              validation.handleSubmit();
                            }}
                          >
                           Save
                          </Link>
                        </li>

                        <li
                         style={{float  : 'right'}}
                          className={ "next"}
                        >
                        
                          <Link
                           to="/corporate-list"
                            className="danger-btn"
                            // onClick={() => {
                            //   navigate('/corporate-list')
                            // }}
                          >
                           Cancel
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                <Row>
                  <Col lg='8'>
                  <CardTitle className="mb-4">{eventData.FLD_TYPE}</CardTitle>
                  </Col>
                  <Col lg='4' >
                
                  <Button color="primary"
                   style={{float : 'right', display : tabData.length <= 0 ? 'none' : ''}} className="btn btn-primary waves-effect waves-light"
                                              onClick={() => {
                                                // console.log(tabData)
                                                onClickNewAnswers()
                                                // setSelectedTab(tabData[0])
                                                // setShowPreviewModal(true)
                                              }}
                                            >New</Button>


                  </Col>
                  </Row>
                  <Col lg='12'>
                  <Row style={{justifyContent : 'flex-end'}}>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Action</th>
                          {/* <th>Event Status</th> */}
                          <th>Decision Text</th>
                          <th>Due Date</th>
                          <th>Event Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tabData.length > 0 ? <tr>
                          <th scope="row">1</th>
                          <td onClick={() => {
                                                // console.log(tabData)
                                                setSelectedTabRef(tabDataRef[0])
                                                setShowViewModal(true)
                                              }}><Link>{eventData && eventData.FLD_FINALDECISION != null ? 'Completed' : ''}</Link></td>
                          <td>{eventData && eventData.FLD_FINALDECISION}</td>
                          <td>{validation.values.duedate}</td>
                          <td>{moment(tabData[0].FLD_LASTUPDATEDON).format('MM/DD/YYYY')}</td>
                        </tr> : null}
                       
                      
                      </tbody>
                    </Table>
                  </div>
                    </Row>
                    </Col>
                  </CardBody>
                  </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Loader isLoading={isLoading} /> 

    </React.Fragment>
  )
}

export default AlterEvent

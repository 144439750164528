import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    GET_USER_CHARTS_DATA,
    GET_MDR_SUBMIT_DATA,
    GET_MDR_STATS_DATA,
    GET_MDR_STATS_DATA_YEAR,
    SET_LOADING_INDICATOR_DASHBOARD,
    GET_MDR_STATS_DATA_PUBLIC,
    GET_MDR_STATS_DATA_YEAR_PUBLIC,
    SET_LOADING_INDICATOR_DASHBOARD_PUBLIC,
    GET_MDR_TOP_DEVICES_PUBLIC,
    GET_MDR_TOP_PATIENTS_PUBLIC,
    GET_MDR_YEAR_DATA_PUBLIC,
    GET_MDR_MONTH_COUNT_PUBLIC,
    GET_MDR_EVENT_COUNT_PUBLIC,
    GET_MDR_YEARLY_COUNT_PUBLIC,
    GET_MDR_MEDICAL_SPEC_COUNT_PUBLIC,
    GET_MDR_MONTHLY_EVENT_COUNT_PUBLIC
} from "./actionTypes";

export const apiSuccessPublic = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFailPublic = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

export const getMDRStatsDataPublic = () => ({
    type: GET_MDR_STATS_DATA_PUBLIC
});

export const getMDRStatsDataYearPublic = () => ({
    type: GET_MDR_STATS_DATA_YEAR_PUBLIC
});

export const getMDRTopDevicesPublic = () => ({
    type: GET_MDR_TOP_DEVICES_PUBLIC
});

export const getMDRTopPatientsPublic = () => ({
    type: GET_MDR_TOP_PATIENTS_PUBLIC
});

export const getMDRYearDataPublic = () => ({
    type: GET_MDR_YEAR_DATA_PUBLIC
});

export const getMDRMonthCountsPublic = () => ({
    type: GET_MDR_MONTH_COUNT_PUBLIC
});

export const getMDREventCountPublic = () => ({
    type: GET_MDR_EVENT_COUNT_PUBLIC
});

export const getMDRYearlyCountPublic = () => ({
    type: GET_MDR_YEARLY_COUNT_PUBLIC
});

export const getMDRMedicalSpecCountPublic = () => ({
    type: GET_MDR_MEDICAL_SPEC_COUNT_PUBLIC
});

export const getMDRMonthlyEventCountPublic = () => ({
    type: GET_MDR_MONTHLY_EVENT_COUNT_PUBLIC
});


export const setLoadingIndicatorDashboardPublic = (loading) => {
    // console.log(loading)
    return {
      type: SET_LOADING_INDICATOR_DASHBOARD_PUBLIC,
      payload: loading,
    };
  };
// import logo from './logo.svg';
import './decision-tree.css';
import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
// import { Tab, Nav } from 'react-bootstrap';
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
import MyTabs from './Sections/MyTabs';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
    Col,
    Row,
    Card,
    CardBody,
    Button,
    FormFeedback,
    CardTitle
  
  } from "reactstrap";
  import { useLocation } from 'react-router-dom';
import DeleteModal from 'components/Common/DeleteModal';
import DeactivateModal from 'components/Common/DeactivateModal';
import {deactivateModule as onDeactivateModule } from "store/actions";
import { Link , useNavigate} from "react-router-dom"
import Loader from "components/Common/Loader";

//redux
import { useSelector, useDispatch } from "react-redux";
import ActivateModal from 'components/Common/ActivateModal';

function DecisionTreeMain() {

  const location = useLocation();
  const childRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [activeLeftTab, setActiveLeftTab] = useState('ContentTab');
  const [activeRightTab, setActiveRightTab] = useState('Question');
  const [deleteModal, setDeleteModal] = useState(false);
  const [module, setModule] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [configError, setConfigError] = useState(null);


  const [moduleData, setModuleData] = useState('');

  const { error, loading, successMsg } = useSelector(state => ({
    error: state.DecisionTree.error,
    loading : state.DecisionTree.loading,
    successMsg : state.DecisionTree.successMsg
  }));

  useEffect(() => {
    setIsLoading(loading)
}, [loading]);

  const handleLeftTabChange = (tabKey) => {
    setActiveLeftTab(tabKey);
  };

  const handleRightTabChange = (tabKey) => {
    setActiveRightTab(tabKey);
  };

  useEffect(() => {
      if(location.state != null){
        setModuleData(location.state.state)
      }

      }, []);


      const onClickDelete = (module) => {
        setModule(module);
        setDeleteModal(true);
    };

    const handleDeleteModule = () => {
      // console.log(company)
      if (module && module.FLD_MODULEID) {
          dispatch(onDeactivateModule(module, navigate));
          setDeleteModal(false);
      }
  };
  
  const setPreviewError = (err) =>{
    setConfigError(err)
  }


  return (
    <div className="page-content">
       {moduleData.FLD_STATUS == 'Archived' ?
       <ActivateModal
       show={deleteModal}
       deleteMsg={'Are you sure you want to activate decision tree.'}
       onDeleteClick={handleDeleteModule}
       onCloseClick={() => setDeleteModal(false)}/>
       : <DeactivateModal
                show={deleteModal}
                deleteMsg={'Are you sure you want to deactivate decision tree.'}
                onDeleteClick={handleDeleteModule}
                onCloseClick={() => setDeleteModal(false)}
            /> }
        <div className="container-fluid">
        {/* <div className=''>
        <nav className='navbar navbar-expand-lg navbar-light bg-white shadow-md p-3'>
          <div className='container-fluid p-2'>
            <a className='navbar-brand text-primary mr-0'>Smarteeva Topbar</a>
            <div className='form-inline ml-auto'>
              <div className='btn btn-primary' >
                <i className='fa fa-bars'></i>
              </div>
            </div>
          </div>
        </nav>
      </div> */}
        {/* <div className='topbar shadow-md'>
          <div className='heading col-6'> */}
            {/* <Breadcrumb className='m-0'>
              <Breadcrumb.Item className='m-0' href="#">US Decision Tree Module</Breadcrumb.Item>
              <Breadcrumb.Item className='m-0' active>
                US Decision Tree 01
              </Breadcrumb.Item>
            </Breadcrumb> */}

<Breadcrumbs title="Decision Tree Config" breadcrumbItem="Decision Tree Builder" />

<Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom" >
                  <div className="d-flex align-items-center">
                    <Col>
                    <h5 className="mb-0 card-title flex-grow-1">{moduleData.FLD_NAME}</h5>
                    <h6 className="mb-0 flex-grow-1" style={{color : '#369BFF'}}>{moduleData.FLD_TYPE}</h6>
                    </Col>
                    <div className="flex-shrink-0" >
                    <Button color="primary" className='margin-right-top-dt' outline
                    onClick={() => childRef.current.onShowMainPreview()}
                    >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 25" fill="none">
                  <path d="M1 12.5C1 12.5 5 4.5 12 4.5C19 4.5 23 12.5 23 12.5C23 12.5 19 20.5 12 20.5C5 20.5 1 12.5 1 12.5Z" stroke="#319EFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z" stroke="#319EFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Button>

              <Button color="primary" outline className='margin-right-top-dt'
               onClick={() => {
                navigate(-1)
               }}
              >Back</Button>
             
              <Button color="primary" outline className='margin-right-top-dt'
               onClick={() => childRef.current.onDTSaveDraft()}
              >Save Draft</Button>


                                            <Button color={moduleData.FLD_STATUS == 'Archived' ? "success" : "danger"}  className='margin-right-top-dt'
                                            onClick={()=>{
                                              onClickDelete(moduleData);
                                            }}
                                            >{moduleData.FLD_STATUS == 'Archived' ? 'Activate' : 'Deactivate' }</Button>

                                            <Button color="primary" outline className='margin-right-top-dt'>Clone</Button>

                                            <Button color="primary" className="btn btn-primary waves-effect waves-light"
                                              onClick={() => childRef.current.onDTPublishData()}
                                            >Publish</Button>

                                           
             
                        </div>
                        </div></CardBody>
                        </Card>
                        </Col>
                        </Row>

           
            {/* <div className='heading-content'>
              Detailed Branching Builder
            </div> */}
          </div>
          {/* <div className='col-6'>
            <div className='row m-0 heading-button'>
            
              <button className="outline-button gray-button-outline eyebutn">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path d="M1 12.5C1 12.5 5 4.5 12 4.5C19 4.5 23 12.5 23 12.5C23 12.5 19 20.5 12 20.5C5 20.5 1 12.5 1 12.5Z" stroke="#319EFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z" stroke="#319EFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
              <button className="outline-button gray-button-outline">
                <div className="icon-text">Deactivate</div>
              </button>
              <button className="outline-button primary-button-outline">
                <div className="icon-text">Clone</div>
              </button>
              <button className="primary-button">
                <div className="icon-text">Publish</div>
              </button>
            </div>
          </div> */}
        {/* </div>
      </div> */}

<Row>
<FormFeedback type="invalid" style={{textAlign : 'center',
      marginTop : -10, fontSize : 12}}>{configError}</FormFeedback>
      {successMsg && <CardTitle type="success" style={{textAlign : 'center',color : '#34c38f', fontWeight : 500,
      marginTop : -10, fontSize : 12}}>{successMsg}</CardTitle>
      }
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom" style={{paddingLeft : 6}}>
                  <div className="d-flex align-items-center">
                  <div className="MyTabs-Container">
      {moduleData && <MyTabs 
        ref={childRef}
        moduleData={moduleData} 
        setPreviewError={setPreviewError}
        /> }
      </div>
                    </div>
                    </CardBody>
                    </Card>
                    </Col>
                </Row>
                <Loader isLoading={isLoading} /> 
    </div>
  );
}

export default DecisionTreeMain;

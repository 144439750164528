import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Link } from 'react-router-dom';

export const ExportToExcel = ({ apiData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const headerRow = ["Brand Name", "Catalog Number", "Date Received", "Device Name", "Device Report Product Code", "Event Description", "Event Type", "Generic Name", "Lot Number", "Manufacturer D Name", "MDR Report Key", "Medical Specialty", "MGF Narrative", "Model Number", "Premarket Approval & Premarket Notification Number", "Registration Number", "Report Number", "Submission Type", "Unclassified Reason"];
    XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: "A1" });
   

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    // <button onClick={(e) => exportToCSV(apiData, fileName)}>Export</button>
    <Link onClick={(e) => exportToCSV(apiData, fileName)} className="btn btn-light me-3"><i className="mdi mdi-download me-1"></i> Download Report </Link>
  );
};
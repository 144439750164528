import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainerMdr';
import * as Yup from "yup";
import { useFormik } from "formik";
import { jobs } from "../../../common/data";
import classnames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

// import {
//     getJobList as onGetJobList,
//     addNewJobList as onAddNewJobList,
//     updateJobList as onUpdateJobList,
//     deleteJobList as onDeleteJobList,
// } from "store/actions";

import {
  ColName
}
  from "./MdrListCol";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
//redux
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  FormGroup,
  Progress
} from "reactstrap";
import QueryBuilder from "../Smart-Query/QueryBuilder";

import {
  getSearchFieldList as onGetSearchFieldList,
  getMedicalSplList as onGetMedicalSplList,
  getDeviceProbCodeList as onGetDeviceProbCodeList,
  getPatientProbCodeList as onGetPatientProbCodeList,
  saveSmartQuery as onSaveSmartQuery,
  executeSmartQueryMdrList as onExecuteSmartQueryMdrList,
  resetQueryResult,
  getMdrList as onGetMdrList,
  getPatientProbCodeMdrList as onGetPatientProbCodeMdrList,
  resetQueryResultMdrList,
  abortSmartQueryMdrList,
  setLoadingIndicatorSmartQuery,
} from "store/actions";
import { authUser } from "helpers/dataConfig";
import moment from "moment";
import Loader from "components/Common/Loader";
import { ExportToExcel } from "components/Common/ExportExcel";
import Hourglass from "components/Common/Hourglass";
import AbortModal from "components/Common/AbortModal";
import GenerateChartsOffCanvas from "../Smart-Query/query result/generate-charts-offcanvas";

function MdrList() {

  //meta title
  document.title = "MDR List | Smarteeva";

  const dispatch = useDispatch();
  const navigate = useNavigate()


  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [jobsList, setJobsList] = useState([]);
  // const [job, setJob] = useState(null);
  const [mdrList, setMdrList] = useState([]);
  const [mdrListRef, setMdrListRef] = useState([]);



  const [isRight, setIsRight] = useState(false);

  const [searchableFieldList, setSearchableFieldList] = useState([]);

  const [medicalSplList, setMedicalSplList] = useState([]);
  const [deviceProblemCodeList, setDeviceProblemCodeList] = useState([]);
  const [patientProblemCodeList, setPatientProblemCodeList] = useState([]);
  const [queryType, setQueryType] = useState('');
  const [noDataModalVisible, setNoDataModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [groupEditData, setGroupEditData] = useState([]);
  const [queryDetails, setQueryDetails] = useState({});
  const [query, setQuery] = useState(null);

  const [toggleCanvas, setToggleCanvas] = useState(false);


  const [conditionList, setConditionList] = useState([
    { label: 'equal', value: '=' },
    { label: 'not equal', value: '!=' },
    { label: 'in', value: 'IN' },
    { label: 'not in', value: 'NOT IN' },
    { label: 'begins with', value: 'LIKE' },
    { label: "doesn't begin with", value: "NOT LIKE" },
    { label: "contains", value: "LIKE" },
    { label: "doesn't contain", value: "NOT LIKE" },
    { label: "ends with", value: "LIKE" },
    { label: "doesn't end with", value: "NOT LIKE" },
    { label: "is empty", value: "IS NULL" },
    { label: "is not empty", value: "IS NOT NULL" },
    { label: "is null", value: "IS NULL" },
    { label: "is not null", value: "IS NOT NULL" },
  ]);

  const [conditionList2, setConditionList2] = useState([
    { label: 'equal', value: '=' },
    { label: 'not equal', value: '!=' },
    { label: 'less than', value: '<' },
    { label: 'less than equal to', value: '<=' },
    { label: 'greater than', value: '>' },
    { label: 'greater than equal to', value: '>=' },
    { label: "is null", value: "IS NULL" },
    { label: "is not null", value: "IS NOT NULL" },
  ]);

  const [conditionList3, setConditionList3] = useState([
    { label: 'equal', value: '=' },
    { label: 'not equal', value: '!=' },
    { label: 'not equal', value: '!=' },
    { label: 'between', value: 'between' },
    { label: 'less than', value: '<' },
    { label: 'less than equal to', value: '<=' },
    { label: 'greater than', value: '>' },
    { label: 'greater than equal to', value: '>=' },
    { label: "is null", value: "IS NULL" },
    { label: "is not null", value: "IS NOT NULL" },
  ]);

  const [groupConditionList, setGroupConditionList] = useState([
    { label: 'AND', value: 'AND' },
    { label: 'OR', value: 'OR' }
  ]);



  const { mdr_list } = useSelector(state => ({
    mdr_list: state.SmartQueryReducer.mdr_list,
  }));

  const { search_fields } = useSelector(state => ({
    search_fields: state.SmartQueryReducer.search_fields,
  }));

  const { medical_spl } = useSelector(state => ({
    medical_spl: state.SmartQueryReducer.medical_spl,
  }));

  const { device_prob_code } = useSelector(state => ({
    device_prob_code: state.SmartQueryReducer.device_prob_code,
  }));

  const { patient_prob_code } = useSelector(state => ({
    patient_prob_code: state.SmartQueryReducer.patient_prob_code,
  }));

  const { query_result_mdrlist, query_result_mdrlist_ref } = useSelector(state => ({
    query_result_mdrlist: state.SmartQueryReducer.query_result_mdrlist,
    query_result_mdrlist_ref: state.SmartQueryReducer.query_result_mdrlist_ref,
  }));

  const { query_details_mdrlist } = useSelector(state => ({
    query_details_mdrlist: state.SmartQueryReducer.query_details_mdrlist,
  }));


  const { error, loading, query_result_no_data_mdrlist } = useSelector(state => ({
    error: state.SmartQueryReducer.error,
    loading: state.SmartQueryReducer.loading,
    query_result_no_data_mdrlist : state.SmartQueryReducer.query_result_no_data_mdrlist,
  }));


  const { abortStatusMdrList } = useSelector(state => ({
    abortStatusMdrList: state.SmartQueryReducer.abortStatusMdrList,
  }));

  useEffect(() => {
    setIsLoading(loading)
  }, [loading]);

  useEffect(() => {
    localStorage.removeItem('QueryDetailsList')
    // console.log('in main')
    if(!abortStatusMdrList){
    if (query_details_mdrlist.total_count == 0 || query_result_no_data_mdrlist) {
      setNoDataModal(true)
    } else {
    console.log(query_result_mdrlist)

      setMdrList(query_result_mdrlist)
      // toggleRightCanvas()
    }
  }
  }, [dispatch, query_result_mdrlist, query_details_mdrlist, query_result_no_data_mdrlist, abortStatusMdrList]);

  useEffect(() => {
    setMdrListRef(query_result_mdrlist_ref)
  },[query_result_mdrlist_ref])

  useEffect(() => {
// console.log('in details')
    if(!abortStatusMdrList){
    let ddata = JSON.parse(localStorage.getItem('QueryDetailsList'))
    // console.log(ddata)
    if(ddata != null){
      if (ddata.total_count > query_result_mdrlist.length) {
        setQueryDetails(ddata);
      }
    }else{
        if(query_details_mdrlist.total_count != undefined){
    
          // console.log(query_details_mdrlist)
            localStorage.setItem('QueryDetailsList',JSON.stringify(query_details_mdrlist))
            setQueryDetails(query_details_mdrlist);
        }
    }
  }
    
    }, [query_details_mdrlist, abortStatusMdrList]);

    useEffect(() => {
            // console.log(query_result_mdrlist)
            // console.log('in excute')
      if(!abortStatusMdrList){
      let ddata = JSON.parse(localStorage.getItem('QueryDetailsList'));
      // if(ddata !=null){
      // console.log(ddata)
      // console.log(query_result.length)
      // }
      if (ddata != null && ddata.total_count > query_result_mdrlist.length) {
          const execute = {
              query_string: ddata.query_string,
              off_set: query_result_mdrlist.length
          };
          dispatch(onExecuteSmartQueryMdrList(execute, navigate, ddata, 'result'));
        }
      }
  
      return () => {
          // This is the cleanup logic.
          // You can leave it empty if you don't have any cleanup tasks.
          // Or you can add cleanup tasks here if needed.
      };
  }, [dispatch, query_result_mdrlist, abortStatusMdrList]);

  useEffect(() => {
    // console.log(search_fields)
    if (search_fields && !search_fields.length) {
      // console.log(recent_query)
      dispatch(onGetSearchFieldList());
    }
  }, [dispatch, search_fields]);




  useEffect(() => {
    // console.log(recent_query.length)
    if (medical_spl && !medical_spl.length) {
      // console.log(recent_query)
      dispatch(onGetMedicalSplList());
    }
  }, [dispatch, medical_spl]);


  useEffect(() => {
    setMedicalSplList(medical_spl);
    // console.log(recent_query)
  }, [medical_spl]);


  useEffect(() => {
    // console.log(recent_query.length)
    if (device_prob_code && !device_prob_code.length) {
      // console.log(recent_query)
      dispatch(onGetDeviceProbCodeList());
    }
  }, [dispatch, device_prob_code]);


  useEffect(() => {
    setDeviceProblemCodeList(device_prob_code);
    // console.log(recent_query)
  }, [device_prob_code]);


  useEffect(() => {
    // console.log(recent_query.length)
    if (patient_prob_code && !patient_prob_code.length) {
      // console.log(recent_query)
      dispatch(onGetPatientProbCodeMdrList());
    }
  }, [dispatch, patient_prob_code]);


  useEffect(() => {
    setPatientProblemCodeList(patient_prob_code);
    // console.log(recent_query)
  }, [patient_prob_code]);


  useEffect(() => {
    // console.log(query_details.total_count == undefined)
    if(queryDetails.total_count == undefined){
    if (mdr_list && !mdr_list.length) {
      dispatch(onGetMdrList({
        off_set: 0
      }, navigate));
    }
  }
  }, [dispatch, mdr_list]);

  useEffect(() => {
    setSearchableFieldList(search_fields);
  }, [search_fields]);

  useEffect(() => {
    // const half = Math.ceil(mdr_list.length / 2);  
    // let arr1 = mdr_list.slice(0, half)
    // setMdrList(arr1);
    // setTimeout(
    //   () => 
    //   {
    //     setMdrList([...arr1, mdr_list.slice(half)]);
    //   }, 
    //   3000
    // );
    if(!mdrList.length){
    setMdrList(mdr_list);
    }
    // if(mdr_list){
    //   setIsLoading(false)
    // }
  }, [mdr_list]);

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
    validation.setFieldValue('title', '')
    validation.setFieldValue('description', '')
    validation.setFieldValue('querylogic', '')
    validation.setFieldValue('displayquerylogic', '')


  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: '',
      description: '',
      querylogic: '',
      displayquerylogic: '',
      groupData : ''

    },
    validationSchema: Yup.object({
      querylogic: Yup.string().required("Please Create Query Logic")
    }),
    onSubmit: (values) => {

      localStorage.removeItem('QueryDetailsList')
      dispatch(abortSmartQueryMdrList(false));

      if (queryType == 'Save') {

        const query = {
          title: values.title,
          description: values.description,
          query_string: values.querylogic,
          display_query_string: values.displayquerylogic,
          userid: JSON.parse(localStorage.getItem(authUser)).fld_userid,
          type: 'Save',
          createdby: JSON.parse(localStorage.getItem(authUser)).fld_userid,
          createdon: moment().format(),
          groupdata : values.groupData
        }
        // console.log(values)
        dispatch(onSaveSmartQuery(query, navigate));
        toggleRightCanvas()

      } else {

        
        const query = {
          title: values.title,
          description: values.description,
          query_string: values.querylogic,
          display_query_string: values.displayquerylogic,
          userid: JSON.parse(localStorage.getItem(authUser)).fld_userid,
          type: 'Recent',
          createdby: JSON.parse(localStorage.getItem(authUser)).fld_userid,
          createdon: moment().format(),
          groupdata : values.groupData
        }
        // console.log(values)


        const execute = {
          query_string: values.querylogic,
          off_set: 0
        }

        dispatch(resetQueryResultMdrList());
        dispatch(onExecuteSmartQueryMdrList(execute, navigate, query, 'mdrlist'));
        dispatch(onSaveSmartQuery(query, navigate));
        setQueryDetails([])
        toggleRightCanvas()

      }



    },
  });


  const handleDeletejob = () => {
    if (job && job.id) {
      dispatch(onDeleteJobList(job.id));
      setDeleteModal(false);
    }
  };
  const handleJobClicks = () => {
    setJobsList("");
    setIsEdit(false);
    toggle();
  };

  // const { jobs } = useSelector(state => ({
  //     jobs: state.JobReducer.jobs,
  // }));

  // useEffect(() => {z
  //     if (jobs && !jobs.length) {
  //         dispatch(onGetJobList());
  //     }
  // }, [dispatch, jobs]);

  // useEffect(() => {
  //     setJobsList(jobs);
  // }, [jobs]);

  // useEffect(() => {
  //     if (!isEmpty(jobs) && !!isEdit) {
  //         setJobsList(jobs);
  //         setIsEdit(false);
  //     }
  // }, [jobs]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setJob(null);
    } else {
      setModal(true);
    }
  };


  //delete Job
  const [deleteModal, setDeleteModal] = useState(false);
  const [abortModal, setAbortModal] = useState(false);

 
  

  const columns = useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link
                  to={'/query_details'} state={{ state: { ...cellProps.row.original } }}
                  className="btn btn-sm btn-soft-primary">
                  <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
              </li>
              <UncontrolledTooltip placement="top" target="viewtooltip">
                View
              </UncontrolledTooltip>


            </ul>
          );
        }
      },
      {
        Header: 'MDR Report Key',
        accessor: 'MDR_REPORT_KEY',
        // searchable : true,
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'Report Number',
        accessor: 'REPORT_NUMBER',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'Registration No.',
        accessor: 'REGISTRATION_NO',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'Event Type',
        accessor: 'EVENT_TYPE',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'PMA_PMN_NUM',
        accessor: 'PMA_PMN_NUM',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'Date Received',
        accessor: 'DATE_RECEIVED',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'Manufacturer Device Name',
        accessor: 'MANUFACTURER_D_NAME',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'Model Number',
        accessor: 'MODEL_NUMBER',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'Lot Number',
        accessor: 'LOT_NUMBER',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'Catalog Number',
        accessor: 'CATALOG_NUMBER',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'Brand Name',
        accessor: 'BRAND_NAME',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'Generic Name',
        accessor: 'GENERIC_NAME',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      }, {
        Header: 'Device Report Product Code',
        accessor: 'DEVICE_REPORT_PRODUCT_CODE',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      // {
      //     Header: 'Event Description',
      //     accessor: 'EVENT_DESCRIPTION',
      //     filterable: true,
      //     Cell: (cellProps) => {
      //         return <ColName {...cellProps} />;
      //     }
      // }, 
      {
        Header: 'Device Name',
        accessor: 'DEVICENAME',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'Submission Type',
        accessor: 'SUBMISSION_TYPE',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'Unclassified Reason',
        accessor: 'UNCLASSIFIED_REASON',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },
      {
        Header: 'Medical Specialty',
        accessor: 'MEDICAL_SPECIALTY',
        filterable: true,
        Cell: (cellProps) => {
          return <ColName {...cellProps} />;
        }
      },


    ],
    []
  );


  const handleLogic = (logic) => {
    // console.log(logic)
    validation.setFieldValue('querylogic', logic)

  }

  const handleDisplayLogic = (logic) => {
    validation.setFieldValue('displayquerylogic', logic)
    validation.setFieldValue('title', logic)
    validation.setFieldValue('description', logic)

  }

  const handleQueryGroup = (grp) =>{
    validation.setFieldValue('groupData', JSON.stringify(grp))
  }

  const NoDataModal = () => {

    return (
      <Modal
        isOpen={noDataModalVisible}
        toggle={() => {
          setNoDataModal(!noDataModalVisible);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            No Data!
          </h5>
          <button
            type="button"
            onClick={() => {
              setNoDataModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Data not Present</h5>
          <p>
            The data you're trying to fetch from smart query is not present.
          </p>

        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setNoDataModal(false)
            }}
            className="btn btn-primary"
            data-dismiss="modal"
          >
            Close
          </button>

        </div>
      </Modal>
    )
  }

  const handleAbortQuery= () => {
    // localStorage.removeItem('QueryDetails')
    dispatch(abortSmartQueryMdrList(true));
    setAbortModal(false)

    dispatch(setLoadingIndicatorSmartQuery(true))
        setTimeout(
            () => 
            {
                dispatch(setLoadingIndicatorSmartQuery(false))
            }, 
            3000
          );

  };

  const toggleChartCanvas = () => {
  //  console.log('hii')
    setToggleCanvas(!toggleCanvas)

  };


  // useEffect(() => {

  // }, [toggleCanvas]);
  

  return (
    <React.Fragment>
      {NoDataModal()}

     <AbortModal
                show={abortModal}
                abortMsg={'Are you sure you want to abort the query?'}
                onAbortClick={handleAbortQuery}
                onCloseClick={() => setAbortModal(false)}
            /> 
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletejob}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="MDR" breadcrumbItem="MDR List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">Medical Device Report List</h5>
                    <div className="flex-shrink-0">
                      {/* <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-3"><i className="mdi mdi-plus"></i> Add New MDR</Link> */}
                      {/* <Link to="#!" className="btn btn-light me-3"><i className="mdi mdi-refresh me-1"></i> Reset Filters </Link> */}
                      {/* <Col xxl={2} lg={4}>
                <div className='mb-3 mb-xxl-0'> */}
                    <button 
                    style={{display : queryDetails.total_count == undefined ? 'none' : ''}}
                    type="button" className="btn btn-primary me-3" 
                    onClick={()=>{
                      // setToggleCanvas(true)
                      toggleChartCanvas()
                      // navigate('/generate-charts', {state : mdrList})
                    }}
                    >
                      Generate Chart</button>
                {/* </div>
            </Col> */}

<ExportToExcel apiData={mdrList} fileName={'MDR Report'} />



{/* {console.log(queryDetails)} */}

                      {/* <Link to="#!" className="btn btn-light me-3"><i className="mdi mdi-download me-1"></i> Download Report </Link> */}
                      {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                <DropdownToggle type="menu" className="btn btn-light" id="dropdownMenuButton1">
                                                    <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                                <DropdownMenu>
                                                    <li><DropdownItem href="#">Action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                </DropdownMenu>
                                            </UncontrolledDropdown> */}


                    </div>
                                           
                    {queryDetails.total_count != undefined && mdrList.length < queryDetails.total_count?        <button type="button" className="btn btn-danger flex-center" 
                    onClick={()=>{
                      setAbortModal(true)
                      // localStorage.removeItem('QueryDetails')
                  }}
                    >
                        <i className="mdi mdi-cancel font-size-10 pr-07-icon" />
                      Abort</button> : null}
                    
                  </div>

                  {/* <h6 className="mb-0 flex-grow-1">{queryDetails.title}</h6> */}
                  <h6 className="mb-0 flex-grow-1">{queryDetails.display_query_string}</h6>


{/* {console.log(Math.ceil((mdrList.length/queryDetails.total_count)*100))} */}


                  {queryDetails.total_count != undefined && mdrList.length < queryDetails.total_count && !abortStatusMdrList ? 
<Col lg="4" style={{float : 'right', marginTop : 20}}>
                                    {/* <Progress color="success" 
                                    striped
                                    animated
                                     className="progress-lg"
                                    value={Math.ceil((mdrList.length/queryDetails.total_count)*100)}
                                    >
                      {Math.ceil((mdrList.length/queryDetails.total_count)*100)} %
                    </Progress> */}

<Row>
                                   <Hourglass />
                                    <Progress color="success" 
                                    striped
                                    animated
                                    style={{margin : 'auto', paddingLeft : 0}}
                                    className="progress-lg col-lg-10"
                                    value={Math.ceil((mdrList.length/queryDetails.total_count)*100) < 10 ? 10 : Math.ceil((mdrList.length/queryDetails.total_count)*100)}
                                    >
                      {Math.ceil((mdrList.length/queryDetails.total_count)*100)} %
                    </Progress>
                    <h6 style={{textAlign : 'end'}}>({mdrList.length} records out of {queryDetails.total_count})</h6>
                    </Row>
                  
                    </Col>
                    : null}
                </CardBody>
                <CardBody>
                  {/* {console.log(mdrList)} */}
                  <TableContainer
                    columns={columns}
                    // refData={mdrList}
                    data={mdrList}
                    // mdrdata={mdrListRef.length <= 0 ? mdrList : mdrListRef}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    handleJobClicks={handleJobClicks}
                    isMdrListGlobalFilter={true}
                    customPageSize={10}
                    openRightModal={toggleRightCanvas}
                    totalCount = {queryDetails.total_count == undefined ? 100 : queryDetails.total_count}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}>
            <OffcanvasHeader toggle={toggleRightCanvas}>
              Generate Query
            </OffcanvasHeader>
            <OffcanvasBody>
              <div className="w-100 h-80vh">
                <Card className="h-96">
                  <div className="p-4 border-bottom ">
                    <Row>
                      <Col md="12" xs="12">
                        <Col md="12" xs="12">
                          <Row className="flex-center">
                            <Col md="12" xs="12" className="mb-3">
                              {/* <h4 className="m-0">
                                  Query Name 
                                </h4> */}
                              <FormGroup className="mb-3">
                                <Label>
                                  Query Title
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter query title"
                                  name="title"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.title || ""}
                                  invalid={
                                    validation.touched.title && validation.errors.title ? true : false
                                  }
                                />
                                {validation.touched.title && validation.errors.title ? (
                                  <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                ) : null}
                              </FormGroup>

                            </Col>
                            {/* <Col md="6" xs="12" className="mb-3 flex-end">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-outline-primary flex-center"  
                                  onClick={() => {
                                    setQueryType('Recent')
                                    validation.handleSubmit();
                                  }}>
                                    <i className="mdi mdi-play font-size-20 pr-07-icon" />
                                    <span className="pl-7-text">Run Query</span></button>
                            
                                  <button type="button" className="btn btn-outline-primary flex-center"
                                  onClick={() => {
                                    setQueryType('Save')
                                    validation.handleSubmit();
                                  }}
                                  >
                                    <i className="mdi mdi-content-save font-size-20 pr-07-icon" />
                                    <span className="pl-7-text">Save</span></button>
                                  <button type="button" className="btn btn-outline-primary flex-center"
                                  
                                  >
                                    <i className="mdi mdi-delete font-size-20 pr-07-icon" />
                                    <span className="pl-7-text">Delete</span></button>
                                </div>
                              </Col> */}
                          </Row>
                        </Col>
                        <Col md="12" xs="12">
                          {/* <p className="text-muted mb-0">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sed ornare quam, a efficitur purus. Mauris tempor eleifend sapien non pellentesque.
                            </p> */}
                          <FormGroup className="mb-3">
                            <Label>
                              Description
                            </Label>
                            <Input
                              type="textarea"
                              rows="3"
                              className="form-control"
                              placeholder="Enter query description"
                              name="description"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.description || ""}
                              invalid={
                                validation.touched.description && validation.errors.description ? true : false
                              }
                            />
                            {validation.touched.description && validation.errors.description ? (
                              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                            ) : null}
                          </FormGroup>

                          {validation.touched.querylogic && validation.errors.querylogic ? (
                            <FormFeedback type="invalid" style={{ textAlign: 'center' }}>{validation.errors.querylogic}</FormFeedback>
                          ) : null}
                        </Col>
                      </Col>
                    </Row>
                  </div>

                  <div className="m-4">
                    <Col md="12" xs="12">
                      <div id="query-builder-container" className="sideSection">
                        <QueryBuilder
                          searchableFieldList={searchableFieldList}
                          conditionList={conditionList}
                          conditionList2={conditionList2}
                          conditionList3={conditionList3}
                          groupConditionList={groupConditionList}
                          medicalSplList={medicalSplList}
                          deviceProblemCodeList={deviceProblemCodeList}
                          patientProblemCodeList={patientProblemCodeList}
                          handleLogic={handleLogic}
                          handleDisplayLogic={handleDisplayLogic}
                          handleQueryGroup={handleQueryGroup}
                          groupEditData={groupEditData}
                        />
                      </div>
                    </Col>
                  </div>

                  <Col md="12" xs="12" className="mb-3 flex-end">
                    <div className="btn-group buttongroupCorrection">
                      <button type="button" className="btn btn-outline-primary flex-center"
                        onClick={() => {
                          setQueryType('Recent')
                          validation.handleSubmit();
                        }}>
                        <i className="mdi mdi-play font-size-20 pr-07-icon" />
                        <span className="pl-7-text">Run Query</span></button>

                      <button type="button" className="btn btn-outline-primary flex-center"
                        onClick={() => {
                          setQueryType('Save')
                          validation.handleSubmit();
                        }}
                      >
                        <i className="mdi mdi-content-save font-size-20 pr-07-icon" />
                        <span className="pl-7-text">Save</span></button>
                      {/* <button type="button" className="btn btn-outline-primary flex-center"
                                  
                                  >
                                    <i className="mdi mdi-delete font-size-20 pr-07-icon" />
                                    <span className="pl-7-text">Delete</span></button> */}
                    </div>
                  </Col>


                </Card>
              </div>
            </OffcanvasBody>
          </Offcanvas>


          <GenerateChartsOffCanvas isRight={toggleCanvas} toggleChartCanvas={toggleChartCanvas} mdrData={mdrList} queryData={query_details_mdrlist}/>

          {/* <Modal isOpen={modal} toggle={toggle} className="model-width80">
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit MDR" : "Add MDR"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12 row m-0">
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Report Number</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Event Type</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Date Received</Label>
                                            <InputGroup className="border-rad">
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    placeholder=""
                                                    options={{
                                                        altInput: true,
                                                        altFormat: "F j, Y",
                                                        dateFormat: "Y-m-d"
                                                    }}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Manufacturer Name</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Model Number</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Lot Number</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Catalog Number</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Brand Name</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Generic Name</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Product Code</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-12 col-sm-12">
                                            <Label className="form-label">Event Description</Label>
                                            <Input
                                                type="textarea"
                                                placeholder=""
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mx-3">
                                        <div className="text-end mt-4">
                                            <button type="submit" className="btn btn-primary px-3">
                                                Submit MDR for Approval
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal> */}
        </div>
      </div>
      <Loader isLoading={isLoading} />

    </React.Fragment>
  );
}


export default MdrList;
import React, {useState, useRef, useEffect} from 'react';
import { FormControl, Button } from 'react-bootstrap';
// import '@fortawesome/fontawesome-free'; // Include Font Awesome CSS

import {
Input,
Col,
Label,
InputGroup,
Row
} from "reactstrap";
import { Link } from 'react-router-dom';

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

function Middlesection(props) {

const [counter, setCounter] = useState(0)

useEffect(() => {

}, [counter]);

  const addInputField = (event) => {
    // Your addInputField function logic here
    props.onAddQuestionDataChoice()
  };

  const showPreviousQuestion = () => {
    // Your showPreviousQuestion function logic here
  };

  const showNextQuestion = () => {
    // Your showNextQuestion function logic here
  };


  return (
    <main className="content" style={{marginTop : 30}}>
      <div className="content-container">
        <div className="edit-questions-container">
          <div id="editQuestions1" className="questions" style={{ display: 'block' }}>
            <div className="questionCard" >
              <div className={props.selectedQuestion.required ?"question required" : "question"}>{props.selectedQuestion.num}. {props.selectedQuestion.questionText}</div>
    <div className="input-wrapper">
    {props.renderFieldView()}
    </div>
    

    <div className="input-wrapper" style={{display : props.selectedQuestion.showcomments ? '' : 'none'}}>
    <Label className={props.selectedQuestion.commentsrequired ? "form-label required" : "form-label"} style={{marginBottom :0}}>Comments</Label>
    <Input
      disabled
      name="key"
      type="textarea"
      rows={5}
      placeholder="" />
    </div>

              {/* <div className="input-wrapper"> */}
                {/* Js appends choices here */}
                {/* <InputGroup className="input-container">
                  <FormControl type="text" className="input-field" placeholder="" />
                  <div className='input-edit'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M15 5L5 15" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 5L15 15" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg></div>
                </InputGroup> */}
              {/* </div> */}
              {/* <div className="add-choice-container">
                <button className="add-choice-button" onClick={addInputField} style={{ cursor: 'pointer' }}>
                  <span className='choosemr-3'>Add Choice</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 4.16666V15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.16666 10H15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </div> */}
            </div>
          </div>
          {/* Add similar sections for editQuestions2 and editQuestions3 */}
        </div>
        <div className="card-navigator" style={{ display: 'flex' }}>
          <div className={props.questionData.indexOf(props.selectedQuestion) > 0 ? "previous active" : "previous disabled" } id="previousButton" onClick={props.onShowPreviousQuestion}>
            <span style={{ marginRight: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M15.5 28C22.4036 28 28 22.4036 28 15.5C28 8.59644 22.4036 3 15.5 3C8.59644 3 3 8.59644 3 15.5C3 22.4036 8.59644 28 15.5 28Z" stroke={props.questionData.indexOf(props.selectedQuestion) > 0 ? '#369bFF' : "#ADB5BD"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15 10.3333L10 15.5L15 20.6667" stroke={props.questionData.indexOf(props.selectedQuestion) > 0 ? '#369bFF' : "#ADB5BD"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M20 15.5H10" stroke={props.questionData.indexOf(props.selectedQuestion) > 0 ? '#369bFF' : "#ADB5BD"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg></span>
            <span className='correctsubmit'>Previous</span>
          </div>
          <div className={props.questionData.indexOf(props.selectedQuestion) < props.questionData.length-1 ? "next active" : "next disabled" } onClick={props.onShowNextQuestion}>
            <span style={{ marginRight: '10px' }} className='correctsubmit' id="submitButton">Next</span>
            <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M15 28C21.9036 28 27.5 22.4036 27.5 15.5C27.5 8.59644 21.9036 3 15 3C8.09644 3 2.5 8.59644 2.5 15.5C2.5 22.4036 8.09644 28 15 28Z" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15 20.5L20 15.5L15 10.5" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10 15.5H20" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg></span>
          </div>
        </div>
      </div>
      <div id="editmodule" className="editModule" style={{ display: 'none' }}>
        {/* The rest of your code goes here */}
      </div>
    </main>
  );
}

export default Middlesection;

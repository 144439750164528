import {
  GET_COMPANY_LIST,
  GET_COMPANY_LIST_FAIL,
  GET_COMPANY_LIST_SUCCESS,
  ADD_NEW_COMPANY,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  GET_PHONECODE_LIST,
  GET_PHONECODE_LIST_SUCCESS,
  GET_PHONECODE_LIST_FAIL,

  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_FAIL,

  GET_STATE_LIST,
  GET_STATE_LIST_SUCCESS,
  GET_STATE_LIST_FAIL,

  GET_CITY_LIST,
  GET_CITY_LIST_SUCCESS,
  GET_CITY_LIST_FAIL,
  GET_BILLING_STATE_LIST,
  GET_BILLING_STATE_LIST_FAIL,
  GET_BILLING_STATE_LIST_SUCCESS,
  GET_BILLING_CITY_LIST,
  GET_BILLING_CITY_LIST_SUCCESS,
  GET_BILLING_CITY_LIST_FAIL,
  GET_COMPANY_DETAILS,
  GET_COMPANY_DETAILS_SUCCESS,
  GET_COMPANY_DETAILS_FAIL,
  GET_COMPANY_CP_SUCCESS,
  APPROVE_COMPANY,
  APPROVE_COMPANY_SUCCESS,
  APPROVE_COMPANY_FAIL,
  SET_LOADING_INDICATOR_COMPANY
} from "./actionTypes";

export const getCompanyList = () => ({
  type: GET_COMPANY_LIST,
})

export const getCompanyListSuccess = company => ({
  type: GET_COMPANY_LIST_SUCCESS,
  payload: company,
})

export const getCompanyListFail = error => ({
  type: GET_COMPANY_LIST_FAIL,
  payload: error,
})

export const getPhonecodeList = () => ({
  type: GET_PHONECODE_LIST,
})

export const getPhonecodeListSuccess = phonecode => ({
  type: GET_PHONECODE_LIST_SUCCESS,
  payload: phonecode,
})

export const getPhonecodeListFail = error => ({
  type: GET_PHONECODE_LIST_FAIL,
  payload: error,
})

export const addNewCompany = (data, history) => ({
  type: ADD_NEW_COMPANY,
  payload: {data, history},
})

export const addCompanySuccess = company => ({
  type: ADD_COMPANY_SUCCESS,
  payload: company,
})

export const addCompanyFail = error => ({
  type: ADD_COMPANY_FAIL,
  payload: error,
})

export const updateCompany = (company, history) => ({
  type: UPDATE_COMPANY,
  payload: {company, history},
})

export const updateCompanySuccess = company => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: company,
})

export const updateCompanyFail = error => ({
  type: UPDATE_COMPANY_FAIL,
  payload: error,
})

export const deleteCompany = data => ({
  type: DELETE_COMPANY,
  payload: data,
})

export const deleteCompanySuccess = data => ({
  type: DELETE_COMPANY_SUCCESS,
  payload: data,
})

export const deleteCompanyFail = error => ({
  type: DELETE_COMPANY_FAIL,
  payload: error,
})


export const getCountryList = () => ({
  type: GET_COUNTRY_LIST,
})

export const getCountryListSuccess = country => ({
  type: GET_COUNTRY_LIST_SUCCESS,
  payload: country,
})

export const getCountryListFail = error => ({
  type: GET_COUNTRY_LIST_FAIL,
  payload: error,
})


export const getStateList = states => ({
  type: GET_STATE_LIST,
  payload: states,
})

export const getStateListSuccess = states => ({
  type: GET_STATE_LIST_SUCCESS,
  payload: states,
})

export const getStateListFail = error => ({
  type: GET_STATE_LIST_FAIL,
  payload: error,
})


export const getCityList = city => ({
  type: GET_CITY_LIST,
  payload: city,
})

export const getCityListSuccess = city => ({
  type: GET_CITY_LIST_SUCCESS,
  payload: city,
})


export const getCityListFail = error => ({
  type: GET_CITY_LIST_FAIL,
  payload: error,
})



export const getBillingStateList = billingstates => ({
  type: GET_BILLING_STATE_LIST,
  payload: billingstates,
})

export const getBillingStateListSuccess = billingstates => ({
  type: GET_BILLING_STATE_LIST_SUCCESS,
  payload: billingstates,
})

export const getBillingStateListFail = error => ({
  type: GET_BILLING_STATE_LIST_FAIL,
  payload: error,
})


export const getBillingCityList = billingcity => ({
  type: GET_BILLING_CITY_LIST,
  payload: billingcity,
})

export const getBillingCityListSuccess = billingcity => ({
  type: GET_BILLING_CITY_LIST_SUCCESS,
  payload: billingcity,
})


export const getBillingCityListFail = error => ({
  type: GET_BILLING_CITY_LIST_FAIL,
  payload: error,
})



export const getCompanyDetails= companydetails => ({
  type: GET_COMPANY_DETAILS,
  payload: companydetails,
})

export const getCompanyDetailsSuccess = companydetails => ({
  type: GET_COMPANY_DETAILS_SUCCESS,
  payload: companydetails,
})

export const getCompanyDetailsFail = error => ({
  type: GET_COMPANY_DETAILS_FAIL,
  payload: error,
})


export const getCompanyCpSuccess = contactperson => ({
  type: GET_COMPANY_CP_SUCCESS,
  payload: contactperson,
})


export const approveCompany = (data, history) => ({
  type: APPROVE_COMPANY,
  payload: {data, history}
})

export const approveCompanySuccess = data => ({
  type: APPROVE_COMPANY_SUCCESS,
  payload: data,
})

export const approveCompanyFail = error => ({
  type: APPROVE_COMPANY_FAIL,
  payload: error,
})

export const setLoadingIndicatorCompany = (loading) => {
  // console.log(loading)
  return {
    type: SET_LOADING_INDICATOR_COMPANY,
    payload: loading,
  };
};
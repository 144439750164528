export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";

export const GET_PROFILE = "GET_PROFILE"
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS"
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL"

export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL"


export const GET_PHONECODE_PROFILE = "GET_PHONECODE_PROFILE"
export const GET_PHONECODE_PROFILE_FAIL = "GET_PHONECODE_PROFILE_FAIL"
export const GET_PHONECODE_PROFILE_SUCCESS = "GET_PHONECODE_PROFILE_SUCCESS"

export const GET_COUNTRY_PROFILE = "GET_COUNTRY_PROFILE"
export const GET_COUNTRY_PROFILE_FAIL = "GET_COUNTRY_PROFILE_FAIL"
export const GET_COUNTRY_PROFILE_SUCCESS = "GET_COUNTRY_PROFILE_SUCCESS"

export const GET_STATE_PROFILE = "GET_STATE_PROFILE"
export const GET_STATE_PROFILE_FAIL = "GET_STATE_PROFILE_FAIL"
export const GET_STATE_PROFILE_SUCCESS = "GET_STATE_PROFILE_SUCCESS"

export const GET_CITY_PROFILE = "GET_CITY_PROFILE"
export const GET_CITY_PROFILE_FAIL = "GET_CITY_PROFILE_FAIL"
export const GET_CITY_PROFILE_SUCCESS = "GET_CITY_PROFILE_SUCCESS"


export const GET_DEPARTMENT_PROFILE = "GET_DEPARTMENT_PROFILE"
export const GET_DEPARTMENT_PROFILE_FAIL = "GET_DEPARTMENT_PROFILE_FAIL"
export const GET_DEPARTMENT_PROFILE_SUCCESS = "GET_DEPARTMENT_PROFILE_SUCCESS"

export const GET_DESIGNATION_PROFILE = "GET_DESIGNATION_PROFILE"
export const GET_DESIGNATION_PROFILE_FAIL = "GET_DESIGNATION_PROFILE_FAIL"
export const GET_DESIGNATION_PROFILE_SUCCESS = "GET_DESIGNATION_PROFILE_SUCCESS"

export const SET_LOADING_INDICATOR_PROFILE = "SET_LOADING_INDICATOR_PROFILE"
import {
  ABORT_GLOBAL_SEARCH_RESULT,
  ABORT_SMART_QUERY,
  ABORT_SMART_QUERY_MDR_LIST,
  EXECUTE_SMART_QUERY,
  EXECUTE_SMART_QUERY_FAIL,
  EXECUTE_SMART_QUERY_MDR_LIST,
  EXECUTE_SMART_QUERY_MDR_LIST_FAIL,
  EXECUTE_SMART_QUERY_MDR_LIST_SUCCESS,
  EXECUTE_SMART_QUERY_NO_DATA,
  EXECUTE_SMART_QUERY_RESULT_NO_DATA,
  EXECUTE_SMART_QUERY_RESULT_NO_DATA_MDRLIST,
  EXECUTE_SMART_QUERY_SUCCESS,
  GET_DEVICE_FIELD_CHART_SMART_SUCCESS,
  GET_DEVICE_PROB_CODE_LIST,
  GET_DEVICE_PROB_CODE_LIST_FAIL,
  GET_DEVICE_PROB_CODE_LIST_SUCCESS,
  GET_EVENT_FIELD_CHART_SMART_SUCCESS,
  GET_FIELD_CHART_SMART,
  GET_FIELD_CHART_SMART_FAIL,
  GET_MANUFACTURER_FIELD_CHART_SMART_SUCCESS,
  GET_MDR_INITIAL_LIST,
  GET_MDR_INITIAL_LIST_FAIL,
  GET_MDR_INITIAL_LIST_SUCCESS,
  GET_MEDICAL_SPL_LIST,
  GET_MEDICAL_SPL_LIST_FAIL,
  GET_MEDICAL_SPL_LIST_SUCCESS,
  GET_MONTHLY_CHART_SMART,
  GET_MONTHLY_CHART_SMART_FAIL,
  GET_MONTHLY_CHART_SMART_SUCCESS,
  GET_PATIENT_PROB_CODE_LIST,
  GET_PATIENT_PROB_CODE_LIST_FAIL,
  GET_PATIENT_PROB_CODE_LIST_SUCCESS,
  GET_PATIENT_PROB_CODE_MDR_LIST,
  GET_PATIENT_PROB_CODE_MDR_LIST_FAIL,
  GET_PATIENT_PROB_CODE_MDR_LIST_SUCCESS,
  GET_REASON_FIELD_CHART_SMART_SUCCESS,
  GET_RECENT_LIST,
  GET_RECENT_LIST_FAIL,
  GET_RECENT_LIST_SUCCESS,
  GET_SAVED_LIST,
  GET_SAVED_LIST_FAIL,
  GET_SAVED_LIST_SUCCESS,
  GET_SEARCH_FIELD_LIST,
  GET_SEARCH_FIELD_LIST_FAIL,
  GET_SEARCH_FIELD_LIST_SUCCESS,
  GET_SPECIALTY_FIELD_CHART_SMART_SUCCESS,
  GET_SUBMISSION_FIELD_CHART_SMART_SUCCESS,
  GET_YEAR_CHART_SMART_SUCCESS,
  GLOBAL_SEARCH_RESULT_MDR_LIST,
  GLOBAL_SEARCH_RESULT_MDR_LIST_FAIL,
  GLOBAL_SEARCH_RESULT_MDR_LIST_SUCCESS,
  GLOBAL_SEARCH_RESULT_NO_DATA,
  RESET_GLOBAL_SEARCH,
  RESET_QUERY_RESULT,
  RESET_QUERY_RESULT_MDR_LIST,
  SAVE_SMART_QUERY,
  SAVE_SMART_QUERY_FAIL,
  SAVE_SMART_QUERY_SUCCESS,
  SET_GLOBAL_SEARCH_RESULT_DETAILS,
  SET_LOADING_INDICATOR_SMART_QUERY,
  SET_SMART_QUERY_DETAILS,
  SET_SMART_QUERY_MDR_LIST_DETAILS
} from "./actionTypes";

export const getRecentList = () => ({
  type: GET_RECENT_LIST,
})

export const getRecentListSuccess = recent_query => ({
  type: GET_RECENT_LIST_SUCCESS,
  payload: recent_query,
})

export const getRecentListFail = error => ({
  type: GET_RECENT_LIST_FAIL,
  payload: error,
})


export const getSavedList = () => ({
  type: GET_SAVED_LIST,
})

export const getSavedListSuccess = saved_query => ({
  type: GET_SAVED_LIST_SUCCESS,
  payload: saved_query,
})

export const getSavedListFail = error => ({
  type: GET_SAVED_LIST_FAIL,
  payload: error,
})

export const getSearchFieldList = () => ({
  type: GET_SEARCH_FIELD_LIST,
})

export const getSearchFieldListSuccess = search_fields => ({
  type: GET_SEARCH_FIELD_LIST_SUCCESS,
  payload: search_fields,
})

export const getSearchFieldListFail = error => ({
  type: GET_SEARCH_FIELD_LIST_FAIL,
  payload: error,
})


export const getMedicalSplList = () => ({
  type: GET_MEDICAL_SPL_LIST,
})

export const getMedicalSplListSuccess = medical_spl => ({
  type: GET_MEDICAL_SPL_LIST_SUCCESS,
  payload: medical_spl,
})

export const getMedicalSplListFail = error => ({
  type: GET_MEDICAL_SPL_LIST_FAIL,
  payload: error,
})


export const getDeviceProbCodeList = () => ({
  type: GET_DEVICE_PROB_CODE_LIST,
})

export const getDeviceProbCodeListSuccess = device_prob_code => ({
  type: GET_DEVICE_PROB_CODE_LIST_SUCCESS,
  payload: device_prob_code,
})

export const getDeviceProbCodeListFail = error => ({
  type: GET_DEVICE_PROB_CODE_LIST_FAIL,
  payload: error,
})


export const getPatientProbCodeList = () => ({
  type: GET_PATIENT_PROB_CODE_LIST,
})

export const getPatientProbCodeListSuccess = patient_prob_code => ({
  type: GET_PATIENT_PROB_CODE_LIST_SUCCESS,
  payload: patient_prob_code,
})

export const getPatientProbCodeListFail = error => ({
  type: GET_PATIENT_PROB_CODE_LIST_FAIL,
  payload: error,
})


export const saveSmartQuery = (data, history) => ({
  type: SAVE_SMART_QUERY,
  payload: {data, history},
})

export const saveSmartQuerySuccess = query => ({
  type: SAVE_SMART_QUERY_SUCCESS,
  payload: query,
})

export const saveSmartQueryFail = error => ({
  type: SAVE_SMART_QUERY_FAIL,
  payload: error,
})



export const executeSmartQuery = (data, history,query, page) => ({
  type: EXECUTE_SMART_QUERY,
  payload: {data, history, query, page},
})

export const executeSmartQuerySuccess = query_result => ({
  type: EXECUTE_SMART_QUERY_SUCCESS,
  payload: query_result,
})

export const executeSmartQueryFail = error => ({
  type: EXECUTE_SMART_QUERY_FAIL,
  payload: error,
})

export const setSmartQueryDetails = query_details => ({
  type: SET_SMART_QUERY_DETAILS,
  payload: query_details,
})


export const resetQueryResult = () => ({
  type: RESET_QUERY_RESULT
})


export const getMdrList = (data, history) => ({
  type: GET_MDR_INITIAL_LIST,
  payload: {data, history},
})

export const getMdrListSuccess = query => ({
  type: GET_MDR_INITIAL_LIST_SUCCESS,
  payload: query,
})

export const getMdrListFail = error => ({
  type: GET_MDR_INITIAL_LIST_FAIL,
  payload: error,
})


export const setLoadingIndicatorSmartQuery = (loading) => {
  // console.log(loading)
  return {
    type: SET_LOADING_INDICATOR_SMART_QUERY,
    payload: loading,
  };
};

export const getPatientProbCodeMdrList = () => ({
  type: GET_PATIENT_PROB_CODE_MDR_LIST,
})

export const getPatientProbCodeMdrListSuccess = patient_prob_code => ({
  type: GET_PATIENT_PROB_CODE_MDR_LIST_SUCCESS,
  payload: patient_prob_code,
})

export const getPatientProbCodeMdrListFail = error => ({
  type: GET_PATIENT_PROB_CODE_MDR_LIST_FAIL,
  payload: error,
})


export const executeQueryResultNoData = dt => ({
  type: EXECUTE_SMART_QUERY_RESULT_NO_DATA,
  payload: dt,
})


export const executeQueryResultNoDataMdrList = dt => ({
  type: EXECUTE_SMART_QUERY_RESULT_NO_DATA_MDRLIST,
  payload: dt,
})


export const executeSmartQueryMdrList = (data, history,query, page) => ({
  type: EXECUTE_SMART_QUERY_MDR_LIST,
  payload: {data, history, query, page},
})

export const executeSmartQueryMdrListSuccess = query_result_mdrlist => ({
  type: EXECUTE_SMART_QUERY_MDR_LIST_SUCCESS,
  payload: query_result_mdrlist,
})

export const executeSmartQueryMdrListFail = error => ({
  type: EXECUTE_SMART_QUERY_MDR_LIST_FAIL,
  payload: error,
})

export const setSmartQueryMdrListDetails = query_details_mdrlist => ({
  type: SET_SMART_QUERY_MDR_LIST_DETAILS,
  payload: query_details_mdrlist,
})


export const resetQueryResultMdrList = () => ({
  type: RESET_QUERY_RESULT_MDR_LIST
})


export const abortSmartQuery= abortStatus => ({
  type: ABORT_SMART_QUERY,
  payload: abortStatus,

})

export const abortSmartQueryMdrList= abortStatusMdrList => ({
  type: ABORT_SMART_QUERY_MDR_LIST,
  payload: abortStatusMdrList,

})


export const getMonthlyChartSmart = (monthData) => ({
  type: GET_MONTHLY_CHART_SMART,
  payload: {monthData},
})

export const getMonthlyChartSmartSuccess = (monthly_chart) => ({
  type: GET_MONTHLY_CHART_SMART_SUCCESS,
  payload: monthly_chart,
})

export const getYearChartSmartSuccess = (year_chart) => ({
  type: GET_YEAR_CHART_SMART_SUCCESS,
  payload: year_chart,
})

export const getMonthlyChartSmartFail = error => ({
  type: GET_MONTHLY_CHART_SMART_FAIL,
  payload: error,
})

//field type chart data

export const getFieldChartSmart = (fieldData) => ({
  type: GET_FIELD_CHART_SMART,
  payload: {fieldData},
})

export const getFieldChartSmartFail = error => ({
  type: GET_FIELD_CHART_SMART_FAIL,
  payload: error,
})

export const getEventFieldChartSmartSuccess = (event_chart) => ({
  type: GET_EVENT_FIELD_CHART_SMART_SUCCESS,
  payload: event_chart,
})

export const getDeviceFieldChartSmartSuccess = (device_chart) => ({
  type: GET_DEVICE_FIELD_CHART_SMART_SUCCESS,
  payload: device_chart,
})

export const getSubmissionFieldChartSmartSuccess = (submission_chart) => ({
  type: GET_SUBMISSION_FIELD_CHART_SMART_SUCCESS,
  payload: submission_chart,
})

export const getManufacturerFieldChartSmartSuccess = (manufacturer_chart) => ({
  type: GET_MANUFACTURER_FIELD_CHART_SMART_SUCCESS,
  payload: manufacturer_chart,
})

export const getReasonFieldChartSmartSuccess = (reason_chart) => ({
  type: GET_REASON_FIELD_CHART_SMART_SUCCESS,
  payload: reason_chart,
})

export const getSpecialtyFieldChartSmartSuccess = (specialty_chart) => ({
  type: GET_SPECIALTY_FIELD_CHART_SMART_SUCCESS,
  payload: specialty_chart,
})


export const globalSearchResultMdrList = (data) => ({
  type: GLOBAL_SEARCH_RESULT_MDR_LIST,
  payload: {data},
})


export const globalSearchResultMdrListSuccess = global_search_results => ({
  type: GLOBAL_SEARCH_RESULT_MDR_LIST_SUCCESS,
  payload: global_search_results,
})

export const globalSearchResultMdrListFail = error => ({
  type: GLOBAL_SEARCH_RESULT_MDR_LIST_FAIL,
  payload: error,
})

export const setGlobalSearchResultDetails = global_search_details => ({
  type: SET_GLOBAL_SEARCH_RESULT_DETAILS,
  payload: global_search_details,
})



export const globalSearchResultNoData = dt => ({
  type: GLOBAL_SEARCH_RESULT_NO_DATA,
  payload: dt,
})

export const abortGlobalSearchResult= abortStatusGlobalSearch => ({
  type: ABORT_GLOBAL_SEARCH_RESULT,
  payload: abortStatusGlobalSearch,

})

export const resetGlobalSearch = () => ({
  type: RESET_GLOBAL_SEARCH
})
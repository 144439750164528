import React, { useState, useEffect } from "react";
import { Link ,useNavigate} from 'react-router-dom';

import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardSubtitle,
  Label,
  UncontrolledDropdown,
  FormFeedback,
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logoImg from '../../../../assets/images/tilt-logo.png'
import CardWithWatermark from "components/Common/Watermark";
import CardWithWatermarkForm from "components/Common/WatermarkForm";

const csvData = "col1,col2,col3\nvalue1,value2,value3\nvalue4,value5,value6";


const SmartQuery = () => {


  //meta title
  document.title = "Query Detailed View | Smarteeva";

  const location = useLocation();
  const navigate = useNavigate()


  const [search_Menu, setsearch_Menu] = useState(false);
  const [settings_Menu, setsettings_Menu] = useState(false);
  const [other_Menu, setother_Menu] = useState(false);
  const [activeTab, setactiveTab] = useState("1");
  const [queryDetails, setQueryDetails] = useState([]);



  useEffect(() => {
      if(location.state != null){
        // console.log(location.state)
      localStorage.setItem('MDRQueryResultDetails',JSON.stringify(location.state.state))
      setQueryDetails(location.state.state)
      }else{
        let dt = JSON.parse(localStorage.getItem('MDRQueryResultDetails'))
        if(dt != null) {
      setQueryDetails(dt)
      // console.log(dt)
        }
      }
      // console.log(queryDetails)
    }, []);

  //Toggle Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu);
  };

  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu);
  };

  const toggleOther = () => {
    setother_Menu(!other_Menu);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  //search recent user
  const searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();
    ul = document.getElementById("recent-list");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  const downloadCSVWithWatermark = () => {
    const watermarkText = 'Your Watermark Here';

    // Create a new canvas element
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Set canvas dimensions
    const canvasWidth = 800; // Set the canvas width to your desired value
    const canvasHeight = 600; // Set the canvas height to your desired value
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // Draw the watermark on the canvas
    ctx.font = '24px Arial';
    ctx.fillStyle = 'rgba(0, 0, 0, 0.3)'; // Adjust the color and opacity of the watermark
    ctx.fillText(watermarkText, 10, 50); // Adjust the position of the watermark

    // Draw the CSV data on the canvas
    ctx.font = '16px Arial';
    ctx.fillStyle = 'black';
    const lineHeight = 20;
    let y = 100; // Adjust the starting position for the CSV data
    csvData.split('\n').forEach((row) => {
      ctx.fillText(row, 10, y);
      y += lineHeight;
    });

    // Convert the canvas content to a data URL
    const dataURL = canvas.toDataURL();

    // Create a link to download the merged CSV with watermark
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'data_with_watermark.png'; // Download as a PNG file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const pxToMm = (px) => {
    return Math.floor(px/document.getElementById('mydiv').offsetHeight);
  };
  
  const mmToPx = (mm) => {
    return document.getElementById('mydiv').offsetHeight*mm;
  };
  
  const range = (start, end) => {
      return Array(end-start).join(0).split(0).map(function(val, id) {return id+start});
  };

  const savePdf = () =>{
    const input = document.getElementById('mydiv');
const inputHeightMm = pxToMm(input.offsetHeight);
const a4WidthMm = 210;
const a4HeightMm = 297;
const a4WidthPx = mmToPx(a4WidthMm);
const numPages = inputHeightMm <= a4WidthMm ? 1 : Math.floor(inputHeightMm / a4WidthMm) + 1;

// console.log({
//   input,
//   inputHeightMm,
//   a4WidthMm,
//   numPages,
//   comp: inputHeightMm <= a4WidthMm,
//   inputHeightPx: input.offsetHeight
// });

// let pdf = new jsPDF(';', 'mm', [297, 210]);

html2canvas(input)
.then((canvas) => {
  const img = canvas.toDataURL('image/jpg');
  let pdf = new jsPDF({
        orientation: 'l', // landscape
        unit: 'pt', // points, pixels won't work properly
        format: [canvas.width, canvas.height] // set needed dimensions for any element
  });
  pdf.addImage(img, 'JPEG', 0, 0, canvas.width, canvas.height);
  // pdf.addImage(logoImg, 'PNG', 40, 80, 130, 40);

  // // Add the watermark image (centered)
  // const watermarkWidth = 800;
  // const watermarkHeight = 800;
  // const watermarkX = (canvas.width - watermarkWidth) / 2.5;
  // const watermarkY = (canvas.height - watermarkHeight) / 3;
  

  // // Load and add the watermark image
  // const watermarkImage = new Image();
  // watermarkImage.src = logoImg; // Replace with your image path
  // watermarkImage.onload = () => {
  //   pdf.addImage(watermarkImage, watermarkX, watermarkY, watermarkWidth, watermarkHeight);
    
  
  pdf.save(`${queryDetails.REPORT_NUMBER}.pdf`);
  // }
});
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Smart Query" breadcrumbItem="MDR Detailed View" />
          <Row>
            <Col lg="12" >
              <div className="d-lg-flex" id={"mydiv"}>
                <div className="w-100 h-80vh">
                  <Card className="h-96">
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col md="12" xs="12">
                          <Col md="12" xs="12">
                            <Row className="flex-center">
                              <Col md="6" xs="12" >
                                <h4 className="m-0">
                                  Medical Device Report Details
                                </h4>
                              </Col>
                              <Col md="6" xs="12" className=" flex-end">

                              <div className="d-grid" style={{marginRight : 10}}>
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                              onClick={()=>{
                                navigate(-1)
                              }}
                            >
                              Back
                            </button>
                          </div>
                                <div className="flex-shrink-0">
                                  {/* <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-3"><i className="mdi mdi-plus"></i> Add New MDR</Link> */}
                                  <Link to="#!" className="btn btn-light me-3"
                                  onClick={()=>{
                                    // downloadCSVWithWatermark()
                                    savePdf()
                                  }}
                                  ><i className="mdi mdi-download me-1"></i> Download Report </Link>
                                  {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                    <DropdownToggle type="menu" className="btn btn-light" id="dropdownMenuButton1">
                                      <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                    <DropdownMenu>
                                      <li><DropdownItem href="#">Action</DropdownItem></li>
                                      <li><DropdownItem href="#">Another action</DropdownItem></li>
                                      <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                    </DropdownMenu>
                                  </UncontrolledDropdown> */}
                                </div>

                              
                              </Col>
                            </Row>
                          </Col>
                          {/* <Col md="12" xs="12">
                            <p className="text-muted mb-0">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sed ornare quam, a efficitur purus. Mauris tempor eleifend sapien non pellentesque.
                            </p>
                          </Col> */}

                          
                        </Col>
                      </Row>
                    </div>


                    <div className="m-4 ">
                      <Col md="12" xs="12">
                        <form>
                          <div className="row col-12">
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Report Number</Label>
                              <Input
                              value={queryDetails.REPORT_NUMBER}
                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                            <Label className="form-label">Event Type</Label>
                              <Input
                              value={queryDetails.EVENT_TYPE}

                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Date Received</Label>
                              <InputGroup>
                                <Flatpickr
                              value={queryDetails.DATE_RECEIVED}
                                  className="form-control d-block"
                                  placeholder=""
                                  options={{
                                    dateFormat: "d-m-Y"
                                  }}
                                  disabled />
                              </InputGroup>
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Manufacturer Name</Label>
                              <Input
                              value={queryDetails.MANUFACTURER_D_NAME}

                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Model Number</Label>
                              <Input
                              value={queryDetails.MODEL_NUMBER}

                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Lot Number</Label>
                              <Input
                              value={queryDetails.LOT_NUMBER}

                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Catalog Number</Label>
                              <Input
                              value={queryDetails.CATALOG_NUMBER}

                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Brand Name</Label>
                              <Input
                              value={queryDetails.BRAND_NAME}

                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Generic Name</Label>
                              <Input
                              value={queryDetails.GENERIC_NAME}

                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Product Code</Label>
                              <Input
                              value={queryDetails.DEVICE_REPORT_PRODUCT_CODE}

                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Medical Speciality</Label>
                              <Input
                              value={queryDetails.MEDICAL_SPECIALTY}
                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Device Name</Label>
                              <Input
                              value={queryDetails.DEVICENAME}
                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Submission Type</Label>
                              <Input
                              value={queryDetails.SUBMISSION_TYPE}
                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Manufacturer Name</Label>
                              <Input
                              value={queryDetails.MANUFACTURER_D_NAME}

                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="4" xs="6" className="mb-3">
                              <Label className="form-label">Unclassified Reason</Label>
                              <Input
                              value={queryDetails.UNCLASSIFIED_REASON}
                              rows={5}
                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="12" xs="12" className="mb-3">
                              <Label className="form-label">Event Description</Label>
                              <Input
                              value={queryDetails.EVENT_DESCRIPTION}
                              rows={5}
                                placeholder=""
                                type="textarea"
                                disabled />
                            </Col>
                            <Col md="12" xs="12" className="mb-3">
                              <Label className="form-label">Manufacturer Narrative</Label>
                              <Input
                              value={queryDetails.MFG_NARRATIVE}
                              rows={5}
                                placeholder=""
                                type="textarea"
                                disabled />
                            </Col>
                            <Col md="12" xs="12" className="mb-3">
                              <Label className="form-label">Device Problem</Label>
                              <Input
                              value={queryDetails.DEVICE_PROBLEM_DESCRIPTION}
                                  rows={5}
                                placeholder=""
                                type="textarea"
                                disabled />
                            </Col>
                          </div>
                        </form>

                      </Col>
                    </div>
                  </Card>
                </div>

                <CardWithWatermarkForm />
              </div>
            </Col>
          </Row>
    
        </Container>
       
      </div>

    </React.Fragment>
  );
};



export default SmartQuery;

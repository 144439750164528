import React, { useState } from 'react';
import { Col } from 'reactstrap';
import { Link } from "react-router-dom";


const UserListGlobalFilter = ({  onStatusChange,
    statusValue,
    approvalValue,
    onApprovalStatusChange,
    onSignupStatusChange,
    signupValue,
    handleReset
}) => {
    const [selectDate, setSelectDate] = useState();
    const dateChange = (date) => {
        setSelectDate(date)
    };

    // const [status, setStatus] = useState('');
    // const [approvalStatus, setApprovalStatus] = useState('');
  
    // const handleStatusChange = (event) => {
    //   setStatus(event.target.value);
    // };
  
    // const handleApprovalStatusChange = (event) => {
    //   setApprovalStatus(event.target.value);
    // };

    return (
        <React.Fragment>  
              
            <Col xxl={2} lg={4}>
                <select className="form-control select2 mb-3 mb-xxl-0" 
                //  onChange={handleStatusChange}
                onChange={onStatusChange}
                 value={statusValue}
                 defaultValue=""
                >
                    <option key={''} disabled value={''}>Status</option>
                    <option key="All" value="All">All</option>
                    <option key="Active" value="Active">Active</option>
                    {/* <option value="New">New</option>
                    <option value="Paused">Paused</option> */}
                    <option key="Archived" value="Archived">Archived</option>
                </select>
            </Col>
            <Col xxl={2} lg={4}>
                <select className="form-control select2 mb-3 mb-xxl-0" 
                 onChange={onApprovalStatusChange}
                //   onChange={handleApprovalStatusChange}
                  value={approvalValue}
                  defaultValue=""
                >
                    <option key={''} disabled value={''}>Approval Status</option>
                    <option key="All" value="All">All</option>
                    <option key="Approved" value="Approved">Approved</option>
                    <option key="Pending" value="Pending">Pending</option>
                </select>
            </Col>
            <Col xxl={2} lg={4}>
                <select className="form-control select2 mb-3 mb-xxl-0" 
                 onChange={onSignupStatusChange}
                //   onChange={handleApprovalStatusChange}
                  value={signupValue}
                  defaultValue=""
                >
                    <option key={''} disabled value={''}>Signup Status</option>
                    <option key="All" value="All">All</option>
                    <option key={true} value={true}>Signed</option>
                    <option key={false} value={false}>Pending</option>
                </select>
            </Col>
            <Col lg={1} style={{float : 'right', textAlign: 'end'}}>
          <div className="flex-shrink-0">
                                            <Link className="btn btn-light me-3" 
                                            onClick={handleReset}
                                            ><i className="mdi mdi-refresh"
                                            
                                            ></i> </Link>
                                          
                                        </div>
          </Col>
        </React.Fragment>
    )
};
export default UserListGlobalFilter;


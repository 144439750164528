import React, {useState, useRef} from 'react';
import { FormControl, InputGroup, Tab, Nav } from 'react-bootstrap';
// import '@fortawesome/fontawesome-free'; // Include Font Awesome CSS
import Form from 'react-bootstrap/Form';
import Draggable from '../../../../assets/images/drag.png';
import {
  Button,
   ButtonGroup,
   CardTitle, 
   FormFeedback,
   Label,
   Input,
   Col
  //  Accordion, 
  //  Form

} from "reactstrap";
import { useDrag, useDrop } from "react-dnd";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Link } from 'react-router-dom';
import Select from 'react-select';

const style = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
  }

function TabComponentPreview(props) {

  const [counter, setCounter] = useState(0)



  const addInputField = (event) => {
    // Your addInputField function logic here
  };

  const showPreviousQuestion = () => {
    // Your showPreviousQuestion function logic here
  };

  const showNextQuestion = () => {
    // Your showNextQuestion function logic here
  };



  const ShortTextView = () =>{
    return (
      <Col lg='12'>
      <div className=" mb-3">
      <Input
    //   disabled 
      name="key"
      type="textarea"
      rows={3}
      placeholder=""
     
  />
   </div>
   </Col>
    )
  }


  const LongTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <Input
    //   disabled
      name="key"
      type="textarea"
      rows={7}
      placeholder=""
   
  />
   </div>
   </Col>
    )
  }


  const NumberTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <InputGroup>
                        <div
                          className="input-group-append"
                          onClick={() => {
                            if(counter > 0){
                            setCounter(counter - 1);
                            }
                          }}
                        >
                          <Button type="button" color="primary">
                            <i className="mdi mdi-minus" />
                          </Button>
                        </div>
                        <Input
                        style={{
                          borderRight: 0,
                          marginRight:-5,
                          borderLeft: 0,
                          marginLeft: -5,
                          background : 'transparent',
                          textAlign : 'center'
                        }}
                      
                          type="number"
                          // className="form-control"
                          value={counter}
                          onChange={(e)=>{
                            // if(e.target.value == ''){
                            //   setCounter(0)
                            // }
                               setCounter(parseInt(e.target.value))
                            // }
                          }}
                          // placeholder="number"
                          // readOnly
                          
                        />
                        <div
                          className="input-group-append"
                          onClick={() => {
                           
                              setCounter(counter + 1);
                          
                          }}
                        >
                          <Button type="button" color="primary">
                            <i className="mdi mdi-plus" />
                          </Button>
                        </div>
                      </InputGroup>
   </div>
   </Col>
    )
  }


  const DateTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <Flatpickr
              // value={Date.parse(pick_date)}
              className="form-control d-block"
              placeholder="Pick a date"
              options={{
                altInput: true,
                dateFormat: "d-m-y"
              }}
              // onChange={(e) => updateValue(groupId, rule.id, moment(new Date(e)).format('MM/DD/YYYY'))
              // }
            />
   </div>
   </Col>
    )
  }


  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'white' : 'white', // Change background color when selected
      color: state.isSelected ? 'black' : 'black', // Change text color when selected
    }),
  };

  const DropdownTextView =  (ques) =>{
    
    return (
      <Col lg='12'>
      <div className="mb-3">
     
 
      <Select
                                  name={''}
                                  isClearable
                                  isSearchable
                                  options={ques.choices.map((option, index) => ({
                                    label: option.value,
                                    value:option.value, // or any unique identifier for the value
                                  }))}
                                  styles={customStyles}
                              
                                />

   </div>
   </Col>
    )
  }


  const MultipleTextView =  (ques) =>{
    return (
      <Col lg='12'>
      <div className="mb-3 row" style={{paddingLeft : 15, paddingRight : 15}}>
     
     {ques.choices.map((choice, index)=>(
       <div className="form-check form-checkbox-outline form-check-primary mb-3 col-lg-6" key={index}>
       <input
               style={{color: '#232D42'}}
         type="checkbox"
         className="form-check-input"
         id="customCheck-outlinecolor1"
         // checked={customOutlinePrimary}
         // onChange={() => {
         //   setcustomOutlinePrimary(!customOutlinePrimary)
         // }}
       />
       <label
       style={{color: '#232D42'}}
         className="form-check-label"
         htmlFor="customCheck-outlinecolor1"
       >
        {choice.value}
       </label>
     </div>
     ))}
     

   </div>
   </Col>
    )
  }

  const YesNoTextView =  () =>{

    return (
      <Col lg='12'>
      <div className="mb-3">
     
      <div className="btn-group btn-group-example" style={{width: '100%'}}
                            role="group"
                          >
                            <button
                              type="button"
                              className="btn btn-outline-primary w-lg"
                            >
                              Yes
                            </button>
                           
                            <button
                              type="button"
                              className="btn btn-outline-primary w-lg"
                            >
                              No
                            </button>
                          </div>
    
   </div>
   </Col>
    )
  }


  const FileUploadTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <Input
      // disabled
      name="key"
      type="file"
      placeholder=""
     
  />
   </div>
   </Col>
    )
  }

  const GoogleDriveTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <div className="flex-shrink-0">

                                                        
<Link className="btn btn-primary me-3" 
style={{width : '100%'}}
// onClick={handleAddNewModule}
// to={{pathname : '/decision-tree-config'}}
><i className="mdi mdi-cloud-upload"

></i> Upload from Google Drive </Link>
</div>
   </div>
   </Col>
    )
  }


  const LinkTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <Input
      // disabled
      name="key"
      type="text"
      placeholder=""
      
  />
   </div>
   </Col>
    )
  }


  const renderFieldViewPreview = (ques) =>{
    switch (ques.type) {
      case 'shorttext':
        return (
          <div>{ShortTextView()}</div>
        );
      case 'longtext':
        return (
          <div>{LongTextView()}</div>
        );
      case 'number':
        return (
          <div>{NumberTextView()}</div>
        );
      case 'date':
        return (
          <div>{DateTextView()}</div>
        );
      case 'dropdown':
        return (
          <div>{DropdownTextView(ques)}</div>
        );
      case 'multiplechoice':
        return (
          <div>{MultipleTextView(ques)}</div>
        );
        case 'yes/no':
          return (
            <div>{YesNoTextView()}</div>
          );
          case 'fileupload':
            return (
              <div>{FileUploadTextView()}</div>
            );
            case 'googledrive':
              return (
                <div>{GoogleDriveTextView()}</div>
              );
              case 'link':
                return (
                  <div>{LinkTextView()}</div>
                );
        default:
          return null;
      }
    
}

  return (
   
    <div className="openQuestion-main-section draggable" draggable="true"  >
               
                    <div className=" edit-module">
                    
                      <div className='card-inner-head'>
                   
                       
            
                        <div className= {props.qData.required ?"question flexHead0043 required" : "question flexHead0043"}>
                        {props.qData.num}. {props.qData.questionText}
                        </div>

                        <div className="input-wrapper">
                        {renderFieldViewPreview(props.qData)}
                        </div>


                        <div className="input-wrapper mb-3" style={{display : props.qData.showcomments ? '' : 'none'}}>
    <Label className={props.qData.commentsrequired ? "form-label required" : "form-label"} style={{marginBottom :0}}>Comments</Label>
    <Input
      // disabled
      name="key"
      type="textarea"
      rows={5}
      placeholder="" />
    </div>

                        {/* <div className="add-choice-container">
                          <button className="add-choice-button" onClick={addInputField} style={{ cursor: 'pointer' }}>
                            <span className='choosemr-3'>Add Choice</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <path d="M10 4.16666V15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.16666 10H15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </button>
                        </div> */}
                      </div>

                    </div>
                  
                  </div>

  );
}

export default TabComponentPreview;

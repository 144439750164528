import React from 'react';

const CardWithWatermarkForm = ({ title, content, watermarkText }) => {
  return (
    <div  className="watermark-form">
      <div>SMARTEEVA SOFTWARE, INC</div>
      {/* <div className="card">
        <h2>SMARTEEVA SOFTWARE, INC.</h2>
        <p>{content}</p>
      </div> */}
    </div>
  );
};

export default CardWithWatermarkForm;
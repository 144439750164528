import PropTypes from "prop-types";
import React from "react";
import { Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import moment from "moment";
import CardWithWatermark from "components/Common/Watermark";

const EventLine = ({ dataColors, data}) => {

  // console.log(data)
  const death = data.map(obj => obj.D_Count)
  const injury = data.map(obj => obj.IN_Count)
  const malfunc = data.map(obj => obj.M_Count)
  const dates = data.map(obj => moment(obj.MONTH,'MM/YYYY').format('MMM YYYY'))
  // console.log(dates)


  const lineColumnAreaColors = getChartColorsArray(dataColors);

  const series = [
    {
      name: "Death",
      type: "column",
      data: death,
    },
    {
      name: "Injury",
      type: "area",
      data: injury,
    },
    {
      name: "Malfunction",
      type: "line",
      data: malfunc,
    },
  ]
  const options = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 2, 4],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    colors: lineColumnAreaColors,

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: dates,
    markers: {
      size: 1,
      strokeWidth: 0,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      showForSingleSeries: true
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "MDR Count",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0)
          }
          return y
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  }

  return (
    <React.Fragment>
     
<Col sm="12">
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-2">
              
              <h5 className="card-title me-2">Event Type Monthly Statistics</h5>
              </div>
              <div className="text-muted mt-4"  >
              
                {/* <div className="d-flex"> */}
                  {/* <span
                    className={
                      "badge badge-soft-" + report.color + " font-size-12"
                    }
                  >
                    {" "}
                    {report.badgeValue}{" "}
                  </span>{" "} */}
                  {/* <span className="ms-2 text-truncate"> */}
    <ReactApexChart options={options} series={series} type="line" height="350"/>

                  {/* </span> */}
                {/* </div> */}
              </div>

              <CardWithWatermark/>


            </CardBody>
          </Card>
        </Col>

      
    </React.Fragment>
  )
}

EventLine.propTypes = {
  reports: PropTypes.array,
}

export default EventLine;

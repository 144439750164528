import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";

//actions
import { getEarningChartsData } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import moment from "moment";
import CardWithWatermark from "components/Common/Watermark";

const MDRStats = ({ dataColors, mdrStats, mdrStatsYear, prevMonthCount }) => {
  const apexearningChartColors = getChartColorsArray(dataColors);
  const [duration, setDuration] = useState("year");
  const [seriesMonth, setSeriesMonth] = useState([]);
  const [countMonth, setCountMonth] = useState([]);
  const dispatch = useDispatch();


  function chooseSixMonths(array) {
    const halfIndex = Math.ceil(array.length / 2);
    return array.slice(halfIndex);
  }

  
  const visitorDurationData = (duration) => {
    setDuration(duration);
    // console.log(duration)
    // dispatch(dashboardBlogVisitorData(duration));
    if(duration == 'sixmonth'){

      var array =  Object.values(chooseSixMonths(mdrStatsYear)).map(item => item.MONTH);
      var array2 =  Object.values(chooseSixMonths(mdrStatsYear)).map(item => item.COUNT);
   
      // console.log(array)
   setSeriesMonth(array)
   setCountMonth(array2)

    }else if(duration == 'year'){

      var array =  Object.values(mdrStatsYear).map(item => item.MONTH);
      var array2 =  Object.values(mdrStatsYear).map(item => item.COUNT);
   
      // console.log(array)
   setSeriesMonth(array)
   setCountMonth(array2)
    }else{
      var array =  Object.values(mdrStats).map(item => formatDate(item.DAYS));
      var array2 =  Object.values(mdrStats).map(item => item.COUNT);
   
     //  console.log(array)
   setSeriesMonth(array)
   setCountMonth(array2)
    }
  };

  function formatDate(dateString) {
    return moment(dateString, 'DD/MM/YYYY').format('DD MMM')
  }
  
  useEffect(() => {
    if(seriesMonth.length <= 0){
    //  var array =  Object.values(mdrStats).map(item => formatDate(item.DAYS));
    //  var array2 =  Object.values(mdrStats).map(item => item.COUNT);

    var array =  Object.values(mdrStatsYear).map(item => item.MONTH);
  var array2 =  Object.values(mdrStatsYear).map(item => item.COUNT);
  
    //  console.log(array)
  setSeriesMonth(array)
  setCountMonth(array2)
  setDuration('year')
    }
    }, [mdrStats]);
  
    // const { mdrStatsData } = useSelector(state => ({
    //   mdrStatsData: state.Dashboard.visitor
    // }));
  
    const series = [
      {
        name: "MDR Reported",
        data: (countMonth || []),
      }
    ];
  
    const options = {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: true,
        },
      },
      // colors: apexCardUserChartColors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.55,
          opacityTo: 0.05,
          stops: [20, 100, 100, 100],
        },
      },
      xaxis: {
        categories: (seriesMonth|| []),
        labels: {
          style: {
            fontSize: '10px',
            fontWeight: 500,
          },
        }
      },
  
      markers: {
        size: 1,
        strokeWidth: 0,
  
        hover: {
          size: 4,
          sizeOffset: 2,
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        showForSingleSeries: true
      },
    };
  


  const { earningChartData } = useSelector(state => ({
    earningChartData: state.DashboardSaas.earningChartData,
  }));

  // const options = {
  //   chart: {
  //     toolbar: "false",
  //     dropShadow: {
  //       enabled: !0,
  //       color: "#000",
  //       top: 18,
  //       left: 7,
  //       blur: 8,
  //       opacity: 0.2,
  //     },
  //   },
  //   dataLabels: {
  //     enabled: !1,
  //   },
  //   colors: apexearningChartColors,
  //   stroke: {
  //     curve: "smooth",
  //     width: 3,
  //   },
  // };

  // const series = [
  //   {
  //     name: "Series 1",
  //     data: [...earningChartData],
  //   },
  // ];

  /*
  call api action to receive data
  */
  useEffect(() => {
    dispatch(getEarningChartsData("jan"));
  }, [dispatch]);

  const [seletedMonth, setSeletedMonth] = useState("jan");
  
  const onChangeMonth = value => {
    setSeletedMonth(value);
    dispatch(getEarningChartsData(value));
  };

  return (
    <React.Fragment>
      <Col xl="12">
        <Card>
          <CardBody>
            {/* <div className="clearfix">
              <div className="float-end">
                <div className="input-group input-group-sm">
                  <select
                    className="form-select form-select-sm"
                    value={seletedMonth}
                    onChange={(e) => {
                      onChangeMonth(e.target.value);
                    }}
                  >
                    <option value="jan">Jan</option>
                    <option value="dec">Dec</option>
                    <option value="nov">Nov</option>
                    <option value="oct">Oct</option>
                  </select>
                 
                </div>
              </div>
              <h4 className="card-title mb-4">MDR Statistics</h4>
            </div> */}

<div className="d-flex flex-wrap">
              <h5 className="card-title me-2">MDR Statistics</h5>
              <div className="ms-auto">
                <div className="toolbar d-flex flex-wrap gap-2 text-end">
                  {/* <button type="button" className="btn btn-light btn-sm" onClick={() => visitorDurationData('all')}>
                    ALL
                  </button>{" "} */}
                   <button type="button" className={duration == 'year' ? "btn btn-light btn-sm active" : "btn btn-light btn-sm"} onClick={() => visitorDurationData('year')}>
                    1 Year
                  </button>{" "}
                  <button type="button" className={duration == 'sixmonth' ? "btn btn-light btn-sm active" : "btn btn-light btn-sm"} onClick={() => visitorDurationData('sixmonth')}>
                    6 Months
                  </button>{" "}
                  <button type="button" className={duration == 'onemonth' ? "btn btn-light btn-sm active" : "btn btn-light btn-sm"} onClick={() => visitorDurationData('onemonth')}>
                    1 Month
                  </button>{" "}
             
                 
                </div>
              </div>
            </div>

            <Row>
              <Col lg="3">
                <div className="text-muted">
                  <div className="mb-4">
                    <p>Reports MDR</p>
                    <h4>{prevMonthCount}</h4>
                    <div>
                      <span className="badge badge-soft-success font-size-12 me-1">
                        {" "}
                        total{" "}
                      </span>{" "}
                      previous month
                    </div>
                  </div>

                  <div>
                    <Link to="/mdr-list" className="btn btn-primary  btn-md">
                      View Details{" "}
                      <i className="mdi mdi-chevron-right ms-1"></i>
                    </Link>
                  </div>

                  {/* <div className="mt-4">
                    <p className="mb-2">Last month</p>
                    <h5>2,281</h5>
                  </div> */}
                </div>
              </Col>

              <Col lg="9">
              <div id="area-chart" dir="ltr">
              <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={350}
                className="apex-charts"
               
              />
            </div>
              </Col>
            </Row>
            <CardWithWatermark />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}

export default MDRStats;

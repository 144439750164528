/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_MDR_STATS_DATA_PUBLIC = "GET_MDR_STATS_DATA_PUBLIC"
export const GET_MDR_STATS_DATA_YEAR_PUBLIC = "GET_MDR_STATS_DATA_YEAR_PUBLIC"
export const GET_MDR_TOP_DEVICES_PUBLIC = 'GET_MDR_TOP_DEVICES_PUBLIC'
export const GET_MDR_TOP_PATIENTS_PUBLIC = "GET_MDR_TOP_PATIENTS_PUBLIC"
export const GET_MDR_YEAR_DATA_PUBLIC = 'GET_MDR_YEAR_DATA_PUBLIC'
export const GET_MDR_MONTH_COUNT_PUBLIC = "GET_MDR_MONTH_COUNT_PUBLIC"
export const GET_MDR_EVENT_COUNT_PUBLIC = "GET_MDR_EVENT_COUNT_PUBLIC"
export const GET_MDR_YEARLY_COUNT_PUBLIC = "GET_MDR_YEARLY_COUNT_PUBLIC"
export const GET_MDR_MEDICAL_SPEC_COUNT_PUBLIC = "GET_MDR_MEDICAL_SPEC_COUNT_PUBLIC"
export const GET_MDR_MONTHLY_EVENT_COUNT_PUBLIC = "GET_MDR_MONTHLY_EVENT_COUNT_PUBLIC"
export const SET_LOADING_INDICATOR_DASHBOARD_PUBLIC = "SET_LOADING_INDICATOR_DASHBOARD_PUBLIC"
import { call, put, takeEvery } from "redux-saga/effects"

import { GET_COMPANY_LIST, ADD_NEW_COMPANY, UPDATE_COMPANY, DELETE_COMPANY, GET_PHONECODE_LIST, GET_COUNTRY_LIST, GET_STATE_LIST, GET_CITY_LIST , GET_BILLING_STATE_LIST, GET_BILLING_CITY_LIST, GET_COMPANY_DETAILS, APPROVE_COMPANY} from "./actionTypes";
import {
    getCompanyListSuccess,
    getCompanyListFail,
    addCompanySuccess,
    addCompanyFail,
    updateCompanySuccess,
    updateCompanyFail,
    deleteCompanySuccess,
    deleteCompanyFail,
    getPhonecodeListSuccess,
    getPhonecodeListFail,
    getCountryListSuccess,
    getCountryListFail,
    getStateListSuccess,
    getStateListFail,
    getCityListSuccess,
    getCityListFail,
    getBillingStateListSuccess,
    getBillingStateListFail,
    getBillingCityListSuccess,
    getBillingCityListFail,
    getCompanyDetailsSuccess,
    getCompanyDetailsFail,
    getCompanyCpSuccess,
    approveCompanySuccess,
    approveCompanyFail,
    setLoadingIndicatorCompany
} from "./actions"

import {
    getCompanyList,
    addNewCompany,
    updateCompany,
    deleteCompany,
    getPhonecodeList,
    getCountryList,
    getStateList,
    getCityList,
    getCompanyContactPerson,
    approveCompany
} from "helpers/backend_helper";
import moment from "moment";
import { authUser } from "helpers/dataConfig";

function* fetchCompanyList() {

  yield put(setLoadingIndicatorCompany(true));

    // console.log('in fetch company list')
    // console.log(JSON.parse(localStorage.getItem(authUser)).fld_userid)
    try {
        const response = yield call(getCompanyList)
        console.log(response)
        yield put(getCompanyListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getCompanyListFail(error))
    }
}

function* fetchPhonecodeList() {

    // console.log('in fetch phone code list')
    try {
        const response = yield call(getPhonecodeList)
        // console.log(response)
        yield put(getPhonecodeListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getPhonecodeListFail(error))
    }
}


function* fetchCountryList() {

  yield put(setLoadingIndicatorCompany(true));

    // console.log('in fetch country list')
    try {
        const response = yield call(getCountryList)
        // console.log(response)
        yield put(getCountryListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getCountryListFail(error))
    }
}


function* fetchStateList({ payload: data }) {

  yield put(setLoadingIndicatorCompany(true));

    // console.log('in fetch phone code list')
    // console.log(data)
    const contData = {
        countryid : data
    }
    try {
        const response = yield call(getStateList, contData)
        // console.log(response)
        yield put(getStateListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getStateListFail(error))
    }
}




function* fetchCityList({ payload: data }) {

  yield put(setLoadingIndicatorCompany(true));

    // console.log('in fetch city list')
    const cityData = {
        stateid : data
    }
    try {
        const response = yield call(getCityList, cityData)
        // console.log(response)
        yield put(getCityListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getCityListFail(error))
    }
}



function* fetchBillingStateList({ payload: data }) {

  yield put(setLoadingIndicatorCompany(true));

    // console.log('in fetch phone code list')
    // console.log(data)
    const contData = {
        countryid : data
    }
    try {
        const response = yield call(getStateList, contData)
        // console.log(response)
        yield put(getBillingStateListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getBillingStateListFail(error))
    }
}


function* fetchBillingCityList({ payload: data }) {

  yield put(setLoadingIndicatorCompany(true));

    // console.log('in fetch city list')
    const cityData = {
        stateid : data
    }
    try {
        const response = yield call(getCityList, cityData)
        // console.log(response)
        yield put(getBillingCityListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getBillingCityListFail(error))
    }
}



function* fetchCompanyDetails({ payload: data }) {

  yield put(setLoadingIndicatorCompany(true));

    // console.log(data)
    yield put(getCompanyDetailsSuccess(data))
    const compData = {
        companyid : data.FLD_COMPANYID
    }

    try {
        const response = yield call(getCompanyContactPerson, compData)
        // console.log(response)
        yield put(getCompanyCpSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getCompanyDetailsFail(error))
    }
}


function* onAddNewCompany({ payload: {data, history }}) {

  yield put(setLoadingIndicatorCompany(true));

    const additional = {
        status : 'Active',
        approvalstatus : 'Pending',
        createdon : moment().format(),
        createdby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
        updatedon :  moment().format(),
        updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    }
    const compData = {...data, ...additional}

    try {
        const response = yield call(addNewCompany, compData)
        // yield put(addCompanySuccess(response))
       
        try {
            yield call(fetchCompanyList)
        history('/corporate-list');

        } catch (error) {
            yield put(addCompanyFail(error))
        }

    } catch (error) {
        yield put(addCompanyFail(error))
    }
}

function* onUpdateCompany({ payload: {company, history} }) {

  yield put(setLoadingIndicatorCompany(true));

    // console.log(company)

    const additional = {
        status : 'Active',
        approvalstatus : 'Pending',
        updatedon :  moment().format(),
        updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    }
    const compData = {...company, ...additional}
    try {
        const response = yield call(updateCompany, compData)
        // yield put(updateCompanySuccess(response))
       
        try {
            yield call(fetchCompanyList)
    localStorage.removeItem('CompDetails')
        history('/corporate-list');

        } catch (error) {
            yield put(updateCompanyFail(error))
    localStorage.removeItem('CompDetails')

        }

    } catch (error) {
        yield put(updateCompanyFail(error))
    localStorage.removeItem('CompDetails')

    }
}

function* onDeleteCompany({ payload: data }) {

  yield put(setLoadingIndicatorCompany(true));


    const comp = {
        companyid : data,
        updatedon : moment().format(),
        updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid
    }

    try {
        const response = yield call(deleteCompany, comp)
        // console.log(response)
        // history('/corporate-list');
        try {
        yield call(fetchCompanyList)
    } catch (error) {
        yield put(deleteCompanyFail(error))
    }

        // yield put(deleteCompanySuccess(response))
    } catch (error) {
        yield put(deleteCompanyFail(error))
    }
}


function* onApproveCompany({ payload: {data, history} }) {

  yield put(setLoadingIndicatorCompany(true));

    const comp = {
        companyid : data,
        updatedon : moment().format(),
        updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid
    }

    try {
        const response = yield call(approveCompany, comp)
        // console.log(response)
        // yield put(approveCompanySuccess(response))

        try {
            yield call(fetchCompanyList)
        history('/corporate-list');

        } catch (error) {
            yield put(approveCompanyFail(error))
        }
    

    } catch (error) {
        yield put(approveCompanyFail(error))
    }
}

function* companySaga() {
    yield takeEvery(GET_COMPANY_LIST, fetchCompanyList)
    yield takeEvery(ADD_NEW_COMPANY, onAddNewCompany)
    yield takeEvery(UPDATE_COMPANY, onUpdateCompany)
    yield takeEvery(DELETE_COMPANY, onDeleteCompany)
    yield takeEvery(GET_PHONECODE_LIST, fetchPhonecodeList)
    yield takeEvery(GET_COUNTRY_LIST, fetchCountryList)
    yield takeEvery(GET_STATE_LIST, fetchStateList)
    yield takeEvery(GET_CITY_LIST, fetchCityList)
    yield takeEvery(GET_BILLING_STATE_LIST, fetchBillingStateList)
    yield takeEvery(GET_BILLING_CITY_LIST, fetchBillingCityList)
    yield takeEvery(GET_COMPANY_DETAILS, fetchCompanyDetails)
    yield takeEvery(APPROVE_COMPANY, onApproveCompany)



}

export default companySaga;
import {
  // ADD_NEW_USER,
  // ADD_USER_SUCCESS,
  // ADD_USER_FAIL,
  // UPDATE_USER,
  // UPDATE_USER_SUCCESS,
  // UPDATE_USER_FAIL,
  // DELETE_USER,
  // DELETE_USER_SUCCESS,
  // DELETE_USER_FAIL,
  SET_LOADING_INDICATOR_DT,
  GET_MODULES,
  GET_MODULES_SUCCESS,
  GET_MODULES_FAIL,
  ADD_NEW_MODULE,
  ADD_MODULE_SUCCESS,
  ADD_MODULE_FAIL,
  DELETE_MODULE,
  DELETE_MODULE_SUCCESS,
  DELETE_MODULE_FAIL,
  UPDATE_MODULE,
  UPDATE_MODULE_SUCCESS,
  UPDATE_MODULE_FAIL,
  APPROVE_MODULE,
  APPROVE_MODULE_SUCCESS,
  APPROVE_MODULE_FAIL,
  DEACTIVATE_MODULE,
  DEACTIVATE_MODULE_SUCCESS,
  DEACTIVATE_MODULE_FAIL,
  SAVE_TAB_DATA_DT,
  SAVE_TAB_DATA_DT_SUCCESS,
  SAVE_TAB_DATA_DT_FAIL,
  SAVE_DRAFT_TAB_DATA_DT,
  SAVE_DRAFT_TAB_DATA_DT_SUCCESS,
  SAVE_DRAFT_TAB_DATA_DT_FAIL,
  GET_DRAFT_TAB_DATA_DT,
  GET_DRAFT_TAB_DATA_DT_SUCCESS,
  GET_DRAFT_TAB_DATA_DT_FAIL,
  GET_ACTIVE_MODULES_SUCCESS,
  GET_ACTIVE_MODULES_FAIL,
  GET_ACTIVE_MODULES,
  GET_EVENTS_DT,
  GET_EVENTS_DT_SUCCESS,
  GET_EVENTS_DT_FAIL,
  ADD_NEW_EVENT_DT,
  ADD_EVENT_DT_SUCCESS,
  ADD_EVENT_DT_FAIL,
  DELETE_EVENT_DT,
  DELETE_EVENT_DT_SUCCESS,
  DELETE_EVENT_DT_FAIL,
  APPROVE_EVENT_DT,
  APPROVE_EVENT_DT_SUCCESS,
  APPROVE_EVENT_DT_FAIL,
  UPDATE_EVENT_DT,
  UPDATE_EVENT_DT_SUCCESS,
  UPDATE_EVENT_DT_FAIL,
  GET_TABDATA_EVENT,
  GET_TABDATA_EVENT_SUCCESS,
  GET_TABDATA_EVENT_FAIL,
  SAVE_TAB_ANSWER_DATA_DT_SUCCESS,
  SAVE_TAB_ANSWER_DATA_DT_FAIL,
  SAVE_TAB_ANSWER_DATA_DT,
  ADD_NEW_MODULE_SCORE_SUCCESS,
  ADD_NEW_MODULE_SCORE,
  ADD_NEW_MODULE_SCORE_FAIL,
  GET_MODULE_SCORES,
  GET_MODULE_SCORES_SUCCESS,
  GET_MODULE_SCORES_FAIL,
  DELETE_MODULE_SCORE,
  DELETE_MODULE_SCORE_SUCCESS,
  DELETE_MODULE_SCORE_FAIL,
  APPROVE_MODULE_SCORE,
  APPROVE_MODULE_SCORE_SUCCESS,
  APPROVE_MODULE_SCORE_FAIL,
  GET_MODULE_SCORE_BYID_SUCCESS,
  GET_MODULE_SCORE_BYID,
  GET_MODULE_SCORE_BYID_FAIL,
} from "./actionTypes"

export const getModules = () => ({
  type: GET_MODULES,
})

export const getModulesSuccess = modulesDT => ({
  type: GET_MODULES_SUCCESS,
  payload: modulesDT,
})

export const getModulesFail = error => ({
  type: GET_MODULES_FAIL,
  payload: error,
})

export const addNewModule = (data) => ({
  type: ADD_NEW_MODULE,
  payload: {data},
})

export const addModuleSuccess = user => ({
  type: ADD_MODULE_SUCCESS,
  payload: user,
})

export const addModuleFail = error => ({
  type: ADD_MODULE_FAIL,
  payload: error,
})



export const updateModule =  (module) => ({
  type: UPDATE_MODULE,
  payload: {module},
})

export const updateModuleSuccess = user => ({
  type: UPDATE_MODULE_SUCCESS,
  payload: user,
})

export const updateModuleFail = error => ({
  type: UPDATE_MODULE_FAIL,
  payload: error,
})

export const deleteModule = module => ({
  type: DELETE_MODULE,
  payload: module,
})

export const deleteModuleSuccess = module => ({
  type: DELETE_MODULE_SUCCESS,
  payload: module,
})

export const deleteModuleFail = error => ({
  type: DELETE_MODULE_FAIL,
  payload: error,
})

export const deactivateModule = (module, history) => ({
  type: DEACTIVATE_MODULE,
  payload: {module, history},
})

export const deactivateModuleSuccess = module => ({
  type: DEACTIVATE_MODULE_SUCCESS,
  payload: module,
})

export const deactivateModuleFail = error => ({
  type: DEACTIVATE_MODULE_FAIL,
  payload: error,
})



export const approveModule = module => ({
  type: APPROVE_MODULE,
  payload: module,
})

export const approveModuleSuccess = user => ({
  type: APPROVE_MODULE_SUCCESS,
  payload: user,
})

export const approveModuleFail = error => ({
  type: APPROVE_MODULE_FAIL,
  payload: error,
})


export const saveTabData = (questionData, tabData, logicData, moduleData, history) => ({
  type: SAVE_TAB_DATA_DT,
  payload: {questionData, tabData, logicData, moduleData, history},
})

export const saveTabDataSuccess = tabData => ({
  type: SAVE_TAB_DATA_DT_SUCCESS,
  payload: tabData,
})

export const saveTabDataFail = error => ({
  type: SAVE_TAB_DATA_DT_FAIL,
  payload: error,
})




export const saveDraftTabData = (questionData, tabData, logicData, moduleData, history) => ({
  type: SAVE_DRAFT_TAB_DATA_DT,
  payload: {questionData, tabData, logicData, moduleData, history},
})

export const saveDraftTabDataSuccess = tabData => ({
  type: SAVE_DRAFT_TAB_DATA_DT_SUCCESS,
  payload: tabData,
})

export const saveDraftTabDataFail = error => ({
  type: SAVE_DRAFT_TAB_DATA_DT_FAIL,
  payload: error,
})


export const getDraftTabData = (moduleData, history) => ({
  type: GET_DRAFT_TAB_DATA_DT,
  payload: {moduleData, history},
})

export const getDraftTabDataSuccess = draftData => ({
  type: GET_DRAFT_TAB_DATA_DT_SUCCESS,
  payload: draftData,
})

export const getDraftTabDataFail = error => ({
  type: GET_DRAFT_TAB_DATA_DT_FAIL,
  payload: error,
})



export const getActiveModules = () => ({
  type: GET_ACTIVE_MODULES
})

export const getActiveModulesSuccess = activeModulesData => ({
  type: GET_ACTIVE_MODULES_SUCCESS,
  payload: activeModulesData,
})

export const getActiveModulesFail = error => ({
  type: GET_ACTIVE_MODULES_FAIL,
  payload: error,
})


export const getEventsDT = () => ({
  type: GET_EVENTS_DT,
})

export const getEventsDTSuccess = eventsDT => ({
  type: GET_EVENTS_DT_SUCCESS,
  payload: eventsDT,
})

export const getEventsDTFail = error => ({
  type: GET_EVENTS_DT_FAIL,
  payload: error,
})


export const addNewEventDT = (data, history) => ({
  type: ADD_NEW_EVENT_DT,
  payload: {data, history},
})

export const addEventSuccessDT = user => ({
  type: ADD_EVENT_DT_SUCCESS,
  payload: user,
})

export const addEventFailDT = error => ({
  type: ADD_EVENT_DT_FAIL,
  payload: error,
})


export const deleteEventDT = (event) => ({
  type: DELETE_EVENT_DT,
  payload: {event},
})

export const deleteEventDTSuccess = event => ({
  type: DELETE_EVENT_DT_SUCCESS,
  payload: event,
})

export const deleteEventDTFail = error => ({
  type: DELETE_EVENT_DT_FAIL,
  payload: error,
})


export const approveEventDT = event => ({
  type: APPROVE_EVENT_DT,
  payload: event,
})

export const approveEventDTSuccess = event => ({
  type: APPROVE_EVENT_DT_SUCCESS,
  payload: event,
})

export const approveEventDTFail = error => ({
  type: APPROVE_EVENT_DT_FAIL,
  payload: error,
})


export const updateEventDT =  (event) => ({
  type: UPDATE_EVENT_DT,
  payload: {event},
})

export const updateEventDTSuccess = event => ({
  type: UPDATE_EVENT_DT_SUCCESS,
  payload: event,
})

export const updateEventDTFail = error => ({
  type: UPDATE_EVENT_DT_FAIL,
  payload: error,
})



export const getTabDataEvent = (event) => ({
  type: GET_TABDATA_EVENT,
  payload: {event},
})

export const getTabDataEventSuccess = tabDataEvent => ({
  type: GET_TABDATA_EVENT_SUCCESS,
  payload: tabDataEvent,
})

export const getTabDataEventFail = error => ({
  type: GET_TABDATA_EVENT_FAIL,
  payload: error,
})



export const saveTabAnswersData = (tabData,eventData,history, moduleScores) => ({
  type: SAVE_TAB_ANSWER_DATA_DT,
  payload: {tabData,eventData, history, moduleScores},
})

export const saveTabAnswersDataSuccess = tabData => ({
  type: SAVE_TAB_ANSWER_DATA_DT_SUCCESS,
  payload: tabData,
})

export const saveTabAnswersDataFail = error => ({
  type: SAVE_TAB_ANSWER_DATA_DT_FAIL,
  payload: error,
})


export const addNewModuleScore = (module) => ({
  type: ADD_NEW_MODULE_SCORE,
  payload: {module},
})

export const addNewModuleScoreSuccess = module => ({
  type: ADD_NEW_MODULE_SCORE_SUCCESS,
  payload: module,
})

export const addNewModuleScoreFail = error => ({
  type: ADD_NEW_MODULE_SCORE_FAIL,
  payload: error,
})


export const getModuleScores = () => ({
  type: GET_MODULE_SCORES,
})

export const getModuleScoresSuccess = moduleScores => ({
  type: GET_MODULE_SCORES_SUCCESS,
  payload: moduleScores,
})

export const getModuleScoresFail = error => ({
  type: GET_MODULE_SCORES_FAIL,
  payload: error,
})

export const deleteModuleScore = modulescore => ({
  type: DELETE_MODULE_SCORE,
  payload: modulescore,
})

export const deleteModuleScoreSuccess = module => ({
  type: DELETE_MODULE_SCORE_SUCCESS,
  payload: module,
})

export const deleteModuleScoreFail = error => ({
  type: DELETE_MODULE_SCORE_FAIL,
  payload: error,
})



export const approveModuleScore = modulescore => ({
  type: APPROVE_MODULE_SCORE,
  payload: modulescore,
})

export const approveModuleScoreSuccess = modulescore => ({
  type: APPROVE_MODULE_SCORE_SUCCESS,
  payload: modulescore,
})

export const approveModuleScoreFail = error => ({
  type: APPROVE_MODULE_SCORE_FAIL,
  payload: error,
})


export const getModuleScoreById = module => ({
  type: GET_MODULE_SCORE_BYID,
  payload: module,
})

export const getModuleScoreByIdSuccess = modulescoring => ({
  type: GET_MODULE_SCORE_BYID_SUCCESS,
  payload: modulescoring,
})

export const getModuleScoreByIdFail = error => ({
  type: GET_MODULE_SCORE_BYID_FAIL,
  payload: error,
})



export const setLoadingIndicatorDT = (loading) => {
  // console.log(loading)
  return {
    type: SET_LOADING_INDICATOR_DT,
    payload: loading,
  };
};
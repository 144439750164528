import React from 'react';

const Hourglass = ({ style}) => {
  return (
    <div id="hourglass" className="fa-stack fa-4x col-sm-4">
    <i className="fa fa-stack-1x fa-hourglass-start"></i>
    <i className="fa fa-stack-1x fa-hourglass-half"></i>
    <i className="fa fa-stack-1x fa-hourglass-end"></i>
    <i className="fa fa-stack-1x fa-hourglass-end"></i>
    <i className="fa fa-stack-1x fa-hourglass-o"></i>
  </div>
  );
};

export default Hourglass;
import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {  Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Popover, PopoverHeader, PopoverBody} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";


// action
import { registerUser, apiError } from "../../store/actions";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "./CarouselPageRegister";
import Loader from "components/Common/Loader";

const Register = () => {


  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShowConfirm, setPasswordShowConfirm] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverOpenUsername, setPopoverOpenUsername] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const toggleUsername = () => setPopoverOpenUsername(!popoverOpenUsername);

  const closePopoverUsername = () => {
    setPopoverOpenUsername(false);
  };

  const toggle = () => setPopoverOpen(!popoverOpen);

  const closePopover = () => {
    setPopoverOpen(false);
  };
  //meta title
  document.title="Register | Smarteeva";

  const dispatch = useDispatch();

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }));

  //form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      password: '',
      confirmpassword : ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
      confirmpassword: Yup.string().required("Please Re-enter Your Password").oneOf([Yup.ref('password'), null], 'Passwords must match'),
      
    }),
    onSubmit: async (values,{ resetForm }) => {
      setIsLoading(true)
      await dispatch(registerUser(values));
        // resetForm();
      // } 
    }
  });




  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  useEffect(() => {
    if(user && user){
      validation.resetForm()
      setIsLoading(false)

    }
  }, [user]);
 

  useEffect(() => {
    if(user && user){
      validation.resetForm()
      setIsLoading(false)

    }
  }, [user]);

  useEffect(() => {
  
    setIsLoading(loading)
 
}, [loading]);
 

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={7}>
              <div className="auth-full-page-content p-md-5 p-4 centerJustCustom">
                <div className="w-100 maxWidthCustom">
                  <div className="d-flex flex-column h-100 centerJustCustom">
                    <div className="mb-2 mb-md-3 centerCustom">
                      <Link to="dashboard" className="d-block auth-logo mb-3">
                        <img
                          src={logodark}
                          alt=""
                          height="70"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="18==70"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="">
                      <div className="centerTextCustom">
                        <h5 className="text-primary welcome-text">Register account</h5>
                        <p className="text-muted">
                        Create your Smarteeva account now.
                        </p>
                      </div>

                      <div className="mt-4">

                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >

{user && user ? (
                        <Alert color="success">
                          You've been successfully registered. We've sent you an email at {user.user_email}. 
                          Kindly verify your email before login.
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}
                       
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Username&nbsp;</Label>
                             <span>
                             <i className="mdi mdi-information-outline" id="Popover-username"></i>

                            <Popover
        placement="bottom"
        isOpen={popoverOpenUsername}
        target="Popover-username"
        toggle={toggleUsername}
        onBlur={closePopoverUsername} trigger="legacy">
        <PopoverHeader>Username Policy</PopoverHeader>
        <PopoverBody>
        Username must not contain any spaces.
        </PopoverBody>
      </Popover>

                            </span>
                            <Input
                              name="username"
                              type="text"
                              placeholder="Enter username"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username && validation.errors.username ? true : false
                              }
                            />
                            {validation.touched.username && validation.errors.username ? (
                              <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Password</Label> <span>
                            {/* <button className="btn btn-light " type="button" id="password-addon"> */}
                                <i className="mdi mdi-information-outline" id="Popover"></i>
                                {/* </button> */}

                                <Popover
        placement="bottom"
        isOpen={popoverOpen}
        target="Popover"
        toggle={toggle}
        onBlur={closePopover} trigger="legacy"
      
      >
        <PopoverHeader>Password Policy</PopoverHeader>
        <PopoverBody>
        <b>Password minimum length : </b><br/>
 * 8 character(s) <br/>
 <b>Password requirements : </b><br/>
 * Contains at least 1 number <br/>
 * Contains at least 1 special character <br/>
 * Contains at least 1 uppercase letter <br/>
 * Contains at least 1 lowercase letter <br/>
        </PopoverBody>
      </Popover>
                            </span>
                            <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              type={passwordShow ? "text" : "password"}
                              placeholder="Enter password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                             <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i></button>

                                

                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Confirm Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="confirmpassword"
                              type={passwordShowConfirm ? "text" : "password"}
                              placeholder="Re-enter password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirmpassword || ""}
                              invalid={
                                validation.touched.confirmpassword && validation.errors.confirmpassword ? true : false
                              }
                            />

                              <button onClick={() => setPasswordShowConfirm(!passwordShowConfirm)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i></button>

                            {validation.touched.confirmpassword && validation.errors.confirmpassword ? (
                              <FormFeedback type="invalid">{validation.errors.confirmpassword}</FormFeedback>
                            ) : null}
                          </div>
                          </div>

                          <div>
                            <p className="mb-0">
                              By registering you agree to the Smarteeva{" "}
                              <a href="#" className="text-primary">
                                Terms of Use
                              </a>
                            </p>
                          </div>

                          <div className="mt-4 d-grid">
                            <button
                              className="btn btn-primary waves-effect waves-light "
                              type="submit"
                            >
                              Create your Account
                            </button>
                          </div>

                        </Form>


                        {/* <Form action="dashboard">

                          <div className="mt-4 text-center">
                            <h5 className="font-size-14 mb-3">Sign up using</h5>

                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary me-1"
                                >
                                  <i className="mdi mdi-facebook"></i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-info text-white border-info me-1"
                                >
                                  <i className="mdi mdi-twitter"></i>
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                >
                                  <i className="mdi mdi-google"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Form> */}

                        <div className="mt-4 text-center">
                          <p>
                            Already have an account ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Login
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

              
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Loader isLoading={isLoading} /> 
    </React.Fragment>
  );
};

export default Register;

import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, GET_PROFILE_SUCCESS, GET_PROFILE_FAIL, GET_COUNTRY_PROFILE_SUCCESS, GET_PHONECODE_PROFILE_SUCCESS, GET_PHONECODE_PROFILE_FAIL, GET_COUNTRY_PROFILE_FAIL, GET_STATE_PROFILE_SUCCESS, GET_STATE_PROFILE_FAIL, GET_CITY_PROFILE_SUCCESS, GET_CITY_PROFILE_FAIL, GET_DEPARTMENT_PROFILE_SUCCESS, GET_DEPARTMENT_PROFILE_FAIL, GET_DESIGNATION_PROFILE_SUCCESS, GET_DESIGNATION_PROFILE_FAIL, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL, SET_LOADING_INDICATOR_PROFILE } from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  user : {},
  phonecode: [],
  country: [],
  states: [],
  city: [],
  department : [],
  designation : [],
  loading : false
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload, loading : false };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload , loading : false};
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null ,loading : false};
      break;
      case GET_PROFILE_SUCCESS:
        return {
          ...state,
          user: action.payload,
          loading : false
        }
        case GET_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading : false
      }

      case GET_PHONECODE_PROFILE_SUCCESS:
        return {
            ...state,
            phonecode: action.payload,
            loading : false
        };

    case GET_PHONECODE_PROFILE_FAIL:
        return {
            ...state,
            error: action.payload,
            loading : false
        };

        case GET_COUNTRY_PROFILE_SUCCESS:
          return {
              ...state,
              country: action.payload,
              loading : false
          };

      case GET_COUNTRY_PROFILE_FAIL:
          return {
              ...state,
              error: action.payload,
              loading : false
          };



      case GET_STATE_PROFILE_SUCCESS:
          return {
              ...state,
              states: action.payload,
              loading : false
          };
        

      case GET_STATE_PROFILE_FAIL:
          return {
              ...state,
              error: action.payload,
              loading : false
          };


      case GET_CITY_PROFILE_SUCCESS:
          return {
              ...state,
              city: action.payload,
              loading : false
          };

      case GET_CITY_PROFILE_FAIL:
          return {
              ...state,
              error: action.payload,
              loading : false
          };

          case GET_DEPARTMENT_PROFILE_SUCCESS:
            return {
                ...state,
                department: action.payload,
                loading : false
            };
  
        case GET_DEPARTMENT_PROFILE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading : false
            };


            case GET_DESIGNATION_PROFILE_SUCCESS:
              return {
                  ...state,
                  designation: action.payload,
                  loading : false
              };
    
          case GET_DESIGNATION_PROFILE_FAIL:
              return {
                  ...state,
                  error: action.payload,
                  loading : false
              };

              case UPDATE_PROFILE_SUCCESS:
                return {
                  ...state,
                  success: action.payload,
                  loading : false
                }
                case UPDATE_PROFILE_FAIL:
              return {
                ...state,
                error: action.payload,
                loading : false
              }
  
              case SET_LOADING_INDICATOR_PROFILE:
                state = {
                  ...state,
                  loading: action.payload,
                }
                break

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;

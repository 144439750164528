import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

//import images
import avatar from "../../assets/images/users/avatar-1.jpg";
import user1 from "../../assets/images/users/user_icon.png";


const Section = (props) => {
    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col lg={12}>
                    <div className="d-flex align-items-center">
                        <img src={props.userData.fld_photo == null || props.userData.fld_photo == '' ? user1 : props.userData.fld_photo}
                         alt="" className="avatar-sm rounded" />
                        <div className="ms-3 flex-grow-1">
                            <h5 className="mb-2 card-title">Hello, {props.userData.fld_fname == '' ? props.userData.fld_username : props.userData.fld_fname+' '+props.userData.fld_lname}</h5>
                            <p className="text-muted mb-0">Corporate Admin Dashboard</p>
                        </div>
                        <div>
                            <Link to="#" className="btn btn-primary"><i className="bx bx-user-plus align-middle font-21"></i> Add New Users</Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Section;
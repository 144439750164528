import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG, GET_PROFILE_SUCCESS, GET_PROFILE, GET_PROFILE_FAIL, GET_PHONECODE_PROFILE, GET_PHONECODE_PROFILE_SUCCESS, GET_PHONECODE_PROFILE_FAIL, GET_COUNTRY_PROFILE, GET_COUNTRY_PROFILE_SUCCESS, GET_COUNTRY_PROFILE_FAIL, GET_STATE_PROFILE, GET_STATE_PROFILE_SUCCESS, GET_STATE_PROFILE_FAIL, GET_CITY_PROFILE, GET_CITY_PROFILE_SUCCESS, GET_CITY_PROFILE_FAIL, GET_DEPARTMENT_PROFILE, GET_DEPARTMENT_PROFILE_SUCCESS, GET_DEPARTMENT_PROFILE_FAIL, GET_DESIGNATION_PROFILE, GET_DESIGNATION_PROFILE_SUCCESS, GET_DESIGNATION_PROFILE_FAIL, UPDATE_PROFILE_FAIL, UPDATE_PROFILE, UPDATE_PROFILE_SUCCESS, SET_LOADING_INDICATOR_PROFILE } from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}


export const getProfileFail = error => ({
  type: GET_PROFILE_FAIL,
  payload: error,
})

export const getProfile = user => ({
  type: GET_PROFILE,
  payload : user
})

export const getProfileSuccess = user => ({
  type: GET_PROFILE_SUCCESS,
  payload: user,
})


export const updateProfileFail = error => ({
  type: UPDATE_PROFILE_FAIL,
  payload: error,
})

export const updateProfile = user => ({
  type: UPDATE_PROFILE,
  payload : user
})

export const updateProfileSuccess = success => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: success,
})



export const getPhonecodeProfile = () => ({
  type: GET_PHONECODE_PROFILE,
})

export const getPhonecodeProfileSuccess = phonecode => ({
  type: GET_PHONECODE_PROFILE_SUCCESS,
  payload: phonecode,
})

export const getPhonecodeProfileFail = error => ({
  type: GET_PHONECODE_PROFILE_FAIL,
  payload: error,
})


export const getCountryProfile = () => ({
  type: GET_COUNTRY_PROFILE,
})

export const getCountryProfileSuccess = country => ({
  type: GET_COUNTRY_PROFILE_SUCCESS,
  payload: country,
})

export const getCountryProfileFail = error => ({
  type: GET_COUNTRY_PROFILE_FAIL,
  payload: error,
})


export const getStateProfile = states => ({
  type: GET_STATE_PROFILE,
  payload: states,
})

export const getStateProfileSuccess = states => ({
  type: GET_STATE_PROFILE_SUCCESS,
  payload: states,
})

export const getStateProfileFail = error => ({
  type: GET_STATE_PROFILE_FAIL,
  payload: error,
})


export const getCityProfile = city => ({
  type: GET_CITY_PROFILE,
  payload: city,
})

export const getCityProfileSuccess = city => ({
  type: GET_CITY_PROFILE_SUCCESS,
  payload: city,
})


export const getCityProfileFail = error => ({
  type: GET_CITY_PROFILE_FAIL,
  payload: error,
})


export const getDepartmentProfile = () => ({
  type: GET_DEPARTMENT_PROFILE,
})

export const getDepartmentProfileSuccess = department => ({
  type: GET_DEPARTMENT_PROFILE_SUCCESS,
  payload: department,
})

export const getDepartmentProfileFail = error => ({
  type: GET_DEPARTMENT_PROFILE_FAIL,
  payload: error,
})


export const getDesignationProfile = () => ({
  type: GET_DESIGNATION_PROFILE,
})

export const getDesignationProfileSuccess = designation => ({
  type: GET_DESIGNATION_PROFILE_SUCCESS,
  payload: designation,
})

export const getDesignationProfileFail = error => ({
  type: GET_DESIGNATION_PROFILE_FAIL,
  payload: error,
})


export const setLoadingIndicatorProfile = (loading) => {
  // console.log(loading)
  return {
    type: SET_LOADING_INDICATOR_PROFILE,
    payload: loading,
  };
};



import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_CORPORATE_DD_SUCCESS,
  GET_CORPORATE_DD_FAIL,
  GET_PHONECODE_LIST_USER_SUCCESS,
  GET_PHONECODE_LIST_USER_FAIL,
  GET_COUNTRY_LIST_USER_SUCCESS,
  GET_CITY_LIST_USER_FAIL,
  GET_CITY_LIST_USER_SUCCESS,
  GET_STATE_LIST_USER_FAIL,
  GET_STATE_LIST_USER_SUCCESS,
  GET_COUNTRY_LIST_USER_FAIL,
  GET_COMPANY_LIST_USER_SUCCESS,
  GET_COMPANY_LIST_USER_FAIL,
  GET_ACTIVE_USER_ORG_SUCCESS,
  GET_ACTIVE_USER_ORG_FAIL,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  APPROVE_USER_SUCCESS,
  APPROVE_USER_FAIL,
  SEND_USER_INVITE_SUCCESS,
  SEND_USER_INVITE_FAIL,
  SET_LOADING_INDICATOR_USER,
  RESET_USER_DETAILS,
  GET_GROUP_PERMISSION_SUCCESS,
  GET_GROUP_PERMISSION_FAIL,
  UPDATE_GROUP_PERMISSION_SUCCESS,
  UPDATE_GROUP_PERMISSION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userProfile: {},
  error: {},
  corpDropList: [],
  phonecode: [],
  country: [],
  state: [],
  city: [],
  companys: [],
  active_org_users : [],
  userdetails : [],
  grouppermissions : [],
  permissionSuccess : '',
  loading : false
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading : false
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading : false
      }

    case ADD_USER_SUCCESS:

      return {
        ...state,
        users: action.payload,
        loading : false
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading : false
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        loading : false
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users:action.payload,
        loading : false
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading : false
      }

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users,
        loading : false
      }

    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading : false
      }

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading : false
      }

    case GET_CORPORATE_DD_SUCCESS:
      return {
        ...state,
        corpDropList: action.payload,
        loading : false
      }

    case GET_CORPORATE_DD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading : false
      }
    case GET_PHONECODE_LIST_USER_SUCCESS:
      return {
        ...state,
        phonecode: action.payload,
        loading : false
      };

    case GET_PHONECODE_LIST_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading : false
      };

    case GET_COUNTRY_LIST_USER_SUCCESS:
      return {
        ...state,
        country: action.payload,
        loading : false
      };

    case GET_COUNTRY_LIST_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading : false
      };



    case GET_STATE_LIST_USER_SUCCESS:
      return {
        ...state,
        states: action.payload,
        loading : false
      };


    case GET_STATE_LIST_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading : false
      };


    case GET_CITY_LIST_USER_SUCCESS:
      return {
        ...state,
        city: action.payload,
        loading : false
      };

    case GET_CITY_LIST_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading : false
      };

    case GET_COMPANY_LIST_USER_SUCCESS:
      return {
        ...state,
        companys: action.payload,
        loading : false
      };

    case GET_COMPANY_LIST_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading : false
      };
      case GET_ACTIVE_USER_ORG_SUCCESS:
        return {
          ...state,
          active_org_users: action.payload,
          loading : false
        };
  
      case GET_ACTIVE_USER_ORG_FAIL:
        return {
          ...state,
          error: action.payload,
          loading : false
        };

        case GET_USER_DETAILS_SUCCESS:
          return {
              ...state,
              userdetails: action.payload,
              loading : false
          };
          case GET_USER_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading : false
            };

            case APPROVE_USER_SUCCESS:
              return {
                  ...state,
                  users: state.users,
                  loading : false
              };
  
          case APPROVE_USER_FAIL:
              return {
                  ...state,
                  error: action.payload,
                  loading : false
              };

              case SEND_USER_INVITE_SUCCESS:
                return {
                    ...state,
                    users: state.users,
                    loading : false
                };
    
            case SEND_USER_INVITE_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading : false
                };

                case RESET_USER_DETAILS:
                  return {
                      ...state,
                      userdetails: [],
                      loading : false
                  };

                  case GET_GROUP_PERMISSION_SUCCESS:
                    return {
                      ...state,
                      grouppermissions: action.payload,
                      loading : false
                    }
              
                  case GET_GROUP_PERMISSION_FAIL:
                    return {
                      ...state,
                      error: action.payload,
                      loading : false
                    }
              

                    case UPDATE_GROUP_PERMISSION_SUCCESS:

                    return {
                      ...state,
                      permissionSuccess: action.payload,
                      loading : false
                    }
              
                  case UPDATE_GROUP_PERMISSION_FAIL:
                    return {
                      ...state,
                      error: action.payload,
                      loading : false
                    }

                    
                case SET_LOADING_INDICATOR_USER:
                        return {
                            ...state,
                            loading: action.payload
                        };
        
    default:
      return state
  }
}

export default contacts

export const authUser = 'authUser'
export const sessionStorage = 'sessionStorage'
export const sessionExpiration = 'sessionExpiration'
export const tspFullAccessToken = 'tspFullAccessToken'


// export const ts_token_api_url='https://3.144.45.14/api/get_ts_token/'
export const ts_token_api_url='https://mdrengineapi.com/api/get_ts_token/'
// export const ts_token_api_url='http://localhost:7000/get_ts_token/'



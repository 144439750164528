import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { 
  ADD_NEW_EVENT_DT,
  ADD_NEW_MODULE,
  ADD_NEW_MODULE_SCORE,
  APPROVE_EVENT_DT,
  APPROVE_MODULE,
  APPROVE_MODULE_SCORE,
  DEACTIVATE_MODULE,
  DELETE_EVENT_DT,
  DELETE_MODULE,
  DELETE_MODULE_SCORE,
  GET_ACTIVE_MODULES,
  GET_DRAFT_TAB_DATA_DT,
  GET_EVENTS_DT,
GET_MODULES,
GET_MODULE_SCORES,
GET_MODULE_SCORE_BYID,
GET_TABDATA_EVENT,
SAVE_DRAFT_TAB_DATA_DT,
SAVE_TAB_ANSWER_DATA_DT,
SAVE_TAB_DATA_DT,
UPDATE_EVENT_DT,
UPDATE_MODULE

 } from "./actionTypes"

import {
  setLoadingIndicatorDT,
  getModulesSuccess,
  getModulesFail,
  addModuleSuccess,
  addModuleFail,
  deleteModuleFail,
  updateModuleSuccess,
  updateModuleFail,
  approveModuleFail,
  approveModuleSuccess,
  saveTabDataSuccess,
  saveTabDataFail,
  saveDraftTabDataFail,
  saveDraftTabDataSuccess,
  getDraftTabDataSuccess,
  getDraftTabDataFail,
  getActiveModulesSuccess,
  getActiveModulesFail,
  getEventsDTSuccess,
  getEventsDTFail,
  addEventSuccessDT,
  addEventFailDT,
  approveEventDTSuccess,
  approveEventDTFail,
  deleteEventDTSuccess,
  deleteEventDTFail,
  updateEventDTSuccess,
  updateEventDTFail,
  getTabDataEventSuccess,
  getTabDataEventFail,
  saveTabAnswersDataFail,
  saveTabAnswersDataSuccess,
  addNewModuleScoreSuccess,
  addNewModuleScoreFail,
  getModuleScoresSuccess,
  getModuleScoresFail,
  deleteModuleScoreFail,
  approveModuleScoreSuccess,
  approveModuleScoreFail,
  getModuleScoreByIdSuccess,
  getModuleScoreByIdFail,
} from "./actions"

//Include Both Helper File with needed methods
import { 
  getModuleList, 
  addModuleApiCall,
  deleteModule,
  updateModuleApiCall,
  approveModule,
  addNewTabData,
  addNewQuestionData,
  addNewQuestionItemData,
  onSaveDraftDTData,
  onGetDraftDTData,
  onGetActiveModule,
  getEventList,
  addEventApiCall,
  approveEventDT,
  deleteEventDT,
  updateEventApiCall,
  getTabDataEventApiCall,
  getQuestionDataEventApiCall,
  getQuestionItemEventApiCall,
  saveTabAnsDataApi,
  saveTabAnsItemDataApi,
  removeTabAnsDataApi,
  addModuleScoreApiCall,
  removeModuleScoreDataApi,
  getModuleScoreList,
  deleteModuleScore,
  approveModuleScore,
  getAnswerEventApiCall,
  getAnswerItemEventApiCall,
  getModuleScoreByid
 } from "../../helpers/backend_helper"
import moment from "moment"
import { authUser } from "helpers/dataConfig"
import { v4 as uuid } from "uuid";

function* fetchModules() {

  yield put(setLoadingIndicatorDT(true));

  const modData = {
    corpid : JSON.parse(localStorage.getItem(authUser)).fld_companyid
  }

  try {
    const response = yield call(getModuleList, modData)
    // console.log(response.data)
    yield put(getModulesSuccess(response.data))
} catch (error) {
    // console.log(error)
    yield put(getModulesFail(error))
}
 
}


function* onAddNewModule({ payload: {data} }) {

  // console.log(data)
  yield put(setLoadingIndicatorDT(true));

  const additional = {
    status : 'Active',
    approvalstatus : 'Pending',
    createdon : moment().format(),
    createdby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    updatedon :  moment().format(),
    updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    corpid : JSON.parse(localStorage.getItem(authUser)).fld_companyid
}
const modData = {...data, ...additional}

  try {
      const response = yield call(addModuleApiCall, modData)
      yield put(addModuleSuccess(response.data))
     
      try {
          yield call(fetchModules)
      // history('/user-list');

      } catch (error) {
          yield put(addModuleFail(error.message))

      }

  } catch (error) {
    // console.log(error.message)
      yield put(addModuleFail(error.message))
  }
}

function* onDeleteModuleData({ payload: module }) {

  yield put(setLoadingIndicatorDT(true));

  const moduleData = {
    moduleid : module,
    lastupdatedon : moment().format(),
    lastupdatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid
}

try {
    const response = yield call(deleteModule, moduleData)
    // console.log(response)
    // history('/corporate-list');
    try {
    yield call(fetchModules)
} catch (error) {
    yield put(deleteModuleFail(error))
}

    // yield put(deleteCompanySuccess(response))
} catch (error) {
    yield put(deleteModuleFail(error))
}
}


function* onUpdateModuleData({ payload: {module} }) {

  // console.log(data)
  yield put(setLoadingIndicatorDT(true));

  const additional = {
    approvalstatus : 'Pending',
    updatedon :  moment().format(),
    updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
}
const modData = {...module, ...additional}
// console.log(modData)

  try {
      const response = yield call(updateModuleApiCall, modData)
      yield put(updateModuleSuccess(response.data))
     
      try {
          yield call(fetchModules)
      // history('/user-list');

      } catch (error) {
          yield put(updateModuleFail(error.message))

      }

  } catch (error) {
    // console.log(error.message)
      yield put(updateModuleFail(error.message))
  }
}



function* onApproveModuleData({ payload: module }) {

  yield put(setLoadingIndicatorDT(true));

  const moduleData = {
    moduleid : module,
    lastupdatedon : moment().format(),
    lastupdatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid
}

try {
    const response = yield call(approveModule, moduleData)
    yield put(approveModuleSuccess(response.data))

    // console.log(response)
    // history('/corporate-list');
    try {
    yield call(fetchModules)
} catch (error) {
    yield put(approveModuleFail(error))
}

    // yield put(deleteCompanySuccess(response))
} catch (error) {
    yield put(approveModuleFail(error))
}
}

function* onDeactivateModuleData({ payload: {module, history} }) {

  yield put(setLoadingIndicatorDT(true));

  const moduleData = {
    moduleid : module.FLD_MODULEID,
    status : module.FLD_STATUS == 'Archived' ? 'Active' : 'Archived',
    lastupdatedon : moment().format(),
    lastupdatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid
}

try {
    const response = yield call(deleteModule, moduleData)
    // console.log(response)
    // history('/corporate-list');
    // try {
      yield call(fetchModules)
      // yield put(setLoadingIndicatorDT(false));
      history('/decision-tree');
    
// } catch (error) {
//     yield put(deleteModuleFail(error))
// }

    // yield put(deleteCompanySuccess(response))
} catch (error) {
    yield put(deleteModuleFail(error))
}
}


function* onSaveTabData({ payload: {questionData, tabData, logicData, moduleData, history} }) {

  // console.log(tabData)
  yield put(setLoadingIndicatorDT(true));

  const draftData = {
    moduleid : moduleData.FLD_MODULEID,
    questiondata : JSON.stringify(questionData),
    tabdata : JSON.stringify(tabData),
    logicdata : JSON.stringify(logicData),
    corpid : JSON.parse(localStorage.getItem(authUser)).fld_companyid
  }

  try {
    const res = yield call(onSaveDraftDTData, draftData)
    // yield put(saveDraftTabDataSuccess('Configuration saved successfully.'))

    const additionalData = {
    status : 'Active',
    approvalstatus : 'Pending',
    createdon : moment().format(),
    createdby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    updatedon :  moment().format(),
    updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    corpid : JSON.parse(localStorage.getItem(authUser)).fld_companyid,
    eventid : 0,
    moduleid : moduleData.FLD_MODULEID,
}
let modData = {'tabdata' : tabData, 'additional' : additionalData}
// console.log(modData)
try {
      const response = yield call(addNewTabData, modData)
      console.log(response)
      yield put(saveTabDataSuccess('Configuration published successfully.'))
}catch(error){
  yield put(saveTabDataFail(error))
}

// let count1 = 0
// let count2 = 0
// let count3 = 0

// let tabDt = {}
// let modData = {}
// let qData = {}
// let quesArr = []
// let newQData = {}
// let qItem = {}
// let newQItem = {}


//   for(let i =0; i< tabData.length; i++){

//     quesArr = tabData[i].questionData

//     tabDt = {
//       eventid : 0,
//       moduleid : moduleData.FLD_MODULEID,  
//       tabno :tabData[i].tabno,
//       tabname : tabData[i].tabname,
//       tabnameedit :  tabData[i].tabnameedit
//     }

//     modData = {...tabDt, ...additionalData}

//     try {
//     const response = yield call(addNewTabData, modData)
//     count1++
//     count2 = 0
//     count3 = 0
   
//     for(let j=0; j < quesArr.length; j++){

//       // console.log(quesArr[j])
      
//     qData = {
//       eventid : 0,
//       tabid : response.data,
//       tabno : tabData[i].tabno,
//       moduleid : moduleData.FLD_MODULEID, 
//       questiontext : quesArr[j].questionText,
//       commentsrequired : quesArr[j].commentsrequired,
//       num : quesArr[j].num,
//       required : quesArr[j].required,
//       serial : quesArr[j].serial,
//       showcomments : quesArr[j].showcomments,
//       subtype : quesArr[j].subType,
//       type : quesArr[j].type,
//       nextquestionid : j<quesArr.length-1 ?  quesArr[j+1].num  : 0
//     }

//     newQData = {...qData, ...additionalData}

//     try {

//     const response1 = yield call(addNewQuestionData, newQData)
//     console.log(response1.data)
//     count2++
//     count3 = 0

//     // console.log(quesArr[j].type == 'dropdown' || quesArr[j].type == 'multiplechoice' ||  quesArr[j].type == 'yes/no')


//     if(quesArr[j].type == 'dropdown' || quesArr[j].type == 'multiplechoice' ||  quesArr[j].type == 'yes/no'){
//       if(quesArr[j].choices.length > 0){
//       for(let k =0 ; k< quesArr[j].choices.length ; k++){

//         qItem ={
//          questionid : response1.data,
//          tabid : response.data,
//          tabno : tabData[i].tabno,
//          moduleid : moduleData.FLD_MODULEID, 
//          value : quesArr[j].choices[k].value,
//          description : '',
//          duedate : quesArr[j].choices[k].duedate,
//          score : quesArr[j].choices[k].score,
//          nextquestionid :  j<quesArr.length-1 ?  quesArr[j+1].num  : 0
//         }

//         newQItem = {...qItem, ...additionalData}


//         const response2 = yield call(addNewQuestionItemData, newQItem)
//         count3++

//         if(count1 == tabData.length && count2 == quesArr.length && count3 == quesArr[j].choices.length){
//           yield put(saveTabDataSuccess('Configuration published successfully.'))
//         }

//       }
//     }else{
//       if(count1 == tabData.length && count2 == quesArr.length){
//         yield put(saveTabDataSuccess('Configuration published successfully.'))
//       }
//     }

//     }else{

//       // console.log(quesArr[j])

//       qItem ={
//         questionid : response1.data,
//         tabid : response.data,
//         tabno : tabData[i].tabno,
//         moduleid : moduleData.FLD_MODULEID, 
//         value : quesArr[j].value,
//         description : '',
//         duedate : quesArr[j].duedate,
//         score : quesArr[j].score,
//         nextquestionid :  j<quesArr.length-1 ?  quesArr[j+1].num  : 0
//        }

  

//        newQItem = {...qItem, ...additionalData}

//        const response2 = yield call(addNewQuestionItemData, newQItem)

//        if(count1 == tabData.length && count2 == quesArr.length){
//         yield put(saveTabDataSuccess('Configuration published successfully.'))
//       }
//     }

//   }
//     catch (error) {
//       // console.log(error)
//       count2++
//     }
//   }
//     // if(count1 == tabData.length){
//     //   console.log(response.data)
//     //   yield put(saveTabDataSuccess(response.data))
//     // }
//     } catch (error) {
//       count1++
//       if(count1 == tabData.length){
//         yield put(saveTabDataFail(error))
//       }
  
//   }

//   }

// // const modData = {...tabData, ...moduleData}

// // try {
// //     const response = yield call(addNewTabData, modData)
// //     yield put(saveTabDataSuccess(response.data))
// // } catch (error) {
// //     yield put(saveTabDataFail(error))
// // }

} catch (error) {
  yield put(saveDraftTabDataFail(error))
}
}


// function* onSaveTabData({ payload: {questionData, tabData, logicData, moduleData, history} }) {

//   // console.log(tabData)
//   yield put(setLoadingIndicatorDT(true));

//   const draftData = {
//     moduleid : moduleData.FLD_MODULEID,
//     questiondata : JSON.stringify(questionData),
//     tabdata : JSON.stringify(tabData),
//     logicdata : JSON.stringify(logicData),
//     corpid : JSON.parse(localStorage.getItem(authUser)).fld_companyid
//   }

//   try {
//     const res = yield call(onSaveDraftDTData, draftData)
//     // yield put(saveDraftTabDataSuccess('Configuration saved successfully.'))

//     const additionalData = {
//     status : 'Active',
//     approvalstatus : 'Pending',
//     createdon : moment().format(),
//     createdby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
//     updatedon :  moment().format(),
//     updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
// }

// let count1 = 0
// let count2 = 0
// let count3 = 0

// let tabDt = {}
// let modData = {}
// let qData = {}
// let quesArr = []
// let newQData = {}
// let qItem = {}
// let newQItem = {}


//   for(let i =0; i< tabData.length; i++){

//     quesArr = tabData[i].questionData

//     tabDt = {
//       eventid : 0,
//       moduleid : moduleData.FLD_MODULEID,  
//       tabno :tabData[i].tabno,
//       tabname : tabData[i].tabname,
//       tabnameedit :  tabData[i].tabnameedit
//     }

//     modData = {...tabDt, ...additionalData}

//     try {
//     const response = yield call(addNewTabData, modData)
//     count1++
//     count2 = 0
//     count3 = 0
   
//     for(let j=0; j < quesArr.length; j++){

//       // console.log(quesArr[j])
      
//     qData = {
//       eventid : 0,
//       tabid : response.data,
//       tabno : tabData[i].tabno,
//       moduleid : moduleData.FLD_MODULEID, 
//       questiontext : quesArr[j].questionText,
//       commentsrequired : quesArr[j].commentsrequired,
//       num : quesArr[j].num,
//       required : quesArr[j].required,
//       serial : quesArr[j].serial,
//       showcomments : quesArr[j].showcomments,
//       subtype : quesArr[j].subType,
//       type : quesArr[j].type,
//       nextquestionid : j<quesArr.length-1 ?  quesArr[j+1].num  : 0
//     }

//     newQData = {...qData, ...additionalData}

//     try {

//     const response1 = yield call(addNewQuestionData, newQData)
//     console.log(response1.data)
//     count2++
//     count3 = 0

//     // console.log(quesArr[j].type == 'dropdown' || quesArr[j].type == 'multiplechoice' ||  quesArr[j].type == 'yes/no')


//     if(quesArr[j].type == 'dropdown' || quesArr[j].type == 'multiplechoice' ||  quesArr[j].type == 'yes/no'){
//       if(quesArr[j].choices.length > 0){
//       for(let k =0 ; k< quesArr[j].choices.length ; k++){

//         qItem ={
//          questionid : response1.data,
//          tabid : response.data,
//          tabno : tabData[i].tabno,
//          moduleid : moduleData.FLD_MODULEID, 
//          value : quesArr[j].choices[k].value,
//          description : '',
//          duedate : quesArr[j].choices[k].duedate,
//          score : quesArr[j].choices[k].score,
//          nextquestionid :  j<quesArr.length-1 ?  quesArr[j+1].num  : 0
//         }

//         newQItem = {...qItem, ...additionalData}


//         const response2 = yield call(addNewQuestionItemData, newQItem)
//         count3++

//         if(count1 == tabData.length && count2 == quesArr.length && count3 == quesArr[j].choices.length){
//           yield put(saveTabDataSuccess('Configuration published successfully.'))
//         }

//       }
//     }else{
//       if(count1 == tabData.length && count2 == quesArr.length){
//         yield put(saveTabDataSuccess('Configuration published successfully.'))
//       }
//     }

//     }else{

//       // console.log(quesArr[j])

//       qItem ={
//         questionid : response1.data,
//         tabid : response.data,
//         tabno : tabData[i].tabno,
//         moduleid : moduleData.FLD_MODULEID, 
//         value : quesArr[j].value,
//         description : '',
//         duedate : quesArr[j].duedate,
//         score : quesArr[j].score,
//         nextquestionid :  j<quesArr.length-1 ?  quesArr[j+1].num  : 0
//        }

  

//        newQItem = {...qItem, ...additionalData}

//        const response2 = yield call(addNewQuestionItemData, newQItem)

//        if(count1 == tabData.length && count2 == quesArr.length){
//         yield put(saveTabDataSuccess('Configuration published successfully.'))
//       }
//     }

//   }
//     catch (error) {
//       // console.log(error)
//       count2++
//     }
//   }
//     // if(count1 == tabData.length){
//     //   console.log(response.data)
//     //   yield put(saveTabDataSuccess(response.data))
//     // }
//     } catch (error) {
//       count1++
//       if(count1 == tabData.length){
//         yield put(saveTabDataFail(error))
//       }
  
//   }

//   }

// // const modData = {...tabData, ...moduleData}

// // try {
// //     const response = yield call(addNewTabData, modData)
// //     yield put(saveTabDataSuccess(response.data))
// // } catch (error) {
// //     yield put(saveTabDataFail(error))
// // }

// } catch (error) {
//   yield put(saveDraftTabDataFail(error))
// }
// }


function* onSaveDraftTabData({ payload: {questionData, tabData, logicData, moduleData, history} }) {

  // console.log(tabData)
  // console.log(questionData)
  // console.log(JSON.parse(localStorage.getItem(authUser)))

  yield put(setLoadingIndicatorDT(true));

  const modData = {
    moduleid : moduleData.FLD_MODULEID,
    questiondata : JSON.stringify(questionData),
    tabdata : JSON.stringify(tabData),
    logicdata : JSON.stringify(logicData),
    corpid : JSON.parse(localStorage.getItem(authUser)).fld_companyid

  }

  try {
    const response = yield call(onSaveDraftDTData, modData)

    yield put(saveDraftTabDataSuccess('Configuration saved successfully.'))
} catch (error) {
    yield put(saveDraftTabDataFail(error))
}

}


function* onGetDraftTabData({ payload: {moduleData, history} }) {

  // console.log(tabData)
  yield put(setLoadingIndicatorDT(true));

  const modData = {
    moduleid : moduleData.FLD_MODULEID,
    corpid : JSON.parse(localStorage.getItem(authUser)).fld_companyid
  }

  try {
    const response = yield call(onGetDraftDTData, modData)
console.log(response.data)
    yield put(getDraftTabDataSuccess(response.data))
} catch (error) {
    yield put(getDraftTabDataFail(error))
}

}


function* onGetActiveModuleData() {

  yield put(setLoadingIndicatorDT(true));

  try {
    const response = yield call(onGetActiveModule)
// console.log(response.data)
    yield put(getActiveModulesSuccess(response.data))
} catch (error) {
    yield put(getActiveModulesFail(error))
}

}

function* fetchEvents() {

  yield put(setLoadingIndicatorDT(true));

  const modData = {
    corpid : JSON.parse(localStorage.getItem(authUser)).fld_companyid
  }

  try {
    const response = yield call(getEventList, modData)
    // console.log(response.data)
    yield put(getEventsDTSuccess(response.data))
} catch (error) {
    // console.log(error)
    yield put(getEventsDTFail(error))
}
 
}


function* onAddNewEventData({ payload: {data, history} }) {

  // console.log(data)
  yield put(setLoadingIndicatorDT(true));

  const additional = {
    status : 'Active',
    approvalstatus : 'Pending',
    createdon : moment().format(),
    createdby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    updatedon :  moment().format(),
    updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    corpid : JSON.parse(localStorage.getItem(authUser)).fld_companyid
}
const modData = {...data, ...additional}

  try {
      const response = yield call(addEventApiCall, modData)
      yield put(addEventSuccessDT(response.data))
     
      try {
          yield call(fetchEvents)
      //     let repData = {...data, FLD_EVENTID:response.data}
      // history('/alter-event',{state: repData});

      } catch (error) {
          yield put(addEventFailDT(error.message))

      }

  } catch (error) {
    // console.log(error.message)
      yield put(addEventFailDT(error.message))
  }
}



function* onApproveEventDTData({ payload: event }) {

  yield put(setLoadingIndicatorDT(true));

  const moduleData = {
    eventid : event,
    lastupdatedon : moment().format(),
    lastupdatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid
}

try {
    const response = yield call(approveEventDT, moduleData)
    yield put(approveEventDTSuccess(response.data))

    // console.log(response)
    // history('/corporate-list');
    try {
    yield call(fetchEvents)
} catch (error) {
    yield put(approveEventDTFail(error))
}

    // yield put(deleteCompanySuccess(response))
} catch (error) {
    yield put(approveEventDTFail(error))
}
}

function* onDeactivateEventDTData({ payload: {event, history} }) {

  yield put(setLoadingIndicatorDT(true));

  // console.log(event)

  const moduleData = {
    eventid : event,
    lastupdatedon : moment().format(),
    lastupdatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid
}

try {
    const response = yield call(deleteEventDT, moduleData)
    yield put(deleteEventDTSuccess(response.data))
    // console.log(response)
    // history('/corporate-list');
    // try {
      yield call(fetchEvents)
      // yield put(setLoadingIndicatorDT(false));
      // history('/decision-tree');
    
// } catch (error) {
//     yield put(deleteModuleFail(error))
// }

    // yield put(deleteCompanySuccess(response))
} catch (error) {
    yield put(deleteEventDTFail(error))
}
}


function* onUpdateEventDTData({ payload: {event} }) {

  // console.log(data)
  yield put(setLoadingIndicatorDT(true));

  const additional = {
    approvalstatus : 'Pending',
    updatedon :  moment().format(),
    updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
}
const modData = {...event, ...additional}
// console.log(modData)

  try {
      const response = yield call(updateEventApiCall, modData)
      yield put(updateEventDTSuccess(response.data))
     
      try {
          yield call(fetchEvents)
      // history('/user-list');

      } catch (error) {
          yield put(updateEventDTFail(error.message))

      }

  } catch (error) {
    // console.log(error.message)
      yield put(updateModuleFail(error.message))
  }
}

function* fetchTabDataEvent({ payload: {event} }) {

  const eveData = {
    moduleid : event.FLD_MODULEID,
    corpid : JSON.parse(localStorage.getItem(authUser)).fld_companyid
  }

  const ansData = {
      moduleid : event.FLD_MODULEID,
      eventid : event.FLD_EVENTID
  }

  
  try {
    const response = yield call(getTabDataEventApiCall, eveData)
    // console.log(response.data)
    if(response.data.length <=0){
      yield put(setLoadingIndicatorDT(false));
    }else{
      yield put(setLoadingIndicatorDT(true));
    }
    // yield put(getTabDataEventSuccess(response.data))
    try {
      const response1 = yield call(getQuestionDataEventApiCall, eveData)

      try {
        const response2 = yield call(getQuestionItemEventApiCall, eveData)

        try {
          const responseans1 = yield call(getAnswerEventApiCall, ansData)

          try {
            const responseans2 = yield call(getAnswerItemEventApiCall, ansData)

        console.log(responseans1.data)
      let questionData = [...response1.data]
      let tabData = [...response.data]

      let answerData = [...responseans1.data]
      let answerItemData = [...responseans2.data]

      // console.log(answerItemData)
    
      questionData.forEach(question => {
        const tabObject = tabData.find(tab => tab.FLD_TABNO === question.FLD_TABNO);
        // console.log(tabObject)
        if (tabObject) {
          if(tabObject.questionData == undefined){
            tabObject.questionData = []
          }

          // tabObject.questionData.push(question);
          // console.log(tabObject)

          const choices = response2.data.filter(
            choice =>
              choice.FLD_TABNO === question.FLD_TABNO && choice.FLD_QUESTIONID === question.FLD_QUESTIONUID
          );


          const relatedAnswer = answerData.find(
            answer =>
              answer.FLD_TABNO === question.FLD_TABNO && answer.FLD_QUESTIONID === question.FLD_QUESTIONUID
          );

          // console.log(relatedAnswer)

          // tabObject.questionData.push({
          //   ...question,
          //   comments : relatedAnswer.FLD_COMMENTTEXT,
          //   choices: choices ? choices : []
          // });

          // tabObject.questionData.push({ ...question, choices: choices ? choices : [] });

        //   const updatedChoices = choices.map(choice => {
        //     const relatedAnswerItem = answerItemData.find(
        //       answerItem =>
        //         answerItem.FLD_TABNO === choice.FLD_TABNO &&
        //         answerItem.FLD_QUESTIONID === choice.FLD_QUESTIONID &&
        //         answerItem.FLD_QUESTIONITEMID === choice.FLD_QUESTION_ITEMID
        //     );
        //     console.log(choice)
        //     return relatedAnswerItem
        //     ? { ...choice, answer: relatedAnswerItem }
        //     : { ...choice, answer: null };
        // });

        // console.log(updatedChoices)
          
          tabObject.questionData.push({
            ...question,
            answeruid : uuid(),
            comments: relatedAnswer ? relatedAnswer.FLD_COMMENTTEXT : null,
            choices: choices
              ? choices.map(choice => {
                  if (choice.FLD_QUESTIONID === question.FLD_QUESTIONUID && choice.FLD_TABID === question.FLD_TABID) {
                    // Add the answer from answerItemData to the choice
                    const relatedAnswerItem = answerItemData.find(
                      answerItem =>
                        answerItem.FLD_TABNO === choice.FLD_TABNO &&
                        answerItem.FLD_QUESTIONID === choice.FLD_QUESTIONID 
                        && parseInt(answerItem.FLD_QUESTIONITEMID) === parseInt(choice.FLD_QUESTION_ITEMID)
                    );

                    console.log(relatedAnswerItem)
      
                    return { ...choice, answer: relatedAnswerItem ? relatedAnswerItem.FLD_ANSWERTEXT  : ''};
                  } else {
                    return choice;
                  }
                })
              : [],
          });
        
        }
        
      });

      console.log(tabData)

    
      // Sort questions within each questionData array based on serial
      tabData.forEach(tab => {
        tab.questionData && tab.questionData.sort((a, b) => a.FLD_SERIAL - b.FLD_SERIAL);
      });
    
      console.log('tabdt',tabData)
      yield put(getTabDataEventSuccess(tabData))

    } catch (error) {
      console.log(error)
      yield put(getTabDataEventFail(error))
  }
    } catch (error) {
      console.log(error)
      yield put(getTabDataEventFail(error))
  }
    } catch (error) {
      console.log(error)
      yield put(getTabDataEventFail(error))
  }
  } catch (error) {
      console.log(error)
      yield put(getTabDataEventFail(error))
  }
} catch (error) {
    console.log(error)
    yield put(getTabDataEventFail(error))
}
 
}



// function* fetchTabDataEvent({ payload: {event} }) {

//   const eveData = {
//     moduleid : event.FLD_MODULEID,
//     corpid : JSON.parse(localStorage.getItem(authUser)).fld_companyid
//   }

//   const ansData = {
//       moduleid : event.FLD_MODULEID,
//       eventid : event.FLD_EVENTID
//   }

  
//   try {
//     const response = yield call(getTabDataEventApiCall, eveData)
//     // console.log(response.data)
//     if(response.data.length <=0){
//       yield put(setLoadingIndicatorDT(false));
//     }else{
//       yield put(setLoadingIndicatorDT(true));
//     }
//     // yield put(getTabDataEventSuccess(response.data))
//     try {
//       const response1 = yield call(getQuestionDataEventApiCall, eveData)

//       try {
//         const response2 = yield call(getQuestionItemEventApiCall, eveData)

//         try {
//           const responseans1 = yield call(getAnswerEventApiCall, ansData)

//           try {
//             const responseans2 = yield call(getAnswerItemEventApiCall, ansData)

//         // console.log(responseans2)
//       let questionData = [...response1.data]
//       let tabData = [...response.data]

//       let answerData = [...responseans1.data]
//       let answerItemData = [...responseans2.data]

//       // console.log(answerItemData)
    
//       questionData.forEach(question => {
//         const tabObject = tabData.find(tab => tab.FLD_TABNO === question.FLD_TABNO);
//         // console.log(tabObject)
//         if (tabObject) {
//           if(tabObject.questionData == undefined){
//             tabObject.questionData = []
//           }

//           // tabObject.questionData.push(question);
//           // console.log(tabObject)

//           const choices = response2.data.filter(
//             choice =>
//               choice.FLD_TABNO === question.FLD_TABNO && choice.FLD_QUESTIONID === question.FLD_QUESTIONUID
//           );


//           const relatedAnswer = answerData.find(
//             answer =>
//               answer.FLD_TABNO === question.FLD_TABNO && answer.FLD_QUESTIONID === question.FLD_QUESTIONUID
//           );

//           // console.log(relatedAnswer)

//           // tabObject.questionData.push({
//           //   ...question,
//           //   comments : relatedAnswer.FLD_COMMENTTEXT,
//           //   choices: choices ? choices : []
//           // });

//           // tabObject.questionData.push({ ...question, choices: choices ? choices : [] });

//         //   const updatedChoices = choices.map(choice => {
//         //     const relatedAnswerItem = answerItemData.find(
//         //       answerItem =>
//         //         answerItem.FLD_TABNO === choice.FLD_TABNO &&
//         //         answerItem.FLD_QUESTIONID === choice.FLD_QUESTIONID &&
//         //         answerItem.FLD_QUESTIONITEMID === choice.FLD_QUESTION_ITEMID
//         //     );
//         //     console.log(choice)
//         //     return relatedAnswerItem
//         //     ? { ...choice, answer: relatedAnswerItem }
//         //     : { ...choice, answer: null };
//         // });

//         // console.log(updatedChoices)
          
//           tabObject.questionData.push({
//             ...question,
//             answeruid : uuid(),
//             comments: relatedAnswer ? relatedAnswer.FLD_COMMENTTEXT : null,
//             choices: choices
//               ? choices.map(choice => {
//                   if (choice.FLD_QUESTIONID === question.FLD_QUESTIONUID && choice.FLD_TABID === question.FLD_TABID) {
//                     // Add the answer from answerItemData to the choice
//                     const relatedAnswerItem = answerItemData.find(
//                       answerItem =>
//                         answerItem.FLD_TABNO === choice.FLD_TABNO &&
//                         answerItem.FLD_QUESTIONID === choice.FLD_QUESTIONID 
//                         && parseInt(answerItem.FLD_QUESTIONITEMID) === parseInt(choice.FLD_QUESTION_ITEMID)
//                     );

//                     // console.log(relatedAnswerItem)
      
//                     return { ...choice, answer: relatedAnswerItem.FLD_ANSWERTEXT };
//                   } else {
//                     return choice;
//                   }
//                 })
//               : [],
//           });
        
//         }
        
//       });

//       // console.log(tabData)

    
//       // Sort questions within each questionData array based on serial
//       tabData.forEach(tab => {
//         tab.questionData && tab.questionData.sort((a, b) => a.FLD_SERIAL - b.FLD_SERIAL);
//       });
    
//       console.log('tabdt',tabData)
//       yield put(getTabDataEventSuccess(tabData))

//     } catch (error) {
//       console.log(error)
//       yield put(getTabDataEventFail(error))
//   }
//     } catch (error) {
//       console.log(error)
//       yield put(getTabDataEventFail(error))
//   }
//     } catch (error) {
//       console.log(error)
//       yield put(getTabDataEventFail(error))
//   }
//   } catch (error) {
//       console.log(error)
//       yield put(getTabDataEventFail(error))
//   }
// } catch (error) {
//     console.log(error)
//     yield put(getTabDataEventFail(error))
// }
 
// }


// function* onSaveTabAnswerData({ payload: {tabData,eventData, history, moduleScores} }) {

//   console.log(tabData)
//   yield put(setLoadingIndicatorDT(true));

//   const additionalData = {
//     status : 'Active',
//     approvalstatus : 'Pending',
//     createdon : moment().format(),
//     createdby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
//     updatedon :  moment().format(),
//     updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
// }

// let remDt = {moduleid : tabData[0].FLD_MODULEID, eventid : eventData.FLD_EVENTID}

// try {
//   const resp = yield call(removeTabAnsDataApi, remDt)


// let ansData = {}
// let final_dt = {}
// let ansItemData = {}
// let quesArr = []
// let quesChoice = []
// let final_item = {}
// let count1 = 0
// let  count2 = 0
// let count3 = 0

// let final_score = 0

// for(let i =0; i< tabData.length; i++){

//   quesArr = tabData[i].questionData
//   count1++
//   count2 = 0
//   count3 = 0

//   for(let j=0; j < quesArr.length; j++){

//     ansData = {
//       eventid : eventData.FLD_EVENTID,
//       moduleid : quesArr[j].FLD_MODULEID,  
//       tabno :tabData[i].FLD_TABNO,
//       tabid :tabData[i].FLD_TABID,
//       questionid : quesArr[j].FLD_QUESTIONID,
//       commenttext : quesArr[j].comments ? quesArr[j].comments : ''
//     }

//     final_dt = {...ansData, ...additionalData}

//     try {
//     const response = yield call(saveTabAnsDataApi, final_dt)

   
//     quesChoice = quesArr[j].choices

//     count2++
//     count3 = 0

//     for(let k=0; k < quesChoice.length; k++){

//       if(quesChoice[k].answer != ''){
//         final_score = final_score + quesChoice[k].FLD_SCORE
//       }

//       const range = moduleScores.filter((val) => {
//         final_score >= val.FLD_LOWERLIMIT && final_score <= val.FLD_UPPERLIMIT;
//       });


//     ansItemData = {
//       eventid : eventData.FLD_EVENTID,
//       moduleid : quesArr[j].FLD_MODULEID,  
//       tabno :tabData[i].FLD_TABNO,
//       tabid :tabData[i].FLD_TABID,
//       questionid : quesArr[j].FLD_QUESTIONID,
//       questionitemid : quesChoice[k].FLD_QUESTION_ITEMID,
//       answerid : response.data,
//       answertext : quesChoice[k].answer ? quesChoice[k].answer : '',
//       finalscore : final_score,
//       finaldecision : range[0] == undefined ? 'Non-Reportable' : range[0].FLD_TYPE
//     }

//     final_item = {...ansItemData, ...additionalData}

//     try {
//       const response1 = yield call(saveTabAnsItemDataApi, final_item)

//       count3++

//       if(count1 == tabData.length && count2 == quesArr.length && count3 == quesChoice.length){
//         yield call(fetchTabDataEvent)
//         // yield put(saveTabAnswersDataSuccess('Answer saved successfully.'))
//       }


//     } catch (error) {
//       count3++
//       // yield put(saveTabAnswersDataFail(error))
//       if(count1 == tabData.length && count2 == quesArr.length && count3 == quesChoice.length){
//         yield call(fetchTabDataEvent)
//         // yield put(saveTabAnswersDataSuccess('Answer saved successfully.'))
//       }
//     }

//   }

//   } catch (error) {
//     count2++
//     // yield put(saveTabAnswersDataFail(error))
//     if(count1 == tabData.length && count2 == quesArr.length && count3 == quesChoice.length){
//       yield call(fetchTabDataEvent)
//       // yield put(saveTabAnswersDataSuccess('Answer saved successfully.'))
//     }
//   }
//   }
// }
// } catch (error) {
//   console.log(error)
//   yield put(saveTabAnswersDataFail('error.'))
// }

// }


function* onSaveTabAnswerData({ payload: {tabData,eventData, history, moduleScores} }) {

  console.log(tabData)
  yield put(setLoadingIndicatorDT(true));

  const additionalData = {
    status : 'Active',
    approvalstatus : 'Pending',
    createdon : moment().format(),
    createdby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    updatedon :  moment().format(),
    updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    moduleScores : moduleScores
}

let remDt = {moduleid : tabData[0].FLD_MODULEID, eventid : eventData.FLD_EVENTID}


   let ansData = {
      eventid : eventData.FLD_EVENTID,
      moduleid : tabData[0].FLD_MODULEID,  
      tabdata : tabData
    }

    let final_dt = {...ansData, ...additionalData}

try {
  const resp = yield call(removeTabAnsDataApi, remDt)

  try {
    const response = yield call(saveTabAnsDataApi, final_dt)
    yield call(fetchTabDataEvent)
    yield put(saveTabAnswersDataSuccess('Answer saved successfully.'))
  }catch (error) {
    console.log(error)
    yield put(saveTabAnswersDataFail('error.'))
  }

// let ansData = {}
// let final_dt = {}
// let ansItemData = {}
// let quesArr = []
// let quesChoice = []
// let final_item = {}
// let count1 = 0
// let  count2 = 0
// let count3 = 0

// let final_score = 0

// for(let i =0; i< tabData.length; i++){

//   quesArr = tabData[i].questionData
//   count1++
//   count2 = 0
//   count3 = 0

//   for(let j=0; j < quesArr.length; j++){

//     ansData = {
//       eventid : eventData.FLD_EVENTID,
//       moduleid : quesArr[j].FLD_MODULEID,  
//       tabno :tabData[i].FLD_TABNO,
//       tabid :tabData[i].FLD_TABID,
//       questionid : quesArr[j].FLD_QUESTIONID,
//       commenttext : quesArr[j].comments ? quesArr[j].comments : ''
//     }

//     final_dt = {...ansData, ...additionalData}

//     try {
//     const response = yield call(saveTabAnsDataApi, final_dt)

   
//     quesChoice = quesArr[j].choices

//     count2++
//     count3 = 0

//     for(let k=0; k < quesChoice.length; k++){

//       if(quesChoice[k].answer != ''){
//         final_score = final_score + quesChoice[k].FLD_SCORE
//       }

//       const range = moduleScores.filter((val) => {
//         final_score >= val.FLD_LOWERLIMIT && final_score <= val.FLD_UPPERLIMIT;
//       });


//     ansItemData = {
//       eventid : eventData.FLD_EVENTID,
//       moduleid : quesArr[j].FLD_MODULEID,  
//       tabno :tabData[i].FLD_TABNO,
//       tabid :tabData[i].FLD_TABID,
//       questionid : quesArr[j].FLD_QUESTIONID,
//       questionitemid : quesChoice[k].FLD_QUESTION_ITEMID,
//       answerid : response.data,
//       answertext : quesChoice[k].answer ? quesChoice[k].answer : '',
//       finalscore : final_score,
//       finaldecision : range[0] == undefined ? 'Non-Reportable' : range[0].FLD_TYPE
//     }

//     final_item = {...ansItemData, ...additionalData}

//     try {
//       const response1 = yield call(saveTabAnsItemDataApi, final_item)

//       count3++

//       if(count1 == tabData.length && count2 == quesArr.length && count3 == quesChoice.length){
//         yield call(fetchTabDataEvent)
//         // yield put(saveTabAnswersDataSuccess('Answer saved successfully.'))
//       }


//     } catch (error) {
//       count3++
//       // yield put(saveTabAnswersDataFail(error))
//       if(count1 == tabData.length && count2 == quesArr.length && count3 == quesChoice.length){
//         yield call(fetchTabDataEvent)
//         // yield put(saveTabAnswersDataSuccess('Answer saved successfully.'))
//       }
//     }

//   }

//   } catch (error) {
//     count2++
//     // yield put(saveTabAnswersDataFail(error))
//     if(count1 == tabData.length && count2 == quesArr.length && count3 == quesChoice.length){
//       yield call(fetchTabDataEvent)
//       // yield put(saveTabAnswersDataSuccess('Answer saved successfully.'))
//     }
//   }
//   }
// }
} catch (error) {
  console.log(error)
  yield put(saveTabAnswersDataFail('error.'))
}

}



function* fetchModuleScores() {

  yield put(setLoadingIndicatorDT(true));

  const modData = {
    corpid : JSON.parse(localStorage.getItem(authUser)).fld_companyid
  }

  try {
    const response = yield call(getModuleScoreList,  modData)

    // console.log(response.data)

    let data = response.data;
    let arr = []
// console.log(data)
  const combinedData = data.reduce((acc, current) => {
    // console.log(acc)
    // console.log(current)
    const existingModule = acc.find(item => (item.FLD_MODULEID === current.FLD_MODULEID && item.FLD_CORPID === current.FLD_CORPID));
  
    if (existingModule) {
      // If module exists, update its values
      // existingModule.value += current.value;
      existingModule.decisionData.push({
        type : current.FLD_TYPE,
        upperlimit : current.FLD_UPPERLIMIT,
        lowerlimit : current.FLD_LOWERLIMIT
      })
    } else {
      // If module doesn't exist, add it to the accumulator
      // acc.push({ FLD_MODULEID: current.FLD_MODULEID, FLD_NAM: current.name, value: current.value });
      // console.log(current)
      acc.push({
        FLD_MODULEID : current.FLD_MODULEID,
        FLD_NAME : current.FLD_NAME,
        FLD_KEY : current.FLD_KEY,
        FLD_APPROVAL_STATUS : current.FLD_APPROVAL_STATUS,
        FLD_LASTUPDATEDON :current.FLD_LASTUPDATEDON,
        FLD_STATUS :current.FLD_STATUS,
        FLD_CORPID : current.FLD_CORPID,
        FLD_OWNER : current.FLD_OWNER,
        decisionData : [{
          type : current.FLD_TYPE,
          upperlimit : current.FLD_UPPERLIMIT,
          lowerlimit : current.FLD_LOWERLIMIT
        }]
      })
      // acc.push(current)
    }
  
    return acc;
  }, []);


    // console.log(combinedData)
    yield put(getModuleScoresSuccess(combinedData))
} catch (error) {
    // console.log(error)
    yield put(getModuleScoresFail(error))
}
 
}


function* onAddNewModuleScore({ payload: {module} }) {

  // console.log(module)
  yield put(setLoadingIndicatorDT(true));

  try {
    const resp = yield call(removeModuleScoreDataApi, {moduleid : module.type})

  const additional = {
    status : 'Active',
    approvalstatus : 'Pending',
    createdon : moment().format(),
    createdby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    updatedon :  moment().format(),
    updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    corpid : JSON.parse(localStorage.getItem(authUser)).fld_companyid
}

let modData = {}
let final_dt = {} 
let count = 0

for(let i=0; i<module.decisionData.length;i++){

   modData = {
    moduleid : module.type,
    type : module.decisionData[i].type,
    lowerlimit : module.decisionData[i].lowerlimit,
    upperlimit : module.decisionData[i].upperlimit,
  }


  final_dt = {...modData, ...additional}

  try {
      const response = yield call(addModuleScoreApiCall, final_dt)
      count++;

      if(count == module.decisionData.length){

      

      yield put(addNewModuleScoreSuccess(response.data))
     
      try {
          yield call(fetchModuleScores)
      // history('/user-list');

      } catch (error) {
          yield put(addNewModuleScoreFail(error.message))

      }

    }
    

  } catch (error) {
    // console.log(error.message)
    count++;
    if(count == module.decisionData.length){
      yield put(addNewModuleScoreFail(error.message))
    }
      // yield put(addNewModuleScoreFail(error.message))
  }
}
  } catch (error) {
    yield put(addNewModuleScoreFail(error.message))

}
}

function* onDeleteModuleScoreData({ payload: modulescore }) {

  yield put(setLoadingIndicatorDT(true));

  const moduleData = {
    moduleid : modulescore.FLD_MODULEID,
    status : modulescore.FLD_STATUS == 'Archived' ? 'Active' : 'Archived',
    lastupdatedon : moment().format(),
    lastupdatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid
}

try {
    const response = yield call(deleteModuleScore, moduleData)
    // console.log(response)
    // history('/corporate-list');
    try {
    yield call(fetchModuleScores)
} catch (error) {
    yield put(deleteModuleScoreFail(error))
}

    // yield put(deleteCompanySuccess(response))
} catch (error) {
    yield put(deleteModuleScoreFail(error))
}
}


function* onApproveModuleScoreData({ payload: modulescore }) {

  yield put(setLoadingIndicatorDT(true));

  const moduleData = {
    moduleid : modulescore,
    lastupdatedon : moment().format(),
    lastupdatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid
}

try {
    const response = yield call(approveModuleScore, moduleData)
    yield put(approveModuleScoreSuccess(response.data))

    // console.log(response)
    // history('/corporate-list');
    try {
    yield call(fetchModuleScores)
} catch (error) {
    yield put(approveModuleScoreFail(error))
}

    // yield put(deleteCompanySuccess(response))
} catch (error) {
    yield put(approveModuleScoreFail(error))
}
}



function* fetchModuleScoresById({ payload: module }) {

  // console.log(module)

  // yield put(setLoadingIndicatorDT(true));
  try {
    const response = yield call(getModuleScoreByid, {moduleid : module})
    // console.log(response.data)
    yield put(getModuleScoreByIdSuccess(response.data))
} catch (error) {
    // console.log(error)
    yield put(getModuleScoreByIdFail(error))
}
 
}


function* decisionTreeSaga() {
  yield takeEvery(GET_MODULES, fetchModules)
  yield takeEvery(ADD_NEW_MODULE, onAddNewModule)
  yield takeEvery(DELETE_MODULE, onDeleteModuleData)
  yield takeEvery(UPDATE_MODULE, onUpdateModuleData)
  yield takeEvery(APPROVE_MODULE, onApproveModuleData)
  yield takeEvery(DEACTIVATE_MODULE, onDeactivateModuleData)
  yield takeEvery(SAVE_TAB_DATA_DT, onSaveTabData)
  yield takeEvery(SAVE_DRAFT_TAB_DATA_DT, onSaveDraftTabData)
  yield takeEvery(GET_DRAFT_TAB_DATA_DT, onGetDraftTabData)
  yield takeEvery(GET_ACTIVE_MODULES, onGetActiveModuleData)
  yield takeEvery(GET_EVENTS_DT, fetchEvents)
  yield takeEvery(ADD_NEW_EVENT_DT, onAddNewEventData)
  
  yield takeEvery(APPROVE_EVENT_DT, onApproveEventDTData)
  yield takeEvery(DELETE_EVENT_DT, onDeactivateEventDTData)
  yield takeEvery(UPDATE_EVENT_DT, onUpdateEventDTData)
  yield takeEvery(GET_TABDATA_EVENT, fetchTabDataEvent)
  yield takeEvery(SAVE_TAB_ANSWER_DATA_DT, onSaveTabAnswerData)

  yield takeEvery(ADD_NEW_MODULE_SCORE, onAddNewModuleScore)
  yield takeEvery(GET_MODULE_SCORES, fetchModuleScores)
  yield takeEvery(DELETE_MODULE_SCORE, onDeleteModuleScoreData)
  yield takeEvery(APPROVE_MODULE_SCORE, onApproveModuleScoreData)
  yield takeEvery(GET_MODULE_SCORE_BYID, fetchModuleScoresById)

  

  

  

}

export default decisionTreeSaga;

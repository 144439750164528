import PropTypes from "prop-types";
import React from "react";
import { Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import CardWithWatermark from "components/Common/Watermark";

const EventDonut = ({ dataColors, data}) => {

  // console.log(lastyear)

  const dountApexChartColors = getChartColorsArray(dataColors);

  const series = [data.DEATH, data.INJURY, data.MALFUNCTION, data.OTHER]
  const options = {
    labels: ["Deaths", "Injuries", "Malfunctions", "Others"],
    colors: dountApexChartColors,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }


  return (
    <React.Fragment>
     
<Col sm="12">
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-2">
              
              <h5 className="card-title me-2">Event Type MDR</h5>
              </div>
              <div className="text-muted mt-4"  >
              
                {/* <div className="d-flex"> */}
                  {/* <span
                    className={
                      "badge badge-soft-" + report.color + " font-size-12"
                    }
                  >
                    {" "}
                    {report.badgeValue}{" "}
                  </span>{" "} */}
                  {/* <span className="ms-2 text-truncate"> */}
    <ReactApexChart options={options} series={series} type="donut" height="390"/>

                  {/* </span> */}
                {/* </div> */}
              </div>
              <CardWithWatermark/>

            </CardBody>
          </Card>
        </Col>

      
    </React.Fragment>
  )
}

EventDonut.propTypes = {
  reports: PropTypes.array,
}

export default EventDonut;

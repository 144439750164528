import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormFeedback
} from "reactstrap";

import classnames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

// import charts
import { getProfile,
getPhonecodeProfile as onGetPhonecodeList,
getCountryProfile as onGetCountryList,
getStateProfile as onGetStateList,
getCityProfile as onGetCityList,
updateProfile as onUpdateProfile
} from "store/actions";
import user1 from "../../../assets/images/users/user_icon.png";
import { useSelector, useDispatch } from "react-redux";
import { authUser } from "helpers/dataConfig";
import Select from 'react-select';
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import Loader from "components/Common/Loader";


const ContactsProfile = props => {

  //meta title
  document.title = "Profile | Smarteeva";

  // const { userProfile, onGetUserProfile } = props;
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();

  const [userData, setUserData] = useState('')
  const [countryOptions, setCountryOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([])

  const [countryVal, setCountryVal] = useState({});
  const [stateVal, setStateVal] = useState({});
  const [cityVal, setCityVal] = useState({});
  const [phoneCodeVal, setPhoneCodeVal] = useState(null);
  const [numRegex, setNumRegex] = useState(/^[0-9]*$/)
  const [alphaNumRegex, setAlphaNumRegex] = useState(/^[a-zA-Z0-9]*$/);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const { user } = useSelector(state => ({
    user: state.Profile.user,
  }));


  const { success } = useSelector(state => ({
    success: state.Profile.success,
  }));


  const { phonecode } = useSelector(state => ({
    phonecode: state.Profile.phonecode,
  }));

  const { country } = useSelector(state => ({
    country: state.Profile.country,
  }));

  const { states } = useSelector(state => ({
    states: state.Profile.states,
  }));

  const { city } = useSelector(state => ({
    city: state.Profile.city,
  }));


  const { error, loading } = useSelector(state => ({
    error: state.Profile.error,
    loading : state.Profile.loading
  }));


  useEffect(() => {
    setIsLoading(loading)
}, [loading]);

  useEffect(() => {
    // console.log(localStorage.getItem(authUser))
    if (user && !user.length) {
      if (localStorage.getItem(authUser) != null) {
        const obj = JSON.parse(localStorage.getItem(authUser));
      dispatch(getProfile(obj.fld_userid));
      }
      }else{
      setUserData(user[0])
      // console.log(user)
      }

  }, [dispatch, user]);



  useEffect(() => {
  
    if(success){
      setIsEditable(false)
    }
}, [success]);

  
  useEffect(() => {
  
      if(userData){

        // console.log(userData.FLD_COUNTRY)
  
    dispatch(onGetStateList(userData.FLD_COUNTRY));
    dispatch(onGetCityList(userData.FLD_STATE));
    // setIsEditable(userDt.isEditable)
      }
  }, [userData]);


  useEffect(() => {

    if(userData){

   setPhoneCodeVal(phoneCodeOptions.filter(val=> val.value == userData.FLD_PHONECODE)[0])
  setCountryVal(countryOptions.filter(val=> val.value == userData.FLD_COUNTRY)[0])
  if(stateOptions.length > 0){
    setStateVal(stateOptions.filter(val=> val.value == userData.FLD_STATE)[0])
  }
  if(cityOptions.length > 0){
  setCityVal(cityOptions.filter(val=> val.value == userData.FLD_CITY)[0])
  }

  }

}, [phoneCodeOptions, countryOptions]);

useEffect(() => {

  if(userData){

if(stateOptions.length > 0){
  setStateVal(stateOptions.filter(val=> val.value == userData.FLD_STATE)[0])
}

}

}, [stateOptions]);


useEffect(() => {

  if(userData){

if(cityOptions.length > 0){
setCityVal(cityOptions.filter(val=> val.value == userData.FLD_CITY)[0])
}

}

}, [cityOptions]);



  useEffect(() => {

    // console.log(companys)
    if (phonecode && !phonecode.length) {
        dispatch(onGetPhonecodeList());
    }
    if (country && !country.length) {
      dispatch(onGetCountryList());
  }

}, [dispatch, phonecode, country]);


//   useEffect(() => {
//    setUserData(user[0])
//    console.log(user)
// }, [user]);

useEffect(() => {
  setPhoneCodeOptions(phonecode);
  setCountryOptions(country)
}, [phonecode, country]);


useEffect(() => {
  setStateOptions(states)
}, [states]);

useEffect(() => {
  setCityOptions(city)
}, [city]);


  const [activeTab, setactiveTab] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }



   // validation
   const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fname: userData ? userData.FLD_FNAME == null ? '' : userData.FLD_FNAME : '',
      lname: userData ? userData.FLD_LNAME == null ? '' : userData.FLD_LNAME : '',
      phonecode: userData ? userData.FLD_PHONECODE == null ? '' :  userData.FLD_PHONECODE : '',
      phone: userData ? userData.FLD_MOBILE == null ? '' :  userData.FLD_MOBILE : '',
      email: userData ? userData.FLD_EMAIL == null ? '' :  userData.FLD_EMAIL : '',
      address: userData ? userData.FLD_ADDRESS == null ? '' :  userData.FLD_ADDRESS : '',
      country: userData ? userData.FLD_COUNTRY == null ? '' :  userData.FLD_COUNTRY : '',
      state: userData ? userData.FLD_STATE == null ? '' :  userData.FLD_STATE : '',
      city: userData ? userData.FLD_CITY == null ? '' :  userData.FLD_CITY : '',
      postalcode: userData ? userData.FLD_POSTALCODE == null ? '' :  userData.FLD_POSTALCODE : ''

    },
    validationSchema: Yup.object({
        // Id: Yup.string().matches(
        //     /[0-9\.\-\s+\/()]+/,
        //     "Please Enter Valid Job Id"
        // ).required("Please Enter Your Job Id"),
        // jobTitle: Yup.string().required("Please Enter Your Job Title"),
        fname: Yup.string().required("Please Enter First Name"),
        lname: Yup.string().required("Please Enter Last Name"),
        phonecode: Yup.string().required("Please Select Phone Code"),
        phone: Yup.string().matches(numRegex, 'Please enter a valid number').required("Please Enter Phone Number"),
        email: Yup.string().email('Please enter a valid email').required("Please Enter Email Address"),
        address: Yup.string().required("Please Enter Address"),
        country: Yup.string().required("Please Select Country"),
        state: Yup.string().required("Please Select State"),
        city: Yup.string().required("Please Select City"),
        postalcode: Yup.string().required("Please Enter Postal Code")
    }),
    onSubmit: (values) => {

      console.log(values)

      let userobj = {
        userid : userData.FLD_USERID,
        fname : values.fname,
        lname : values.lname,
        phonecode : values.phonecode,
        phone : values.phone,
        address : values.address,
        country : values.country,
        state : values.state,
        city : values.city,
        postalcode : values.postalcode,
        updatedon : moment().format(),
        updatedby : userData.FLD_USERID

      }

      dispatch(onUpdateProfile(userobj));

        // if (isEdit) {
        //     const updateJobList = {
        //         id: job ? job.id : 0,
        //         jobId: values.jobId,
        //         jobTitle: values.jobTitle,
        //         companyName: values.companyName,
        //         location: values.location,
        //         experience: values.experience,
        //         position: values.position,
        //         type: values.type,
        //         postedDate: "02 June 2021",
        //         lastDate: "25 June 2021",
        //         status: values.status,
        //     };
        //     // update Job
        //     dispatch(onUpdateJobList(updateJobList));
        //     validation.resetForm();
        // } else {
        //     const newJobList = {
        //         id: Math.floor(Math.random() * (30 - 20)) + 20,
        //         jobId: values["jobId"],
        //         jobTitle: values["jobTitle"],
        //         companyName: values["companyName"],
        //         location: values["location"],
        //         experience: values["experience"],
        //         position: values["position"],
        //         type: values["type"],
        //         postedDate: "02 June 2021",
        //         lastDate: "25 June 2021",
        //         status: values["status"],
        //     };
        //     // save new Job
        //     dispatch(onAddNewJobList(newJobList));
        //     validation.resetForm();
        // }
        // toggle();
    },
});


const handleKeyDown = (e) => {
  // console.log(e.key)
  if (e.key.match(/[a-z]/i) && e.key!= 'Backspace') {
    e.preventDefault();
  }
};



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="User" breadcrumbItem="Profile" />
          {userData ?
          <Row>
            <Col xl="8">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="6">
                  <CardTitle className="mb-4">Personal Information</CardTitle>
                  </Col>
                  <Col lg="6" style={{textAlign : 'right', 
                            display : !isEditable  ? '' : 'none'
                            }}>

                            <button
                              className="btn btn-success btn-block "
                              // type="submit"
                              onClick={()=>{
                                setIsEditable(true)
                              }}
                            >
                              Edit Profile
                            </button>
                            </Col>
                            </Row>
                  <p className="text-muted mb-4">
                    {/* {userProfile.personalDetail} */}
                  </p>
                  <div>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          {/* <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">1</span> Personal Info
                            </NavLink>
                          </NavItem> */}
                          {/* <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number">2</span> Corporate Details
                            </NavLink>
                          </NavItem> */}
                          {/* <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                setactiveTab(3)
                              }}
                              disabled={!(passedSteps || []).includes(3)}
                            >
                              <span className="number">3</span> Bank Details
                            </NavLink>
                          </NavItem> */}
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          <TabPane tabId={1}>
                            <Form>
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-firstname-input1" className="required">
                                      First name
                                    </Label>
                                    <Input
                                  disabled={!isEditable}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter First Name"
                                    name="fname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.fname || ""}
                                    invalid={
                                      validation.touched.fname && validation.errors.fname ? true : false
                                    }
                                  />
                                  {validation.touched.fname && validation.errors.fname ? (
                                    <FormFeedback type="invalid">{validation.errors.fname}</FormFeedback>
                                  ) : null}
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label for="basicpill-lastname-input2" className="required">
                                      Last name
                                    </Label>
                                    <Input
                                  disabled={!isEditable}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Last Name"
                                    name="lname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lname || ""}
                                    invalid={
                                      validation.touched.lname && validation.errors.lname ? true : false
                                    }
                                  />
                                  {validation.touched.lname && validation.errors.lname ? (
                                    <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                  ) : null}
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                
                                <Col lg="5">
                                  <div className="mb-3">
                                    <Label for="basicpill-email-input4" className="required">
                                      Email
                                    </Label>
                                    <Input
                                  disabled
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Email Address"
                                    name="email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                      validation.touched.email && validation.errors.email ? true : false
                                    }
                                  />
                                  {validation.touched.email && validation.errors.email ? (
                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                  ) : null}
                                  </div>
                                </Col>


                             
                                <Col lg="3" >
                                <FormGroup className="mb-3">
                                  <Label className="required">Phone Code</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isDisabled={!isEditable}
                                  isClearable
                                  isSearchable
                                  name={'phonecode'}
                                  onChange={(e)=>{
                                    setPhoneCodeVal(e)
                                    validation.setFieldValue('phonecode', e.value)
                                    validation.setFieldError('phonecode',false)
                                  }}
                                  onBlur={validation.handleBlur}
                                  options={phoneCodeOptions}
                                  value={
                                    phoneCodeVal || ""
                                }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // // invalid={
                                // //     validation.touched.country && validation.errors.country
                                // //         ? true
                                // //         : false
                                // // }
                              
                                />
                                   {validation.touched.phonecode && validation.errors.phonecode ? (
                                     <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                   ) : null}
                                </FormGroup>
                              </Col>
                                <Col lg="4">
                                  <div className="mb-3">
                                    <Label for="basicpill-phoneno-input3" className="required">
                                      Phone
                                    </Label>
                                    <Input
                                  disabled={!isEditable}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Phone Number"
                                    name="phone"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone || ""}
                                    invalid={
                                      validation.touched.phone && validation.errors.phone ? true : false
                                    }
                                  />
                                  {validation.touched.phone && validation.errors.phone ? (
                                    <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                  ) : null}
                                  </div>
                                </Col>
                                </Row>
                             
                                <Row>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                     Address
                                  </Label>
                                  <Input
                                  disabled={!isEditable}
                                    type="textarea"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Address"
                                    name="address"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.address || ""}
                                    invalid={
                                      validation.touched.address && validation.errors.address ? true : false
                                    }
                                  />
                                  {validation.touched.address && validation.errors.address ? (
                                    <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6" >
                                <FormGroup className="mb-3">
                                  <Label className="required">Country</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isDisabled={!isEditable}
                                  isClearable
                                  isSearchable
                                  name={'country'}
                                  onChange={(e)=>{
                                    if(e){
                                    setCountryVal(e)
                                    validation.setFieldValue('country', e.value)
                                    validation.setFieldError('country',false)
                                    dispatch(onGetStateList(e.value));
                                  }else{
                                    setCountryVal('')
                                    validation.setFieldValue('country', '')
                                    validation.setFieldError('country',true)
                                    
                                  }
                                  setStateOptions([])
                                  setCityOptions([])
                                  validation.setFieldValue('state', '')
                                  validation.setFieldValue('city', '')
                                  setStateVal('')
                                  setCityVal('')
                                }
                                }
                                  onBlur={validation.handleBlur}
                                  options={countryOptions}
                                  value={
                                    countryVal || ""
                                }
                                className={validation.touched.country && validation.errors.country ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.country && validation.errors.country ? (
                                    <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">State</Label>
                                  <Select
                                  isDisabled={!isEditable}
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'state'}

                                  isClearable
                                  isSearchable
                                  options={stateOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setStateVal(e)
                                    validation.setFieldValue('state', e.value)
                                    validation.setFieldError('state',false)
                                    dispatch(onGetCityList(e.value));
                                    }else{
                                      setStateVal('')
                                      validation.setFieldValue('state', '')
                                      validation.setFieldError('state',true)
                                     
                                    }
                                    setCityOptions([])
                                    validation.setFieldValue('city', '')
                                    setCityVal('')
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    stateVal || ""
                                }
                                invalid={
                                    validation.touched.state && validation.errors.state
                                        ? true
                                        : false
                                }
                                className={validation.touched.state && validation.errors.state ? 'invalid-select' : ''}

                                />
                                  {validation.touched.state && validation.errors.state ? (
                                    <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">City</Label>
                                  <Select
                                  isDisabled={!isEditable}
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'city'}

                                  isClearable
                                  isSearchable
                                  options={cityOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setCityVal(e)
                                    validation.setFieldValue('city', e.value)
                                    validation.setFieldError('city',false)
                                    }else{
                                      setCityVal('')
                                    validation.setFieldValue('city', '')
                                    validation.setFieldError('city',true)
                                    }
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    cityVal || ""
                                }
                                invalid={
                                    validation.touched.city && validation.errors.city
                                        ? true
                                        : false
                                }
                                className={validation.touched.city && validation.errors.city ? 'invalid-select' : ''}

                                />
                                  {validation.touched.city && validation.errors.city ? (
                                    <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                  ) : null}
                            
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                    Postal Code
                                  </Label>
                                  <Input
                                  disabled={!isEditable}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Postal Code"
                                    name="postalcode"
                                    onKeyDown={handleKeyDown}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.postalcode || ""}
                                    invalid={
                                      validation.touched.postalcode && validation.errors.postalcode ? true : false
                                    }
                                  />
                                  {validation.touched.postalcode && validation.errors.postalcode ? (
                                    <FormFeedback type="invalid">{validation.errors.postalcode}</FormFeedback>
                                  ) : null}
                                
                                </FormGroup>
                              </Col>
                            </Row>
                            </Form>
                          </TabPane>
                          {/* <TabPane tabId={2}>
                            <div>
                              <Form>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="basicpill-pancard-input5">
                                        Organisation Name
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-pancard-input5"
                                        placeholder="Enter Your Company Name."
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="basicpill-vatno-input6">
                                        Employee Id
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-vatno-input6"
                                        placeholder="Enter Your Registration No."
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="basicpill-cstno-input7">
                                        Corporate Contact
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-cstno-input7"
                                        placeholder="Enter Your Phone No."
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="basicpill-declaration-input10">
                                        Email Address
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-Declaration-input10"
                                        placeholder="Declaration Details"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <div className="mb-3">
                                      <Label for="basicpill-address-input1">
                                        Location
                                      </Label>
                                      <textarea
                                        id="basicpill-address-input1"
                                        className="form-control"
                                        rows="2"
                                        placeholder="Enter Your Company Address"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </TabPane>
                          <TabPane tabId={3}>
                            <div>
                              <Form>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="basicpill-namecard-input11">
                                        Name on Card
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-namecard-input11"
                                        placeholder="Enter Your Name on Card"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label>Credit Card Type</Label>
                                      <select className="form-select">
                                        <option defaultValue>
                                          Select Card Type
                                        </option>
                                        <option value="AE">
                                          American Express
                                        </option>
                                        <option value="VI">Visa</option>
                                        <option value="MC">MasterCard</option>
                                        <option value="DI">Discover</option>
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="basicpill-cardno-input12">
                                        Credit Card Number
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-cardno-input12"
                                        placeholder="Credit Card Number"
                                      />
                                    </div>
                                  </Col>

                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="basicpill-card-verification-input0">
                                        Card Verification Number
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-card-verification-input0"
                                        placeholder="Credit Verification Number"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label for="basicpill-expiration-input13">
                                        Expiration Date
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-expiration-input13"
                                        placeholder="Card Expiration Date"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </TabPane> */}
                        </TabContent>
                      </div>
                      {/* <div className="actions clearfix">
                        <ul>
                          <li
                            className={
                              activeTab === 1 ? "previous disabled" : "previous"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab - 1)
                              }}
                            >
                              <i className="mdi mdi-arrow-left" />
                            </Link>
                          </li>
                          <li
                            className={activeTab === 4 ? "next disabled" : "next"}
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                toggleTab(activeTab + 1)
                              }}
                            >
                              <i className="mdi mdi-arrow-right" />
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                  <div className="mt-5 center-text">
                    <Link 
                    hidden={!isEditable}
                    onClick={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    to="" className="btn btn-primary">
                      Update Information{" "}
                      {/* <i className="mdi mdi-arrow-right ms-1" /> */}
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="overflow-hidden">

                <CardBody className="pt-0">
                  <Row>
                    <Col sm="12">
                      <div className="flex-center">
                        <div className="avatar-xl my-4">
                          <img
                            // src={userProfile.img}
                           src={userData.userPhoto == null || userData.userPhoto == '' ? user1 : userData.userPhoto}

                            alt=""
                            className="img-thumbnail rounded-circle"
                          />
                        </div>
                      </div>
                      <h5 className="font-size-15 center-text text-truncate">
                        {userData.FLD_FNAME} {userData.FLD_LNAME}
                      </h5>
                      <p className="text-muted mb-0 center-text text-truncate"
                      style={{display : userData.FLD_COMPANYID == 0 || userData.FLD_COMPANYID == '' || userData.FLD_COMPANYID == null ? 'none' : ''}}
                      >
                        {userData.FLD_DESIGNATIONNAME} <br/>({userData.FLD_DEPARTMENTNAME})
                      </p>
                    </Col>

                    <Col sm={12}>
                      <div className="pt-4">
                        <Row>
                          <Col xs="6" className="center-text">
                            <h5 className="font-size-15">
                              {userData.FLD_USERNAME}
                            </h5>
                            <p className="text-muted mb-0">Username</p>
                          </Col>
                          <Col xs="6" className="center-text">
                            <h5 className="font-size-15">
                              {userData.FLD_ISADMIN == 0 && userData.FLD_COMPANYID == 0 ? 'Public' : userData.FLD_ISADMIN == 0 && userData.FLD_COMPANYID != 0 ? 'Corporate' : 'Admin'}
                            </h5>
                            <p className="text-muted mb-0">User</p>
                          </Col>
                        </Row>
                        {/* <div className="mt-4 center-text">
                          <Link to="" className="btn btn-primary width-100 btn-lg">
                            Switch to user account{" "}
                            <i className="mdi mdi-arrow-right ms-1" />
                          </Link>
                        </div> */}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>


              <Card style={{display : userData.FLD_COMPANYID == 0 || userData.FLD_COMPANYID == '' || userData.FLD_COMPANYID == null ? 'none' : ''}}>
                <CardBody>
                  <CardTitle className="mb-4">Corporate Information</CardTitle>
                  <p className="text-muted mb-4">
                  {/* Corporate Information section provides a comprehensive overview of company's background */}
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Organisation Name :</th>
                          <td style={{whiteSpace : 'break-spaces'}}>{userData.FLD_COMPANY_NAME}</td>
                        </tr>
                        <tr>
                          <th scope="row">Employee Id :</th>
                          <td style={{whiteSpace : 'break-spaces'}}>{userData.FLD_EMPID}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td style={{whiteSpace : 'break-spaces'}}>{userData.FLD_COMPANY_EMAIL}</td>
                        </tr>
                        <tr>
                          <th scope="row">Contact :</th>
                          <td style={{whiteSpace : 'break-spaces'}}>({userData.FLD_COMPANY_PHONECODE}){userData.FLD_COMPANY_PHONE}</td>
                        </tr>
                        <tr>
                          <th scope="row">Location :</th>
                          <td style={{whiteSpace : 'break-spaces'}}>{userData.FLD_COMPANY_ADDRESS},&nbsp; 
                          {userData.FLD_COMP_CITY} - &nbsp;
                          {userData.FLD_COMPANY_POSTALCODE}, &nbsp;
                          {userData.FLD_COMP_STATE}, &nbsp;
                          {userData.FLD_COMP_COUNTRY}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
              

            </Col>
          </Row>
          : null}
        </Container>
      </div>
      <Loader isLoading={isLoading} /> 

    </React.Fragment>
  );
};


export default withRouter(ContactsProfile);

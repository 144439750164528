import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import user1 from "../../assets/images/users/user_icon.png";


function CardUser(props) {
  const [settingsMenu, setSettingsMenu] = useState(false)
  //Setting Menu


  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="7">
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        src={props.userData.fld_photo == null || props.userData.fld_photo == '' ? user1 : props.userData.fld_photo}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <p className="mb-2">Welcome to Smarteeva</p>
                        <h5 className="mb-1">{props.userData.fld_fname == '' || props.userData.fld_fname == null ? props.userData.fld_username : props.userData.fld_fname+' '+props.userData.fld_lname}</h5>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="5" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Prev. Month's MDRs
                          </p>
                          <h5 className="mb-0">{props.total}</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                          Prev. Month's Deaths 
                          </p>
                          <h5 className="mb-0">{props.deaths}</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                           Prev. Month's Injuries
                          </p>
                          <h5 className="mb-0">{props.injuries}</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
       {/* <Col lg={12}>
                <Card>
                    <CardBody>
                        <div className="d-flex flex-center py-2">
                            <h2 className='py-2'>Google Ads Placeholder </h2>
                        </div>
                    </CardBody>
                </Card>
            </Col> */}
    </React.Fragment>
  )
}

export default CardUser

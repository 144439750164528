import PropTypes from "prop-types";
import React, {useState} from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form, Button } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import CarouselPage from "./CarouselPageRegister";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import Loader from "components/Common/Loader";

const ForgetPasswordPage = props => {

  //meta title
  document.title = "Forget Password | Smarteeva";

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      setIsLoading(true)
      dispatch(userForgetPassword(values, props.router.navigate));
    }
  });

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  return (
    <React.Fragment>
    <div>
      <Container fluid className="p-0">
        <Row className="g-0">
        <CarouselPage />
          <Col xl={7}>
            <div className="auth-full-page-content p-md-5 p-4 centerJustCustom">
              <div className="w-100 maxWidthCustom">
                <div className="d-flex flex-column h-100 centerJustCustom">
                  <div className="mb-2 mb-md-3 centerCustom">
                    <Link to="dashboard" className="d-block auth-logo mb-3">
                      <img
                        src={logodark}
                        alt=""
                        height="70"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logolight}
                        alt=""
                        height="70"
                        className="auth-logo-light"
                      />
                    </Link>
                  </div>
                  <div className="my-3">
                    <div className="centerTextCustom">
                      <h5 className="text-primary welcome-text">Reset Password</h5>
                      <p className="text-muted">Re-Password with Smarteeva.
                      </p>
                    </div>

                    <div className="mt-4">

                      <div className="alert alert-success text-center mb-4" role="alert">
                        Enter your Email and OTP will be sent to you!
                      </div>

                      <Form className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mt-3 text-end">
                          <Button
                            className="btn btn-primary btn-custome-color waves-effect waves-light width100"
                            type="submit"
                          >
                            Reset password
                          </Button>
                        </div>

                      </Form>
                      <div className="mt-5 text-center">
                        <p>
                          Remember It ?{" "}
                          <Link
                            to="/login"
                            className="fw-medium text-primary"
                          >
                            {" "}
                            Sign In here{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          

        </Row>
      </Container>
    </div>
    <Loader isLoading={isLoading} /> 
  </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
import React, { Fragment, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import CompanyListGlobalFilter from "../../components/Common/GlobalSearchFilter";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isCompanyListGlobalFilter,
  resetTableFilter
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const [statusFilterValue, setStatusFilterValue] = React.useState('');
  const [approvalStatusFilterValue, setApprovalStatusFilterValue] = React.useState('');


  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);


  useImperativeHandle(resetTableFilter, () => ({
    resetTable() {
      setStatusFilterValue('');
      setGlobalFilter('');
      setApprovalStatusFilterValue('')
    }
  }));

  // console.log(resetFilter)
  // if(resetFilter){
  //   setStatusFilterValue('');
  //   setGlobalFilter('');
  // }

  // const onStatusFilterChange = event => {
  //   // const statusFilterValue = event.target.value;
  //   // setStatusFilter(statusFilterValue);
  //   setStatusFilterValue(event.target.value)
  //   setGlobalFilter(event.target.value || undefined);
  //   console.log(event.target.value)
  // };


  const onStatusFilterChange = event => {
    const statusFilterValue = event.target.value;
    setStatusFilterValue(statusFilterValue);
    // setGlobalFilter(statusFilterValue);
    if (statusFilterValue === 'All') {
      setGlobalFilter('');
    } else {
      setGlobalFilter(statusFilterValue || undefined);
    }
  };

  const onApprovalStatusFilterChange = event => {
    const approvalStatusFilterValue = event.target.value;
    setApprovalStatusFilterValue(approvalStatusFilterValue);
    if (approvalStatusFilterValue === 'All') {
      setGlobalFilter('');
    } else {
      setGlobalFilter(approvalStatusFilterValue || undefined);
    }
  };

  return (
    <React.Fragment>
      <Col md={6}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>

      </Col>
      {isCompanyListGlobalFilter && (
        <CompanyListGlobalFilter onStatusChange={onStatusFilterChange}
        statusValue={statusFilterValue}
        approvalValue={approvalStatusFilterValue}
        onApprovalStatusChange={onApprovalStatusFilterChange} />
      )}


    </React.Fragment>
  );
}

const TableContainerDT = forwardRef(({
  columns,
  data,
  isGlobalFilter,
  isCompanyListGlobalFilter,
  isAddOptions,
  isAddModule,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
}, ref) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };


  // const setResetFilter = () =>{
  //   console.log(state.statusFilterValue)
  // }

 

  return (
    <Fragment>
      <Row className="mb-2">
        <Col md={customPageSizeOptions ? 2 : 2}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isCompanyListGlobalFilter={isCompanyListGlobalFilter}
            resetTableFilter={ref} 
          />
        )}
        {isAddModule && (
          <Col sm="6">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-account-multiple-plus me-2" />
                Add New User
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {/* <Filter column={column} /> */}
                    {/* <div>{column.canFilter ? column.render("Filter") : null}</div> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
 );
});

TableContainerDT.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};
TableContainerDT.displayName = 'TableContainerDT';

export default TableContainerDT;

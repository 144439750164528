import axios from "axios";
import { authUser, tspFullAccessToken } from "./dataConfig";
import { useEffect } from "react";

export const getTspToken = () =>{

    let tspUserData = {
        // "username": JSON.parse(localStorage.getItem(authUser)).username,
        "username": 'nidhiy',
        "secret_key": '1b1c6525-d692-4640-be5f-a268e9f3775b',
        // "validity_time_in_sec": 300,
        "org_id": 0,
        "auto_create": false,
        }

        // console.log(tspUserData)
    
          let config = {
            method: 'post',
            url: 'https://smarteeva.thoughtspot.cloud/api/rest/2.0/auth/token/full',
            headers: { 
              'Accept': 'application/json', 
              'Content-Type': 'application/json'},
            data : tspUserData
          };
    
     return  axios.request(config)
    .then((tspresponse) => {
      // console.log(tspresponse.data)
      localStorage.setItem(tspFullAccessToken, JSON.stringify(tspresponse.data.token))

     return  tspresponse.data.token
     
    }).catch((error) => {
      console.log(error);
    });
    
}
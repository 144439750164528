import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { No, Name, Email, Mobile, Location, IsAdmin, ApprovalStatus, SignupStatus, UpdateOn, Status} from "./userlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  getUsers as onGetUsers,
  getCorporateDropdown as onGetCorporateDropdown,
  sendUserInvite as onSendUserInvite,
  deleteUser as onDeleteUser,
} from "store/user/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainerUsers from "components/Common/TableContainerUsers";
import Loader from "components/Common/Loader";

const UserList = props => {

  //meta title
  document.title = "Manage Users | Smarteeva";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactRef, setContactRef] = useState();

  const [userType, setUserType] = useState('All');
  const [corpList, setCorpList] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');



  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (contact && contact.name) || "",
      designation: (contact && contact.designation) || "",
      tags: (contact && contact.tags) || "",
      email: (contact && contact.email) || "",
      projects: (contact && contact.projects) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      designation: Yup.string().required("Please Enter Your Designation"),
      tags: Yup.array().required("Please Enter Tag"),
      email: Yup.string().matches(
        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        "Please Enter Valid Email"
      ).required("Please Enter Your Email"),
      projects: Yup.string().required("Please Enter Your Project"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          id: contact.id,
          name: values.name,
          designation: values.designation,
          tags: values.tags,
          email: values.email,
          projects: values.projects,
        };
        // update user
        dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"],
          designation: values["designation"],
          email: values["email"],
          tags: values["tags"],
          projects: values["projects"],
        };
        // save new user
        dispatch(onAddNewUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.user.users,
  }));

  const { corpDropList } = useSelector(state => ({
    corpDropList: state.user.corpDropList,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);


  const columns = useMemo(
    () => [
      {
        Header: "#",//should be replaced with checkbox
        Cell: (cellProps) => {
          return <No {...cellProps} />;
      },
      },
      {
        Header: "Name",
        accessor: "FLD_FNAME",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
          
        },
        // accessor: (cellProps) => (
        //   <>
        //     {!cellProps.img ? (
        //       <div className="avatar-xs">
        //         <span className="avatar-title rounded-circle">
        //           {cellProps.name.charAt(0)}
        //         </span>
        //       </div>
        //     ) : (
        //       <div>
        //         <img
        //           className="rounded-circle avatar-xs"
        //           src={cellProps.img}
        //           alt=""
        //         />
        //       </div>
        //     )}
        //   </>
        // ),
      },
      {
        Header: "Email Address",
        accessor: "FLD_EMAIL",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
          
        },
      },
      {
        Header: "Organisation Name",
        accessor: "FLD_COMPANY_NAME",
        filterable: true,
        show : false,
        Cell: cellProps => {
          return <Email {...cellProps} />;
          
        },
      },
      {
        Header: "Mobile Number",
        accessor: "FLD_MOBILE",
        filterable: true,
        Cell: cellProps => {
          return <Mobile {...cellProps} />;
        },
      },
      {
        Header: "Location",
        accessor: "FLD_ADDRESS",
        filterable: true,
        Cell: cellProps => {
          return <Location {...cellProps} />;
        },
      },
      {
        Header: "Is Admin",
        accessor: "FLD_ISADMIN",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <IsAdmin {...cellProps} />{" "}
            </>
          );
        },
      },
      
      {
        Header: "Approval Status",
        accessor: "FLD_APPROVAL_STATUS",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <ApprovalStatus {...cellProps} />{" "}
            </>
          );
        },
      },
      {
        Header: 'Date Updated On',
        accessor: 'FLD_LASTUPDATEDON',
        filterable: true,
        Cell: (cellProps) => {
            return <UpdateOn {...cellProps} />;
        }
    },
    {
      Header: "Signup Status",
      accessor: "FLD_SIGNUP_STATUS",
      filterable: true,
      Cell: cellProps => {
        return (
          <>
            {" "}
            <SignupStatus {...cellProps} />{" "}
          </>
        );
      },
    },
      {
        Header: "User Status",
        accessor: "FLD_STATUS",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              {" "}
              <Status {...cellProps} />{" "}
            </>
          );
        },
      },
      
      {
        Header: "Invite User",
        disableFilters: true,
        Cell: (cellProps) => {
            return (
                <ul className="list-unstyled hstack gap-1 mb-0">
                    <li data-bs-toggle="tooltip" data-bs-placement="top" title={cellProps.row.original.FLD_INVITE_SENT ? 'Resend Joining Invite' : 'Send Joining Invite'}>
                        <Link 
                          onClick={() => {
                            const userData = cellProps.row.original;
                            dispatch(onSendUserInvite({email : cellProps.row.original.FLD_EMAIL}));
                        }}
                        // to={'/alter-user'} state={{ state: {...cellProps.row.original, isEditable : false} }}
                        className="btn btn-sm btn-soft-info">
                            <i className="mdi mdi-card-account-mail" id={"viewtooltipj"+cellProps.row.original.FLD_USERID}></i></Link>
                    </li>
                    <UncontrolledTooltip placement="top" target={"viewtooltipj"+cellProps.row.original.FLD_USERID}>
                       {cellProps.row.original.FLD_INVITE_SENT? 'Resend Joining Invite' : 'Send Joining Invite'} 
                    </UncontrolledTooltip>

                
                </ul>
            );
        }
      },
      {
        Header: "Action",
        disableFilters: true,
        Cell: (cellProps) => {
            return (
                <ul className="list-unstyled hstack gap-1 mb-0">
                    <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                        <Link 
                        to={'/alter-user'} state={{ state: {...cellProps.row.original, isEditable : false} }}
                        className="btn btn-sm btn-soft-primary">
                            <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                    </li>
                    <UncontrolledTooltip placement="top" target="viewtooltip">
                        View
                    </UncontrolledTooltip>

                    <li>
                        <Link
                            // to="#"
                            to={'/alter-user'} state={{ state: {...cellProps.row.original, isEditable : true} }}
                            // to={{ pathname: '/alter-corporate-profile', state: { data : cellProps.row.original } }}
                            className="btn btn-sm btn-soft-info"
                           
                        >
                            <i className="mdi mdi-pencil-outline" id="edittooltip" />
                            <UncontrolledTooltip placement="top" target="edittooltip">
                                Edit
                            </UncontrolledTooltip>
                        </Link>
                    </li>

                    <li>
                        <Link
                            to="#"
                            className="btn btn-sm btn-soft-danger"
                            onClick={() => {
                                const userData = cellProps.row.original;
                                // console.log(userData)
                                onClickDelete(userData);
                            }}
                        >
                            <i className="mdi mdi-delete-outline" id="deletetooltip" />
                            <UncontrolledTooltip placement="top" target="deletetooltip">
                                Delete
                            </UncontrolledTooltip>
                        </Link>
                    </li>
                </ul>
            );
        }
      },
    ],
    []
  );


  const { error, loading } = useSelector(state => ({
    error: state.user.error,
    loading : state.user.loading
  }));


  useEffect(() => {
    setIsLoading(loading)
}, [loading]);

  useEffect(() => {
    console.log(users)
    if (users && !users.length) {
      dispatch(onGetUsers());
      // setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    if (corpDropList && !corpDropList.length) {
      dispatch(onGetCorporateDropdown());
      // setIsEdit(false);
    }
  }, [dispatch, corpDropList]);

  useEffect(() => {
    setContact(users);
    setContactRef(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    setCorpList(corpDropList);
  }, [corpDropList]);

  useEffect(() => {

    if (!isEmpty(users)) {

      // setContact(users);
      setContactRef(users)
      // setIsEdit(false);
    }
  }, [users, contactRef]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;

    setContact({
      id: user.id,
      name: user.name,
      designation: user.designation,
      email: user.email,
      tags: user.tags,
      projects: user.projects,
    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setSelectedUser(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (selectedUser && selectedUser.FLD_USERID) {
      dispatch(onDeleteUser({userid : selectedUser.FLD_USERID, username : selectedUser.FLD_USERNAME}));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        deleteMsg={'Are you sure you want to archive the user.'}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Manage Users" breadcrumbItem="Users" />
          <Row>
            <Col lg="12">
              <Card>
              <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                    <Col lg="6">
                                        <h5 className="mb-0 card-title flex-grow-1">List Of Users associated to MDR Engine</h5>
                                        </Col>
                                        <Col lg="6">
                                        <div className="btn-group" role="group" style={{float : 'right'}}>
                          <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off" defaultChecked 
                           onChange={()=>{
                            // console.log('All')
                            setUserType('All')
                            setContact(contactRef)
                          }}
                          />
                          <label className="btn btn-outline-primary" htmlFor="btnradio4">All</label>

                          <input type="radio" className="btn-check" name="btnradio" id="btnradio5" autoComplete="off" 
                          onChange={()=>{
                            // console.log('Public')
                            setUserType('Public')
                            let public_filter = contactRef.filter(val=> val.FLD_COMPANYID == 0 || val.FLD_COMPANYID == '' || val.FLD_COMPANYID == null)
                            setContact(public_filter)
                          }}/>
                          <label className="btn btn-outline-primary" htmlFor="btnradio5">Public Users</label>

                          <input type="radio" className="btn-check" name="btnradio" id="btnradio6" autoComplete="off" 
                          onChange={()=>{
                            // console.log('Corporate')
                            setUserType('Corporate')
                            let corp_filter = contactRef.filter(val=> val.FLD_COMPANYID > 0 && val.FLD_COMPANYID != '' && val.FLD_COMPANYID != null)
                            setContact(corp_filter)
                          }}
                          />
                          <label className="btn btn-outline-primary" htmlFor="btnradio6" 
                          >Corporate Users</label>
                        </div>
                  </Col>
                        {/* <div className="flex-shrink-0">
                                            <Link className="btn btn-light me-3" 
                                            // onClick={handleReset}
                                            ><i className="mdi mdi-refresh"
                                            
                                            ></i> Reset Filters </Link>
                                          
                                        </div> */}
                                    </div>
                                </CardBody>
                <CardBody>
                {contact ?  <TableContainerUsers
                    columns={columns}
                    data={contact}
                    dataRef={contact}
                    isGlobalFilter={true}
                    isUserListGlobalFilter={true}
                    isAddUserList={true}
                    userType={userType}
                    corpList={corpList}
                    handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                :null}
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit User" : "Add User"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Insert Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Designation</Label>
                              <Input
                                name="designation"
                                label="Designation"
                                placeholder="Insert Designation"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.designation || ""}
                                invalid={
                                  validation.touched.designation &&
                                    validation.errors.designation
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.designation &&
                                validation.errors.designation ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.designation}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                label="Email"
                                type="email"
                                placeholder="Insert Email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                    validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                                validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Option</Label>
                              <Input
                                type="select"
                                name="tags"
                                className="form-select"
                                multiple={true}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tags || []}
                                invalid={
                                  validation.touched.tags &&
                                    validation.errors.tags
                                    ? true
                                    : false
                                }
                              >
                                <option>Photoshop</option>
                                <option>illustrator</option>
                                <option>Html</option>
                                <option>Php</option>
                                <option>Java</option>
                                <option>Python</option>
                                <option>UI/UX Designer</option>
                                <option>Ruby</option>
                                <option>Css</option>
                              </Input>
                              {validation.touched.tags &&
                                validation.errors.tags ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.tags}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Projects</Label>
                              <Input
                                name="projects"
                                label="Projects"
                                type="text"
                                placeholder="Insert Projects"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.projects || ""}
                                invalid={
                                  validation.touched.projects &&
                                    validation.errors.projects
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.projects &&
                                validation.errors.projects ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.projects}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Loader isLoading={isLoading} /> 
    </React.Fragment>
  );
};

export default withRouter(UserList);

import React from "react";

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const FieldAccess = () => {

  //meta title
  document.title = "FLS & Access Setup | Smarteeva";

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Admin" breadcrumbItem="FLS & Access Setup" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Permission Group</CardTitle>
                  {/* <Button color="primary" type="button" className="btn-sm">Add Fields</Button> */}
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr className="vertical-middle">
                          <th>#</th>
                          <th>Field Name</th>
                          <th>Field Type</th>
                          <th className="text-center">
                            Read Access
                            <div><p className="lh-sm font-400 mt-1 mb-0">
                              <a href="#" className="margin-custom-05">Select all</a>
                              {/* <a href="#" className="margin-custom-05">Unselect all</a> */}
                            </p>
                            </div>
                          </th>
                          <th className="text-center">
                            Edit Access
                            <div><p className="lh-sm font-400 mt-1 mb-0">
                              <a href="#" className="margin-custom-05">Select all</a>
                              {/* <a href="#" className="margin-custom-05">Unselect all</a> */}
                            </p>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="vertical-middle">
                          <th scope="row">1</th>
                          <td>Organisation</td>
                          <td>Text</td>
                          
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                        </tr>
                        <tr className="vertical-middle">
                          <th scope="row">2</th>
                          <td>User</td>
                          <td>Text</td>
                         
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                        </tr>
                        <tr className="vertical-middle">
                          <th scope="row">3</th>
                          <td>MDREntry</td>
                          <td>Long Text</td>
                          
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                        </tr>
                        <tr className="vertical-middle">
                          <th scope="row">4</th>
                          <td>Parent Device</td>
                          <td>Searchable Select</td>
                          
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                        </tr>
                        <tr className="vertical-middle">
                          <th scope="row">5</th>
                          <td>Actual Device</td>
                          <td>Searchable Select</td>
                         
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                        </tr>
                        <tr className="vertical-middle">
                          <th scope="row">6</th>
                          <td>First Name</td>
                          <td>Text</td>
                         
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                        </tr>
                        <tr className="vertical-middle">
                          <th scope="row">7</th>
                          <td>Last Name</td>
                          <td>Text</td>
                         
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                        </tr>
                        <tr className="vertical-middle">
                          <th scope="row">8</th>
                          <td>Email</td>
                          <td>Text</td>
                          
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                        </tr>
                        <tr className="vertical-middle">
                          <th scope="row">11</th>
                          <td>Role</td>
                          <td>Select</td>
                          
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                        </tr>
                        <tr className="vertical-middle">
                          <th scope="row">12</th>
                          <td>Department</td>
                          <td>Select</td>
                          
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                        </tr>
                        <tr className="vertical-middle">
                          <th scope="row">13</th>
                          <td>Designation</td>
                          <td>Select</td>
                          
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FieldAccess

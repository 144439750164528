import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  SET_LOADING_INDICATOR_PASSWORD,
} from "./actionTypes"

const initialState = {
  resetSuccessMsg: null,
  resetError: null,
  loading: false
}

const resetPassword = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD:
      state = {
        ...state,
        resetSuccessMsg: null,
        resetError: null,
      }
      break
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        resetSuccessMsg: action.payload,
        loading: false
      }
      break
    case RESET_PASSWORD_ERROR:
      state = { ...state, resetError: action.payload, loading: false }
      break

    case CHANGE_PASSWORD:
      state = {
        ...state,
        resetSuccessMsg: null,
        resetError: null,
        loading: false
      }
      break
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        resetSuccessMsg: action.payload,
        loading: false
      }
      break
    case CHANGE_PASSWORD_ERROR:
      state = { ...state, resetError: action.payload, loading: false }
      break

    case SET_LOADING_INDICATOR_PASSWORD:
      state = {
        ...state,
        loading: action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default resetPassword

import PropTypes from "prop-types";
import React from "react";
import { Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import CardWithWatermark from "components/Common/Watermark";

const MiniWidget = ({ dataColors, thisYear, lastYear, malfunction, injury, death }) => {

  // console.log(lastyear)

  const spineareaChartColors = getChartColorsArray(dataColors);

  const series = [
    {
      name: "MDR Reported",
      data: [lastYear, thisYear],
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: spineareaChartColors,
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [
        "Last Year MDR's",
        "Current Year MDR's",
       
      ],
    },
    markers: {
      size: 1,
      strokeWidth: 0,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      showForSingleSeries: true
    },
  }

  const series1 = [
    {
      name: "MDR Reported",
      data: [injury, malfunction, death],
    },
  ]
  const options1 = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: spineareaChartColors,
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [
        "Injury",
        "Malfunction",
        "Death"
       
      ],
    },
    markers: {
      size: 1,
      strokeWidth: 0,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      showForSingleSeries: true
    },
  }


  return (
    <React.Fragment>
      {/* {reports.map((report, key) => (
        <Col sm="6" key={key}>
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-2">
                <div className="avatar-xs me-3">
                  
                  <span className="avatar-title rounded-circle bg-primary font-size-18">
                    <i className={report.icon} />
                  </span>
                </div>
                <h5 className="font-size-14 mb-0">Current and Last Year Reports</h5>
              </div>
              <div className="text-muted mt-4"  >
                <h4>
                  {report.value}{" "}
                  <i className="mdi mdi-chevron-up ms-1 text-success" />
                </h4>
                <div className="d-flex">
                  <span
                    className={
                      "badge badge-soft-" + report.color + " font-size-12"
                    }
                  >
                    {" "}
                    {report.badgeValue}{" "}
                  </span>{" "}
                  <span className="ms-2 text-truncate">{report.desc}</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))} */}

<Col sm="6">
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-2">
                <div className="avatar-xs me-3">
                  
                  <span className="avatar-title rounded-circle bg-primary font-size-18">
                    <i className={'bx bxs-report'} />
                  </span>
                </div>
                <h5 className="font-size-14 mb-0">Current and Last Year Reports</h5>
              </div>
              <div className="text-muted mt-4"  >
              
                <div className="d-flex">
                  {/* <span
                    className={
                      "badge badge-soft-" + report.color + " font-size-12"
                    }
                  >
                    {" "}
                    {report.badgeValue}{" "}
                  </span>{" "} */}
                  <span className="ms-2 text-truncate" style={{width : '100%'}}>
    <ReactApexChart options={options} series={series} type="bar" height="140" />

                  </span>
                </div>
              </div>
              <CardWithWatermark
              style={{fontSize : 20}}
              />
            </CardBody>
          </Card>
        </Col>

        <Col sm="6">
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-2">
                <div className="avatar-xs me-3">
                  
                  <span className="avatar-title rounded-circle bg-primary font-size-18">
                    <i className='bx bx-devices' />
                  </span>
                </div>
                <h5 className="font-size-14 mb-0">Reports by Event Type</h5>
              </div>
              <div className="text-muted mt-4"  >
              
                <div className="d-flex">
                  {/* <span
                    className={
                      "badge badge-soft-" + report.color + " font-size-12"
                    }
                  >
                    {" "}
                    {report.badgeValue}{" "}
                  </span>{" "} */}
                  <span className="ms-2 text-truncate" style={{width : '100%'}}>
                  <ReactApexChart options={options1} series={series1} type="bar" height="140" />
                  </span>
                </div>
              </div>
              <CardWithWatermark
              style={{fontSize : 20}}
              />
            </CardBody>
          </Card>
        </Col>
    </React.Fragment>
  )
}

MiniWidget.propTypes = {
  reports: PropTypes.array,
}

export default MiniWidget;

import {
    ABORT_GLOBAL_SEARCH_RESULT,
    ABORT_SMART_QUERY,
    ABORT_SMART_QUERY_MDR_LIST,
    EXECUTE_SMART_QUERY_FAIL,
    EXECUTE_SMART_QUERY_MDR_LIST_FAIL,
    EXECUTE_SMART_QUERY_MDR_LIST_SUCCESS,
    EXECUTE_SMART_QUERY_RESULT_NO_DATA,
    EXECUTE_SMART_QUERY_RESULT_NO_DATA_MDRLIST,
    EXECUTE_SMART_QUERY_SUCCESS,
    GET_DEVICE_FIELD_CHART_SMART_SUCCESS,
    GET_DEVICE_PROB_CODE_LIST_FAIL,
    GET_DEVICE_PROB_CODE_LIST_SUCCESS,
    GET_EVENT_FIELD_CHART_SMART_SUCCESS,
    GET_FIELD_CHART_SMART_FAIL,
    GET_MANUFACTURER_FIELD_CHART_SMART_SUCCESS,
    GET_MDR_INITIAL_LIST_FAIL,
    GET_MDR_INITIAL_LIST_SUCCESS,
    GET_MEDICAL_SPL_LIST_FAIL,
    GET_MEDICAL_SPL_LIST_SUCCESS,
    GET_MONTHLY_CHART_SMART_FAIL,
    GET_MONTHLY_CHART_SMART_SUCCESS,
    GET_PATIENT_PROB_CODE_LIST_FAIL,
    GET_PATIENT_PROB_CODE_LIST_SUCCESS,
    GET_PATIENT_PROB_CODE_MDR_LIST_FAIL,
    GET_PATIENT_PROB_CODE_MDR_LIST_SUCCESS,
    GET_REASON_FIELD_CHART_SMART_SUCCESS,
    GET_RECENT_LIST_FAIL,
    GET_RECENT_LIST_SUCCESS,
    GET_SAVED_LIST_FAIL,
    GET_SAVED_LIST_SUCCESS,
    GET_SEARCH_FIELD_LIST_FAIL,
    GET_SEARCH_FIELD_LIST_SUCCESS,
    GET_SPECIALTY_FIELD_CHART_SMART_SUCCESS,
    GET_SUBMISSION_FIELD_CHART_SMART_SUCCESS,
    GET_YEAR_CHART_SMART_SUCCESS,
    GLOBAL_SEARCH_RESULT_MDR_LIST,
    GLOBAL_SEARCH_RESULT_MDR_LIST_FAIL,
    GLOBAL_SEARCH_RESULT_MDR_LIST_SUCCESS,
    GLOBAL_SEARCH_RESULT_NO_DATA,
    RESET_GLOBAL_SEARCH,
    RESET_QUERY_RESULT,
    RESET_QUERY_RESULT_MDR_LIST,
    SAVE_SMART_QUERY_FAIL,
    SAVE_SMART_QUERY_SUCCESS,
    SET_GLOBAL_SEARCH_RESULT_DETAILS,
    SET_LOADING_INDICATOR_SMART_QUERY,
    SET_SMART_QUERY_DETAILS,
    SET_SMART_QUERY_MDR_LIST_DETAILS
} from "./actionTypes";

const INIT_STATE = {
    recent_query: [],
    recent_length: -1,
    saved_query: [],
    saved_length: -1,
    search_fields: [],
    medical_spl: [],
    device_prob_code: [],
    patient_prob_code: [],
    query_result: [],
    query_result_no_data: false,
    query_result_no_data_mdrlist: false,
    query_details: {},
    mdr_list: [],
    error: {},
    loading: false,
    doneState: false,
    query_details_mdrlist: {},
    query_result_mdrlist: [],
    query_result_mdrlist_ref: [],
    abortStatus: false,
    abortStatusMdrList: false,
    monthly_chart: [],
    year_chart: [],
    event_chart: [],
    device_chart: [],
    submission_chart: [],
    manufacturer_chart: [],
    reason_chart: [],
    specialty_chart: [],
    global_search_results : [],
    global_search_details : {},
    global_search_no_data : false,
    abortStatusGlobalSearch : false
}

const SmartQueryReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_RECENT_LIST_SUCCESS:
            return {
                ...state,
                recent_query: action.payload,
                recent_length: action.payload.length
                // loading : false
            };

        case GET_RECENT_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case GET_SAVED_LIST_SUCCESS:
            return {
                ...state,
                saved_query: action.payload,
                saved_length: action.payload.length,
                loading: false

            };

        case GET_SAVED_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case GET_SEARCH_FIELD_LIST_SUCCESS:
            return {
                ...state,
                search_fields: action.payload,
                // loading : false
            };

        case GET_SEARCH_FIELD_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };


        case GET_MEDICAL_SPL_LIST_SUCCESS:
            return {
                ...state,
                medical_spl: action.payload,
                // loading : false
            };

        case GET_MEDICAL_SPL_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false

            };


        case GET_DEVICE_PROB_CODE_LIST_SUCCESS:
            return {
                ...state,
                device_prob_code: action.payload,
                // loading : false

            };

        case GET_DEVICE_PROB_CODE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false

            };

        case GET_PATIENT_PROB_CODE_LIST_SUCCESS:
            return {
                ...state,
                patient_prob_code: action.payload,
                loading: false

            };

        case GET_PATIENT_PROB_CODE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false

            };

        case SAVE_SMART_QUERY_SUCCESS:
            return {
                ...state,
                // saved_query: action.payload,
                loading: false

            };

        case SAVE_SMART_QUERY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false

            };

        case EXECUTE_SMART_QUERY_SUCCESS:
            return {
                ...state,
                query_result: [...state.query_result, ...action.payload],
                // doneState : true
                loading: false

                // query_result: action.payload,
            };

        case EXECUTE_SMART_QUERY_RESULT_NO_DATA:
            return {
                ...state,
                query_result_no_data: action.payload, loading: false

            };

        case EXECUTE_SMART_QUERY_RESULT_NO_DATA_MDRLIST:
            return {
                ...state,
                query_result_no_data_mdrlist: action.payload, loading: false

            };

        case EXECUTE_SMART_QUERY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false

            };

        case SET_SMART_QUERY_DETAILS:
            return {
                ...state,
                query_details: action.payload,

            };
        case RESET_QUERY_RESULT:
            return {
                ...state,
                query_result: [],

            };

        case GET_MDR_INITIAL_LIST_SUCCESS:
            return {
                ...state,
                mdr_list: action.payload,
                loading: false

            };

        case GET_MDR_INITIAL_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false

            };

        case SET_LOADING_INDICATOR_SMART_QUERY:
            return {
                ...state,
                loading: action.payload
            };


        case GET_PATIENT_PROB_CODE_MDR_LIST_SUCCESS:
            return {
                ...state,
                patient_prob_code: action.payload,
                // loading : false

            };


        case EXECUTE_SMART_QUERY_MDR_LIST_SUCCESS:
            // console.log([...state.query_result_mdrlist, ...action.payload])
            return {
                ...state,
                query_result_mdrlist: [...state.query_result_mdrlist, ...action.payload],
                query_result_mdrlist_ref : state.query_result_mdrlist_ref.length <= 0 ?  action.payload : state.query_result_mdrlist_ref,
                // doneState : true
                loading: false

                // query_result: action.payload,
            };


        case EXECUTE_SMART_QUERY_MDR_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false

            };

        case SET_SMART_QUERY_MDR_LIST_DETAILS:
            return {
                ...state,
                query_details_mdrlist: action.payload,

            };
        case RESET_QUERY_RESULT_MDR_LIST:
            return {
                ...state,
                query_result_mdrlist: [],
                query_details_mdrlist: {},

            };

        case GET_PATIENT_PROB_CODE_MDR_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false

            };

        case ABORT_SMART_QUERY:
            return {
                ...state,
                abortStatus: action.payload,
                // loading : true
            };

        case ABORT_SMART_QUERY_MDR_LIST:
            return {
                ...state,
                abortStatusMdrList: action.payload,
            };


        case GET_MONTHLY_CHART_SMART_SUCCESS:
            return {
                ...state,
                monthly_chart: action.payload,
                loading: false
            };

        case GET_YEAR_CHART_SMART_SUCCESS:
            return {
                ...state,
                year_chart: action.payload,
                loading: false
            };

        case GET_MONTHLY_CHART_SMART_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false

            };


        case GET_FIELD_CHART_SMART_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false

            };

        case GET_EVENT_FIELD_CHART_SMART_SUCCESS:
            return {
                ...state,
                event_chart: action.payload,
                loading: false
            };

        case GET_MANUFACTURER_FIELD_CHART_SMART_SUCCESS:
            return {
                ...state,
                manufacturer_chart: action.payload,
                loading: false
            };

        case GET_DEVICE_FIELD_CHART_SMART_SUCCESS:
            return {
                ...state,
                device_chart: action.payload,
                loading: false
            };

        case GET_SUBMISSION_FIELD_CHART_SMART_SUCCESS:
            return {
                ...state,
                submission_chart: action.payload,
                loading: false
            };

        case GET_REASON_FIELD_CHART_SMART_SUCCESS:
            return {
                ...state,
                reason_chart: action.payload,
                loading: false
            };


        case GET_SPECIALTY_FIELD_CHART_SMART_SUCCESS:
            return {
                ...state,
                specialty_chart: action.payload,
                loading: false
            };

            case GLOBAL_SEARCH_RESULT_MDR_LIST_SUCCESS:
                return {
                    ...state,
                    global_search_results: [...state.global_search_results, ...action.payload],
                    // doneState : true
                    loading: false

                };

                case GLOBAL_SEARCH_RESULT_MDR_LIST_FAIL:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false
        
                    };

                    case SET_GLOBAL_SEARCH_RESULT_DETAILS:
                        return {
                            ...state,
                            global_search_details: action.payload,
            
                        };

                        case GLOBAL_SEARCH_RESULT_NO_DATA:
                            return {
                                ...state,
                                global_search_no_data: action.payload, loading: false
                
                            };

                            case ABORT_GLOBAL_SEARCH_RESULT:
                                return {
                                    ...state,
                                    abortStatusGlobalSearch: action.payload,
                                    // loading : true
                                };

                                case RESET_GLOBAL_SEARCH:
                                    return {
                                        ...state,
                                        global_search_results: [],
                                        global_search_details: {},
                        
                                    };

        default:
            return state
    }
}

export default SmartQueryReducer;
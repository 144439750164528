import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

const No = (cell,index) => {
    return <Link to="#" className="text-body fw-bold">{cell.rows.length-cell.row.index }.</Link>
};

const ColName = (cell) => {
    return cell.value ? cell.value : "";
};

const ModuleName = (cell) => {
    return cell.value ? <span style={{cursor : 'pointer', color : '#369bff'}}>{cell.value}</span> : "";
};


const ApprovalStatus = (cell) => {
    switch (cell.value) {
        case "Approved":
            return <Badge className="badge-soft-success">Approved</Badge>
        case "Pending":
            return <Badge className="badge-soft-danger">Pending</Badge>
        // case "Freelance":
        //     return <Badge className="badge-soft-info">Freelance</Badge>
        // case "Internship":
        //     return <Badge className="badge-soft-warning">Internship</Badge>
    };   
};

const UpdateOn = (cell) => {
    return cell.value ? moment(cell.value).format('lll') : "";
};


const Status = (cell) => {
    switch(cell.value) {
        case "Active":
            return <Badge className="bg-success">Active</Badge>
        case "Archived":
            return <Badge className="bg-danger">Archived</Badge>
        // case "Close":
        //     return <Badge className="bg-danger">Close</Badge>
    }
};


export { No, ColName, ApprovalStatus, UpdateOn, Status, ModuleName };
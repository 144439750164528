import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    GET_USER_CHARTS_DATA,
    GET_MDR_SUBMIT_DATA,
    GET_MDR_STATS_DATA,
    GET_MDR_STATS_DATA_YEAR,
    SET_LOADING_INDICATOR_DASHBOARD
} from "./actionTypes";

export const apiSuccess = (actionType, data) => ({
    type: API_SUCCESS,
    payload: { actionType, data },
});

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

// charts data
export const getChartsData = (periodType) => ({
    type: GET_CHARTS_DATA,
    payload: periodType
});

// charts data
export const getUserChartsData = () => ({
    type: GET_USER_CHARTS_DATA
});

export const getMDRSubmitData = () => ({
    type: GET_MDR_SUBMIT_DATA
});

export const getMDRStatsData = () => ({
    type: GET_MDR_STATS_DATA
});

export const getMDRStatsDataYear = () => ({
    type: GET_MDR_STATS_DATA_YEAR
});

export const setLoadingIndicatorDashboard = (loading) => {
    // console.log(loading)
    return {
      type: SET_LOADING_INDICATOR_DASHBOARD,
      payload: loading,
    };
  };
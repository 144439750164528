import React, {useEffect, useState} from "react"
import {
  Col,
  Row,
  Container,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle
} from "reactstrap"
import { Link } from 'react-router-dom';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Components
import CardUser from "./card-user"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
// import Earning from "./earning"
import SalesAnalytics from "./sales-analytics"
import TotalSellingProduct from "./total-selling-product"
import Tasks from "./tasks"
import ChatBox from "./chat-box"

import TopDevice from "./TopDevice";
import TopPatient from "./TopPatient";

import HorizontalAds from '../Dashboard Corporate/HorizontalAds';
import VerticalAds from '../Dashboard Corporate/VerticalAds';
import { authUser } from "helpers/dataConfig";
import MDRStats from "./mdrstats";
//redux
import { useSelector, useDispatch } from "react-redux";
import {
  getMDRStatsDataYearPublic as onGetMDRStatsDataYearPublic,
  getMDRStatsDataPublic as onGetMDRStatsDataPublic,
  getMDRTopDevicesPublic as  onGetMDRTopDevicesPublic,
  getMDRTopPatientsPublic as onGetMDRTopPatientsPublic,
  getMDRYearDataPublic as onGetMDRYearDataPublic,
  getMDRMonthCountsPublic as onGetMDRMonthCountsPublic,
  getMDREventCountPublic as onGetMDREventCountPublic,
  getMDRYearlyCountPublic as onGetMDRYearlyCountPublic,
  getMDRMedicalSpecCountPublic as onGetMDRMedicalSpecCountPublic,
  getMDRMonthlyEventCountPublic as onGetMDRMonthlyEventCountPublic
} from '../../store/actions'
import Loader from "components/Common/Loader";
import RadialChart from "pages/AllCharts/apex/RadialChart";
import DonutChart from "pages/AllCharts/apex/dountchart";
import LineColumnArea from "pages/AllCharts/apex/LineColumnArea";
import FunnelChart from "pages/AllCharts/apex/funnel";
import EventDonut from "./event-donut";
import MDRRadial from "./mdr-radial";
import MDRFunnel from "./mdr-funnel";
import EventLine from "./event-line";


const DashboardSaas = props => {
  const reports = [
    {
      icon: "bx bxs-report",
      title: "My MDR Reports",
      value: "1,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-devices",
      title: "My Device Reports",
      value: "452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
  
  ]

  //meta title
  document.title = "Public Dashboard | Smarteeva"

  const dispatch = useDispatch();
  const [userData, setUserData] = useState("");
  const [mdrStats,setMdrStats] = useState([])
  const [mdrStatsYear,setMdrStatsYear] = useState([])

  const [deviceCodes,setDeviceCodes] = useState([])
  const [patientCodes,setPatientCodes] = useState([])

  const [mdrYearData,setMdrYearData] = useState([])
  const [mdrMonthCounts,setMdrMonthCounts] = useState([])

  const [mdrEventCount,setMdrEventCount] = useState([])
  const [mdrYearlyCount,setMdrYearlyCount] = useState([])
  const [mdrMedicalSpecCount,setMdrMedicalSpecCount] = useState([])
  const [mdrMonthlyEventCount,setMdrMonthlyEventCount] = useState([])



  const [isLoading, setIsLoading] = useState(false);


  const { mdrStatsData } = useSelector(state => ({
    mdrStatsData: state.DashboardPublic.mdrStatsData,
  }));

  const { mdrStatsDataYear } = useSelector(state => ({
    mdrStatsDataYear: state.DashboardPublic.mdrStatsDataYear,
  }));

  const { topDevices } = useSelector(state => ({
    topDevices: state.DashboardPublic.topDevices,
  }));

  const { topPatients } = useSelector(state => ({
    topPatients: state.DashboardPublic.topPatients,
  }));

  const { mdr_year_data } = useSelector(state => ({
    mdr_year_data: state.DashboardPublic.mdr_year_data,
  }));

  const { mdr_month_counts } = useSelector(state => ({
    mdr_month_counts: state.DashboardPublic.mdr_month_counts,
  }));

  const { mdr_event_count } = useSelector(state => ({
    mdr_event_count: state.DashboardPublic.mdr_event_count,
  }));

  const { mdr_yearly_count } = useSelector(state => ({
    mdr_yearly_count: state.DashboardPublic.mdr_yearly_count,
  }));

  const { mdr_medical_spec_count } = useSelector(state => ({
    mdr_medical_spec_count: state.DashboardPublic.mdr_medical_spec_count,
  }));

  const { mdr_monthly_event_count } = useSelector(state => ({
    mdr_monthly_event_count: state.DashboardPublic.mdr_monthly_event_count,
  }));


  const { loading } = useSelector(state => ({
    loading : state.DashboardPublic.loading
  }));


  useEffect(() => {
    // console.log(loading)
    setIsLoading(loading)
}, [loading]);

useEffect(() => {
  if(mdrStatsData && !mdrStatsData.length){
    // console.log('not yet')
  dispatch(onGetMDRStatsDataPublic());
  }
}, [dispatch,mdrStatsData]);


useEffect(() => {
  // console.log(mdrStatsData)
  setMdrStats(mdrStatsData)

}, [mdrStatsData]);

useEffect(() => {
  if(mdrStatsDataYear && !mdrStatsDataYear.length){
  dispatch(onGetMDRStatsDataYearPublic());
  }
}, [dispatch,mdrStatsDataYear]);


useEffect(() => {
  if(topDevices && !topDevices.length){
  dispatch(onGetMDRTopDevicesPublic());
  }
}, [dispatch,topDevices]);


useEffect(() => {
  if(topPatients && !topPatients.length){
  dispatch(onGetMDRTopPatientsPublic());
  }
}, [dispatch,topPatients]);

useEffect(() => {
  if(mdr_year_data && !mdr_year_data.length){
  dispatch(onGetMDRYearDataPublic());
  }
}, [dispatch,mdr_year_data]);

useEffect(() => {
  if(mdr_month_counts && !mdr_month_counts.length){
  dispatch(onGetMDRMonthCountsPublic());
  }
}, [dispatch,mdr_month_counts]);


useEffect(() => {
  if(mdr_event_count && !mdr_event_count.length){
  dispatch(onGetMDREventCountPublic());
  }
}, [dispatch,mdr_event_count]);

useEffect(() => {
  if(mdr_yearly_count && !mdr_yearly_count.length){
  dispatch(onGetMDRYearlyCountPublic());
  }
}, [dispatch,mdr_yearly_count]);

useEffect(() => {
  if(mdr_medical_spec_count && !mdr_medical_spec_count.length){
  dispatch(onGetMDRMedicalSpecCountPublic());
  }
}, [dispatch,mdr_medical_spec_count]);

useEffect(() => {
  if(mdr_monthly_event_count&& !mdr_monthly_event_count.length){
  dispatch(onGetMDRMonthlyEventCountPublic());
  }
}, [dispatch,mdr_monthly_event_count]);

useEffect(() => {
  // console.log(mdrStatsDataYear)
  setMdrStatsYear(mdrStatsDataYear)

}, [mdrStatsDataYear]);

useEffect(() => {
  // console.log(topDevices)
  setDeviceCodes(topDevices)

}, [topDevices]);

useEffect(() => {
  // console.log(topPatients)
  setPatientCodes(topPatients)

}, [topPatients]);

useEffect(() => {
  // console.log(mdr_year_data.data)
  setMdrYearData(mdr_year_data)

}, [mdr_year_data]);

useEffect(() => {
  // console.log(mdr_month_counts.data)
  setMdrMonthCounts(mdr_month_counts)

}, [mdr_month_counts]);

useEffect(() => {
  // console.log(mdr_event_count.data)
  setMdrEventCount(mdr_event_count)

}, [mdr_event_count]);

useEffect(() => {
  // console.log(mdr_yearly_count.data)
  setMdrYearlyCount(mdr_yearly_count)

}, [mdr_yearly_count]);

useEffect(() => {
  // console.log(mdr_medical_spec_count.data)
  // console.log(mdr_event_count.data)
  setMdrMedicalSpecCount(mdr_medical_spec_count)

}, [mdr_medical_spec_count]);

useEffect(() => {
  // console.log(mdr_monthly_event_count.data)
  setMdrMonthlyEventCount(mdr_monthly_event_count)

}, [mdr_monthly_event_count]);

  useEffect(() => {
    if (localStorage.getItem(authUser)) {

        const obj = JSON.parse(localStorage.getItem(authUser));
        setUserData(obj);
        // console.log(obj)
      // }
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Public Dashboard" />

          {/* Card User */}
          {mdrMonthCounts.data &&  <CardUser userData={userData} deaths={mdrMonthCounts.data[0].DEATHS} injuries={mdrMonthCounts.data[0].INJURIES} 
          total={mdrMonthCounts.data[0].TOTAL}
          />}



          <Row>
          <Col xl="12">
            {/* MDR reporting */}
            {mdrMonthCounts.data &&  <MDRStats dataColors='["--bs-primary"]' mdrStats={mdrStats} mdrStatsYear={mdrStatsYear}
            prevMonthCount={mdrMonthCounts.data[0].TOTAL}
            />}

            {/* <VerticalAds /> */}
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Row>
                {/*mimi widgets */}
              {mdrYearData.data &&  <MiniWidget dataColors='["--bs-primary"]' lastYear={mdrYearData.data[0].LAST_YEAR_MDR} thisYear={mdrYearData.data[0].THIS_YEAR_MDR} injury={mdrYearData.data[0].THIS_YEAR_INJURY}
              death={mdrYearData.data[0].THIS_YEAR_DEATH}
              malfunction={mdrYearData.data[0].THIS_YEAR_MALFUNCTION}
              />}
              </Row>
            </Col>
          </Row>
          {/* <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="d-flex flex-center py-2">
                    <h2 className='py-2'>Google Ads Placeholder </h2>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <Row>
          <Col lg={6}>
          
          {mdrYearlyCount.data  &&  mdrEventCount.data &&
          <MDRRadial dataColors='["--bs-primary","--bs-success", "--bs-danger", "--bs-warning",  "--bs-info"]'
          data={mdrYearlyCount.data} total={mdrEventCount.data[0].TOTAL}
          />
          }
          
            </Col>

            <Col lg={6}>

            {mdrEventCount.data &&   <EventDonut dataColors='["--bs-success", "--bs-warning","--bs-info", "--bs-danger"]'
                  data={mdrEventCount.data[0]}
                  /> }
           
            </Col>

            <Col lg={12}>
              {/* <Card>
                <CardBody>
                  <CardTitle className="mb-4">Donut Chart</CardTitle> */}
                  { mdrEventCount.data && mdrMedicalSpecCount.data  && 
                   <MDRFunnel data={mdrMedicalSpecCount.data}
                  total={mdrEventCount.data[0].TOTAL}
                  dataColors='["--bs-primary","--bs-primary", "--bs-danger","--bs-info", "--bs-warning"]'
                  />
}
                {/* </CardBody>
              </Card> */}
            </Col>
          </Row>

          <Row>
          <Col lg={12}>
              {/* <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Line, Column & Area Chart{" "}
                  </CardTitle> */}
                  {mdrMonthlyEventCount.data &&  <EventLine dataColors='["--bs-danger","--bs-primary", "--bs-success"]'
                  data={mdrMonthlyEventCount.data}
                  />
}
                {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
            <TopDevice topDevice={deviceCodes} />
            </Col>
            <Col lg={6}>
            <TopPatient topPatient={patientCodes} />
            </Col>
          </Row>
          {/* <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">List of MDRs Submitted</h5>
                    <div className="flex-shrink-0">
                      <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-3"><i className="mdi mdi-plus"></i> Add a New MDR</Link>
                      <UncontrolledDropdown className="dropdown d-inline-block me-1">
                        <DropdownToggle type="menu" className="btn btn-light" id="dropdownMenuButton1">
                          <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                        <DropdownMenu>
                          <li><DropdownItem href="#">Action</DropdownItem></li>
                          <li><DropdownItem href="#">Another action</DropdownItem></li>
                          <li><DropdownItem href="#">Something else here</DropdownItem></li>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <div className="d-flex flex-center py-5">
                    <h2 className='py-5 my-5'>MDR Table Placeholder</h2>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <Row>
            {/* <HorizontalAds /> */}
          </Row>
        </Container>
      </div>
<Loader isLoading={isLoading} /> 

    </React.Fragment>
  )
}

export default DashboardSaas

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
  Action,
  init,
  EmbedEvent,
  HostEvent,
  RuntimeFilterOp,
  AuthType
} from "@thoughtspot/visual-embed-sdk";
import { LiveboardEmbed, useEmbedRef } from "@thoughtspot/visual-embed-sdk/react";
import Loader from "components/Common/Loader";
import { authUser, ts_token_api_url } from "helpers/dataConfig";




const RecallDashboard = props => {
  const [modal, setmodal] = useState(false);
  const [subscribemodal, setSubscribemodal] = useState(false);

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  const [isLoading, setIsLoading] = useState(false);

  const [deviceCodes,setDeviceCodes] = useState([])
  const [patientCodes,setPatientCodes] = useState([])

  const [mdrYearData,setMdrYearData] = useState([])
  const [mdrMonthCounts,setMdrMonthCounts] = useState([])

  const [mdrEventCount,setMdrEventCount] = useState([])
  const [mdrYearlyCount,setMdrYearlyCount] = useState([])
  const [mdrMedicalSpecCount,setMdrMedicalSpecCount] = useState([])
  const [mdrMonthlyEventCount,setMdrMonthlyEventCount] = useState([])
  const [userData,setUserData] = useState(null)

  // const TSURL = "https://smarteeva.thoughtspot.cloud";

  const [hiddenActions, setHiddenActions] = useState([
    Action.Subscription,
    Action.Share,
    Action.Save,
    Action.Edit,
    Action.EditTitle,
    Action.MakeACopy,
    Action.RenameModalTitleDescription,
    Action.UpdateTML,
    Action.EditTML,
    Action.SchedulesList,
    Action.RequestVerification,
    Action.SyncToOtherApps,
    Action.LiveboardInfo,
    Action.ManagePipelines,
    Action.SyncToSheets,
    Action.Explore,
    Action.CreateMonitor,
    Action.ExportTML,
    Action.CopyLink,
    Action.AddToFavorites,
    Action.Pin,
    Action.ShowUnderlyingData,
    'savedqueries',
    Action.SpotIQAnalyze,
    ])

    const [disableActions, setDisableActions] = useState([
      
      Action.Explore,
      Action.Pin,

      Action.DownloadAsPdf,
      Action.ShowUnderlyingData,
    Action.Download,
      ])

  const embedRef = useEmbedRef();

    //apply runtime filters
    const runtimeFilters = [
      {
        columnName: "state",
        operator: RuntimeFilterOp.EQ,
        values: ["michigan"],
      },
    ];
   const onLoad = () => {
   console.log(EmbedEvent.Load, {});
   };
   //Register an event handler to trigger the SetVisibleVizs event when the Liveboard is rendered
    const onLiveboardRendered = () => {
      embedRef.current.trigger(HostEvent.SetVisibleVizs, [
         "3f84d633-e325-44b2-be25-c6650e5a49cf",
         "28b73b4a-1341-4535-ab71-f76b6fe7bf92",
        ]);
      };

  useEffect(() => {
   
  }, []);

  useEffect(() => {
    let userDt = JSON.parse(localStorage.getItem(authUser))
    // console.log(userDt)
    if(userDt != null){
     setUserData(userDt)
    
    }
   }, [localStorage.getItem(authUser)]);
 
 
   useEffect(() => {
    //  console.log(userData)
     if(userData){
  if(userData.fld_companyid == 0 || userData.fld_companyid == null || userData.fld_companyid == '')
       {
         setDisableActions(disableActions)
       }else{
         setDisableActions([])
       }
     }
 }, [userData]);

  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");
  const [userCount, setUserCount] = useState([]);
  const [reports2, setReports2] = useState([])
  const [latestMdrSubmits,setLatestMdrSubmits] = useState([])
  const [mdrStats,setMdrStats] = useState([])
  const [mdrStatsYear,setMdrStatsYear] = useState([])


  init({
    thoughtSpotHost: process.env.REACT_APP_TS_URL,
    autoLogin: true,
    authType: AuthType.TrustedAuthTokenCookieless,
    username: process.env.REACT_APP_TS_USERNAME,
    // username: JSON.parse(localStorage.getItem(authUser)).username,
    customizations: {
      style: {
          customCSS: {
              variables: {
                  //"--ts-var-root-font-family": 'Poppins, sans-serif',
                  "--ts-var-root-font-family": 'Inter, sans-serif',
                  "--ts-var-button-border-radius" : '5px',
                  "--ts-var-search-bar-text-font-style": "normal",
  
              },
              rules_UNSTABLE: {
                  '@font-face': {
                      //'font-family': 'Poppins, sans-serif',
                      'font-family': 'Inter, sans-serif',
                  },
                  '.answer-title-module__descriptionTextOneLine': {
                    'display': 'none !important',
                  },
                  '.answer-title-module__titleText': {
                    'font-weight': '400 !important',
                    'color': '545a6d !important',
                  },
                  '.chartSection .vizWrapper .bk-axis-label-container .bk-axis-label-control .axis-label-title': {
                    'font-weight': '400 !important',
                    'color': '545a6d !important',
                  },
                  '.kpi-module__hero': {
                    'font-weight': '500 !important',
                  },
                  '.bk-powered-footer .bk-ts-powered-footer-logo': {
                    'display': 'none !important',
                  },
                  '.pinboard-header-module__embedPinboardHeader':{
                    'margin-top' : '25px'
                  }
                //    '.answer-content-module__compactVizContainer .answer-content-module__answerVizContainer':{
                //   'background-image': 'url("data:image/svg+xml;utf8,<svg xmlns='+"'"+'http://www.w3.org/2000/svg'+"'"+' version='+"'"+'1.1'+"'"+' height='+"'"+'200px'+"'"+' width='+"'"+'500px'+"'"+'><text x='+"'"+'25%'+"'"+' y='+"'"+'50%'+"'"+' fill='+"'"+'lightgray'+"'"+' font-family='+"'"+'Helvetica, Arial, sans-serif'+"'"+' font-size='+"'"+'20'+"'"+' opacity='+"'"+'0.4'+"'"+' transform='+"'"+'rotate(-30, 250, 25)'+"'"+'>SMARTEEVA SOFTWARE, INC.</text></svg>")',
                //   'background-repeat': 'no-repeat',
                //   'background-position': 'center'
                // }
              }
          }
      },
      content: {
        strings: {
            "SpotIQ analyse": "SmartIQ Analyse",
        }
    }
  },
    getAuthToken: () => {
      // fetch() returns a Promise naturally. Assumes a JSON response from the token request service with a 'token' property
      // return fetch(`${ts_token_api_url}${JSON.parse(localStorage.getItem(authUser)).username}`)
      return fetch(`${ts_token_api_url}${process.env.REACT_APP_TS_USERNAME}`)
        .then((response) => response.text())
        // .then((data) => console.log(data.token));
    }
  });
  
  

  const { loading } = useSelector(state => ({
    loading : state.Dashboard.loading
  }));


  useEffect(() => {
    // console.log(loading)
    setIsLoading(loading)
}, [loading]);

 

  //meta title
  document.title = "Recall Dashboard | Smarteeva";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Recall Dashboard")}
          />

{userData && userData.fld_companyid != undefined &&   
      <LiveboardEmbed
                frameParams={{
                    height: '100vh'
                }}
                ref={embedRef}
                liveboardId="9ffe78ea-a74f-409d-929d-015fdde1179c"
                // runtimeFilters={runtimeFilters}
                onLoad={onLoad}
                hiddenActions={hiddenActions}
                // disabledActions={disableActions}
                disabledActions={userData.fld_companyid == 0 || userData.fld_companyid == null || userData.fld_companyid == '' ? disableActions : []}
                // onLiveboardRendered={onLiveboardRendered}
            />
          }
       
        </Container>
      </div>

<Loader isLoading={isLoading} /> 
    </React.Fragment>
  );
};

RecallDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(RecallDashboard);

import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Countdown
import Countdown from "react-countdown"

//Import Images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import maintanence from "../../assets/images/coming-soon.svg"

const PagesComingsoon = () => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>You are good to go!</span>
    } else {
      return (
        <>
          <div className="coming-box">
            {days} <span>Days</span>
          </div>{" "}
          <div className="coming-box">
            {hours} <span>Hours</span>
          </div>{" "}
          <div className="coming-box">
            {minutes} <span>Minutes</span>
          </div>{" "}
          <div className="coming-box">
            {seconds} <span>Seconds</span>
          </div>
        </>
      )
    }
  }

  //meta title
  document.title = "Coming Soon | Smarteeva";

  return (
    <React.Fragment>

      <div className="my-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center">
                <Row className="justify-content-center mb-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h2 className="head-error">Hang on! We are under maintenance</h2>
                <h6 className="subhead-error pt-3">
                  It will not take a long time till we get the error fixed. We will be live again in
                </h6>

                <Row className="justify-content-center mt-5">
                  <Col md="8">
                    <div className="counter-number">
                      <Countdown date="2023/12/31" renderer={renderer} />
                    </div>
                  </Col>
                </Row>
                <div className="mt-5 text-center">
                  <Link
                    className="btn btn-primary py-3 px-5 waves-effect waves-light "
                    to="/mdr-dashboard"
                  >
                    Back to Dashboard
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PagesComingsoon

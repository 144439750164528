import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  GOOGLE_SOCIAL_LOGIN,
  CHECK_SOCIAL_LOGIN,
  GET_USER_PERSONAL_DETAILS,
  SET_LOADING_INDICATOR,
  LOGIN_USER_PERSONAL_DETAILS,
  VERIFY_USER_EMAIL_DETAILS,
} from "./actionTypes"

export const loginUser = (user, history, rememberText) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, rememberText },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (type, history) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { type, history },
  };
};


export const googleSocialLogin = () => {
  return {
    type: GOOGLE_SOCIAL_LOGIN,
  };
};
export const checkSocialLogin = (history) => {
  return {
    type: CHECK_SOCIAL_LOGIN,
    payload: { history },
  };
};

export const getUserPersonalDetails = (dispatch) => {
  return {
    type: GET_USER_PERSONAL_DETAILS,
    payload: { dispatch },
  };
};

export const loginPersonalUserData = (userPersonalData) =>{
  // console.log(userPersonalData)
  return {
    type: LOGIN_USER_PERSONAL_DETAILS,
    payload: userPersonalData,
  };
}

export const verifyUserEmailData = (userDetails) =>{
  // console.log(userPersonalData)
  return {
    type: VERIFY_USER_EMAIL_DETAILS,
    payload: {userDetails},
  };
}

export const setLoadingIndicator = (loading) => {
  // console.log(loading)
  return {
    type: SET_LOADING_INDICATOR,
    payload: loading,
  };
};


import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { size, map } from "lodash";
import { Badge } from 'reactstrap';


const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
        str === "" || str === undefined ? "" : str.toLowerCase()
    );
};

const No = (cell,index) => {
    return <Link to="#" className="text-body fw-bold">{cell.rows.length-cell.row.index }.</Link>
};

const Name = (cell) => {
    return cell.value ? cell.value+' '+cell.row.original.FLD_LNAME : '';
};

const Email = (cell) => {
    return cell.value ? cell.value : '';
};

const Mobile = (cell) => {
    return cell.value ? '('+cell.row.original.FLD_PHONECODE+') ' +cell.value : "";
};

const Location = (cell) => {
    return cell.value ? cell.value : '';
};

const IsAdmin = (cell) => {
      switch (cell.value) {
        case 1:
            return  <i className="mdi mdi-check-decagram" style={{fontSize : 18, color : '#34c38f'}}></i> 
        case 0:
            return  <i className="mdi mdi-close-thick" style={{fontSize : 18, color : '#f46a6a'}}></i> 
      }
};


const ApprovalStatus = (cell) => {
    switch (cell.value) {
        case "Approved":
            return <Badge className="badge-soft-success">Approved</Badge>
        case "Pending":
            return <Badge className="badge-soft-danger">Pending</Badge>
        // case "Freelance":
        //     return <Badge className="badge-soft-info">Freelance</Badge>
        // case "Internship":
        //     return <Badge className="badge-soft-warning">Internship</Badge>
    };   
};

const UpdateOn = (cell) => {
    return cell.value ? moment(cell.value).format('lll') : "";
};


const Status = (cell) => {
    switch(cell.value) {
        case "Active":
            return <Badge className="bg-success">Active</Badge>
        case "Archived":
            return <Badge className="bg-danger">Archived</Badge>
        // case "Close":
        //     return <Badge className="bg-danger">Close</Badge>
    }
};


const SignupStatus = (cell) => {
    switch (cell.value) {
        case true:
            return  <i className="mdi mdi-account-check" style={{fontSize : 18, color : '#34c38f'}}></i> 
            
        case false:
            return  <i className="mdi mdi-account-cancel" style={{fontSize : 18, color : '#f46a6a'}}></i> 
      }
};

export {
    No,
    Name,
    Email,
    Mobile,
    Location,
    IsAdmin,
    SignupStatus,
    ApprovalStatus,
    UpdateOn,
    Status
};
import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  Badge,
  UncontrolledTooltip,
  FormFeedback,

} from "reactstrap"

import classnames from "classnames"
import { Link , useNavigate} from "react-router-dom"
import Select from 'react-select';
import Switch from "react-switch";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  addNewUser as onAddNewUser,
  updateCompany as onUpdateCompany,
  deleteCompany as onDeleteCompany,
  getPhonecodeUserList as onGetPhonecodeUserList,
  getCountryUserList as onGetCountryUserList,
  getStateUserList as onGetStateUserList,
  getCityUserList as onGetCityUserList,
  getCompanyUserList as onGetCompanyUserList,
  getDesignationProfile as onGetDesignationProfile,
  getDepartmentProfile as onGetDepartmentProfile,
  getActiveUsersOrg as onGetActiveUsersOrg
} from "store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import { authUser } from "helpers/dataConfig";
import Loader from "components/Common/Loader";

const AddUser = () => {

  //meta title
  document.title = "Add New User | Smarteeva";

  const navigate = useNavigate()

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  const [countryOptions, setCountryOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([])


  const [corporateOptions, setCorporateOptions] = useState([])
  const [designationOptions, setDesignationOptions] = useState([])
  const [departmentOptions, setDepartmentOptions] = useState([])
  const [adminOptions, setAdminOptions] = useState([])

  const [company, setCompany] = useState(null);

  const [countryVal, setCountryVal] = useState(null);
  const [stateVal, setStateVal] = useState(null);
  const [cityVal, setCityVal] = useState(null);
  const [corporateVal, setCorporateVal] = useState(null);
  const [designationVal, setDesignationVal] = useState(null);
  const [departmentVal, setDepartmentVal] = useState(null);
  const [adminVal, setAdminVal] = useState(null);


  const [phoneCodeVal, setPhoneCodeVal] = useState(null);

  const [numRegex, setNumRegex] = useState(/^[0-9]*$/)

  const [isLoading, setIsLoading] = useState(false);


  const dispatch = useDispatch();
  const { phonecode } = useSelector(state => ({
    phonecode: state.user.phonecode,
  }));

  const { country } = useSelector(state => ({
    country: state.user.country,
  }));

  const { states } = useSelector(state => ({
    states: state.user.states,
  }));

  const { city } = useSelector(state => ({
    city: state.user.city,
  }));


  const { companys } = useSelector(state => ({
    companys: state.user.companys,
  }));

  const { active_org_users } = useSelector(state => ({
    active_org_users: state.user.active_org_users,
  }));

  const { designation } = useSelector(state => ({
    designation: state.Profile.designation,
  }));

  const { department } = useSelector(state => ({
    department: state.Profile.department,
  }));


  const { error, loading } = useSelector(state => ({
    error: state.user.error,
    loading : state.user.loading
  }));

  useEffect(() => {
    setIsLoading(loading)
}, [loading]);

  useEffect(() => {
    // console.log(companys)
    if (phonecode && !phonecode.length) {
        dispatch(onGetPhonecodeUserList());
    }
    if (country && !country.length) {
      dispatch(onGetCountryUserList());
  }

}, [dispatch, phonecode, country]);


useEffect(() => {
  // console.log(companys)
if (companys && !companys.length) {
  dispatch(onGetCompanyUserList());
}

}, [dispatch, companys]);


useEffect(() => {
if (designation && !designation.length) {
  dispatch(onGetDesignationProfile());
}

}, [dispatch, designation]);


useEffect(() => {
  if (department && !department.length) {
    dispatch(onGetDepartmentProfile());
  }
  
  }, [dispatch, department]);

  // useEffect(() => {
  //   if (active_org_users && !active_org_users.length) {
  //     dispatch(onGetActiveUsersOrg());
  //   }
    
  //   }, [dispatch, active_org_users]);

useEffect(() => {
    setPhoneCodeOptions(phonecode);
    setCountryOptions(country)
}, [phonecode, country]);

useEffect(() => {
  setStateOptions(states)
}, [states]);

useEffect(() => {
  setCityOptions(city)
}, [city]);

useEffect(() => {
  // console.log(companys)
  // setCorporateOptions(companys)
  const userAdminDt = JSON.parse(localStorage.getItem(authUser))
  if(userAdminDt.fld_issuperadmin == 1){
    setCorporateOptions(companys)
  }else{
    setCorporateOptions(companys.filter(val => val.value == userAdminDt.fld_companyid))
    dispatch(onGetActiveUsersOrg({companyid : userAdminDt.fld_companyid}));
  }
}, [companys]);

useEffect(() => {
  // console.log(designation)
  setDesignationOptions(designation)
}, [designation]);

useEffect(() => {
  // console.log(designation)
  setDepartmentOptions(department)
}, [department]);

useEffect(() => {
  // console.log(active_org_users)
  setAdminOptions(active_org_users)
}, [active_org_users]);



  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        fname: '',
        lname: '',
        phonecode : '',
        phone : '',
        email:'',
        address:'',
        country:'',
        state:'',
        city:'',
        postalcode:''

    },
    validationSchema: Yup.object({
        // Id: Yup.string().matches(
        //     /[0-9\.\-\s+\/()]+/,
        //     "Please Enter Valid Job Id"
        // ).required("Please Enter Your Job Id"),
        // jobTitle: Yup.string().required("Please Enter Your Job Title"),
        fname: Yup.string().required("Please Enter First Name"),
        lname: Yup.string().required("Please Enter Last Name"),
        phonecode: Yup.string().required("Please Select Phone Code"),
        phone: Yup.string().matches(numRegex, 'Please enter a valid number').required("Please Enter Phone Number"),
        email: Yup.string().email('Please enter a valid email').required("Please Enter Email Address"),
        address: Yup.string().required("Please Enter Address"),
        country: Yup.string().required("Please Select Country"),
        state: Yup.string().required("Please Select State"),
        city: Yup.string().required("Please Select City"),
        postalcode: Yup.string().matches(numRegex, 'Please enter a valid postal code').required("Please Enter Postal Code")
    }),
    onSubmit: (values) => {
      toggleTabVertical(activeTabVartical + 1)
    },
});



 // validation
 const validationCorporate = useFormik({
  // enableReinitialize : use this flag when initial values needs to be changed
  enableReinitialize: true,

  initialValues: {
      companyid : '',
      designation:'',
      department:'',
      employeeid:'',
      adminid:'',
      isadmin:0

  },
  validationSchema: Yup.object({
    companyid: Yup.string().required("Please Select Organisation Name"),
    designation: Yup.string().required("Please Select Designation"),
    department: Yup.string().required("Please Select Department"),
    employeeid: Yup.string().required("Please Enter Employee Id"),
    adminid: Yup.string().required("Please Select Admin Name")
  }),
  onSubmit: (values) => {


    // console.log(values)
    // console.log(validation)
    const userObj = { ...validation.values, ...values};
    dispatch(onAddNewUser(userObj, navigate));

      // if (isEdit) {
      //     const updateJobList = {
      //         id: job ? job.id : 0,
      //         jobId: values.jobId,
      //         jobTitle: values.jobTitle,
      //         companyName: values.companyName,
      //         location: values.location,
      //         experience: values.experience,
      //         position: values.position,
      //         type: values.type,
      //         postedDate: "02 June 2021",
      //         lastDate: "25 June 2021",
      //         status: values.status,
      //     };
      //     // update Job
      //     dispatch(onUpdateJobList(updateJobList));
      //     validation.resetForm();
      // } else {
      //     const newJobList = {
      //         id: Math.floor(Math.random() * (30 - 20)) + 20,
      //         jobId: values["jobId"],
      //         jobTitle: values["jobTitle"],
      //         companyName: values["companyName"],
      //         location: values["location"],
      //         experience: values["experience"],
      //         position: values["position"],
      //         type: values["type"],
      //         postedDate: "02 June 2021",
      //         lastDate: "25 June 2021",
      //         status: values["status"],
      //     };
      //     // save new Job
      //     dispatch(onAddNewJobList(newJobList));
      //     validation.resetForm();
      // }
      // toggle();
  },
});

const handleKeyDown = (e) => {
  // console.log(e.key)
  if (e.key.match(/[a-z]/i) && e.key!= 'Backspace') {
    e.preventDefault();
  }
};


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Manage Users" breadcrumbItem="Create New User" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New User Profile</CardTitle>
                  {/* <p className="text-muted mb-5">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultricies vel enim in malesuada. Nulla porta tempor placerat. Aenean vitae hendrerit lacus, vitae sollicitudin ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam tristique et mauris nec iaculis.
                  </p> */}
                  <div className="horizontal-wizard wizard clearfix horizontal">
                    <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 1,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 1,
                            })}
                            onClick={() => {
                              toggleTabVertical(1)
                            }}
                            disabled={!(passedStepsVertical || []).includes(1)}
                          >
                            <span className="number">1</span> General Info
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 2,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 2,
                            })}
                            onClick={() => {
                              toggleTabVertical(2)
                            }}
                            disabled={!(passedStepsVertical || []).includes(2)}
                          >
                            <span className="number">2</span>{" "}
                            <span>Organisation Details</span>
                          </NavLink>
                        </NavItem>
                    
                      </ul>
                      </div>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                            <Row>
                             
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                     First Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter First Name"
                                    name="fname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.fname || ""}
                                    invalid={
                                      validation.touched.fname && validation.errors.fname ? true : false
                                    }
                                  />
                                  {validation.touched.fname && validation.errors.fname ? (
                                    <FormFeedback type="invalid">{validation.errors.fname}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                    Last Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Last Name"
                                    name="lname"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lname || ""}
                                    invalid={
                                      validation.touched.lname && validation.errors.lname ? true : false
                                    }
                                  />
                                  {validation.touched.lname && validation.errors.lname ? (
                                    <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                            <Col lg="2" >
                                <FormGroup className="mb-3">
                                  <Label  className="required">Phone Code</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'phonecode'}
                                  onChange={(e)=>{
                                    setPhoneCodeVal(e)
                                    validation.setFieldValue('phonecode', e.value)
                                    validation.setFieldError('phonecode',false)
                                  }}
                                  onBlur={validation.handleBlur}
                                  options={phoneCodeOptions}
                                  value={
                                    phoneCodeVal || ""
                                }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="5">
                                <FormGroup className="mb-3">
                                  <Label  className="required">
                                     Phone Number
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Phone No."
                                    name="phone"
                                    onKeyDown={handleKeyDown}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone || ""}
                                    invalid={
                                      validation.touched.phone && validation.errors.phone ? true : false
                                    }
                                  />
                                  {validation.touched.phone && validation.errors.phone ? (
                                    <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                  ) : null}
                                  
                                </FormGroup>
                              </Col>
                              <Col lg="5">
                                <FormGroup className="mb-3">
                                  <Label  className="required">
                                     Email Address
                                  </Label>
                                  <Input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Email Address"
                                    name="email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                      validation.touched.email && validation.errors.email ? true : false
                                    }
                                  />
                                  {validation.touched.email && validation.errors.email ? (
                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label  className="required">
                                     Address
                                  </Label>
                                  <Input
                                    type="textarea"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Address"
                                    name="address"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.address || ""}
                                    invalid={
                                      validation.touched.address && validation.errors.address ? true : false
                                    }
                                  />
                                  {validation.touched.address && validation.errors.address ? (
                                    <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6" >
                                <FormGroup className="mb-3">
                                  <Label  className="required">Country</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'country'}
                                  onChange={(e)=>{
                                    if(e){
                                    setCountryVal(e)
                                    validation.setFieldValue('country', e.value)
                                    validation.setFieldError('country',false)
                                    dispatch(onGetStateUserList(e.value));
                                  }else{
                                    setCountryVal('')
                                    validation.setFieldValue('country', '')
                                    validation.setFieldError('country',true)
                                   
                                  }
                                  setStateOptions([])
                                  setCityOptions([])
                                  validation.setFieldValue('state', '')
                                  validation.setFieldValue('city', '')
                                  setStateVal('')
                                  setCityVal('')
                                }
                                }
                                  onBlur={validation.handleBlur}
                                  options={countryOptions}
                                  value={
                                    countryVal || ""
                                }
                                className={validation.touched.country && validation.errors.country ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.country && validation.errors.country ? (
                                    <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label  className="required">State</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'state'}

                                  isClearable
                                  isSearchable
                                  options={stateOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setStateVal(e)
                                    validation.setFieldValue('state', e.value)
                                    validation.setFieldError('state',false)
                                    dispatch(onGetCityUserList(e.value));
                                    }else{
                                      setStateVal('')
                                      validation.setFieldValue('state', '')
                                      validation.setFieldError('state',true)
                                     
                                    }
                                    setCityOptions([])
                                    validation.setFieldValue('city', '')
                                    setCityVal('')
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    stateVal || ""
                                }
                                invalid={
                                    validation.touched.state && validation.errors.state
                                        ? true
                                        : false
                                }
                                className={validation.touched.state && validation.errors.state ? 'invalid-select' : ''}

                                />
                                  {validation.touched.state && validation.errors.state ? (
                                    <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label  className="required">City</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'city'}

                                  isClearable
                                  isSearchable
                                  options={cityOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setCityVal(e)
                                    validation.setFieldValue('city', e.value)
                                    validation.setFieldError('city',false)
                                    }else{
                                      setCityVal('')
                                    validation.setFieldValue('city', '')
                                    validation.setFieldError('city',true)
                                    }
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    cityVal || ""
                                }
                                invalid={
                                    validation.touched.city && validation.errors.city
                                        ? true
                                        : false
                                }
                                className={validation.touched.city && validation.errors.city ? 'invalid-select' : ''}

                                />
                                  {validation.touched.city && validation.errors.city ? (
                                    <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                  ) : null}
                            
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label  className="required">
                                    Postal Code
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Postal Code"
                                    name="postalcode"
                                    onKeyDown={handleKeyDown}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.postalcode || ""}
                                    invalid={
                                      validation.touched.postalcode && validation.errors.postalcode ? true : false
                                    }
                                  />
                                  {validation.touched.postalcode && validation.errors.postalcode ? (
                                    <FormFeedback type="invalid">{validation.errors.postalcode}</FormFeedback>
                                  ) : null}
                                
                                </FormGroup>
                              </Col>
                            </Row>

                          </Form>
                        </TabPane>

                      

                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                              
                              </Row>
                              <Row>
                              <Col lg="6" >
                                <FormGroup className="mb-3">
                                  <Label className="required">Organisation Name</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'companyid'}
                                  onChange={(e)=>{
                                    if(e){
                                    setCorporateVal(e)
                                    validationCorporate.setFieldValue('companyid', e.value)
                                    validationCorporate.setFieldError('companyid',false)
                                    //dispatch
                                    dispatch(onGetActiveUsersOrg({companyid : e.value}));
                                     }else{
                                      setCorporateVal('')
                                      validationCorporate.setFieldValue('companyid', '')
                                      validationCorporate.setFieldError('companyid',true)
                                    }
                                    setAdminOptions([])
                                    validationCorporate.setFieldValue('adminid', '')
                                    setAdminVal('')

                                  }}
                                  onBlur={validationCorporate.handleBlur}
                                  options={corporateOptions}
                                  value={
                                    corporateVal || ""
                                }
                                // formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                className={validationCorporate.touched.companyid && validation.errors.companyid ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validationCorporate.touched.companyid && validationCorporate.errors.companyid ? (
                                    <FormFeedback type="invalid">{validationCorporate.errors.companyid}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label className="required">
                                      Employee Id
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Coporate Id"
                                      name="employeeid"
                                      // onKeyDown={handleKeyDown}
                                      onChange={validationCorporate.handleChange}
                                      onBlur={validationCorporate.handleBlur}
                                      value={validationCorporate.values.employeeid || ""}
                                      invalid={
                                        validationCorporate.touched.employeeid && validationCorporate.errors.employeeid ? true : false
                                      }
                                    />
                                    {validationCorporate.touched.employeeid && validationCorporate.errors.employeeid ? (
                                      <FormFeedback type="invalid">{validationCorporate.errors.employeeid}</FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                                
                              </Row>
                              
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label className="required">Designation</Label>
                                    <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'designation'}

                                  isClearable
                                  isSearchable
                                  options={designationOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setDesignationVal(e)
                                    validationCorporate.setFieldValue('designation', e.value)
                                    validationCorporate.setFieldError('designation',false)
                                    }else{
                                      setDesignationVal('')
                                      validationCorporate.setFieldValue('designation', '')
                                      validationCorporate.setFieldError('designation',true)
                                    }
                                }}
                                  
                                  onBlur={validationCorporate.handleBlur}
                                  value={
                                    designationVal || ""
                                }
                                invalid={
                                  validationCorporate.touched.designation && validationCorporate.errors.designation
                                        ? true
                                        : false
                                }
                                className={validationCorporate.touched.designation && validationCorporate.errors.designation ? 'invalid-select' : ''}

                                />
                                  {validationCorporate.touched.designation && validationCorporate.errors.designation ? (
                                    <FormFeedback type="invalid">{validationCorporate.errors.designation}</FormFeedback>
                                  ) : null}
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label className="required">Department</Label>
                                    <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'department'}

                                  isClearable
                                  isSearchable
                                  options={departmentOptions}
                                 
                                  onChange={(e)=>{
                                    if(e){
                                    setDepartmentVal(e)
                                    validationCorporate.setFieldValue('department', e.value)
                                    validationCorporate.setFieldError('department',false)
                                    }else{
                                      setDepartmentVal('')
                                    validationCorporate.setFieldValue('department', '')
                                    validationCorporate.setFieldError('department',true)
                                    }
                                  }}
                                  onBlur={validationCorporate.handleBlur}
                                  value={
                                    departmentVal || ""
                                }
                                invalid={
                                  validationCorporate.touched.department && validationCorporate.errors.department
                                        ? true
                                        : false
                                }
                                className={validationCorporate.touched.department && validationCorporate.errors.department ? 'invalid-select' : ''}

                                />
                                  {validationCorporate.touched.department && validationCorporate.errors.department ? (
                                    <FormFeedback type="invalid">{validationCorporate.errors.department}</FormFeedback>
                                  ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label className="required">Admin Name</Label>
                                    <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'adminid'}

                                  isClearable
                                  isSearchable
                                  options={adminOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setAdminVal(e)
                                    validationCorporate.setFieldValue('adminid', e.value)
                                    validationCorporate.setFieldError('adminid',false)
                                    }else{
                                      setAdminVal('')
                                      validationCorporate.setFieldValue('adminid', '')
                                      validationCorporate.setFieldError('adminid',true)
                                    }
                                  }}
                                  onBlur={validationCorporate.handleBlur}
                                  value={
                                    adminVal || ""
                                }
                                invalid={
                                  validationCorporate.touched.adminid && validationCorporate.errors.adminid
                                        ? true
                                        : false
                                }
                                className={validationCorporate.touched.adminid && validationCorporate.errors.adminid ? 'invalid-select' : ''}

                                />
                                  {validationCorporate.touched.adminid && validationCorporate.errors.adminid ? (
                                    <FormFeedback type="invalid">{validationCorporate.errors.adminid}</FormFeedback>
                                  ) : null}
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Is Admin
                                    </Label>

                                    <div className="square-switch"    style={{marginTop : 10}}>
                            <input
                               type="checkbox"
                              //  id="square-switch1"
                               name="isadmin"
                               className="switch switch-bool"
                           
                               onKeyDown={handleKeyDown}
                               onChange={()=>{
                                // console.log('clicked')
                                 if(validationCorporate.values.isadmin == 1){
                                  validationCorporate.setFieldValue('isadmin', 0)
                                 }else{
                                  validationCorporate.setFieldValue('isadmin', 1)
             
                                 }
                               }}
                               onBlur={validationCorporate.handleBlur}
                              //  value={validationCorporate.values.isadmin || ""}
                              checked={validationCorporate.values.isadmin == 0 ? false : true}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label="Yes"
                              data-off-label="No"
                              onClick={()=>{
                                // console.log('clicked')
                                 if(validationCorporate.values.isadmin == 1){
                                  validationCorporate.setFieldValue('isadmin', 0)
                                 }else{
                                  validationCorporate.setFieldValue('isadmin', 1)
             
                                 }
                               }}
                            />
                          </div>
                                    {/* <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Postal Code"
                                      name="billingpostalcode"
                                      onKeyDown={handleKeyDown}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.billingpostalcode || ""}
                                      invalid={
                                        validation.touched.billingpostalcode && validation.errors.billingpostalcode ? true : false
                                      }
                                    /> */}
                                    {/* {validation.touched.billingpostalcode && validation.errors.billingpostalcode ? (
                                      <FormFeedback type="invalid">{validation.errors.billingpostalcode}</FormFeedback>
                                    ) : null} */}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTabVartical === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTabVertical(activeTabVartical - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={
                            activeTabVartical === 2 ? "next disabled" : "next"
                          }
                        >
                          <Link
                            // to="#"
                            onClick={(e) => {
                              // toggleTabVertical(activeTabVartical + 1)
                              e.preventDefault();
                              if(activeTabVartical ==1){
                                validation.handleSubmit();
                              }
                              return false;
                            }}
                            type="submit"
                          >
                            Next
                          </Link>
                        </li>

                        {/* <li
                        style={{float  : 'left'}}
                          className={
                            activeTabVartical < 3 ? "next disabled" : "next"
                          }
                        >
                        
                          <Link
                            to="#"
                            onClick={() => {
                              validationBilling.handleSubmit();
                            }}
                          >
                            Skip & Save
                          </Link>
                        </li> */}

                        <li
                         style={{float  : 'left'}}
                          className={
                            activeTabVartical < 2 ? "next disabled" : "next"
                          }
                        >
                        
                          <Link
                            to="#"
                            onClick={() => {
                              validationCorporate.handleSubmit();
                            }}
                          >
                           Save
                          </Link>
                        </li>

                        <li
                         style={{float  : 'left'}}
                          className={ "next"}
                        >
                        
                          <Link
                           to="/corporate-list"
                            className="danger-btn"
                            // onClick={() => {
                            //   navigate('/corporate-list')
                            // }}
                          >
                           Cancel
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Loader isLoading={isLoading} /> 

    </React.Fragment>
  )
}

export default AddUser

import React, { useEffect, useMemo, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainerDT from '../../../components/Common/TableContainerDT';
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from 'react-select';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getModuleScores as onGetModuleScores,
    addNewModuleScore as onAddNewModuleScore,
    updateModule as onUpdateModule,
    deleteModuleScore as onDeleteModuleScore,
    approveModuleScore as onApproveModuleScore,
    getActiveModules as onGetActiveModules,
} from "store/actions";

import {
    No, ColName, ApprovalStatus, UpdateOn, Status, ModuleName
}
    from "./DecisionTreeScoreListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    FormGroup,
    Progress,
    Alert
} from "reactstrap";
import Loader from "components/Common/Loader";
import ApproveModal from "components/Common/ApproveModal";
import TableContainerScore from "components/Common/TableContainerScore";


function DecisionTreeScoreList() {

    //meta title
    document.title = "Module List - Score Decision Tree | Smarteeva";

    const tableRef = useRef(null);

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEditable, setIsEditable] = useState(true);



    const [moduleList, setModuleList] = useState([]);
    const [module, setModule] = useState(null);
    const [resetFilter, setResetFilter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [toggleCanvas, setToggleCanvas] = useState(false);
    const [isRight, setIsRight] = useState(false);
    const [moduleOptions, setModuleOptions] = useState([])

    const [decisionTypeOptions, setDecisionTypeOptions] = useState([
        {label : 'Reportable', value : 'Reportable', isDisabled : false},
        {label : 'Non-Repotable', value : 'Non-Repotable',  isDisabled : false},
        {label : 'To Be Escalated', value : 'To Be Escalated',  isDisabled : false},

    ]);
    const [typeVal, setTypeVal] = useState('');
    const [approveModal, setApproveModal] = useState(false);
    const [scoreData, setScoreData] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');



    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            type : '',
            status : 'Active'

        },
        validationSchema: Yup.object({
            
             type: Yup.string().required("Please Select Module Name"),
            
        }),
        onSubmit: (values) => {

            // console.log(values)

            if(scoreData.length >0){

            if(isEdit || isView){

                // console.log('in view & edit')

            //    values.moduleid = module.FLD_MODULEID
            //     // console.log(values)

            //     dispatch(onUpdateModule(values));

            let final_val = {...values, decisionData : scoreData}

                // console.log(final_val)

                dispatch(onAddNewModuleScore(final_val));

            }else{
                let final_val = {...values, decisionData : scoreData}

                // console.log(final_val)

                dispatch(onAddNewModuleScore(final_val));
            }

        }else{
            setErrorMsg('Please add Module Scoring')
        }

            // 

            // if (isEdit) {
            //     const updateJobList = {
            //         id: job ? job.id : 0,
            //         jobId: values.jobId,
            //         jobTitle: values.jobTitle,
            //         companyName: values.companyName,
            //         location: values.location,
            //         experience: values.experience,
            //         position: values.position,
            //         type: values.type,
            //         postedDate: "02 June 2021",
            //         lastDate: "25 June 2021",
            //         status: values.status,
            //     };
            //     // update Job
            //     dispatch(onUpdateJobList(updateJobList));
            //     validation.resetForm();
            // } else {
            //     const newJobList = {
            //         id: Math.floor(Math.random() * (30 - 20)) + 20,
            //         jobId: values["jobId"],
            //         jobTitle: values["jobTitle"],
            //         companyName: values["companyName"],
            //         location: values["location"],
            //         experience: values["experience"],
            //         position: values["position"],
            //         type: values["type"],
            //         postedDate: "02 June 2021",
            //         lastDate: "25 June 2021",
            //         status: values["status"],
            //     };
            //     // save new Job
            //     dispatch(onAddNewJobList(newJobList));
            //     validation.resetForm();
            // }
            // toggle();
        },
    });

    const dispatch = useDispatch();

    const { moduleScores,module_score_length, addModuleScoreSuccessMsg, activeModulesData  } = useSelector(state => ({
        moduleScores: state.DecisionTree.moduleScores,
        module_score_length: state.DecisionTree.module_score_length,
        addModuleScoreSuccessMsg: state.DecisionTree.addModuleScoreSuccessMsg,
        activeModulesData: state.DecisionTree.activeModulesData,
    }));

    const { error, loading } = useSelector(state => ({
        error: state.DecisionTree.error,
        loading : state.DecisionTree.loading
      }));
    
    
      useEffect(() => {
        setIsLoading(loading)
    }, [loading]);

    useEffect(() => {
        if (activeModulesData && !activeModulesData.length) {
            dispatch(onGetActiveModules());
    }
    }, [dispatch, activeModulesData]);

    useEffect(() => {
        setModuleOptions(activeModulesData);
       }, [activeModulesData]);

       useEffect(() => {
        if(error != ''){
            setErrorMsg(error)
        }
       }, [error]);

    useEffect(() => {


        if(module_score_length == -1){
        if (moduleScores && !moduleScores.length) {
            // console.log(modulesDT)
            // setIsLoading(true)
            dispatch(onGetModuleScores());
        }
    }
    }, [dispatch, moduleScores]);

    useEffect(() => {
        // console.log(modulesDT)

        setModuleList(moduleScores);
        // setIsRight(false)
    }, [moduleScores]);


    useEffect(() => {
 
      if(addModuleScoreSuccessMsg != ''){
       setIsRight(false)
        setTypeVal('')
        validation.resetForm()
        setScoreData([])
      }
   
    }, [addModuleScoreSuccessMsg]);

    // useEffect(() => {
    //     if (!isEmpty(companys) && !!isEdit) {
    //         setCompanyList(companys);
    //         setIsEdit(false);
    //     }
    // }, [companys]);

    // const toggle = () => {
    //     if (modal) {
    //         setModal(false);
    //         setCompany(null);
    //     } else {
    //         setModal(true);
    //     }
    // };

    // const handleJobClick = arg => {
    //     const job = arg;
    //     setJob({
    //         id: job.id,
    //         jobId: job.jobId,
    //         jobTitle: job.jobTitle,
    //         companyName: job.companyName,
    //         location: job.location,
    //         experience: job.experience,
    //         position: job.position,
    //         type: job.type,
    //         status: job.status,
    //     });

    //     setIsEdit(true);

    //     toggle();
    // };

    //delete Company
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (module) => {
        setModule(module);
        setDeleteModal(true);
    };

    // const handleDeletecompany = () => {
    //     // console.log(company)
    //     if (company && company.FLD_COMPANYID) {
    //         dispatch(onDeleteCompany(company.FLD_COMPANYID));
    //         setDeleteModal(false);
    //     }
    // };
    // const handleCompanyClicks = () => {
    //     setCompanyList("");
    //     setIsEdit(false);
    //     toggle();
    // };

    const columns = useMemo(
        () => [
            {
                Header: 'No',
                accessor: 'No',
                filterable: true,
                Cell: (cellProps) => {
                    return <No {...cellProps} />;
                }
            },
           
            {
                Header: 'Module Name',
                accessor: 'FLD_NAME',
                filterable: true,
                Cell: (cellProps) => {
                    return <Link 
                    // to={'/decision-tree-config'} state={{ state: {...cellProps.row.original, isEditable : false} }}
                    >
                        <ModuleName {...cellProps} />
                        </Link>;
                }
            },
            {
                Header: 'Key Value',
                accessor: 'FLD_KEY',
                filterable: true,
                Cell: (cellProps) => {
                    return <ColName {...cellProps} />;
                }
            },
            
            
           
            {
                Header: 'Approval Status',
                accessor: 'FLD_APPROVAL_STATUS',
                filterable: true,
                Cell: (cellProps) => {
                    return <ApprovalStatus {...cellProps} />;
                }
            },
            {
                Header: 'Date Updated On',
                accessor: 'FLD_LASTUPDATEDON',
                filterable: true,
                Cell: (cellProps) => {
                    return <UpdateOn {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'FLD_STATUS',
                filterable: true,
                // disableFilters: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Owner',
                accessor: 'FLD_OWNER',
                filterable: true,
                // disableFilters: true,
                Cell: (cellProps) => {
                    return <ColName {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link 
                                // to={'/alter-corporate-profile'} state={{ state: {...cellProps.row.original, isEditable : false} }}
                                onClick={()=>{
                                    setIsEdit(false)
                                    setIsView(true)

                                        setIsEditable(false)

                                        let data = cellProps.row.original

                                       
                                        validation.setFieldValue('type', data.FLD_MODULEID)
                                        validation.setFieldValue('key', data.FLD_KEY)
                                        validation.setFieldValue('status', data.FLD_STATUS == 'Active' ? 'Active' : 'Archived') 
                                        setTypeVal({label : data.FLD_NAME + "("+data.FLD_KEY+")", value : data.FLD_MODULEID})
                                        setScoreData(data.decisionData)
                                        setModule(data)

                                        setIsRight(true)
                                }}
                                className="btn btn-sm btn-soft-primary">
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                View
                            </UncontrolledTooltip>

                            <li>
                                <Link
                                    // to="#"
                                    // to={'/alter-corporate-profile'} state={{ state: {...cellProps.row.original, isEditable : true} }}
                                    // to={{ pathname: '/alter-corporate-profile', state: { data : cellProps.row.original } }}
                                    onClick={()=>{
                                        setIsEdit(true)
                                        setIsView(false)
                                        setIsEditable(true)

                                        // console.log(cellProps.row.original)
                                        let data = cellProps.row.original

                                  
                                        validation.setFieldValue('type', data.FLD_MODULEID)
                                        validation.setFieldValue('key', data.FLD_KEY)
                                        validation.setFieldValue('status', data.FLD_STATUS == 'Active' ? 'Active' : 'Archived') 
                                        setTypeVal({label : data.FLD_NAME + "("+data.FLD_KEY+")", value : data.FLD_MODULEID})
                                        setScoreData(data.decisionData)
                                        setModule(data)

                                        setIsRight(true)
                                    }}
                                    className="btn btn-sm btn-soft-info"
                                   
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const moduleData = cellProps.row.original;
                                        onClickDelete(moduleData);
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

      // Reset filters
  const handleReset = () => {
    tableRef.current.resetTable(); 
    // console.log(tableRef.current)
  };

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const handleAddNewModule = () =>{
    setIsEdit(false)
    setIsEditable(true)
    setIsView(false)


    // validation.setFieldValue('name', '')
    validation.setFieldValue('type', '')
    validation.setFieldValue('key', '')
    validation.setFieldValue('status', 'Active') 
    setTypeVal('')
    setModule('')

    setIsRight(!isRight);
  }


const handleKeyDown = (e) => {
    // console.log(e.key)
    if (e.key.match(/[a-z]/i) && e.key!= 'Backspace') {
      e.preventDefault();
    }
  };


  const handleDeleteModule = () => {
    // console.log(company)
    if (module && module.FLD_MODULEID) {
        dispatch(onDeleteModuleScore(module));
        setDeleteModal(false);
    }
};

const handleApproveModule = () => {
    // console.log(companyDetails)
    if (module && module.FLD_MODULEID) {
        dispatch(onApproveModuleScore(module.FLD_MODULEID));
        setApproveModal(false);
    }
  };
  

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                deleteMsg={'Are you sure you want to archive module scoring.'}
                onDeleteClick={handleDeleteModule}
                onCloseClick={() => setDeleteModal(false)}
            />

                <ApproveModal
                show={approveModal}
                approveMsg={'Are you sure you want to approve module scoring.'}
                onApproveClick={handleApproveModule}
                onCloseClick={() => setApproveModal(false)}
            />

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Smart Config" breadcrumbItem="Score Decision Tree" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">List Of Decision Tree Scoring</h5>
          
                                                    <div className="flex-shrink-0">

                                                        
                                            <Link className="btn btn-primary me-3" 
                                            onClick={handleAddNewModule}
                                            // to={{pathname : '/decision-tree-config'}}
                                            ><i className="mdi mdi-file-tree"

                                            ></i> Add Decision Tree Score </Link>
                                            </div>
                                        <div className="flex-shrink-0">

             
                                            <Link className="btn btn-light me-3" onClick={handleReset}><i className="mdi mdi-refresh"
                                            
                                            ></i> Reset Filters </Link>
                                            {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                <DropdownToggle type="menu" className="btn btn-light" id="dropdownMenuButton1">
                                                    <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                                <DropdownMenu>
                                                    <li><DropdownItem href="#">Action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                </DropdownMenu>
                                            </UncontrolledDropdown> */}
                                        </div>

                                      
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <TableContainerScore
                                        ref={tableRef}
                                        columns={columns}
                                        data={moduleScores}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        // isAddModule={true}
                                        // handleCompanyClicks={handleCompanyClicks}
                                        // resetFilter={resetFilter}
                                        isCompanyListGlobalFilter={true}
                                        customPageSize={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    
                    <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}>
            <OffcanvasHeader toggle={toggleRightCanvas}>
            {isEdit ? 'Alter Score' : isView ? 'View Score' : 'Add New Score'}  
            </OffcanvasHeader>
            <OffcanvasBody style={{marginBottom : 20}}>
              <div className="w-100 h-80vh" >
                <Card className="h-96">
                  <div className="p-4 border-bottom ">
                                <Row>

{/* {console.log(error)} */}
                                {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}
                                    <Col className="col-12">
                                       
                                           
                                 
                                       
                                    <Row>
                                        <Col lg="10">
                                            <Label className="form-label">Module Name</Label>
                                            </Col>
                                            <Col lg="2" style={{marginTop : -10, display : isEditable ? 'none' : ''}}>
                                   
                                   <Link
                                       onClick={()=>{
                                           
                                           setIsEditable(true)
                                         
                                       }}
                                       className="btn btn-sm btn-soft-info"
                                      
                                   >
                                       <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                       <UncontrolledTooltip placement="top" target="edittooltip">
                                           Edit
                                       </UncontrolledTooltip>
                                   </Link>
                            
                                           </Col>
                                            </Row>
                                            <Select
                                           
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'type'}
                                  isDisabled={!isEditable}
                                  isClearable
                                  isSearchable
                                  options={moduleOptions}
                                  onChange={(e)=>{
                                    if(e){
                                        
                                    setTypeVal(e)
                                    validation.setFieldValue('type', e.value)
                                    validation.setFieldError('type',false)
                                    validation.setFieldValue('key',e.label.split("(")[1].split(")")[0])
                                    validation.setFieldError('key',false)
                                    }else{
                                        setTypeVal('')
                                      validation.setFieldValue('type', '')
                                      validation.setFieldError('type',true)
                                      validation.setFieldValue('key', '')
                                      validation.setFieldError('key',true)
                                    }
                                }}
                                  
                                  onBlur={validation.handleBlur}
                                  value={
                                    typeVal || ""
                                }
                                invalid={
                                  validation.touched.type && validation.errors.type
                                        ? true
                                        : false
                                }
                                className={validation.touched.type && validation.errors.type ? 'invalid-select mb-3' : 'mb-3'}

                                />
                                  {validation.touched.type && validation.errors.type ? (
                                    <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                  ) : null}
                                        {/* </div> */}

                                        <div className="mb-3">
                                            <Label className="form-label">Key Value</Label>
                                            <Input
                                            disabled
                                                name="key"
                                                type="text"
                                                placeholder="Enter Key"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                // onChange={validation.handleChange}
                                                // onBlur={validation.handleBlur}
                                                value={validation.values.key || ""}
                                                invalid={
                                                    validation.touched.key && validation.errors.key ? true : false
                                                }
                                            />
                                            {validation.touched.key && validation.errors.key ? (
                                                <FormFeedback type="invalid">{validation.errors.key}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Col lg="12">
                                        <button
                                        style={{width : '100%'}}
                                            disabled={!isEditable}
                                                type="submit"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    let scData = [...scoreData]

                                                    scData.push({
                                                        type : '',
                                                        lowerlimit : 0,
                                                        upperlimit : 0
                                                    })
                                                  setScoreData(scData)

                                                  }}
                                            >
                                                Add Scoring
                                            </button>
                                            </Col>
                                            </div>

                                        {scoreData.map((score, index) =>(
  <Card key={index}>
  <CardBody className="border-bottom">
      <div className=" align-items-center">
                    <Col>

                    <div className='buttonwrap-right mt-3' style={{position : 'absolute',right : 20, top : -5}}>
                                                                     <div className=''>
                                                                         <button className='delete-Button' 
                                                                         onClick={()=>{
                                                                            let scData = [...scoreData]
                                                                            scData.splice(index,1)
                                                                            setScoreData(scData)
                                                                         }}
                                                                         >
                                                                             <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 17 17" fill="none">
                                                                                 <path d="M2.125 4.25H3.54167H14.875" stroke="#C03221" strokeLinecap="round" strokeLinejoin="round" />
                                                                                 <path d="M13.4583 4.25002V14.1667C13.4583 14.5424 13.309 14.9027 13.0434 15.1684C12.7777 15.4341 12.4174 15.5834 12.0416 15.5834H4.95829C4.58257 15.5834 4.22223 15.4341 3.95656 15.1684C3.69088 14.9027 3.54163 14.5424 3.54163 14.1667V4.25002M5.66663 4.25002V2.83335C5.66663 2.45763 5.81588 2.0973 6.08156 1.83162C6.34724 1.56594 6.70757 1.41669 7.08329 1.41669H9.91663C10.2924 1.41669 10.6527 1.56594 10.9184 1.83162C11.184 2.0973 11.3333 2.45763 11.3333 2.83335V4.25002" stroke="#C03221" strokeLinecap="round" strokeLinejoin="round" />
                                                                                 <path d="M7.08337 7.79169V12.0417" stroke="#C03221" strokeLinecap="round" strokeLinejoin="round" />
                                                                                 <path d="M9.91663 7.79169V12.0417" stroke="#C03221" strokeLinecap="round" strokeLinejoin="round" />
                                                                             </svg>
                                                                         </button>
                                                                     </div>
                                                                   
                                                                 </div>
                    
      <div className="mb-3" style={{width : '100%'}}>
              <Label className="form-label required">Decision Type</Label>
              <Select
    value={{label : score.type, value : score.type}}
    // onChange={this.handleChange}
    name={'type'}
    isDisabled={!isEditable}
    isClearable
    isSearchable
    options={decisionTypeOptions}
    onChange={(e)=>{
        let scData = [...scoreData]
        let scTypeOptions = [...decisionTypeOptions]

       
      if(e){
          
        scData[index].type = e.label
        scTypeOptions[scTypeOptions.findIndex(object => {
            return object.label ===  e.label;
          })].isDisabled = true
    
      }else{
        scData[index].type = ''
        scTypeOptions[scTypeOptions.findIndex(object => {
            return object.label ===  e.label;
          })].isDisabled = false
      }
      setScoreData(scData)
      setDecisionTypeOptions(scTypeOptions)
  }}
    
    onBlur={validation.handleBlur}
//     value={
//       score.type || ""
//   }
 
  />
          </div>  
          </Col>

      <Row>
          <Col lg="6">
            <div className="mb-3">
              <Label className="form-label required">Lower Limit</Label>
              <Input
              // disabled
                  name="key"
                  type="number"
                  placeholder="Enter Lower Limit"
                  validate={{
                      required: { value: true },
                  }}
                  // onChange={validation.handleChange}
                  // onBlur={validation.handleBlur}
                  onChange={(e)=>{
                    let scData = [...scoreData]
                    scData[index].lowerlimit = e.target.value
                    setScoreData(scData)
                  }}
                  value={score.lowerlimit || ""}
                //   invalid={
                //       validation.touched.key && validation.errors.key ? true : false
                //   }
              />
              {/* {validation.touched.key && validation.errors.key ? (
                  <FormFeedback type="invalid">{validation.errors.key}</FormFeedback>
              ) : null} */}
          </div>
          </Col>

          <Col lg="6">
            <div className="mb-3">
              <Label className="form-label required">Upper Limit</Label>
              <Input
              // disabled
                  name="key"
                  type="number"
                  placeholder="Enter Upper Limit"
                  validate={{
                      required: { value: true },
                  }}
                  onChange={(e)=>{
                    let scData = [...scoreData]
                    scData[index].upperlimit = e.target.value
                    setScoreData(scData)
                  }}
                  value={score.upperlimit || ""}
                  invalid={
                      validation.touched.key && validation.errors.key ? true : false
                  }
              />
              {validation.touched.key && validation.errors.key ? (
                  <FormFeedback type="invalid">{validation.errors.key}</FormFeedback>
              ) : null}
          </div>
          </Col>
          </Row>

          </div>
          </CardBody>
          </Card>
                                        ))}
                                          


                                        <div className="mb-3">
                                            <Label className="form-label required">Is Active</Label>
                                            <div className="square-switch"    style={{marginTop : 10}}>

                            <input
                                // disabled
                               type="checkbox"
                               id="square-switch1"
                               name="status"
                               className="switch switch-bool"
                                                
                               onKeyDown={handleKeyDown}
                               onChange={()=>{
                                // console.log('clicked')
                                 if(validation.values.status == 'Active'){
                                    validation.setFieldValue('status', 'Archived')
                                 }else{
                                    validation.setFieldValue('status', 'Active')
             
                                 }
                               }}
                               onBlur={validation.handleBlur}
                              //  value={validationCorporate.values.isadmin || ""}
                              checked={validation.values.status == 'Archived' ? false : true}
                            />
                            
                            <label
                                disabled={!isEditable}
                              htmlFor="square-switch1"
                              data-on-label="Yes"
                              data-off-label="No"
                              onClick={()=>{
                                // console.log('clicked')
                                 if(validation.values.status == 'Active'){
                                    validation.setFieldValue('status', 'Archived')
                                 }else{
                                    validation.setFieldValue('status', 'Active')
             
                                 }
                               }}
                            />
                          </div>
                                        </div>
                                      
                                    
                                    </Col>
                                </Row>
                                <Row style={{justifyContent : 'end'}}> 
                                <Col lg="6" style={{display : (module != null && module != '' && module.FLD_APPROVAL_STATUS == 'Pending') ? '' : 'none'}}>
                                        <div className="text-end">
                                            <button
                                            disabled={!isEditable}
                                                type="submit"
                                                className="btn btn-outline-primary save-user"
                                                onClick={() => {
                                                    setModule(module);
                                                    setApproveModal(true);
                                                  }}
                                            >
                                                Approve Score
                                            </button>
                                        </div>
                                    </Col>
                                    <Col lg="5">
                                        <div className="text-end">
                                            <button
                                            disabled={!isEditable}
                                                type="submit"
                                                className="btn btn-primary save-user"
                                                onClick={() => {
                                                    validation.handleSubmit();
                                                  }}
                                            >
                                                Save Score
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                        </div>
                        </Card>
                    </div>
                    </OffcanvasBody>
                    </Offcanvas>
                    </div>
             
                
            </div>

        <Loader isLoading={isLoading} /> 
        </React.Fragment>
    );
}


export default DecisionTreeScoreList;
import { sessionStorage } from "./dataConfig";

// tokenService.js
export const TokenService = {
    getToken() {
      return   JSON.parse(localStorage.getItem(sessionStorage)); // assuming you store the token in localStorage
    },
  
    getTokenExpirationTime(token) {
      if (!token) return null;
      const userSession = JSON.parse(localStorage.getItem(sessionStorage));
      const tokenExpiration = new Date(
        userSession.session.accessToken.payload.exp * 1000 // Convert expiration time to milliseconds
      );
      return tokenExpiration // JWT exp is in seconds, convert to milliseconds
    },
  
    isTokenExpired() {
      const token = this.getToken();
      if (!token) return true;
  
      const expirationTime = this.getTokenExpirationTime(token);
      return Date.now() > expirationTime;
    },
  
    clearToken() {
      localStorage.removeItem(sessionStorage); // Clear token from storage
    },
  };
  
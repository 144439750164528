import React, {useState, useCallback, useRef, useEffect} from 'react';
import { FormControl, InputGroup, Tab, Nav, ModalTitle } from 'react-bootstrap';
// import '@fortawesome/fontawesome-free'; // Include Font Awesome CSS
import Form from 'react-bootstrap/Form';
import Draggable from '../../../../assets/images/drag.png';
import {
  Button,
   ButtonGroup,
   CardTitle, 
   FormFeedback,
   Input,
   Modal,
   ModalBody,
   ModalHeader,
   Row,
   Col
  //  Accordion, 
  //  Form

} from "reactstrap";
import { useDrop } from "react-dnd";
import TabComponent from './TabComponent';
import TabComponent2 from './TabComponent2';
import TabComponentPreview2 from './TabComponentPreview2';
import TabComponentPreview from './TabComponentPreview';


function Middlesection(props) {

  const editRefs = useRef([]);

  const [showPreviewModal, setShowPreviewModal] = useState(false)

  const addInputField = (event) => {
    // Your addInputField function logic here
  };

  const showPreviousQuestion = () => {
    // Your showPreviousQuestion function logic here
  };

  const showNextQuestion = () => {
    // Your showNextQuestion function logic here
  };


  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const onClosePreviewModalClick = () =>{
    setShowPreviewModal(!showPreviewModal)
  }


  const renderCard = useCallback((qData, indx) => {
    return (
      <TabComponent 
      key={indx}
      qData={qData}
      questionData={props.questionData}
                 selectedQuestion={props.selectedQuestion} 
                 tabData={props.tabData}
                 selectedTab={props.selectedTab}
                 addNewTabs={props.addNewTabs}
                 onShowNextTab={props.onShowNextTab}
                 onShowPrevioustab={props.onShowPrevioustab}
                 onActivateSelectedTab={props.onActivateSelectedTab}
                 questionTypeOptions={props.questionTypeOptions}
                 renderFieldViewTab={props.renderFieldViewTab}
                 removeQuestionFromTab={props.removeQuestionFromTab}
                 onChangeTabQuestionType={props.onChangeTabQuestionType}
                 configError={props.configError}
                 fieldTypeOptions={props.fieldTypeOptions}
                 index={indx}
                 moveCard={props.moveCard}
      />
    )
  }, [])


  useEffect(() => {
    // Attach event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Detach event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [props.selectedTab, props.tabData]); // Empty dependency array ensures the effect runs only once


  const handleClickOutside = (event) => {
       
    // console.log(props.selectedTab)
    // if (editRef.current && !editRef.current.contains(event.target)) {
      // console.log(props.tabData)
      // props.OnEditTabName(false,props.tabData.indexOf(props.selectedTab))
  //  setSelectQuestionTypeVisible(false)
    // boxRef.current = null;
    // }
    editRefs.current.forEach((editRef, index) => {
    
      if(props.selectedTab.tabno == index+1){
      if (editRef && editRef.current && !editRef.current.contains(event.target)) {
        // Clicked outside the referenced element
        // props.onActivateSelectedTab(props.tabData[index])
        props.OnEditTabName(false, index);
        // editRef.current = null;
        // props.onActivateSelectedTab(props.tabData[index])
      }
    }
    });
  };


  const previewDecisionTree = () =>{
    return(
    <Modal size="md" isOpen={showPreviewModal} toggle={onClosePreviewModalClick} centered={true}>
    <div className="modal-content">
      <ModalHeader>
        <Row>
          <Col lg='2'>
        <div className="avatar-sm mx-auto">
          <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
            <i className="mdi mdi-eye-outline"></i>
          </div>
        </div>
        </Col>
        <Col lg='8' style={{margin : 'auto', marginLeft : 0}}>
        <ModalTitle> Decision Tree Preview</ModalTitle>
        </Col>
      
        </Row>

        <button type="button" onClick={onClosePreviewModalClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
     
      </ModalHeader>
      <ModalBody className="text-center model-body" style={{padding: '22px 30px'}}>
       

      <Tab.Container defaultActiveKey="1">
            <Nav className="tabGroup " style={{marginTop : 5}}>
              {props.tabData.map((tab, index)=>{

                 // Ensure the ref array is populated
        if (!editRefs.current[index]) {
          editRefs.current[index] = React.createRef();
        }
        return(
                <Nav.Item className='nav-item-dt' key={index}>
                <Nav.Link eventKey="1" className='nav-link-dt'>
                  <div className="flex-centre01">
                    <div className="flex-centre02" onClick={()=>{
      
                      props.onActivateSelectedTab(tab)
                    }}>
                      <div className="number-tab" style={{backgroundColor : props.tabData.indexOf(props.selectedTab) == index ? '#369bff' : '#ADB5BD'}}>{index+1}</div>
                      <div className="tabtext" ref={editRefs.current[index]}>
                        {/* {console.log(tab.tabnameedit)} */}
                     {tab.tabnameedit ?
                     <input className='tab-edit-input'
                     type="text"
                     value = {tab.tabname}
                     onChange={(e)=>{
                      props.OnTabNameChange(e.target.value, index)
                     }}
                    //  onKeyDown={(e)=>{
                    //   console.log(e)
                    //  }}
                     />
                     : <span className="form-name"  style={{color : props.tabData.indexOf(props.selectedTab) == index ? '#369bff' : '#ADB5BD'}}>{tab.tabname == '' ? `Tab ${index+1}` : tab.tabname}</span>}
                       {/* <span className="edit-icon"
                        onClick={()=>{
                          // console.log(editRefs)
                          props.OnEditTabName(true,index)
                        }}
                        >
                          <i className="mdi mdi-lead-pencil" 
                          style={{marginLeft : 10, fontSize : 15}}
                          ></i>
                        </span> */}
                      </div>
                    </div>
                    <div className="top01" style={{display : props.tabData.length == index+1 ? 'none' :''}}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M1 13L7 7L1 1"
                          stroke="#ADB5BD"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
        )
})}
              
           

              {/* <div className='addbutton' onClick={props.addNewTabs}>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                  <path d="M9.5 3.75V14.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4.25 9H14.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> */}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="1">

                <div className="draggableWrapper">
              
                  {props.selectedTab.questionData && props.selectedTab.questionData.map((qData, indx)=>(
                 qData.subType == '' ?
                 <TabComponentPreview 
      key={indx}
      qData={qData}
      questionData={props.questionData}
                 selectedQuestion={props.selectedQuestion} 
                 tabData={props.tabData}
                 selectedTab={props.selectedTab}
                 addNewTabs={props.addNewTabs}
                 onShowNextTab={props.onShowNextTab}
                 onShowPrevioustab={props.onShowPrevioustab}
                 onActivateSelectedTab={props.onActivateSelectedTab}
                 questionTypeOptions={props.questionTypeOptions}
                 renderFieldViewTab={props.renderFieldViewTab}
                 removeQuestionFromTab={props.removeQuestionFromTab}
                 onChangeTabQuestionType={props.onChangeTabQuestionType}
                 configError={props.configError}
                 fieldTypeOptions={props.fieldTypeOptions}
                 index={indx}
                 moveCard={props.moveCard}
      />
               
               
             : 
             <TabComponentPreview2
             key={indx}
             qData={qData}
             questionData={props.questionData}
                        selectedQuestion={props.selectedQuestion} 
                        tabData={props.tabData}
                        selectedTab={props.selectedTab}
                        addNewTabs={props.addNewTabs}
                        onShowNextTab={props.onShowNextTab}
                        onShowPrevioustab={props.onShowPrevioustab}
                        onActivateSelectedTab={props.onActivateSelectedTab}
                        questionTypeOptions={props.questionTypeOptions}
                        renderFieldViewTab={props.renderFieldViewTab}
                        removeQuestionFromTab={props.removeQuestionFromTab}
                        onChangeTabQuestionType={props.onChangeTabQuestionType}
                        configError={props.configError}
                        fieldTypeOptions={props.fieldTypeOptions}
                        index={indx}
                        moveCard={props.moveCard}

             />
                    ))}
                </div>

              </Tab.Pane>
              {/* <Tab.Pane eventKey="2">Tab 2 Content</Tab.Pane> */}
            </Tab.Content>
          </Tab.Container>
        {/* <p className="text-muted font-size-16 mb-4">{deleteMsg}</p> */}

        <div className="card-navigator" style={{ display: 'flex' }}>
        <div className={props.tabData.indexOf(props.selectedTab) > 0 ? "previous active" : "previous disabled" } id="previousButton" onClick={props.onShowPrevioustab}>
            <span style={{ marginRight: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M15.5 28C22.4036 28 28 22.4036 28 15.5C28 8.59644 22.4036 3 15.5 3C8.59644 3 3 8.59644 3 15.5C3 22.4036 8.59644 28 15.5 28Z" stroke={props.tabData.indexOf(props.selectedTab) > 0 ? '#369bFF' : "#ADB5BD"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15 10.3333L10 15.5L15 20.6667" stroke={props.tabData.indexOf(props.selectedTab) > 0 ? '#369bFF' : "#ADB5BD"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M20 15.5H10" stroke={props.tabData.indexOf(props.selectedTab) > 0 ? '#369bFF' : "#ADB5BD"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg></span>
            <span className='correctsubmit'>Previous</span>
          </div>
         
          <div className={props.tabData.indexOf(props.selectedTab) < props.tabData.length-1 ? "next active" : "next disabled" } onClick={props.onShowNextTab}>
            <span style={{ marginRight: '10px' }} className='correctsubmit' id="submitButton">Next</span>
            <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M15 28C21.9036 28 27.5 22.4036 27.5 15.5C27.5 8.59644 21.9036 3 15 3C8.09644 3 2.5 8.59644 2.5 15.5C2.5 22.4036 8.09644 28 15 28Z" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15 20.5L20 15.5L15 10.5" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10 15.5H20" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg></span>
          </div>
        </div>

        <div className="hstack gap-2 justify-content-end mb-0">
          <button type="button" className="btn btn-secondary" onClick={onClosePreviewModalClick}>Close</button>
        </div>
      </ModalBody>
    </div>
  </Modal>
    )
  }

  return (
    <main className="content" style={{marginTop : 20}}>
      {previewDecisionTree()}
      <div className="content-container">
        <div className='col-12 row p-0 m-0'>
          <div className='headheading col-6'>
            <div className='mr-2'>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                <g clipPath="url(#clip0_33_213)">
                  <path d="M10.5 1.75L1.75 6.125L10.5 10.5L19.25 6.125L10.5 1.75Z" stroke="#232D42" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M1.75 14.875L10.5 19.25L19.25 14.875" stroke="#232D42" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M1.75 10.5L10.5 14.875L19.25 10.5" stroke="#232D42" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_33_213">
                    <rect width="21" height="21" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className='headingDecision-txt'>
              Customise Decision Tree
            </div>
          </div>
          <div className='col-6' style={{textAlign : 'end'}}>
          <Button color="secondary" outline className='margin-right-top-dt'
          onClick={()=>{
            if(props.tabData.length > 0){
            props.onActivateSelectedTab(props.tabData[0])
            setShowPreviewModal(!showPreviewModal)
            }else{
              props.setConfigError('Please add tabs to preview.')
            }
          
          }}
          >Preview</Button>

{/* <Button color="secondary" outline className='margin-right-top-dt'>Cancel</Button> */}

<Button color="primary" className="btn btn-danger waves-effect waves-light"
onClick={()=>{
  props.resetTabData()
}}
>Reset</Button>

            {/* <button>Preview</button>
            <button>Cancel</button>
            <button className='savebutton'>Save</button> */}
          </div>
        </div>
     
        <div className='col-12 row p-0 m-0'>
        <FormFeedback type="invalid" style={{textAlign : 'center',
      marginTop : 20, fontSize : 12}}>{props.configError}</FormFeedback>
                                 
                        
          <Tab.Container defaultActiveKey="1">
            <Nav className="tabGroup my-4 ">
              {props.tabData.map((tab, index)=>{

                 // Ensure the ref array is populated
        if (!editRefs.current[index]) {
          editRefs.current[index] = React.createRef();
        }
        return(
                <Nav.Item className='nav-item-dt' key={index}>
                <Nav.Link eventKey="1" className='nav-link-dt'>
                  <div className="flex-centre01">
                    <div className="flex-centre02" onClick={()=>{
      
                      props.onActivateSelectedTab(tab)
                    }}>
                      <div className="number-tab" style={{backgroundColor : props.tabData.indexOf(props.selectedTab) == index ? '#369bff' : '#ADB5BD'}}>{index+1}</div>
                      <div className="tabtext" ref={editRefs.current[index]}>
                        {/* {console.log(tab.tabnameedit)} */}
                     {tab.tabnameedit ?
                     <input className='tab-edit-input'
                     type="text"
                     value = {tab.tabname}
                     onChange={(e)=>{
                      props.OnTabNameChange(e.target.value, index)
                     }}
                    //  onKeyDown={(e)=>{
                    //   console.log(e)
                    //  }}
                     />
                     : <span className="form-name"  style={{color : props.tabData.indexOf(props.selectedTab) == index ? '#369bff' : '#ADB5BD'}}>{tab.tabname == '' ? `Tab ${index+1}` : tab.tabname}</span>}
                       <span className="edit-icon"
                        onClick={()=>{
                          // console.log(editRefs)
                          props.OnEditTabName(true,index)
                        }}
                        >
                          <i className="mdi mdi-lead-pencil" 
                          style={{marginLeft : 10, fontSize : 15}}
                          ></i>
                        </span>
                        <span className="remove-icon"
                        onClick={()=>{
                          // console.log(editRefs)
                          props.OnRemoveTab(true,index)
                        }}
                        >
                          <i className="mdi mdi-close-thick" 
                          style={{marginLeft : 5, fontSize : 15}}
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div className="top01">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M1 13L7 7L1 1"
                          stroke="#ADB5BD"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
        )
})}
              
           

              {/* <Nav.Item  className='nav-item-dt'>
                <Nav.Link eventKey="2"  className='nav-link-dt'>
                  <div className="flex-centre01 active">
                    <div className="flex-centre02">
                      <div className="number-tab">2</div>
                      <div className="tabtext">
                        <span className="form-name">Tab 2</span>
                        <span className="edit-icon">
                          <i className="fa-solid fa-pencil"></i>
                        </span>
                      </div>
                    </div>
                    <div className="top01">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M1 13L7 7L1 1"
                          stroke="#ADB5BD"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item> */}
              <div className='addbutton' onClick={props.addNewTabs}>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                  <path d="M9.5 3.75V14.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4.25 9H14.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="1">

                <div className="draggableWrapper">
              
                {/* {props.selectedTab.questionData && props.selectedTab.questionData.map((qData, indx)=>(
                 qData.subType === 'field' ? 
                   <div className="openQuestion-main-section draggable" draggable="true" key={indx}
                   >
                    <div className="questionCard edit-module">
                      <div className='imagesCorrec'>
                        <img src={Draggable} alt="Draggable" />
                      </div>
                      <div className='card-inner-head'>
                        <div className='heading0023'>
                          <div className='heading-cards'>
                            <div className='icons_heading-cards'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19 19" fill="none">
                                <path d="M3.16669 5.54169V3.16669H15.8334V5.54169" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M7.125 15.8333H11.875" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.5 3.16669V15.8334" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                            <div className='customSelect'>
                            <Form.Select aria-label="Default select example" className='questSec-select'
                              value={qData.type}
                              onChange={(e)=>{
                                props.onChangeTabQuestionType(e.target.value, indx)
                              }}
                              >
                               {props.fieldTypeOptions.map((choice, i)=>(
                                 <option value={choice.value} key={choice.value}>{choice.label}</option>
                               ))}
              
                              </Form.Select>
                            </div>

                          </div>
                          <div className='' onClick={()=>{props.removeQuestionFromTab(qData, indx)}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 21" fill="none">
                              <path d="M2.5 5.5H4.16667H17.5" stroke="#F16A1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M15.8333 5.50002V17.1667C15.8333 17.6087 15.6577 18.0326 15.3451 18.3452C15.0326 18.6578 14.6087 18.8334 14.1666 18.8334H5.83329C5.39127 18.8334 4.96734 18.6578 4.65478 18.3452C4.34222 18.0326 4.16663 17.6087 4.16663 17.1667V5.50002M6.66663 5.50002V3.83335C6.66663 3.39133 6.84222 2.9674 7.15478 2.65484C7.46734 2.34228 7.89127 2.16669 8.33329 2.16669H11.6666C12.1087 2.16669 12.5326 2.34228 12.8451 2.65484C13.1577 2.9674 13.3333 3.39133 13.3333 3.83335V5.50002" stroke="#F16A1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M8.33337 9.66669V14.6667" stroke="#F16A1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.6666 9.66669V14.6667" stroke="#F16A1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </div>
                        </div>
                        {/* <div className='flexHead'>
                          Risk Assessment
                        </div> */}
                            {/* <div className="input-wrapper">
                        {props.renderFieldViewTab(qData)}
                        </div>
                      </div>

                    </div>
                  </div>
                  : null 
                    ))}  */}

                  {props.selectedTab.questionData && props.selectedTab.questionData.map((qData, indx)=>(
                 qData.subType == '' ?
                 <TabComponent 
      key={indx}
      qData={qData}
      questionData={props.questionData}
                 selectedQuestion={props.selectedQuestion} 
                 tabData={props.tabData}
                 selectedTab={props.selectedTab}
                 addNewTabs={props.addNewTabs}
                 onShowNextTab={props.onShowNextTab}
                 onShowPrevioustab={props.onShowPrevioustab}
                 onActivateSelectedTab={props.onActivateSelectedTab}
                 questionTypeOptions={props.questionTypeOptions}
                 renderFieldViewTab={props.renderFieldViewTab}
                 removeQuestionFromTab={props.removeQuestionFromTab}
                 onChangeTabQuestionType={props.onChangeTabQuestionType}
                 configError={props.configError}
                 fieldTypeOptions={props.fieldTypeOptions}
                 index={indx}
                 moveCard={props.moveCard}
      />
               
                  // <div className="openQuestion-main-section draggable" draggable="true" key={indx} ref={drag}>
               
                  //   <div className="questionCard edit-module">
                  //     <div className='imagesCorrec'>
                  //       <img src={Draggable} alt="Draggable" />
                  //     </div>
                  //     <div className='card-inner-head'>
                   
                  //       <div className='heading0023'>
                  //         <div className='heading-cards'>
                  //           <div className='icons_heading-cards' >
                  //             <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19 18" fill="none">
                  //               <path d="M13.8182 2.59091H16.4091L15.1136 4.31818L13.8182 2.59091ZM1.72727 0H17.2727C18.2314 0 19 0.777273 19 1.72727V5.18182C19 6.14045 18.2314 6.90909 17.2727 6.90909H12.9545V15.5455C12.9545 16.5041 12.1859 17.2727 11.2273 17.2727H1.72727C0.777273 17.2727 0 16.5041 0 15.5455V1.72727C0 0.777273 0.777273 0 1.72727 0ZM1.72727 1.72727V5.18182H11.2273V1.72727H1.72727ZM17.2727 5.18182V1.72727H12.9545V5.18182H17.2727ZM1.72727 15.5455H11.2273V6.90909H1.72727V15.5455ZM3.45455 8.63636H9.5V10.3636H3.45455V8.63636ZM3.45455 12.0909H9.5V13.8182H3.45455V12.0909Z" fill="#ADB5BD" />
                  //             </svg>
                  //           </div>
                  //           <div className='customSelect'>
                  //             <Form.Select aria-label="Default select example" className='questSec-select'
                  //             value={qData.type}
                  //             onChange={(e)=>{
                  //               props.onChangeTabQuestionType(e.target.value, indx)
                  //             }}
                  //             >
                  //              {props.questionTypeOptions.map((choice, i)=>(
                  //                <option value={choice.value} key={choice.value}>{choice.label}</option>
                  //              ))}
              
                  //             </Form.Select>
                  //           </div>

                  //         </div>
                  //         <div className='' onClick={()=>{props.removeQuestionFromTab(qData, indx)}}>
                  //           <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 21" fill="none">
                  //             <path d="M2.5 5.5H4.16667H17.5" stroke="#F16A1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  //             <path d="M15.8333 5.50002V17.1667C15.8333 17.6087 15.6577 18.0326 15.3451 18.3452C15.0326 18.6578 14.6087 18.8334 14.1666 18.8334H5.83329C5.39127 18.8334 4.96734 18.6578 4.65478 18.3452C4.34222 18.0326 4.16663 17.6087 4.16663 17.1667V5.50002M6.66663 5.50002V3.83335C6.66663 3.39133 6.84222 2.9674 7.15478 2.65484C7.46734 2.34228 7.89127 2.16669 8.33329 2.16669H11.6666C12.1087 2.16669 12.5326 2.34228 12.8451 2.65484C13.1577 2.9674 13.3333 3.39133 13.3333 3.83335V5.50002" stroke="#F16A1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  //             <path d="M8.33337 9.66669V14.6667" stroke="#F16A1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  //             <path d="M11.6666 9.66669V14.6667" stroke="#F16A1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  //           </svg>
                  //         </div>
                  //       </div>
                  //       <div className= {qData.required ?"question flexHead0043 required" : "question flexHead0043"}>
                  //       {qData.num}. {qData.questionText}
                  //       </div>

                  //       <div className="input-wrapper">
                  //       {props.renderFieldViewTab(qData)}
                  //       </div>
                  //       {/* <div className="add-choice-container">
                  //         <button className="add-choice-button" onClick={addInputField} style={{ cursor: 'pointer' }}>
                  //           <span className='choosemr-3'>Add Choice</span>
                  //           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  //             <path d="M10 4.16666V15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  //             <path d="M4.16666 10H15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  //           </svg>
                  //         </button>
                  //       </div> */}
                  //     </div>

                  //   </div>
                  
                  // </div>
             : 
             <TabComponent2 
             key={indx}
             qData={qData}
             questionData={props.questionData}
                        selectedQuestion={props.selectedQuestion} 
                        tabData={props.tabData}
                        selectedTab={props.selectedTab}
                        addNewTabs={props.addNewTabs}
                        onShowNextTab={props.onShowNextTab}
                        onShowPrevioustab={props.onShowPrevioustab}
                        onActivateSelectedTab={props.onActivateSelectedTab}
                        questionTypeOptions={props.questionTypeOptions}
                        renderFieldViewTab={props.renderFieldViewTab}
                        removeQuestionFromTab={props.removeQuestionFromTab}
                        onChangeTabQuestionType={props.onChangeTabQuestionType}
                        configError={props.configError}
                        fieldTypeOptions={props.fieldTypeOptions}
                        index={indx}
                        moveCard={props.moveCard}

             />
                    ))}
                </div>

              </Tab.Pane>
              {/* <Tab.Pane eventKey="2">Tab 2 Content</Tab.Pane> */}
            </Tab.Content>
          </Tab.Container>
        </div>

        <div className="card-navigator" style={{ display: 'flex' }}>
        <div className={props.tabData.indexOf(props.selectedTab) > 0 ? "previous active" : "previous disabled" } id="previousButton" onClick={props.onShowPrevioustab}>
            <span style={{ marginRight: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M15.5 28C22.4036 28 28 22.4036 28 15.5C28 8.59644 22.4036 3 15.5 3C8.59644 3 3 8.59644 3 15.5C3 22.4036 8.59644 28 15.5 28Z" stroke={props.tabData.indexOf(props.selectedTab) > 0 ? '#369bFF' : "#ADB5BD"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15 10.3333L10 15.5L15 20.6667" stroke={props.tabData.indexOf(props.selectedTab) > 0 ? '#369bFF' : "#ADB5BD"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M20 15.5H10" stroke={props.tabData.indexOf(props.selectedTab) > 0 ? '#369bFF' : "#ADB5BD"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg></span>
            <span className='correctsubmit'>Previous</span>
          </div>
          {/* <div className='AddSection-bottom'>
            Add Section
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 21" fill="none">
              <path d="M10 4.93937V16.606" stroke="#6C757D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4.16663 10.7727H15.8333" stroke="#6C757D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div> */}
          <div className={props.tabData.indexOf(props.selectedTab) < props.tabData.length-1 ? "next active" : "next disabled" } onClick={props.onShowNextTab}>
            <span style={{ marginRight: '10px' }} className='correctsubmit' id="submitButton">Next</span>
            <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M15 28C21.9036 28 27.5 22.4036 27.5 15.5C27.5 8.59644 21.9036 3 15 3C8.09644 3 2.5 8.59644 2.5 15.5C2.5 22.4036 8.09644 28 15 28Z" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15 20.5L20 15.5L15 10.5" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10 15.5H20" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg></span>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Middlesection;

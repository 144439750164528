export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const GOOGLE_SOCIAL_LOGIN = "GOOGLE_SOCIAL_LOGIN"
export const CHECK_SOCIAL_LOGIN = "CHECK_SOCIAL_LOGIN"

export const LOGIN_USER_PERSONAL_DETAILS  = "LOGIN_USER_PERSONAL_DETAILS"

export const GET_USER_PERSONAL_DETAILS = "GET_USER_PERSONAL_DETAILS"

export const VERIFY_USER_EMAIL_DETAILS = "VERIFY_USER_EMAIL_DETAILS"
export const SET_LOADING_INDICATOR = "SET_LOADING_INDICATOR"



import React, { useState, useEffect, forwardRef ,useImperativeHandle} from 'react';
import { Col, Container, Row, Button, Form, Label, Input, FormFeedback } from "reactstrap";
const Otpinput =forwardRef(({onChange, invalid}, ref) =>{

    const [otp, setOtp] = useState({ value: '', otp1: "", otp2: "", otp3: "", otp4: "", otp5: "",otp6: "", disable: true })
    const [numRegex, setNumRegex] = useState(/^[0-9]*$/)

  const handleChange = (value1, event) => {

    // setOtp({[value1]: event.target.value })

    setOtp((prevOtp) => ({
        ...prevOtp,
        [value1]: event.target.value
      }));

    // console.log(`${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}${otp.otp5}${otp.otp6}`)
    // onChange(otp)
  }

const resetOTPData = () =>{
    setOtp({ value: '', otp1: "", otp2: "", otp3: "", otp4: "", otp5: "",otp6: "", disable: true })
}

useImperativeHandle(ref, () => ({
    resetOTPData
  }));

  useEffect(() => {
    // onChange(otp)
    const completeOtp = `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}${otp.otp5}${otp.otp6}`;
    // console.log('Complete OTP:', completeOtp);
    onChange(completeOtp)
  }, [otp]);

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
      }
    }
    else {
    //   console.log("next");
     
        const next = elmnt.target.tabIndex;
        if (next < 6) {
          elmnt.target.form.elements[next].focus()
        }
    }

  }

    return (
   
        <div className="otpContainer">

        <Row className="flex-center1" >
                              <div className="widthCustom-80">
                                <div className="mb-3">
          <Input
          style={{backgroundImage : 'none'}}
           type="text"
           className="form-control form-control-lg text-center two-step"
           maxLength="1"
           data-value="1"
           id="digit1-input"
            name="otp1"
            autoComplete="off"
            value={otp.otp1}
            // onKeyPress={keyPressed}
            onChange={e => {
                if(numRegex.test(e.target.value)){
                handleChange("otp1", e)}}
            }
            tabIndex="1" onKeyUp={e =>   {
                if(numRegex.test(e.key) && e.key !== "Backspace"){
                    inputfocus(e)}}}
        invalid = {invalid}
          />
          </div>
          </div>
          <div className="widthCustom-80">
          <div className="mb-3">
          <Input
          style={{backgroundImage : 'none'}}
           type="text"
           className="form-control form-control-lg text-center two-step"
           maxLength="1"
           data-value="2"
           id="digit2-input"
            name="otp2"
            autoComplete="off"
            value={otp.otp2}
            onChange={e =>{
                if(numRegex.test(e.target.value)){
                     handleChange("otp2", e)}}}
            tabIndex="2" onKeyUp={e => {
                if(numRegex.test(e.key) || e.key == "Backspace"){
                    inputfocus(e)}}}
            invalid = {invalid}
          />
          </div>
          </div>
          <div className="widthCustom-80">
          <div className="mb-3">
          <Input
          style={{backgroundImage : 'none'}}
           type="text"
           className="form-control form-control-lg text-center two-step"
           maxLength="1"
           data-value="3"
           id="digit3-input"
            name="otp3"
            autoComplete="off"
            value={otp.otp3}
            onChange={e => {
                if(numRegex.test(e.target.value)){
                    handleChange("otp3", e)}
                }}
            tabIndex="3" onKeyUp={e => {
                if(numRegex.test(e.key) || e.key == "Backspace"){
                    inputfocus(e)}}}
            invalid = {invalid}
          />
          </div>
          </div>
          <div className="widthCustom-80">
          <div className="mb-3">
          <Input
          style={{backgroundImage : 'none'}}
           type="text"
           className="form-control form-control-lg text-center two-step"
           maxLength="1"
           data-value="4"
           id="digit4-input"
            name="otp4"
            autoComplete="off"
            value={otp.otp4}
            onChange={e => {
                if(numRegex.test(e.target.value)){
                    handleChange("otp4", e)}}}
            tabIndex="4"  
            onKeyUp={e =>{
                if(numRegex.test(e.key) || e.key == "Backspace"){
                    inputfocus(e)}}}
            invalid = {invalid}
          />
</div>
</div>
<div className="widthCustom-80">
<div className="mb-3">
          <Input
          style={{backgroundImage : 'none'}}
           type="text"
           className="form-control form-control-lg text-center two-step"
           maxLength="1"
           data-value="5"
           id="digit5-input"
            name="otp5"
            autoComplete="off"
            value={otp.otp5}
            onChange={e => {
                if(numRegex.test(e.target.value)){
                    handleChange("otp5", e)}}}
            tabIndex="5" onKeyUp={e =>{
                if(numRegex.test(e.key) || e.key == "Backspace"){
                    inputfocus(e)}}}
            invalid = {invalid}
          />
</div>
</div>
<div className="widthCustom-80">
<div className="mb-3">
<Input
style={{backgroundImage : 'none'}}
           type="text"
           className="form-control form-control-lg text-center two-step"
           maxLength="1"
           data-value="6"
           id="digit6-input"
            name="otp6"
            autoComplete="off"
            value={otp.otp6}
            onChange={e => {
                if(numRegex.test(e.target.value)){
                    handleChange("otp6", e)}}}
            tabIndex="6" onKeyUp={e => {
                if(numRegex.test(e.key) || e.key == "Backspace"){
                    inputfocus(e)}}}
            invalid = {invalid}
          />
          </div>
          </div>



    
</Row>
</div>

    );

})

Otpinput.displayName = 'Otpinput';

export default Otpinput;
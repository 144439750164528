import React, { useEffect, useMemo, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainerDT from '../../../components/Common/TableContainerDT';
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from 'react-select';

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getModules as onGetModules,
    addNewModule as onAddNewModule,
    updateModule as onUpdateModule,
    deleteModule as onDeleteModule,
    approveModule as onApproveModule,
    deactivateModule
} from "store/actions";

import {
    No, ColName, ApprovalStatus, UpdateOn, Status, ModuleName
}
    from "./DecisionTreeListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    FormGroup,
    Progress,
    Alert
} from "reactstrap";
import Loader from "components/Common/Loader";
import ApproveModal from "components/Common/ApproveModal";


function DecisionTreeList() {

    //meta title
    document.title = "Module List - Decision Tree | Smarteeva";

    const tableRef = useRef(null);

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEditable, setIsEditable] = useState(true);



    const [moduleList, setModuleList] = useState([]);
    const [module, setModule] = useState(null);
    const [resetFilter, setResetFilter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [toggleCanvas, setToggleCanvas] = useState(false);
    const [isRight, setIsRight] = useState(false);

    const [typeOptions, setTypeOptions] = useState([
        {label : 'Regular Branching', value : 'Regular Branching'},
        {label : 'Detailed Branching', value : 'Detailed Branching'},
        {label : 'Chat Bot', value : 'Chat Bot'},
        {label : 'Checklist', value : 'Checklist'}

    ]);
    const [typeVal, setTypeVal] = useState('');
    const [approveModal, setApproveModal] = useState(false);



    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: '',
            type : '',
            key: '',
            status: 'Active',

        },
        validationSchema: Yup.object({
             name: Yup.string().required("Please Enter Module Name"),
             type: Yup.string().required("Please Select Module Type"),
             key: Yup.string().required("Please Enter Module Key Value"),
            status: Yup.string().required("Please Select Your Status"),
        }),
        onSubmit: (values) => {

            // console.log(values)

            if(isEdit || isView){

                // console.log('in view & edit')

               values.moduleid = module.FLD_MODULEID
                // console.log(values)

                dispatch(onUpdateModule(values));

            }else{
                dispatch(onAddNewModule(values));
            }

            // 

            // if (isEdit) {
            //     const updateJobList = {
            //         id: job ? job.id : 0,
            //         jobId: values.jobId,
            //         jobTitle: values.jobTitle,
            //         companyName: values.companyName,
            //         location: values.location,
            //         experience: values.experience,
            //         position: values.position,
            //         type: values.type,
            //         postedDate: "02 June 2021",
            //         lastDate: "25 June 2021",
            //         status: values.status,
            //     };
            //     // update Job
            //     dispatch(onUpdateJobList(updateJobList));
            //     validation.resetForm();
            // } else {
            //     const newJobList = {
            //         id: Math.floor(Math.random() * (30 - 20)) + 20,
            //         jobId: values["jobId"],
            //         jobTitle: values["jobTitle"],
            //         companyName: values["companyName"],
            //         location: values["location"],
            //         experience: values["experience"],
            //         position: values["position"],
            //         type: values["type"],
            //         postedDate: "02 June 2021",
            //         lastDate: "25 June 2021",
            //         status: values["status"],
            //     };
            //     // save new Job
            //     dispatch(onAddNewJobList(newJobList));
            //     validation.resetForm();
            // }
            // toggle();
        },
    });

    const dispatch = useDispatch();

    const { modulesDT,modules_length, addModuleSuccessMsg  } = useSelector(state => ({
        modulesDT: state.DecisionTree.modulesDT,
        modules_length: state.DecisionTree.modules_length,
        addModuleSuccessMsg: state.DecisionTree.addModuleSuccessMsg,
    }));

    const { error, loading } = useSelector(state => ({
        error: state.DecisionTree.error,
        loading : state.DecisionTree.loading
      }));
    
    
      useEffect(() => {
        setIsLoading(loading)
    }, [loading]);

    useEffect(() => {


        if(modules_length == -1){
        if (modulesDT && !modulesDT.length) {
            // console.log(modulesDT)
            // setIsLoading(true)
            dispatch(onGetModules());
        }
    }
    }, [dispatch, modulesDT]);

    useEffect(() => {
        console.log(modulesDT)

        setModuleList(modulesDT);
        // setIsRight(false)
    }, [modulesDT]);


    useEffect(() => {
 
      if(addModuleSuccessMsg != ''){
       setIsRight(false)
        setTypeVal('')
        validation.resetForm()
      }
   
    }, [addModuleSuccessMsg]);

    // useEffect(() => {
    //     if (!isEmpty(companys) && !!isEdit) {
    //         setCompanyList(companys);
    //         setIsEdit(false);
    //     }
    // }, [companys]);

    // const toggle = () => {
    //     if (modal) {
    //         setModal(false);
    //         setCompany(null);
    //     } else {
    //         setModal(true);
    //     }
    // };

    // const handleJobClick = arg => {
    //     const job = arg;
    //     setJob({
    //         id: job.id,
    //         jobId: job.jobId,
    //         jobTitle: job.jobTitle,
    //         companyName: job.companyName,
    //         location: job.location,
    //         experience: job.experience,
    //         position: job.position,
    //         type: job.type,
    //         status: job.status,
    //     });

    //     setIsEdit(true);

    //     toggle();
    // };

    //delete Company
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (module) => {
        setModule(module);
        setDeleteModal(true);
    };

    // const handleDeletecompany = () => {
    //     // console.log(company)
    //     if (company && company.FLD_COMPANYID) {
    //         dispatch(onDeleteCompany(company.FLD_COMPANYID));
    //         setDeleteModal(false);
    //     }
    // };
    // const handleCompanyClicks = () => {
    //     setCompanyList("");
    //     setIsEdit(false);
    //     toggle();
    // };

    const columns = useMemo(
        () => [
            {
                Header: 'No',
                accessor: 'No',
                filterable: true,
                Cell: (cellProps) => {
                    return <No {...cellProps} />;
                }
            },
           
            {
                Header: 'Module Name',
                accessor: 'FLD_NAME',
                filterable: true,
                Cell: (cellProps) => {
                    return <Link 
                    to={'/decision-tree-config'} state={{ state: {...cellProps.row.original, isEditable : false} }}
                    >
                        <ModuleName {...cellProps} />
                        </Link>;
                }
            },
            {
                Header: 'Module Type',
                accessor: 'FLD_TYPE',
                filterable: true,
                Cell: (cellProps) => {
                    return <ColName {...cellProps} />;
                }
            },
            {
                Header: 'Key Value',
                accessor: 'FLD_KEY',
                filterable: true,
                Cell: (cellProps) => {
                    return <ColName {...cellProps} />;
                }
            },
            
           
            {
                Header: 'Approval Status',
                accessor: 'FLD_APPROVAL_STATUS',
                filterable: true,
                Cell: (cellProps) => {
                    return <ApprovalStatus {...cellProps} />;
                }
            },
            {
                Header: 'Date Updated On',
                accessor: 'FLD_LASTUPDATEDON',
                filterable: true,
                Cell: (cellProps) => {
                    return <UpdateOn {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'FLD_STATUS',
                filterable: true,
                // disableFilters: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Owner',
                accessor: 'FLD_OWNER',
                filterable: true,
                // disableFilters: true,
                Cell: (cellProps) => {
                    return <ColName {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link 
                                // to={'/alter-corporate-profile'} state={{ state: {...cellProps.row.original, isEditable : false} }}
                                onClick={()=>{
                                    setIsEdit(false)
                                    setIsView(true)

                                        setIsEditable(false)

                                        let data = cellProps.row.original

                                        validation.setFieldValue('name', data.FLD_NAME)
                                        validation.setFieldValue('type', data.FLD_TYPE)
                                        validation.setFieldValue('key', data.FLD_KEY)
                                        validation.setFieldValue('status', data.FLD_STATUS == 'Active' ? 'Active' : 'Archived') 
                                        setTypeVal({label : data.FLD_TYPE, value : data.FLD_TYPE})
                                        setModule(data)

                                        setIsRight(true)
                                }}
                                className="btn btn-sm btn-soft-primary">
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                View
                            </UncontrolledTooltip>

                            <li>
                                <Link
                                    // to="#"
                                    // to={'/alter-corporate-profile'} state={{ state: {...cellProps.row.original, isEditable : true} }}
                                    // to={{ pathname: '/alter-corporate-profile', state: { data : cellProps.row.original } }}
                                    onClick={()=>{
                                        setIsEdit(true)
                                        setIsView(false)
                                        setIsEditable(true)

                                        // console.log(cellProps.row.original)
                                        let data = cellProps.row.original

                                        validation.setFieldValue('name', data.FLD_NAME)
                                        validation.setFieldValue('type', data.FLD_TYPE)
                                        validation.setFieldValue('key', data.FLD_KEY)
                                        validation.setFieldValue('status', data.FLD_STATUS == 'Active' ? 'Active' : 'Archived') 
                                        setTypeVal({label : data.FLD_TYPE, value : data.FLD_TYPE})
                                        setModule(data)

                                        setIsRight(true)
                                    }}
                                    className="btn btn-sm btn-soft-info"
                                   
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const moduleData = cellProps.row.original;
                                        onClickDelete(moduleData);
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

      // Reset filters
  const handleReset = () => {
    tableRef.current.resetTable(); 
    // console.log(tableRef.current)
  };

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const handleAddNewModule = () =>{
    setIsEdit(false)
    setIsEditable(true)
    setIsView(false)


    validation.setFieldValue('name', '')
    validation.setFieldValue('type', '')
    validation.setFieldValue('key', '')
    validation.setFieldValue('status', 'Active') 
    setTypeVal('')
    setModule('')

    setIsRight(!isRight);
  }


const handleKeyDown = (e) => {
    // console.log(e.key)
    if (e.key.match(/[a-z]/i) && e.key!= 'Backspace') {
      e.preventDefault();
    }
  };


  const handleDeleteModule = () => {
    // console.log(company)
    if (module && module.FLD_MODULEID) {
        dispatch(deactivateModule(module));
        setDeleteModal(false);
    }
};

const handleApproveModule = () => {
    // console.log(companyDetails)
    if (module && module.FLD_MODULEID) {
        dispatch(onApproveModule(module.FLD_MODULEID));
        setApproveModal(false);
    }
  };
  

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                deleteMsg={'Are you sure you want to archive module.'}
                onDeleteClick={handleDeleteModule}
                onCloseClick={() => setDeleteModal(false)}
            />

                <ApproveModal
                show={approveModal}
                approveMsg={'Are you sure you want to approve module.'}
                onApproveClick={handleApproveModule}
                onCloseClick={() => setApproveModal(false)}
            />

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Smart Config" breadcrumbItem="Decision Tree Builder" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">List Of Decision Tree</h5>
          
                                                    <div className="flex-shrink-0">

                                                        
                                            <Link className="btn btn-primary me-3" 
                                            onClick={handleAddNewModule}
                                            // to={{pathname : '/decision-tree-config'}}
                                            ><i className="mdi mdi-file-tree"

                                            ></i> Add New Decision Tree </Link>
                                            </div>
                                        <div className="flex-shrink-0">

             
                                            <Link className="btn btn-light me-3" onClick={handleReset}><i className="mdi mdi-refresh"
                                            
                                            ></i> Reset Filters </Link>
                                            {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                <DropdownToggle type="menu" className="btn btn-light" id="dropdownMenuButton1">
                                                    <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                                <DropdownMenu>
                                                    <li><DropdownItem href="#">Action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                </DropdownMenu>
                                            </UncontrolledDropdown> */}
                                        </div>

                                      
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <TableContainerDT
                                        ref={tableRef}
                                        columns={columns}
                                        data={modulesDT}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        // isAddModule={true}
                                        // handleCompanyClicks={handleCompanyClicks}
                                        // resetFilter={resetFilter}
                                        isCompanyListGlobalFilter={true}
                                        customPageSize={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    
                    <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}>
            <OffcanvasHeader toggle={toggleRightCanvas}>
            {isEdit ? 'Alter Module' : isView ? 'View Module' : 'Add New Module'}  
            </OffcanvasHeader>
            <OffcanvasBody style={{marginBottom : 20}}>
              <div className="w-100 h-80vh" >
                <Card className="h-96">
                  <div className="p-4 border-bottom ">
                                <Row>

{/* {console.log(error)} */}
                                {error ? <Alert color="danger">{error}</Alert> : null}
                                    <Col className="col-12">
                                       
                                           
                                        <div className="mb-3">
                                        <Row>
                                        <Col lg="10">
                                            <Label className="form-label required"> Module Name</Label>
                                            </Col>
                                            <Col lg="2" style={{marginTop : -10, display : isEditable ? 'none' : ''}}>
                                   
                                   <Link
                                       onClick={()=>{
                                           
                                           setIsEditable(true)
                                         
                                       }}
                                       className="btn btn-sm btn-soft-info"
                                      
                                   >
                                       <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                       <UncontrolledTooltip placement="top" target="edittooltip">
                                           Edit
                                       </UncontrolledTooltip>
                                   </Link>
                            
                                           </Col>
                                            </Row>
                                            <Input
                                                disabled={!isEditable}
                                                name="name"
                                                type="text"
                                                placeholder="Enter Module Name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.name || ""}
                                                invalid={
                                                    validation.touched.name && validation.errors.name ? true : false
                                                }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                            ) : null}
                                        </div>
                                      
                                     
                                     
                                       
                                        <div className="mb-3">
                                            <Label className="form-label required">Module Type</Label>
                                            <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'type'}
                                  isDisabled={!isEditable}
                                  isClearable
                                  isSearchable
                                  options={typeOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setTypeVal(e)
                                    validation.setFieldValue('type', e.value)
                                    validation.setFieldError('type',false)
                                    }else{
                                        setTypeVal('')
                                      validation.setFieldValue('type', '')
                                      validation.setFieldError('type',true)
                                    }
                                }}
                                  
                                  onBlur={validation.handleBlur}
                                  value={
                                    typeVal || ""
                                }
                                invalid={
                                  validation.touched.type && validation.errors.type
                                        ? true
                                        : false
                                }
                                className={validation.touched.type && validation.errors.type ? 'invalid-select' : ''}

                                />
                                  {validation.touched.type && validation.errors.type ? (
                                    <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                  ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label required">Key Value</Label>
                                            <Input
                                            disabled={!isEditable}
                                                name="key"
                                                type="text"
                                                placeholder="Enter Key"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.key || ""}
                                                invalid={
                                                    validation.touched.key && validation.errors.key ? true : false
                                                }
                                            />
                                            {validation.touched.key && validation.errors.key ? (
                                                <FormFeedback type="invalid">{validation.errors.key}</FormFeedback>
                                            ) : null}
                                        </div>


                                        <div className="mb-3">
                                            <Label className="form-label required">Is Active</Label>
                                            <div className="square-switch"    style={{marginTop : 10}}>

                            <input
                                // disabled
                               type="checkbox"
                               id="square-switch1"
                               name="status"
                               className="switch switch-bool"
                                                
                               onKeyDown={handleKeyDown}
                               onChange={()=>{
                                // console.log('clicked')
                                 if(validation.values.status == 'Active'){
                                    validation.setFieldValue('status', 'Archived')
                                 }else{
                                    validation.setFieldValue('status', 'Active')
             
                                 }
                               }}
                               onBlur={validation.handleBlur}
                              //  value={validationCorporate.values.isadmin || ""}
                              checked={validation.values.status == 'Archived' ? false : true}
                            />
                            
                            <label
                                disabled={!isEditable}
                              htmlFor="square-switch1"
                              data-on-label="Yes"
                              data-off-label="No"
                              onClick={()=>{
                                // console.log('clicked')
                                 if(validation.values.status == 'Active'){
                                    validation.setFieldValue('status', 'Archived')
                                 }else{
                                    validation.setFieldValue('status', 'Active')
             
                                 }
                               }}
                            />
                          </div>
                                        </div>
                                      
                                    
                                    </Col>
                                </Row>
                                <Row style={{justifyContent : 'end'}}> 
                                <Col lg="6" style={{display : (module != null && module != '' && module.FLD_APPROVAL_STATUS == 'Pending') ? '' : 'none'}}>
                                        <div className="text-end">
                                            <button
                                            disabled={!isEditable}
                                                type="submit"
                                                className="btn btn-outline-primary save-user"
                                                onClick={() => {
                                                    setModule(module);
                                                    setApproveModal(true);
                                                  }}
                                            >
                                                Approve Module
                                            </button>
                                        </div>
                                    </Col>
                                    <Col lg="5">
                                        <div className="text-end">
                                            <button
                                            disabled={!isEditable}
                                                type="submit"
                                                className="btn btn-primary save-user"
                                                onClick={() => {
                                                    validation.handleSubmit();
                                                  }}
                                            >
                                                Save Module
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                        </div>
                        </Card>
                    </div>
                    </OffcanvasBody>
                    </Offcanvas>
                    </div>
             
                
            </div>

        <Loader isLoading={isLoading} /> 
        </React.Fragment>
    );
}


export default DecisionTreeList;
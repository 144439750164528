import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, GET_CITY_PROFILE, GET_COUNTRY_PROFILE, GET_DEPARTMENT_PROFILE, GET_DESIGNATION_PROFILE, GET_PHONECODE_PROFILE, GET_PROFILE, GET_STATE_PROFILE, UPDATE_PROFILE } from "./actionTypes"
import { profileSuccess, profileError, getProfileSuccess, getProfileFail, getPhonecodeProfileSuccess, getPhonecodeProfileFail, getCountryProfileSuccess, getCountryProfileFail, getStateProfileSuccess, getStateProfileFail, getCityProfileSuccess, getCityProfileFail, getDepartmentProfileSuccess, getDepartmentProfileFail, getDesignationProfileSuccess, getDesignationProfileFail, updateProfileFail, updateProfileSuccess, setLoadingIndicatorProfile } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeProfile,
  postJwtProfile,
} from "../../../helpers/fakebackend_helper"
import { getCityList, getCountryList, getDepartmentList, getDesignationList, getPhonecodeList, getStateList, getUserProfile, updateUserProfile } from "helpers/backend_helper"

const fireBaseBackend = getFirebaseBackend()

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.editProfileAPI,
        user.username,
        user.idx
      )
      yield put(profileSuccess(response))      
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeProfile, {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    }
  } catch (error) {
    yield put(profileError(error))
  }
}


function* fetchUserProfile({payload : user}) {

  yield put(setLoadingIndicatorProfile(true));

  const user_dt = {
    userid : user
  }
  try {
    const response = yield call(getUserProfile,user_dt)
    // console.log(response)
    yield put(getProfileSuccess(response.data))
  } catch (error) {
    yield put(getProfileFail(error))
  }
}


function* updateProfile({payload : user}) {

  yield put(setLoadingIndicatorProfile(true));

  // console.log(user)

  try {
    const response = yield call(updateUserProfile,user)
    console.log(response)
    yield put(updateProfileSuccess(response.data))
    yield call(fetchUserProfile(user.userid))
  } catch (error) {
    yield put(updateProfileFail(error))
  }
}


function* fetchPhonecodeProfile() {

  // console.log('in fetch phone code list')
  try {
      const response = yield call(getPhonecodeList)
      // console.log(response)
      yield put(getPhonecodeProfileSuccess(response.data))
  } catch (error) {
      // console.log(error)
      yield put(getPhonecodeProfileFail(error))
  }
}


function* fetchCountryProfile() {

  yield put(setLoadingIndicatorProfile(true));

  // console.log('in fetch country list')
  try {
      const response = yield call(getCountryList)
      // console.log(response)
      yield put(getCountryProfileSuccess(response.data))
  } catch (error) {
      // console.log(error)
      yield put(getCountryProfileFail(error))
  }
}


function* fetchStateProfile({ payload: data }) {
  yield put(setLoadingIndicatorProfile(true));

  // console.log('in fetch phone code list')
  // console.log(data)
  const contData = {
      countryid : data
  }
  try {
      const response = yield call(getStateList, contData)
      // console.log(response)
      yield put(getStateProfileSuccess(response.data))
  } catch (error) {
      // console.log(error)
      yield put(getStateProfileFail(error))
  }
}




function* fetchCityProfile({ payload: data }) {
  // yield put(setLoadingIndicatorProfile(true));

  // console.log('in fetch city list')
  const cityData = {
      stateid : data
  }
  try {
      const response = yield call(getCityList, cityData)
      // console.log(response)
      yield put(getCityProfileSuccess(response.data))
  } catch (error) {
      // console.log(error)
      yield put(getCityProfileFail(error))
  }
}


function* fetchDepartmentProfile() {
  // yield put(setLoadingIndicatorProfile(true));

  try {
      const response = yield call(getDepartmentList)
      // console.log(response)
      yield put(getDepartmentProfileSuccess(response.data))
  } catch (error) {
      // console.log(error)
      yield put(getDepartmentProfileFail(error))
  }
}


function* fetchDesignationProfile() {
  // yield put(setLoadingIndicatorProfile(true));

  try {
      const response = yield call(getDesignationList)
      // console.log(response)
      yield put(getDesignationProfileSuccess(response.data))
  } catch (error) {
      // console.log(error)
      yield put(getDesignationProfileFail(error))
  }
}



export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(GET_PROFILE, fetchUserProfile)
  yield takeEvery(UPDATE_PROFILE, updateProfile)
  yield takeEvery(GET_PHONECODE_PROFILE, fetchPhonecodeProfile)
  yield takeEvery(GET_COUNTRY_PROFILE, fetchCountryProfile)
  yield takeEvery(GET_STATE_PROFILE, fetchStateProfile)
  yield takeEvery(GET_CITY_PROFILE, fetchCityProfile)
  yield takeEvery(GET_DEPARTMENT_PROFILE, fetchDepartmentProfile)
  yield takeEvery(GET_DESIGNATION_PROFILE, fetchDesignationProfile)

}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga

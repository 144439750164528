import React, { useEffect, useState } from 'react';
import { Col, Row, Card, CardBody, CardTitle, Alert } from 'reactstrap';
import classnames from "classnames";
import Select from 'react-select';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from 'moment';

const QueryBuilder = (props) => {
  const [groups, setGroups] = useState([{ id: 1, rules: [{ id: Date.now(), field: '', condition: 'equals', value: '' }], condition: '', grpcondition: '' }]);
  const [logic, setLogic] = useState('');
  const [displayLogic, setDisplayLogic] = useState('');
  const [selectedField, setSelectedField] = useState('');
  const [seperableLogic, setSeperableLogic] = useState('');

  const [dateLogic, setDateLogic] = useState('');
  const [dateLogic2, setDateLogic2] = useState('');

  useEffect(() => {
    // console.log(props.groupEditData)
    if(props.groupEditData != '' && props.groupEditData != undefined){
      // console.log(props.groupEditData)
      setGroups(JSON.parse(props.groupEditData.groupdata))
      setLogic(props.groupEditData.query_string)
      setDisplayLogic(props.groupEditData.display_query_string)
    }else{
      setGroups([{ id: 1, rules: [{ id: Date.now(), field: '', condition: 'equals', value: '' }], condition: '', grpcondition: '' }])
      setLogic('')
      setDisplayLogic('')
    }
  }, [props.groupEditData]);

  const addRule = (groupId) => {
    const newGroups = [...groups];
    const group = newGroups.find((g) => g.id === groupId);
    group.rules.push({ id: Date.now(), field: '', condition: 'equals', value: '', grpcondition: ' and' });
    setGroups(newGroups);
    // console.log(newGroups)
    updateLogic(newGroups);
  };

  const addGroup = () => {
    const newGroups = [...groups];
    newGroups.push({ id: Date.now(), rules: [{ id: Date.now(), field: '', condition: 'equals', value: '', grpcondition: '' }], condition: ' and' });
    setGroups(newGroups);
  };

  const deleteRule = (groupId, ruleId) => {
    const newGroups = [...groups];
    const group = newGroups.find((g) => g.id === groupId);
    group.rules = group.rules.filter((rule) => rule.id !== ruleId);
    setGroups(newGroups);
    updateLogic(newGroups);
  };

  const deleteGroup = (groupId) => {
    const newGroups = groups.filter((group) => group.id !== groupId);
    setGroups(newGroups);
    updateLogic(newGroups);
  };

  const updateField = (groupId, ruleId, field) => {
    const newGroups = [...groups];
    const group = newGroups.find((g) => g.id === groupId);
    const rule = group.rules.find((r) => r.id === ruleId);
    rule.field = field;
    setGroups(newGroups);
    updateLogic(newGroups);
  };

  const updateCondition = (groupId, ruleId, condition) => {
    const newGroups = [...groups];
    const group = newGroups.find((g) => g.id === groupId);
    const rule = group.rules.find((r) => r.id === ruleId);
    rule.condition = condition;
    setGroups(newGroups);
    updateLogic(newGroups);
  };

  const updateRuleConditionGrp = (groupId, ruleId, condition) => {
    const newGroups = [...groups];
    const group = newGroups.find((g) => g.id === groupId);
    const rule = group.rules.find((r) => r.id === ruleId);
    rule.grpcondition = condition;
    // console.log(rule)
    setGroups(newGroups);
    updateLogic(newGroups);
  };

  const updateValue = (groupId, ruleId, value) => {
    const newGroups = [...groups];
    const group = newGroups.find((g) => g.id === groupId);
    const rule = group.rules.find((r) => r.id === ruleId);
    rule.value = value;

    console.log(rule)
    setGroups(newGroups);
    updateLogic(newGroups);
  };

  const updateGroupCondition = (groupId, condition) => {
    const newGroups = [...groups];
    const group = newGroups.find((g) => g.id === groupId);
    group.condition = condition;
    setGroups(newGroups);
    updateLogic(newGroups);
  };

  const updateLogic = (groups) => {
    let logic = '';
    let displayLogic = '';
    let seperableLogic = '';
    groups.forEach((group, groupIndex) => {
      if (groupIndex !== 0) {
        logic += ` ${group.condition != undefined ? group.condition.toUpperCase() : ''} `;
        displayLogic += ` ${group.condition != undefined ? group.condition.toUpperCase() : ''} `;
        seperableLogic += ` ${group.condition != undefined ? group.condition.toUpperCase() : ''} `;

      }
      if (group.rules.length === 1 && group.rules[0].type === 'group') {
        logic += `(${updateLogic(group.rules[0].rules)})`;
        displayLogic += `(${updateLogic(group.rules[0].rules)})`;
        seperableLogic += `(${updateLogic(group.rules[0].rules)})`;


      } else {
        logic += '(';
        displayLogic += '(';
        seperableLogic += '(';

        group.rules.forEach((rule, ruleIndex) => {
          if (ruleIndex !== 0) {
            // logic += ` ${group.condition != undefined ? group.condition.toUpperCase() : ''} `;
            // displayLogic += `${group.condition != undefined ? group.condition.toUpperCase() : ''} `;
            // seperableLogic += `${group.condition != undefined ? group.condition.toUpperCase() : ''} `;

          }
          if (rule.type === 'group') {
            logic += `(${updateLogic(rule.rules)})`;
            displayLogic += `(${updateLogic(rule.rules)})`;
            seperableLogic += `(${updateLogic(rule.rules)})`;


          } else {
            const { field, condition, value, grpcondition } = rule;
            let valData = ''
            if (condition.label == 'begins with' || condition.label == "doesn't begin with") {
              valData = field.type == 'Text' ? "'" + value.toUpperCase() + "%'" :  field.value == 'MEDICAL_SPECIALTY' ? "'" + value.value + "'"  :  value.value.toUpperCase() + '%'
            } else if (condition.label == 'contains' || condition.label == "doesn't contain") {
              valData = field.type == 'Text' ? "'%" + value.toUpperCase() + "%'" : '%' +   field.value == 'MEDICAL_SPECIALTY' ? "'" + value.value + "'"  : value.value.toUpperCase() + '%'
            } else if (condition.label == 'ends with' || condition.label == "doesn't end with") {
              valData = field.type == 'Text' ? "'%" + value.toUpperCase() + "'" : '%' +  field.value == 'MEDICAL_SPECIALTY' ? "'" + value.value + "'"  : value.value.toUpperCase()
            } else {
              valData = (field.type == 'Text' || field.type == 'Number' || field.type == 'Date') ? "'" + value.toUpperCase() + "'" :  field.value == 'MEDICAL_SPECIALTY' ? "'" + value.value + "'"  : value.value.toUpperCase()
            }

            if(condition.label == 'between'){
              // console.log('TO_DATE('+"'"+value.split(' ')[0]+"' , 'MM/DD/YYYY') " +value.split(' ')[1]+' TO_DATE('+"'"+value.split(' ')[2]+"' , 'MM/DD/YYYY')")
              valData = 'TO_DATE('+"'"+value.split(' ')[0]+"' , 'MM/DD/YYYY') " +value.split(' ')[1]+' TO_DATE('+"'"+value.split(' ')[2]+"' , 'MM/DD/YYYY')"
            }
            // console.log(field)

            if(field.value == 'DATE_RECEIVED' && condition.label != 'between'){
              valData = 'TO_DATE('+"'"+value+"' , 'MM/DD/YYYY') "
            }

            logic += `${grpcondition != undefined ? grpcondition.toUpperCase() : ''} `;
            displayLogic += `${grpcondition != undefined ? grpcondition.toUpperCase() : ''} `;
            seperableLogic += `${grpcondition != undefined ? ''+grpcondition.toUpperCase() : ''} `;


            // logic += field.value != undefined ? `${grpcondition != undefined ? grpcondition.toUpperCase() : ''} ${field.value} ${condition.value != undefined ? condition.value.toUpperCase() : ''} ${valData}` : '';
            // displayLogic += field.value != undefined ? `${grpcondition != undefined ? grpcondition.toUpperCase() : ''} ${field.label} ${condition.value != undefined ? condition.label.toUpperCase() : ''} ${(field.type == 'Text' || field.type == 'Number' || field.type == 'Date') ? "'" + value + "'" : value.value}` : '';
            logic += field.value != undefined ? ` ${field.value} ${condition.value != undefined ? condition.value.toUpperCase() : ''} ${valData}` : '';
            displayLogic += field.value != undefined ? ` ${field.label} ${condition.value != undefined ? condition.label.toUpperCase() : ''} ${(field.type == 'Text' || field.type == 'Number' || field.type == 'Date') ? "'" + value + "'" : "'" + value.label + "'" }` : '';
            seperableLogic += field.value != undefined ? `[ /${field.label}/ ${condition.value != undefined ? '<'+condition.label.toUpperCase()+'>' : ''} ${(field.type == 'Text' || field.type == 'Number' || field.type == 'Date') ? "'$" + value + "$'" : '$'+value.value+'$'}]` : '';

          }
        });
        logic += ' )';
        displayLogic += ' )';
        seperableLogic += ' )';

      }
    });
    setLogic(logic);
    setDisplayLogic(displayLogic);
    setSeperableLogic(seperableLogic)
    props.handleLogic(logic)
    props.handleDisplayLogic(displayLogic)
    props.handleQueryGroup(groups)
  };

  const renderRuleCard = (groupId, rule) => (
    <Row key={rule.id} className="rule-card">
      <Col md="3" xs="12" className='mb-3 adjust-col12'>

        <Select
          // value={count}
          // onChange={this.handleChange}
          value={rule.field}

          onChange={(e, triggeredAction) => {
            // console.log(triggeredAction)
            if (triggeredAction.action == 'clear') {
              updateField(groupId, rule.id, '')
              updateValue(groupId, rule.id, '')
            } else {
              updateField(groupId, rule.id, e)
            }
          }
          }
          isClearable
          isSearchable
          name={'field'}
          options={props.searchableFieldList}
          placeholder="Select Field"

        // formatOptionLabel={({ value, label }) => `(${value}) ${label}`}


        />

        {/* <select
          defaultValue="0"
          className="form-select"
          value={rule.field}
          onChange={(e) => updateField(groupId, rule.id, e.target.value)}
        >
          <option value="0">Select Field</option>
          <option value="Field One">Field One</option>
          <option value="Field Two">Field Two</option>
          <option value="Field Three">Field Three</option>
        </select> */}
      </Col>
      <Col md="3" xs="12" className='mb-3 adjust-col12'>

        <Select
          // value={count}
          // onChange={this.handleChange}
          isClearable
          isSearchable
          name={'Condition'}
          placeholder="Condition"
          options={rule.field.type == 'Text' ? props.conditionList : rule.field.type == 'Number' ? props.conditionList2 : props.conditionList3}
          value={rule.condition}
          onChange={(e, triggeredAction) => {
            // console.log(triggeredAction)
            if (triggeredAction.action == 'clear') {
              updateCondition(groupId, rule.id, '')
            } else {
              updateCondition(groupId, rule.id, e)
            }
          }}
        // formatOptionLabel={({ value, label }) => `(${value}) ${label}`}


        />
        {/* <select
          className="form-select"
          value={rule.condition}
          onChange={(e) => updateCondition(groupId, rule.id, e.target.value)}
        >
          <option>Condition</option>
          <option value="equals">Equals</option>
          <option value="notEquals">Not Equals</option>
          <option value="contains">Contains</option>
          <option value="notContains">Not Contains</option>
        </select> */}
      </Col>
      <Col md="4" xs="12" className='mb-3 adjust-col12'>
        {rule.field == '' ? <input
          type="text"
          className="form-control"
          id="autoSizingInput"
          value={rule.value}
          onChange={(e) => updateValue(groupId, rule.id, e.target.value)}
          placeholder="Value"
        /> : (rule.field.type == 'Text' || rule.field.type == 'Number') ? <input
          type="text"
          className="form-control"
          id="autoSizingInput"
          value={rule.value}
          onChange={(e) => updateValue(groupId, rule.id, e.target.value)}
          placeholder="Value"
        /> : rule.field.type == 'Date' ?
          rule.condition.label == 'between' ?
            <Row>
              <Col lg={6}>
                <Flatpickr
                  // value={Date.parse(pick_date)}
                  className="form-control d-block"
                  placeholder="Pick a date"
                  options={{
                    altInput: true,
                    dateFormat: "d-m-y"
                  }}
                  onChange={(e) => {
                    if (dateLogic2 == '') {
                      updateValue(groupId, rule.id, moment(new Date(e)).format('MM/DD/YYYY'))
                    } else {
                      updateValue(groupId, rule.id, moment(new Date(e)).format('MM/DD/YYYY') + ' AND ' + dateLogic2)
                    }
                    setDateLogic(moment(new Date(e)).format('MM/DD/YYYY'))
                  }}
                />
              </Col>
              <Col lg={6}>
                <Flatpickr
                  // value={Date.parse(pick_date)}
                  className="form-control d-block"
                  placeholder="Pick a date"
                  options={{
                    altInput: true,
                    dateFormat: "d-m-y"
                  }}
                  onChange={(e) => {
                    updateValue(groupId, rule.id, dateLogic + ' AND ' + moment(new Date(e)).format('MM/DD/YYYY'))
                    setDateLogic2(moment(new Date(e)).format('MM/DD/YYYY'))
                  }}
                />
              </Col>
            </Row>
            :
            <Flatpickr
              // value={Date.parse(pick_date)}
              className="form-control d-block"
              placeholder="Pick a date"
              options={{
                altInput: true,
                dateFormat: "d-m-y"
              }}
              onChange={(e) => updateValue(groupId, rule.id, moment(new Date(e)).format('MM/DD/YYYY'))
              }
            />
          : <Select
            value={rule.value}
            onChange={(e) => updateValue(groupId, rule.id, e)}
            isClearable
            isSearchable
            name={'value'}
            placeholder="Value"
            options={rule.field.value == 'MEDICAL_SPECIALTY' ? props.medicalSplList : rule.field.value == 'DEVICE_PROBLEM_CODE' ? props.deviceProblemCodeList : props.patientProblemCodeList}
          />}
      </Col>
      <Col md="2" xs="12" className='mb-3 adjust-col12'>
        <button
          className="btn btn-outline-danger"
          onClick={() => deleteRule(groupId, rule.id)}
        >
          Delete
        </button>
      </Col>
    </Row>
  );

  const renderGroupCard = (group) => (
    <Card key={group.id} className="group-card">
      <CardBody>
        <Row className="group-actions">
          {/* {group.rules.length > 1 ?
          <Col md="2" xs="6" className='border-end mb-4'>
            <select
              className="form-select condition-select"
              value={group.condition}
              onChange={(e) => updateGroupCondition(group.id, e.target.value)}
            >
              <option value="and">AND</option>
              <option value="or">OR</option>
            </select>
          </Col>
           :null} */}

          <Col md="12" xs="12" className='pr-0'>
            <form>{group.rules.map((rule, ind) => (
              <Row key={ind}>
                {ind > 0 ? <Col md="2" xs="6" className='border-end mb-4'>
                  <select
                    className="form-select condition-select"
                    value={rule.grpcondition}
                    onChange={(e) => updateRuleConditionGrp(group.id, rule.id, e.target.value)}
                  >
                    <option value="and">AND</option>
                    <option value="or">OR</option>
                  </select>
                </Col> : null}
                <Col md="10" xs="6" className='adjust-col12'>
                  {renderRuleCard(group.id, rule)}
                </Col>
              </Row>
            )
            )}
            </form>
          </Col>
        </Row>
      </CardBody>

      <div className="px-4 py-3 border-top">
        <a type="button" className="me-4 w-md condition-button" onClick={() => addRule(group.id)}>
          <h6 className='m-0'><i className="bx bx-plus font-21 align-middle me-1"></i>{" "} Add Condition</h6>
        </a>
        <a type="button" className="me-4 w-md" onClick={() => addGroup()}>
          <h6 className='m-0'><i className="bx bx-plus font-21 align-middle me-1 condition-button"></i>{" "} Add Condition Group</h6>
        </a>
        <a type="button" className="delete-group w-md text-danger right-float-responsive deleteButcorr" onClick={() => deleteGroup(group.id)}>
          <h6 className='m-0'><i className="bx bx-minus font-21 align-middle me-1 condition-button"></i>{" "} Delete Condition Group</h6>
        </a>
      </div>


    </Card>
  );

  return (
    <div>

      {groups.map((group, index) => (
        <Col key={index}>
          {index > 0 ? <Col md="2" xs="6" className='border-end mb-4'>
            <select
              className="form-select condition-select"
              value={group.condition}
              onChange={(e) => updateGroupCondition(group.id, e.target.value)}
            >
              <option value="and">AND</option>
              <option value="or">OR</option>
            </select>
          </Col> : null}
          {renderGroupCard(group)}
        </Col>
      )
      )}
      <Card className='logic-card'>
        <CardBody>
          <CardTitle className="mb-2 logic-label">Query Logic </CardTitle>
          <p className="card-title-desc">
            By simplifying the process of constructing complex search queries, a search query logic builder empowers users to perform advanced searches and obtain more precise and tailored search results, saving time and effort in information retrieval.
          </p>

          <div >
            <Alert color="info" className="mb-0 py-3 heightcorr">
              <h4 className="alert-heading mb-3 textcorr">Generated Logic</h4>
              {displayLogic}
              {/* {logic} */}
              {/* {seperableLogic} */}
            </Alert>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default QueryBuilder;

import React, {useEffect, useState} from "react"
import {
  Col,
  Row,
  Container,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from 'react-router-dom';
//import component
import CardUser from "./CardUser"
import Settings from "./Settings"

// import TopCities1 from "../Dashboard Public/TopCities 1";
// import TopCities2 from "../Dashboard Public/TopCities 2";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import TableContainer from '../../components/Common/TableContainer';
import { authUser } from "helpers/dataConfig";


const index = () => {
  //meta title
  document.title = "Corporate Dashboard | Smarteeva";


  const [userData, setUserData] = useState("");

  useEffect(() => {
    if (localStorage.getItem(authUser)) {

        const obj = JSON.parse(localStorage.getItem(authUser));
        setUserData(obj);
        // console.log(obj)
      // }
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Corporate Dashboard" />
          <Row>
          <Settings userData={userData} />
            {/* card user */}
            <CardUser dataColors='["--bs-primary", "--bs-warning"]' />
          </Row>
          {/* <Row>
            <Col lg={6}>
            <TopCities1 />
            </Col>
            <Col lg={6}>
            <TopCities2 />
            </Col>
          </Row> */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">List of MDRs Submitted</h5>
                    <div className="flex-shrink-0">
                      <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-3"><i className="mdi mdi-plus"></i> Add a New MDR</Link>
                      <UncontrolledDropdown className="dropdown d-inline-block me-1">
                        <DropdownToggle type="menu" className="btn btn-light" id="dropdownMenuButton1">
                          <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                        <DropdownMenu>
                          <li><DropdownItem href="#">Action</DropdownItem></li>
                          <li><DropdownItem href="#">Another action</DropdownItem></li>
                          <li><DropdownItem href="#">Something else here</DropdownItem></li>
                        </DropdownMenu> 
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </CardBody>
                
                <CardBody>
                  <div className="d-flex flex-center py-5">
                    <h2 className='py-5 my-5'>MDR Table Placeholder</h2>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}



export default index

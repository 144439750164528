import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_CHARTS_DATA, GET_MDR_STATS_DATA, GET_MDR_STATS_DATA_YEAR, GET_MDR_SUBMIT_DATA, GET_USER_CHARTS_DATA } from "./actionTypes";
import { apiSuccess, apiFail, setLoadingIndicatorDashboard } from "./actions";

//Include Both Helper File with needed methods
import {
    // getWeeklyData,
    // getYearlyData,
    // getMonthlyData,
    getUserCountDashboard,
    getMDRSubmitDashboard,
    getMDRStatsDashboard,
    getMDRStatsDashboardYear
}
    from "../../helpers/backend_helper";

function* getChartsData({ payload: periodType }) {
    // try {
    //     var response;
    //     if (periodType == "monthly") {
    //         response = yield call(getWeeklyData, periodType);
    //     }
    //     if (periodType == "yearly") {
    //         response = yield call(getYearlyData, periodType);
    //     }
    //     if (periodType == "weekly") {
    //         response = yield call(getMonthlyData, periodType);
    //     }

    //     yield put(apiSuccess(GET_CHARTS_DATA, response));
    // } catch (error) {
    //     yield put(apiFail(GET_CHARTS_DATA, error));
    // }
}


function* getUserChartsData() {

    // yield put(setLoadingIndicatorDashboard(true));
    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getUserCountDashboard);

            // console.log(response.data)
          yield put(apiSuccess(GET_USER_CHARTS_DATA, response.data));
    } catch (error) {
        yield put(apiFail(GET_USER_CHARTS_DATA, error));
    }
}

function* getMDRSubmitData() {
    // yield put(setLoadingIndicatorDashboard(true));

    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getMDRSubmitDashboard);

            // console.log(response.data)
          yield put(apiSuccess(GET_MDR_SUBMIT_DATA, response.data));
    } catch (error) {
        yield put(apiFail(GET_MDR_SUBMIT_DATA, error));
    }
}

function* getMDRStatsData() {
    // yield put(setLoadingIndicatorDashboard(true));

    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getMDRStatsDashboard);

            // console.log(response.data)
          yield put(apiSuccess(GET_MDR_STATS_DATA, response.data.reverse()));
    } catch (error) {
        yield put(apiFail(GET_MDR_STATS_DATA, error));
    }
}

function* getMDRStatsDataYear() {
    yield put(setLoadingIndicatorDashboard(true));

    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getMDRStatsDashboardYear);

            // console.log(response.data)
          yield put(apiSuccess(GET_MDR_STATS_DATA_YEAR, response.data.reverse()));
    } catch (error) {
        yield put(apiFail(GET_MDR_STATS_DATA_YEAR, error));
    }
}

export function* watchGetChartsData() {
    yield takeEvery(GET_CHARTS_DATA, getChartsData);
    yield takeEvery(GET_USER_CHARTS_DATA, getUserChartsData);
    yield takeEvery(GET_MDR_SUBMIT_DATA, getMDRSubmitData);
    yield takeEvery(GET_MDR_STATS_DATA, getMDRStatsData);
    yield takeEvery(GET_MDR_STATS_DATA_YEAR, getMDRStatsDataYear);


}

function* dashboardSaga() {
    yield all([fork(watchGetChartsData)]);
}

export default dashboardSaga;

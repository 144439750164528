import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"

const ConfirmMail = () => {

  //meta title
  document.title = "Confirm Mail | Smarteeva";

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">

        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={6} xl={10} className="height-center">
              <Card>
                <CardBody>
                  <div className="p-5">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-success">
                          <i className="bx bx-mail-send h1 mb-0 text-light"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h2 className="head-error">Success !</h2>
                        <p className="subhead-error pt-3">
                          A email has been send to your email@domain.com. Please check for an email from company and click on the included link to reset your password.
                        </p>
                        <div className="mt-4">
                          <Link to="/dashboard" className="btn btn-success py-3 px-5">
                            Back to Home
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConfirmMail

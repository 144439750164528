import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  Badge,
  UncontrolledTooltip,
  FormFeedback,
Alert
} from "reactstrap"

import classnames from "classnames"
import { Link , useNavigate} from "react-router-dom"
import Select from 'react-select';
import Switch from "react-switch";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  onChangePassword,
} from "store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import Loader from "components/Common/Loader";

const ChangePassword = () => {

  //meta title
  document.title = "Change Password | Smarteeva";

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false);


  const { resetSuccessMsg, loading} = useSelector(state => ({
    resetSuccessMsg: state.ResetPassword.resetSuccessMsg,
    loading : state.ResetPassword.loading
  }));

  useEffect(() => {
    // console.log(loading)
    setIsLoading(loading)
}, [loading]);

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldpassword : '',
      password: '',
      confirmpassword : ''

    },
    validationSchema: Yup.object({
      oldpassword : Yup.string().required("Please Enter Old Password"),
      password: Yup.string().required("Please Enter New Password"),
      confirmpassword: Yup.string().required("Please Re-enter New Password").oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: (values) => {
      dispatch(onChangePassword(values, navigate));
    },
});

useEffect(() => {
  if(resetSuccessMsg && resetSuccessMsg){
    validation.resetForm()
  }
}, [resetSuccessMsg]);

const handleKeyDown = (e) => {
  // console.log(e.key)
  if (e.key.match(/[a-z]/i) && e.key!= 'Backspace') {
    e.preventDefault();
  }
};




  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Manage Profile" breadcrumbItem="Change Password" />

          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Change Password</CardTitle>
                  {/* <p className="text-muted mb-5">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultricies vel enim in malesuada. Nulla porta tempor placerat. Aenean vitae hendrerit lacus, vitae sollicitudin ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam tristique et mauris nec iaculis.
                  </p> */}

{resetSuccessMsg && resetSuccessMsg ? (
                        <Alert color="success">
                          Your password has been updated successfully.
                        </Alert>
                      ) : null}

                  <div className="horizontal-wizard wizard clearfix horizontal">
                    
                    <div className="content clearfix">
                     
                          <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                            <Row>
                             
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                     Old Password
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Old Password"
                                    name="oldpassword"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.oldpassword || ""}
                                    invalid={
                                      validation.touched.oldpassword && validation.errors.oldpassword ? true : false
                                    }
                                  />
                                  {validation.touched.oldpassword && validation.errors.oldpassword ? (
                                    <FormFeedback type="invalid">{validation.errors.oldpassword}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                            <Col lg="12" >
                            <FormGroup className="mb-3">
                                  <Label className="required">
                                     New Password
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter New Password"
                                    name="password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.password || ""}
                                    invalid={
                                      validation.touched.password && validation.errors.password ? true : false
                                    }
                                  />
                                  {validation.touched.password && validation.errors.password ? (
                                    <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label  className="required">
                                     Confirm Password
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Re-enter New Password"
                                    name="confirmpassword"
                                    // onKeyDown={handleKeyDown}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.confirmpassword || ""}
                                    invalid={
                                      validation.touched.confirmpassword && validation.errors.confirmpassword ? true : false
                                    }
                                  />
                                  {validation.touched.confirmpassword && validation.errors.confirmpassword ? (
                                    <FormFeedback type="invalid">{validation.errors.confirmpassword}</FormFeedback>
                                  ) : null}
                                  
                                </FormGroup>
                              </Col>
                            
                            </Row>
                         
                          </Form>
                      
                    </div>
                    <div className="actions clearfix">
                      <ul>
                    
                      
                        <li
                        style={{float  : 'left'}}
             
                        >
                        
                          <Link
                            to="#"
                            onClick={() => {
                              validation.handleSubmit();
                            }}
                          >
                           Submit
                          </Link>
                        </li>

                    
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Loader isLoading={isLoading} /> 
    </React.Fragment>
  )
}

export default ChangePassword

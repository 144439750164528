import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {  GET_MDR_EVENT_COUNT_PUBLIC, GET_MDR_MEDICAL_SPEC_COUNT_PUBLIC, GET_MDR_MONTHLY_EVENT_COUNT_PUBLIC, GET_MDR_MONTH_COUNT_PUBLIC, GET_MDR_STATS_DATA_PUBLIC, GET_MDR_STATS_DATA_YEAR_PUBLIC, GET_MDR_TOP_DEVICES_PUBLIC, GET_MDR_TOP_PATIENTS_PUBLIC, GET_MDR_YEARLY_COUNT_PUBLIC, GET_MDR_YEAR_DATA_PUBLIC} from "./actionTypes";
import { apiSuccessPublic, apiFailPublic, setLoadingIndicatorDashboardPublic, getMDRStatsDataPublic, getMDRStatsDataYearPublic } from "./actions";

//Include Both Helper File with needed methods
import {
 
    getMDRStatsDashboard,
    getMDRStatsDashboardYear,
    getMDRTopPatientsPublicData,
    getMDRTopDevicesPublicData,
    getMDRYearDataStats,
    getMDRMonthDataStats,
    getMDREventDataStats,
    getMDRYearlyDataStats,
    getMDRMedicalSpecDataStats,
    getMDRMonthlyEventDataStats
}
    from "../../helpers/backend_helper";


function* getMDRStatsDataPublicData() {
    // yield put(setLoadingIndicatorDashboard(true));

    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getMDRStatsDashboard);

            // console.log(response.data)
          yield put(apiSuccessPublic(GET_MDR_STATS_DATA_PUBLIC, response.data.reverse()));
    } catch (error) {
        yield put(apiFailPublic(GET_MDR_STATS_DATA_PUBLIC, error));
    }
}

function* getMDRStatsDataYearPublicData() {
    yield put(setLoadingIndicatorDashboardPublic(true));

    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getMDRStatsDashboardYear);

            // console.log(response.data)
          yield put(apiSuccessPublic(GET_MDR_STATS_DATA_YEAR_PUBLIC, response.data.reverse()));
    } catch (error) {
        yield put(apiFailPublic(GET_MDR_STATS_DATA_YEAR_PUBLIC, error));
    }
}


function* getMDRTopDevicesData() {
    // yield put(setLoadingIndicatorDashboardPublic(true));

    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getMDRTopDevicesPublicData);

            // console.log(response.data)
          yield put(apiSuccessPublic(GET_MDR_TOP_DEVICES_PUBLIC, response.data));
    } catch (error) {
        yield put(apiFailPublic(GET_MDR_TOP_DEVICES_PUBLIC, error));
    }
}

function* getMDRTopPatientsData() {
    // yield put(setLoadingIndicatorDashboardPublic(true));

    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getMDRTopPatientsPublicData);

            // console.log(response.data)
          yield put(apiSuccessPublic(GET_MDR_TOP_PATIENTS_PUBLIC, response.data));
    } catch (error) {
        yield put(apiFailPublic(GET_MDR_TOP_PATIENTS_PUBLIC, error));
    }
}


function* getMDRYearData() {
    // yield put(setLoadingIndicatorDashboardPublic(true));

    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getMDRYearDataStats);

            // console.log(response.data)
          yield put(apiSuccessPublic(GET_MDR_YEAR_DATA_PUBLIC, response.data));
    } catch (error) {
        yield put(apiFailPublic(GET_MDR_YEAR_DATA_PUBLIC, error));
    }
}


function* getMDRMonthCountData() {
    // yield put(setLoadingIndicatorDashboardPublic(true));

    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getMDRMonthDataStats);

            // console.log(response.data)
          yield put(apiSuccessPublic(GET_MDR_MONTH_COUNT_PUBLIC, response.data));
    } catch (error) {
        yield put(apiFailPublic(GET_MDR_MONTH_COUNT_PUBLIC, error));
    }
}

function* getMDREventCountData() {
    // yield put(setLoadingIndicatorDashboardPublic(true));

    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getMDREventDataStats);

            // console.log(response.data)
          yield put(apiSuccessPublic(GET_MDR_EVENT_COUNT_PUBLIC, response.data));
    } catch (error) {
        yield put(apiFailPublic(GET_MDR_EVENT_COUNT_PUBLIC, error));
    }
}


function* getMDRYearlyCountData() {
    // yield put(setLoadingIndicatorDashboardPublic(true));

    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getMDRYearlyDataStats);

            // console.log(response.data)
          yield put(apiSuccessPublic(GET_MDR_YEARLY_COUNT_PUBLIC, response.data));
    } catch (error) {
        yield put(apiFailPublic(GET_MDR_YEARLY_COUNT_PUBLIC, error));
    }
}

function* getMDRMedicalSpecCountData() {
    // yield put(setLoadingIndicatorDashboardPublic(true));

    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getMDRMedicalSpecDataStats);

            // console.log(response.data)
          yield put(apiSuccessPublic(GET_MDR_MEDICAL_SPEC_COUNT_PUBLIC, response.data));
    } catch (error) {
        yield put(apiFailPublic(GET_MDR_MEDICAL_SPEC_COUNT_PUBLIC, error));
    }
}


function* getMDRMonthlyEventCountData() {
    // yield put(setLoadingIndicatorDashboardPublic(true));

    // console.log('in saga')
    try {
        var response;
       
            response = yield call(getMDRMonthlyEventDataStats);

            // console.log(response.data)
          yield put(apiSuccessPublic(GET_MDR_MONTHLY_EVENT_COUNT_PUBLIC, response.data));
    } catch (error) {
        yield put(apiFailPublic(GET_MDR_MONTHLY_EVENT_COUNT_PUBLIC, error));
    }
}


export function* watchGetChartsData() {
    yield takeEvery(GET_MDR_STATS_DATA_PUBLIC, getMDRStatsDataPublicData);
    yield takeEvery(GET_MDR_STATS_DATA_YEAR_PUBLIC, getMDRStatsDataYearPublicData);
    yield takeEvery(GET_MDR_TOP_DEVICES_PUBLIC, getMDRTopDevicesData);
    yield takeEvery(GET_MDR_TOP_PATIENTS_PUBLIC, getMDRTopPatientsData);
    yield takeEvery(GET_MDR_YEAR_DATA_PUBLIC, getMDRYearData);
    yield takeEvery(GET_MDR_MONTH_COUNT_PUBLIC, getMDRMonthCountData);
    yield takeEvery(GET_MDR_EVENT_COUNT_PUBLIC, getMDREventCountData);
    yield takeEvery(GET_MDR_YEARLY_COUNT_PUBLIC, getMDRYearlyCountData);
    yield takeEvery(GET_MDR_MEDICAL_SPEC_COUNT_PUBLIC, getMDRMedicalSpecCountData);
    yield takeEvery(GET_MDR_MONTHLY_EVENT_COUNT_PUBLIC, getMDRMonthlyEventCountData);
}

function* dashboardSaga() {
    yield all([fork(watchGetChartsData)]);
}

export default dashboardSaga;

import React, {useState, useRef} from 'react';
import { FormControl, InputGroup, Tab, Nav } from 'react-bootstrap';
// import '@fortawesome/fontawesome-free'; // Include Font Awesome CSS
import Form from 'react-bootstrap/Form';
import Draggable from '../../../assets/images/drag.png';
import {
  Button,
   ButtonGroup,
   CardTitle, 
   FormFeedback,
   Label,
   Input,
   Col
  //  Accordion, 
  //  Form

} from "reactstrap";
import { useDrag, useDrop } from "react-dnd";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Link } from 'react-router-dom';
import Select from 'react-select';

const style = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
  }

function PerformDecisionTree(props) {

  const [counter, setCounter] = useState(0)




  const addInputField = (event) => {
    // Your addInputField function logic here
  };

  const showPreviousQuestion = () => {
    // Your showPreviousQuestion function logic here
  };

  const showNextQuestion = () => {
    // Your showNextQuestion function logic here
  };



  const ShortTextView = (ques) =>{
    return (
      <Col lg='12'>
      <div className=" mb-3">
      <Input
    //   disabled 
    value={ques.choices[0] && ques.choices[0].answer}
      name="key"
      type="textarea"
      rows={3}
      placeholder=""
      onChange={(e)=>{
        props.onChangeAnswers(props.tabIndex, props.quesIndex,'shorttext', e.target.value,  0)
      }}
     
  />
   </div>
   </Col>
    )
  }


  const LongTextView = (ques) =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <Input
    //   disabled
    value={ques.choices[0] && ques.choices[0].answer}
      name="key"
      type="textarea"
      rows={7}
      placeholder=""
      onChange={(e)=>{
        props.onChangeAnswers(props.tabIndex, props.quesIndex,'longtext', e.target.value, 0)
      }}
  />
   </div>
   </Col>
    )
  }


  const NumberTextView = (ques) =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <InputGroup>
                        <div
                          className="input-group-append"
                          onClick={() => {
                            if(counter > 0){
                              props.onChangeAnswers(props.tabIndex, props.quesIndex,'number',(parseInt(counter - 1)), 0) 
                            setCounter(counter - 1);
                            }
                          }}
                        >
                          <Button type="button" color="primary">
                            <i className="mdi mdi-minus" />
                          </Button>
                        </div>
                        <Input
                        style={{
                          borderRight: 0,
                          marginRight:-5,
                          borderLeft: 0,
                          marginLeft: -5,
                          background : 'transparent',
                          textAlign : 'center'
                        }}
                      
                          type="number"
                          // className="form-control"
                          // value={counter}
                          value={ques.choices[0] ? ques.choices[0].answer : 0}
                          onChange={(e)=>{
                            // if(e.target.value == ''){
                            //   setCounter(0)
                            // }
                            props.onChangeAnswers(props.tabIndex, props.quesIndex,'number',(parseInt(e.target.value)), 0) 
                               setCounter(parseInt(e.target.value))
                            // }
                          }}
                          // placeholder="number"
                          // readOnly
                          
                        />
                        <div
                          className="input-group-append"
                          onClick={() => {
                           
                              setCounter(counter + 1);
                              props.onChangeAnswers(props.tabIndex, props.quesIndex,'number',(parseInt(counter + 1)), 0) 
                          
                          }}
                        >
                          <Button type="button" color="primary">
                            <i className="mdi mdi-plus" />
                          </Button>
                        </div>
                      </InputGroup>
   </div>
   </Col>
    )
  }


  const DateTextView = (ques) =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <Flatpickr
              value={ques.choices[0] && Date.parse(ques.choices[0].answer)}
              className="form-control d-block"
              placeholder="Pick a date"
              options={{
                altInput: true,
                dateFormat: "d-m-y"
              }}
              
              onChange={(e) => {
                props.onChangeAnswers(props.tabIndex, props.quesIndex,'date',  moment(new Date(e)).format('MM/DD/YYYY'), 0)
            }
              }
            />
   </div>
   </Col>
    )
  }


  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'white' : 'white', // Change background color when selected
      color: state.isSelected ? 'black' : 'black', // Change text color when selected
    }),
  };

  const DropdownTextView =  (ques) =>{
    
    return (
      <Col lg='12'>
      <div className="mb-3">
     
 
      <Select
                                  name={''}
                                  value={ques.choices[0] && ques.choices[ques.choices.indexOf(e.label)].answer}
                                  isClearable
                                  isSearchable
                                  options={ques.choices && ques.choices.map((option, index) => ({
                                    label: option.FLD_VALUE,
                                    value:option.FLD_VALUE, // or any unique identifier for the value
                                  }))}
                                  styles={customStyles}
                                  onChange={(e) => {
                                    props.onChangeAnswersChoice(props.tabIndex, props.quesIndex,'dropdown',  e.label, ques.choices.indexOf(e.label))
                                }
                                  }
                              
                                />

   </div>
   </Col>
    )
  }


  const MultipleTextView =  (ques) =>{
    return (
      <Col lg='12'>
      <div className="mb-3 row" style={{paddingLeft : 15, paddingRight : 15}}>
     
     {ques.choices && ques.choices.map((choice, index)=>(
       <div className="form-check form-checkbox-outline form-check-primary mb-3 col-lg-6" key={index}>
       <input
               style={{color: '#232D42'}}
         type="checkbox"
         className="form-check-input"
         id="customCheck-outlinecolor1"
         checked={choice.answer == choice.FLD_VALUE ? true : false}
         // onChange={() => {
         //   setcustomOutlinePrimary(!customOutlinePrimary)
         // }}
         onChange={(e) => {
          // console.log(choice)
          if(choice.answer == choice.FLD_VALUE){
            props.onRemoveAnswers(props.tabIndex, props.quesIndex,'multiplechoice',  choice.FLD_VALUE, index)
          }else{
            props.onChangeAnswersChoice(props.tabIndex, props.quesIndex,'multiplechoice',  choice.FLD_VALUE, index)

          }
      }}
       />
       <label
       style={{color: '#232D42'}}
         className="form-check-label"
         htmlFor="customCheck-outlinecolor1"
       >
        {choice.FLD_VALUE}
       </label>
     </div>
     ))}
     

   </div>
   </Col>
    )
  }

  const YesNoTextView =  (ques) =>{

    return (
      <Col lg='12'>
      <div className="mb-3">
     
      <div className="btn-group btn-group-example" style={{width: '100%'}}
                            role="group"
                          >
                              {ques.choices && ques.choices.map((choice, index)=>(
                            <button key={index}
                              type="button"
                              className={ques.choices[index].answer== choice.FLD_VALUE ? "btn btn-primary w-lg" : "btn btn-outline-primary w-lg"}
                              onChange={(e) => {
                                props.onChangeAnswersChoice(props.tabIndex, props.quesIndex,'yes/no', index)
                            }}
                            >
                              
                              {choice.FLD_VALUE}
                            </button>
                              ))}
                           
                            {/* <button
                              type="button"
                              className="btn btn-outline-primary w-lg"
                              onChange={(e) => {
                                props.onChangeAnswersChoice(props.tabIndex, props.quesIndex,'yes/no',  'No')
                            }}
                            >
                              No
                            </button> */}
                          </div>
    
   </div>
   </Col>
    )
  }


  const FileUploadTextView = (ques) =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
        <Label>{ques.choices[0].answer}</Label>
      <Input
      // disabled
      name="key"
      type="file"
      placeholder=""
      onChange={(e) => {

        e.preventDefault();
        if (e.target.files[0].size < 5000000) {
        const reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
          // this.setState({
          //   file: file,
          //   imagePreviewUrl: reader.result,
          //   ImageData : file
          // });
          props.onChangeAnswers(props.tabIndex, props.quesIndex,'fileupload',  file, 0)
        }
        reader.readAsDataURL(file);
     } else {
            Notiflix.Notify.Failure("File too large, upload file less than 5 Mb.");
          }

       
    }}
  />
   </div>
   </Col>
    )
  }

  const GoogleDriveTextView = (ques) =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <div className="flex-shrink-0">

                                                        
<Link className="btn btn-primary me-3" 
style={{width : '100%'}}
// onClick={handleAddNewModule}
// to={{pathname : '/decision-tree-config'}}
><i className="mdi mdi-cloud-upload"

></i> Upload from Google Drive </Link>
</div>
   </div>
   </Col>
    )
  }


  const LinkTextView = (ques) =>{
    return (
      <Col lg='12'>
      <div className="mb-3">
      <Input
      // disabled
      value={ques.choices[0] && ques.choices[0].answer}
      name="key"
      type="text"
      placeholder=""
      onChange={(e) => {
        props.onChangeAnswers(props.tabIndex, props.quesIndex,'link',  e.target.value, 0)
    }}
  />
   </div>
   </Col>
    )
  }


  const renderFieldViewPreview = (ques) =>{
    switch (ques.FLD_TYPE) {
      case 'shorttext':
        return (
          <div>{ShortTextView(ques)}</div>
        );
      case 'longtext':
        return (
          <div>{LongTextView(ques)}</div>
        );
      case 'number':
        return (
          <div>{NumberTextView(ques)}</div>
        );
      case 'date':
        return (
          <div>{DateTextView(ques)}</div>
        );
      case 'dropdown':
        return (
          <div>{DropdownTextView(ques)}</div>
        );
      case 'multiplechoice':
        return (
          <div>{MultipleTextView(ques)}</div>
        );
        case 'yes/no':
          return (
            <div>{YesNoTextView(ques)}</div>
          );
          case 'fileupload':
            return (
              <div>{FileUploadTextView(ques)}</div>
            );
            case 'googledrive':
              return (
                <div>{GoogleDriveTextView(ques)}</div>
              );
              case 'link':
                return (
                  <div>{LinkTextView(ques)}</div>
                );
        default:
          return null;
      }
    
}





  return (
   
    <div className="openQuestion-main-section draggable" draggable="true"  >
               
                    <div className=" edit-module">
                    
                      <div className='card-inner-head'>
                   
                       
            
                        <div className= {props.qData.FLD_REQUIRED ?"question flexHead0043 required" : "question flexHead0043"}>
                        {props.qData.FLD_NUM}. {props.qData.FLD_QUESTIONTEXT}
                        </div>

                        <div className="input-wrapper">
                        {renderFieldViewPreview(props.qData)}
                        </div>


                        <div className="input-wrapper mb-3" style={{display : props.qData.FLD_SHOWCOMMENTS ? '' : 'none'}}>
    <Label className={props.qData.FLD_COMMENTSREQUIRED ? "form-label required" : "form-label"} style={{marginBottom :0}}>Comments</Label>
    <Input
      // disabled
      value={props.qData.comments}
      name="key"
      type="textarea"
      rows={5}
      onChange={(e) => {
        props.onChangeComments(props.tabIndex, props.quesIndex, e.target.value)
    }}
      placeholder="" />
    </div>

                        {/* <div className="add-choice-container">
                          <button className="add-choice-button" onClick={addInputField} style={{ cursor: 'pointer' }}>
                            <span className='choosemr-3'>Add Choice</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <path d="M10 4.16666V15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.16666 10H15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </button>
                        </div> */}
                      </div>

                    </div>
                  
                  </div>

  );
}

export default PerformDecisionTree;

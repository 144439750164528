/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"

/**
 * add user
 */
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

export const GET_CORPORATE_DD = "GET_CORPORATE_DD"
export const GET_CORPORATE_DD_SUCCESS = "GET_CORPORATE_DD_SUCCESS"
export const GET_CORPORATE_DD_FAIL = "GET_CORPORATE_DD_FAIL"

export const GET_PHONECODE_LIST_USER = "GET_PHONECODE_LIST_USER"
export const GET_PHONECODE_LIST_USER_SUCCESS = "GET_PHONECODE_LIST_USER_SUCCESS"
export const GET_PHONECODE_LIST_USER_FAIL = "GET_PHONECODE_LIST_USER_FAIL"

export const GET_COUNTRY_LIST_USER = "GET_COUNTRY_LIST_USER"
export const GET_COUNTRY_LIST_USER_FAIL = "GET_COUNTRY_LIST_USER_FAIL"
export const GET_COUNTRY_LIST_USER_SUCCESS = "GET_COUNTRY_LIST_USER_SUCCESS"

export const GET_STATE_LIST_USER = "GET_STATE_LIST_USER"
export const GET_STATE_LIST_USER_FAIL = "GET_STATE_LIST_USER_FAIL"
export const GET_STATE_LIST_USER_SUCCESS = "GET_STATE_LIST_USER_SUCCESS"

export const GET_CITY_LIST_USER = "GET_CITY_LIST_USER"
export const GET_CITY_LIST_USER_FAIL = "GET_CITY_LIST_USER_FAIL"
export const GET_CITY_LIST_USER_SUCCESS = "GET_CITY_LIST_USER_SUCCESS"

export const GET_COMPANY_LIST_USER = "GET_COMPANY_LIST_USER"
export const GET_COMPANY_LIST_USER_SUCCESS = "GET_COMPANY_LIST_USER_SUCCESS"
export const GET_COMPANY_LIST_USER_FAIL = "GET_COMPANY_LIST_USER_FAIL"

export const GET_ACTIVE_USER_ORG = "GET_ACTIVE_USER_ORG"
export const GET_ACTIVE_USER_ORG_SUCCESS = "GET_ACTIVE_USER_ORG_SUCCESS"
export const GET_ACTIVE_USER_ORG_FAIL = "GET_ACTIVE_USER_ORG_FAIL"


export const GET_USER_DETAILS = "GET_USER_DETAILS"
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS"
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL"

export const APPROVE_USER = "APPROVE_USER"
export const APPROVE_USER_SUCCESS = "APPROVE_USER_SUCCESS"
export const APPROVE_USER_FAIL = "APPROVE_USER_FAIL"

export const SEND_USER_INVITE = "SEND_USER_INVITE"
export const SEND_USER_INVITE_SUCCESS = "SEND_USER_INVITE_SUCCESS"
export const SEND_USER_INVITE_FAIL = "SEND_USER_INVITE_FAIL"

export const RESET_USER_DETAILS = "RESET_USER_DETAILS"

export const GET_GROUP_PERMISSION = 'GET_GROUP_PERMISSION'
export const GET_GROUP_PERMISSION_SUCCESS = 'GET_GROUP_PERMISSION_SUCCESS'
export const GET_GROUP_PERMISSION_FAIL = 'GET_GROUP_PERMISSION_FAIL'

export const UPDATE_GROUP_PERMISSION = "UPDATE_GROUP_PERMISSION"
export const UPDATE_GROUP_PERMISSION_SUCCESS = "UPDATE_GROUP_PERMISSION_SUCCESS"
export const UPDATE_GROUP_PERMISSION_FAIL = "UPDATE_GROUP_PERMISSION_FAIL"

export const SET_LOADING_INDICATOR_USER = "SET_LOADING_INDICATOR_USER"

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin, googleSocialLogin,checkSocialLogin,
setLoadingIndicator
} from "../../store/actions";
import CarouselPage from "./CarouselPage";
// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";
import { Auth } from "aws-amplify";
import moment from "moment";
import { authUser, sessionExpiration, sessionStorage } from "helpers/dataConfig";
import Loader from "components/Common/Loader";

const Login = props => {

  const [passwordShow, setPasswordShow] = useState(false);
  const [rememberPassword, setRememberPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);



  //meta title
  document.title = "Login | Smarteeva";

  const dispatch = useDispatch();

const [loginChecked, setLoginChecked] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: localStorage.getItem('rememberCreds')!= null ? JSON.parse(localStorage.getItem('rememberCreds')).email : '',
      password: localStorage.getItem('rememberCreds')!= null ? JSON.parse(localStorage.getItem('rememberCreds')).password : '',
      email: localStorage.getItem('rememberCreds')!= null ? JSON.parse(localStorage.getItem('rememberCreds')).email : '',
      password: localStorage.getItem('rememberCreds')!= null ? JSON.parse(localStorage.getItem('rememberCreds')).password : '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate, rememberPassword));
      // dispatch(loginUser(values, props.router.navigate, rememberPassword));
    }
  });

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading : state.Login.loading
  }));

  // const signIn = type => {
  //   dispatch(socialLogin(type, props.router.navigate));
  // };

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type);
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  useEffect(()=>{
    // console.log(loading)
    let user = JSON.parse(localStorage.getItem(authUser))
    // console.log(localStorage.getItem(sessionStorage))
    if (user != null) {
      // props.router.navigate('/mdr-list')
      props.router.navigate('/mdr-dashboard')

    }else{
    // dispatch(checkSocialLogin(props.router.navigate)) 
    }
  },[])


  const googleSignIn = () => {

    dispatch(googleSocialLogin());
    // try{
    //    await Auth.federatedSignIn({provider : 'Google'})
      
    // }catch(error){
    //   console.log(error)
    // }
  }


  useEffect(() => {
  
     setIsLoading(loading)
  
}, [loading]);

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={6}>
              <div className="auth-full-page-content p-md-5 p-4 centerJustCustom">
                <div className="w-100 maxWidthCustom">
                  <div className="d-flex flex-column h-100 centerJustCustom">
                    <div className="mb-2 mb-md-3 centerCustom">
                      <Link to="/dashboard" className="d-block auth-logo mb-3">
                        <img
                          src={logodark}
                          alt=""
                          height="70"
                          className="logo-dark-element"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="70"
                          className="logo-light-element"
                        />
                      </Link>
                    </div>
                    <div className="">
                      <div className="centerTextCustom">
                        <h5 className="welcome-text text-primary">Welcome Back !</h5>
                        <p className="text-muted">
                          Sign in to stay connected.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >

{error ? <Alert color="danger">{error}</Alert> : null}

                          <div className="mb-3">
                            <Label className="form-label">Email Address</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <div className="float-end">
                              <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                            </div>
                            <Label className="form-label">Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                              />
                              <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i></button>
                            
                                {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                            </div>
                            
                          </div>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                              value={rememberPassword}
                              onClick={()=>{ setRememberPassword(!rememberPassword)}}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Sign In
                            </button>
                          </div>

                        </Form>

                        <div className="mt-4 text-center">
                            <h5 className="font-size-14 mb-3">
                              Sign in with
                            </h5>

                            <ul className="list-inline">
                              {/* <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary me-1"
                                >
                                  <i className="mdi mdi-facebook"></i>
                                </Link>
                              </li> */}
                              {/* <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-info text-white border-info me-1"
                                >
                                  <i className="mdi mdi-twitter"></i>
                                </Link>
                              </li> */}
                              <li className="list-inline-item">
                                <Link
                                  // to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={googleSignIn}
                                >
                                  <i className="mdi mdi-google"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>

                        <div className="mt-4 text-center">
                          <p>
                            Don&apos;t have an account?&nbsp;
                            <Link
                              // to="pages-register"
                              to="/register"
                              className="fw-medium text-primary"
                            >
                              Click here to sign up
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <CarouselPage />

          </Row>
        </Container>
      </div>


      <Loader isLoading={isLoading} /> 
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};

import {
    GET_COMPANY_LIST_FAIL,
    GET_COMPANY_LIST_SUCCESS,
    ADD_COMPANY_SUCCESS,
    ADD_COMPANY_FAIL,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAIL,
    DELETE_COMPANY_SUCCESS,
    DELETE_COMPANY_FAIL,
    GET_PHONECODE_LIST_SUCCESS,
    GET_PHONECODE_LIST_FAIL,

    GET_COUNTRY_LIST_SUCCESS,
    GET_COUNTRY_LIST_FAIL,

    GET_STATE_LIST_SUCCESS,
    GET_STATE_LIST_FAIL,

    GET_CITY_LIST_SUCCESS,
    GET_CITY_LIST_FAIL,

    GET_BILLING_STATE_LIST_SUCCESS,
    GET_BILLING_STATE_LIST_FAIL,

    GET_BILLING_CITY_LIST_SUCCESS,
    GET_BILLING_CITY_LIST_FAIL,

    GET_COMPANY_DETAILS_SUCCESS,
    GET_COMPANY_DETAILS_FAIL,
    GET_COMPANY_CP_SUCCESS,
    APPROVE_COMPANY_SUCCESS,
    APPROVE_COMPANY_FAIL,
    SET_LOADING_INDICATOR_COMPANY
} from "./actionTypes";

const INIT_STATE = {
    companys: [],
    phonecode: [],
    country: [],
    states: [],
    city: [],
    billingstates: [],
    billingcity: [],
    companydetails : [],
    contactperson : [],
    error: {},
    loading : false
}

const CompanyReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                companys: action.payload,
                loading : false
            };

        case GET_COMPANY_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading : false
            };

            case GET_COMPANY_DETAILS_SUCCESS:
                return {
                    ...state,
                    companydetails: action.payload,
                    loading : false
                };

                case GET_COMPANY_CP_SUCCESS:
                    return {
                        ...state,
                        contactperson: action.payload,
                        loading : false
                    };
    
            case GET_COMPANY_DETAILS_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading : false
                };

        case GET_PHONECODE_LIST_SUCCESS:
            return {
                ...state,
                phonecode: action.payload,
                loading : false
            };

        case GET_PHONECODE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading : false
            };




        case ADD_COMPANY_SUCCESS:
            return {
                ...state,
                companys: state.companys,
                loading : false
            };

        case ADD_COMPANY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading : false
            };

        case UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                companys: state.companys,
                loading : false
            };

        case UPDATE_COMPANY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading : false
            };

        case DELETE_COMPANY_SUCCESS:
            return {
                ...state,
                companys: state.companys,
                loading : false
            };

        case DELETE_COMPANY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading : false
            };


        case GET_COUNTRY_LIST_SUCCESS:
            return {
                ...state,
                country: action.payload,
                loading : false
            };

        case GET_COUNTRY_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading : false
            };



        case GET_STATE_LIST_SUCCESS:
            return {
                ...state,
                states: action.payload,
                loading : false
            };
          

        case GET_STATE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading : false
            };


        case GET_CITY_LIST_SUCCESS:
            return {
                ...state,
                city: action.payload,
                loading : false
            };

        case GET_CITY_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading : false
            };

            case GET_BILLING_STATE_LIST_SUCCESS:
                return {
                    ...state,
                    billingstates: action.payload,
                    loading : false
                };
              
    
            case GET_BILLING_STATE_LIST_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading : false
                };
    
    
            case GET_BILLING_CITY_LIST_SUCCESS:
                return {
                    ...state,
                    billingcity: action.payload,
                    loading : false
                };
    
            case GET_BILLING_CITY_LIST_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading : false
                };


                case APPROVE_COMPANY_SUCCESS:
                    return {
                        ...state,
                        companys: state.companys,
                        loading : false
                    };
        
                case APPROVE_COMPANY_FAIL:
                    return {
                        ...state,
                        error: action.payload,
                        loading : false
                    };

                    case SET_LOADING_INDICATOR_COMPANY:
                        return {
                            ...state,
                            loading: action.payload
                        };
        

        default:
            return state
    }
}

export default CompanyReducer;
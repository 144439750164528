import React, { useState } from 'react';
import { Col } from 'reactstrap';

const CompanyListGlobalFilter = ({  onStatusChange,
    statusValue,
    approvalValue,
    onApprovalStatusChange}) => {
    const [selectDate, setSelectDate] = useState();
    const dateChange = (date) => {
        setSelectDate(date)
    };

    // const [status, setStatus] = useState('');
    // const [approvalStatus, setApprovalStatus] = useState('');
  
    // const handleStatusChange = (event) => {
    //   setStatus(event.target.value);
    // };
  
    // const handleApprovalStatusChange = (event) => {
    //   setApprovalStatus(event.target.value);
    // };

    return (
        <React.Fragment>    
            <Col xxl={2} lg={6}>
                <select className="form-control select2 mb-3 mb-xxl-0" 
                //  onChange={handleStatusChange}
                onChange={onStatusChange}
                 value={statusValue}
                 defaultValue=""
                >
                    <option disabled value={''}>Status</option>
                    <option value="All">All</option>
                    <option value="Active">Active</option>
                    {/* <option value="New">New</option>
                    <option value="Paused">Paused</option> */}
                    <option value="Archived">Archived</option>
                </select>
            </Col>
            <Col xxl={2} lg={6}>
                <select className="form-control select2 mb-3 mb-xxl-0" 
                 onChange={onApprovalStatusChange}
                //   onChange={handleApprovalStatusChange}
                  value={approvalValue}
                  defaultValue=""
                >
                    <option disabled value={''}>Approval Status</option>
                    <option value="All">All</option>
                    <option value="Approved">Approved</option>
                    <option value="Pending">Pending</option>
                </select>
            </Col>
            {/* <Col xxl={2} lg={4}>
                <div className='mb-3 mb-xxl-0'>
                    <button type="button" className="btn btn-primary w-100">
                        <i className="mdi mdi-filter-outline align-middle me-1"></i> Advanced Filter</button>
                </div>
            </Col> */}
        </React.Fragment>
    )
};
export default CompanyListGlobalFilter;


import PropTypes from "prop-types";
import React from "react";
import { Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import CardWithWatermark from "components/Common/Watermark";

const MDRFunnel = ({ dataColors , data, total}) => {

  // console.log(lastyear)
  // const sers = data.map(obj => Math.ceil((obj.MEDICAL_SPECIALTY_COUNT/total)*100))
  let sers = []
  let category = []
  if(data != undefined){
     sers = data.map(obj => obj.MEDICAL_SPECIALTY_COUNT)
     category = data.map(obj => obj.MEDICAL_SPECIALTY)
  }


  // console.log(sers)
  // console.log(category)


  const spineareaChartColors = getChartColorsArray(dataColors);

  const series = [
    {
      name: "MDR Count",
      data: sers,
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // top, center, bottom
        },
        // borderRadius: 10,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.ceil((val/total)*100) + "%"
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    colors: spineareaChartColors,
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: category,
      position: "bottom",
      labels: {
        offsetY: -5,
        rotate: -55,
          style: {
            fontSize: '10px',
            fontWeight: 500,
          },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -35,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val
        },
      },
    },
    markers: {
      size: 1,
      strokeWidth: 0,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      showForSingleSeries: true
    },
    // title: {
    //   text: "Total MDR based on Medical Specialty",
    //   floating: true,
    //   offsetY: 330,
    //   align: "center",
    //   style: {
    //     color: "#444",
    //   },
    // },
  }


  return (
    <React.Fragment>
     
<Col sm="12">
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-2">
              
              <h5 className="card-title me-2">MDRs based on Medcial Specialty</h5>
              </div>
              <div className="text-muted mt-4"  >
              
                {/* <div className="d-flex"> */}
                  {/* <span
                    className={
                      "badge badge-soft-" + report.color + " font-size-12"
                    }
                  >
                    {" "}
                    {report.badgeValue}{" "}
                  </span>{" "} */}
                  {/* <span className="ms-2 text-truncate"> */}
                  <ReactApexChart 
    options={options} 
    series={series} 
    type="bar" 
    height={500}
     className="apex-charts"
     />

                  {/* </span> */}
                {/* </div> */}
              </div>

              <CardWithWatermark/>


            </CardBody>
          </Card>
        </Col>

      
    </React.Fragment>
  )
}

MDRFunnel.propTypes = {
  reports: PropTypes.array,
}

export default MDRFunnel;

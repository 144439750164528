import PropTypes from "prop-types";
import React from "react";
import { Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import CardWithWatermark from "components/Common/Watermark";

const MDRRadial = ({ dataColors, data , total}) => {

  // console.log(data)

  const radialChartColors = getChartColorsArray(dataColors);

  const series = [Math.ceil((data[0].Count/total)*100), Math.ceil((data[1].Count/total)*100),Math.ceil((data[2].Count/total)*100), Math.ceil((data[3].Count/total)*100),Math.ceil((data[4].Count/total)*100)]
  const options = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return parseInt((w/100)*total)+' ('+w+'%)'
            },
          },
          total: {
            show: true,
            label: "Total",
            formatter: function (w) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return total
            },
          },
        },
        
      },
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    labels: [data[0].Year, data[1].Year, data[2].Year, data[3].Year,data[4].Year],
    colors: radialChartColors,
  }

  return (
    <React.Fragment>
     
<Col sm="12">
          <Card>
            <CardBody>
              <div className="d-flex align-items-center mb-2">
              
              <h5 className="card-title me-2">Yearly MDR</h5>
              </div>
              <div className="text-muted mt-4"  >
              
                {/* <div className="d-flex"> */}
                  {/* <span
                    className={
                      "badge badge-soft-" + report.color + " font-size-12"
                    }
                  >
                    {" "}
                    {report.badgeValue}{" "}
                  </span>{" "} */}
                  {/* <span className="ms-2 text-truncate"> */}
    <ReactApexChart options={options} series={series} type="radialBar" height="380"/>

                  {/* </span> */}
                {/* </div> */}
              </div>

              <CardWithWatermark/>

            </CardBody>
          </Card>
        </Col>

      
    </React.Fragment>
  )
}

MDRRadial.propTypes = {
  reports: PropTypes.array,
}

export default MDRRadial;

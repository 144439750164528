import React from 'react';

const CardWithWatermark = ({ style}) => {
  return (
    <div  className="watermark" style={style}>
      <div>SMARTEEVA SOFTWARE, INC</div>
      {/* <div className="card">
        <h2>SMARTEEVA SOFTWARE, INC.</h2>
        <p>{content}</p>
      </div> */}
    </div>
  );
};

export default CardWithWatermark;
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col } from "reactstrap";

const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={6} className="d-none d-sm-none d-md-block">
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            <div className="bg-overlay"></div>
            <div className="d-flex h-100 flex-column">
              <div className="px-4 py-2 height-100vh">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="text-center">
                      {/* <h4 className="mb-3">
                        <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                        <span className="text-primary">5k</span>+ Satisfied
                        clients
                      </h4> */}
                      <div dir="ltr" >
                        <Carousel className="owl-carousel owl-theme auth-review-carousel slider_css" id="auth-review-carousel"
                        autoPlay
                        infiniteLoop
                        swipeable
                        interval={2000}
                        axis={'horizontal'}
                          showThumbs={false}>
                          <div className="">
                            <div className="item pb-5 custom-calc">
                              <div className="outerWrap">
                                <div>
                                  <h1 className="heading-carsol">
                                    <div className="white-text" style={{textAlign : 'center'}}>Post Market Surveillance</div>
                                    <div className="gray-text" style={{textAlign : 'center'}}>Analytics Platform.</div>
                                  </h1>
                                </div>
                                {/* <div className="font-size-16 mt-4 para-text">
                                  &quot;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse id aliquam dui. Donec vel mi faucibus, imperdiet sem id, scelerisque turpis. Duis a mollis enim, sed porta felis. Nullam et pulvinar nisi. Aenean tincidunt leo at tortor pharetra gravida. Mauris facilisis dapibus libero, nec fermentum ipsum varius at. Curabitur euismod tristique nibh. Donec eget erat vitae risus porttitor dapibus. 
                                   Donec vel mi faucibus, imperdiet sem id, scelerisque turpis. Duis a mollis enim, sed porta felis. Nullam et pulvinar nisi. Aenean tincidunt leo at tortor pharetra gravida. &ldquo;
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div>
                          <div className="item pb-5 custom-calc">
                              <div className="outerWrap">
                                <div>
                                  <h1 className="heading-carsol">
                                    <div className="white-text"  style={{textAlign : 'center'}}>Digital platform for</div>
                                    <div className="gray-text"  style={{textAlign : 'center'}}>MDR Reporting.</div>
                                  </h1>
                                </div>
                                {/* <div className="font-size-16 mt-4 para-text">
                                  &quot;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse id aliquam dui. Donec vel mi faucibus, imperdiet sem id, scelerisque turpis. Duis a mollis enim, sed porta felis. Nullam et pulvinar nisi. Aenean tincidunt leo at tortor pharetra gravida. Mauris facilisis dapibus libero, nec fermentum ipsum varius at. Curabitur euismod tristique nibh. Donec eget erat vitae risus porttitor dapibus. 
                                   Donec vel mi faucibus, imperdiet sem id, scelerisque turpis. Duis a mollis enim, sed porta felis. Nullam et pulvinar nisi. Aenean tincidunt leo at tortor pharetra gravida. &ldquo;
                                </div> */}
                              </div>
                            </div>
                          </div>
                          
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage

import {
  ADD_EVENT_DT_FAIL,
  ADD_EVENT_DT_SUCCESS,
  ADD_MODULE_FAIL,
  ADD_MODULE_SUCCESS,
  ADD_NEW_MODULE_SCORE_FAIL,
  ADD_NEW_MODULE_SCORE_SUCCESS,
  APPROVE_EVENT_DT_FAIL,
  APPROVE_EVENT_DT_SUCCESS,
  APPROVE_MODULE_FAIL,
  APPROVE_MODULE_SCORE_FAIL,
  APPROVE_MODULE_SCORE_SUCCESS,
  APPROVE_MODULE_SUCCESS,
  DEACTIVATE_MODULE_FAIL,
  DEACTIVATE_MODULE_SUCCESS,
  DELETE_EVENT_DT_FAIL,
  DELETE_EVENT_DT_SUCCESS,
  DELETE_MODULE_FAIL,
  DELETE_MODULE_SCORE_FAIL,
  DELETE_MODULE_SCORE_SUCCESS,
  DELETE_MODULE_SUCCESS,
  GET_ACTIVE_MODULES_FAIL,
  GET_ACTIVE_MODULES_SUCCESS,
  GET_DRAFT_TAB_DATA_DT_FAIL,
  GET_DRAFT_TAB_DATA_DT_SUCCESS,
  GET_EVENTS_DT_FAIL,
  GET_EVENTS_DT_SUCCESS,
  GET_MODULES_FAIL,
  GET_MODULES_SUCCESS,
  GET_MODULE_SCORES_FAIL,
  GET_MODULE_SCORES_SUCCESS,
  GET_MODULE_SCORE_BYID_FAIL,
  GET_MODULE_SCORE_BYID_SUCCESS,
  GET_TABDATA_EVENT_FAIL,
  GET_TABDATA_EVENT_SUCCESS,
  SAVE_DRAFT_TAB_DATA_DT_FAIL,
  SAVE_DRAFT_TAB_DATA_DT_SUCCESS,
  SAVE_TAB_ANSWER_DATA_DT,
  SAVE_TAB_ANSWER_DATA_DT_FAIL,
  SAVE_TAB_ANSWER_DATA_DT_SUCCESS,
  SAVE_TAB_DATA_DT_FAIL,
  SAVE_TAB_DATA_DT_SUCCESS,
  SET_LOADING_INDICATOR_DT,
  UPDATE_EVENT_DT_FAIL,
  UPDATE_EVENT_DT_SUCCESS,
  UPDATE_MODULE_FAIL,
  UPDATE_MODULE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  modulesDT: [],
  modules_length: -1,
  error: "",
  loading: false,
  addModuleSuccessMsg: '',
  tabid: '',
  successMsg: '',
  draftData: {},
  activeModulesData: [],
  eventsDT: [],
  event_length: -1,
  addEventSuccessMsg: '',
  ansSuccessMsg: '',
  tabDataEvent: [],
  addModuleScoreSuccessMsg: '',
  moduleScores: [],
  module_score_length: -1,
  moduleScores : []
}

const DecisionTree = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MODULES_SUCCESS:
      return {
        ...state,
        modulesDT: action.payload,
        modules_length: action.payload.length,
        loading: false
      }

    case GET_MODULES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case ADD_MODULE_SUCCESS:

      return {
        ...state,
        // modulesDT: action.payload,
        addModuleSuccessMsg: action.payload,
        loading: false
      }

    case ADD_MODULE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // case GET_USER_PROFILE_SUCCESS:
    //   return {
    //     ...state,
    //     userProfile: action.payload,
    //     loading : false
    //   }

    case UPDATE_MODULE_SUCCESS:
      return {
        ...state,
        // modulesDT: state.modulesDT,
        addModuleSuccessMsg: action.payload,
        loading: false
      }

    case UPDATE_MODULE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case DELETE_MODULE_SUCCESS:
      return {
        ...state,
        modulesDT: state.modulesDT,
        loading: false
      }

    case DELETE_MODULE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case DEACTIVATE_MODULE_SUCCESS:
      return {
        ...state,
        modulesDT: state.modulesDT,
        loading: false
      }

    case DEACTIVATE_MODULE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case APPROVE_MODULE_SUCCESS:
      return {
        ...state,
        addModuleSuccessMsg: action.payload,
        loading: false
      }

    case APPROVE_MODULE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }


    case SAVE_TAB_DATA_DT_SUCCESS:
      return {
        ...state,
        successMsg: action.payload,
        loading: false
      }

    case SAVE_TAB_DATA_DT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case SAVE_DRAFT_TAB_DATA_DT_SUCCESS:
      return {
        ...state,
        successMsg: action.payload,
        loading: false
      }

    case SAVE_DRAFT_TAB_DATA_DT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }


    case GET_DRAFT_TAB_DATA_DT_SUCCESS:
      return {
        ...state,
        draftData: action.payload,
        loading: false
      }

    case GET_DRAFT_TAB_DATA_DT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }


    case GET_ACTIVE_MODULES_SUCCESS:
      return {
        ...state,
        activeModulesData: action.payload,
        // loading: false
      }

    case GET_ACTIVE_MODULES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case GET_EVENTS_DT_SUCCESS:
      return {
        ...state,
        eventsDT: action.payload,
        event_length: action.payload.length,
        loading: false
      }

    case GET_EVENTS_DT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case ADD_EVENT_DT_SUCCESS:

      return {
        ...state,
        // modulesDT: action.payload,
        addEventSuccessMsg: action.payload,
        loading: false
      }

    case ADD_EVENT_DT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }


    case APPROVE_EVENT_DT_SUCCESS:
      return {
        ...state,
        addEventSuccessMsg: action.payload,
        loading: false
      }

    case APPROVE_EVENT_DT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }


    case DELETE_EVENT_DT_SUCCESS:
      return {
        ...state,
        eventsDT: state.eventsDT,
        loading: false
      }

    case DELETE_EVENT_DT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case UPDATE_EVENT_DT_SUCCESS:
      return {
        ...state,
        // modulesDT: state.modulesDT,
        addEventSuccessMsg: action.payload,
        loading: false
      }

    case UPDATE_EVENT_DT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }


    case GET_TABDATA_EVENT_SUCCESS:
      return {
        ...state,
        tabDataEvent: action.payload,
        // modules_length: action.payload.length,
        loading: false
      }

    case GET_TABDATA_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case SAVE_TAB_ANSWER_DATA_DT_SUCCESS:
      return {
        ...state,
        ansSuccessMsg: action.payload,
        loading: false
      }

    case SAVE_TAB_ANSWER_DATA_DT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }


    case ADD_NEW_MODULE_SCORE_SUCCESS:

      return {
        ...state,
        addModuleScoreSuccessMsg: action.payload,
        loading: false
      }

    case ADD_NEW_MODULE_SCORE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case GET_MODULE_SCORES_SUCCESS:
      return {
        ...state,
        moduleScores: action.payload,
        module_score_length: action.payload.length,
        loading: false
      }

    case GET_MODULE_SCORES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }


    case DELETE_MODULE_SCORE_SUCCESS:
      return {
        ...state,
        moduleScores: state.moduleScores,
        loading: false
      }

    case DELETE_MODULE_SCORE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }


    case APPROVE_MODULE_SCORE_SUCCESS:
      return {
        ...state,
        addModuleScoreSuccessMsg: action.payload,
        loading: false
      }

    case APPROVE_MODULE_SCORE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

      case GET_MODULE_SCORE_BYID_SUCCESS:
        return {
          ...state,
          moduleScores: action.payload,
          // loading: false
        }
  
      case GET_MODULE_SCORE_BYID_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        }
  


    case SET_LOADING_INDICATOR_DT:
      return {
        ...state,
        loading: action.payload
      };

    default:
      return state
  }
}

export default DecisionTree

import React, { useState, useEffect } from "react";
import { Link , useNavigate} from "react-router-dom"
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardSubtitle,
  Label,
  FormFeedback,
  Modal,
  ModalBody
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import * as Yup from "yup";
import { useFormik } from "formik";

//importing QueryBuilder
import QueryBuilder from '../Smart-Query/QueryBuilder';
//redux
import { useSelector, useDispatch } from "react-redux";
import {
  getRecentList as onGetRecentList,
  getSavedList as onGetSavedList,
  getSearchFieldList as onGetSearchFieldList,
  getMedicalSplList as onGetMedicalSplList,
  getDeviceProbCodeList as onGetDeviceProbCodeList,
  getPatientProbCodeList as onGetPatientProbCodeList,
  saveSmartQuery as onSaveSmartQuery,
  executeSmartQuery as onExecuteSmartQuery,
  resetQueryResult,
  executeQueryResultNoDataMdrList,
  abortSmartQuery
} from "store/actions";
import moment from "moment";
import { authUser } from "helpers/dataConfig";
import Loader from "components/Common/Loader";
import { useLocation } from 'react-router-dom';

const SmartQuery = () => {

  const dispatch = useDispatch();

  //meta title
  document.title = "Smart Query | Smarteeva";

  const navigate = useNavigate()
  const location = useLocation();

  const [search_Menu, setsearch_Menu] = useState(false);
  const [settings_Menu, setsettings_Menu] = useState(false);
  const [other_Menu, setother_Menu] = useState(false);
  const [activeTab, setactiveTab] = useState("1");
  const [recentQueryList, setRecentQueryList] = useState([]);
  const [savedQueryList, setSavedQueryList] = useState([]);
  const [searchableFieldList, setSearchableFieldList] = useState([]);

  const [medicalSplList, setMedicalSplList] = useState([]);
  const [deviceProblemCodeList, setDeviceProblemCodeList] = useState([]);
  const [patientProblemCodeList, setPatientProblemCodeList] = useState([]);
  const [queryType, setQueryType] = useState('');
  const [noDataModalVisible, setNoDataModal] = useState(false);

  const [groupEditData, setGroupEditData] = useState([]);


  const [isLoading, setIsLoading] = useState(false);


  const [conditionList, setConditionList] = useState([
  {label : 'equal', value : '='},
  {label : 'not equal', value : '!='},
  {label : 'in', value : 'IN'},
  {label : 'not in', value : 'NOT IN'},
  {label : 'begins with', value : 'LIKE'},
  {label : "doesn't begin with", value : "NOT LIKE"},
  {label : "contains", value : "LIKE"},
  {label : "doesn't contain", value : "NOT LIKE"},
  {label : "ends with", value : "LIKE"},
  {label : "doesn't end with", value : "NOT LIKE"},
  {label : "is empty", value : "IS NULL"},
  {label : "is not empty", value : "IS NOT NULL"},
  {label : "is null", value : "IS NULL"},
  {label : "is not null", value : "IS NOT NULL"},
  ]);

  const [conditionList2, setConditionList2] = useState([
    {label : 'equal', value : '='},
    {label : 'not equal', value : '!='},
    {label : 'less than', value : '<'},
    {label : 'less than equal to', value : '<='},
    {label : 'greater than', value : '>'},
    {label : 'greater than equal to', value : '>='},
    {label : "is null", value : "IS NULL"},
    {label : "is not null", value : "IS NOT NULL"},
    ]);

    const [conditionList3, setConditionList3] = useState([
      {label : 'equal', value : '='},
      {label : 'not equal', value : '!='},
      {label : 'not equal', value : '!='},
      {label : 'between', value : 'between'},
      {label : 'less than', value : '<'},
      {label : 'less than equal to', value : '<='},
      {label : 'greater than', value : '>'},
      {label : 'greater than equal to', value : '>='},
      {label : "is null", value : "IS NULL"},
      {label : "is not null", value : "IS NOT NULL"},
      ]);

  const [groupConditionList, setGroupConditionList] = useState([
    {label : 'AND', value : 'AND'},
    {label : 'OR', value : 'OR'}
    ]);

  



  const { recent_query, recent_length } = useSelector(state => ({
    recent_query: state.SmartQueryReducer.recent_query,
    recent_length: state.SmartQueryReducer.recent_length,

  }));

  const { saved_query, saved_length} = useSelector(state => ({
    saved_query: state.SmartQueryReducer.saved_query,
    saved_length: state.SmartQueryReducer.saved_length,

  }));

  const { search_fields } = useSelector(state => ({
    search_fields: state.SmartQueryReducer.search_fields,
  }));

  const { medical_spl } = useSelector(state => ({
    medical_spl: state.SmartQueryReducer.medical_spl,
  }));

  const { device_prob_code } = useSelector(state => ({
    device_prob_code: state.SmartQueryReducer.device_prob_code,
  }));

  const { patient_prob_code } = useSelector(state => ({
    patient_prob_code: state.SmartQueryReducer.patient_prob_code,
  }));

  const { query_result } = useSelector(state => ({
    query_result: state.SmartQueryReducer.query_result,
  }));

  const { error, loading, doneState,query_result_no_data } = useSelector(state => ({
    error: state.SmartQueryReducer.error,
    loading : state.SmartQueryReducer.loading,
    doneState : state.SmartQueryReducer.doneState,
    query_result_no_data : state.SmartQueryReducer.query_result_no_data,

  }));


  useEffect(() => {
    setIsLoading(loading)
}, [loading]);




useEffect(() => {
  // let ddata = JSON.parse(localStorage.getItem('QueryDetailsNav'));
  // console.log(location.state)

  let ddata = location.state
  if(ddata){
          setGroupEditData(ddata)
    validation.setFieldValue('title', ddata.title)
    validation.setFieldValue('description', ddata.description)
    validation.setFieldValue('querylogic', ddata.query_string)
    validation.setFieldValue('displayquerylogic', ddata.display_query_string)
    validation.setFieldValue('groupData', ddata.groupdata)
  }
  // if(ddata!=null){

  //   const isRefreshed = performance.navigation.type === 1;
  //   const isRedirected = performance.navigation.type === 2;

  //   if (isRedirected) {
  //     console.log('Page was redirected');
  //     setGroupEditData(ddata)
  //   validation.setFieldValue('title', ddata.title)
  //   validation.setFieldValue('description', ddata.description)
  //   validation.setFieldValue('querylogic', ddata.query_string)
  //   validation.setFieldValue('displayquerylogic', ddata.display_query_string)
  //   validation.setFieldValue('groupData', ddata.groupdata)
  //   }else if(isRefreshed){
  //     // localStorage.removeItem('QueryDetailsNav')
  //     console.log('Page was refreshed');
  //   }
    
  // }
}, [location.state]);

  useEffect(() => {
    localStorage.removeItem('QueryDetails')
    console.log(query_result_no_data)
   if(query_result_no_data){
   setNoDataModal(true)
   }
  }, [query_result_no_data, dispatch, query_result]);

  useEffect(() => {
    dispatch(abortSmartQuery(false));
    // console.log(recent_query.length)
    if(recent_length == -1){
    if (recent_query && !recent_query.length) {
        // console.log(recent_query)
        dispatch(onGetRecentList());
    }
  }
}, [dispatch, recent_query]);


useEffect(() => {
  setRecentQueryList(recent_query);
  // console.log(recent_query)
}, [recent_query]);


useEffect(() => {
  // console.log(saved_query)
  if(saved_length == -1){
  if (saved_query && !saved_query.length) {
      dispatch(onGetSavedList());
  }
}
}, [dispatch, saved_query]);


useEffect(() => {
setSavedQueryList(saved_query);
// console.log(recent_query)
}, [saved_query]);


useEffect(() => {
  // console.log(search_fields)
  if (search_fields && !search_fields.length) {
      // console.log(recent_query)
      dispatch(onGetSearchFieldList());
  }
}, [dispatch, search_fields]);

useEffect(() => {
  setSearchableFieldList(search_fields);
  // console.log(recent_query)
  }, [search_fields]);


  useEffect(() => {
    // console.log(recent_query.length)
    if (medical_spl && !medical_spl.length) {
        // console.log(recent_query)
        dispatch(onGetMedicalSplList());
    }
}, [dispatch, medical_spl]);


useEffect(() => {
  setMedicalSplList(medical_spl);
  // console.log(medical_spl)
}, [medical_spl]);


useEffect(() => {
  // console.log(recent_query.length)
  if (device_prob_code && !device_prob_code.length) {
      // console.log(recent_query)
      dispatch(onGetDeviceProbCodeList());
  }
}, [dispatch, device_prob_code]);


useEffect(() => {
setDeviceProblemCodeList(device_prob_code);
// console.log(recent_query)
}, [device_prob_code]);


useEffect(() => {
  // console.log(recent_query.length)
  if (patient_prob_code && !patient_prob_code.length) {
      // console.log(recent_query)
      dispatch(onGetPatientProbCodeList());
  }
}, [dispatch, patient_prob_code]);


useEffect(() => {
setPatientProblemCodeList(patient_prob_code);
// console.log(recent_query)
}, [patient_prob_code]);


  //Toggle Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu);
  };

  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu);
  };

  const toggleOther = () => {
    setother_Menu(!other_Menu);
  };

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };




  //search recent user
  const searchUsers = () => {
    var input,filter;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();

    if(activeTab == '1'){
    var ul, li, a, i, txtValue;
      ul = document.getElementById("recent-list");
      li = ul.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("a")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    }else{
    var ul, li, a, i, txtValue;
      ul = document.getElementById("saved-list");
      li = ul.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("a")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    }
  
  };



    // validation
    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
  
      initialValues: {
          title: '',
          description : '',
          querylogic : '',
          displayquerylogic : '',
          groupData : ''
  
      },
      validationSchema: Yup.object({
        querylogic: Yup.string().required("Please Create Query Logic")
      }),
      onSubmit: (values) => {

        if(queryType == 'Save'){

          const query = {
            title : values.title,
            description : values.description,
            query_string : values.querylogic,
            display_query_string : values.displayquerylogic,
            userid : JSON.parse(localStorage.getItem(authUser)).fld_userid,
            type : 'Save',
            createdby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
            createdon : moment().format(),
            groupdata : values.groupData
            }
            // console.log(values)
            dispatch(onSaveSmartQuery(query, navigate));

        }else{

          const query = {
            title : values.title,
            description : values.description,
            query_string : values.querylogic,
            display_query_string : values.displayquerylogic,
            userid : JSON.parse(localStorage.getItem(authUser)).fld_userid,
            type : 'Recent',
            createdby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
            createdon : moment().format(),
            groupdata : values.groupData
            }
            // console.log(values)
           

            const execute = {
              query_string : values.querylogic,
              off_set : 0
              }

              dispatch(resetQueryResult());
            dispatch(onExecuteSmartQuery(execute, navigate, query, 'main'));
            dispatch(onSaveSmartQuery(query, navigate));

        }

      
      
      },
  });
  

  const handleLogic = (logic) =>{
    // console.log(logic)
    validation.setFieldValue('querylogic', logic)

  }

  const handleDisplayLogic = (logic) =>{
    validation.setFieldValue('displayquerylogic', logic)
    validation.setFieldValue('title', logic)
    validation.setFieldValue('description', logic)

  }

  const handleQueryGroup = (grp) =>{
    validation.setFieldValue('groupData', JSON.stringify(grp))
  }

  const NoDataModal = () =>{

    return (
      <Modal
      isOpen={noDataModalVisible}
      toggle={() => {
        setNoDataModal(!noDataModalVisible);
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          No Data!
        </h5>
        <button
          type="button"
          onClick={() => {
            setNoDataModal(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <h5>Data not Present</h5>
        <p>
         The data you're trying to fetch from smart query is not present.
        </p>
       
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setNoDataModal(false)
            executeQueryResultNoDataMdrList(false)
          }}
          className="btn btn-primary"
          data-dismiss="modal"
        >
          Close
        </button>
      
      </div>
    </Modal>
    )
  }

  return (
    <React.Fragment>
      {NoDataModal()}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Smart Query" breadcrumbItem="Recent Query" />
          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div>
                    <div className="search-box chat-search-box py-4">
                      <div className="position-relative">
                        <Input
                          onKeyUp={searchUsers}
                          id="search-user"
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav">
                      <Nav pills justified>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1");
                            }}
                          >
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Recent Queries</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2");
                            }}
                          >
                            <i className="bx bx-buildings font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Saved Queries</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="1">
                          <div>
                            {/* <h5 className="font-size-14 mb-3">Recent Query</h5> */}
                            <ul className="list-unstyled chat-list" id="recent-list">
                              <PerfectScrollbar style={{ height: "410px" }}>

                            {recentQueryList.length > 0 ? 
                            recentQueryList.map((query, index)=>(
                              <li key={index} onClick={()=>{
                                // console.log(query)
                                // setGroupEditData(query.FLD_GROUPDATA)
                                let grpData = {
                                  groupdata : query.FLD_GROUPDATA,
                                  query_string : query.FLD_QUERY_STRING,
                                  display_query_string : query.FLD_DISPLAY_QUERY_STRING
                                }
                                setGroupEditData(grpData)
                                validation.setFieldValue('title', query.FLD_TITLE)
                                validation.setFieldValue('description', query.FLD_DESCRIPTION)
                                validation.setFieldValue('querylogic', query.FLD_QUERY_STRING)
                                validation.setFieldValue('displayquerylogic', query.FLD_DISPLAY_QUERY_STRING)
                                validation.setFieldValue('groupData', query.FLD_GROUPDATA)
                              }}>
                              <Link to="#">
                                <div className="d-flex">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="text-truncate font-size-14 mb-1">
                                      {query.FLD_TITLE}
                                    </h5>
                                    <p className="text-truncate mb-0">
                                      {query.FLD_DESCRIPTION}
                                    </p>
                                  </div>

                                </div>
                              </Link>
                            </li>
                            ))
                            : null}
                               

                              </PerfectScrollbar>
                            </ul>
                          </div>
                        </TabPane>

                        <TabPane tabId="2">
                          {/* <h5 className="font-size-14 mb-3">Organizational Query </h5> */}
                          <ul className="list-unstyled chat-list" id="saved-list">
                            <PerfectScrollbar style={{ height: "410px" }}>


                            {savedQueryList.length > 0 ? 
                            savedQueryList.map((query, index)=>(
                              <li key={index} onClick={()=>{
                                // console.log(query)
                                // setGroupEditData(query.FLD_GROUPDATA)
                                let grpData = {
                                  groupdata : query.FLD_GROUPDATA,
                                  query_string : query.FLD_QUERY_STRING,
                                  display_query_string : query.FLD_DISPLAY_QUERY_STRING
                                }
                                setGroupEditData(grpData)
                                validation.setFieldValue('title', query.FLD_TITLE)
                                validation.setFieldValue('description', query.FLD_DESCRIPTION)
                                validation.setFieldValue('querylogic', query.FLD_QUERY_STRING)
                                validation.setFieldValue('displayquerylogic', query.FLD_DISPLAY_QUERY_STRING)
                                validation.setFieldValue('groupData', query.FLD_GROUPDATA)
                              }}>
                              <Link to="#">
                                <div className="d-flex">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="text-truncate font-size-14 mb-1">
                                      {query.FLD_TITLE}
                                    </h5>
                                    <p className="text-truncate mb-0">
                                      {query.FLD_DESCRIPTION}
                                    </p>
                                  </div>

                                </div>
                              </Link>
                            </li>
                            ))
                            : null}


                              {/* <li>
                                <Link
                                  to="#"
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                      <h5 className="font-size-14 mb-0">
                                        Query Group 1
                                      </h5>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                             */}
                            </PerfectScrollbar>
                          </ul>
                        </TabPane>

                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="w-100 h-80vh">
                  <Card className="h-96">
                    <div className="p-4 border-bottom ">
                  
                      <Row>
                      <Col md="12" xs="12">
                      <Link className="btn btn-light me-3" style={{float : 'right'}}
                      onClick={()=>{
                        setGroupEditData('')
                        validation.resetForm();
                      }}
                      ><i className="mdi mdi-refresh"
                                            
                                            ></i> Reset </Link>
                                            </Col>
                        <Col md="12" xs="12">
                          <Col md="12" xs="12">
                            <Row className="flex-center">
                              <Col md="6" xs="12" className="mb-3">
                                {/* <h4 className="m-0">
                                  Query Name 
                                </h4> */}
                                  <FormGroup className="mb-3">
                                    <Label>
                                       Query Title
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter query title"
                                      name="title"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.title || ""}
                                      invalid={
                                        validation.touched.title && validation.errors.title ? true : false
                                      }
                                    />
                                    {validation.touched.title && validation.errors.title ? (
                                      <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                    ) : null}
                                  </FormGroup>
                              
                              </Col>
                              <Col md="6" xs="12" className="mb-3 flex-end">
                                <div className="btn-group">
                                  <button type="button" className="btn btn-outline-primary flex-center"  
                                  onClick={() => {
                                    setQueryType('Recent')
                                    validation.handleSubmit();
                                  }}>
                                    <i className="mdi mdi-play font-size-20 pr-07-icon" />
                                    <span className="pl-7-text">Run Query</span></button>
                                  {/* <button type="button" className="btn btn-outline-primary flex-center">
                                    <i className="mdi mdi-square-edit-outline font-size-20 pr-07-icon" />
                                    <span className="pl-7-text">Edit</span></button> */}
                                  <button type="button" className="btn btn-outline-primary flex-center"
                                  onClick={() => {
                                    setQueryType('Save')
                                    validation.handleSubmit();
                                  }}
                                  >
                                    <i className="mdi mdi-content-save font-size-20 pr-07-icon" />
                                    <span className="pl-7-text">Save</span></button>
                                  <button type="button" className="btn btn-outline-primary flex-center"
                                  
                                  >
                                    <i className="mdi mdi-delete font-size-20 pr-07-icon" />
                                    <span className="pl-7-text">Delete</span></button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="12" xs="12">
                            {/* <p className="text-muted mb-0">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque sed ornare quam, a efficitur purus. Mauris tempor eleifend sapien non pellentesque.
                            </p> */}
                              <FormGroup className="mb-3">
                                    <Label>
                                       Description
                                    </Label>
                                    <Input
                                      type="textarea"
                                      rows="3"
                                      className="form-control"
                                      placeholder="Enter query description"
                                      name="description"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.description || ""}
                                      invalid={
                                        validation.touched.description && validation.errors.description ? true : false
                                      }
                                    />
                                    {validation.touched.description && validation.errors.description ? (
                                      <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                    ) : null}
                                  </FormGroup>

                                  {validation.touched.querylogic && validation.errors.querylogic ? (
                                      <FormFeedback type="invalid" style={{textAlign : 'center'}}>{validation.errors.querylogic}</FormFeedback>
                                    ) : null}
                          </Col>
                        </Col>
                      </Row>
                    </div>

                    <div className="m-4">
                      <Col md="12" xs="12">
                        <div id="query-builder-container">
                          <QueryBuilder searchableFieldList={searchableFieldList} 
                          conditionList={conditionList} 
                          conditionList2={conditionList2} 
                          conditionList3={conditionList3} 
                          groupConditionList={groupConditionList}
                          medicalSplList={medicalSplList}
                          deviceProblemCodeList={deviceProblemCodeList}
                          patientProblemCodeList={patientProblemCodeList}
                          handleLogic={handleLogic}
                          handleDisplayLogic={handleDisplayLogic}
                          handleQueryGroup={handleQueryGroup}
                          groupEditData={groupEditData}
                          />
                        </div>
                      </Col>
                    </div>

                    <Col md="12" xs="12" className="mb-4 flex-start" style={{marginLeft : 20}}>
                                <div className="btn-group">
                                  <button type="button" className="btn btn-outline-primary flex-center"  
                                  onClick={() => {
                                    setQueryType('Recent')
                                    validation.handleSubmit();
                                  }}>
                                    <i className="mdi mdi-play font-size-20 pr-07-icon" />
                                    <span className="pl-7-text">Run Query</span></button>
                                  {/* <button type="button" className="btn btn-outline-primary flex-center">
                                    <i className="mdi mdi-square-edit-outline font-size-20 pr-07-icon" />
                                    <span className="pl-7-text">Edit</span></button> */}
                                  <button type="button" className="btn btn-outline-primary flex-center"
                                  onClick={() => {
                                    setQueryType('Save')
                                    validation.handleSubmit();
                                  }}
                                  >
                                    <i className="mdi mdi-content-save font-size-20 pr-07-icon" />
                                    <span className="pl-7-text">Save</span></button>
                                  <button type="button" className="btn btn-outline-primary flex-center"
                                  
                                  >
                                    <i className="mdi mdi-delete font-size-20 pr-07-icon" />
                                    <span className="pl-7-text">Delete</span></button>
                                </div>
                              </Col>

                    {/* <div className="m-4">
                      <Col md="12" xs="12">
                        <div className="mb-4">
                          <h5>MDR Search Values</h5>
                        </div>
                        <form>
                          <div className="row">
                            <Col md="6" xs="12" className="mb-3">
                              <Label className="form-label">Product Description</Label>
                              <Input
                                placeholder=""
                                type="text"
                                disabled/>
                            </Col>
                            <Col md="6" xs="12" className="mb-3">
                              <Row>
                                <Col md="6" xs="6">
                                  <Label className="form-label">Product Code</Label>
                                  <Input
                                    placeholder=""
                                    type="text"
                                    disabled/>
                                </Col>
                                <Col md="6" xs="6">
                                  <Label className="form-label">Report Type</Label>
                                  <Select
                                    className="select2-selection"
                                    disabled/>
                                </Col>
                              </Row>
                            </Col>
                            <Col md="6" xs="12" className="mb-3">
                              <Label className="form-label">Manufacturer</Label>
                              <Input
                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="6" xs="12" className="mb-3">
                              <Label className="form-label">Date Range Report Received</Label>
                              <InputGroup>
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Select Date Range for Search"
                                  options={{
                                    mode: "range",
                                    dateFormat: "d-m-Y"
                                  }}
                                  disabled />
                              </InputGroup>
                            </Col>
                          </div>
                        </form>
                        <div className="my-4">
                          <h5>Advanced MDR Search Values</h5>
                        </div>
                        <form>
                          <div className="row">
                            <Col md="6" xs="12" className="mb-3">
                              <Label className="form-label">Device Name</Label>
                              <Input
                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="6" xs="12" className="mb-3">
                              <Row>
                                <Col md="6" xs="6">
                                  <Label className="form-label">Model Number</Label>
                                  <Input
                                    placeholder=""
                                    type="text"
                                    disabled />
                                </Col>
                                <Col md="6" xs="6">
                                  <Label className="form-label">Medical Panel</Label>
                                  <Select
                                    className="select2-selection"
                                    disabled />
                                </Col>
                              </Row>
                            </Col>
                            <Col md="6" xs="12" className="mb-3">
                              <Label className="form-label">Manufacturer</Label>
                              <Input
                                placeholder=""
                                type="text"
                                disabled />
                            </Col>
                            <Col md="6" xs="12" className="mb-3">
                              <Label className="form-label">Date FDA Received</Label>
                              <InputGroup disabled>
                                <Flatpickr
                                  className="form-control border-rad d-block"
                                  placeholder="Can Select Multiple Dates"
                                  options={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    mode: "multiple",
                                    dateFormat: "d-m-Y"
                                  }}
                                   />
                              </InputGroup>
                            </Col>
                          </div>
                        </form>
                      </Col>
                    </div> */}
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Loader isLoading={isLoading} /> 
    </React.Fragment>
  );
};



export default SmartQuery;

import React from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  DropdownItem,
} from "reactstrap"

import avatar from "../../assets/images/users/avatar-1.jpg"
import user1 from "../../assets/images/users/user_icon.png";


const Settings = props => {
  return (
    <React.Fragment>
      <Col xl={12}>
        <Row>
          <Col xl={6}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="me-3">
                    <img
                      src={props.userData.fld_photo == null || props.userData.fld_photo == '' ? user1 : props.userData.fld_photo}
                      alt=""
                      className="avatar-sm rounded-circle img-thumbnail"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="text-muted">
                          <h5 className="mb-1">{props.userData.fld_fname == '' ? props.userData.fld_username : props.userData.fld_fname+' '+props.userData.fld_lname}</h5>
                          <p className="mb-0">{props.userData.fld_designationname}</p>
                        </div>
                      </div>

                      {/* <UncontrolledDropdown
                        className="ms-2"
                      >
                        <DropdownToggle
                          className="btn btn-light btn-sm"
                          color="#eff2f7"
                          type="button"
                        >
                          <i className="bx bxs-cog align-middle me-1"></i> Setting
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <Link className="dropdown-item" to="#">
                            Action
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Another action
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Something else
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown> */}
                    </div>

                    <hr />

                    <Row>
                      <Col xl={6}>
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Total MDRs
                          </p>
                          <h5 className="mb-0">320</h5>
                        </div>
                      </Col>
                      <Col xl={6}>
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Device Reports
                          </p>
                          <h5 className="mb-0">430</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={6}>
            <Card>
              <CardBody>
                <div className="d-flex flex-wrap align-items-start">
                  <h5 className="card-title mb-3 me-2">Total Reports</h5>

                  <UncontrolledDropdown className="ms-auto">
                    <DropdownToggle tag="a" className="text-muted font-size-16" role="button">
                      <i className="mdi mdi-dots-horizontal"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem className="dropdown-item" href="#">Action</DropdownItem>
                      <DropdownItem className="dropdown-item" href="#">Another action</DropdownItem>
                      <DropdownItem className="dropdown-item" href="#">Something else here</DropdownItem>
                      <div className="dropdown-divider"></div>
                      <DropdownItem className="dropdown-item" href="#">Separated link</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>

                <div className="d-flex flex-wrap">
                  <div>
                    <p className="text-muted mb-1">Medical Device Reports</p>
                    <h4 className="mb-3">10,512</h4>
                    <p className="text-success mb-0"><span>0.6 % <i className="mdi mdi-arrow-top-right ms-1"></i></span></p>
                  </div>
                  <div className="ms-auto align-self-end">
                    <i className="bx bxs-layer display-4 text-light"></i>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  )
}

export default Settings

import React, {useState, useEffect} from "react";

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useSelector, useDispatch } from "react-redux";
import Loader from "components/Common/Loader";
import { getGroupPermission, updateGroupPermission } from "store/actions";
import { Link, useNavigate } from "react-router-dom";
import Notiflix from "notiflix";

const ManageGroupAccess = () => {

  //meta title
  document.title = "Access Setup | Smarteeva";
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false);
  const [groupPermission, setGroupPermission] = useState([]);
  const [groupedPermissions, setGroupedPermissions] = useState([]);



  const { error, loading, grouppermissions, permissionSuccess } = useSelector(state => ({
    error: state.user.error,
    loading : state.user.loading,
    grouppermissions : state.user.grouppermissions,
    permissionSuccess : state.user.permissionSuccess

  }));


  useEffect(() => {
    setIsLoading(loading)
}, [loading]);

useEffect(() => {
  console.log(grouppermissions)
  if (grouppermissions && !grouppermissions.length) {
    dispatch(getGroupPermission());
    // setIsEdit(false);
  }else{
    const groupedPermissionsArr = grouppermissions.reduce((acc, item) => {
      const group = item.FLD_GROUP_NAME;
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(item);
      return acc;
    }, {});
  
    // console.log(groupedPermissionsArr)
  
    setGroupedPermissions(groupedPermissionsArr)
  }
}, [dispatch, grouppermissions]);

useEffect(() => {
  setGroupPermission(grouppermissions);
}, [grouppermissions]);


const togglePermission = (permissionId) => {
  console.log('in toggle')
  const updatedPermissions = groupPermission.map(perm => {
    if (perm.FLD_PERMISSIONID === permissionId) {
      return { ...perm, FLD_ACCESS: perm.FLD_ACCESS === 1 ? 0 : 1 }; // Toggle FLD_ACCESS between 0 and 1
    }
    return perm;
  });
  // console.log(updatedPermissions)
  setGroupPermission(updatedPermissions);

  const groupedPermissionsArr = updatedPermissions.reduce((acc, item) => {
    const group = item.FLD_GROUP_NAME;
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(item);
    return acc;
  }, {});

  setGroupedPermissions(groupedPermissionsArr)

};

const SavePermissions = () =>{
  // console.log(groupPermission)
  dispatch(updateGroupPermission(groupPermission, navigate));

}

useEffect(() => {

  if(permissionSuccess != ''){
    Notiflix.Confirm.show(
      'Successfully Updated',
      'Group Permission has been successfully updated.',
      'Ok',
      function okCb() {
      
      },
      {
      },
      );
  }
  
}, [permissionSuccess]);


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Manage Users" breadcrumbItem="Access Setup" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
               <Row>  
               <Col lg='6'> <CardTitle className="h4 mb-4">Permission Group</CardTitle></Col>
                 <Col lg='6'>
                   <Button 
                   style={{float : 'right'}}
                      color="primary"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={()=>SavePermissions()}
                    >
                      Save Changes
                    </Button>
                    </Col>
               </Row>
                  {/* <Button color="primary" type="button" className="btn-sm">Add Fields</Button> */}
                  <div className="table-responsive">
                    {/* <Table className="table mb-0">
                      <thead>
                        <tr className="vertical-middle">
                          <th>#</th>
                          <th>Group Name</th>
                    
                          <th className="text-center">
                            MDR Dashboard
                            <div><p className="lh-sm font-400 mt-1 mb-0">
                              <a href="#" className="margin-custom-05">Select all</a>
                            </p>
                            </div>
                          </th>
                          <th className="text-center">
                            Recall Dashboard
                            <div><p className="lh-sm font-400 mt-1 mb-0">
                              <a href="#" className="margin-custom-05">Select all</a>
                            </p>
                            </div>
                          </th>
                          <th className="text-center">
                            Warning Letters
                            <div><p className="lh-sm font-400 mt-1 mb-0">
                              <a href="#" className="margin-custom-05">Select all</a>
                            </p>
                            </div>
                          </th>
                          <th className="text-center">
                            Smart Search
                            <div><p className="lh-sm font-400 mt-1 mb-0">
                              <a href="#" className="margin-custom-05">Select all</a>
                            </p>
                            </div>
                          </th>
                          <th className="text-center">
                            SmartIQ
                            <div><p className="lh-sm font-400 mt-1 mb-0">
                              <a href="#" className="margin-custom-05">Select all</a>
                            </p>
                            </div>
                          </th>
                       
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="vertical-middle">
                          <th scope="row">1</th>
                          <td>Corporate Group</td>
                        
                          
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>

                        </tr>
                        <tr className="vertical-middle">
                          <th scope="row">2</th>
                          <td>Public Group</td>
                       
                         
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>
                          <td className="text-center"><div className="round"><label className="checkbox m-0"><input type="checkbox" className="checkbox__input" /><span className="checkbox__inner"></span></label></div></td>

                        </tr>
                       
                      </tbody>
                    </Table> */}


<Table className="table mb-0">
      <thead>
        <tr className="vertical-middle">
          <th>#</th>
          <th>Group Name</th>
          {/* Dynamically generate column headers based on unique permission names */}
          {Array.from(new Set(grouppermissions.map(p => p.FLD_NAME))).map((permission, index) => (
            <th className="text-center" key={index}>
              {/* {console.log(permission)} */}
              {permission} 
              {/* Optionally add "Select all" links here */}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {/* Map over groups to create rows */}
        {Object.entries(groupedPermissions).map(([groupName, permissions], groupIndex) => (
          <tr className="vertical-middle" key={groupIndex}>
            <th scope="row">{groupIndex + 1}</th>
            <td>{groupName} Group</td> 
            {/* Dynamically generate checkboxes based on permissions */}
            {permissions.map((permission, permIndex) => (
              <td className="text-center" key={permIndex}>
                <div className="round">
                  <label className="checkbox m-0">
                    <input type="checkbox" className="checkbox__input" 
                    checked={permission.FLD_ACCESS || false}
                    onClick={() => togglePermission(permission.FLD_PERMISSIONID)}
                    />
                    <span className="checkbox__inner"></span>
                  </label>
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Loader isLoading={isLoading} /> 
    </React.Fragment>
  )
}

export default ManageGroupAccess

import React, { useEffect, useState } from "react"
import CarouselPage from "./CarouselPageRegister"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import { Col, Container, Row } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import { verifyUserEmailData } from "store/actions"


const EmailVerification = () => {
    //meta title
    document.title="Email Verification | Smarteeva";

    const navigation = useNavigate()
  const dispatch = useDispatch();
    const [redirectCount, setRedirectCount] = useState(3)

    useEffect(()=>{

      setInterval(() => {
        setRedirectCount(redirectCount-1)
      }, 1000);
    },[redirectCount])

    useEffect(()=>{

      setTimeout(()=>{
        navigation('/login')
      },3000)
    },[])

    useEffect(()=>{
      const search = window.location.search;
const params = new URLSearchParams(search);
const username = params.get('username');
const code = params.get('code');
const clientid = params.get('clientId');
const data = {
  username : username,
  code : code,
  clientid : clientid
}
dispatch(verifyUserEmailData(data))
    },[])

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

             
            <Col cl={3}>
              <div className="auth-full-page-content p-md-5 p-4 centerJustCustom">
                <div className="w-100 maxWidthCustom">
                  <div className="d-flex flex-column h-100 centerJustCustom">
                  <div className="text-center">
                  <h5 className="pb-1 mb-5" style={{color : 'green'}}>Redirecting to login in...{redirectCount}</h5>
                  </div>
                    <div className="mb-1 mb-md-3 centerCustom">
                      <a href="/" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="70"
                          className="logo-dark-element"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="70"
                          className="logo-light-element"
                        />
                      </a>
                    </div>
                    <div className="">
                      <div className="text-center">
                        {/* <div className="avatar-md mx-auto">
                          <div className="avatar-title rounded-circle bg-light">
                            <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                          </div>
                        </div> */}
                        <div className="p-2 mt-4">
                          <h4 className="pb-1">Verification Success!</h4>
                          <p className="pb-4">
                           Thank you for your support,{" "}
                           
                              We have successfully verified your email address.
                            </p>
                          <div className="mt-4 d-grid">
                            <a
                              href="/login"
                              className="btn btn-primary btn-block padding-custom"
                            >
                              Login
                              </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Skote. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                          Themesbrand
                        </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EmailVerification;

import {
    API_SUCCESS,
    API_FAIL,
    GET_CHARTS_DATA,
    GET_USER_CHARTS_DATA,
    GET_MDR_SUBMIT_DATA,
    GET_MDR_STATS_DATA,
    GET_MDR_STATS_DATA_YEAR,
    SET_LOADING_INDICATOR_DASHBOARD
} from "./actionTypes";

const INIT_STATE = {
    chartsData: [],
    userChartData: [],
    mdrSubmitData: [],
    mdrStatsData: [],
    mdrStatsDataYear: [],
    error: '',
    loading : false
};

const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_SUCCESS:
            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state,
                        chartsData: action.payload.data,
                     
                    };
                case GET_USER_CHARTS_DATA:
                    return {
                        ...state,
                        userChartData: action.payload.data,
                        
                    };
                case GET_MDR_SUBMIT_DATA:
                    return {
                        ...state,
                        mdrSubmitData: action.payload.data,
                      
                    };
                case GET_MDR_STATS_DATA:
                    return {
                        ...state,
                        mdrStatsData: action.payload.data,
                        loading : false

                        
                    };
                case GET_MDR_STATS_DATA_YEAR:
                    return {
                        ...state,
                        mdrStatsDataYear: action.payload.data,
                   loading : false
                    };

                      case SET_LOADING_INDICATOR_DASHBOARD:
                state = {
                  ...state,
                  loading: action.payload.data,
                }
                break
               
                default:
                    return state;
            }
        case API_FAIL:
            switch (action.payload.actionType) {
                case GET_CHARTS_DATA:
                    return {
                        ...state,
                        chartsDataError: action.payload.error,
                        loading : false
                 
                    };
                case GET_USER_CHARTS_DATA:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading : false
                    };
                case GET_MDR_SUBMIT_DATA:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading : false
                    };
                case GET_MDR_STATS_DATA:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading : false
                    };
                case GET_MDR_STATS_DATA_YEAR:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading : false
                    };
                
                    case SET_LOADING_INDICATOR_DASHBOARD:
                        state = {
                          ...state,
                          loading: action.payload.data,
                        }
                        break
                default:
                    return state;
            }
            case SET_LOADING_INDICATOR_DASHBOARD:
                // console.log(action.payload)
                state = {
                  ...state,
                  loading: action.payload,
                }
            //     break
        default:
            return state;
    }
}


export default Dashboard;
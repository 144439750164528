import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { CHANGE_PASSWORD, RESET_PASSWORD } from "./actionTypes"
import { userResetPasswordSuccess, userResetPasswordError, onChangePasswordSuccess, onChangePasswordError, setLoadingIndicatorPassword } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeForgetPwd,
  postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper"
import { Auth } from 'aws-amplify';


const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* resetUser({ payload: { user, history } }) {

  yield put(setLoadingIndicatorPassword(true));

  try {
    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(fireBaseBackend.forgetPassword, user.email)
    //   if (response) {
    //     yield put(
    //       userForgetPasswordSuccess(
    //         "Reset link are sended to your mailbox, check there first"
    //       )
    //     )
    //   }
    // } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
    //   const response = yield call(postJwtForgetPwd, "/jwt-forget-pwd", {
    //     email: user.email,
    //   })
    //   if (response) {
    //     yield put(
    //       userForgetPasswordSuccess(
    //         "Reset link are sended to your mailbox, check there first"
    //       )
    //     )
    //   }
    // } else {
    //   const response = yield call(postFakeForgetPwd, "/fake-forget-pwd", {
    //     email: user.email,
    //   })
    //   if (response) {
    //     yield put(
    //       userForgetPasswordSuccess(
    //         "Reset link are sended to your mailbox, check there first"
    //       )
    //     )
    //   }
    // }

    // console.log(user)
    const resetResponse =  yield Auth.forgotPasswordSubmit(
      user.email,
      user.otp,
      user.password
    )
    // console.log(resetResponse)
  yield put(setLoadingIndicatorPassword(false));

    yield put(userResetPasswordSuccess(resetResponse))
  } catch (error) {
    // console.log(error)
    yield put(userResetPasswordError(error.message))
  }
}


function* changePasswordUser({ payload: { user, history } }) {

  yield put(setLoadingIndicatorPassword(true));

  try {
   
    const user_data = yield Auth.currentAuthenticatedUser()
    // console.log(user)
    const resetResponse =  yield Auth.changePassword(
      user_data,
      user.oldpassword,
      user.password
    )
    console.log(resetResponse)
    yield put(onChangePasswordSuccess(resetResponse))
  } catch (error) {
    console.log(error)
    yield put(onChangePasswordError(error.message))
  }
}

export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD, resetUser)
  yield takeEvery(CHANGE_PASSWORD, changePasswordUser)

}

function* resetPasswordSaga() {
  yield all([fork(watchUserPasswordReset)])
}

export default resetPasswordSaga

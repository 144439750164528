import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { isEmpty } from "lodash";
import '../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../../components/Common/TableContainerMdr';
import * as Yup from "yup";
import { useFormik } from "formik";
import { jobs } from "../../../../common/data";
import classnames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
//import components
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import DeleteModal from '../../../../components/Common/DeleteModal';
import Select from 'react-select';
import ReactApexChart from "react-apexcharts";


// import {
//     getJobList as onGetJobList,
//     addNewJobList as onAddNewJobList,
//     updateJobList as onUpdateJobList,
//     deleteJobList as onDeleteJobList,
// } from "store/actions";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
//redux
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  FormGroup,
  CardTitle
} from "reactstrap";
import QueryBuilder from "../../Smart-Query/QueryBuilder";
import { useLocation } from 'react-router-dom';
import {
  getSearchFieldList as onGetSearchFieldList,
  getMedicalSplList as onGetMedicalSplList,
  getDeviceProbCodeList as onGetDeviceProbCodeList,
  getPatientProbCodeList as onGetPatientProbCodeList,
  saveSmartQuery as onSaveSmartQuery,
  executeSmartQuery as onExecuteSmartQuery,
  resetQueryResult,
  getMdrList as onGetMdrList,
  getPatientProbCodeMdrList as onGetPatientProbCodeMdrList,
} from "store/actions";
import { authUser } from "helpers/dataConfig";
import moment from "moment";
import Loader from "components/Common/Loader";
import LineChart from '../../../../assets/images/line-icon.png'
import PieChart from '../../../../assets/images/pie-icon.png'
import BarChart from '../../../../assets/images/bar-icon.png'
import DonutChart from '../../../../assets/images/donut-icon.png'
import getChartColorsArray from "../../../../components/Common/ChartsDynamicColor";
import CardWithWatermark from "components/Common/Watermark";

function GenerateChartsOffCanvas(props) {

  //meta title
  document.title = "Generate Charts | Smarteeva";

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  const dataColors = '["--bs-primary","--bs-primary", "--bs-success"]'

  const spineareaChartColors = getChartColorsArray(dataColors);

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [jobsList, setJobsList] = useState([]);
  // const [job, setJob] = useState(null);
  const [mdrList, setMdrList] = useState([]);


  const [isRight, setIsRight] = useState(false);

  const [searchableFieldList, setSearchableFieldList] = useState([]);

  const [medicalSplList, setMedicalSplList] = useState([]);
  const [deviceProblemCodeList, setDeviceProblemCodeList] = useState([]);
  const [patientProblemCodeList, setPatientProblemCodeList] = useState([]);
  const [queryType, setQueryType] = useState('');
  const [noDataModalVisible, setNoDataModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  const [selectedThumbnail, setSelectedThumbnail] = useState('');
  const [generatedCharts, setGeneratedCharts] = useState([]);
  const [fieldSelected, setFieldSelected] = useState('')

  const [query_details, setQueryDetails] = useState('')


  const [thumbnails, setThumbnails] = useState([
    {chart : LineChart, title : 'Line'},
    {chart :PieChart, title : 'Pie'},
    {chart :DonutChart, title : 'Donut'},
    {chart :BarChart, title : 'Bar'}
  ]);

  const [groupByField, setGroupByField] = useState([
    {label : 'Event Type', value : 'EVENT_TYPE'},
    {label : 'Manufacturer D Name', value : 'MANUFACTURER_D_NAME'},
    {label : 'Device Report Product Code', value : 'DEVICE_REPORT_PRODUCT_CODE'},
    {label : 'Submission Type', value : 'SUBMISSION_TYPE'},
    {label : 'Unclassified Reason', value : 'UNCLASSIFIED_REASON'},
    {label : 'Medical Specialty', value : 'MEDICAL_SPECIALTY'},

  ]);
  
  const [monthsData, setMonthsData] = useState([]);
  const [yearsData, setYearsData] = useState([]);
  const [lineConfigData, setLineConfigData] = useState({
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: true,
      },
    },
    // colors: apexCardUserChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "solid",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.55,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: ([] || []),
      labels: {
        style: {
          fontSize: '10px',
          fontWeight: 500,
        },
      }
    },

    markers: {
      size: 1,
      strokeWidth: 0,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  });
  const [barConfigData, setBarConfigData] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // top, center, bottom
        },
        // borderRadius: 10,
        columnWidth: '50%',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.ceil((val/total)*100) + "%"
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    colors: spineareaChartColors,
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [],
      position: "bottom",
      labels: {
        offsetY: -5,
        rotate: -55,
          style: {
            fontSize: '10px',
            fontWeight: 500,
          },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "solid",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
        offsetY: -35,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val
        },
      },
    },
    // title: {
    //   text: "Total MDR based on Medical Specialty",
    //   floating: true,
    //   offsetY: 330,
    //   align: "center",
    //   style: {
    //     color: "#444",
    //   },
    // },
  });
  const [pieConfigData, setPieConfigData] = useState({
    labels: [],
    colors: spineareaChartColors,
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  });
  const [donutConfigData, setDonutConfigData] = useState([]);

  const [finalChartData, setFinalChartData] = useState([]);

  const { error, loading } = useSelector(state => ({
    error: state.SmartQueryReducer.error,
    loading: state.SmartQueryReducer.loading
  }));


  useEffect(() => {
    setIsLoading(loading)
  }, [loading]);

  useEffect(() => {
  
    let chartData = props.mdrData

    // console.log(chartData)
    setMdrList(chartData)

    // getMonths()
    // getYears()

  }, []);

  // useEffect(() => {
  
  //   setIsRight(props.isRight)

  // }, [isRight]);

  const getMonths = () =>{

    let theMonths = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
let now = new Date();
let dts = [] 

for (var i = 0; i < 24; i++) {
  var future = new Date(now.getFullYear(), now.getMonth() - i, 1);
  var month = theMonths[future.getMonth()];
  var year = future.getFullYear();
//   console.log(moment(month, 'MMMM').format('MMM'));
    dts.push(moment(month, 'MMMM').format('MMM')+'/'+year)
}
// console.log(dts.reverse())
setMonthsData(dts.reverse())
  }

  const getYears = () =>{
    var max = new Date().getFullYear()
    var min = max - 9
    var years = []
  
    for (var i = max; i >= min; i--) {
      years.push(i)
    }
    // console.log(years)
    setYearsData(years.reverse())
  }


  const toggleRightCanvas = () => {
    setIsRight(!isRight);

  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: '',
      field: '',
      date: '',
      type: '',
      url : '',
      groupby : ''

    },
    validationSchema: Yup.object({
        title: Yup.string().required("Please enter chart title"),
        groupby: Yup.string().required("Please select group by"),
        // field: Yup.string().required("Please select group by field"),
        field: Yup.string().when('groupby', {
            is: 'Field',
            then: Yup.string().required("Please select group by field"),
            // otherwise: Yup.string().required("Please select group by date received"),
          }),
        date: Yup.string().when('groupby', {
            is: 'DateRec',
            then: Yup.string().required("Please select group by date received"),
            // otherwise: Yup.string().required("Please select group by field"),
          }),
        type: Yup.string().required("Please select chart"),
        
    }),
    onSubmit: (values, { resetForm }) => {

        // console.log(values)

        let charts = [...generatedCharts]
        charts.push(values)

        setGeneratedCharts(charts)

        setThumbnails([
            {chart : LineChart, title : 'Line'},
            {chart :PieChart, title : 'Pie'},
            {chart :DonutChart, title : 'Donut'},
            {chart :BarChart, title : 'Bar'}
          ])
          setFieldSelected('')
          setSelectedThumbnail('')
          validation.setFieldValue('date', '')
          validation.setFieldValue('type', '')
          validation.setFieldValue('url', '')
          validation.setFieldValue('groupby', '')


        resetForm()

    
    },
  });

 

  const toggle = () => {
    if (modal) {
      setModal(false);
      setJob(null);
    } else {
      setModal(true);
    }
  };

  

const alterThumbnails = () =>{
setThumbnails([
    {chart : LineChart, title : 'Line'},
    {chart :BarChart, title : 'Bar'}
  ])
}

const resetThumbnails = () =>{
     setThumbnails([
        {chart : LineChart, title : 'Line'},
        {chart :PieChart, title : 'Pie'},
        {chart :DonutChart, title : 'Donut'},
        {chart :BarChart, title : 'Bar'}
      ])
}

const getMonthyMdr = () =>{
    // console.log('monthly mdr')
    let counts = Array(monthsData.length).fill(0)

    // console.log(counts)
    for(let i=0;i<monthsData.length;i++){
        for(let j=0;j<mdrList.length;j++){
            // console.log(moment(mdrList[j].DATE_RECEIVED,'MM/DD/YYYY').format('MMM/YYYY'))
            if(moment(mdrList[j].DATE_RECEIVED,'MM/DD/YYYY').format('MMM/YYYY') == monthsData[i]){
                counts[i]++
            }
        }
    }

    // console.log(counts)
    return counts
}
 

const getYearlyMdr = () =>{
    console.log('yearly mdr')
    let counts = Array(yearsData.length).fill(0)

    // console.log(counts)
    for(let i=0;i<yearsData.length;i++){
        for(let j=0;j<mdrList.length;j++){
            // console.log(moment(mdrList[j].DATE_RECEIVED,'MM/DD/YYYY').format('YYYY'))
            if(moment(mdrList[j].DATE_RECEIVED,'MM/DD/YYYY').format('YYYY') == yearsData[i]){
                counts[i]++
            }
        }
    }

    // console.log(counts)
    return counts
}


const getFieldData = (data) =>{

    let xData

    if(data.field == 'EVENT_TYPE'){

     xData = [...new Set(mdrList.filter(item => item.EVENT_TYPE !== null).map(item => item.EVENT_TYPE))];
     console.log(xData)
    

    }else if(data.field == 'MANUFACTURER_D_NAME'){

        xData = [...new Set(mdrList.filter(item => item.MANUFACTURER_D_NAME !== null).map(item => item.MANUFACTURER_D_NAME))];
        // console.log(xData)

    }else if(data.field == 'DEVICE_REPORT_PRODUCT_CODE'){

        xData = [...new Set(mdrList.filter(item => item.DEVICE_REPORT_PRODUCT_CODE !== null).map(item => item.DEVICE_REPORT_PRODUCT_CODE))];
        // console.log(xData)

    }else if(data.field == 'SUBMISSION_TYPE'){

        xData = [...new Set(mdrList.filter(item => item.SUBMISSION_TYPE !== null).map(item => item.SUBMISSION_TYPE))];
        // console.log(xData)

    }else if(data.field == 'UNCLASSIFIED_REASON'){

        xData = [...new Set(mdrList.filter(item => item.UNCLASSIFIED_REASON !== null).map(item => item.UNCLASSIFIED_REASON))];
        // console.log(xData)

    }else if(data.field == 'MEDICAL_SPECIALTY'){

        xData = [...new Set(mdrList.filter(item => item.MEDICAL_SPECIALTY !== null).map(item => item.MEDICAL_SPECIALTY))];
        // console.log(xData)

    }
    return xData;
}

const getFieldMdrValues = (data, xdata) =>{
    let counts = Array(xdata.length).fill(0)



    for(let i=0;i<xdata.length;i++){
        for(let j=0;j<mdrList.length;j++){

 if(data.field == 'EVENT_TYPE'){

    if(mdrList[j].EVENT_TYPE == xdata[i] ){
        counts[i]++
    }
  
    }else if(data.field == 'MANUFACTURER_D_NAME'){

        if(mdrList[j].MANUFACTURER_D_NAME == xdata[i] ){
            counts[i]++
        }

    }else if(data.field == 'DEVICE_REPORT_PRODUCT_CODE'){

        if(mdrList[j].DEVICE_REPORT_PRODUCT_CODE == xdata[i] ){
            counts[i]++
        }

    }else if(data.field == 'SUBMISSION_TYPE'){

        if(mdrList[j].SUBMISSION_TYPE == xdata[i] ){
            counts[i]++
        }

     
    }else if(data.field == 'UNCLASSIFIED_REASON'){

        if(mdrList[j].UNCLASSIFIED_REASON == xdata[i] ){
            counts[i]++
        }

    
    }else if(data.field == 'MEDICAL_SPECIALTY'){

        if(mdrList[j].MEDICAL_SPECIALTY == xdata[i] ){
            counts[i]++
        }

    }
}
    }

    return counts;

}

const generateVisualCharts = () =>{

  // console.log(mdrList)
  localStorage.removeItem('ChartsData')
  console.log(props.queryData)
  navigate('/mdr-charts', {state : {chartData : generatedCharts, mdrListData : mdrList, queryData : props.queryData}})

    // console.log(generatedCharts)
    // let genCharts = []

    // for(let i =0;i<generatedCharts.length;i++){
    //     if(generatedCharts[i].date != ''){
    //         if(generatedCharts[i].date == 'Month'){

    //            let mdrCnt = getMonthyMdr()

    //         //    console.log(mdrCnt)

    //             if(generatedCharts[i].type == 'Bar'){

                    
    //             // monthly mdr bar
    //             genCharts.push(
    //             <Col sm="12">
    //             <Card>
    //               <CardBody>
    //                 <div className="d-flex align-items-center mb-2">
                    
    //                 <h5 className="card-title me-2">{generatedCharts[i].title}</h5>
    //                 </div>
    //                 <div className="text-muted mt-4"  >
                    
    //             <ReactApexChart
    //               options={{
    //                 chart: {
    //                   height: 550,
    //                   type: "bar",
    //                   toolbar: {
    //                     show: true,
    //                   },
    //                 },
    //                 colors: spineareaChartColors,
    //                 dataLabels: {
    //                   enabled: false,
    //                 },
    //                 stroke: {
    //                   curve: "smooth",
    //                   width: 2,
    //                 },
    //                 fill: {
    //                   type: "solid",
    //                   gradient: {
    //                     shadeIntensity: 1,
    //                     inverseColors: false,
    //                     opacityFrom: 0.7,
    //                     opacityTo: 0.05,
    //                     stops: [20, 100, 100, 100],
    //                   },
    //                 },
    //                 xaxis: {
    //                   categories: (monthsData|| []),
    //                   labels: {
    //                     style: {
    //                       fontSize: '10px',
    //                       fontWeight: 500,
    //                     },
    //                   }
    //                 },
                
    //                 markers: {
    //                   size: 1,
    //                   strokeWidth: 0,
                
    //                   hover: {
    //                     size: 4,
    //                     sizeOffset: 2,
    //                   },
    //                 },
    //                 legend: {
    //                   position: "top",
    //                   horizontalAlign: "center",
    //                   showForSingleSeries: true
    //                 },
    //               }}
    //               series={[
    //                 {
    //                   name: 'Mdr Count',
    //                   data: (mdrCnt || []),
    //                 }
    //               ]}
    //               type="bar"
    //               height={350}
    //               className="apex-charts"
                 
    //             />
    //            </div>
    //            <CardWithWatermark />

    //         </CardBody>
    //       </Card>
    //     </Col>)


    //             }else{
    //                      // monthly mdr line

    //                      genCharts.push(
    //                         <Col sm="12">
    //                         <Card>
    //                           <CardBody>
    //                             <div className="d-flex align-items-center mb-2">
                                
    //                             <h5 className="card-title me-2">{generatedCharts[i].title}</h5>
    //                             </div>
    //                             <div className="text-muted mt-4"  >
                                
    //                         <ReactApexChart
    //                           options={{
    //                             chart: {
    //                               height: 550,
    //                               type: "area",
    //                               toolbar: {
    //                                 show: true,
    //                               },
    //                             },
    //                             colors: spineareaChartColors,
    //                             dataLabels: {
    //                               enabled: false,
    //                             },
    //                             stroke: {
    //                               curve: "smooth",
    //                               width: 2,
    //                             },
    //                             fill: {
    //                               type: "gradient",
    //                               gradient: {
    //                                 shadeIntensity: 1,
    //                                 inverseColors: false,
    //                                 opacityFrom: 0.7,
    //                                 opacityTo: 0.05,
    //                                 stops: [20, 100, 100, 100],
    //                               },
    //                             },
    //                             xaxis: {
    //                               categories: (monthsData|| []),
    //                               labels: {
    //                                 style: {
    //                                   fontSize: '10px',
    //                                   fontWeight: 500,
    //                                 },
    //                               }
    //                             },
                            
    //                             markers: {
    //                               size: 1,
    //                               strokeWidth: 0,
                            
    //                               hover: {
    //                                 size: 4,
    //                                 sizeOffset: 2,
    //                               },
    //                             },
    //                             legend: {
    //                                 position: "top",
    //                                 horizontalAlign: "center",
    //                                 showForSingleSeries: true
    //                               },
    //                           }}
    //                           series={[
    //                             {
    //                               name: 'Mdr Count',
    //                               data: mdrCnt,
    //                             }
    //                           ]}
    //                           type="area"
    //                           height={350}
    //                           className="apex-charts"
                             
    //                         />
    //                        </div>
    //                        <CardWithWatermark />
    //                     </CardBody>
    //                   </Card>
    //                 </Col>)

                         
    //             }

    //         }else{
    //             //yearly mdr
    //             let mdrYearCnt = getYearlyMdr()

    //             if(generatedCharts[i].type == 'Bar'){

                    
    //                 // monthly mdr bar
    //                 genCharts.push(
    //                 <Col sm="12">
    //                 <Card>
    //                   <CardBody>
    //                     <div className="d-flex align-items-center mb-2">
                        
    //                     <h5 className="card-title me-2">{generatedCharts[i].title}</h5>
    //                     </div>
    //                     <div className="text-muted mt-4"  >
                        
    //                 <ReactApexChart
    //                   options={{
    //                     chart: {
    //                       height: 550,
    //                       type: "bar",
    //                       toolbar: {
    //                         show: true,
    //                       },
    //                     },
    //                     colors: spineareaChartColors,
    //                     dataLabels: {
    //                       enabled: false,
    //                     },
    //                     stroke: {
    //                       curve: "smooth",
    //                       width: 2,
    //                     },
    //                     fill: {
    //                       type: "solid",
    //                       gradient: {
    //                         shadeIntensity: 1,
    //                         inverseColors: false,
    //                         opacityFrom: 0.7,
    //                         opacityTo: 0.05,
    //                         stops: [20, 100, 100, 100],
    //                       },
    //                     },
    //                     xaxis: {
    //                       categories: (yearsData|| []),
    //                       labels: {
    //                         style: {
    //                           fontSize: '10px',
    //                           fontWeight: 500,
    //                         },
    //                       }
    //                     },
                    
    //                     markers: {
    //                       size: 1,
    //                       strokeWidth: 0,
                    
    //                       hover: {
    //                         size: 4,
    //                         sizeOffset: 2,
    //                       },
    //                     },
    //                     legend: {
    //                         position: "top",
    //                         horizontalAlign: "center",
    //                         showForSingleSeries: true
    //                       },
    //                   }}
    //                   series={[
    //                     {
    //                       name: 'Mdr Count',
    //                       data: (mdrYearCnt || []),
    //                     }
    //                   ]}
    //                   type="bar"
    //                   height={350}
    //                   className="apex-charts"
                     
    //                 />
    //                </div>
    //                <CardWithWatermark />
    //             </CardBody>
    //           </Card>
    //         </Col>)
    
    
    //                 }else{
    //                          // monthly mdr line
    
    //                          genCharts.push(
    //                             <Col sm="12">
    //                             <Card>
    //                               <CardBody>
    //                                 <div className="d-flex align-items-center mb-2">
                                    
    //                                 <h5 className="card-title me-2">{generatedCharts[i].title}</h5>
    //                                 </div>
    //                                 <div className="text-muted mt-4"  >
                                    
    //                             <ReactApexChart
    //                               options={{
    //                                 chart: {
    //                                   height: 550,
    //                                   type: "area",
    //                                   toolbar: {
    //                                     show: true,
    //                                   },
    //                                 },
    //                                 colors: spineareaChartColors,
    //                                 dataLabels: {
    //                                   enabled: false,
    //                                 },
    //                                 stroke: {
    //                                   curve: "smooth",
    //                                   width: 2,
    //                                 },
    //                                 fill: {
    //                                   type: "gradient",
    //                                   gradient: {
    //                                     shadeIntensity: 1,
    //                                     inverseColors: false,
    //                                     opacityFrom: 0.7,
    //                                     opacityTo: 0.05,
    //                                     stops: [20, 100, 100, 100],
    //                                   },
    //                                 },
    //                                 xaxis: {
    //                                   categories: (yearsData|| []),
    //                                   labels: {
    //                                     style: {
    //                                       fontSize: '10px',
    //                                       fontWeight: 500,
    //                                     },
    //                                   }
    //                                 },
                                
    //                                 markers: {
    //                                   size: 1,
    //                                   strokeWidth: 0,
                                
    //                                   hover: {
    //                                     size: 4,
    //                                     sizeOffset: 2,
    //                                   },
    //                                 },
    //                                 legend: {
    //                                     position: "top",
    //                                     horizontalAlign: "center",
    //                                     showForSingleSeries: true
    //                                   },
    //                               }}
    //                               series={[
    //                                 {
    //                                   name: 'Mdr Count',
    //                                   data: (mdrYearCnt || []),
    //                                 }
    //                               ]}
    //                               type="area"
    //                               height={350}
    //                               className="apex-charts"
                                 
    //                             />
    //                            </div>
    //                            <CardWithWatermark />
    //                         </CardBody>
    //                       </Card>
    //                     </Col>)
    
                             
    //                 }

    //         }
    //     }else{

    //         // field data

    //        let xdata = getFieldData(generatedCharts[i])
    //     //    console.log(xdata)
    //        let serData = getFieldMdrValues(generatedCharts[i],xdata)
    //     //    console.log(serData)

    //        if(generatedCharts[i].type == 'Bar'){

                    
    //         // monthly mdr bar
    //         genCharts.push(
    //         <Col sm="12">
    //         <Card>
    //           <CardBody>
    //             <div className="d-flex align-items-center mb-2">
                
    //             <h5 className="card-title me-2">{generatedCharts[i].title}</h5>
    //             </div>
    //             <div className="text-muted mt-4"  >
                
    //         <ReactApexChart
    //           options={{
    //             chart: {
    //               height: 550,
    //               type: "bar",
    //               toolbar: {
    //                 show: true,
    //               },
    //             },
    //             colors: spineareaChartColors,
    //             dataLabels: {
    //               enabled: false,
    //             },
    //             stroke: {
    //               curve: "smooth",
    //               width: 2,
    //             },
    //             fill: {
    //               type: "solid",
    //               gradient: {
    //                 shadeIntensity: 1,
    //                 inverseColors: false,
    //                 opacityFrom: 0.7,
    //                 opacityTo: 0.05,
    //                 stops: [20, 100, 100, 100],
    //               },
    //             },
    //             xaxis: {
    //               categories: (xdata|| []),
    //               labels: {
    //                 style: {
    //                   fontSize: '10px',
    //                   fontWeight: 500,
    //                 },
    //               }
    //             },
            
    //             markers: {
    //               size: 1,
    //               strokeWidth: 0,
            
    //               hover: {
    //                 size: 4,
    //                 sizeOffset: 2,
    //               },
    //             },
    //             legend: {
    //                 position: "top",
    //                 horizontalAlign: "center",
    //                 showForSingleSeries: true
    //               },
    //           }}
    //           series={[
    //             {
    //               name: 'Mdr Count',
    //               data: (serData || []),
    //             }
    //           ]}
    //           type="bar"
    //           height={350}
    //           className="apex-charts"
             
    //         />
    //        </div>
    //        <CardWithWatermark />
    //     </CardBody>
    //   </Card>
    // </Col>)


    //         }else if(generatedCharts[i].type == 'Line'){
    //                  // monthly mdr line

    //                  genCharts.push(
    //                     <Col sm="12">
    //                     <Card>
    //                       <CardBody>
    //                         <div className="d-flex align-items-center mb-2">
                            
    //                         <h5 className="card-title me-2">{generatedCharts[i].title}</h5>
    //                         </div>
    //                         <div className="text-muted mt-4"  >
                            
    //                     <ReactApexChart
    //                       options={{
    //                         chart: {
    //                           height: 550,
    //                           type: "area",
    //                           toolbar: {
    //                             show: true,
    //                           },
    //                         },
    //                         colors: spineareaChartColors,
    //                         dataLabels: {
    //                           enabled: false,
    //                         },
    //                         stroke: {
    //                           curve: "smooth",
    //                           width: 2,
    //                         },
    //                         fill: {
    //                           type: "gradient",
    //                           gradient: {
    //                             shadeIntensity: 1,
    //                             inverseColors: false,
    //                             opacityFrom: 0.7,
    //                             opacityTo: 0.05,
    //                             stops: [20, 100, 100, 100],
    //                           },
    //                         },
    //                         xaxis: {
    //                           categories: (xdata|| []),
    //                           labels: {
    //                             style: {
    //                               fontSize: '10px',
    //                               fontWeight: 500,
    //                             },
    //                           }
    //                         },
                        
    //                         markers: {
    //                           size: 1,
    //                           strokeWidth: 0,
                        
    //                           hover: {
    //                             size: 4,
    //                             sizeOffset: 2,
    //                           },
    //                         },
    //                         legend: {
    //                             position: "top",
    //                             horizontalAlign: "center",
    //                             showForSingleSeries: true
    //                           },
    //                       }}
    //                       series={[
    //                         {
    //                           name: 'Mdr Count',
    //                           data: (serData || []),
    //                         }
    //                       ]}
    //                       type="area"
    //                       height={350}
    //                       className="apex-charts"
                         
    //                     />
    //                    </div>
    //                    <CardWithWatermark />
    //                 </CardBody>
    //               </Card>
    //             </Col>)

                     
    //         }
    //         else if(generatedCharts[i].type == 'Pie'){


    //             genCharts.push(
    //                 <Col sm="6">
    //                 <Card>
    //                   <CardBody>
    //                     <div className="d-flex align-items-center mb-2">
                        
    //                     <h5 className="card-title me-2">{generatedCharts[i].title}</h5>
    //                     </div>
    //                     <div className="text-muted mt-4"  >
                        
    //                 <ReactApexChart
    //                   options={{
    //                     labels: xdata,
    //                     colors: [
    //                         "#FFB6C1", // Pastel Pink
    //                         "#FFD700", // Pastel Yellow
    //                         "#87CEEB", // Pastel Blue
    //                         "#98FB98", // Pastel Green
    //                         "#FFA07A", // Pastel Salmon
    //                         "#9370DB", // Pastel Purple
    //                         "#FFA500", // Pastel Orange
    //                         "#20B2AA", // Pastel Turquoise
    //                         "#FFE4B5", // Pastel Peach
    //                         "#B0E0E6", // Pastel Powder Blue
    //                         "#F0E68C", // Pastel Khaki
    //                         "#FF69B4", // Pastel Hot Pink
    //                         "#C0C0C0", // Pastel Gray
    //                         "#FFC0CB", // Pastel Rose
    //                         "#00CED1", // Pastel Dark Turquoise
    //                         "#DDA0DD", // Pastel Plum
    //                         "#F5DEB3", // Pastel Wheat
    //                         "#87CEFA", // Pastel Light Sky Blue
    //                         "#D8BFD8", // Pastel Thistle
    //                         "#E6E6FA"  // Pastel Lavender
    //                       ],
    //                     legend: {
    //                       show: true,
    //                       position: "bottom",
    //                       horizontalAlign: "center",
    //                       verticalAlign: "middle",
    //                       floating: false,
    //                       fontSize: "14px",
    //                       offsetX: 0,
    //                       offsetY: -10,
    //                     },
    //                     responsive: [
    //                       {
    //                         breakpoint: 600,
    //                         options: {
    //                           chart: {
    //                             height: 240,
    //                           },
    //                           legend: {
    //                             show: false,
    //                           },
    //                         },
    //                       },
    //                     ],
    //                   }}
    //                   series={serData}
    //                   type="pie"
    //                   height={500}
    //                   className="apex-charts"
                     
    //                 />
    //                </div>
    //                <CardWithWatermark />
    //             </CardBody>
    //           </Card>
    //         </Col>)
    //         }
    //         else if(generatedCharts[i].type == 'Donut'){

    //             genCharts.push(
    //                 <Col sm="6">
    //                 <Card>
    //                   <CardBody>
    //                     <div className="d-flex align-items-center mb-2">
                        
    //                     <h5 className="card-title me-2">{generatedCharts[i].title}</h5>
    //                     </div>
    //                     <div className="text-muted mt-4"  >
                        
    //                 <ReactApexChart
    //                   options={{
    //                     labels: xdata,
    //                     colors: [
    //                         "#FFB6C1", // Pastel Pink
    //                         "#FFD700", // Pastel Yellow
    //                         "#87CEEB", // Pastel Blue
    //                         "#98FB98", // Pastel Green
    //                         "#FFA07A", // Pastel Salmon
    //                         "#9370DB", // Pastel Purple
    //                         "#FFA500", // Pastel Orange
    //                         "#20B2AA", // Pastel Turquoise
    //                         "#FFE4B5", // Pastel Peach
    //                         "#B0E0E6", // Pastel Powder Blue
    //                         "#F0E68C", // Pastel Khaki
    //                         "#FF69B4", // Pastel Hot Pink
    //                         "#C0C0C0", // Pastel Gray
    //                         "#FFC0CB", // Pastel Rose
    //                         "#00CED1", // Pastel Dark Turquoise
    //                         "#DDA0DD", // Pastel Plum
    //                         "#F5DEB3", // Pastel Wheat
    //                         "#87CEFA", // Pastel Light Sky Blue
    //                         "#D8BFD8", // Pastel Thistle
    //                         "#E6E6FA"  // Pastel Lavender
    //                       ],
    //                     legend: {
    //                       show: true,
    //                       position: "bottom",
    //                       horizontalAlign: "center",
    //                       verticalAlign: "middle",
    //                       floating: false,
    //                       fontSize: "14px",
    //                       offsetX: 0,
    //                       offsetY: -10,
    //                     },
    //                     responsive: [
    //                       {
    //                         breakpoint: 600,
    //                         options: {
    //                           chart: {
    //                             height: 240,
    //                           },
    //                           legend: {
    //                             show: false,
    //                           },
    //                         },
    //                       },
    //                     ],
    //                   }}
    //                   series={serData}
    //                   type="donut"
    //                   height={350}
    //                   className="apex-charts"
                     
    //                 />
    //                </div>

    //                <CardWithWatermark />
    //             </CardBody>
    //           </Card>
    //         </Col>)
    //         }


    //     }
    // }
    // setFinalChartData(genCharts)
    // console.log(JSON.stringify(genCharts))
    // navigate('/mdr-charts', {state : JSON.stringify(genCharts)})
}

  return (
    <React.Fragment>
    
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="MDR Data" breadcrumbItem="MDR Reports" />

        

          {/* <Row>
          
          <Col xxl={12} lg={12} style={{float : 'right', marginBottom : 50}}>
            <Row style={{justifyContent : 'end'}}>
          <Col md="2" xs="4">
                      <Link className="btn btn-light me-3" style={{float : 'right'}}
                      onClick={()=>{
                       navigate(-1)
                      }}
                      > Back </Link>
                                            </Col>
                  <Col md="3" xs="8">                       
                <div className='mb-3 mb-xxl-0' style={{float : 'right'}}>
                    <button type="button" className="btn btn-primary" 
                    onClick={toggleRightCanvas}
                    >
                        <i className="mdi mdi-lightning-bolt align-middle me-1"></i> Generate Customised Reports</button>
                </div>
                </Col>
                </Row>
            </Col>
            

            <Col xxl={12} lg={12}>
                <Row>
            {finalChartData}
            </Row>
            </Col>
            </Row> */}
          
          <Offcanvas
            isOpen={props.isRight}
            direction="end"
            toggle={props.toggleChartCanvas}>
            <OffcanvasHeader toggle={props.toggleChartCanvas}>
              Customise Charts
            </OffcanvasHeader>
            <OffcanvasBody style={{marginBottom : 20}}>
              <div className="w-100 h-80vh" >
                <Card className="h-96">
                  <div className="p-4 border-bottom ">
                    <Row>
                      <Col md="12" xs="12">
                        <Col md="12" xs="12">
                          <Row className="flex-center">
                            <Col md="12" xs="12">
                              {/* <h4 className="m-0">
                                  Query Name 
                                </h4> */}
                              <FormGroup className="mb-3">
                                <Label>
                                 Title
                                </Label>

                               
                                <Input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter chart title"
                                  name="title"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.title || ""}
                                  invalid={
                                    validation.touched.title && validation.errors.title ? true : false
                                  }
                                />
                                {validation.touched.title && validation.errors.title ? (
                                  <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                ) : null}
                              </FormGroup> 

                            </Col>

                            <Col md="12" xs="12">
                              {/* <h4 className="m-0">
                                  Query Name 
                                </h4> */}
                              <FormGroup className="mb-3">
                                <Label>
                                  Select Group By
                                </Label>
                                <div className="row" style={{marginLeft : 10}}>
                                <div className="form-check form-radio-primary col-lg-6">
                            <input
                              type="radio"
                              id="customRadiocolor1"
                              className="form-check-input"
                              name="groupby"
                              onClick={()=>{
                                validation.setFieldValue('groupby', 'Field')
                                validation.setFieldError('groupby',false)
                                resetThumbnails()
                              }}
                              onBlur={validation.handleBlur}
                            //   value={validation.values.date || ""}
                              checked={validation.values.groupby == 'Field'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiocolor1"
                            >
                              Field Name
                            </label>
                          </div>
                               

                          <div className="form-check form-radio-primary col-lg-6">
                            <input
                              type="radio"
                              id="customRadiocolor2"
                            
                              className="form-check-input"
                              name="groupby"
                              onClick={()=>{
                                validation.setFieldValue('groupby', 'DateRec')
                                validation.setFieldError('groupby',false)
                                alterThumbnails()

                              }}
                              onBlur={validation.handleBlur}
                            //   value={validation.values.date || ""}
                              checked={validation.values.groupby == 'DateRec'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiocolor2"
                            >
                              Date Received
                            </label>
                          </div>
                               
                          </div>
                                {validation.touched.groupby && validation.errors.groupby ? (
                                  <FormFeedback type="invalid">{validation.errors.groupby}</FormFeedback>
                                ) : null}
                              </FormGroup> 

                              

                            </Col>

                            <Col md="12" xs="12" style={{display : validation.values.groupby == 'Field' ? '' : 'none'}}>
                              {/* <h4 className="m-0">
                                  Query Name 
                                </h4> */}
                              <FormGroup className="mb-3">
                                <Label>
                                  Group By Field
                                </Label>

                                <Select
                             
                                  isClearable
                                  isSearchable
                                  name={'field'}
                                  onChange={(e)=>{
                                    setFieldSelected(e)
                                    validation.setFieldValue('field', e.value)
                                    validation.setFieldError('field',false)
                                    resetThumbnails()
                                  }}
                                  onBlur={validation.handleBlur}
                                  options={groupByField}
                                  value={
                                    fieldSelected || ""
                                }
                                // formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.field && validation.errors.field ? 'invalid-select' : ''}
                             
                                />
                             
                                {validation.touched.field && validation.errors.field ? (
                                  <FormFeedback type="invalid">{validation.errors.field}</FormFeedback>
                                ) : null}
                              </FormGroup> 

                            </Col>

                            <Col md="12" xs="12" style={{display : validation.values.groupby == 'DateRec' ? '' : 'none'}}>
                              {/* <h4 className="m-0">
                                  Query Name 
                                </h4> */}
                              <FormGroup className="mb-3">
                                <Label>
                                  Group By Date Received
                                </Label>
                                <div className="row" style={{marginLeft : 10}}>
                                <div className="form-check form-radio-primary col-lg-4">
                            <input
                              type="radio"
                              id="customRadiocolor1"
                              className="form-check-input"
                              name="date"
                              onClick={()=>{
                                validation.setFieldValue('date', 'Month')
                                validation.setFieldError('date',false)
                                alterThumbnails()
                              }}
                              onBlur={validation.handleBlur}
                            //   value={validation.values.date || ""}
                              checked={validation.values.date == 'Month'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiocolor1"
                            >
                              Month
                            </label>
                          </div>
                               

                          <div className="form-check form-radio-primary col-lg-4">
                            <input
                              type="radio"
                              id="customRadiocolor2"
                            
                              className="form-check-input"
                              name="date"
                              onClick={()=>{
                                validation.setFieldValue('date', 'Year')
                                validation.setFieldError('date',false)
                                alterThumbnails()

                              }}
                              onBlur={validation.handleBlur}
                            //   value={validation.values.date || ""}
                              checked={validation.values.date == 'Year'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiocolor2"
                            >
                              Year
                            </label>
                          </div>
                               

                          {/* <div className="form-check form-radio-primary col-lg-4">
                            <input
                              type="radio"
                              id="customRadiocolor3"
                          
                              className="form-check-input"
                              name="date"
                              onClick={()=>{
                                validation.setFieldValue('date', 'None')
                                validation.setFieldError('date',false)
                                resetThumbnails()
                              }}
                              onBlur={validation.handleBlur}
                            //   value={validation.values.date || ""}
                              checked={validation.values.date == 'None'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiocolor3"
                            >
                              None
                            </label>
                          </div> */}
                               
                          </div>
                                {validation.touched.date && validation.errors.date ? (
                                  <FormFeedback type="invalid">{validation.errors.date}</FormFeedback>
                                ) : null}
                              </FormGroup> 

                              

                            </Col>

                            <Col md="12" xs="12" className="mb-3">
                              {/* <h4 className="m-0">
                                  Query Name 
                                </h4> */}
                              <FormGroup className="mb-3">
                                <Label>
                                  Select Chart
                                </Label>
                                <div className="row" style={{marginLeft : 10}}> 
                                {thumbnails.map((chart, index) =>(
                                    <div key={index} className="col-lg-3"
                                    style={{border : selectedThumbnail == chart.title ? '2px solid #369bff' :'1px solid #dcdcdc', marginRight : 20, height : 50, width : 50,
                                display : 'flex',
                                alignItems: 'center',
                            justifyContent: 'center',

                                }}
                                onClick={()=>{
                                    setSelectedThumbnail(chart.title)
                                validation.setFieldValue('type',chart.title)
                                validation.setFieldValue('url',chart.chart)

                                validation.setFieldError('type',false)

                                }}
                                    >
                                <img  style={{height : 40, width : 40}} 
                                src={chart.chart}
                                ></img>
                               </div>
                                ))}
                               </div>

                                
                                {validation.touched.type && validation.errors.type ? (
                                  <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                ) : null}
                              </FormGroup> 

                            </Col>

                   
                          </Row>
                        </Col>
                        <Col xxl={6} lg={6} style={{float : 'right'}}>
           
           <button type="button" className="btn btn-primary w-100" 
           onClick={()=>{
            validation.submitForm()
           }}
           >
           Add Chart</button>

   </Col>
                       
                      </Col>
                    </Row>
                  </div>

                  {/* <div className="m-4">
                    <Col md="12" xs="12">
                      <div id="query-builder-container" className="sideSection">
                        <QueryBuilder
                          searchableFieldList={searchableFieldList}
                          conditionList={conditionList}
                          conditionList2={conditionList2}
                          conditionList3={conditionList3}
                          groupConditionList={groupConditionList}
                          medicalSplList={medicalSplList}
                          deviceProblemCodeList={deviceProblemCodeList}
                          patientProblemCodeList={patientProblemCodeList}
                          handleLogic={handleLogic}
                          handleDisplayLogic={handleDisplayLogic}

                        />
                      </div>
                    </Col>
                  </div> */}

                  {/* <Col md="12" xs="12" className="mb-3 flex-end">
                    <div className="btn-group buttongroupCorrection">
                      <button type="button" className="btn btn-outline-primary flex-center"
                        onClick={() => {
                          setQueryType('Recent')
                          validation.handleSubmit();
                        }}>
                        <i className="mdi mdi-play font-size-20 pr-07-icon" />
                        <span className="pl-7-text">Run Query</span></button>

                      <button type="button" className="btn btn-outline-primary flex-center"
                        onClick={() => {
                          setQueryType('Save')
                          validation.handleSubmit();
                        }}
                      >
                        <i className="mdi mdi-content-save font-size-20 pr-07-icon" />
                        <span className="pl-7-text">Save</span></button>
                     
                    </div>
                  </Col> */}


                </Card>

                  {generatedCharts.map((chrt,index)=>(
                    <Card className="h-96" key={index}>
                    <div className="p-3 border-bottom ">
                        <Label style={{fontSize : 15}}>{chrt.title} </Label><span 
                        style={{float : 'right'}}
                        onClick={()=>{
                            let grp = [...generatedCharts]
                            grp.splice(index,1)
                            setGeneratedCharts(grp)
                        }}
                        > <i className="mdi mdi-close font-size-15 " /></span>

                        <Col md="12" xs="12">
                            <Row>
                        <Col lg='12'><Label>Group By :</Label><span> {chrt.groupby == 'Field' ? 'Field' : 'Date Recieved'}</span></Col>
                        </Row>
                            </Col>

                        <Col md="12" xs="12" style={{display : chrt.groupby == 'Field' ? '' : 'none'}}>
                            <Row>
                        <Col lg='12'><Label>Group By Field :</Label><span> {chrt.field}</span></Col>
                        </Row>
                            </Col>

                            <Col md="12" xs="12" style={{display : chrt.groupby == 'Field' ? 'none' : ''}}>
                            <Row>
                            <Col lg='12'> <Label>Group By Date Received : </Label><span> {chrt.date}</span></Col>
                        </Row>
                            </Col>

                            <Col md="12" xs="12">
                            <Row>
                            <Col lg='12'> <Label>Chart :</Label><span> {chrt.type}</span></Col>
                        </Row>
                     
                                    <div className="col-lg-3"
                                    style={{border :'1px solid #dcdcdc', marginRight : 20, height : 50, width : 50,
                                display : 'flex',
                                alignItems: 'center',
                            justifyContent: 'center',

                                }}
                               
                                    >
                                <img  style={{height : 40, width : 40}} 
                                src={chrt.url}
                                ></img>
                               </div>
                            </Col>
                        
                    
                        </div>
                    </Card>
                  ))}
               


                <Col xxl={6} lg={6} style={{float : 'right'}}>
           
           <button type="button" className="btn btn-primary w-100" 
           onClick={()=>{
            generateVisualCharts()
            toggleRightCanvas()
            // console.log(JSON.stringify(finalChartData))
            // navigate('/mdr-charts', {state : finalChartData})
          }
           }
           >
           Generate Chart</button>

   </Col>

              </div>
            </OffcanvasBody>
          </Offcanvas>

          {/* <Modal isOpen={modal} toggle={toggle} className="model-width80">
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit MDR" : "Add MDR"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12 row m-0">
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Report Number</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Event Type</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Date Received</Label>
                                            <InputGroup className="border-rad">
                                                <Flatpickr
                                                    className="form-control d-block"
                                                    placeholder=""
                                                    options={{
                                                        altInput: true,
                                                        altFormat: "F j, Y",
                                                        dateFormat: "Y-m-d"
                                                    }}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Manufacturer Name</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Model Number</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Lot Number</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Catalog Number</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Brand Name</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Generic Name</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6 col-sm-12">
                                            <Label className="form-label">Product Code</Label>
                                            <Input
                                                type="text"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="mb-3 col-md-12 col-sm-12">
                                            <Label className="form-label">Event Description</Label>
                                            <Input
                                                type="textarea"
                                                placeholder=""
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mx-3">
                                        <div className="text-end mt-4">
                                            <button type="submit" className="btn btn-primary px-3">
                                                Submit MDR for Approval
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal> */}
        </div>
        
      </div>

      <Loader isLoading={isLoading} />

    </React.Fragment>
  );
}


export default GenerateChartsOffCanvas;
import React, { useState, useEffect } from "react"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  Badge,
  UncontrolledTooltip,
  FormFeedback,

} from "reactstrap"

import classnames from "classnames"
import { Link , useNavigate} from "react-router-dom"
import Select from 'react-select';
import Switch from "react-switch";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getCompanyList as onGetCompanyList,
  addNewCompany as onAddNewCompany,
  updateCompany as onUpdateCompany,
  deleteCompany as onDeleteCompany,
  getPhonecodeList as onGetPhonecodeList,
  getCountryList as onGetCountryList,
  getStateList as onGetStateList,
  getCityList as onGetCityList,
  getBillingStateList as onGetBillingStateList,
  getBillingCityList as onGetBillingCityList
} from "store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import Loader from "components/Common/Loader";

const AddCompany = () => {

  //meta title
  document.title = "Add New Corporate Profile | Smarteeva";

  const navigate = useNavigate()

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  const [countryOptions, setCountryOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([])


  const [billingCountryOptions, setBillingCountryOptions] = useState([])
  const [billingStateOptions, setBillingStateOptions] = useState([])
  const [billingCityOptions, setBillingCityOptions] = useState([])

  const [company, setCompany] = useState(null);

  const [countryVal, setCountryVal] = useState(null);
  const [stateVal, setStateVal] = useState(null);
  const [cityVal, setCityVal] = useState(null);
  const [billingCountryVal, setBillingCountryVal] = useState(null);
  const [billingStateVal, setBillingStateVal] = useState(null);
  const [billingCityVal, setBillingCityVal] = useState(null);

  const [phoneCodeVal, setPhoneCodeVal] = useState(null);
  const [cpPhoneCodeVal, setCpPhoneCodeVal] = useState(null);
  const [billingPhoneCodeVal, setBillingPhoneCodeVal] = useState(null);


  const [numRegex, setNumRegex] = useState(/^[0-9]*$/)
  const [contactPersonError, setContactPersonError] = useState('')

  const [companyContactPerson, setCompanyContactPerson] = useState([]);
  const [isLoading, setIsLoading] = useState(false);



  const dispatch = useDispatch();
  const { phonecode } = useSelector(state => ({
    phonecode: state.companyReducer.phonecode,
  }));

  const { country } = useSelector(state => ({
    country: state.companyReducer.country,
  }));

  const { states } = useSelector(state => ({
    states: state.companyReducer.states,
  }));

  const { city } = useSelector(state => ({
    city: state.companyReducer.city,
  }));


  const { billingstates } = useSelector(state => ({
    billingstates: state.companyReducer.billingstates,
  }));

  const { billingcity } = useSelector(state => ({
    billingcity: state.companyReducer.billingcity,
  }));


  const { error, loading } = useSelector(state => ({
    error: state.companyReducer.error,
    loading : state.companyReducer.loading
  }));


  useEffect(() => {
    setIsLoading(loading)
}, [loading]);

  useEffect(() => {

 
    // console.log(companys)
    if (phonecode && !phonecode.length) {
        dispatch(onGetPhonecodeList());
    }
    if (country && !country.length) {
      dispatch(onGetCountryList());
  }

}, [dispatch, phonecode, country]);

useEffect(() => {
    setPhoneCodeOptions(phonecode);
    setCountryOptions(country)
    setBillingCountryOptions(country)
}, [phonecode, country]);

useEffect(() => {
  setStateOptions(states)
}, [states]);

useEffect(() => {
  setCityOptions(city)
}, [city]);

useEffect(() => {
  setBillingStateOptions(billingstates)
}, [billingstates]);

useEffect(() => {
  setBillingCityOptions(billingcity)
}, [billingcity]);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        name: '',
        phonecode : '',
        phone : '',
        email:'',
        address:'',
        country:'',
        state:'',
        city:'',
        postalcode:''

    },
    validationSchema: Yup.object({
        // Id: Yup.string().matches(
        //     /[0-9\.\-\s+\/()]+/,
        //     "Please Enter Valid Job Id"
        // ).required("Please Enter Your Job Id"),
        // jobTitle: Yup.string().required("Please Enter Your Job Title"),
        name: Yup.string().required("Please Enter Organisation Name"),
        phonecode: Yup.string().required("Please Select Phone Code"),
        phone: Yup.string().matches(numRegex, 'Please enter a valid number').required("Please Enter Phone Number"),
        email: Yup.string().email('Please enter a valid email').required("Please Enter Email Address"),
        address: Yup.string().required("Please Enter Organisation Address"),
        country: Yup.string().required("Please Select Country"),
        state: Yup.string().required("Please Select State"),
        city: Yup.string().required("Please Select City"),
        postalcode: Yup.string().matches(numRegex, 'Please enter a valid postal code').required("Please Enter Postal Code")
    }),
    onSubmit: (values) => {
      toggleTabVertical(activeTabVartical + 1)

        // if (isEdit) {
        //     const updateJobList = {
        //         id: job ? job.id : 0,
        //         jobId: values.jobId,
        //         jobTitle: values.jobTitle,
        //         companyName: values.companyName,
        //         location: values.location,
        //         experience: values.experience,
        //         position: values.position,
        //         type: values.type,
        //         postedDate: "02 June 2021",
        //         lastDate: "25 June 2021",
        //         status: values.status,
        //     };
        //     // update Job
        //     dispatch(onUpdateJobList(updateJobList));
        //     validation.resetForm();
        // } else {
        //     const newJobList = {
        //         id: Math.floor(Math.random() * (30 - 20)) + 20,
        //         jobId: values["jobId"],
        //         jobTitle: values["jobTitle"],
        //         companyName: values["companyName"],
        //         location: values["location"],
        //         experience: values["experience"],
        //         position: values["position"],
        //         type: values["type"],
        //         postedDate: "02 June 2021",
        //         lastDate: "25 June 2021",
        //         status: values["status"],
        //     };
        //     // save new Job
        //     dispatch(onAddNewJobList(newJobList));
        //     validation.resetForm();
        // }
        // toggle();
    },
});




  // validation contact person
  const validationContact = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
        contactname: '',
        contactemail:'',
        contactmobile:'',
        isadmin:false,
        cpphonecode:'',
        cp_status : 'Active'

    },
    validationSchema: Yup.object({
        // Id: Yup.string().matches(
        //     /[0-9\.\-\s+\/()]+/,
        //     "Please Enter Valid Job Id"
        // ).required("Please Enter Your Job Id"),
        // jobTitle: Yup.string().required("Please Enter Your Job Title"),
        contactname: Yup.string().required("Please Enter Contact Person Name"),
        cpphonecode: Yup.string().required("Please Select Phone Code"),
        contactmobile: Yup.string().matches(numRegex, 'Please enter a valid number').required("Please Enter Mobile Number"),
        contactemail: Yup.string().email('Please enter a valid email').required("Please Enter Email Address"),
    }),
    onSubmit: (values, { resetForm }) => {
      // toggleTabVertical(activeTabVartical + 1)


      const duplicateEmailOrMobileExists = companyContactPerson.filter(user=> values.contactemail == user.contactemail || values.contactmobile == user.contactmobile)

      console.log(duplicateEmailOrMobileExists)
        
      if(duplicateEmailOrMobileExists.length > 0){
        setContactPersonError('Contact Person Already exists')

      }else{
      setContactPersonError('')
      setCpPhoneCodeVal('')
      values.cp_status = 'Active'
      setCompanyContactPerson(prevArray => [...prevArray, values]);
      resetForm();
      }
      
        // if (isEdit) {
        //     const updateJobList = {
        //         id: job ? job.id : 0,
        //         jobId: values.jobId,
        //         jobTitle: values.jobTitle,
        //         companyName: values.companyName,
        //         location: values.location,
        //         experience: values.experience,
        //         position: values.position,
        //         type: values.type,
        //         postedDate: "02 June 2021",
        //         lastDate: "25 June 2021",
        //         status: values.status,
        //     };
        //     // update Job
        //     dispatch(onUpdateJobList(updateJobList));
        //     validation.resetForm();
        // } else {
        //     const newJobList = {
        //         id: Math.floor(Math.random() * (30 - 20)) + 20,
        //         jobId: values["jobId"],
        //         jobTitle: values["jobTitle"],
        //         companyName: values["companyName"],
        //         location: values["location"],
        //         experience: values["experience"],
        //         position: values["position"],
        //         type: values["type"],
        //         postedDate: "02 June 2021",
        //         lastDate: "25 June 2021",
        //         status: values["status"],
        //     };
        //     // save new Job
        //     dispatch(onAddNewJobList(newJobList));
        //     validation.resetForm();
        // }
        // toggle();
    },
});


 // validation
 const validationBilling = useFormik({
  // enableReinitialize : use this flag when initial values needs to be changed
  enableReinitialize: true,

  initialValues: {
      billingphone : '',
      billingemail:'',
      billingaddress:'',
      billingcountry:'',
      billingstate:'',
      billingcity:'',
      billingpostalcode:'',
      billingphonecode:''

  },
  validationSchema: Yup.object({
      billingphone: Yup.string().matches(numRegex, 'Please enter a valid number'),
      billingemail: Yup.string().email('Please enter a valid email'),
      billingpostalcode: Yup.string().matches(numRegex, 'Please enter a valid postal code')
  }),
  onSubmit: (values) => {


    const compObj = { ...validation.values, ...values, companyContactPerson};
    dispatch(onAddNewCompany(compObj, navigate));

      // if (isEdit) {
      //     const updateJobList = {
      //         id: job ? job.id : 0,
      //         jobId: values.jobId,
      //         jobTitle: values.jobTitle,
      //         companyName: values.companyName,
      //         location: values.location,
      //         experience: values.experience,
      //         position: values.position,
      //         type: values.type,
      //         postedDate: "02 June 2021",
      //         lastDate: "25 June 2021",
      //         status: values.status,
      //     };
      //     // update Job
      //     dispatch(onUpdateJobList(updateJobList));
      //     validation.resetForm();
      // } else {
      //     const newJobList = {
      //         id: Math.floor(Math.random() * (30 - 20)) + 20,
      //         jobId: values["jobId"],
      //         jobTitle: values["jobTitle"],
      //         companyName: values["companyName"],
      //         location: values["location"],
      //         experience: values["experience"],
      //         position: values["position"],
      //         type: values["type"],
      //         postedDate: "02 June 2021",
      //         lastDate: "25 June 2021",
      //         status: values["status"],
      //     };
      //     // save new Job
      //     dispatch(onAddNewJobList(newJobList));
      //     validation.resetForm();
      // }
      // toggle();
  },
});

const handleKeyDown = (e) => {
  // console.log(e.key)
  if (e.key.match(/[a-z]/i) && e.key!= 'Backspace') {
    e.preventDefault();
  }
};


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Manage Organisation" breadcrumbItem="Corporate Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Corporate Profile</CardTitle>
                  {/* <p className="text-muted mb-5">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultricies vel enim in malesuada. Nulla porta tempor placerat. Aenean vitae hendrerit lacus, vitae sollicitudin ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam tristique et mauris nec iaculis.
                  </p> */}
                  <div className="horizontal-wizard wizard clearfix horizontal">
                    <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 1,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 1,
                            })}
                            onClick={() => {
                              toggleTabVertical(1)
                            }}
                            disabled={!(passedStepsVertical || []).includes(1)}
                          >
                            <span className="number">1</span> General Info
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 2,
                          })}
                        >
                          <NavLink
                            className={classnames({
                              active: activeTabVartical === 2,
                            })}
                            onClick={() => {
                              toggleTabVertical(2)
                            }}
                            disabled={!(passedStepsVertical || []).includes(2)}
                          >
                            <span className="number">2</span>{" "}
                            <span>Corporate Contacts</span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTabVartical === 3,
                          })}
                        >
                          <NavLink
                            className={
                              (classnames({
                                active: activeTabVartical === 3,
                              }),
                                "done")
                            }
                            onClick={() => {
                              toggleTabVertical(3)
                            }}
                            disabled={!(passedStepsVertical || []).includes(3)}
                          >
                            <span className="number">3</span> Billing Info
                          </NavLink>
                        </NavItem>
                      </ul>
                      </div>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}>
                            <Row>
                             
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label className="required">
                                     Name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Company Name"
                                    name="name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name || ""}
                                    invalid={
                                      validation.touched.name && validation.errors.name ? true : false
                                    }
                                  />
                                  {validation.touched.name && validation.errors.name ? (
                                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                  ) : null}
                               
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                            <Col lg="2" >
                                <FormGroup className="mb-3">
                                  <Label  className="required">Phone Code</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'phonecode'}
                                  onChange={(e)=>{
                                    setPhoneCodeVal(e)
                                    validation.setFieldValue('phonecode', e.value)
                                    validation.setFieldError('phonecode',false)
                                  }}
                                  onBlur={validation.handleBlur}
                                  options={phoneCodeOptions}
                                  value={
                                    phoneCodeVal || ""
                                }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                
                                
                                className={validation.touched.phonecode && validation.errors.phonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.phonecode && validation.errors.phonecode ? (
                                    <FormFeedback type="invalid">{validation.errors.phonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="5">
                                <FormGroup className="mb-3">
                                  <Label  className="required">
                                     Phone Number
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Company Phone No."
                                    name="phone"
                                    onKeyDown={handleKeyDown}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone || ""}
                                    invalid={
                                      validation.touched.phone && validation.errors.phone ? true : false
                                    }
                                  />
                                  {validation.touched.phone && validation.errors.phone ? (
                                    <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                  ) : null}
                                  
                                </FormGroup>
                              </Col>
                              <Col lg="5">
                                <FormGroup className="mb-3">
                                  <Label  className="required">
                                     Email Address
                                  </Label>
                                  <Input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Company Email Address"
                                    name="email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                      validation.touched.email && validation.errors.email ? true : false
                                    }
                                  />
                                  {validation.touched.email && validation.errors.email ? (
                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <FormGroup className="mb-3">
                                  <Label  className="required">
                                     Address
                                  </Label>
                                  <Input
                                    type="textarea"
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter Company Address"
                                    name="address"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.address || ""}
                                    invalid={
                                      validation.touched.address && validation.errors.address ? true : false
                                    }
                                  />
                                  {validation.touched.address && validation.errors.address ? (
                                    <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6" >
                                <FormGroup className="mb-3">
                                  <Label  className="required">Country</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'country'}
                                  onChange={(e)=>{
                                    if(e){
                                    setCountryVal(e)
                                    validation.setFieldValue('country', e.value)
                                    validation.setFieldError('country',false)
                                    dispatch(onGetStateList(e.value));
                                  }else{
                                    setCountryVal('')
                                    validation.setFieldValue('country', '')
                                    validation.setFieldError('country',true)
                                   
                                  }
                                  setStateOptions([])
                                  setCityOptions([])
                                  validation.setFieldValue('state', '')
                                  validation.setFieldValue('city', '')
                                  setStateVal('')
                                  setCityVal('')
                                }
                                }
                                  onBlur={validation.handleBlur}
                                  options={countryOptions}
                                  value={
                                    countryVal || ""
                                }
                                className={validation.touched.country && validation.errors.country ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validation.touched.country && validation.errors.country ? (
                                    <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label  className="required">State</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'state'}

                                  isClearable
                                  isSearchable
                                  options={stateOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setStateVal(e)
                                    validation.setFieldValue('state', e.value)
                                    validation.setFieldError('state',false)
                                    dispatch(onGetCityList(e.value));
                                    }else{
                                      setStateVal('')
                                      validation.setFieldValue('state', '')
                                      validation.setFieldError('state',true)
                                     
                                    }
                                    setCityOptions([])
                                    validation.setFieldValue('city', '')
                                    setCityVal('')
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    stateVal || ""
                                }
                                invalid={
                                    validation.touched.state && validation.errors.state
                                        ? true
                                        : false
                                }
                                className={validation.touched.state && validation.errors.state ? 'invalid-select' : ''}

                                />
                                  {validation.touched.state && validation.errors.state ? (
                                    <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label  className="required">City</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'city'}

                                  isClearable
                                  isSearchable
                                  options={cityOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setCityVal(e)
                                    validation.setFieldValue('city', e.value)
                                    validation.setFieldError('city',false)
                                    }else{
                                      setCityVal('')
                                    validation.setFieldValue('city', '')
                                    validation.setFieldError('city',true)
                                    }
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={
                                    cityVal || ""
                                }
                                invalid={
                                    validation.touched.city && validation.errors.city
                                        ? true
                                        : false
                                }
                                className={validation.touched.city && validation.errors.city ? 'invalid-select' : ''}

                                />
                                  {validation.touched.city && validation.errors.city ? (
                                    <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                  ) : null}
                            
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="mb-3">
                                  <Label  className="required">
                                    Postal Code
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Postal Code"
                                    name="postalcode"
                                    onKeyDown={handleKeyDown}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.postalcode || ""}
                                    invalid={
                                      validation.touched.postalcode && validation.errors.postalcode ? true : false
                                    }
                                  />
                                  {validation.touched.postalcode && validation.errors.postalcode ? (
                                    <FormFeedback type="invalid">{validation.errors.postalcode}</FormFeedback>
                                  ) : null}
                                
                                </FormGroup>
                              </Col>
                            </Row>

                          </Form>
                        </TabPane>

                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="3">
                                  <FormGroup className="mb-3">
                                    <Label className="required">
                                     Name
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Contact Person Name"
                                      name="contactname"
                                      onChange={validationContact.handleChange}
                                      onBlur={validationContact.handleBlur}
                                      value={validationContact.values.contactname || ""}
                                      invalid={
                                        validationContact.touched.contactname && validationContact.errors.contactname ? true : false
                                      }
                                    />
                                    {validationContact.touched.contactname && validationContact.errors.contactname ? (
                                      <FormFeedback type="invalid">{validationContact.errors.contactname}</FormFeedback>
                                    ) : null}
                                  
                                  </FormGroup>
                                </Col>

                               

                                <Col lg="3">
                                  <FormGroup className="mb-3">
                                    <Label className="required">
                                       Email Address
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Contact Person Email"
                                      name="contactemail"
                                      onChange={validationContact.handleChange}
                                      onBlur={validationContact.handleBlur}
                                      value={validationContact.values.contactemail || ""}
                                      invalid={
                                        validationContact.touched.contactemail && validationContact.errors.contactemail ? true : false
                                      }
                                    />
                                    {validationContact.touched.contactemail && validationContact.errors.contactemail ? (
                                      <FormFeedback type="invalid">{validationContact.errors.contactemail}</FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>


                                <Col lg="2" >
                                <FormGroup className="mb-3">
                                  <Label  className="required">Phone Code</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'cpphonecode'}
                                  onChange={(e)=>{
                                    setCpPhoneCodeVal(e)
                                    validationContact.setFieldValue('cpphonecode', e.value)
                                    validationContact.setFieldError('cpphonecode',false)
                                  }}
                                  onBlur={validationContact.handleBlur}
                                  options={phoneCodeOptions}
                                  value={
                                    cpPhoneCodeVal || ""
                                }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                className={validationContact.touched.cpphonecode && validationContact.errors.cpphonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validationContact.touched.cpphonecode && validationContact.errors.cpphonecode ? (
                                    <FormFeedback type="invalid">{validationContact.errors.cpphonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                                      
                                <Col lg="2">
                                  <FormGroup className="mb-3">
                                    <Label className="required">
                                       Mobile Number
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Contact Person Mobile"
                                      name="contactmobile"
                                      onKeyDown={handleKeyDown}
                                      onChange={validationContact.handleChange}
                                      onBlur={validationContact.handleBlur}
                                      value={validationContact.values.contactmobile || ""}
                                      invalid={
                                        validationContact.touched.contactmobile && validationContact.errors.contactmobile ? true : false
                                      }
                                    />
                                    {validationContact.touched.contactmobile && validationContact.errors.contactmobile ? (
                                      <FormFeedback type="invalid">{validationContact.errors.contactmobile}</FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>

                                <Col lg="1">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Is Admin
                                    </Label>
                                    <div className="" >
                                    <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="mb-sm-8 mb-2"
                          onColor="#369BFF"
                          name="contactmobile"
                                      onKeyDown={handleKeyDown}
                                      onChange={()=>{
                                        if(validationContact.values.isadmin){
                                          validationContact.setFieldValue('isadmin', false)
                                        }else{
                                          validationContact.setFieldValue('isadmin', true)
                    
                                        }
                                      }}
                                      onBlur={validationContact.handleBlur}
                                      value={validationContact.values.isadmin || ""}
                                     checked={validationContact.values.isadmin}
                                    />
                                   
                      
                         
                        </div>
                                  </FormGroup>
                                </Col>

                                <Col lg="1" style={{margin : 'auto', marginTop : '20px'}}>
                                  <FormGroup className="mb-3">
                                  <button
                              className="btn btn-primary btn-block "
                              type="submit"
                              onClick={(e) => {
                                // toggleTabVertical(activeTabVartical + 1)
                                e.preventDefault();
                                validationContact.handleSubmit();
                                return false;
                              }}
                            >
                              Add
                            </button>
                          
                                  </FormGroup>
                                </Col>

                             

                                <div className="table-responsive mb-5 mt-5">
                      <Table className="align-middle mb-0">

                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email </th>
                            <th>Mobile </th>
                            <th>Is Admin </th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {companyContactPerson && companyContactPerson.map((person, index)=>(
                         
                          <tr key={index}>
                            <th scope="row">{index+1}.</th>
                            <td>{person.contactname}</td>
                            <td>{person.contactemail}</td>
                            <td>({person.cpphonecode}) {person.contactmobile}</td>
                            <td>{person.isadmin ? <Badge className="bg-success">Yes</Badge> : <Badge className="bg-danger">No</Badge>}</td>
                            <td>{person.cp_status == 'Active' ? <Badge className="bg-success">{person.cp_status}</Badge> : <Badge className="bg-danger">{person.cp_status}</Badge>}</td>
                            <td>
                            <ul className="list-unstyled hstack gap-1 mb-0">
                           

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {

                                      validationContact.setFieldValue('contactname', person.contactname)
                                      validationContact.setFieldValue('contactemail', person.contactemail)
                                      validationContact.setFieldValue('contactmobile', person.contactmobile)
                                      validationContact.setFieldValue('cpphonecode', person.cpphonecode)
                                      validationContact.setFieldValue('isadmin', person.isadmin)
                                      validationContact.setFieldValue('cp_status', person.cp_status)

                                      setCpPhoneCodeVal(phoneCodeOptions.filter(val=> val.value == person.cpphonecode)[0])

                                      const userArray = [...companyContactPerson]; 
                                      userArray.splice(index, 1); 
                                      setCompanyContactPerson(userArray); 
                                    }}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                      const userArray = [...companyContactPerson]; 
                                      // userArray[index].cp_status = 'Archived'
                                      userArray.splice(index,1)
                                      setCompanyContactPerson(userArray); 
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                            </td>
                          </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <div style={{textAlign : 'center'}}>
                                {contactPersonError && contactPersonError ? (
                                      <FormFeedback type="invalid">{contactPersonError}</FormFeedback>
                                    ) : null}
                                    </div>
                              </Row>
                             
                          
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <Form>
                              <Row>
                              
                              </Row>
                              <Row>
                              <Col lg="2" >
                                <FormGroup className="mb-3">
                                  <Label>Phone Code</Label>
                                  <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  isClearable
                                  isSearchable
                                  name={'billingphonecode'}
                                  onChange={(e)=>{
                                    setBillingPhoneCodeVal(e)
                                    validationBilling.setFieldValue('billingphonecode', e.value)
                                    validationBilling.setFieldError('billingphonecode',false)
                                  }}
                                  onBlur={validationBilling.handleBlur}
                                  options={phoneCodeOptions}
                                  value={
                                    billingPhoneCodeVal || ""
                                }
                                formatOptionLabel={({ value, label }) => `(${value}) ${label}`}
                                className={validationBilling.touched.billingphonecode && validationBilling.errors.billingphonecode ? 'invalid-select' : ''}
                                // invalid={
                                //     validation.touched.country && validation.errors.country
                                //         ? true
                                //         : false
                                // }
                              
                                />
                                  {validationBilling.touched.billingphonecode && validationBilling.errors.billingphonecode ? (
                                    <FormFeedback type="invalid">{validationBilling.errors.billingphonecode}</FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                                <Col lg="4">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Phone Number
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Billing Phone No."
                                      name="billingphone"
                                      onKeyDown={handleKeyDown}
                                      onChange={validationBilling.handleChange}
                                      onBlur={validationBilling.handleBlur}
                                      value={validationBilling.values.billingphone || ""}
                                      invalid={
                                        validationBilling.touched.billingphone && validationBilling.errors.billingphone ? true : false
                                      }
                                    />
                                    {validationBilling.touched.billingphone && validationBilling.errors.billingphone ? (
                                      <FormFeedback type="invalid">{validationBilling.errors.billingphone}</FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>
                                       Email Address
                                    </Label>
                                    <Input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Billing Email Address"
                                      name="billingemail"
                                      onChange={validationBilling.handleChange}
                                      onBlur={validationBilling.handleBlur}
                                      value={validationBilling.values.billingemail || ""}
                                      invalid={
                                        validationBilling.touched.billingemail && validationBilling.errors.billingemail ? true : false
                                      }
                                    />
                                    {validationBilling.touched.billingemail && validationBilling.errors.billingemail ? (
                                      <FormFeedback type="invalid">{validationBilling.errors.billingemail}</FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <FormGroup className="mb-3">
                                    <Label>
                                       Address
                                    </Label>
                                    <Input
                                    type="textarea"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Enter Billing Address"
                                      name="billingaddress"
                                      onChange={validationBilling.handleChange}
                                      onBlur={validationBilling.handleBlur}
                                      value={validationBilling.values.billingaddress || ""}
                                      invalid={
                                        validationBilling.touched.billingaddress && validationBilling.errors.billingaddress ? true : false
                                      }
                                    />
                                    {validationBilling.touched.billingaddress && validationBilling.errors.billingaddress ? (
                                      <FormFeedback type="invalid">{validationBilling.errors.billingaddress}</FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>Country</Label>
                                    <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'billingcountry'}

                                  isClearable
                                  isSearchable
                                  options={billingCountryOptions}
                                  onChange={(e)=>{
                                    if(e){
                                      setBillingCountryVal(e)
                                    validationBilling.setFieldValue('billingcountry', e.value)
                                    validationBilling.setFieldError('billingcountry',false)
                                    dispatch(onGetBillingStateList(e.value));
                                  }else{
                                    setBillingCountryVal('')
                                    validationBilling.setFieldValue('billingcountry', '')
                                    validationBilling.setFieldError('billingcountry',true)
                                    
                                  }
                                  setBillingStateOptions([])
                                  setBillingCityOptions([])
                                  validationBilling.setFieldValue('billingstate', '')
                                  validationBilling.setFieldValue('billingcity', '')
                                  setBillingStateVal('')
                                  setBillingCityVal('')
                                }}
                                  // onChange={(e)=>{
                                    
                                  //   setBillingCountryVal(e)
                                  //   validationBilling.setFieldValue('billingcountry', e.value)
                                  //   validationBilling.setFieldError('billingcountry',false)
                                  // }}
                                  onBlur={validationBilling.handleBlur}
                                  value={
                                    billingCountryVal || ""
                                }
                                invalid={
                                  validationBilling.touched.billingcountry && validationBilling.errors.billingcountry
                                        ? true
                                        : false
                                }
                                className={validationBilling.touched.billingcountry && validationBilling.errors.billingcountry ? 'invalid-select' : ''}

                                />
                                  {validationBilling.touched.billingcountry && validationBilling.errors.billingcountry ? (
                                    <FormFeedback type="invalid">{validationBilling.errors.billingcountry}</FormFeedback>
                                  ) : null}
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>State</Label>
                                    <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'billingstate'}

                                  isClearable
                                  isSearchable
                                  options={billingStateOptions}
                                  onChange={(e)=>{
                                    if(e){
                                      setBillingStateVal(e)
                                    validationBilling.setFieldValue('billingstate', e.value)
                                    validationBilling.setFieldError('billingstate',false)
                                    dispatch(onGetBillingCityList(e.value));
                                    }else{
                                      setBillingStateVal('')
                                      validationBilling.setFieldValue('billingstate', '')
                                      validationBilling.setFieldError('billingstate',true)
                                    
                                    }
                                    setBillingCityOptions([])
                                    validationBilling.setFieldValue('billingcity', '')
                                    setBillingCityVal('')
                                  }}
                                  // onChange={(e)=>{
                                  //   setBillingStateVal(e)
                                  //   validationBilling.setFieldValue('billingstate', e.value)
                                  //   validationBilling.setFieldError('billingstate',false)
                                  // }}
                                  onBlur={validationBilling.handleBlur}
                                  value={
                                    billingStateVal || ""
                                }
                                invalid={
                                  validationBilling.touched.billingstate && validationBilling.errors.billingstate
                                        ? true
                                        : false
                                }
                                className={validationBilling.touched.billingstate && validationBilling.errors.billingstate ? 'invalid-select' : ''}

                                />
                                  {validationBilling.touched.billingstate && validationBilling.errors.billingstate ? (
                                    <FormFeedback type="invalid">{validationBilling.errors.billingstate}</FormFeedback>
                                  ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>City</Label>
                                    <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'billingcity'}

                                  isClearable
                                  isSearchable
                                  options={billingCityOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setBillingCityVal(e)
                                    validationBilling.setFieldValue('billingcity', e.value)
                                    validationBilling.setFieldError('billingcity',false)
                                    }else{
                                      setBillingCityVal('')
                                      validationBilling.setFieldValue('billingcity', '')
                                      validationBilling.setFieldError('billingcity',true)
                                    }
                                  }}
                                  onBlur={validationBilling.handleBlur}
                                  value={
                                    billingCityVal || ""
                                }
                                invalid={
                                  validationBilling.touched.billingcity && validationBilling.errors.billingcity
                                        ? true
                                        : false
                                }
                                className={validationBilling.touched.billingcity && validationBilling.errors.billingcity ? 'invalid-select' : ''}

                                />
                                  {validationBilling.touched.billingcity && validationBilling.errors.billingcity ? (
                                    <FormFeedback type="invalid">{validationBilling.errors.billingcity}</FormFeedback>
                                  ) : null}
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Postal Code
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Postal Code"
                                      name="billingpostalcode"
                                      onKeyDown={handleKeyDown}
                                      onChange={validationBilling.handleChange}
                                      onBlur={validationBilling.handleBlur}
                                      value={validationBilling.values.billingpostalcode || ""}
                                      invalid={
                                        validationBilling.touched.billingpostalcode && validationBilling.errors.billingpostalcode ? true : false
                                      }
                                    />
                                    {validationBilling.touched.billingpostalcode && validationBilling.errors.billingpostalcode ? (
                                      <FormFeedback type="invalid">{validationBilling.errors.billingpostalcode}</FormFeedback>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTabVartical === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTabVertical(activeTabVartical - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={
                            activeTabVartical === 3 ? "next disabled" : "next"
                          }
                        >
                          <Link
                            // to="#"
                            onClick={(e) => {
                              // toggleTabVertical(activeTabVartical + 1)
                              e.preventDefault();
                              if(activeTabVartical ==1){
                                validation.handleSubmit();
                              }else if(activeTabVartical == 2){
                                if(companyContactPerson.length<=0){
                                  setContactPersonError('Please Add Atleast 1 Contact Person')
                                }else{
                                  const adminCount = companyContactPerson.filter(user => user.isadmin).length;
                                  if(adminCount <= 0){
                                    setContactPersonError('Please Add Atleast 1 Contact Person as Admin')
                                  }else{
                                    toggleTabVertical(activeTabVartical + 1)

                                  }
                                }
                              }

                              
                              return false;
                            }}
                            type="submit"
                          >
                            Next
                          </Link>
                        </li>

                        <li
                        style={{float  : 'left'}}
                          className={
                            activeTabVartical < 3 ? "next disabled" : "next"
                          }
                        >
                        
                          <Link
                            to="#"
                            onClick={() => {
                              validationBilling.handleSubmit();
                            }}
                          >
                            Skip & Save
                          </Link>
                        </li>

                        <li
                         style={{float  : 'left'}}
                          className={
                            activeTabVartical < 3 ? "next disabled" : "next"
                          }
                        >
                        
                          <Link
                            to="#"
                            onClick={() => {
                              validationBilling.handleSubmit();
                            }}
                          >
                           Save
                          </Link>
                        </li>

                        <li
                         style={{float  : 'left'}}
                          className={ "next"}
                        >
                        
                          <Link
                           to="/corporate-list"
                            className="danger-btn"
                            // onClick={() => {
                            //   navigate('/corporate-list')
                            // }}
                          >
                           Cancel
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Loader isLoading={isLoading} /> 

    </React.Fragment>
  )
}

export default AddCompany

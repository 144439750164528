import React, {useState, useRef} from 'react';
import { FormControl, InputGroup, Tab, Nav } from 'react-bootstrap';
// import '@fortawesome/fontawesome-free'; // Include Font Awesome CSS
import Form from 'react-bootstrap/Form';
import Draggable from '../../../../assets/images/drag.png';
import {
  Button,
   ButtonGroup,
   CardTitle, 
   FormFeedback,
   Label,
   Input
  //  Accordion, 
  //  Form

} from "reactstrap";
import { useDrag, useDrop } from "react-dnd";

const style = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move',
  }

function TabComponent(props) {
  const addInputField = (event) => {
    // Your addInputField function logic here
  };

  const showPreviousQuestion = () => {
    // Your showPreviousQuestion function logic here
  };

  const showNextQuestion = () => {
    // Your showNextQuestion function logic here
  };


  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
    //   console.log(item)
      const dragIndex = item.index
      const hoverIndex = props.index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })


  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
        let serial = props.qData.serial
        let index = props.index
        // console.log(ser, index)
      return { serial, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
 
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
   
    <div className="openQuestion-main-section draggable" draggable="true"  ref={ref} data-handler-id={handlerId}>
               
                    <div className="questionCard edit-module">
                      <div className='imagesCorrec'>
                        <img src={Draggable} alt="Draggable" />
                      </div>
                      <div className='card-inner-head'>
                   
                        <div className='heading0023'>
                          <div className='heading-cards'>
                            <div className='icons_heading-cards' >
                              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19 18" fill="none">
                                <path d="M13.8182 2.59091H16.4091L15.1136 4.31818L13.8182 2.59091ZM1.72727 0H17.2727C18.2314 0 19 0.777273 19 1.72727V5.18182C19 6.14045 18.2314 6.90909 17.2727 6.90909H12.9545V15.5455C12.9545 16.5041 12.1859 17.2727 11.2273 17.2727H1.72727C0.777273 17.2727 0 16.5041 0 15.5455V1.72727C0 0.777273 0.777273 0 1.72727 0ZM1.72727 1.72727V5.18182H11.2273V1.72727H1.72727ZM17.2727 5.18182V1.72727H12.9545V5.18182H17.2727ZM1.72727 15.5455H11.2273V6.90909H1.72727V15.5455ZM3.45455 8.63636H9.5V10.3636H3.45455V8.63636ZM3.45455 12.0909H9.5V13.8182H3.45455V12.0909Z" fill="#ADB5BD" />
                              </svg>
                            </div>
                            <div className='customSelect'>
                              <Form.Select aria-label="Default select example" className='questSec-select'
                              value={props.qData.type}
                              onChange={(e)=>{
                                props.onChangeTabQuestionType(e.target.value, props.index)
                              }}
                              >
                               {props.questionTypeOptions.map((choice, i)=>(
                                 <option value={choice.value} key={choice.value}>{choice.label}</option>
                               ))}
              
                              </Form.Select>
                            </div>

                          </div>
                          <div className='' onClick={()=>{props.removeQuestionFromTab(props.qData, props.index)}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 21" fill="none">
                              <path d="M2.5 5.5H4.16667H17.5" stroke="#F16A1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M15.8333 5.50002V17.1667C15.8333 17.6087 15.6577 18.0326 15.3451 18.3452C15.0326 18.6578 14.6087 18.8334 14.1666 18.8334H5.83329C5.39127 18.8334 4.96734 18.6578 4.65478 18.3452C4.34222 18.0326 4.16663 17.6087 4.16663 17.1667V5.50002M6.66663 5.50002V3.83335C6.66663 3.39133 6.84222 2.9674 7.15478 2.65484C7.46734 2.34228 7.89127 2.16669 8.33329 2.16669H11.6666C12.1087 2.16669 12.5326 2.34228 12.8451 2.65484C13.1577 2.9674 13.3333 3.39133 13.3333 3.83335V5.50002" stroke="#F16A1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M8.33337 9.66669V14.6667" stroke="#F16A1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.6666 9.66669V14.6667" stroke="#F16A1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </div>
                        </div>
            
                        <div className= {props.qData.required ?"question flexHead0043 required" : "question flexHead0043"}>
                        {props.qData.num}. {props.qData.questionText}
                        </div>

                        <div className="input-wrapper">
                        {props.renderFieldViewTab(props.qData)}
                        </div>


                        <div className="input-wrapper" style={{display : props.qData.showcomments ? '' : 'none'}}>
    <Label className={props.qData.commentsrequired ? "form-label required" : "form-label"} style={{marginBottom :0}}>Comments</Label>
    <Input
      // disabled
      name="key"
      type="textarea"
      rows={5}
      placeholder="" />
    </div>

                        {/* <div className="add-choice-container">
                          <button className="add-choice-button" onClick={addInputField} style={{ cursor: 'pointer' }}>
                            <span className='choosemr-3'>Add Choice</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <path d="M10 4.16666V15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.16666 10H15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </button>
                        </div> */}
                      </div>

                    </div>
                  
                  </div>

  );
}

export default TabComponent;

import CardWithWatermark from "components/Common/Watermark"
import React from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"

const TopPatient = (props) => {
  return (
    <React.Fragment>
      <Card 
      // className="fixed-height-match2"
      >
        <CardBody>
          <CardTitle className="mb-4">Top Patient Codes</CardTitle>
          {/* <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-map-pin text-primary display-4" />
            </div>
            <h3>1,456</h3>
            <p>San Francisco</p>
          </div> */}

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
              {props.topPatient.data && props.topPatient.data.map((patient,index)=>(
                   <tr key={index}>
                   <td style={{ width: "30%" }}>
                     <p className="mb-0">{patient.DESCRIPTION}</p>
                   </td>
                   <td style={{ width: "25%" }}>
                     <h5 className="mb-0">{patient.REPORTS.toLocaleString()}</h5>
                   </td>
                   {/* <td>
                     <Progress
                       value="94"
                       color="primary"
                       className="bg-transparent progress-sm"
                       size="sm"
                     />
                   </td> */}
                 </tr>
                ))}
              </tbody>
            </table>
          </div>
          <CardWithWatermark/>

        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopPatient

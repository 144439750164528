import { call, put, takeEvery } from "redux-saga/effects"

import { EXECUTE_SMART_QUERY, EXECUTE_SMART_QUERY_MDR_LIST, GET_DEVICE_PROB_CODE_LIST, GET_FIELD_CHART_SMART, GET_MDR_INITIAL_LIST, GET_MEDICAL_SPL_LIST, GET_MONTHLY_CHART_SMART, GET_PATIENT_PROB_CODE_LIST, GET_PATIENT_PROB_CODE_MDR_LIST, GET_RECENT_LIST, GET_SAVED_LIST, GET_SEARCH_FIELD_LIST, GLOBAL_SEARCH_RESULT_MDR_LIST, SAVE_SMART_QUERY} from "./actionTypes";
import {
    executeQueryResultNoData,
    executeQueryResultNoDataMdrList,
    executeSmartQueryFail,
    executeSmartQueryMdrListFail,
    executeSmartQueryMdrListSuccess,
    executeSmartQuerySuccess,
    getDeviceFieldChartSmartSuccess,
    getDeviceProbCodeListFail,
    getDeviceProbCodeListSuccess,
    getEventFieldChartSmartSuccess,
    getFieldChartSmartFail,
    getManufacturerFieldChartSmartSuccess,
    getMdrListFail,
    getMdrListSuccess,
    getMedicalSplListFail,
    getMedicalSplListSuccess,
    getMonthlyChartSmartFail,
    getMonthlyChartSmartSuccess,
    getPatientProbCodeListFail,
    getPatientProbCodeListSuccess,
    getPatientProbCodeMdrListFail,
    getPatientProbCodeMdrListSuccess,
    getReasonFieldChartSmartSuccess,
    getRecentListFail,
    getRecentListSuccess,
    getSavedListFail,
    getSavedListSuccess,
    getSearchFieldListFail,
    getSearchFieldListSuccess,
    getSpecialtyFieldChartSmartSuccess,
    getSubmissionFieldChartSmartSuccess,
    getYearChartSmartSuccess,
    globalSearchResultMdrListFail,
    globalSearchResultMdrListSuccess,
    globalSearchResultNoData,
    resetQueryResult,
    saveSmartQueryFail,
    saveSmartQuerySuccess,
    setGlobalSearchResultDetails,
    setLoadingIndicatorSmartQuery,
    setSmartQueryDetails,
    setSmartQueryMdrListDetails
} from "./actions"

import {
    getRecentQueryList,
    getSavedQueryList,
    getSeachableDataList,
    getMedicalSplDataList,
    getDeviceProbCodeDataList,
    getPatientProbCodeDataList,
    saveSmartQueryApi,
    executeSmartQueryApi,
    getMdrListInitial,
    getMonthlyChartApi,
    getFieldChartApi,
    globalSearchResultApi
} from "helpers/backend_helper";
import moment from "moment";
import { authUser } from "helpers/dataConfig";

function* fetchRecentList() {

//   yield put(setLoadingIndicatorSmartQuery(true));


    const data = {
        userid : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    }

    try {
        const response = yield call(getRecentQueryList, data)
        // console.log(response.data)
        yield put(getRecentListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getRecentListFail(error))
    }
}

function* fetchSavedList() {

//   yield put(setLoadingIndicatorSmartQuery(true));

  const data = {
    userid : JSON.parse(localStorage.getItem(authUser)).fld_userid,
}

    try {
        const response = yield call(getSavedQueryList, data)
        // console.log(response.data)
        yield put(getSavedListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getSavedListFail(error))
    }
}

function* fetchSearchFieldList() {


    yield put(setLoadingIndicatorSmartQuery(true));

    try {
        const response = yield call(getSeachableDataList)
        // console.log(response.data)
        yield put(getSearchFieldListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getSearchFieldListFail(error))
    }
}

function* fetchMedicalSplist() {

    try {
        const response = yield call(getMedicalSplDataList)
        // console.log(response.data)
        yield put(getMedicalSplListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getMedicalSplListFail(error))
    }
}

function* fetchDeviceProbCodeist() {

    try {
        const response = yield call(getDeviceProbCodeDataList)
        // console.log(response.data)
        yield put(getDeviceProbCodeListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getDeviceProbCodeListFail(error))
    }
}

function* fetchPatientProbCodeist() {

    try {
        const response = yield call(getPatientProbCodeDataList)
        // console.log(response.data)
        yield put(getPatientProbCodeListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getPatientProbCodeListFail(error))
    }
}


function* saveSamrtQueryData({ payload: {data, history }}) {

  yield put(setLoadingIndicatorSmartQuery(true));


    try {
        const response = yield call(saveSmartQueryApi, data)
        // console.log(response.data)
        if(data.type == 'Save'){

            yield call(fetchSavedList)
        }else{
            yield call(fetchRecentList)
        }
        // yield put(saveSmartQuerySuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(saveSmartQueryFail(error))
    }
}

function* executeSmartQueryData({ payload: {data, history, query, page }}) {
    
    yield put(executeQueryResultNoData(false))

    // console.log(query)
    // console.log('in sm',query)

    if(query != null){
        yield put(setLoadingIndicatorSmartQuery(true));

    }

    try {
        // yield put(resetQueryResult())
        const response = yield call(executeSmartQueryApi, data)
        // console.log(response)

        // console.log(response.data)
        if(response.data.data == null || response.data.data.length <= 0){
            yield put(executeQueryResultNoData(true))
            yield put(executeSmartQuerySuccess(response.data))
        }else{
            // yield put(executeQueryResultNoData(false))
            yield put(executeSmartQuerySuccess(response.data.data))
            const additional = {
                total_count : response.data.total_count,
              
            }
            yield put(setSmartQueryDetails({...query, ...additional}))
            if(page == 'main'){
        history('/query_output')
            }
        }

    } catch (error) {
        // console.log(error)
        yield put(executeSmartQueryFail(error))
    }
}

function* getMdrInitialList({ payload: {data, history }}) {

    // const response = yield call(executeSmartQueryApi, data)

    yield put(setLoadingIndicatorSmartQuery(true));

    try {
        const response = yield call(getMdrListInitial, data)
        // console.log(response.data)
        yield put(getMdrListSuccess(response.data.data))
    } catch (error) {
        // console.log(error)
        yield put(getMdrListFail(error))
    }
}


function* fetchPatientProbCodeMdrList() {

    try {
        const response = yield call(getPatientProbCodeDataList)
        // console.log(response.data)
        yield put(getPatientProbCodeMdrListSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getPatientProbCodeMdrListFail(error))
    }
}


function* executeSmartQueryDataMdrList({ payload: {data, history, query, page }}) {
    
    yield put(executeQueryResultNoDataMdrList(false))

    // console.log('in list',query)
    if(query != null){
        // yield put(setLoadingIndicatorSmartQuery(true));

    }


    try {
        // yield put(resetQueryResult())
        const response = yield call(executeSmartQueryApi, data)
        // console.log(response)

        // console.log(response.data)
        if(response.data.data == null || response.data.data.length <= 0){
            yield put(executeQueryResultNoDataMdrList(true))
            yield put(executeSmartQueryMdrListSuccess(response.data))
         
        }else{
           
            // yield put(executeQueryResultNoDataMdrList(false))
            yield put(executeSmartQueryMdrListSuccess(response.data.data))
            if(query != null){
            const additional = {
                total_count : response.data.total_count,
              
            }
            yield put(setSmartQueryMdrListDetails({...query, ...additional}))
        }
            if(page == 'main'){
        history('/query_output')
            }
        }

    } catch (error) {
        // console.log(error)
        yield put(executeSmartQueryMdrListFail(error))
    }
}



function* getMonthlyChartSmartData({ payload: {monthData }}) {

    // console.log(monthData)
      try {
          const response = yield call(getMonthlyChartApi, monthData)
        //   console.log(monthData.type)
          if(monthData.type == 'Month'){
          yield put(getMonthlyChartSmartSuccess(response.data))
          }else{
          yield put(getYearChartSmartSuccess(response.data))
          }
      } catch (error) {
          // console.log(error)
          yield put(getMonthlyChartSmartFail(error))
      }
  }


  function* getFieldChartSmartData({ payload: {fieldData }}) {

    // console.log(fieldData)
      try {
          const response = yield call(getFieldChartApi, fieldData)
        //   console.log(response)
          if (fieldData.type == 'Event') {
              yield put(getEventFieldChartSmartSuccess(response.data.data))
          } else if (fieldData.type == 'Manufacturer') {
              yield put(getManufacturerFieldChartSmartSuccess(response.data.data))
          } else if (fieldData.type == 'Device') {
              yield put(getDeviceFieldChartSmartSuccess(response.data.data))
          } else if (fieldData.type == 'Submission') {
              yield put(getSubmissionFieldChartSmartSuccess(response.data.data))
          } else if (fieldData.type == 'Reason') {
              yield put(getReasonFieldChartSmartSuccess(response.data.data))
          } else if (fieldData.type == 'Specialty') {
            yield put(getSpecialtyFieldChartSmartSuccess(response.data.data))
        }
      } catch (error) {
          // console.log(error)
          yield put(getFieldChartSmartFail(error))
      }
  }


  function* getGlobalSearchResultData({ payload: {data }}) {
    
    yield put(globalSearchResultNoData(false))

    if(data.off_set == 0){
        yield put(setLoadingIndicatorSmartQuery(true));
    }


    try {
        // yield put(resetQueryResult())
        const response = yield call(globalSearchResultApi, data)
        // console.log(response)

        // console.log(response.data)
        if(response.data.data == null || response.data.data.length <= 0){
            yield put(globalSearchResultNoData(true))
            yield put(globalSearchResultMdrListSuccess(response.data))
         
        }else{
           
            // yield put(executeQueryResultNoDataMdrList(false))
            yield put(globalSearchResultMdrListSuccess(response.data.data))
            if(data != null){
            const additional = {
                total_count : response.data.total_count,
              
            }
            yield put(setGlobalSearchResultDetails({...data, ...additional}))
        }
         
        }

    } catch (error) {
        // console.log(error)
        yield put(globalSearchResultMdrListFail(error))
    }
}




function* smartquerySaga() {
    yield takeEvery(GET_RECENT_LIST, fetchRecentList)
    yield takeEvery(GET_SAVED_LIST, fetchSavedList)
    yield takeEvery(GET_SEARCH_FIELD_LIST, fetchSearchFieldList)
    yield takeEvery(GET_MEDICAL_SPL_LIST, fetchMedicalSplist)
    yield takeEvery(GET_DEVICE_PROB_CODE_LIST, fetchDeviceProbCodeist)
    yield takeEvery(GET_PATIENT_PROB_CODE_LIST, fetchPatientProbCodeist)
    yield takeEvery(SAVE_SMART_QUERY, saveSamrtQueryData)
    yield takeEvery(EXECUTE_SMART_QUERY, executeSmartQueryData)
    yield takeEvery(GET_MDR_INITIAL_LIST, getMdrInitialList)
    yield takeEvery(GET_PATIENT_PROB_CODE_MDR_LIST, fetchPatientProbCodeMdrList)
    yield takeEvery(EXECUTE_SMART_QUERY_MDR_LIST, executeSmartQueryDataMdrList)

    yield takeEvery(GET_MONTHLY_CHART_SMART, getMonthlyChartSmartData)
    yield takeEvery(GET_FIELD_CHART_SMART, getFieldChartSmartData)

    yield takeEvery(GLOBAL_SEARCH_RESULT_MDR_LIST, getGlobalSearchResultData)



}

export default smartquerySaga;
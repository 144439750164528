import {
    API_SUCCESS,
    API_FAIL,
    GET_MDR_STATS_DATA_PUBLIC,
    GET_MDR_STATS_DATA_YEAR_PUBLIC,
    SET_LOADING_INDICATOR_DASHBOARD_PUBLIC,
    GET_MDR_TOP_DEVICES_PUBLIC,
    GET_MDR_TOP_PATIENTS_PUBLIC,
    GET_MDR_YEAR_DATA_PUBLIC,
    GET_MDR_MONTH_COUNT_PUBLIC,
    GET_MDR_EVENT_COUNT_PUBLIC,
    GET_MDR_YEARLY_COUNT_PUBLIC,
    GET_MDR_MEDICAL_SPEC_COUNT_PUBLIC,
    GET_MDR_MONTHLY_EVENT_COUNT_PUBLIC
} from "./actionTypes";

const INIT_STATE = {
    mdrStatsData: [],
    mdrStatsDataYear: [],
    topDevices: [],
    topPatients: [],
    mdr_year_data: [],
    mdr_month_counts: [],
    mdr_event_count: [],
    mdr_yearly_count: [],
    mdr_medical_spec_count : [],
    mdr_monthly_event_count : [],
    error: '',
    loading: false
};

const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_SUCCESS:
            switch (action.payload.actionType) {

                case GET_MDR_STATS_DATA_PUBLIC:
                    return {
                        ...state,
                        mdrStatsData: action.payload.data,
                        loading: false


                    };
                case GET_MDR_STATS_DATA_YEAR_PUBLIC:
                    return {
                        ...state,
                        mdrStatsDataYear: action.payload.data,
                           loading : false
                    };

                case GET_MDR_TOP_DEVICES_PUBLIC:
                    return {
                        ...state,
                        topDevices: action.payload.data,
                        //    loading : false
                    };

                case GET_MDR_TOP_PATIENTS_PUBLIC:
                    return {
                        ...state,
                        topPatients: action.payload.data,
                        //    loading : false
                    };

                case GET_MDR_YEAR_DATA_PUBLIC:
                    return {
                        ...state,
                        mdr_year_data: action.payload.data,
                        //    loading : false
                    };

                case GET_MDR_MONTH_COUNT_PUBLIC:
                    return {
                        ...state,
                        mdr_month_counts: action.payload.data,
                        //    loading : false
                    };

                case GET_MDR_EVENT_COUNT_PUBLIC:
                    return {
                        ...state,
                        mdr_event_count: action.payload.data,
                        //    loading : false
                    };

                case GET_MDR_YEARLY_COUNT_PUBLIC:
                    return {
                        ...state,
                        mdr_yearly_count: action.payload.data,
                        //    loading : false
                    };

                    case GET_MDR_MEDICAL_SPEC_COUNT_PUBLIC:
                        return {
                            ...state,
                            mdr_medical_spec_count: action.payload.data,
                            //    loading : false
                        };

                        case GET_MDR_MONTHLY_EVENT_COUNT_PUBLIC:
                            return {
                                ...state,
                                mdr_monthly_event_count: action.payload.data,
                                //    loading : false
                            };

                case SET_LOADING_INDICATOR_DASHBOARD_PUBLIC:
                    state = {
                        ...state,
                        loading: action.payload.data,
                    }
                    break

                default:
                    return state;
            }
        case API_FAIL:
            switch (action.payload.actionType) {

                case GET_MDR_STATS_DATA_PUBLIC:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };
                case GET_MDR_STATS_DATA_YEAR_PUBLIC:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };

                case GET_MDR_TOP_DEVICES_PUBLIC:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };

                case GET_MDR_TOP_PATIENTS_PUBLIC:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };


                case SET_LOADING_INDICATOR_DASHBOARD_PUBLIC:
                    state = {
                        ...state,
                        loading: action.payload.data,
                    }
                    break

                case GET_MDR_YEAR_DATA_PUBLIC:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };

                case GET_MDR_MONTH_COUNT_PUBLIC:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };

                case GET_MDR_EVENT_COUNT_PUBLIC:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };

                case GET_MDR_YEARLY_COUNT_PUBLIC:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false
                    };

                    case GET_MDR_MEDICAL_SPEC_COUNT_PUBLIC:
                        return {
                            ...state,
                        error: action.payload.error,
                        loading: false
                        };

                        case GET_MDR_MONTHLY_EVENT_COUNT_PUBLIC:
                            return {
                                ...state,
                            error: action.payload.error,
                            loading: false
                            };
    
                default:
                    return state;
            }
        case SET_LOADING_INDICATOR_DASHBOARD_PUBLIC:
            // console.log(action.payload)
            state = {
                ...state,
                loading: action.payload,
            }
        //     break
        default:
            return state;
    }
}


export default Dashboard;
import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_USERS, GET_USER_PROFILE , ADD_NEW_USER , DELETE_USER, UPDATE_USER, GET_CORPORATE_DD, GET_PHONECODE_LIST_USER, GET_COUNTRY_LIST_USER, GET_STATE_LIST_USER, GET_CITY_LIST_USER, GET_COMPANY_LIST_USER, GET_ACTIVE_USER_ORG, GET_USER_DETAILS, APPROVE_USER, SEND_USER_INVITE, GET_GROUP_PERMISSION, UPDATE_GROUP_PERMISSION } from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getCorporateDropdownSuccess,
  getCorporateDropdownFail,
  getPhonecodeListUserSuccess,
  getPhonecodeListUserFail,
  getCityListUserSuccess,
  getCityListUserFail,
  getStateListUserFail,
  getStateListUserSuccess,
  getCountryListUserSuccess,
  getCountryListUserFail,
  getCompanyListUserSuccess,
  getCompanyListUserFail,
  getActiveUsersOrgSuccess,
  getActiveUsersOrgFail,
  getUserDetailsSuccess,
  approveUserFail,
  sendUserInviteFail,
  setLoadingIndicatorUser,
  getGroupPermissionSuccess,
  getGroupPermissionFail,
  updateGroupPermissionSuccess,
  updateGroupPermissionFail,
} from "./actions"

//Include Both Helper File with needed methods
import { 
  getUsersList, 
  getUserProfile, 
  updateUserData,
  deleteUser, 
  getCorporateList, 
  getPhonecodeList, 
  getCityList, 
  getStateList, 
  getCountryList,
  getActiveCompanyUserList,
  getActiveUserOrg,
  addNewUserData,
  approveUser,
  sendUserInvite,
  getGroupPermissionList,
  updateGroupPermission
 } from "../../helpers/backend_helper"
import moment from "moment"
import { authUser } from "helpers/dataConfig"
import { Auth } from 'aws-amplify';
import axios from "axios"
import { getTspToken } from "helpers/getTspToken"

function* fetchUsers() {

  yield put(setLoadingIndicatorUser(true));
  const userDT = JSON.parse(localStorage.getItem(authUser))
  let corpData = {
    corpid : userDT.fld_issuperadmin ? 0 :  userDT.fld_companyid
  }
  console.log(userDT)
  try {
    const response = yield call(getUsersList, corpData)
    // console.log(response)
    yield put(getUsersSuccess(response.data))
} catch (error) {
    // console.log(error)
    yield put(getUsersFail(error))
}
 
}

function* fetchCorporateDropdown() {

  yield put(setLoadingIndicatorUser(true));
  try {
    const response = yield call(getCorporateList)
    // console.log(response)
    yield put(getCorporateDropdownSuccess(response.data))
} catch (error) {
    // console.log(error)
    yield put(getCorporateDropdownFail(error))
}
 
}

function* fetchUserProfile(user) {
  yield put(setLoadingIndicatorUser(true));
  const user_dt = {
    userid : user
  }
  try {
    const response = yield call(getUserProfile,user_dt)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* onUpdateUser({ payload: {user, history} }) {

  console.log(user)
  yield put(setLoadingIndicatorUser(true));

  const additional = {
      status : 'Active',
      approvalstatus : 'Pending',
      updatedon :  moment().format(),
      updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
  }
  const compData = {...user, ...additional}
  try {
      const response = yield call(updateUserData, compData)
      console.log(response)
      // yield put(updateCompanySuccess(response))


     let tspUserData = {
      "record_offset": 0,
      "record_size": 10,
      "include_favorite_metadata": false,
      "user_identifier": user.username
      }


 getTspToken().then((tspToken) =>{


        let config = {
          method: 'post',
          url: 'https://smarteeva.thoughtspot.cloud/api/rest/2.0/users/search',
          headers: { 
            'Accept': 'application/json', 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+tspToken
          },
          data : tspUserData
        };

        axios.request(config)
.then((tspresponse) => {
  console.log(tspresponse.data.length);
  if(tspresponse.data.length > 0){
    // update

    let tspUpdate = {
      "operation": "REPLACE"
    }

    try {
      const Updateresponse = axios.post('https://smarteeva.thoughtspot.cloud/api/rest/2.0/users/'+user.username+'/update', tspUpdate, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer '+tspToken
        }
      });
      // console.log(Updateresponse.data);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  


  }else{
    //create

    console.log('in create')

    let tspData = {}
    if(user.companyid == 0){
  //public user
       tspData = {
        "name": user.username,
        "display_name": user.username,
        "email": user.email,
        "account_type": "LOCAL_USER",
        "account_status": "ACTIVE",
        "visibility": "NON_SHARABLE",
        "notify_on_share": true,
        "show_onboarding_experience": true,
        "onboarding_experience_completed": false,
        "password" : "WelcomeUser123!",
        "group_identifiers": [
          "6571364b-55e3-47a3-bc51-29f4fe784104"
        ]
      }
    
  
    }else{
  
  //corporate user
      tspData = {
        "name": user.username,
        "display_name": user.username,
        "email": user.email,
        "account_type": "LOCAL_USER",
        "account_status": "ACTIVE",
        "visibility": "NON_SHARABLE",
        "notify_on_share": true,
        "show_onboarding_experience": true,
        "onboarding_experience_completed": false,
        "password" : "WelcomeUser123!",
        "group_identifiers": [
          "8f15a112-4867-4419-bae0-2ada5ea6174c"
        ]
      }
    }

    try {
      const Createresponse = axios.post('https://smarteeva.thoughtspot.cloud/api/rest/2.0/users/create', tspData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer '+tspToken
        }
      });
      console.log(Createresponse.data);
    } catch (error) {
      console.error('Error creating user:', error);
    }
  

  }
})
.catch((error) => {
  console.log(error);
});
    
    })
    

      try {
          yield call(fetchUsers)
  localStorage.removeItem('UserDetails')
      history('/user-list');

      } catch (error) {
          yield put(updateUserFail(error))
  localStorage.removeItem('UserDetails')

      }

  } catch (error) {
      yield put(updateUserFail(error))
  localStorage.removeItem('UserDetails')

  }
}

function* onDeleteUser({ payload: user }) {

  yield put(setLoadingIndicatorUser(true));

  const userData = {
    userid : user.userid,
    updatedon : moment().format(),
    updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid
}

// console.log(Auth.currentAuthenticatedUser())

try {
  const userAuth = yield Auth.signIn(user.email, user.password)
} catch (error) {
    yield put(deleteUserSuccess(error))
}

console.log(JSON.parse(localStorage.getItem(authUser)))

try {
    const response = yield call(deleteUser, userData)


    let tspUserData = {
      "record_offset": 0,
      "record_size": 10,
      "include_favorite_metadata": false,
      "user_identifier": user.username
      }


 getTspToken().then((tspToken) =>{


        let config = {
          method: 'post',
          url: 'https://smarteeva.thoughtspot.cloud/api/rest/2.0/users/search',
          headers: { 
            'Accept': 'application/json', 
            'Content-Type': 'application/json', 
            'Authorization': 'Bearer '+tspToken
          },
          data : tspUserData
        };

        axios.request(config)
.then((tspresponse) => {
  console.log(tspresponse.data.length);
  if(tspresponse.data.length > 0){
    // update

    let tspUpdate = {
      "user_identifier": user.username,
      "base_url": "https://smarteeva.thoughtspot.cloud"
    }

    try {
      const Updateresponse = axios.post('https://smarteeva.thoughtspot.cloud/api/rest/2.0/users/deactivate', tspUpdate, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer '+tspToken
        }
      });
      // console.log(Updateresponse.data);
    } catch (error) {
      console.error('Error deactivating user:', error);
    }


  }
})
.catch((error) => {
  console.log(error);
});
    
    })
    
    // console.log(response)
    // history('/corporate-list');
    try {
    yield call(fetchUsers)
} catch (error) {
    yield put(deleteUserFail(error))
}

    // yield put(deleteCompanySuccess(response))
} catch (error) {
    yield put(deleteUserSuccess(error))
}
}

function* onAddNewUser({ payload: {data, history }}) {

  yield put(setLoadingIndicatorUser(true));

  const additional = {
    status : 'Active',
    approvalstatus : 'Pending',
    createdon : moment().format(),
    createdby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
    updatedon :  moment().format(),
    updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid,
}
const userData = {...data, ...additional}

try {
    const response = yield call(addNewUserData, userData)
    // yield put(addCompanySuccess(response))
   
    try {
        yield call(fetchUsers)
    history('/user-list');

    } catch (error) {
        yield put(addUserFail(error))
    }

} catch (error) {
    yield put(addUserFail(error))
}
}


function* fetchPhonecodeList() {


  // console.log('in fetch phone code list')
  try {
      const response = yield call(getPhonecodeList)
      // console.log(response)
      yield put(getPhonecodeListUserSuccess(response.data))
  } catch (error) {
      // console.log(error)
      yield put(getPhonecodeListUserFail(error))
  }
}


function* fetchCountryList() {

  yield put(setLoadingIndicatorUser(true));
  // console.log('in fetch country list')
  try {
      const response = yield call(getCountryList)
      // console.log(response)
      yield put(getCountryListUserSuccess(response.data))
  } catch (error) {
      // console.log(error)
      yield put(getCountryListUserFail(error))
  }
}


function* fetchStateList({ payload: data }) {

  yield put(setLoadingIndicatorUser(true));
  // console.log('in fetch phone code list')
  // console.log(data)
  const contData = {
      countryid : data
  }
  try {
      const response = yield call(getStateList, contData)
      // console.log(response)
      yield put(getStateListUserSuccess(response.data))
  } catch (error) {
      // console.log(error)
      yield put(getStateListUserFail(error))
  }
}




function* fetchCityList({ payload: data }) {

  yield put(setLoadingIndicatorUser(true));
  // console.log('in fetch city list')
  const cityData = {
      stateid : data
  }
  try {
      const response = yield call(getCityList, cityData)
      // console.log(response)
      yield put(getCityListUserSuccess(response.data))
  } catch (error) {
      // console.log(error)
      yield put(getCityListUserFail(error))
  }
}

function* fetchCompanyList() {

  yield put(setLoadingIndicatorUser(true));

  try {
      const response = yield call(getActiveCompanyUserList)
      // console.log(response)
      yield put(getCompanyListUserSuccess(response.data))
  } catch (error) {
      // console.log(error)
      yield put(getCompanyListUserFail(error))
  }
}

function* fetchActiveUserList({ payload: comp }) {

  yield put(setLoadingIndicatorUser(true));

  try {
      const response = yield call(getActiveUserOrg, comp)
      // console.log(response)
      yield put(getActiveUsersOrgSuccess(response.data))
  } catch (error) {
      // console.log(error)
      yield put(getActiveUsersOrgFail(error))
  }
}

function* fetchUserDetails({ payload: data }) {

  yield put(setLoadingIndicatorUser(true));

  // console.log(data)
  yield put(getUserDetailsSuccess(data))
}


function* onApproveUser({ payload: {data, history} }) {

  yield put(setLoadingIndicatorUser(true));

  const comp = {
      userid : data,
      updatedon : moment().format(),
      updatedby : JSON.parse(localStorage.getItem(authUser)).fld_userid
  }

  try {
      const response = yield call(approveUser, comp)
      // console.log(response)
      // yield put(approveCompanySuccess(response))

      try {
          yield call(fetchUsers)
      history('/user-list');

      } catch (error) {
          yield put(approveUserFail(error))
      }
  

  } catch (error) {
      yield put(approveUserFail(error))
  }
}


function* onSendUserInvite({ payload: data }) {

  yield put(setLoadingIndicatorUser(true));
  // console.log(data)

  try {
      const response = yield call(sendUserInvite, data)
      // console.log(response)
      // yield put(approveCompanySuccess(response))

      try {
          yield call(fetchUsers)
      // history('/user-list');

      } catch (error) {
          yield put(sendUserInviteFail(error))
      }
  

  } catch (error) {
      yield put(sendUserInviteFail(error))
  }
}


function* fetchGroupPermission() {

  yield put(setLoadingIndicatorUser(true));
  try {
    const response = yield call(getGroupPermissionList)
    console.log(response)
    
    yield put(getGroupPermissionSuccess(response.data))
} catch (error) {
    // console.log(error)
    yield put(getGroupPermissionFail(error))
}
 
}

function* fetchGroupPermissionSuccess() {

  yield put(setLoadingIndicatorUser(true));
  try {
    const response = yield call(getGroupPermissionList)
    console.log(response)
    
    yield put(updateGroupPermissionSuccess(response.data))
} catch (error) {
    // console.log(error)
    yield put(updateGroupPermissionFail(error))
}
 
}

function* onUpdateGroupPermission({ payload: {data, history }}) {

  console.log(data)
  const permissionData = {'permissionData' : data}
  yield put(setLoadingIndicatorUser(true));
  try {
    const response = yield call(updateGroupPermission, permissionData)
    // console.log(response)
    try {
      yield call(fetchGroupPermissionSuccess)
  } catch (error) {
    yield put(updateGroupPermissionFail(error))
  }

    yield put(updateGroupPermissionSuccess(response.data))
} catch (error) {
    // console.log(error)
    yield put(updateGroupPermissionFail(error))
}
 

}

function* contactsSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(GET_CORPORATE_DD, fetchCorporateDropdown)
  yield takeEvery(GET_PHONECODE_LIST_USER, fetchPhonecodeList)
  yield takeEvery(GET_COUNTRY_LIST_USER, fetchCountryList)
  yield takeEvery(GET_STATE_LIST_USER, fetchStateList)
  yield takeEvery(GET_CITY_LIST_USER, fetchCityList)
  yield takeEvery(GET_COMPANY_LIST_USER, fetchCompanyList)
  yield takeEvery(GET_ACTIVE_USER_ORG, fetchActiveUserList)
  yield takeEvery(GET_USER_DETAILS, fetchUserDetails)
  yield takeEvery(APPROVE_USER, onApproveUser)
  yield takeEvery(SEND_USER_INVITE, onSendUserInvite)
  yield takeEvery(GET_GROUP_PERMISSION, fetchGroupPermission)
  yield takeEvery(UPDATE_GROUP_PERMISSION, onUpdateGroupPermission)


}

export default contactsSaga;

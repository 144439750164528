import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  CHECK_SOCIAL_LOGIN,
  SET_LOADING_INDICATOR,
  LOGIN_USER_PERSONAL_DETAILS,
  GET_USER_PERSONAL_DETAILS,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  userPersonalData : {}
}

const login = (state = initialState, action) => {
  switch (action.type) {
    // case LOGIN_USER:
    //   state = {
    //     ...state,
    //     loading: false,
    //   }
    //   break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        isUserLogout: false,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state , isUserLogout: true}
      break
      // case CHECK_SOCIAL_LOGIN:
      // state = { ...state , loading: false}
      // break
    case API_ERROR:
      state = { ...state, error: action.payload, 
        loading: false,
        isUserLogout: false, }
      break

      case LOGIN_USER_PERSONAL_DETAILS:
        return {
          ...state,
          userPersonalData: action.payload,
          loading: false
        }
      case SET_LOADING_INDICATOR:
        state = {
          ...state,
          loading: action.payload,
        }
        break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login

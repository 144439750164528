import React, {useState, useEffect, useRef} from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Container, Row, Button, Form, Label, Input, FormFeedback, Alert } from "reactstrap";
// Formik Validation
import * as Yup from "yup";
import { useFormik, validateYupSchema } from "formik";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "./CarouselPageRegister";
import UIOTPInput from "../Ui/UIOTPInput";

import { useSelector, useDispatch } from "react-redux";

// action
import { userResetPassword } from "../../store/actions";
import Loader from "components/Common/Loader";

const ResetPassword = () => {

  const dispatch = useDispatch();
  const {state} = useLocation();
  const otpRef = useRef(null);

  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShowConfirm, setPasswordShowConfirm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  //meta title
  document.title="Reset Password | Smarteeva";


  const { resetSuccessMsg, resetError,loading  } = useSelector(state => ({
    resetError: state.ResetPassword.resetError,
    resetSuccessMsg: state.ResetPassword.resetSuccessMsg,
    loading: state.ResetPassword.loading,
  }));



  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      otp: '',
      password: '',
      confirmpassword : ''
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("Please Enter OTP"),
      password: Yup.string().required("Please Enter Your Password"),
      confirmpassword: Yup.string().required("Please Re-enter Your Password").oneOf([Yup.ref('password'), null], 'Passwords must match'),
  
    }),
    onSubmit: async(values, {resetForm}) => {
  
      setIsLoading(true)

      const email = state.email
     await dispatch(userResetPassword({...values,email}));
    }
  });

  const handleotpvalue = (value)=>{

const event = {
  target: {
    name: 'otp',
    value: value,
  },
};

validation.handleChange(event);
  }


  useEffect(() => {
    if(resetSuccessMsg){
      validation.resetForm()
      if (otpRef.current) {
        otpRef.current.resetOTPData();
      }
    }
  }, [resetSuccessMsg]);
 
  useEffect(() => {
    setIsLoading(loading)
  }, [loading]);

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
          <CarouselPage />
            <Col xl={7}>
              <div className="auth-full-page-content p-md-5 p-4 centerJustCustom">
                <div className="w-100 maxWidthCustom">
                  <div className="d-flex flex-column h-100 centerJustCustom">
                    <div className="mb-2 mb-md-3 centerCustom">
                      <Link to="dashboard" className="d-block auth-logo mb-3">
                        <img
                          src={logodark}
                          alt=""
                          height="70"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="70"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-3">
                      <div className="centerTextCustom">
                        <h5 className="text-primary welcome-text">Reset Password</h5>
                        <p className="text-muted">Re-Password with Smarteeva.
                        </p>
                      </div>

                      <div className="mt-4">

                       

                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >

{resetError ? (
                            <Alert color="danger">{resetError}</Alert>
                          ) :   null}
                       
{resetSuccessMsg ? (
  <Alert color="success"> Your password has been reset, kindly login. </Alert>
) : null}

{!resetSuccessMsg  & !resetError? (
<div className="alert alert-success text-center mb-4" role="alert">
                          Enter your new password to use with Smarteeva!
                        </div> ) :null}
                   

<div className="mb-3">
                            <Label className="form-label">Verification OTP</Label>
                          <UIOTPInput onChange={handleotpvalue}
                          ref={otpRef}
                          invalid={
                            validation.touched.otp && validation.errors.otp ? true : false
                          }
                          ></UIOTPInput>
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">New Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="password"
                              className="form-control"
                              placeholder="Enter new password"
                              type={passwordShow ? "text" : "password"}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                             <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i></button>

                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                            </div>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Retype New Password</Label>
                            <div className="input-group auth-pass-inputgroup">
                            <Input
                              name="confirmpassword"
                              className="form-control"
                              placeholder="Enter new password"
                              type={passwordShowConfirm ? "text" : "password"}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirmpassword || ""}
                              invalid={
                                validation.touched.confirmpassword && validation.errors.confirmpassword ? true : false
                              }
                            />
                              <button onClick={() => setPasswordShowConfirm(!passwordShowConfirm)} className="btn btn-light " type="button" id="password-addon">
                                <i className="mdi mdi-eye-outline"></i></button>

                            {validation.touched.confirmpassword && validation.errors.confirmpassword ? (
                              <FormFeedback type="invalid">{validation.errors.confirmpassword}</FormFeedback>
                            ) : null}
                          </div>
                          </div>

                          <div className="mt-3 text-end">
                            <Button
                              className="btn btn-primary btn-custome-color waves-effect waves-light width100"
                              type="submit"
                            >
                              Reset password
                            </Button>
                          </div>

                        </Form>
                        <div className="mt-5 text-center">
                          <p>
                            Remember It ?{" "}
                            <Link
                              to="/login"
                              className="fw-medium text-primary"
                            >
                              {" "}
                              Sign In here{" "}
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            

          </Row>
        </Container>
      </div>
    <Loader isLoading={isLoading} /> 

    </React.Fragment>
  );
};

export default ResetPassword;

import { useEffect } from 'react';
import { TokenService } from './tokenService';
import { useNavigate } from 'react-router-dom';
import { sessionStorage } from './dataConfig';

const useTokenExpiryCheck = (checkInterval = 60000) => { // Default check every 60 seconds
  const navigate = useNavigate();

  useEffect(() => {
    let intervalId; // Declare interval ID

    const checkTokenValidity = () => {
    //   console.log('Checking token validity...');
      
      if (TokenService.isTokenExpired()) {
        // console.log('Token expired');

        // Clear the interval to stop the service before navigating
        const userSession = JSON.parse(localStorage.getItem(sessionStorage));
        // Now navigate after clearing the interval
        // console.log(userSession)
        if(userSession != null){
        // TokenService.clearToken();
        navigate('/logout'); // Redirect to logout page or handle any custom logic
      } 
        console.log(intervalId)
        if (intervalId) {
          clearInterval(intervalId);
        //   console.log('Interval cleared');
        }

       
    }
    };

    // Initial token validity check on mount
    checkTokenValidity();

    // Set interval to check token periodically
    intervalId = setInterval(checkTokenValidity, checkInterval);

    // Clear interval when component unmounts to prevent memory leaks
    return () => clearInterval(intervalId);
    
  }, [navigate, checkInterval]); // Dependency array includes `navigate` and `checkInterval`
};

export default useTokenExpiryCheck;

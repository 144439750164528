import React, { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Tab, Nav,ModalTitle
} from 'react-bootstrap';
import Middlesection from './middlesection';
import MiddlesectionConf from './middlesection conf';
// import Accordion from 'react-bootstrap/Accordion';
// import Form from 'react-bootstrap/Form';
import {   Accordion, Form } from 'react-bootstrap';

import {
    Button,
     ButtonGroup,
     CardTitle, 
     Label,
     Input,
     Col,
     InputGroup,
     Modal,
     ModalHeader,
     Row,
     ModalBody
    //  Accordion, 
    //  Form

  } from "reactstrap";
import Select from 'react-select';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper'
import moment from 'moment';
import TabComponentPreview2 from './TabComponentPreview2';
import TabComponentPreview from './TabComponentPreview';
import { Link , useNavigate} from "react-router-dom"
import Loader from "components/Common/Loader";
import { v4 as uuid } from "uuid";

//redux
import { useSelector, useDispatch } from "react-redux";
import { saveTabData, saveDraftTabData, getDraftTabData } from 'store/actions';

const MyTabs = React.forwardRef((props, ref) => {

    const boxRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [activeLeftTab, setActiveLeftTab] = useState('ContentTab');
    const [activeRightTab, setActiveRightTab] = useState('Question');
    const [selectQuestionTypeVisible, setSelectQuestionTypeVisible] = useState(false);

    const [questionData, setQuestionData] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState({});
    const [configError, setConfigError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [counter, setCounter] = useState(0)

    const [questionTypeOptions, setQuestionTypeOptions] = useState([
        {label : 'Short Text', value : 'shorttext'},
        {label : 'Long Text', value : 'longtext'},
        {label : 'Number', value : 'number'},
        {label : 'Date', value : 'date'},
        {label : 'Dropdown', value : 'dropdown'},
        {label : 'Multiple Choice', value : 'multiplechoice'},
        {label : 'Yes/No', value : 'yes/no'},
        {label : 'File Upload', value : 'fileupload'},
        {label : 'Google Drive', value : 'googledrive'},
        {label : 'Link', value : 'link'}
    ]);

    const [fieldTypeOptions, setFieldTypeOptions] = useState([
        {label : 'Heading', value : 'heading'},
        {label : 'Paragraph', value : 'paragraph'},
        {label : 'Attachment', value : 'attachment'},
        {label : 'Hyperlink', value : 'hyperlink'},
      
    ]);

    const [logicRule, setLogicRule] = useState([])
    const [logicConditionOptions, setLogicConditionOptions] = useState([
        {label : 'is', value : 'is'},
        {label : 'equal', value : 'equal'}
    ]);

    const [tabData, setTabData] = useState([])
    const [selectedTab, setSelectedTab] = useState({})
    const [showPreviewModal, setShowPreviewModal] = useState(false)

    const { error, loading, draftData } = useSelector(state => ({
        error: state.DecisionTree.error,
        loading : state.DecisionTree.loading,
        draftData : state.DecisionTree.draftData
      }));

    //   useEffect(() => {
    //     setIsLoading(loading)
    // }, [loading]);

    const handleLeftTabChange = (tabKey) => {
        setActiveLeftTab(tabKey);
    };

    const handleRightTabChange = (tabKey) => {
        setActiveRightTab(tabKey);
    };

    const isLogicTabActive = activeRightTab === 'Logic';

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const showQuestionCard = (questionNumber) => {
        // logic to show the question card here
    };

    const showDeleteConfirmation = (event) => {
        const questionNumber = event.currentTarget.getAttribute('data-question-number');
        // logic to show delete confirmation for the specified question number 
    };

    // useEffect(() => {
       
    //     }, [selectQuestionTypeVisible]);


    const handleClickOutside = (event) => {
       
        if (boxRef.current && !boxRef.current.contains(event.target)) {
       setSelectQuestionTypeVisible(false)
        // boxRef.current = null;
        }
      };

    useEffect(() => {
        // Attach event listener when the component mounts
        document.addEventListener('click', handleClickOutside);
    
        // Detach event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, [selectQuestionTypeVisible]); // Empty dependency array ensures the effect runs only once
    

      useEffect(() => {
        // console.log(selectedQuestion)
      }, [selectedQuestion]);



    //   useEffect(() => {

    //     if(draftData[0] != undefined){
    //     setQuestionData(JSON.parse(draftData[0].FLD_QUESTIONDATA))
    //     setTabData(JSON.parse(draftData[0].FLD_TABATA))
    //     setLogicRule(JSON.parse(draftData[0].FLD_LOGICDATA))
    //     setSelectedQuestion(JSON.parse(draftData[0].FLD_QUESTIONDATA)[0])
    //     setSelectedTab(JSON.parse(draftData[0].FLD_TABATA)[0])
    //     }
       
    //   }, [draftData]);

      useEffect(() => {

        if(tabData && !tabData.length){
            // console.log('call refresh')
            refreshTabData()
        }

        // refreshTabData()
        // console.log(draftData[0])
    //     if(tabData && !tabData.length){

    //         console.log('tab empty')

    //     if(draftData[0] != undefined){

    //         // console.log(JSON.parse(draftData[0].FLD_TABATA))
    //         setQuestionData(JSON.parse(draftData[0].FLD_QUESTIONDATA))
    //         setTabData(JSON.parse(draftData[0].FLD_TABATA))
    //         setLogicRule(JSON.parse(draftData[0].FLD_LOGICDATA))
    //         setSelectedQuestion(JSON.parse(draftData[0].FLD_QUESTIONDATA)[0])
    //         setSelectedTab(JSON.parse(draftData[0].FLD_TABATA)[0])


    //     }else{

    //         // console.log(props.moduleData)

    //     dispatch(getDraftTabData(props.moduleData, navigate));

    //     const isRefreshed = performance.navigation.type === 1;
    //     const isRedirected = performance.navigation.type === 2;
    
    //     if(isRefreshed){
    //         let qDt = localStorage.getItem('QuestionData'+props.moduleData.FLD_MODULEID)
    //         if(qDt != null){
    //             setQuestionData(JSON.parse(qDt))
    //             setSelectedQuestion(JSON.parse(qDt)[0])
    //         }

    //         let tDt = localStorage.getItem('TabData'+props.moduleData.FLD_MODULEID)
    //         // console.log(JSON.parse(tDt)) 
    //         if(tDt != null && tDt.length > 0){
    //             setTabData(JSON.parse(tDt))
    //             setSelectedTab(JSON.parse(tDt)[0])
    //         }


    //         let tDt1 = localStorage.getItem('LogicRuleData'+props.moduleData.FLD_MODULEID)
    //         // console.log(JSON.parse(tDt))
    //         if(tDt1 != null && tDt1.length > 0){
    //             setLogicRule(JSON.parse(tDt1))
    //         }

    //     }
    // }
    // }
    
      }, [performance.navigation.type, tabData, dispatch]);



      useEffect(() => {
        if(draftData[0] != undefined && parseInt(draftData[0].FLD_MODULEID) == parseInt(props.moduleData.FLD_MODULEID)){
    
            console.log('in draft data')
            // console.log(JSON.parse(draftData[0].FLD_TABATA))
            setQuestionData(JSON.parse(draftData[0].FLD_QUESTIONDATA))
            setTabData(JSON.parse(draftData[0].FLD_TABATA))
            setLogicRule(JSON.parse(draftData[0].FLD_LOGICDATA))
            setSelectedQuestion(JSON.parse(draftData[0].FLD_QUESTIONDATA)[0])
            setSelectedTab(JSON.parse(draftData[0].FLD_TABATA)[0])
        // setIsLoading(false)
            
        let qDt = localStorage.getItem('QuestionData'+props.moduleData.FLD_MODULEID)
        if(qDt != null){
            // console.log(JSON.parse(qDt))
            setQuestionData(JSON.parse(qDt))
            setSelectedQuestion(JSON.parse(qDt)[0])
        }

        let tDt = localStorage.getItem('TabData'+props.moduleData.FLD_MODULEID)
        // console.log(JSON.parse(tDt)) 
        if(tDt != null && tDt.length > 0){
            setTabData(JSON.parse(tDt))
            setSelectedTab(JSON.parse(tDt)[0])
        }


        let tDt1 = localStorage.getItem('LogicRuleData'+props.moduleData.FLD_MODULEID)
        // console.log(JSON.parse(tDt))
        if(tDt1 != null && tDt1.length > 0){
            setLogicRule(JSON.parse(tDt1))
        }

        }
      }, [draftData]);



      const refreshTabData = () =>{
            //  console.log(props.moduleData)
             if(tabData && !tabData.length){

                console.log('tab empty')
    
            if(draftData[0] != undefined && parseInt(draftData[0].FLD_MODULEID) == parseInt(props.moduleData.FLD_MODULEID)){
    
                console.log('in draft data')
                // console.log(JSON.parse(draftData[0].FLD_TABATA))
                setQuestionData(JSON.parse(draftData[0].FLD_QUESTIONDATA))
                setTabData(JSON.parse(draftData[0].FLD_TABATA))
                setLogicRule(JSON.parse(draftData[0].FLD_LOGICDATA))
                setSelectedQuestion(JSON.parse(draftData[0].FLD_QUESTIONDATA)[0])
                setSelectedTab(JSON.parse(draftData[0].FLD_TABATA)[0])
            // setIsLoading(false)
                
    
            }else{
    
                console.log('new data')
    
            dispatch(getDraftTabData(props.moduleData, navigate));
    
            const isRefreshed = performance.navigation.type === 1;
            const isRedirected = performance.navigation.type === 2;

            // console.log(isRefreshed)
        
            if(isRefreshed && !draftData){
                let qDt = localStorage.getItem('QuestionData'+props.moduleData.FLD_MODULEID)
                if(qDt != null){
                    setQuestionData(JSON.parse(qDt))
                    setSelectedQuestion(JSON.parse(qDt)[0])
                }
    
                let tDt = localStorage.getItem('TabData'+props.moduleData.FLD_MODULEID)
                // console.log(JSON.parse(tDt)) 
                if(tDt != null && tDt.length > 0){
                    setTabData(JSON.parse(tDt))
                    setSelectedTab(JSON.parse(tDt)[0])
                }
    
    
                let tDt1 = localStorage.getItem('LogicRuleData'+props.moduleData.FLD_MODULEID)
                // console.log(JSON.parse(tDt))
                if(tDt1 != null && tDt1.length > 0){
                    setLogicRule(JSON.parse(tDt1))
                }

            // setIsLoading(false)

    
            }
        }
        }
        
      }

      

    //   useEffect(() => {
    //     const isRefreshed = performance.navigation.type === 1;
    //     const isRedirected = performance.navigation.type === 2;
    //     if(isRefreshed){
    //         let tDt = localStorage.getItem('TabData'+props.moduleData.FLD_MODULEID)
    //         // console.log(JSON.parse(tDt))
    //         if(tDt != null && tDt.length > 0){
    //             setTabData(JSON.parse(tDt))
    //             setSelectedTab(JSON.parse(tDt)[0])
    //         }
    //     }
     
    //   }, [performance.navigation.type]);

    //   useEffect(() => {
    //     const isRefreshed = performance.navigation.type === 1;
    //     const isRedirected = performance.navigation.type === 2;
    //     if(isRefreshed){
    //         let tDt = localStorage.getItem('LogicRuleData'+props.moduleData.FLD_MODULEID)
    //         // console.log(JSON.parse(tDt))
    //         if(tDt != null && tDt.length > 0){
    //             setLogicRule(JSON.parse(tDt))
    //         }
    //     }
     
    //   }, [performance.navigation.type]);

      useEffect(() => {
       
        // console.log('in refresh')
       setSelectedTab([])
       setSelectedQuestion([])
       setQuestionData([])
       setLogicRule([])
       setTabData([])
       
 if(draftData[0]  && draftData[0].FLD_MODULEID == props.moduleData.FLD_MODULEID){
    refreshTabData()

        }

    }, []);

    const addField = (selectedOption) => {
        let newField;
        let numData = questionData.length == 0 ? 1 : questionData[questionData.length - 1].num + 1
    
        switch (selectedOption) {
            case 'shorttext':
                newField = {uid : uuid(), type: 'shorttext', subType : '', value: '',score : 0, duedate : '', num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false };
                break;
            case 'longtext':
                newField = { uid : uuid(), type: 'longtext',subType : '', value: '',score : 0, duedate : '', num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false};
                break;
            case 'number':
                newField = { uid : uuid(), type: 'number', subType : '',value: '',score : 0, duedate : '', num: numData ,  questionText : '', required : false, showcomments : false, commentsrequired : false};
                break;
            case 'date':
                newField = { uid : uuid(), type: 'date',subType : '', value: '',score : 0, duedate : '', num: numData,  questionText : '' , required : false, showcomments : false, commentsrequired : false};
                break;
            case 'dropdown':
                newField = { uid : uuid(), type: 'dropdown',subType : '', choices: [], num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false};
                break;
            case 'multiplechoice':
                newField = { uid : uuid(), type: 'multiplechoice', subType : '',choices: [], num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false};
                break;
            case 'yes/no':
                newField = { uid : uuid(), type: 'yes/no', subType : '',choices: [{value : 'Yes', score : 0,duedate : ''}, {value : 'No', score : 0,duedate : ''}], num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false};
                break;
            case 'fileupload':
                newField = { uid : uuid(), type: 'fileupload', subType : '',file: null,score : 0, duedate : '', num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false};
                break;
            case 'googledrive':
                newField = { uid : uuid(), type: 'googledrive',subType : '', link: null,score : 0, duedate : '', num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false };
                break;
            case 'link':
                newField = { uid : uuid(), type: 'link', subType : '',link: null,score : 0, duedate : '', num: numData,  questionText : '' , required : false, showcomments : false, commentsrequired : false};
                break;
            default:
                console.error('Unknown option selected');
                return;
        }
    
        if(questionData.length <= 0){
            setSelectedQuestion(newField)
        }
        setSelectQuestionTypeVisible(false)
        setQuestionData([...questionData, newField]);

        onSetQuestionDataStorage([...questionData, newField])
      };


      const updateField = (selectedOption, num) => {
        let newField;
        let numData = num
    
        switch (selectedOption) {
            case 'shorttext':
                newField = {uid : uuid(), type: 'shorttext', subType : '', value: '', score : 0, duedate : '', num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false };
                break;
            case 'longtext':
                newField = {uid : uuid(), type: 'longtext',subType : '', value: '', score : 0, duedate : '', num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false};
                break;
            case 'number':
                newField = {uid : uuid(), type: 'number', subType : '',value: '', score : 0,duedate : '', num: numData ,  questionText : '', required : false, showcomments : false, commentsrequired : false};
                break;
            case 'date':
                newField = {uid : uuid(), type: 'date',subType : '', value: '', score : 0, duedate : '', num: numData,  questionText : '' , required : false, showcomments : false, commentsrequired : false};
                break;
            case 'dropdown':
                newField = {uid : uuid(), type: 'dropdown',subType : '', choices: [], num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false};
                break;
            case 'multiplechoice':
                newField = {uid : uuid(), type: 'multiplechoice', subType : '',choices: [], num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false};
                break;
            case 'yes/no':
                newField = {uid : uuid(), type: 'yes/no', subType : '',choices: [{value : 'Yes', score : 0, duedate : ''}, {value : 'No', score : 0, duedate : ''}], num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false};
                break;
            case 'fileupload':
                newField = {uid : uuid(), type: 'fileupload', subType : '',file: null, score : 0, duedate : '', num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false};
                break;
            case 'googledrive':
                newField = {uid : uuid(), type: 'googledrive',subType : '', link: null, score : 0, duedate : '', num: numData,  questionText : '', required : false, showcomments : false, commentsrequired : false };
                break;
            case 'link':
                newField = {uid : uuid(), type: 'link', subType : '',link: null, score : 0, duedate : '', num: numData,  questionText : '' , required : false, showcomments : false, commentsrequired : false};
                break;
            default:
                console.error('Unknown option selected');
                return;
        }
    
        // if(questionData.length <= 0){
        //     setSelectedQuestion(newField)
        // }
        // setSelectQuestionTypeVisible(false)
        // setQuestionData([...questionData, newField]);

        return newField;

      };


      const addInputField = (event) => {
        // Your addInputField function logic here
        onAddQuestionDataChoice()
      };


    const selectQuestion = () =>{
        return (
            <div className="dropdown-content01" >
            <div className="section-outer">
                <div className="section">
                    <h3>TextBox</h3>
                    <ul>
                        <li>
                            <div className="add-options" data-type="shorttext" 
                            // onclick="addNewQuestion(this)"
                            onClick={()=>{
                                addField('shorttext')
                            }}
                            >
                                <div className="icon-options-contain">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" id="short-text">
                                        <path d="M13,13.5H3a1,1,0,0,0,0,2H13a1,1,0,0,0,0-2Zm8-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z" fill="#369BFF" /></svg>
                                </div>
                             
                                <div className='question-select-text'>Short Text</div>
                            </div>
                        </li>
                        <li>
                            <div className="add-options" data-type="longtext" 
                           onClick={()=>{
                            addField('longtext')
                        }}
                            >
                                <div className="icon-options-contain">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                        viewBox="0 0 19 18" fill="none">
                                        <path
                                            d="M13.3333 3.16669H15.8333L14.5833 4.83335L13.3333 3.16669ZM1.66667 0.666687H16.6667C17.5917 0.666687 18.3333 1.41669 18.3333 2.33335V5.66669C18.3333 6.59169 17.5917 7.33335 16.6667 7.33335H12.5V15.6667C12.5 16.5917 11.7583 17.3334 10.8333 17.3334H1.66667C0.75 17.3334 0 16.5917 0 15.6667V2.33335C0 1.41669 0.75 0.666687 1.66667 0.666687ZM1.66667 2.33335V5.66669H10.8333V2.33335H1.66667ZM16.6667 5.66669V2.33335H12.5V5.66669H16.6667ZM1.66667 15.6667H10.8333V7.33335H1.66667V15.6667ZM3.33333 9.00002H9.16667V10.6667H3.33333V9.00002ZM3.33333 12.3334H9.16667V14H3.33333V12.3334Z"
                                            fill="#369BFF" />
                                    </svg>
                                </div>
                                <div  className='question-select-text'>Long Text</div>
                            </div>
                        </li>
                        <li>
                            <div className="add-options" data-type="number" 
                               onClick={()=>{
                                addField('number')
                            }}
                            >
                                <div className="icon-options-contain">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                        viewBox="0 0 19 18" fill="none">
                                        <path
                                            d="M13.3333 3.16669H15.8333L14.5833 4.83335L13.3333 3.16669ZM1.66667 0.666687H16.6667C17.5917 0.666687 18.3333 1.41669 18.3333 2.33335V5.66669C18.3333 6.59169 17.5917 7.33335 16.6667 7.33335H12.5V15.6667C12.5 16.5917 11.7583 17.3334 10.8333 17.3334H1.66667C0.75 17.3334 0 16.5917 0 15.6667V2.33335C0 1.41669 0.75 0.666687 1.66667 0.666687ZM1.66667 2.33335V5.66669H10.8333V2.33335H1.66667ZM16.6667 5.66669V2.33335H12.5V5.66669H16.6667ZM1.66667 15.6667H10.8333V7.33335H1.66667V15.6667ZM3.33333 9.00002H9.16667V10.6667H3.33333V9.00002ZM3.33333 12.3334H9.16667V14H3.33333V12.3334Z"
                                            fill="#369BFF" />
                                    </svg>
                                </div>
                                <div className='question-select-text'>Number</div>
                            </div>
                        </li>
                        <li>
                            <div className="add-options" data-type="date" 
                               onClick={()=>{
                                addField('date')
                            }}
                            >
                                <div className="icon-options-contain">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                        viewBox="0 0 19 18" fill="none">
                                        <path
                                            d="M13.3333 3.16669H15.8333L14.5833 4.83335L13.3333 3.16669ZM1.66667 0.666687H16.6667C17.5917 0.666687 18.3333 1.41669 18.3333 2.33335V5.66669C18.3333 6.59169 17.5917 7.33335 16.6667 7.33335H12.5V15.6667C12.5 16.5917 11.7583 17.3334 10.8333 17.3334H1.66667C0.75 17.3334 0 16.5917 0 15.6667V2.33335C0 1.41669 0.75 0.666687 1.66667 0.666687ZM1.66667 2.33335V5.66669H10.8333V2.33335H1.66667ZM16.6667 5.66669V2.33335H12.5V5.66669H16.6667ZM1.66667 15.6667H10.8333V7.33335H1.66667V15.6667ZM3.33333 9.00002H9.16667V10.6667H3.33333V9.00002ZM3.33333 12.3334H9.16667V14H3.33333V12.3334Z"
                                            fill="#369BFF" />
                                    </svg>
                                </div>
                                <div className='question-select-text'>Date</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="section">
                    <h3>Choices</h3>
                    <ul>
                        <li>
                            <div className="add-options" data-type="dropdown" 
                             onClick={()=>{
                                addField('dropdown')
                            }}
                            >
                                <div className="icon-options-contain">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                        viewBox="0 0 19 18" fill="none">
                                        <path
                                            d="M13.3333 3.16669H15.8333L14.5833 4.83335L13.3333 3.16669ZM1.66667 0.666687H16.6667C17.5917 0.666687 18.3333 1.41669 18.3333 2.33335V5.66669C18.3333 6.59169 17.5917 7.33335 16.6667 7.33335H12.5V15.6667C12.5 16.5917 11.7583 17.3334 10.8333 17.3334H1.66667C0.75 17.3334 0 16.5917 0 15.6667V2.33335C0 1.41669 0.75 0.666687 1.66667 0.666687ZM1.66667 2.33335V5.66669H10.8333V2.33335H1.66667ZM16.6667 5.66669V2.33335H12.5V5.66669H16.6667ZM1.66667 15.6667H10.8333V7.33335H1.66667V15.6667ZM3.33333 9.00002H9.16667V10.6667H3.33333V9.00002ZM3.33333 12.3334H9.16667V14H3.33333V12.3334Z"
                                            fill="#369BFF" />
                                    </svg>
                                </div>
                                <div className='question-select-text'>Dropdown</div>
                            </div>
                        </li>
                        <li>
                            <div className="add-options" data-type="multiplechoice" 
                               onClick={()=>{
                                addField('multiplechoice')
                            }}
                            >
                                <div className="icon-options-contain">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                        viewBox="0 0 19 18" fill="none">
                                        <path
                                            d="M13.3333 3.16669H15.8333L14.5833 4.83335L13.3333 3.16669ZM1.66667 0.666687H16.6667C17.5917 0.666687 18.3333 1.41669 18.3333 2.33335V5.66669C18.3333 6.59169 17.5917 7.33335 16.6667 7.33335H12.5V15.6667C12.5 16.5917 11.7583 17.3334 10.8333 17.3334H1.66667C0.75 17.3334 0 16.5917 0 15.6667V2.33335C0 1.41669 0.75 0.666687 1.66667 0.666687ZM1.66667 2.33335V5.66669H10.8333V2.33335H1.66667ZM16.6667 5.66669V2.33335H12.5V5.66669H16.6667ZM1.66667 15.6667H10.8333V7.33335H1.66667V15.6667ZM3.33333 9.00002H9.16667V10.6667H3.33333V9.00002ZM3.33333 12.3334H9.16667V14H3.33333V12.3334Z"
                                            fill="#369BFF" />
                                    </svg>
                                </div>
                                <div className='question-select-text'> Multiple Choice</div>
                            </div>
                        </li>
                        <li>
                            <div className="add-options" data-type="yesno" 
                               onClick={()=>{
                                addField('yes/no')
                            }}
                            >
                                <div className="icon-options-contain">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                        viewBox="0 0 19 18" fill="none">
                                        <path
                                            d="M13.3333 3.16669H15.8333L14.5833 4.83335L13.3333 3.16669ZM1.66667 0.666687H16.6667C17.5917 0.666687 18.3333 1.41669 18.3333 2.33335V5.66669C18.3333 6.59169 17.5917 7.33335 16.6667 7.33335H12.5V15.6667C12.5 16.5917 11.7583 17.3334 10.8333 17.3334H1.66667C0.75 17.3334 0 16.5917 0 15.6667V2.33335C0 1.41669 0.75 0.666687 1.66667 0.666687ZM1.66667 2.33335V5.66669H10.8333V2.33335H1.66667ZM16.6667 5.66669V2.33335H12.5V5.66669H16.6667ZM1.66667 15.6667H10.8333V7.33335H1.66667V15.6667ZM3.33333 9.00002H9.16667V10.6667H3.33333V9.00002ZM3.33333 12.3334H9.16667V14H3.33333V12.3334Z"
                                            fill="#369BFF" />
                                    </svg>
                                </div>
                                <div className='question-select-text'>Yes/No</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="section">
                    <h3>File Upload</h3>
                    <ul>
                        <li>
                            <div className="add-options" data-type="file-upload" 
                               onClick={()=>{
                                addField('fileupload')
                            }}
                            >
                                <div className="icon-options-contain">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                        viewBox="0 0 19 18" fill="none">
                                        <path
                                            d="M13.3333 3.16669H15.8333L14.5833 4.83335L13.3333 3.16669ZM1.66667 0.666687H16.6667C17.5917 0.666687 18.3333 1.41669 18.3333 2.33335V5.66669C18.3333 6.59169 17.5917 7.33335 16.6667 7.33335H12.5V15.6667C12.5 16.5917 11.7583 17.3334 10.8333 17.3334H1.66667C0.75 17.3334 0 16.5917 0 15.6667V2.33335C0 1.41669 0.75 0.666687 1.66667 0.666687ZM1.66667 2.33335V5.66669H10.8333V2.33335H1.66667ZM16.6667 5.66669V2.33335H12.5V5.66669H16.6667ZM1.66667 15.6667H10.8333V7.33335H1.66667V15.6667ZM3.33333 9.00002H9.16667V10.6667H3.33333V9.00002ZM3.33333 12.3334H9.16667V14H3.33333V12.3334Z"
                                            fill="#369BFF" />
                                    </svg>
                                </div>
                                <div className='question-select-text'>File Upload</div>
                            </div>
                        </li>
                        <li>
                            <div className="add-options" data-type="google-upload" 
                              onClick={()=>{
                                addField('googledrive')
                            }}
                            >
                                <div className="icon-options-contain">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                        viewBox="0 0 19 18" fill="none">
                                        <path
                                            d="M13.3333 3.16669H15.8333L14.5833 4.83335L13.3333 3.16669ZM1.66667 0.666687H16.6667C17.5917 0.666687 18.3333 1.41669 18.3333 2.33335V5.66669C18.3333 6.59169 17.5917 7.33335 16.6667 7.33335H12.5V15.6667C12.5 16.5917 11.7583 17.3334 10.8333 17.3334H1.66667C0.75 17.3334 0 16.5917 0 15.6667V2.33335C0 1.41669 0.75 0.666687 1.66667 0.666687ZM1.66667 2.33335V5.66669H10.8333V2.33335H1.66667ZM16.6667 5.66669V2.33335H12.5V5.66669H16.6667ZM1.66667 15.6667H10.8333V7.33335H1.66667V15.6667ZM3.33333 9.00002H9.16667V10.6667H3.33333V9.00002ZM3.33333 12.3334H9.16667V14H3.33333V12.3334Z"
                                            fill="#369BFF" />
                                    </svg>
                                </div>
                                <div className='question-select-text'>Google Drive</div>
                            </div>
                        </li>
                        <li>
                            <div className="add-options" data-type="link-upload" 
                               onClick={()=>{
                                addField('link')
                            }}
                            >
                                <div className="icon-options-contain">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                        viewBox="0 0 19 18" fill="none">
                                        <path
                                            d="M13.3333 3.16669H15.8333L14.5833 4.83335L13.3333 3.16669ZM1.66667 0.666687H16.6667C17.5917 0.666687 18.3333 1.41669 18.3333 2.33335V5.66669C18.3333 6.59169 17.5917 7.33335 16.6667 7.33335H12.5V15.6667C12.5 16.5917 11.7583 17.3334 10.8333 17.3334H1.66667C0.75 17.3334 0 16.5917 0 15.6667V2.33335C0 1.41669 0.75 0.666687 1.66667 0.666687ZM1.66667 2.33335V5.66669H10.8333V2.33335H1.66667ZM16.6667 5.66669V2.33335H12.5V5.66669H16.6667ZM1.66667 15.6667H10.8333V7.33335H1.66667V15.6667ZM3.33333 9.00002H9.16667V10.6667H3.33333V9.00002ZM3.33333 12.3334H9.16667V14H3.33333V12.3334Z"
                                            fill="#369BFF" />
                                    </svg>
                                </div>
                                <div className='question-select-text'>Link</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        )
    }

   const onShowNextQuestion = ()=>{
    const currentIndex = questionData.indexOf(selectedQuestion);
    const nextIndex = (currentIndex + 1) % questionData.length;
        // console.log(questionData[nextIndex])
        if(currentIndex < questionData.length-1){
            setSelectedQuestion(questionData[nextIndex])
        }
    }

    const onShowPreviousQuestion = ()=>{
        const currentIndex = questionData.indexOf(selectedQuestion);
        const nextIndex = (currentIndex - 1) % questionData.length;  
        // console.log(questionData[nextIndex])
        if(currentIndex > 0){
        setSelectedQuestion(questionData[nextIndex])
        }
        }


        const onAddQuestionDataChoice = () =>{
            let mainQuestionIndex = questionData.indexOf(selectedQuestion)
            let mainData = [...questionData]

            mainData[mainQuestionIndex].choices.push({uid : uuid(),value : '', score:0, duedate : ''})

            setQuestionData(mainData)
            setSelectedQuestion(mainData[mainQuestionIndex])

            
            onSetQuestionDataStorage(mainData)


            let tabDt = [...tabData]
            // let selTab = selectedTab

            for(let i=0;i<tabData.length;i++){
                for(let j=0;j<tabData[i].questionData.length;j++){
                    if(tabData[i].questionData[j].num == selectedQuestion.num){
                        tabDt[i].questionData[j].choices.push({uid : uuid(),value : '', score:0, duedate : ''})
                    }
                }  
            }
            setTabData(tabDt)

            // for(let j=0;j<selectedTab.questionData.length;j++){
            //     if(selectedTab.questionData[j].num  == selectedQuestion.num){
            //         selTab.questionData[j].choices.push('')
            //     }
            // } 
            
            // setSelectedTab(selTab)
        }

        const removeDropdownChoice = (indx) =>{
            // console.log(indx)
            let mainQuestionIndex = questionData.indexOf(selectedQuestion)
            let mainData = [...questionData]

            mainData[mainQuestionIndex].choices.splice(indx, 1)

            setQuestionData(mainData)
            setSelectedQuestion(mainData[mainQuestionIndex])

            onSetQuestionDataStorage(mainData)


            let tabDt = [...tabData]
            let selTab = selectedTab

            for(let i=0;i<tabData.length;i++){
                for(let j=0;j<tabData[i].questionData.length;j++){
                    if(tabData[i].questionData[j].num == selectedQuestion.num){
                        tabDt[i].questionData[j].choices.splice(indx, 1)
                    }
                }  
            }
            setTabData(tabDt)
            onSetTabDataStorage(tabDt)

            // for(let j=0;j<selectedTab.questionData.length;j++){
            //     if(selectedTab.questionData[j].num  == selectedQuestion.num){
            //         selTab.questionData[j].choices.splice(indx, 1)
            //     }
            // } 
            
            // setSelectedTab(selTab)

        }

        const updateDropdownChoice = (indx, choice) =>{

            // console.log(choice)

            let mainQuestionIndex = questionData.indexOf(selectedQuestion)
            let mainData = [...questionData]

            mainData[mainQuestionIndex].choices[indx].value = choice

            // console.log(mainData)

            setQuestionData(mainData)
            setSelectedQuestion(mainData[mainQuestionIndex])

            onSetQuestionDataStorage(mainData)

            let tabDt = [...tabData]
            // let selTab = selectedTab

            for(let i=0;i<tabData.length;i++){
                for(let j=0;j<tabData[i].questionData.length;j++){
                    if(tabData[i].questionData[j].num == selectedQuestion.num){
                        tabDt[i].questionData[j].choices[indx].value = choice
                    }
                }  
            }
            setTabData(tabDt)
            onSetTabDataStorage(tabDt)
            // for(let j=0;j<selectedTab.questionData.length;j++){
            //     if(selectedTab.questionData[j].num  == selectedQuestion.num){
            //         selTab.questionData[j].choices[indx] = choice
            //     }
            // } 
            
            // setSelectedTab(selTab)
        }


    
      
      
        const onShowNextTab = ()=>{
          const currentIndex = tabData.indexOf(selectedTab);
          const nextIndex = (currentIndex + 1) % tabData.length;
              // console.log(questionData[nextIndex])
              if(currentIndex < tabData.length-1){
                  setSelectedTab(tabData[nextIndex])
              }
          }
      
          const onShowPrevioustab = ()=>{
              const currentIndex = tabData.indexOf(selectedTab);
              const nextIndex = (currentIndex - 1) % tabData.length;  
              // console.log(questionData[nextIndex])
              if(currentIndex > 0){
                setSelectedTab(tabData[nextIndex])
              }
              }
      


        const addNewTabs = () =>{

            setConfigError('')

            let tabDt = {
              uid : uuid(),
              tabno : tabData.length + 1,
              tabname : '',
              tabnameedit : false,
              questionData : [],
  
            }
            if(tabData.length <= 0){
              setSelectedTab(tabDt)
            }
            let tabsInfo = [...tabData]

            tabsInfo.push(tabDt)
  
            setTabData(tabsInfo)
            // console.log(tabsInfo)
            onSetTabDataStorage(tabsInfo)
          }


          const onActivateSelectedTab = (tab) =>{
            setSelectedTab(tab)
          }


          const AddQuestionsToTab = (question) => {

            if(Object.keys(selectedTab).length !== 0){
            // console.log(question)
            setConfigError('')

            let tabDt = [...tabData]
            let tabIndex = tabData.indexOf(selectedTab)


            // console.log(tabDt[tabIndex])
            // if(tabDt[tabIndex] == undefined){
            //     tabDt[tabIndex] = []
            //     tabDt[tabIndex].questionData = []
            // }

            tabDt[tabIndex].questionData.push({...question, serial : tabDt[tabIndex].questionData.length + 1})

            // console.log(tabDt)
            setSelectedTab(tabDt[tabIndex])
            setTabData(tabDt)
            onSetTabDataStorage(tabDt)


            }else{
                setConfigError('Please add tabs to add questions.')
            }

          }

  const ShortTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-2">
      <Input
    //   disabled 
      name="key"
      type="textarea"
      rows={3}
      placeholder=""
      // onChange={validation.handleChange}
      // onBlur={validation.handleBlur}
      // value={selectedQuestion.questionText || ""}
      // onChange={(e)=>{
      //     let mainQuestionIndex = questionData.indexOf(selectedQuestion)
      //     let mainData = [...questionData]

      //     mainData[mainQuestionIndex].questionText = e.target.value

      //     setQuestionData(mainData)
      //     setSelectedQuestion(mainData[mainQuestionIndex])
      //   }}
  />
   </div>
   </Col>
    )
  }


  const LongTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-2">
      <Input
    //   disabled
      name="key"
      type="textarea"
      rows={7}
      placeholder=""
      // onChange={validation.handleChange}
      // onBlur={validation.handleBlur}
      // value={selectedQuestion.questionText || ""}
      // onChange={(e)=>{
      //     let mainQuestionIndex = questionData.indexOf(selectedQuestion)
      //     let mainData = [...questionData]

      //     mainData[mainQuestionIndex].questionText = e.target.value

      //     setQuestionData(mainData)
      //     setSelectedQuestion(mainData[mainQuestionIndex])
      //   }}
  />
   </div>
   </Col>
    )
  }


  const NumberTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-2">
      <InputGroup>
                        <div
                          className="input-group-append"
                          onClick={() => {
                            if(counter > 0){
                            setCounter(counter - 1);
                            }
                          }}
                        >
                          <Button type="button" color="primary">
                            <i className="mdi mdi-minus" />
                          </Button>
                        </div>
                        <Input
                        style={{
                          borderRight: 0,
                          marginRight:-5,
                          borderLeft: 0,
                          marginLeft: -5,
                          background : 'transparent',
                          textAlign : 'center'
                        }}
                      
                          type="number"
                          // className="form-control"
                          value={counter}
                          onChange={(e)=>{
                            // if(e.target.value == ''){
                            //   setCounter(0)
                            // }
                               setCounter(parseInt(e.target.value))
                            // }
                          }}
                          // placeholder="number"
                          // readOnly
                          
                        />
                        <div
                          className="input-group-append"
                          onClick={() => {
                           
                              setCounter(counter + 1);
                          
                          }}
                        >
                          <Button type="button" color="primary">
                            <i className="mdi mdi-plus" />
                          </Button>
                        </div>
                      </InputGroup>
   </div>
   </Col>
    )
  }


  const DateTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-2">
      <Flatpickr
              // value={Date.parse(pick_date)}
              className="form-control d-block"
              placeholder="Pick a date"
              options={{
                altInput: true,
                dateFormat: "d-m-y"
              }}
              // onChange={(e) => updateValue(groupId, rule.id, moment(new Date(e)).format('MM/DD/YYYY'))
              // }
            />
   </div>
   </Col>
    )
  }


  const DropdownTextView =  () =>{
    return (
      <Col lg='12'>
      <div className="mb-2">
     
    
     {selectedQuestion.type == 'dropdown' || 'multiplechoice' ? selectedQuestion.choices.map((choice, index) =>(
      <div key={index} className='mb-2' style={{position : 'relative'}}>
   
      <Input
      key={index}
       name="key"
       type="text"
      //  rows={3}
       placeholder=""
       value={choice.value}
       // onChange={validation.handleChange}
       // onBlur={validation.handleBlur}
       // value={selectedQuestion.questionText || ""}
       onChange={(e)=>{
        updateDropdownChoice(index, e.target.value)

         }}
   />
    <div className='' 
    style={{
      position : 'absolute',
      right : 5,
      top : 8
    }}
    onClick={()=>{
      removeDropdownChoice(index)
    }}
    ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M15 5L5 15" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 5L15 15" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg></div>
                  
   </div>
     )) : null }
      <div className="add-choice-container">
                <button className="add-choice-button" onClick={addInputField} style={{ cursor: 'pointer' }}>
                  <span className='choosemr-3'>Add Choice</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 4.16666V15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.16666 10H15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </div>
   </div>
   </Col>
    )
  }

  const YesNoTextView =  () =>{

    return (
      <Col lg='12'>
      <div className="mb-2">
     
    
     {selectedQuestion.type == 'yes/no' ? selectedQuestion.choices.map((choice, index) =>(
      <div key={index} className='mb-2' >
   
      <Input
      key={index}
       name="key"
       type="text"
      //  rows={3}
       placeholder=""
       value={choice.value}
       // onChange={validation.handleChange}
       // onBlur={validation.handleBlur}
       // value={selectedQuestion.questionText || ""}
      //  onChange={(e)=>{
      //   updateDropdownChoice(index, e.target.value)

      //    }}
      disabled
   />
    {/* <div className='' 
    style={{
      position : 'absolute',
      right : 60,
      top : 95 + (index * 44.5) 
    }}
    onClick={()=>{
      removeDropdownChoice(index)
    }}
    ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M15 5L5 15" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 5L15 15" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg></div> */}
                  
   </div>
     )) : null }
      {/* <div className="add-choice-container">
                <button className="add-choice-button" onClick={addInputField} style={{ cursor: 'pointer' }}>
                  <span className='choosemr-3'>Add Choice</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 4.16666V15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.16666 10H15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </div> */}
   </div>
   </Col>
    )
  }


  const FileUploadTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-2">
      <Input
      // disabled
      name="key"
      type="file"
      placeholder=""
      // onChange={validation.handleChange}
      // onBlur={validation.handleBlur}
      // value={selectedQuestion.questionText || ""}
      // onChange={(e)=>{
      //     let mainQuestionIndex = questionData.indexOf(selectedQuestion)
      //     let mainData = [...questionData]

      //     mainData[mainQuestionIndex].questionText = e.target.value

      //     setQuestionData(mainData)
      //     setSelectedQuestion(mainData[mainQuestionIndex])
      //   }}
  />
   </div>
   </Col>
    )
  }

  const GoogleDriveTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-2">
      <div className="flex-shrink-0">

                                                        
<Link className="btn btn-primary me-3" 
style={{width : '100%'}}
// onClick={handleAddNewModule}
// to={{pathname : '/decision-tree-config'}}
><i className="mdi mdi-cloud-upload"

></i> Upload from Google Drive </Link>
</div>
   </div>
   </Col>
    )
  }


  const LinkTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-2">
      <Input
      // disabled
      name="key"
      type="text"
      placeholder=""
      // onChange={validation.handleChange}
      // onBlur={validation.handleBlur}
      // value={selectedQuestion.questionText || ""}
      // onChange={(e)=>{
      //     let mainQuestionIndex = questionData.indexOf(selectedQuestion)
      //     let mainData = [...questionData]

      //     mainData[mainQuestionIndex].questionText = e.target.value

      //     setQuestionData(mainData)
      //     setSelectedQuestion(mainData[mainQuestionIndex])
      //   }}
  />
   </div>
   </Col>
    )
  }

  const renderFieldView = () =>{
      switch (selectedQuestion.type) {
        case 'shorttext':
          return (
            <div>{ShortTextView()}</div>
          );
        case 'longtext':
          return (
            <div>{LongTextView()}</div>
          );
        case 'number':
          return (
            <div>{NumberTextView()}</div>
          );
        case 'date':
          return (
            <div>{DateTextView()}</div>
          );
        case 'dropdown':
          return (
            <div>{DropdownTextView()}</div>
          );
        case 'multiplechoice':
          return (
            <div>{DropdownTextView()}</div>
          );
          case 'yes/no':
            return (
              <div>{YesNoTextView()}</div>
            );
            case 'fileupload':
              return (
                <div>{FileUploadTextView()}</div>
              );
              case 'googledrive':
                return (
                  <div>{GoogleDriveTextView()}</div>
                );
                case 'link':
                  return (
                    <div>{LinkTextView()}</div>
                  );
          default:
            return null;
        }
      
  }



  const onAddQuestionDataChoiceTab = (ques) =>{
    // console.log(ques)
    let tabDt = [...tabData]
    let selectedTabIndex = tabDt.indexOf(selectedTab)
    let selQuesData = [...tabDt[selectedTabIndex].questionData]
    let questionIndex = selQuesData.indexOf(ques)

    selQuesData[questionIndex].choices.push({uid : uuid(),value : '', score : 0, duedate : ''})

    setSelectedTab(tabDt[selectedTabIndex])
    setTabData(tabDt)
    onSetTabDataStorage(tabDt)

//     let qData = [...questionData]
//     let sData = selectedQuestion


//     for(let j=0;j<qData.length;j++){
//         if(qData[j].num  == selQuesData[questionIndex].num){
//             qData[j].choices.push('')
//         }
//     } 

//     setQuestionData(qData)

//     if(selQuesData[questionIndex].num == selectedQuestion.num){
//        sData.choices.push('')
//     }
    
//    setSelectedQuestion(sData)

}

const removeDropdownChoiceTab = (ques, indx) =>{
    // console.log(indx)
    let tabDt = [...tabData]
    let selectedTabIndex = tabDt.indexOf(selectedTab)
    let selQuesData = [...tabDt[selectedTabIndex].questionData]
    let questionIndex = selQuesData.indexOf(ques)

    selQuesData[questionIndex].choices.splice(indx, 1)


    setSelectedTab(tabDt[selectedTabIndex])
    setTabData(tabDt)
    onSetTabDataStorage(tabDt)

    let qData = [...questionData]

    for(let j=0;j<qData.length;j++){
        if(qData[j].num  == selQuesData[questionIndex].num){
            qData[j].choices.splice(indx, 1)
        }
    } 
    
    setQuestionData(qData)
    onSetQuestionDataStorage(qData)

    let sData = selectedQuestion
    if(selQuesData[questionIndex].num == selectedQuestion.num){
        sData.choices.splice(indx, 1)
     }
     
    setSelectedQuestion(sData)

}

const updateDropdownChoiceTab = (ques, indx, choice) =>{

    let tabDt = [...tabData]
    let selectedTabIndex = tabDt.indexOf(selectedTab)
    let selQuesData = [...tabDt[selectedTabIndex].questionData]
    let questionIndex = selQuesData.indexOf(ques)

    selQuesData[questionIndex].choices[indx].value = choice

    setSelectedTab(tabDt[selectedTabIndex])
    setTabData(tabDt)
    onSetTabDataStorage(tabDt)

    let qData = [...questionData]

    for(let j=0;j<qData.length;j++){
        if(qData[j].num  == selQuesData[questionIndex].num){
            qData[j].choices[indx].value = choice
        }
    } 
    
    setQuestionData(qData)
    onSetQuestionDataStorage(qData)

    let sData = selectedQuestion
    if(selQuesData[questionIndex].num == selectedQuestion.num){
        sData.choices[indx].value = choice
     }
     
    setSelectedQuestion(sData)

}

const removeQuestionFromTab = (ques, index) =>{

    let tabDt = [...tabData]
    let selectedTabIndex = tabDt.indexOf(selectedTab)
    // let selQuesData = [...tabDt[selectedTabIndex].questionData]
    // let questionIndex = selQuesData.indexOf(ques)

    // console.log(selQuesData)

    tabDt[selectedTabIndex].questionData.splice(index, 1)


    setSelectedTab(tabDt[selectedTabIndex])
    setTabData(tabDt)
    onSetTabDataStorage(tabDt)

}

const onChangeTabQuestionType = (type, index) =>{


    let tabDt = [...tabData]
    let selectedTabIndex = tabDt.indexOf(selectedTab)

    tabDt[selectedTabIndex].questionData[index] = updateField(type, tabDt[selectedTabIndex].questionData[index].num)

    setSelectedTab(tabDt[selectedTabIndex])
    setTabData(tabDt)
    onSetTabDataStorage(tabDt)

    let qData = [...questionData]

    for(let j=0;j<qData.length;j++){
        if(qData[j].num  == tabDt[selectedTabIndex].questionData[index].num){
            qData[j] = updateField(type, tabDt[selectedTabIndex].questionData[index].num)
        }
    } 
    
    setQuestionData(qData)
    onSetQuestionDataStorage(qData)

    let sData = selectedQuestion
    if(tabDt[selectedTabIndex].questionData[index].num == selectedQuestion.num){
        sData = updateField(type, tabDt[selectedTabIndex].questionData[index].num)
     }
     
    setSelectedQuestion(sData)

    

}



  const DropdownTextViewTab =  (ques) =>{
    // console.log(ques)
    return (
      <Col lg='12'>
      <div className="mb-2">
     
    
     {ques.type == 'dropdown' || 'multiplechoice' ? ques.choices.map((choice, index) =>(
      <div key={index} className='mb-2' style={{position : 'relative'}}>
   
      <Input
      key={index}
       name="key"
       type="text"
      //  rows={3}
       placeholder=""
       value={choice.value}
       // onChange={validation.handleChange}
       // onBlur={validation.handleBlur}
       // value={selectedQuestion.questionText || ""}
       onChange={(e)=>{
        updateDropdownChoiceTab(ques, index, e.target.value)

         }}
   />
    <div className='' 
    style={{
      position : 'absolute',
      right : 5,
      top : 8
    }}
    onClick={()=>{
      removeDropdownChoiceTab(ques, index)
    }}
    ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M15 5L5 15" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 5L15 15" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg></div>
                  
   </div>
     )) : null }
      <div className="add-choice-container">
                <button className="add-choice-button" onClick={()=>{onAddQuestionDataChoiceTab(ques)}} style={{ cursor: 'pointer' }}>
                  <span className='choosemr-3'>Add Choice</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 4.16666V15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.16666 10H15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </div>
   </div>
   </Col>
    )
  }

  const YesNoTextViewTab =  (ques) =>{

    // console.log(ques)
    return (
      <Col lg='12'>
      <div className="mb-2">
     
    
     {ques.type == 'yes/no' ? ques.choices.map((choice, index) =>(
      <div key={index} className='mb-2' >
   
      <Input
      key={index}
       name="key"
       type="text"
      //  rows={3}
       placeholder=""
       value={choice.value}
       // onChange={validation.handleChange}
       // onBlur={validation.handleBlur}
       // value={selectedQuestion.questionText || ""}
      //  onChange={(e)=>{
      //   updateDropdownChoice(index, e.target.value)

      //    }}
      disabled
   />
    {/* <div className='' 
    style={{
      position : 'absolute',
      right : 60,
      top : 95 + (index * 44.5) 
    }}
    onClick={()=>{
      removeDropdownChoice(index)
    }}
    ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M15 5L5 15" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 5L15 15" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg></div> */}
                  
   </div>
     )) : null }
      {/* <div className="add-choice-container">
                <button className="add-choice-button" onClick={addInputField} style={{ cursor: 'pointer' }}>
                  <span className='choosemr-3'>Add Choice</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M10 4.16666V15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.16666 10H15.8333" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </div> */}
   </div>
   </Col>
    )
  }


  const updateAdditionalFieldsTab = (ques, text) =>{

    let tabDt = [...tabData]
    let selectedTabIndex = tabDt.indexOf(selectedTab)
    let selQuesData = [...tabDt[selectedTabIndex].questionData]
    let questionIndex = selQuesData.indexOf(ques)

    selQuesData[questionIndex].questionText = text

    setSelectedTab(tabDt[selectedTabIndex])
    setTabData(tabDt)
    onSetTabDataStorage(tabDt)

    let qData = [...questionData]

    for(let j=0;j<qData.length;j++){
        if(qData[j].num  == selQuesData[questionIndex].num){
            qData[j].questionText = text
        }
    } 
    
    setQuestionData(qData)
    onSetQuestionDataStorage(qData)

    let sData = selectedQuestion
    if(selQuesData[questionIndex].num == selectedQuestion.num){
        sData.questionText = text
     }
     
    setSelectedQuestion(sData)

}

  const HeadingTextView = (ques) =>{
    return (
      <Col lg='12'>
      <div className="mb-2">
      <Input
      // disabled
      name="key"
      type="text"
      value={ques.questionText}
      placeholder=""
      onChange={(e)=>{
        updateAdditionalFieldsTab(ques, e.target.value)
      }}
      // onBlur={validation.handleBlur}
      // value={selectedQuestion.questionText || ""}
      // onChange={(e)=>{
      //     let mainQuestionIndex = questionData.indexOf(selectedQuestion)
      //     let mainData = [...questionData]

      //     mainData[mainQuestionIndex].questionText = e.target.value

      //     setQuestionData(mainData)
      //     setSelectedQuestion(mainData[mainQuestionIndex])
      //   }}
  />
   </div>
   </Col>
    )
  }

  const ParagraphTextView = (ques) =>{
    return (
      <Col lg='12'>
      <div className="mb-2">
      <Input
      // disabled
      name="key"
      type="textarea"
      rows={5}
      placeholder=""
      value={ques.questionText}
      onChange={(e)=>{
        updateAdditionalFieldsTab(ques, e.target.value)
      }}
      // onChange={validation.handleChange}
      // onBlur={validation.handleBlur}
      // value={selectedQuestion.questionText || ""}
      // onChange={(e)=>{
      //     let mainQuestionIndex = questionData.indexOf(selectedQuestion)
      //     let mainData = [...questionData]

      //     mainData[mainQuestionIndex].questionText = e.target.value

      //     setQuestionData(mainData)
      //     setSelectedQuestion(mainData[mainQuestionIndex])
      //   }}
  />
   </div>
   </Col>
    )
  }


  const AttachmentTextView = () =>{
    return (
      <Col lg='12'>
      <div className="mb-2">
      <Input
      // disabled
      name="key"
      type="file"
      placeholder=""
      // onChange={validation.handleChange}
      // onBlur={validation.handleBlur}
      // value={selectedQuestion.questionText || ""}
      // onChange={(e)=>{
      //     let mainQuestionIndex = questionData.indexOf(selectedQuestion)
      //     let mainData = [...questionData]

      //     mainData[mainQuestionIndex].questionText = e.target.value

      //     setQuestionData(mainData)
      //     setSelectedQuestion(mainData[mainQuestionIndex])
      //   }}
  />
   </div>
   </Col>
    )
  }

  const renderFieldViewTab = (tabquestion) =>{
    switch (tabquestion.type) {
      case 'shorttext':
        return (
          <div>{ShortTextView()}</div>
        );
      case 'longtext':
        return (
          <div>{LongTextView()}</div>
        );
      case 'number':
        return (
          <div>{NumberTextView()}</div>
        );
      case 'date':
        return (
          <div>{DateTextView()}</div>
        );
      case 'dropdown':
        return (
          <div>{DropdownTextViewTab(tabquestion)}</div>
        );
      case 'multiplechoice':
        return (
          <div>{DropdownTextViewTab(tabquestion)}</div>
        );
        case 'yes/no':
          return (
            <div>{YesNoTextViewTab(tabquestion)}</div>
          );
          case 'fileupload':
            return (
              <div>{FileUploadTextView()}</div>
            );
            case 'googledrive':
              return (
                <div>{GoogleDriveTextView()}</div>
              );
              case 'link':
                return (
                  <div>{LinkTextView()}</div>
                );
                case 'heading':
                    return (
                      <div>{HeadingTextView(tabquestion)}</div>
                    );
                    case 'paragraph':
                    return (
                      <div>{ParagraphTextView(tabquestion)}</div>
                    );
                    case 'attachment':
                        return (
                          <div>{AttachmentTextView(tabquestion)}</div>
                        );
                        case 'hyperlink':
                            return (
                              <div>{HeadingTextView(tabquestion)}</div>
                            );
        default:
          return null;
      }
    
}


const moveQuestion = (fromIndex, toIndex) => {
    const tabIndex = tabData.indexOf(selectedTab)
    const newQuestions = [...tabData[tabIndex].questionData];
    const [movedQuestion] = newQuestions.splice(fromIndex, 1);
    newQuestions.splice(toIndex, 0, movedQuestion);
    setSelectedTab(newQuestions);
    setTabData(tabData)
    onSetTabDataStorage(tabData)
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setSelectedTab((prevCards) =>
    update(prevCards, {
      questionData: {
        $splice: [
          [dragIndex, 1],
        //   [hoverIndex, 0, { ...prevCards.questionData[dragIndex], serial: prevCards.questionData[hoverIndex].serial }],
          [hoverIndex, 0, prevCards.questionData[dragIndex]],
        ],
      },
    })
  );

  setSelectedTab(prevState => ({
    ...prevState,
    questionData: prevState.questionData.map((question, index) => ({
      ...question,
      serial: index + 1, // You can generate your own unique ID here
    })),
  }));


//   console.log(selectedTab)
 

    // console.log(dragIndex)
    // console.log(hoverIndex)


  }, [])

useEffect(()=>{


    
    if(!_.isEmpty(selectedTab)){

        // console.log(selectedTab)
        let tabDt = [...tabData]
        let tabIndx = tabDt.filter(val=> val.tabno == selectedTab.tabno)
      
        // console.log(tabIndx)
        let newTabIndex = tabDt.indexOf(tabIndx[0])

        // console.log(newTabIndex)

        tabDt[newTabIndex] = selectedTab

        // console.log(tabDt)
      
        setTabData(tabDt)
        onSetTabDataStorage(tabDt)
    }

},[selectedTab])



// useEffect(()=>{

//     if(tabData.length > 0){

//         let tabDt = [...tabData]
//         let selTab = [...selectedTab]

//         for(let i= 0;i<tabDt.length;i++){



//         }


//     }

// },[questionData, selectedQuestion])


const OnEditTabName = (tabedit, index) =>{

    let tabDt = [...tabData]
    tabDt[index].tabnameedit = tabedit

    // console.log(tabedit)
    setTabData(tabDt)
    onSetTabDataStorage(tabDt)
    setSelectedTab(tabDt[index])

}

// useEffect(()=>{
//     },[selectedTab, tabData])

const OnRemoveTab = (tabedit, index) =>{

    let tabDt = [...tabData]
    tabDt.splice(index, 1)

    // console.log(tabDt)
    setTabData(tabDt)
    onSetTabDataStorage(tabDt)
    // setSelectedTab([])

}

const OnTabNameChange = (tabname, index) =>{

    let tabDt = [...tabData]
    tabDt[index].tabname = tabname

    setTabData(tabDt)
    onSetTabDataStorage(tabDt)
    setSelectedTab(tabDt[index])

}


const onSetQuestionDataStorage = (data) => {
localStorage.setItem('QuestionData'+props.moduleData.FLD_MODULEID, JSON.stringify(data))
}

const onSetTabDataStorage = (data) => {
    // console.log(props.moduleData.FLD_MODULEID)

localStorage.setItem('TabData'+props.moduleData.FLD_MODULEID, JSON.stringify(data))

if(data.length <=0){
    localStorage.removeItem('TabData'+props.moduleData.FLD_MODULEID) 
    setSelectedTab([])  
}

}


const onSetLogicRuleStorage = (data) => {
localStorage.setItem('LogicRuleData'+props.moduleData.FLD_MODULEID, JSON.stringify(data))
}

const resetTabData = () => {
    setTabData([])
    setSelectedTab({})
    localStorage.removeItem('TabData'+props.moduleData.FLD_MODULEID)
}


useImperativeHandle(ref, () => ({

    onShowMainPreview() {
        if(tabData.length > 0){
            props.setPreviewError(null)
            onActivateSelectedTab(tabData[0])
            setShowPreviewModal(!showPreviewModal)
            }else{
              props.setPreviewError('Please add configuration to preview.')
            }
          
    },

    onDTSaveDraft() {
        // console.log(questionData)
        if(questionData.length > 0){

            dispatch(saveDraftTabData(questionData, tabData, logicRule , props.moduleData, navigate));

        }else{
            props.setPreviewError('Please add questions to save.')
          }
    },

    onDTPublishData() {
        // console.log(tabData) 
        if(tabData.length > 0){
            dispatch(saveTabData(questionData, tabData, logicRule, props.moduleData, navigate));
        }else{
            props.setPreviewError('Please add configuration to save.')
          }
    }

  }));


  const onClosePreviewModalClick = () =>{
    setShowPreviewModal(!showPreviewModal)
  }


  const previewDecisionTree = () =>{
    return(
    <Modal size="md" isOpen={showPreviewModal} toggle={onClosePreviewModalClick} centered={true}>
    <div className="modal-content">
      <ModalHeader>
        <Row>
          <Col lg='2'>
        <div className="avatar-sm mx-auto">
          <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
            <i className="mdi mdi-eye-outline"></i>
          </div>
        </div>
        </Col>
        <Col lg='8' style={{margin : 'auto', marginLeft : 0}}>
        <ModalTitle> Decision Tree Preview</ModalTitle>
        </Col>
      
        </Row>

        <button type="button" onClick={onClosePreviewModalClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
     
      </ModalHeader>
      <ModalBody className="text-center model-body" style={{padding: '22px 30px'}}>
       

      <Tab.Container defaultActiveKey="1">
            <Nav className="tabGroup " style={{marginTop : 5}}>
              {tabData.map((tab, index)=>{

                 // Ensure the ref array is populated
        // if (!editRefs.current[index]) {
        //   editRefs.current[index] = React.createRef();
        // }
        return(
                <Nav.Item className='nav-item-dt' key={index}>
                <Nav.Link eventKey="1" className='nav-link-dt'>
                  <div className="flex-centre01">
                    <div className="flex-centre02" onClick={()=>{
      
                      onActivateSelectedTab(tab)
                    }}>
                      <div className="number-tab" style={{backgroundColor : tabData.indexOf(selectedTab) == index ? '#369bff' : '#ADB5BD'}}>{index+1}</div>
                      <div className="tabtext">
                        {/* {console.log(tab.tabnameedit)} */}
                     {tab.tabnameedit ?
                     <input className='tab-edit-input'
                     type="text"
                     value = {tab.tabname}
                     onChange={(e)=>{
                      props.OnTabNameChange(e.target.value, index)
                     }}
                    //  onKeyDown={(e)=>{
                    //   console.log(e)
                    //  }}
                     />
                     : <span className="form-name"  style={{color : tabData.indexOf(selectedTab) == index ? '#369bff' : '#ADB5BD'}}>{tab.tabname == '' ? `Tab ${index+1}` : tab.tabname}</span>}
                       {/* <span className="edit-icon"
                        onClick={()=>{
                          // console.log(editRefs)
                          props.OnEditTabName(true,index)
                        }}
                        >
                          <i className="mdi mdi-lead-pencil" 
                          style={{marginLeft : 10, fontSize : 15}}
                          ></i>
                        </span> */}
                      </div>
                    </div>
                    <div className="top01" style={{display : tabData.length == index+1 ? 'none' :''}}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="14"
                        viewBox="0 0 8 14"
                        fill="none"
                      >
                        <path
                          d="M1 13L7 7L1 1"
                          stroke="#ADB5BD"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </Nav.Link>
              </Nav.Item>
        )
})}
              
           

              {/* <div className='addbutton' onClick={props.addNewTabs}>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                  <path d="M9.5 3.75V14.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4.25 9H14.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> */}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="1">

                <div className="draggableWrapper">
              
                  {selectedTab && selectedTab.questionData && selectedTab.questionData.map((qData, indx)=>(
                 qData.subType == '' ?
                 <TabComponentPreview 
      key={indx}
      qData={qData}
      questionData={questionData}
                 selectedQuestion={selectedQuestion} 
                 tabData={tabData}
                 selectedTab={selectedTab}
                 addNewTabs={addNewTabs}
                 onShowNextTab={onShowNextTab}
                 onShowPrevioustab={onShowPrevioustab}
                 onActivateSelectedTab={onActivateSelectedTab}
                 questionTypeOptions={questionTypeOptions}
                 renderFieldViewTab={renderFieldViewTab}
                 removeQuestionFromTab={removeQuestionFromTab}
                 onChangeTabQuestionType={onChangeTabQuestionType}
                 configError={configError}
                 fieldTypeOptions={fieldTypeOptions}
                 index={indx}
                 moveCard={moveCard}
      />
               
               
             : 
             <TabComponentPreview2
             key={indx}
             qData={qData}
             questionData={questionData}
                        selectedQuestion={selectedQuestion} 
                        tabData={tabData}
                        selectedTab={selectedTab}
                        addNewTabs={addNewTabs}
                        onShowNextTab={onShowNextTab}
                        onShowPrevioustab={onShowPrevioustab}
                        onActivateSelectedTab={onActivateSelectedTab}
                        questionTypeOptions={questionTypeOptions}
                        renderFieldViewTab={renderFieldViewTab}
                        removeQuestionFromTab={removeQuestionFromTab}
                        onChangeTabQuestionType={onChangeTabQuestionType}
                        configError={configError}
                        fieldTypeOptions={fieldTypeOptions}
                        index={indx}
                        moveCard={moveCard}

             />
                    ))}
                </div>

              </Tab.Pane>
              {/* <Tab.Pane eventKey="2">Tab 2 Content</Tab.Pane> */}
            </Tab.Content>
          </Tab.Container>
        {/* <p className="text-muted font-size-16 mb-4">{deleteMsg}</p> */}

        <div className="card-navigator" style={{ display: 'flex' }}>
        <div className={tabData.indexOf(selectedTab) > 0 ? "previous active" : "previous disabled" } id="previousButton" onClick={onShowPrevioustab}>
            <span style={{ marginRight: '10px' }}><svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M15.5 28C22.4036 28 28 22.4036 28 15.5C28 8.59644 22.4036 3 15.5 3C8.59644 3 3 8.59644 3 15.5C3 22.4036 8.59644 28 15.5 28Z" stroke={tabData.indexOf(selectedTab) > 0 ? '#369bFF' : "#ADB5BD"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15 10.3333L10 15.5L15 20.6667" stroke={tabData.indexOf(selectedTab) > 0 ? '#369bFF' : "#ADB5BD"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M20 15.5H10" stroke={tabData.indexOf(selectedTab) > 0 ? '#369bFF' : "#ADB5BD"}  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg></span>
            <span className='correctsubmit'>Previous</span>
          </div>
         
          <div className={tabData.indexOf(selectedTab) < tabData.length-1 ? "next active" : "next disabled" } onClick={onShowNextTab}>
            <span style={{ marginRight: '10px' }} className='correctsubmit' id="submitButton">Next</span>
            <span><svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
              <path d="M15 28C21.9036 28 27.5 22.4036 27.5 15.5C27.5 8.59644 21.9036 3 15 3C8.09644 3 2.5 8.59644 2.5 15.5C2.5 22.4036 8.09644 28 15 28Z" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15 20.5L20 15.5L15 10.5" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10 15.5H20" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg></span>
          </div>
        </div>

        <div className="hstack gap-2 justify-content-end mb-0">
          <button type="button" className="btn btn-secondary" onClick={onClosePreviewModalClick}>Close</button>
        </div>
      </ModalBody>
    </div>
  </Modal>
    )
  }


    return (
        <div className="container-fluid">
            {previewDecisionTree()}

            <div className="row pageheight pagewidth" style={{width : window.screen.width - 300, height : selectedQuestion.num == undefined ? window.screen.height - 200 : '100%'}}>
                <div className={`left-section p-0  ${isLogicTabActive ? 'd-none' : ''}`}>
                    {/* Left section content */}

                    <Tab.Container activeKey={activeLeftTab}>
                        <div className='heading-tab-outer'>
                            <div className="heading-tab">
                                <Nav variant="tabs" className="tab">
                                    <Nav.Item  className='nav-item-dt'>
                                        <Nav.Link
                                                 className='nav-link-dt'
                                            eventKey="ContentTab"
                                            onClick={() => handleLeftTabChange('ContentTab')}
                                        >
                                            Content
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item  className='nav-item-dt'>
                                        <Nav.Link
                                             className='nav-link-dt'
                                            eventKey="ConfigureTab"
                                            onClick={() => handleLeftTabChange('ConfigureTab')}
                                        >
                                            Configure
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="dropdown01" ref={boxRef}>
                            <div className='addIconSection' onClick={()=>{
                                setSelectQuestionTypeVisible(!selectQuestionTypeVisible)
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <path d="M12 5.5V19.5" stroke="#3A57E8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5 12.5H19" stroke="#3A57E8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                    </div>
                    {selectQuestionTypeVisible ? selectQuestion() : null}
                                
                            </div>
                        </div>
                        <Tab.Content>
                            <Tab.Pane eventKey="ContentTab">
                                {/* Content tab content */}
                                <div className="tab-content">
                                    <div className="questions">
                                        {questionData.map((ques, index) =>(
                                            
                                            <div 
                                            className={selectedQuestion != undefined && selectedQuestion.num == ques.num ? "questionlinks questionActive" : "questionlinks"} 
                                            data-question-number={index} key={index} >
                                              
                                            <div className="questions-text"
                                            style={{width : '80%'}}
                                            onClick={() => {
                                                setSelectedQuestion(ques)
                                                // console.log(ques)
                                            }}>{ques.num}. Question</div>
                                            <div className="question-icongroup">
                                                <span className="clone" style={{ marginRight: '10px' }} data-question-number="1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                        <g clipPath="url(#clip0_15_441)">
                                                            <path d="M12.5 5.625H6.875C6.18464 5.625 5.625 6.18464 5.625 6.875V12.5C5.625 13.1904 6.18464 13.75 6.875 13.75H12.5C13.1904 13.75 13.75 13.1904 13.75 12.5V6.875C13.75 6.18464 13.1904 5.625 12.5 5.625Z" stroke="#3A57E8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M3.125 9.375H2.5C2.16848 9.375 1.85054 9.2433 1.61612 9.00888C1.3817 8.77446 1.25 8.45652 1.25 8.125V2.5C1.25 2.16848 1.3817 1.85054 1.61612 1.61612C1.85054 1.3817 2.16848 1.25 2.5 1.25H8.125C8.45652 1.25 8.77446 1.3817 9.00888 1.61612C9.2433 1.85054 9.375 2.16848 9.375 2.5V3.125" stroke="#3A57E8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_15_441">
                                                                <rect width="15" height="15" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                                <span className="trash delete" onClick={()=>{
                                                    let mainData = [...questionData]
                                                    // if(mainData[index] == selectedQuestion){
                                                    //     setSelectedQuestion(mainData[0])
                                                    // }
                                                    mainData.splice(index, 1)
                                                    setQuestionData(mainData)
                                                    onSetQuestionDataStorage(mainData)
                                                }} data-question-number="1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 15 15" fill="none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                            <path d="M1.875 3.75H3.125H13.125" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M11.875 3.75V12.5C11.875 12.8315 11.7433 13.1495 11.5089 13.3839C11.2745 13.6183 10.9565 13.75 10.625 13.75H4.375C4.04348 13.75 3.72554 13.6183 3.49112 13.3839C3.2567 13.1495 3.125 12.8315 3.125 12.5V3.75M5 3.75V2.5C5 2.16848 5.1317 1.85054 5.36612 1.61612C5.60054 1.3817 5.91848 1.25 6.25 1.25H8.75C9.08152 1.25 9.39946 1.3817 9.63388 1.61612C9.8683 1.85054 10 2.16848 10 2.5V3.75" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M6.25 6.875V10.625" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M8.75 6.875V10.625" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        ))}
                                        {/* <div className="questionlinks questionActive" data-question-number="1" onClick={() => showQuestionCard(1)}>
                                            <div className="questions-text">1. Question</div>
                                            <div className="question-icongroup">
                                                <span className="clone" style={{ marginRight: '10px' }} data-question-number="1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                        <g clipPath="url(#clip0_15_441)">
                                                            <path d="M12.5 5.625H6.875C6.18464 5.625 5.625 6.18464 5.625 6.875V12.5C5.625 13.1904 6.18464 13.75 6.875 13.75H12.5C13.1904 13.75 13.75 13.1904 13.75 12.5V6.875C13.75 6.18464 13.1904 5.625 12.5 5.625Z" stroke="#3A57E8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M3.125 9.375H2.5C2.16848 9.375 1.85054 9.2433 1.61612 9.00888C1.3817 8.77446 1.25 8.45652 1.25 8.125V2.5C1.25 2.16848 1.3817 1.85054 1.61612 1.61612C1.85054 1.3817 2.16848 1.25 2.5 1.25H8.125C8.45652 1.25 8.77446 1.3817 9.00888 1.61612C9.2433 1.85054 9.375 2.16848 9.375 2.5V3.125" stroke="#3A57E8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_15_441">
                                                                <rect width="15" height="15" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                                <span className="trash delete" onClick={showDeleteConfirmation} data-question-number="1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 15 15" fill="none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                            <path d="M1.875 3.75H3.125H13.125" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M11.875 3.75V12.5C11.875 12.8315 11.7433 13.1495 11.5089 13.3839C11.2745 13.6183 10.9565 13.75 10.625 13.75H4.375C4.04348 13.75 3.72554 13.6183 3.49112 13.3839C3.2567 13.1495 3.125 12.8315 3.125 12.5V3.75M5 3.75V2.5C5 2.16848 5.1317 1.85054 5.36612 1.61612C5.60054 1.3817 5.91848 1.25 6.25 1.25H8.75C9.08152 1.25 9.39946 1.3817 9.63388 1.61612C9.8683 1.85054 10 2.16848 10 2.5V3.75" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M6.25 6.875V10.625" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M8.75 6.875V10.625" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="questionlinks" data-question-number="2" onClick={() => showQuestionCard(2)}>
                                            <div className="questions-text">2. Question</div>
                                            <div className="question-icongroup" style={{ display: 'none' }}>
                                                <span className="clone" style={{ marginRight: '10px' }} data-question-number="2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 15 15" fill="none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                            <g clipPath="url(#clip0_15_441)">
                                                                <path d="M12.5 5.625H6.875C6.18464 5.625 5.625 6.18464 5.625 6.875V12.5C5.625 13.1904 6.18464 13.75 6.875 13.75H12.5C13.1904 13.75 13.75 13.1904 13.75 12.5V6.875C13.75 6.18464 13.1904 5.625 12.5 5.625Z" stroke="#3A57E8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M3.125 9.375H2.5C2.16848 9.375 1.85054 9.2433 1.61612 9.00888C1.3817 8.77446 1.25 8.45652 1.25 8.125V2.5C1.25 2.16848 1.3817 1.85054 1.61612 1.61612C1.85054 1.3817 2.16848 1.25 2.5 1.25H8.125C8.45652 1.25 8.77446 1.3817 9.00888 1.61612C9.2433 1.85054 9.375 2.16848 9.375 2.5V3.125" stroke="#3A57E8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_15_441">
                                                                    <rect width="15" height="15" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </svg>
                                                </span>
                                                <span className="trash delete" onClick={showDeleteConfirmation} data-question-number="2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 15 15" fill="none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                            <path d="M1.875 3.75H3.125H13.125" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M11.875 3.75V12.5C11.875 12.8315 11.7433 13.1495 11.5089 13.3839C11.2745 13.6183 10.9565 13.75 10.625 13.75H4.375C4.04348 13.75 3.72554 13.6183 3.49112 13.3839C3.2567 13.1495 3.125 12.8315 3.125 12.5V3.75M5 3.75V2.5C5 2.16848 5.1317 1.85054 5.36612 1.61612C5.60054 1.3817 5.91848 1.25 6.25 1.25H8.75C9.08152 1.25 9.39946 1.3817 9.63388 1.61612C9.8683 1.85054 10 2.16848 10 2.5V3.75" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M6.25 6.875V10.625" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M8.75 6.875V10.625" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="questionlinks" data-question-number="3" onClick={() => showQuestionCard(3)}>
                                            <div className="questions-text">3. Question</div>
                                            <div className="question-icongroup" style={{ display: 'none' }}>
                                                <span className="clone" style={{ marginRight: '10px' }} data-question-number="3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 15 15" fill="none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                            <g clipPath="url(#clip0_15_441)">
                                                                <path d="M12.5 5.625H6.875C6.18464 5.625 5.625 6.18464 5.625 6.875V12.5C5.625 13.1904 6.18464 13.75 6.875 13.75H12.5C13.1904 13.75 13.75 13.1904 13.75 12.5V6.875C13.75 6.18464 13.1904 5.625 12.5 5.625Z" stroke="#3A57E8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M3.125 9.375H2.5C2.16848 9.375 1.85054 9.2433 1.61612 9.00888C1.3817 8.77446 1.25 8.45652 1.25 8.125V2.5C1.25 2.16848 1.3817 1.85054 1.61612 1.61612C1.85054 1.3817 2.16848 1.25 2.5 1.25H8.125C8.45652 1.25 8.77446 1.3817 9.00888 1.61612C9.2433 1.85054 9.375 2.16848 9.375 2.5V3.125" stroke="#3A57E8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_15_441">
                                                                    <rect width="15" height="15" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </svg>
                                                </span>
                                                <span className="trash delete" onClick={showDeleteConfirmation} data-question-number="3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 15 15" fill="none">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                            <path d="M1.875 3.75H3.125H13.125" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M11.875 3.75V12.5C11.875 12.8315 11.7433 13.1495 11.5089 13.3839C11.2745 13.6183 10.9565 13.75 10.625 13.75H4.375C4.04348 13.75 3.72554 13.6183 3.49112 13.3839C3.2567 13.1495 3.125 12.8315 3.125 12.5V3.75M5 3.75V2.5C5 2.16848 5.1317 1.85054 5.36612 1.61612C5.60054 1.3817 5.91848 1.25 6.25 1.25H8.75C9.08152 1.25 9.39946 1.3817 9.63388 1.61612C9.8683 1.85054 10 2.16848 10 2.5V3.75" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M6.25 6.875V10.625" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M8.75 6.875V10.625" stroke="#F16A1B" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="ConfigureTab">
                                {/* Configure tab content */}
                                <div className="tab-content">
                                    <div id="ConfigureTab" className="tabcontent ConfigureTab" style={{ display: 'block', padding: '21px 15px' }}>
                                        <div className="configure-inner">
                                            <div className="button-TabGroup">
                                                <ButtonGroup className='buttonwidth-100'>
                                                <Button color="secondary" outline className="selected" onClick={()=>{
                                                    addNewTabs()
                                                }}>
                                                        <div className="relative-icon-correction">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 22 22" fill="none">
                                                                <path d="M9.25 3.125H3.125V9.25H9.25V3.125Z" stroke="#8A92A6" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M18.875 3.125H12.75V9.25H18.875V3.125Z" stroke="#8A92A6" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M18.875 12.75H12.75V18.875H18.875V12.75Z" stroke="#8A92A6" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M9.25 12.75H3.125V18.875H9.25V12.75Z" stroke="#8A92A6" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>
                                                        Add Tab Group
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                            <div className="addFields">
                                                {/* <div className="fieldHeadings">Add Fields</div> */}
                                                <h6 className='mt-3'>Add Fields</h6>
                                                <div className="flex-gap">
                                                    <div className="flex-gap2">
                                                    <Button color="secondary" outline className="selected" style={{fontSize : 12}}
                                                    onClick={()=>{
                                                        AddQuestionsToTab({ type: 'heading', subType : 'field', value: '', score : 0,
                                                         duedate : '', num: 0,  questionText : '', required : false, showcomments : false, commentsrequired : false})
                                                    
                                                    }}
                                                    >
                                                            <div className="relative-icon-correction">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 21 21" fill="none">
                                                                    <path d="M3.5 6.125V3.5H17.5V6.125" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M7.875 17.5H13.125" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M10.5 3.5V17.5" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                            Heading
                                                        </Button>
                                                        <Button color="secondary" outline className="selected" style={{fontSize : 12}}
                                                         onClick={()=>{
                                                            AddQuestionsToTab({ type: 'paragraph', subType : 'field',  value: '',score : 0,
                                                            duedate : '',num: 0,  questionText : '', required : false, showcomments : false, commentsrequired : false})
                                                        
                                                        }}
                                                        >
                                                            <div className="relative-icon-correction">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 21 21" fill="none">
                                                                    <path d="M3.5 15.75H12.25" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M3.5 10.5L17.5 10.5" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M3.5 5.25H8.75" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                            Paragraph
                                                        </Button>
                                                    </div>
                                                    <div className="flex-gap2">
                                                        <Button color="secondary" outline className="selected" style={{fontSize : 12}}
                                                         onClick={()=>{
                                                            AddQuestionsToTab({ type: 'attachment', subType : 'field',  subType : 'field',  value: '',score : 0,
                                                            duedate : '',num: 0,  questionText : '', required : false, showcomments : false, commentsrequired : false })
                                                        
                                                        }}
                                                        >
                                                            <div className="relative-icon-correction">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 21 21" fill="none">
                                                                    <g clipPath="url(#clip0_33_200)">
                                                                        <path d="M8.75 11.375C9.12577 11.8774 9.60519 12.293 10.1557 12.5938C10.7063 12.8946 11.3151 13.0735 11.9408 13.1183C12.5666 13.1631 13.1946 13.0728 13.7824 12.8536C14.3702 12.6343 14.904 12.2912 15.3475 11.8475L17.9725 9.2225C18.7694 8.39737 19.2104 7.29223 19.2004 6.14512C19.1905 4.99801 18.7304 3.9007 17.9192 3.08954C17.108 2.27838 16.0107 1.81827 14.8636 1.8083C13.7165 1.79833 12.6114 2.23931 11.7863 3.03625L10.2812 4.5325" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        <path d="M12.25 9.62501C11.8742 9.12265 11.3948 8.70698 10.8443 8.40619C10.2937 8.1054 9.68492 7.92654 9.05917 7.88172C8.43342 7.83691 7.80535 7.92719 7.21756 8.14646C6.62978 8.36572 6.09602 8.70883 5.65249 9.15251L3.02749 11.7775C2.23055 12.6026 1.78957 13.7078 1.79954 14.8549C1.80951 16.002 2.26962 17.0993 3.08079 17.9105C3.89195 18.7216 4.98925 19.1817 6.13636 19.1917C7.28347 19.2017 8.38861 18.7607 9.21374 17.9638L10.71 16.4675" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_33_200">
                                                                            <rect width="21" height="21" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </div>
                                                            Attachment
                                                        </Button>
                                                        <Button color="secondary" outline className="selected" style={{fontSize : 12}}
                                                         onClick={()=>{
                                                            AddQuestionsToTab({ type: 'hyperlink', subType : 'field',   subType : 'field',  value: '',score : 0,
                                                            duedate : '',num: 0,  questionText : '', required : false, showcomments : false, commentsrequired : false})
                                                        
                                                        }}
                                                        >

                                                            <div className="relative-icon-correction">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 21 21" fill="none">
                                                                    <path d="M3.5 7.875H17.5" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M3.5 13.125H17.5" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M8.75 2.625L7 18.375" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M14 2.625L12.25 18.375" stroke="#ADB5BD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </div>
                                                            Hyperlink
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="addFields">
                                                {/* <div className="fieldHeadings">Add Questions</div> */}
                                                <h6 className='mt-3'>Questions</h6>

                                                <div className="flex-gap">
                                                    {questionData.map((ques, index)=>(
                                                         <Button key={index} color="secondary" outline className="selected" 
                                                         onClick={()=>{
                                                            AddQuestionsToTab(ques)
                                                         }}
                                                         >
                                                         <div className="textCorrection">Question {ques.num < 10 ? '0' : ''}{ques.num}</div>
                                                     </Button>
                                                    ))}
                                              
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>

               
                <div className={`middle-section p-0 ${isLogicTabActive ? 'd-none' : ''}`}>
                    {/* Middle section content */}
                    <div className="tab-content">
                        {(activeLeftTab === 'ContentTab' && activeRightTab === 'Question') && (
                          selectedQuestion.num != undefined &&  <div>
                                <Middlesection  questionData={questionData}
                                selectedQuestion={selectedQuestion}
                                onShowPreviousQuestion={onShowPreviousQuestion}
                                onShowNextQuestion={onShowNextQuestion}
                                onAddQuestionDataChoice={onAddQuestionDataChoice}
                                removeDropdownChoice={removeDropdownChoice}
                                updateDropdownChoice={updateDropdownChoice}
                                renderFieldView={renderFieldView}
                                />
                                </div> 
                        )}
                        {(activeLeftTab === 'ContentTab' && activeRightTab === 'Logic') && (
                            <div>ContentTab and Logic Tab Content Goes Here</div>
                        )}
                        {(activeLeftTab === 'ConfigureTab' && activeRightTab === 'Question') && (
                            <div>
                                    <DndProvider backend={HTML5Backend}>
                                <MiddlesectionConf questionData={questionData}
                            selectedQuestion={selectedQuestion} 
                            tabData={tabData}
                            selectedTab={selectedTab}
                            addNewTabs={addNewTabs}
                            onShowNextTab={onShowNextTab}
                            onShowPrevioustab={onShowPrevioustab}
                            onActivateSelectedTab={onActivateSelectedTab}
                            questionTypeOptions={questionTypeOptions}
                            renderFieldViewTab={renderFieldViewTab}
                            removeQuestionFromTab={removeQuestionFromTab}
                            onChangeTabQuestionType={onChangeTabQuestionType}
                            configError={configError}
                            fieldTypeOptions={fieldTypeOptions}
                            moveCard={moveCard}
                            OnEditTabName={OnEditTabName}
                            OnRemoveTab={OnRemoveTab}
                            OnTabNameChange={OnTabNameChange}
                            setConfigError={setConfigError}
                            resetTabData={resetTabData}
                            />
                            </DndProvider>
                            </div>
                        )}
                        {(activeLeftTab === 'ConfigureTab' && activeRightTab === 'Logic') && (
                            <div>ConfigureTab and Logic Tab Content Goes Here</div>
                        )}
                    </div>
                </div>
                <div className={`logicMiddle ${isLogicTabActive ? '' : 'd-none'}`}>
                    {/* LogicMiddle section content */}
                    <div className="tab-content">LogicMiddle Content Goes Here</div>
                </div>
                <div className={`right-section p-0 ${isLogicTabActive ? '' : ''}`}>
                    {/* Right section content */}
                    <Tab.Container activeKey={activeRightTab}>
                        <div className='heading-tab-outer'>
                            <div className="heading-tab">
                                <Nav variant="tabs" className="tab">
                                    <Nav.Item  className='nav-item-dt'>
                                        <Nav.Link
                                         className='nav-link-dt'
                                            eventKey="Question"
                                            onClick={() => handleRightTabChange('Question')}
                                        >
                                            Question
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item  className='nav-item-dt'>
                                        <Nav.Link
                                             className='nav-link-dt'
                                            eventKey="Logic"
                                            onClick={() => handleRightTabChange('Logic')}
                                        >
                                            Logic
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                     {   selectedQuestion.num != undefined &&            <Tab.Content className='tabcontent-overflow'>
                       <Tab.Pane eventKey="Question">
                                {/* Question tab content */}
                                <div className="tab-content">
                                    <div className='col-12 p-3'>
                                        {/* <Form.Select aria-label="Default select example" className='questSec-select'>
                                            <option>Multiple Choice</option>
                                            <option value="1">Dropdown</option>
                                            <option value="2">Short Text</option>
                                            <option value="3">Long Text</option>
                                        </Form.Select> */}
                                        <Select
                                  name={'questionType'}
                                  isClearable
                                  isSearchable
                                  options={questionTypeOptions}
                                  placeholder={'Question Type...'}
                                  value={questionTypeOptions.filter(val => val.value == selectedQuestion.type)}
                                  onChange={(e)=>{
                                    let mainQuestionIndex = questionData.indexOf(selectedQuestion)
                                    let mainData = [...questionData]

                                    // mainData[mainQuestionIndex].type = e.value
                                    mainData[mainQuestionIndex] = updateField(e.value, mainData[mainQuestionIndex].num)


                                    setQuestionData(mainData)
                                    onSetQuestionDataStorage(mainData)
                                    setSelectedQuestion(mainData[mainQuestionIndex])

                                    let tabDt = [...tabData]
                                    let selTab = selectedTab

                                    for(let i=0;i<tabData.length;i++){
                                        for(let j=0;j<tabData[i].questionData.length;j++){
                                            if(tabData[i].questionData[j].num == selectedQuestion.num){
                                                tabDt[i].questionData[j] = updateField(e.value, mainData[mainQuestionIndex].num)
                                            }
                                        }  
                                    }
                                    setTabData(tabDt)
                                    onSetTabDataStorage(tabDt)

                                    for(let j=0;j<selectedTab.questionData.length;j++){
                                        if(selectedTab.questionData[j].num  == selectedQuestion.num){
                                            selTab.questionData[j] = updateField(e.value, mainData[mainQuestionIndex].num)
                                        }
                                    } 
                                    
                                    setSelectedTab(selTab)

                                  }}
                                />
                                    </div>
                                    <div className='col-12 px-3 pb-3'>
                                        <div className='p-3 white-bg br-5'>
                                            {/* <Form.Label>Question</Form.Label> */}
                                            {/* <Form.Control className='font-12' as="textarea" rows={3} placeholder='Is company considered the Manufacturer or otherwise responsible for the product?' /> */}
                                            <Label className={selectedQuestion.required ? "form-label required" : "form-label"}>Question</Label>
                                            <Input
                                                name="key"
                                                type="textarea"
                                                rows={5}
                                                placeholder="Question Text"
                                                // onChange={validation.handleChange}
                                                // onBlur={validation.handleBlur}
                                                value={selectedQuestion.questionText || ""}
                                                onChange={(e)=>{
                                                    let mainQuestionIndex = questionData.indexOf(selectedQuestion)
                                                    let mainData = [...questionData]
                
                                                    mainData[mainQuestionIndex].questionText = e.target.value
                
                                                    setQuestionData(mainData)
                                                    onSetQuestionDataStorage(mainData)
                                                    setSelectedQuestion(mainData[mainQuestionIndex])

                                                    let tabDt = [...tabData]
                                                    let selTab = selectedTab
                
                                                    for(let i=0;i<tabData.length;i++){
                                                        for(let j=0;j<tabData[i].questionData.length;j++){
                                                            if(tabData[i].questionData[j].num == selectedQuestion.num){
                                                                tabDt[i].questionData[j].questionText = e.target.value
                                                            }
                                                        }  
                                                    }
                                                    setTabData(tabDt)
                                                    onSetTabDataStorage(tabDt)
                
                                                    // for(let j=0;j<selectedTab.questionData.length;j++){
                                                    //     if(selectedTab.questionData[j].num  == selectedQuestion.num){
                                                    //         selTab.questionData[j].questionText = e.target.value
                                                    //     }
                                                    // } 
                                                    
                                                    // setSelectedTab(selTab)
                                                  }}
                                            />
                                       
                                        </div>
                                    </div>
                                    {/* <div className='col-12 px-3 pb-3'>
                                        <div className='p-3 white-bg br-5'>
                                            <Form.Label>Answer</Form.Label>
                                            <div className='fixcheck mb-1'>
                                                <Form.Check
                                                    type="checkbox"
                                                    label=""
                                                    id="checkboxId"
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <p className='manswer m-0'>Multiple answers {isChecked ? 'accepted' : 'not accepted'}</p>
                                            </div>
                                            <Accordion className='accordion_sidebar' defaultActiveKey="0" flush>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Answer 1</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Form.Control as="textarea" rows={3} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Answer 2</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Form.Control as="textarea" rows={3} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div> */}


                                        <div className='col-12 px-3 pb-3'>
                                        <div className='p-3 white-bg br-5'>
                                            <Form.Label>Answer</Form.Label>
                                            {renderFieldView()}
                                         
                                        </div>
                                    </div>

                                    <div className='col-12 px-3 pb-3'>
                                        <div className='p-3 white-bg br-5'>
                                            <Form.Label>Settings</Form.Label>
                                            <div className=''>
                                                <div className='SettingsWrap'>
                                                    <div className='text-settings'>Required</div>
                                                    <Form.Check type="switch" id="custom-switch" className='custom-switch-lg' 
                                                    onChange={()=>{
                                                        let mainQuestionIndex = questionData.indexOf(selectedQuestion)
                                                        let mainData = [...questionData]
                    
                                                        mainData[mainQuestionIndex].required = selectedQuestion.required ? false : true
                    
                                                        setQuestionData(mainData)
                                                        onSetQuestionDataStorage(mainData)
                                                        setSelectedQuestion(mainData[mainQuestionIndex])

                                                        let tabDt = [...tabData]
                                            
                                                        for(let i=0;i<tabData.length;i++){
                                                            for(let j=0;j<tabData[i].questionData.length;j++){
                                                                if(tabData[i].questionData[j].num == mainData[mainQuestionIndex].num){
                                                                    tabDt[i].questionData[j].required = mainData[mainQuestionIndex].required 
                                                                }
                                                            }  
                                                        }
                                                        setTabData(tabDt)
                                                        onSetTabDataStorage(tabDt)
                                                    }}
                                                    />
                                                </div>
                                                {/* <div className='SettingsWrap'>
                                                    <div className='text-settings'>Multiple selection</div>
                                                    <Form.Check type="switch" id="custom-switch" className='custom-switch-lg' />
                                                </div> */}
                                                <div className='SettingsWrap'>
                                                    <div className='text-settings'>Show Comments</div>
                                                    <Form.Check type="switch" id="custom-switch" className='custom-switch-lg'
                                                    onChange={()=>{
                                                        let mainQuestionIndex = questionData.indexOf(selectedQuestion)
                                                        let mainData = [...questionData]
                    
                                                        mainData[mainQuestionIndex].showcomments = selectedQuestion.showcomments ? false : true
                    
                                                        setQuestionData(mainData)
                                                        onSetQuestionDataStorage(mainData)
                                                        setSelectedQuestion(mainData[mainQuestionIndex])

                                                        let tabDt = [...tabData]
                                            
                                                        for(let i=0;i<tabData.length;i++){
                                                            for(let j=0;j<tabData[i].questionData.length;j++){
                                                                if(tabData[i].questionData[j].num == mainData[mainQuestionIndex].num){
                                                                    tabDt[i].questionData[j].showcomments = mainData[mainQuestionIndex].showcomments
                                                                }
                                                            }  
                                                        }
                                                        setTabData(tabDt)
                                                        onSetTabDataStorage(tabDt)
                                                    }}
                                                    />
                                                </div>
                                                <div className='SettingsWrap'>
                                                    <div className='text-settings'>Comments Required</div>
                                                    <Form.Check type="switch" id="custom-switch" className='custom-switch-lg' 
                                                    onChange={()=>{
                                                        let mainQuestionIndex = questionData.indexOf(selectedQuestion)
                                                        let mainData = [...questionData]
                    
                                                        mainData[mainQuestionIndex].commentsrequired = selectedQuestion.commentsrequired ? false : true
                    
                                                        setQuestionData(mainData)
                                                        onSetQuestionDataStorage(mainData)
                                                        setSelectedQuestion(mainData[mainQuestionIndex])


                                                        let tabDt = [...tabData]
                                            
                                                        for(let i=0;i<tabData.length;i++){
                                                            for(let j=0;j<tabData[i].questionData.length;j++){
                                                                if(tabData[i].questionData[j].num == mainData[mainQuestionIndex].num){
                                                                    tabDt[i].questionData[j].commentsrequired = mainData[mainQuestionIndex].commentsrequired
                                                                }
                                                            }  
                                                        }
                                                        setTabData(tabDt)
                                                        onSetTabDataStorage(tabDt)

                                                    }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Logic">
                                {/* Logic tab content */}
                                <div className="tab-content">
                                    <div className="tabcontent-right" style={{ padding: '15px' }}>
                                        <div className="logicWrapper">
                                            <div className="editLogic">
                                                <div className="logicHeadText">Edit Logic</div>
                                                <div className="removeLogic" 
                                                onClick={()=>{
                                                    setLogicRule([])
                                                    onSetLogicRuleStorage([])
                                                }}
                                                >clear all</div>
                                            </div>

                                            <div className='logic-edit'>
                                                <Accordion defaultActiveKey="0" flush>
                                                    {logicRule.map((logic, index) =>(
                                                         <Accordion.Item eventKey={index} className='mb-2' key={index}>
                                                         <Accordion.Header className='rules-head'>Rule #{index+1}</Accordion.Header>
                                                         <Accordion.Body>
                                                             <div className='col-12'>
                                                                <div className='row mb-3'>
                                                                    <div className='col-lg-1'>
                                                                 <Form.Label>If</Form.Label></div>
                                                                 <div className='col-lg-8'>
                                                                 <Form.Select aria-label="Default select example"
                                                                 value={logicRule[index].questionnum}
                                                                 onChange={(e)=>{
                                                                    let logicArr = [...logicRule]
                                                                    logicArr[index].questionnum = e.target.value
                                                                    logicArr[index].questionData = questionData.filter(val => val.num == e.target.value)

                                                                    setLogicRule(logicArr)
                                                                    onSetLogicRuleStorage(logicArr)
                                                                 }}
                                                                 >
                                                                 <option value={''} key={''}>Select Question
                                                                            </option>
                                                                         {questionData.map((ques, i)=>(
                                                                            <option value={ques.num} key={ques.num}>Question {ques.num < 10 ? '0' : ''}{ques.num}
                                                                            </option>
                                                                        ))}
                                                                         </Form.Select></div>
                                                                 <div className='col-lg-1'>
                                                                 <Form.Label>answer</Form.Label></div>

                                                                 </div>
                                                                 <div className='row mb-3'>
                                                                     <div className='col-6 pad-right'>
                                                                         <Form.Select aria-label="Default select example"
                                                                        value={logicRule[index].condition}
                                                                          onChange={(e)=>{
                                                                            let logicArr = [...logicRule]
                                                                            logicArr[index].condition = e.target.value
                                              
                                                                            setLogicRule(logicArr)
                                                                            onSetLogicRuleStorage(logicArr)
                                                                         }}
                                                                         >
                                                                         {logicConditionOptions.map((choice, i)=>(
                                                                            <option value={choice.value} key={choice.value}>{choice.label}</option>
                                                                        ))}
                                                                         </Form.Select>
                                                                     </div>
                                                                     <div className='col-6 pad-left'
                                                                     style={{display : logicRule[index].questionData[0] != undefined && (logicRule[index].questionData[0].type === 'dropdown' || logicRule[index].questionData[0].type === 'multiplechoice' || logicRule[index].questionData[0].type === 'yes/no') ? '' : 'none'}}
                                                                     >
                                                                         <Form.Select aria-label="Default select example"
                                                                        value={logicRule[index].answerType}
                                                                         onChange={(e)=>{
                                                                            let logicArr = [...logicRule]
                                                                            logicArr[index].answerType = e.target.value
                                              
                                                                            setLogicRule(logicArr)
                                                                            onSetLogicRuleStorage(logicArr)
                                                                         }}
                                                                         >
                                                                         <option value={''} key={''}>Select Answer
                                                                            </option>
                                                                         {logicRule[index].questionData[0] != undefined && (logicRule[index].questionData[0].type === 'dropdown' || logicRule[index].questionData[0].type === 'multiplechoice' || logicRule[index].questionData[0].type === 'yes/no') ? logicRule[index].questionData[0].choices.map((choice, i)=>(
                                                                            <option value={choice.value} key={choice.value}>{choice.value}</option>
                                                                        )) : null}
                                                                         </Form.Select>
                                                                     </div>
                                                                 </div>
                                                             
 
                                                                 <Form.Label>Then Add to Score</Form.Label>
                                                                 <Form.Control className='mb-2' type="number" placeholder="" 
                                                                        value={logicRule[index].score}
                                                                 onChange={(e)=>{
                                                                    let logicArr = [...logicRule]
                                                                    logicArr[index].score = e.target.value
                                      
                                                                    setLogicRule(logicArr)
                                                                    onSetLogicRuleStorage(logicArr)
                                                                    // console.log(tabData)
                                                                    let tabNewData = [...tabData]
                                                                    for(let i =0; i< tabNewData.length ;i++){
                                                                        for(let j =0; j< tabNewData[i].questionData.length ;j++){
                                                                            if(tabNewData[i].questionData[j].num == logicRule[index].questionnum){
                                                                                if(tabNewData[i].questionData[j].type == 'multiplechoice' || tabNewData[i].questionData[j].type == 'dropdown' || tabNewData[i].questionData[j].type == 'yes/no'){
                                                                                    for(let k =0; k< tabNewData[i].questionData[j].choices.length ;k++){
                                                                                        if(tabNewData[i].questionData[j].choices[k].value == logicArr[index].answerType){
                                                                                            tabNewData[i].questionData[j].choices[k].score = e.target.value
                                                                                        }
                                                                                    }
                                                                                }else{
                                                                                    tabNewData[i].questionData[j].score = e.target.value
                                                                                }
                                                                            }
                                                                        
                                                                        }
                                                                    }
                                                                    setTabData(tabNewData)
                                                                    onSetTabDataStorage(tabNewData)
                                                                 }}
                                                                 />
                                                                 <Form.Label>Then Add to due date</Form.Label>
                                                                 <Form.Control className='mb-2' 
                                                                 type="number" 
                                                                 placeholder="" 
                                                                 value={logicRule[index].duedate}
                                                                
                                                              
                                                                    onChange={(e) => {
                                                                        let logicArr = [...logicRule]
                                                                        logicArr[index].duedate =  e.target.value
                                          
                                                                        setLogicRule(logicArr)
                                                                        onSetLogicRuleStorage(logicArr)


                                                                        let tabNewData = [...tabData]
                                                                    for(let i =0; i< tabNewData.length ;i++){
                                                                        for(let j =0; j< tabNewData[i].questionData.length ;j++){
                                                                            if(tabNewData[i].questionData[j].num == logicRule[index].questionnum){
                                                                                if(tabNewData[i].questionData[j].type == 'multiplechoice' || tabNewData[i].questionData[j].type == 'dropdown' || tabNewData[i].questionData[j].type == 'yes/no'){
                                                                                    for(let k =0; k< tabNewData[i].questionData[j].choices.length ;k++){
                                                                                        if(tabNewData[i].questionData[j].choices[k].value == logicArr[index].answerType){
                                                                                            tabNewData[i].questionData[j].choices[k].duedate =  e.target.value
                                                                                        }
                                                                                    }
                                                                                }else{
                                                                                    tabNewData[i].questionData[j].duedate =  e.target.value
                                                                                }
                                                                            }
                                                                        
                                                                        }
                                                                    }
                                                                    setTabData(tabNewData)
                                                                    onSetTabDataStorage(tabNewData)
                                                                    }}
                                                                    />
                                                                 {/* <Form.Control className='mb-2' type="text" placeholder=""
                                                                 onChange={(e)=>{
                                                                    let logicArr = [...logicRule]
                                                                    logicArr[index].duedate = e.target.value
                                      
                                                                    setLogicRule(logicArr)
                                                                 }}
                                                                 /> */}
                                                                 <div className='buttonwrap-right mt-3' >
                                                                     <div className=''>
                                                                         <button className='delete-Button' onClick={()=>{
                                                                    let logicArr = [...logicRule]
                                                                    logicArr.splice(index, 1)
                                                                    setLogicRule(logicArr)
                                                                    onSetLogicRuleStorage(logicArr)
                                                                 }}>
                                                                             <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                                                 <path d="M2.125 4.25H3.54167H14.875" stroke="#C03221" strokeLinecap="round" strokeLinejoin="round" />
                                                                                 <path d="M13.4583 4.25002V14.1667C13.4583 14.5424 13.309 14.9027 13.0434 15.1684C12.7777 15.4341 12.4174 15.5834 12.0416 15.5834H4.95829C4.58257 15.5834 4.22223 15.4341 3.95656 15.1684C3.69088 14.9027 3.54163 14.5424 3.54163 14.1667V4.25002M5.66663 4.25002V2.83335C5.66663 2.45763 5.81588 2.0973 6.08156 1.83162C6.34724 1.56594 6.70757 1.41669 7.08329 1.41669H9.91663C10.2924 1.41669 10.6527 1.56594 10.9184 1.83162C11.184 2.0973 11.3333 2.45763 11.3333 2.83335V4.25002" stroke="#C03221" strokeLinecap="round" strokeLinejoin="round" />
                                                                                 <path d="M7.08337 7.79169V12.0417" stroke="#C03221" strokeLinecap="round" strokeLinejoin="round" />
                                                                                 <path d="M9.91663 7.79169V12.0417" stroke="#C03221" strokeLinecap="round" strokeLinejoin="round" />
                                                                             </svg>
                                                                         </button>
                                                                     </div>
                                                                     {/* <div className='buttonwrap-right'>
                                                                         <button className='cancelbutton buttonbatch'>
                                                                             Cancel
                                                                         </button>
                                                                         <button className='savebutton buttonbatch'>
                                                                             Save
                                                                         </button>
                                                                     </div> */}
                                                                 </div>
                                                             </div>
                                                         </Accordion.Body>
                                                     </Accordion.Item>

                                                    ))}
                                                   
                                                  
                                                </Accordion>
                                            </div>

                                            <div className="addRuleButton" onClick={()=>{
                                                let logicArr = [...logicRule]

                                                logicArr.push({questionnum : '', questionData : [], condition : 'is', answerType : '', score : 0, duedate : '' })

                                                setLogicRule(logicArr)
                                                onSetLogicRuleStorage(logicArr)
                                            }}>
                                                <button className="add-choice-button" style={{ cursor: 'pointer' }}>
                                                    <svg className='mar-r5' xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                                        <path d="M10.5 4.16669V15.8334" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M4.66663 10H16.3333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <span className='ruleColor'>Add New Rule</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
}
                    </Tab.Container>
                </div>
            </div>
            <Loader isLoading={isLoading} /> 
        </div>
    );
})


MyTabs.displayName = 'MyTabs';


export default MyTabs;

import React, {useState, useEffect} from 'react';
import {
    Col,
    Row,
    Container,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ActivityFeed from './ActivityFeed';
import AddedJobs from './AddedJobs';
import VerticalAds from './VerticalAds';

//Import Components
import ChartSection from './ChartSection';
import JobVacancy from './JobVacancy';
import HorizontalAds from './HorizontalAds';
import Section from './Section';
import StatisticsApplications from './StatisticsApplications';
import { authUser } from 'helpers/dataConfig';

const DashboardJob = () => {
    document.title = "Corporate Dashboard | Smarteeva "


  const [userData, setUserData] = useState("");


    const chartsData = [
        {
            id: 1,
            title: "MDR's Reported",
            price: "14,487",
            perstangeValue: "18.89",
            bagdeColor: "success",
            seriesData: [{
                name: "Job View",
                data: [36, 21, 65, 22, 35, 50, 87, 98],
            }],
            color: '["--bs-success", "--bs-transparent"]'
        },
        {
            id: 2,
            title: "Device Reports",
            price: "7,402",
            perstangeValue: "24.07",
            bagdeColor: "success",
            seriesData: [{
                name: "New Application",
                data: [36, 48, 10, 74, 35, 50, 70, 73],
            }],
            color: '["--bs-success", "--bs-transparent"]'
        },
        {
            id: 3,
            title: "Total Reports",
            price: "12,487",
            perstangeValue: " 8.41",
            bagdeColor: "success",
            seriesData: [{
                name: "Total Approved",
                data: [60, 14, 5, 60, 30, 43, 65, 84],
            }],
            color: '["--bs-success", "--bs-transparent"]'
        },
    ];


    useEffect(() => {
        if (localStorage.getItem(authUser)) {

            const obj = JSON.parse(localStorage.getItem(authUser));
            setUserData(obj);
            // console.log(obj)
          // }
        }
      }, []);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Section userData={userData} />

                    <ChartSection chartsData={chartsData} />
                    <Row>
                        {/* StatisticsApplications */}
                        <StatisticsApplications />

                        {/* <VerticalAds /> */}
                    </Row>
                    <Row>
                        {/* <HorizontalAds /> */}
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">List of MDRs Submitted</h5>
                                        <div className="flex-shrink-0">
                                            <Link to="#!" onClick={() => setModal(true)} className="btn btn-primary me-3"><i className="mdi mdi-plus"></i> Add a New MDR</Link>
                                            <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                <DropdownToggle type="menu" className="btn btn-light" id="dropdownMenuButton1">
                                                    <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                                <DropdownMenu>
                                                    <li><DropdownItem href="#">Action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <div className="d-flex flex-center py-5">
                                        <h2 className='py-5 my-5'>MDR Table Placeholder</h2>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default DashboardJob;
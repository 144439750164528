// Loader.js
import React, { Component } from 'react';
import './loaderStyle.css'; // Make sure to import the CSS file
import logo from '../../assets/images/logo-dark.png'

const Loader = (props) => {
 
    // {console.log(props.isLoading)}

    return (
        props.isLoading ? 
      <div className={`loader-view`}>
        <div className={`animate-flicker`}>
        <img src={logo} alt="" height="80"  />
        </div>
      </div>
        : null
    );

}

export default Loader;

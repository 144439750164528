import { GET_CITY_LIST, GET_STATE_LIST, GET_STATE_LIST_FAIL, GET_STATE_LIST_SUCCESS } from "store/company/actionTypes"
import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_CORPORATE_DD,
  GET_CORPORATE_DD_SUCCESS,
  GET_CORPORATE_DD_FAIL,
  GET_PHONECODE_LIST_USER,
  GET_PHONECODE_LIST_USER_SUCCESS,
  GET_PHONECODE_LIST_USER_FAIL,
  GET_COUNTRY_LIST_USER,
  GET_COUNTRY_LIST_USER_SUCCESS,
  GET_COUNTRY_LIST_USER_FAIL,
  GET_CITY_LIST_USER_FAIL,
  GET_CITY_LIST_USER_SUCCESS,
  GET_CITY_LIST_USER,
  GET_STATE_LIST_USER_FAIL,
  GET_STATE_LIST_USER_SUCCESS,
  GET_STATE_LIST_USER,
  GET_COMPANY_LIST_USER,
  GET_COMPANY_LIST_USER_SUCCESS,
  GET_COMPANY_LIST_USER_FAIL,
  GET_ACTIVE_USER_ORG,
  GET_ACTIVE_USER_ORG_SUCCESS,
  GET_ACTIVE_USER_ORG_FAIL,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  APPROVE_USER,
  APPROVE_USER_SUCCESS,
  APPROVE_USER_FAIL,
  SEND_USER_INVITE,
  SEND_USER_INVITE_SUCCESS,
  SEND_USER_INVITE_FAIL,
  SET_LOADING_INDICATOR_USER,
  RESET_USER_DETAILS,
  GET_GROUP_PERMISSION_SUCCESS,
  GET_GROUP_PERMISSION,
  GET_GROUP_PERMISSION_FAIL,
  UPDATE_GROUP_PERMISSION_FAIL,
  UPDATE_GROUP_PERMISSION_SUCCESS,
  UPDATE_GROUP_PERMISSION,
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const addNewUser = (data, history) => ({
  type: ADD_NEW_USER,
  payload: {data, history},
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

export const getUserProfile = (user) => ({
  type: GET_USER_PROFILE,
  payload : user
})

export const getUserProfileSuccess = userProfile => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: userProfile,
})

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  payload: error,
})

export const updateUser =  (user, history) => ({
  type: UPDATE_USER,
  payload: {user, history},
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user,
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user,
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error,
})


export const getCorporateDropdown = () => ({
  type: GET_CORPORATE_DD,
})

export const getCorporateDropdownSuccess = users => ({
  type: GET_CORPORATE_DD_SUCCESS,
  payload: users,
})

export const getCorporateDropdownFail = error => ({
  type: GET_CORPORATE_DD_FAIL,
  payload: error,
})


export const getPhonecodeUserList = () => ({
  type: GET_PHONECODE_LIST_USER,
})

export const getPhonecodeListUserSuccess = phonecode => ({
  type: GET_PHONECODE_LIST_USER_SUCCESS,
  payload: phonecode,
})

export const getPhonecodeListUserFail = error => ({
  type: GET_PHONECODE_LIST_USER_FAIL,
  payload: error,
})


export const getCountryUserList = () => ({
  type: GET_COUNTRY_LIST_USER,
})

export const getCountryListUserSuccess = country => ({
  type: GET_COUNTRY_LIST_USER_SUCCESS,
  payload: country,
})

export const getCountryListUserFail = error => ({
  type: GET_COUNTRY_LIST_USER_FAIL,
  payload: error,
})


export const getStateUserList = states => ({
  type: GET_STATE_LIST_USER,
  payload: states,
})

export const getStateListUserSuccess = states => ({
  type: GET_STATE_LIST_USER_SUCCESS,
  payload: states,
})

export const getStateListUserFail = error => ({
  type: GET_STATE_LIST_USER_FAIL,
  payload: error,
})


export const getCityUserList = city => ({
  type: GET_CITY_LIST_USER,
  payload: city,
})

export const getCityListUserSuccess = city => ({
  type: GET_CITY_LIST_USER_SUCCESS,
  payload: city,
})


export const getCityListUserFail = error => ({
  type: GET_CITY_LIST_USER_FAIL,
  payload: error,
})


export const getCompanyUserList = () => ({
  type: GET_COMPANY_LIST_USER
})

export const getCompanyListUserSuccess = companys => ({
  type: GET_COMPANY_LIST_USER_SUCCESS,
  payload: companys,
})


export const getCompanyListUserFail = error => ({
  type: GET_COMPANY_LIST_USER_FAIL,
  payload: error,
})


export const getActiveUsersOrg = comp => ({
  type: GET_ACTIVE_USER_ORG,
  payload: comp,
})

export const getActiveUsersOrgSuccess = active_org_users => ({
  type: GET_ACTIVE_USER_ORG_SUCCESS,
  payload: active_org_users,
})


export const getActiveUsersOrgFail = error => ({
  type: GET_ACTIVE_USER_ORG_FAIL,
  payload: error,
})



export const getUserDetails= userdetails => ({
  type: GET_USER_DETAILS,
  payload: userdetails,
})

export const getUserDetailsSuccess = userdetails => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: userdetails,
})

export const getUserDetailsFail = error => ({
  type: GET_USER_DETAILS_FAIL,
  payload: error,
})


export const approveUser = (data, history) => ({
  type: APPROVE_USER,
  payload: {data, history}
})

export const approveUserSuccess = data => ({
  type: APPROVE_USER_SUCCESS,
  payload: data,
})

export const approveUserFail = error => ({
  type: APPROVE_USER_FAIL,
  payload: error,
})



export const sendUserInvite = data => ({
  type: SEND_USER_INVITE,
  payload: data
})

export const sendUserInviteSuccess = data => ({
  type: SEND_USER_INVITE_SUCCESS,
  payload: data,
})

export const sendUserInviteFail = error => ({
  type: SEND_USER_INVITE_FAIL,
  payload: error,
})

export const resetUserDetails = () => ({
  type: RESET_USER_DETAILS
})


export const getGroupPermission = () => ({
  type: GET_GROUP_PERMISSION,
})

export const getGroupPermissionSuccess = grouppermissions => ({
  type:GET_GROUP_PERMISSION_SUCCESS,
  payload: grouppermissions,
})

export const getGroupPermissionFail = error => ({
  type: GET_GROUP_PERMISSION_FAIL,
  payload: error,
})


export const updateGroupPermission = (data, history) => ({
  type: UPDATE_GROUP_PERMISSION,
  payload: {data, history},
})

export const updateGroupPermissionSuccess = permissionSuccess => ({
  type: UPDATE_GROUP_PERMISSION_SUCCESS,
  payload: permissionSuccess,
})

export const updateGroupPermissionFail = error => ({
  type: UPDATE_GROUP_PERMISSION_FAIL,
  payload: error,
})

export const setLoadingIndicatorUser = (loading) => {
  // console.log(loading)
  return {
    type: SET_LOADING_INDICATOR_USER,
    payload: loading,
  };
};
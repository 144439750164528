import React, { useEffect, useMemo, useState, useRef } from "react";
import { Link,useNavigate } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainerDT from '../../../components/Common/TableContainerDT';
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from 'react-select';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    updateEventDT as onUpdateEvent,
    deleteEventDT as onDeleteEventDT,
    approveEventDT as onApproveEventDT,
    getEventsDT as onGetEventsDT,
    getActiveModules as onGetActiveModules,
    addNewEventDT as onAddNewEventDT
} from "store/actions";

import {
    No, ColName, ApprovalStatus, UpdateOn, Status, ModuleName
}
    from "./EventsListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    FormGroup,
    Progress,
    Alert
} from "reactstrap";
import Loader from "components/Common/Loader";
import ApproveModal from "components/Common/ApproveModal";
import TableContainerEvent from "components/Common/TableContainerEvent";
import moment from "moment";


function EventsList() {

    //meta title
    document.title = "Event List | Smarteeva";

    const tableRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isEditable, setIsEditable] = useState(true);



    const [eventList, setEventList] = useState([]);
    const [event, setEvent] = useState(null);
    const [resetFilter, setResetFilter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [toggleCanvas, setToggleCanvas] = useState(false);
    const [isRight, setIsRight] = useState(false);

    const [typeOptions, setTypeOptions] = useState([
        {label : 'CVR Decision Tree', value : 'CVR Decision Tree'},
        {label : 'MIR Decision Tree', value : 'MIR Decision Tree'},
        {label : 'MDR Decision Tree', value : 'MDR Decision Tree'}

    ]);
    const [typeVal, setTypeVal] = useState('');
    const [moduleValDT, setModuleValDT] = useState('');
    const [complaintVal, setComplaintVal] = useState('');

    const [approveModal, setApproveModal] = useState(false);

    const [complaintOptions, setComplaintOptions] = useState([])
    const [moduleOptions, setModuleOptions] = useState([])


    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            title: '',
            type : '',
            complaintid :'',
            moduleid: '',
            duedate : '',
            status: 'Active',

        },
        validationSchema: Yup.object({
             type: Yup.string().required("Please Enter Event Type"),
             moduleid: Yup.string().required("Please Select Module Type")
        }),
        onSubmit: (values) => {

            // console.log(values)
            
            if(isEdit || isView){

                // console.log('in view & edit')

               values.eventid = event.FLD_EVENTID
               values.eventno = event.FLD_EVENTNO
                // console.log(values)

                let val = {...values, complaintid : values.complaintid == '' ? 0 : values.complaintid}

                dispatch(onUpdateEvent(val));

            }else{

                let val = {...values, complaintid : values.complaintid == '' ? 0 : values.complaintid}
                // console.log(val)
                dispatch(onAddNewEventDT(val, navigate));
            }

            // 

            // if (isEdit) {
            //     const updateJobList = {
            //         id: job ? job.id : 0,
            //         jobId: values.jobId,
            //         jobTitle: values.jobTitle,
            //         companyName: values.companyName,
            //         location: values.location,
            //         experience: values.experience,
            //         position: values.position,
            //         type: values.type,
            //         postedDate: "02 June 2021",
            //         lastDate: "25 June 2021",
            //         status: values.status,
            //     };
            //     // update Job
            //     dispatch(onUpdateJobList(updateJobList));
            //     validation.resetForm();
            // } else {
            //     const newJobList = {
            //         id: Math.floor(Math.random() * (30 - 20)) + 20,
            //         jobId: values["jobId"],
            //         jobTitle: values["jobTitle"],
            //         companyName: values["companyName"],
            //         location: values["location"],
            //         experience: values["experience"],
            //         position: values["position"],
            //         type: values["type"],
            //         postedDate: "02 June 2021",
            //         lastDate: "25 June 2021",
            //         status: values["status"],
            //     };
            //     // save new Job
            //     dispatch(onAddNewJobList(newJobList));
            //     validation.resetForm();
            // }
            // toggle();
        },
    });



    const { eventsDT,event_length, addEventSuccessMsg, activeModulesData  } = useSelector(state => ({
        eventsDT: state.DecisionTree.eventsDT,
        event_length: state.DecisionTree.event_length,
        addEventSuccessMsg: state.DecisionTree.addEventSuccessMsg,
        activeModulesData: state.DecisionTree.activeModulesData,

    }));

    const { error, loading } = useSelector(state => ({
        error: state.DecisionTree.error,
        loading : state.DecisionTree.loading
      }));
    
    
      useEffect(() => {
        setIsLoading(loading)
    }, [loading]);

    useEffect(() => {


        if(event_length == -1){
        if (eventsDT && !eventsDT.length) {
            // console.log(modulesDT)
            // setIsLoading(true)
            dispatch(onGetEventsDT());
        }
    }
    }, [dispatch, eventsDT]);

    useEffect(() => {
        if (activeModulesData && !activeModulesData.length) {
            dispatch(onGetActiveModules());
    }
    }, [dispatch, activeModulesData]);

    useEffect(() => {
        // console.log(modulesDT)

        setEventList(eventsDT);
        // setIsRight(false)
    }, [eventsDT]);

    useEffect(() => {
     setModuleOptions(activeModulesData);
    }, [activeModulesData]);


    useEffect(() => {
 
      if(addEventSuccessMsg != ''){
       setIsRight(false)
        setTypeVal('')
        validation.resetForm()
      }
   
    }, [addEventSuccessMsg]);

    // useEffect(() => {
    //     if (!isEmpty(companys) && !!isEdit) {
    //         setCompanyList(companys);
    //         setIsEdit(false);
    //     }
    // }, [companys]);

    // const toggle = () => {
    //     if (modal) {
    //         setModal(false);
    //         setCompany(null);
    //     } else {
    //         setModal(true);
    //     }
    // };

    // const handleJobClick = arg => {
    //     const job = arg;
    //     setJob({
    //         id: job.id,
    //         jobId: job.jobId,
    //         jobTitle: job.jobTitle,
    //         companyName: job.companyName,
    //         location: job.location,
    //         experience: job.experience,
    //         position: job.position,
    //         type: job.type,
    //         status: job.status,
    //     });

    //     setIsEdit(true);

    //     toggle();
    // };

    //delete Company
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (module) => {
        setEvent(module);
        setDeleteModal(true);
    };

    // const handleDeletecompany = () => {
    //     // console.log(company)
    //     if (company && company.FLD_COMPANYID) {
    //         dispatch(onDeleteCompany(company.FLD_COMPANYID));
    //         setDeleteModal(false);
    //     }
    // };
    // const handleCompanyClicks = () => {
    //     setCompanyList("");
    //     setIsEdit(false);
    //     toggle();
    // };

    const columns = useMemo(
        () => [
            // {
            //     Header: 'No',
            //     accessor: 'No',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <No {...cellProps} />;
            //     }
            // },
           
            {
                Header: 'Event No',
                accessor: 'FLD_EVENTNO',
                filterable: true,
                Cell: (cellProps) => {
                    return <Link 
                    to={'/alter-event'} state={{ state: {...cellProps.row.original, isEditable : false} }}
                    >
                        <ModuleName {...cellProps} />
                        </Link>;
                }
            },

            {
                Header: 'Event Title',
                accessor: 'FLD_TITLE',
                filterable: true,
                Cell: (cellProps) => {
                    return <ColName {...cellProps} />;
                }
            },
            {
                Header: 'Event Type',
                accessor: 'FLD_TYPE',
                filterable: true,
                Cell: (cellProps) => {
                    return <ColName {...cellProps} />;
                }
            },
            {
                Header: 'Decision',
                accessor: 'FLD_FINALDECISION',
                filterable: true,
                Cell: (cellProps) => {
                    return <ColName {...cellProps} />;
                }
            },

            {
                Header: 'Related Complaint',
                accessor: 'FLD_KEYS',
                filterable: true,
                Cell: (cellProps) => {
                    return <ColName {...cellProps} />;
                }
            },
            
           
            {
                Header: 'Approval Status',
                accessor: 'FLD_APPROVAL_STATUS',
                filterable: true,
                Cell: (cellProps) => {
                    return <ApprovalStatus {...cellProps} />;
                }
            },
            {
                Header: 'Date Updated On',
                accessor: 'FLD_LASTUPDATEDON',
                filterable: true,
                Cell: (cellProps) => {
                    return <UpdateOn {...cellProps} />;
                }
            },

            // {
            //     Header: 'Created By',
            //     accessor: 'FLD_CREATEDBY',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            {
                Header: 'Status',
                accessor: 'FLD_STATUS',
                filterable: true,
                // disableFilters: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Owner',
                accessor: 'FLD_OWNER',
                filterable: true,
                // disableFilters: true,
                Cell: (cellProps) => {
                    return <ColName {...cellProps} />;
                }
            },
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link 
                                // to={'/alter-corporate-profile'} state={{ state: {...cellProps.row.original, isEditable : false} }}
                                onClick={()=>{
                                    setIsEdit(false)
                                    setIsView(true)

                                        setIsEditable(false)

                                        let data = cellProps.row.original


                                        setEvent(data)
                                  
    
                                        validation.setFieldValue('title', data.FLD_TITLE)
                                        validation.setFieldValue('type', data.FLD_TYPE)
                                        validation.setFieldValue('complaintid', data.FLD_COMPLAINTID)
                                        validation.setFieldValue('moduleid', data.FLD_MODULEID)
                                        validation.setFieldValue('duedate', data.FLD_DUEDATE)
                                        validation.setFieldValue('status', data.FLD_STATUS == 'Active' ? 'Active' : 'Archived') 
                                        setTypeVal({label : data.FLD_TYPE, value : data.FLD_TYPE})
                                        setComplaintVal('')
                                        setModuleValDT(moduleOptions.filter(val => val.value === data.FLD_MODULEID)[0])
                                      
                                        setIsRight(true)
                                }}
                                className="btn btn-sm btn-soft-primary">
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                View
                            </UncontrolledTooltip>

                            <li>
                                <Link
                                    // to="#"
                                    // to={'/alter-corporate-profile'} state={{ state: {...cellProps.row.original, isEditable : true} }}
                                    // to={{ pathname: '/alter-corporate-profile', state: { data : cellProps.row.original } }}
                                    onClick={()=>{
                                        setIsEdit(true)
                                        setIsView(false)
                                        setIsEditable(true)

                                        // console.log(cellProps.row.original)
                                        let data = cellProps.row.original

                                        setEvent(data)

                                        validation.setFieldValue('title', data.FLD_TITLE)
                                        validation.setFieldValue('type', data.FLD_TYPE)
                                        validation.setFieldValue('complaintid', data.FLD_COMPLAINTID)
                                        validation.setFieldValue('moduleid', data.FLD_MODULEID)
                                        validation.setFieldValue('duedate', data.FLD_DUEDATE)
                                        validation.setFieldValue('status', data.FLD_STATUS == 'Active' ? 'Active' : 'Archived') 
                                        setTypeVal({label : data.FLD_TYPE, value : data.FLD_TYPE})
                                        setComplaintVal('')
                                        setModuleValDT(moduleOptions.filter(val => val.value === data.FLD_MODULEID)[0])



                                        setIsRight(true)
                                    }}
                                    className="btn btn-sm btn-soft-info"
                                   
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const eventData = cellProps.row.original;
                                        onClickDelete(eventData);
                                    }}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        [moduleOptions]
    );

      // Reset filters
  const handleReset = () => {
    tableRef.current.resetTable(); 
    // console.log(tableRef.current)
  };

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const handleAddNewModule = () =>{
    setIsEdit(false)
    setIsEditable(true)
    setIsView(false)

    validation.setFieldValue('title', '')
    validation.setFieldValue('type', '')
    validation.setFieldValue('complaintid', '')
    validation.setFieldValue('moduleid', '')
    validation.setFieldValue('duedate', '')
    validation.setFieldValue('status', 'Active') 
    setTypeVal('')
    setComplaintVal('')
    setModuleValDT('')

    setIsRight(!isRight);
  }


const handleKeyDown = (e) => {
    // console.log(e.key)
    if (e.key.match(/[a-z]/i) && e.key!= 'Backspace') {
      e.preventDefault();
    }
  };


  const handleDeleteModule = () => {
    if (event && event.FLD_EVENTID) {
        dispatch(onDeleteEventDT(event.FLD_EVENTID));
        setDeleteModal(false);
    }
};

const handleApproveModule = () => {
    // console.log(companyDetails)
    if (event && event.FLD_EVENTID) {
        dispatch(onApproveEventDT(event.FLD_EVENTID));
        setApproveModal(false);
    }
  };
  

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                deleteMsg={'Are you sure you want to archive event.'}
                onDeleteClick={handleDeleteModule}
                onCloseClick={() => setDeleteModal(false)}
            />

                <ApproveModal
                show={approveModal}
                approveMsg={'Are you sure you want to approve event.'}
                onApproveClick={handleApproveModule}
                onCloseClick={() => setApproveModal(false)}
            />

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="MDR Data" breadcrumbItem="Event List" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">List Of Events</h5>
          
                                                    <div className="flex-shrink-0">

                                                        
                                            <Link className="btn btn-primary me-3" 
                                            onClick={handleAddNewModule}
                                            // to={{pathname : '/decision-tree-config'}}
                                            ><i className="mdi mdi-file-tree"

                                            ></i> Add New Event </Link>
                                            </div>
                                        <div className="flex-shrink-0">

             
                                            <Link className="btn btn-light me-3" onClick={handleReset}><i className="mdi mdi-refresh"
                                            
                                            ></i> Reset Filters </Link>
                                            {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                <DropdownToggle type="menu" className="btn btn-light" id="dropdownMenuButton1">
                                                    <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                                <DropdownMenu>
                                                    <li><DropdownItem href="#">Action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                    <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                </DropdownMenu>
                                            </UncontrolledDropdown> */}
                                        </div>

                                      
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <TableContainerEvent
                                        ref={tableRef}
                                        columns={columns}
                                        data={eventsDT}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        // isAddModule={true}
                                        // handleCompanyClicks={handleCompanyClicks}
                                        // resetFilter={resetFilter}
                                        isCompanyListGlobalFilter={true}
                                        customPageSize={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    
                    <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}>
            <OffcanvasHeader toggle={toggleRightCanvas}>
            {isEdit ? 'Alter Event' : isView ? 'View Event' : 'Add New Event'}  
            </OffcanvasHeader>
            <OffcanvasBody style={{marginBottom : 20}}>
              <div className="w-100 h-80vh" >
                <Card className="h-96">
                  <div className="p-4 border-bottom ">
                                <Row>

{/* {console.log(error)} */}
                                {error ? <Alert color="danger">{error}</Alert> : null}
                                    <Col className="col-12">
                                       
                                           
                                        <div className="mb-3">
                                        <Row>
                                        <Col lg="10">
                                            <Label className="form-label"> Event Title</Label>
                                            </Col>
                                            <Col lg="2" style={{marginTop : -10, display : isEditable ? 'none' : ''}}>
                                   
                                   <Link
                                       onClick={()=>{
                                           
                                           setIsEditable(true)
                                         
                                       }}
                                       className="btn btn-sm btn-soft-info"
                                      
                                   >
                                       <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                       <UncontrolledTooltip placement="top" target="edittooltip">
                                           Edit
                                       </UncontrolledTooltip>
                                   </Link>
                            
                                           </Col>
                                            </Row>
                                            <Input
                                                disabled={!isEditable}
                                                name="title"
                                                type="text"
                                                placeholder="Enter Event Title"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.title || ""}
                                                invalid={
                                                    validation.touched.title && validation.errors.title ? true : false
                                                }
                                            />
                                            {validation.touched.title && validation.errors.title ? (
                                                <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                            ) : null}
                                        </div>
                                      
                                     
                                     
                                       
                                        <div className="mb-3">
                                            <Label className="form-label required">Event Type</Label>
                                            <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'type'}
                                  isDisabled={!isEditable}
                                  isClearable
                                  isSearchable
                                  options={typeOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setTypeVal(e)
                                    validation.setFieldValue('type', e.value)
                                    validation.setFieldError('type',false)
                                    }else{
                                        setTypeVal('')
                                      validation.setFieldValue('type', '')
                                      validation.setFieldError('type',true)
                                    }
                                }}
                                  
                                  onBlur={validation.handleBlur}
                                  value={
                                    typeVal || ""
                                }
                                invalid={
                                  validation.touched.type && validation.errors.type
                                        ? true
                                        : false
                                }
                                className={validation.touched.type && validation.errors.type ? 'invalid-select' : ''}

                                />
                                  {validation.touched.type && validation.errors.type ? (
                                    <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                  ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label">Related Complaint</Label>
                                            <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'complaintid'}
                                  isDisabled={!isEditable}
                                  isClearable
                                  isSearchable
                                  options={complaintOptions}
                                  onChange={(e)=>{
                                    if(e){
                                    setComplaintVal(e)
                                    validation.setFieldValue('complaintid', e.value)
                                    validation.setFieldError('complaintid',false)
                                    }else{
                                        setComplaintVal('')
                                      validation.setFieldValue('complaintid', '')
                                      validation.setFieldError('complaintid',true)
                                    }
                                }}
                                  
                                  onBlur={validation.handleBlur}
                                  value={
                                    complaintVal || ""
                                }
                                invalid={
                                  validation.touched.complaintid && validation.errors.complaintid
                                        ? true
                                        : false
                                }
                                className={validation.touched.complaintid && validation.errors.complaintid ? 'invalid-select' : ''}

                                />
                                  {validation.touched.complaintid && validation.errors.complaintid ? (
                                    <FormFeedback type="invalid">{validation.errors.complaintid}</FormFeedback>
                                  ) : null}
                                        </div>


                                        <div className="mb-3">
                                         
                                            <Label className="form-label required">Module Name (Decision Tree)</Label>
                                            <Select
                                  // value={count}
                                  // onChange={this.handleChange}
                                  name={'moduleid'}
                                  isDisabled={!isEditable}
                                  isClearable
                                  isSearchable
                                  options={moduleOptions}
                                  onChange={(e)=>{
                                    if(e){
                                        // console.log(e)
                                        // console.log(moduleValDT)
                                    
                                        setModuleValDT(e)
                                    validation.setFieldValue('moduleid', e.value)
                                    validation.setFieldError('moduleid',false)
                                    }else{
                                        setModuleValDT('')
                                      validation.setFieldValue('moduleid', '')
                                      validation.setFieldError('moduleid',true)
                                    }
                                }}
                                  
                                  onBlur={validation.handleBlur}
                                //   value={
                                //     event && activeModulesData.filter(val => val.value == event.FLD_MODULEID)[0] || ""
                                // }
                                value={moduleValDT || ''}
                                invalid={
                                  validation.touched.moduleid && validation.errors.moduleid
                                        ? true
                                        : false
                                }
                                className={validation.touched.moduleid && validation.errors.moduleid ? 'invalid-select' : ''}

                                />
                                  {validation.touched.moduleid && validation.errors.moduleid ? (
                                    <FormFeedback type="invalid">{validation.errors.moduleid}</FormFeedback>
                                  ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label className="form-label">Due Date</Label>
                             <Flatpickr
                                    // value={Date.parse(pick_date)}
                                    disabled={!isEditable}
                                    className="form-control d-block"
                                    placeholder="Pick a date"
                                    options={{
                                        altInput: true,
                                        dateFormat: "d-m-y"
                                    }}
                                    //   onChange={(e) => updateValue(groupId, rule.id, moment(new Date(e)).format('MM/DD/YYYY'))
                                    //   onChange={validation.handleChange}
                                    onChange={(e)=>{
                                        if(e){
                                       
                                        validation.setFieldValue('duedate', moment(new Date(e)).format('MM/DD/YYYY'))
                                        validation.setFieldError('duedate',false)
                                        }else{
                                        
                                          validation.setFieldValue('duedate', '')
                                          validation.setFieldError('duedate',true)
                                        }
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={Date.parse(validation.values.duedate) || ""}
                                    // invalid={
                                    //     validation.touched.duedate && validation.errors.duedate ? true : false
                                    // }
                                />
                                {validation.touched.duedate && validation.errors.duedate ? (
                                    <FormFeedback type="invalid">{validation.errors.duedate}</FormFeedback>
                                ) : null}
              
             
                                            {/* <Input
                                            disabled={!isEditable}
                                                name="key"
                                                type="text"
                                                placeholder="Enter Key"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.key || ""}
                                                invalid={
                                                    validation.touched.key && validation.errors.key ? true : false
                                                }
                                            />
                                            {validation.touched.key && validation.errors.key ? (
                                                <FormFeedback type="invalid">{validation.errors.key}</FormFeedback>
                                            ) : null} */}
                                        </div>


                                        <div className="mb-3">
                                            <Label className="form-label required">Is Active</Label>
                                            <div className="square-switch"    style={{marginTop : 10}}>

                            <input
                                // disabled
                               type="checkbox"
                               id="square-switch1"
                               name="status"
                               className="switch switch-bool"
                                                
                               onKeyDown={handleKeyDown}
                               onChange={()=>{
                                // console.log('clicked')
                                 if(validation.values.status == 'Active'){
                                    validation.setFieldValue('status', 'Archived')
                                 }else{
                                    validation.setFieldValue('status', 'Active')
             
                                 }
                               }}
                               onBlur={validation.handleBlur}
                              //  value={validationCorporate.values.isadmin || ""}
                              checked={validation.values.status == 'Archived' ? false : true}
                            />
                            
                            <label
                                disabled={!isEditable}
                              htmlFor="square-switch1"
                              data-on-label="Yes"
                              data-off-label="No"
                              onClick={()=>{
                                // console.log('clicked')
                                 if(validation.values.status == 'Active'){
                                    validation.setFieldValue('status', 'Archived')
                                 }else{
                                    validation.setFieldValue('status', 'Active')
             
                                 }
                               }}
                            />
                          </div>
                                        </div>
                                      
                                    
                                    </Col>
                                </Row>
                                <Row style={{justifyContent : 'end'}}> 
                                <Col lg="6" 
                                style={{display : (event != null && event != '' && event.FLD_APPROVAL_STATUS == 'Pending') ? '' : 'none'}}
                                >
                                        <div className="text-end">
                                            <button
                                            disabled={!isEditable}
                                                type="submit"
                                                className="btn btn-outline-primary save-user"
                                                onClick={() => {
                                                    setEvent(event);
                                                    setApproveModal(true);
                                                  }}
                                            >
                                                Approve Event
                                            </button>
                                        </div>
                                    </Col>
                                    <Col lg="5">
                                        <div className="text-end">
                                            <button
                                            disabled={!isEditable}
                                                type="submit"
                                                className="btn btn-primary save-user"
                                                onClick={() => {
                                                    
                                                    validation.handleSubmit();
                                                  }}
                                            >
                                                Save Event
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                        </div>
                        </Card>
                    </div>
                    </OffcanvasBody>
                    </Offcanvas>
                    </div>
             
                
            </div>

        <Loader isLoading={isLoading} /> 
        </React.Fragment>
    );
}


export default EventsList;
import React, {useState, useRef} from 'react';
import { FormControl, InputGroup, Tab, Nav } from 'react-bootstrap';
// import '@fortawesome/fontawesome-free'; // Include Font Awesome CSS
import Form from 'react-bootstrap/Form';
import Draggable from '../../../../assets/images/drag.png';
import {
  Button,
   ButtonGroup,
   CardTitle, 
   FormFeedback
  //  Accordion, 
  //  Form

} from "reactstrap";
import { useDrag, useDrop } from "react-dnd";

function TabComponentPreview2(props) {
  const addInputField = (event) => {
    // Your addInputField function logic here
  };

  const showPreviousQuestion = () => {
    // Your showPreviousQuestion function logic here
  };

  const showNextQuestion = () => {
    // Your showNextQuestion function logic here
  };

  const renderPreviewTabs = () =>{
    // console.log(props.qData)
    switch(props.qData.type)
    {
      case 'heading' :
      return (
        <div className="heading-model" style={{alignSelf : 'center'}}>
                         {props.qData.questionText}
                        </div> 
      )

      case 'paragraph' :
        return (
          <div className="paragraph-model" style={{alignSelf : 'center'}}>
                           {props.qData.questionText}
                          </div> 
        )

        case 'hyperlink' :
          return (
            <div className="hyperlink-model" style={{alignSelf : 'start'}}>
                             {props.qData.questionText}
                            </div> 
          )
          case 'attachment' :
            return (
              <div style={{alignSelf : 'start'}} className='question flexHead0043'>
                <h5>Additional Attachments</h5>
              <div  className="attachment-model" style={{textAlign : 'left'}}>
                Read Term & Condition - Part A
                
                           
                              </div> 
                              </div>
            )
      
    }

    

  }


  return (
   
    <div className="openQuestion-main-section draggable" draggable="true" >
               
              
                      <div className="input-wrapper">
                  {renderPreviewTabs()}
                  </div>
               
                  </div>

  );
}

export default TabComponentPreview2;

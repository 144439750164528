import CardWithWatermark from "components/Common/Watermark"
import React from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"

const TopDevice = (props) => {

  // console.log(props.topDevice.data)

  return (
    <React.Fragment>
      <Card 
      // className="fixed-height-match2"
      >
        <CardBody>
          <CardTitle className="mb-4">Top Device Codes</CardTitle>
          {/* <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-map-pin text-primary display-4" />
            </div>
            <h3>1,456</h3>
            <p>San Francisco</p>
          </div> */}

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
                {props.topDevice.data && props.topDevice.data.map((device,index)=>(
                   <tr key={index}>
                   <td style={{ width: "30%" }}>
                     <p className="mb-0">{device.DESCRIPTION}</p>
                   </td>
                   <td style={{ width: "25%" }}>
                     <h5 className="mb-0">{device.REPORTS.toLocaleString()}</h5>
                   </td>
                   {/* <td>
                     <Progress
                       value="94"
                       color="primary"
                       className="bg-transparent progress-sm"
                       size="sm"
                     />
                   </td> */}
                 </tr>
                ))}
                {/* <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">Pain</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">1,456</h5>
                  </td>
                  <td>
                    <Progress
                      value="94"
                      color="primary"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Preforation</p>
                  </td>
                  <td>
                    <h5 className="mb-0">1,123</h5>
                  </td>
                  <td>
                    <Progress
                      value="82"
                      color="primary"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Implant</p>
                  </td>
                  <td>
                    <h5 className="mb-0">1,026</h5>
                  </td>
                  <td>
                    <Progress
                      value="70"
                      color="primary"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Contracture</p>
                  </td>
                  <td>
                    <h5 className="mb-0">1,026</h5>
                  </td>
                  <td>
                    <Progress
                      value="70"
                      color="primary"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Infection</p>
                  </td>
                  <td>
                    <h5 className="mb-0">1,026</h5>
                  </td>
                  <td>
                    <Progress
                      value="70"
                      color="primary"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">Information</p>
                  </td>
                  <td>
                    <h5 className="mb-0">1,026</h5>
                  </td>
                  <td>
                    <Progress
                      value="70"
                      color="primary"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>

          <CardWithWatermark/>

        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopDevice

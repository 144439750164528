//REGISTER
export const POST_REGISTER = "/post-fake-register";

//LOGIN
export const POST_LOGIN = "/post-fake-login";
export const POST_JWT_LOGIN = "/post-jwt-login";
export const POST_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";


// COMPANYS
export const GET_COMPANY_LIST = "/get_all_company_list";
export const GET_USERS_LIST = "/get_all_user_list"
export const ADD_NEW_COMPANY = "/add_new_company";
export const UPDATE_COMPANY = "/update_company";
export const DELETE_COMPANY= "/delete_company";
export const GET_PHONECODE_LIST= "/get_phonecode";
export const GET_COUNTRY_LIST= "/get_country";
export const GET_STATE_LIST= "/get_state";
export const GET_CITY_LIST= "/get_city";
export const CHECK_USER_EMAIL_EXISTS = "/check_user_email_exists"
export const VALIDATE_LOGIN_EMAIL = "/validate_login_email"
export const UPDATE_USER_SIGNUP_STATUS = "/update_user_signup_status"
export const GET_USER_DETAILS = "/get_user_details"
export const GET_COMPANY_CONTACT_PERSON = '/get_company_contact_person'
export const APPROVE_COMPANY = '/approve_company'

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//Mails
export const GET_MAILS_LIST = "/mailslists";
export const SELECT_FOLDER = "/folders";
export const GET_SELECTED_MAILS = "/selectedmails";
export const SET_FOLDER_SELECTED_MAILS = "/setfolderonmail";
export const UPDATE_MAIL = "/update/mail";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";
export const ADD_NEW_CUSTOMER = "/add/customer";
export const UPDATE_CUSTOMER = "/update/customer";
export const DELETE_CUSTOMER = "/delete/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";
export const GET_CRYPTO_PRODUCTS = "/crypto-products";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

// JOBS
export const GET_JOB_LIST = "/jobs";
export const ADD_NEW_JOB_LIST = "/add/job";
export const UPDATE_JOB_LIST = "/update/job";
export const DELETE_JOB_LIST = "/delete/job";

//Apply Jobs
export const GET_APPLY_JOB = "/jobApply";
export const DELETE_APPLY_JOB = "add/applyjob";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//TASKS
export const GET_TASKS = "/tasks";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/get_user_profile";
export const UPDATE_USER_PROFILE = "/update_user_profile"

//Blog
export const GET_VISITOR_DATA = "/visitor-data";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

export const TOP_SELLING_DATA = "/top-selling-data";

//dashboard crypto
export const GET_WALLET_DATA = "/wallet-balance-data";

//dashboard jobs
export const GET_STATISTICS_DATA = "/Statistics-data";

export const GET_EARNING_DATA = "/earning-charts-data";

export const GET_PRODUCT_COMMENTS = "/comments-product";

export const ON_LIKNE_COMMENT = "/comments-product-action";

export const ON_ADD_REPLY = "/comments-product-add-reply";

export const ON_ADD_COMMENT = "/comments-product-add-comment";

export const GET_DEPARTMENT_LIST ="/get_department"

export const GET_DESIGNATION_LIST = "/get_designation"

export const GET_CORPORATE_DD_LIST = "/get_all_company_dropdown"

export const GET_RECENT_QUERY_LIST = "/get_recent_query_list"

export const GET_SAVED_QUERY_LIST = "/get_saved_query_list"

export const GET_SEARCH_FIELD_LIST = "/get_searchable_list"

export const GET_MEDICAL_SPL_LIST = "/get_medical_specialty_list"

export const GET_DEVICE_PROB_CODE_LIST = "/get_device_problem_code_list"

export const GET_PATIENT_PROB_CODE_LIST = "/get_patient_problem_code_list"

export const SAVE_SMART_QUERY_API = "/save_smart_query";

export const EXECUTE_SMART_QUERY_API = "/execute_recent_query";

export const GET_USER_COUNT_DASHBOARD = "/get_user_count_admin_dashboard"

export const GET_MDR_SUBMIT_DASHBOARD = "/get_mdr_month_list"

export const GET_MDR_STATS_DASHBOARD = "/get_mdrstats_month"

export const GET_MDR_STATS_DASHBOARD_YEAR = "/get_mdrstats_year"

export const GET_ACTIVE_COMPANY_LIST = "/get_active_company_list"

export const GET_ACTIVE_USER_ORG = "/get_active_user_org"

export const ADD_NEW_USER = "/add_new_user"

export const DELETE_USER = "/delete_user";

export const APPROVE_USER = '/approve_user'

export const UPDATE_USER = "/update_user"

export const SEND_USER_INTIVE = '/welcome_mailer'

export const  GET_MDR_INITIAL_LIST = '/get_all_mdr'

export const GET_MDR_TOP_PATIENTS = '/get_top_patient_code'

export const GET_MDR_TOP_DEVICES = '/get_top_device_code'

export const GET_MDR_YEAR_DATA_STATS = '/get_mdr_year_chart_public'

export const GET_MDR_MONTH_DATA_STATS = '/get_mdr_count_public'

export const GET_MDR_EVENT_COUNT_STATS = '/get_event_type_counts'

export const GET_MDR_YEARLY_COUNT_STATS = "/get_year_wise_mdrs"

export const GET_MDR_MEDICAL_SPEC_COUNT_STATS = '/get_medical_spec_count'

export const GET_MDR_MONTHLY_EVENT_COUNT_STATS = '/get_month_wise_event_type_mdr'

export const  GET_MONTHLY_CHART_SMART = "/get_monthly_chart_smart_data"

export const  GET_FIELD_CHART_SMART = "/get_field_chart_smart_data"

export const GLOABL_SEARCH_RESULT_API = "/global_search_query"

export const GET_ALL_MODULE_LIST = "/get_all_module_list"

export const ADD_NEW_MODULE_DT = "/add_new_module_dt"

export const DELETE_MODULE = "/delete_module"

export const UPDATE_MODULE = "/update_module_dt"

export const APPROVE_MODULE = "/approve_module"

export const ADD_TAB_DATA_DT = "/add_tabdata_dt"

export const ADD_QUESTION_DATA_DT = "/add_question_dt"

export const ADD_QUESTION_ITEM_DATA_DT = "/add_question_item_dt"

export const ADD_SAVE_DRAFT_DATA_DT = "/add_tempdata_dt"

export const GET_DRAFT_DATA_DT = "/get_all_tempdata_dt"

export const GET_ACTIVE_MODULE_LIST = "/get_active_module_list"

export const GET_EVENT_LIST = "/get_all_event_list"

export const ADD_NEW_EVENT_DATA = "/add_new_event_dt"

export const APPROVE_EVENT_DT = "/approve_event"

export const DELETE_EVENT_DT = "/delete_event"

export const UPDATE_EVENT_DT = "/update_event_dt"

export const GET_TABDATA_EVENT = "/get_tabdata_event"

export const GET_QUESTIONDATA_EVENT = "/get_questiondata_event"

export const GET_QUESTIONITEM_EVENT = "/get_question_itemdata_event"

export const SAVE_TAB_ANSITEM_DATA = "/add_answeritem_dt"

export const SAVE_TAB_ANS_DATA = "/add_answerdata_dt"

export const REMOVE_TAB_ANS_DATA = "/remove_answerdata_dt"

export const ADD_MODULE_SCORE_API = "/add_new_module_score_dt"

export const REMOVE_MODULE_SCORE_API = "/remove_module_score_toadd"

export const GET_ALL_MODULE_SCORE_LIST = "/get_all_module_score_list"

export const DELETE_MODULE_SCORE = "/delete_module_score"

export const APPROVE_MODULE_SCORE = "/approve_module_score"

export const GET_ANSWER_EVENT = "/get_answerdata_event"

export const GET_ANSWER_ITEM_EVENT = "/get_answeritem_event"

export const GET_MODULE_SCORE_BYID = "/get_modulescore_byid"

export const GET_GROUP_PERMISSION = "/get_user_group_permission"

export const UPDATE_GROUP_PERMISSION = "/update_group_permission"



/* modules */
export const GET_MODULES = "GET_MODULES"
export const GET_MODULES_SUCCESS = "GET_MODULES_SUCCESS"
export const GET_MODULES_FAIL = "GET_MODULES_FAIL"


/**
 * add module
 */
export const ADD_NEW_MODULE = "ADD_NEW_MODULE"
export const ADD_MODULE_SUCCESS = "ADD_MODULE_SUCCESS"
export const ADD_MODULE_FAIL = "ADD_MODULE_FAIL"

/**
 * Edit module
 */
export const UPDATE_MODULE = "UPDATE_MODULE"
export const UPDATE_MODULE_SUCCESS = "UPDATE_MODULE_SUCCESS"
export const UPDATE_MODULE_FAIL = "UPDATE_MODULE_FAIL"

/**
 * Delete module
 */
export const DELETE_MODULE = "DELETE_MODULE"
export const DELETE_MODULE_SUCCESS = "DELETE_MODULE_SUCCESS"
export const DELETE_MODULE_FAIL = "DELETE_MODULE_FAIL"


export const APPROVE_MODULE = "APPROVE_MODULE"
export const APPROVE_MODULE_SUCCESS = "APPROVE_MODULE_SUCCESS"
export const APPROVE_MODULE_FAIL = "APPROVE_MODULE_FAIL"


export const DEACTIVATE_MODULE = "DEACTIVATE_MODULE"
export const DEACTIVATE_MODULE_SUCCESS = "DEACTIVATE_MODULE_SUCCESS"
export const DEACTIVATE_MODULE_FAIL = "DEACTIVATE_MODULE_FAIL"

export const SAVE_TAB_DATA_DT = "SAVE_TAB_DATA_DT"
export const SAVE_TAB_DATA_DT_SUCCESS = "SAVE_TAB_DATA_DT_SUCCESS"
export const SAVE_TAB_DATA_DT_FAIL = "SAVE_TAB_DATA_DT_FAIL"

export const SAVE_DRAFT_TAB_DATA_DT = "SAVE_DRAFT_TAB_DATA_DT"
export const SAVE_DRAFT_TAB_DATA_DT_SUCCESS  = "SAVE_DRAFT_TAB_DATA_DT_SUCCESS"
export const SAVE_DRAFT_TAB_DATA_DT_FAIL = "SAVE_DRAFT_TAB_DATA_DT_FAIL"

export const GET_DRAFT_TAB_DATA_DT = "GET_DRAFT_TAB_DATA_DT"
export const GET_DRAFT_TAB_DATA_DT_SUCCESS = "GET_DRAFT_TAB_DATA_DT_SUCCESS"
export const GET_DRAFT_TAB_DATA_DT_FAIL = "GET_DRAFT_TAB_DATA_DT_FAIL"

export const GET_ACTIVE_MODULES = "GET_ACTIVE_MODULES"
export const GET_ACTIVE_MODULES_SUCCESS = "GET_ACTIVE_MODULES_SUCCESS"
export const GET_ACTIVE_MODULES_FAIL = "GET_ACTIVE_MODULES_FAIL"

export const GET_EVENTS_DT = "GET_EVENTS_DT"
export const GET_EVENTS_DT_SUCCESS = "GET_EVENTS_DT_SUCCESS"
export const GET_EVENTS_DT_FAIL = "GET_EVENTS_DT_FAIL"

export const ADD_NEW_EVENT_DT = "ADD_NEW_EVENT_DT"
export const ADD_EVENT_DT_SUCCESS = "ADD_EVENT_DT_SUCCESS"
export const ADD_EVENT_DT_FAIL = "ADD_EVENT_DT_FAIL"

export const DELETE_EVENT_DT = "DELETE_EVENT_DT"
export const DELETE_EVENT_DT_SUCCESS = "DELETE_EVENT_DT_SUCCESS"
export const DELETE_EVENT_DT_FAIL = "DELETE_EVENT_DT_FAIL"

export const APPROVE_EVENT_DT = "APPROVE_EVENT_DT"
export const APPROVE_EVENT_DT_SUCCESS = "APPROVE_EVENT_DT_SUCCESS"
export const APPROVE_EVENT_DT_FAIL = "APPROVE_EVENT_DT_FAIL"

export const UPDATE_EVENT_DT = "UPDATE_EVENT_DT"
export const UPDATE_EVENT_DT_SUCCESS = "UPDATE_EVENT_DT_SUCCESS"
export const UPDATE_EVENT_DT_FAIL = "UPDATE_EVENT_DT_FAIL"

export const GET_TABDATA_EVENT = "GET_TABDATA_EVENT"
export const GET_TABDATA_EVENT_SUCCESS = "GET_TABDATA_EVENT_SUCCESS"
export const GET_TABDATA_EVENT_FAIL = "GET_TABDATA_EVENT_FAIL"

export const SAVE_TAB_ANSWER_DATA_DT = "SAVE_TAB_ANSWER_DATA_DT"
export const SAVE_TAB_ANSWER_DATA_DT_SUCCESS = "SAVE_TAB_ANSWER_DATA_DT_SUCCESS"
export const SAVE_TAB_ANSWER_DATA_DT_FAIL = "SAVE_TAB_ANSWER_DATA_DT_FAIL"

export const ADD_NEW_MODULE_SCORE = "ADD_NEW_MODULE_SCORE"
export const ADD_NEW_MODULE_SCORE_SUCCESS = "ADD_NEW_MODULE_SCORE_SUCCESS"
export const ADD_NEW_MODULE_SCORE_FAIL= "ADD_NEW_MODULE_SCORE_FAIL"

export const GET_MODULE_SCORES = "GET_MODULE_SCORES"
export const GET_MODULE_SCORES_SUCCESS = "GET_MODULE_SCORES_SUCCESS"
export const GET_MODULE_SCORES_FAIL = "GET_MODULE_SCORES_FAIL"

export const DELETE_MODULE_SCORE= "DELETE_MODULE_SCORE"
export const DELETE_MODULE_SCORE_SUCCESS= "DELETE_MODULE_SCORE_SUCCESS"
export const DELETE_MODULE_SCORE_FAIL= "DELETE_MODULE_SCORE_FAIL"

export const APPROVE_MODULE_SCORE = "APPROVE_MODULE_SCORE"
export const APPROVE_MODULE_SCORE_SUCCESS = "APPROVE_MODULE_SCORE_SUCCESS"
export const APPROVE_MODULE_SCORE_FAIL = "APPROVE_MODULE_SCORE_FAIL"

export const GET_MODULE_SCORE_BYID = "GET_MODULE_SCORE_BYID"
export const GET_MODULE_SCORE_BYID_SUCCESS = "GET_MODULE_SCORE_BYID_SUCCESS"
export const GET_MODULE_SCORE_BYID_FAIL = "GET_MODULE_SCORE_BYID_FAIL"


export const SET_LOADING_INDICATOR_DT = "SET_LOADING_INDICATOR_DT"

import React, { useEffect, useMemo, useState } from "react";
import { Link ,useNavigate} from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainerQueryResult from '../../../../components/Common/TableContainerQueryResult';
import * as Yup from "yup";
import { useFormik } from "formik";
import { jobs } from "../../../../common/data";

//import components
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import DeleteModal from '../../../../components/Common/DeleteModal';

import {
    abortSmartQuery,
    executeSmartQuery as onExecuteSmartQuery, resetQueryResult, setLoadingIndicatorSmartQuery
  } from "store/actions";

import {
    ColName
}
    from "./queryResultColList";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Progress
} from "reactstrap";
import Hourglass from "components/Common/Hourglass";
import AbortModal from "components/Common/AbortModal";
import GenerateChartsOffCanvas from "./generate-charts-offcanvas";

function QueryManager() {

    //meta title
    document.title = "Query Result | Smarteeva";

    const navigate = useNavigate()

    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [queryResultList, setQueryResult] = useState([]);
    const [query, setQuery] = useState(null);
    const [queryDetails, setQueryDetails] = useState({});
    const [abortModal, setAbortModal] = useState(false);
    const [toggleCanvas, setToggleCanvas] = useState(false);


    // validation
    // const validation = useFormik({
    //     // enableReinitialize : use this flag when initial values needs to be changed
    //     enableReinitialize: true,

    //     initialValues: {
    //         jobId: (job && job.jobId) || '',
    //         jobTitle: (job && job.jobTitle) || '',
    //         companyName: (job && job.companyName) || '',
    //         location: (job && job.location) || '',
    //         experience: (job && job.experience) || '',
    //         position: (job && job.position) || '',
    //         type: (job && job.type) || '',
    //         status: (job && job.status) || '',

    //     },
    //     validationSchema: Yup.object({
    //         jobId: Yup.string().matches(
    //             /[0-9\.\-\s+\/()]+/,
    //             "Please Enter Valid Job Id"
    //         ).required("Please Enter Your Job Id"),
    //         jobTitle: Yup.string().required("Please Enter Your Job Title"),
    //         companyName: Yup.string().required("Please Enter Your Company Name"),
    //         location: Yup.string().required("Please Enter Your Location"),
    //         experience: Yup.string().required("Please Enter Your Experience"),
    //         position: Yup.string().required("Please Enter Your Position"),
    //         type: Yup.string().required("Please Enter Your Type"),
    //         status: Yup.string().required("Please Enter Your Status"),
    //     }),
    //     onSubmit: (values) => {
    //         if (isEdit) {
    //             const updateJobList = {
    //                 id: job ? job.id : 0,
    //                 jobId: values.jobId,
    //                 jobTitle: values.jobTitle,
    //                 companyName: values.companyName,
    //                 location: values.location,
    //                 experience: values.experience,
    //                 position: values.position,
    //                 type: values.type,
    //                 postedDate: "02 June 2021",
    //                 lastDate: "25 June 2021",
    //                 status: values.status,
    //             };
    //             // update Job
    //             dispatch(onUpdateJobList(updateJobList));
    //             validation.resetForm();
    //         } else {
    //             const newJobList = {
    //                 id: Math.floor(Math.random() * (30 - 20)) + 20,
    //                 jobId: values["jobId"],
    //                 jobTitle: values["jobTitle"],
    //                 companyName: values["companyName"],
    //                 location: values["location"],
    //                 experience: values["experience"],
    //                 position: values["position"],
    //                 type: values["type"],
    //                 postedDate: "02 June 2021",
    //                 lastDate: "25 June 2021",
    //                 status: values["status"],
    //             };
    //             // save new Job
    //             dispatch(onAddNewJobList(newJobList));
    //             validation.resetForm();
    //         }
    //         toggle();
    //     },
    // });

    const dispatch = useDispatch();

    const { query_result } = useSelector(state => ({
        query_result: state.SmartQueryReducer.query_result,
      }));

      const { query_details } = useSelector(state => ({
        query_details: state.SmartQueryReducer.query_details,
      }));

      const { abortStatus } = useSelector(state => ({
        abortStatus: state.SmartQueryReducer.abortStatus,
      }));
    

    useEffect(() => {

        // if(query_result.length > 0){
        //     let prevData = JSON.parse(localStorage.getItem('QueryResultData'))
        //     if(prevData != null){
        //         let concatData = [...prevData,...query_result]
        //         localStorage.setItem('QueryResultData',JSON.stringify(concatData))
        //     }else{
        //         localStorage.setItem('QueryResultData',JSON.stringify(query_result))
        //     }
        // }

        // let qdata = JSON.parse(localStorage.getItem('QueryResultData'))
   
   
            setQueryResult(query_result);
    
        // console.log(query_result)

        // let ddata = JSON.parse(localStorage.getItem('QueryDetails'))
        // if(ddata != null){
        // //    console.log(ddata)
        //    if(ddata.total_count - query_result == 0){
        //     // console.log('data full')
        //    }else{

        //     const execute = {
        //         query_string : ddata.query_string,
        //         off_set : query_result.length
        //         }
  
        //       dispatch(onExecuteSmartQuery(execute, navigate, query));
        //     // console.log('get more data')
        //    }
        // }

        
        }, [query_result]);


        // useEffect(() => {

    
        //     // let qdata = JSON.parse(localStorage.getItem('QueryResultData'))
        //     // if(qdata != null){
        //     //     setQueryResult(qdata);
        //     // }
          
        //     // console.log(qdata)
    
        //     let ddata = JSON.parse(localStorage.getItem('QueryDetails'))
        //     if(ddata != null){
        //        console.log(queryResultList.length)
        //        if(ddata.total_count - queryResultList.length == 0){
        //         // console.log('data full')
        //        }else{
        //         const execute = {
        //             query_string : ddata.query_string,
        //             off_set : queryResultList.length
        //             }
      
        //           dispatch(onExecuteSmartQuery(execute, navigate, query, 'result'));
        //         // console.log('get more data')
        //        }
        //     }
    
            
        //     }, [dispatch]);

        

        // useEffect(() => {
        //             // dispatch(resetQueryResult());
        // }, []);

        useEffect(() => {
            // console.log(abortStatus)
            if(!abortStatus){
            let ddata = JSON.parse(localStorage.getItem('QueryDetails'));
            // console.log(ddata)

            if (ddata != null && ddata.total_count > query_result.length) {
                const execute = {
                    query_string: ddata.query_string,
                    off_set: query_result.length
                };
                dispatch(onExecuteSmartQuery(execute, navigate, query, 'result'));
            }
        
            return () => {
                // This is the cleanup logic.
                // You can leave it empty if you don't have any cleanup tasks.
                // Or you can add cleanup tasks here if needed.
            };
        }
        }, [dispatch, queryDetails, abortStatus]);


        useEffect(() => {
            let ddata = JSON.parse(localStorage.getItem('QueryDetails'))
            if(ddata != null){
                setQueryDetails(ddata);
            }else{
                if(query_details.total_count != undefined){
                    localStorage.setItem('QueryDetails',JSON.stringify(query_details))
                    setQueryDetails(query_details);
                }
            }
            
            }, [query_details]);


    // useEffect(() => {
    //     if (!isEmpty(query_result) && !!isEdit) {
    //         // setQueryResult(query_result);
    //         setIsEdit(false);
    //     }
    // }, [query_result]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setJob(null);
        } else {
            setModal(true);
        }
    };

    const handleJobClick = arg => {
        const job = arg;
        setJob({
            id: job.id,
            jobId: job.jobId,
            jobTitle: job.jobTitle,
            companyName: job.companyName,
            location: job.location,
            experience: job.experience,
            position: job.position,
            type: job.type,
            status: job.status,
        });

        setIsEdit(true);

        toggle();
    };

    //delete Job
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (job) => {
        setJob(job);
        setDeleteModal(true);
    };

    const handleDeletejob = () => {
        if (job && job.id) {
            dispatch(onDeleteJobList(job.id));
            setDeleteModal(false);
        }
    };
    const handleJobClicks = () => {
        setJobsList("");
        setIsEdit(false);
        toggle();
    };

    // MDR_REPORT_KEY
	// REPORT_NUMBER
	// EVENT_TYPE
	// PMA_PMN_NUM
	// DATE_RECEIVED
	// MANUFACTURER_D_NAME
	// MODEL_NUMBER
	// LOT_NUMBER
	// CATALOG_NUMBER
	// BRAND_NAME
	// GENERIC_NAME
	// DEVICE_REPORT_PRODUCT_CODE
	// MDR_TEXT_KEY
	// EVENT_DESCRIPTION 
	// MDR_TEXT_KEY_N 
	// MFG_NARRATIVE 
	// PATIENT_PROBLEM_CODE 
	// DEVICE_PROBLEM_CODE 
	// DEVICE_PROBLEM_DESCRIPTION 
	// MEDICAL_SPECIALTY_CODE 
	// MEDICALSPECIALTY 
	// REGULATION_NO 

    const columns = useMemo(
        () => [
            {
                Header: 'Action',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link 
                                 to={'/query_details'} state={{ state: {...cellProps.row.original} }}
                                className="btn btn-sm btn-soft-primary">
                                    <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                            </li>
                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                View
                            </UncontrolledTooltip>

                    
                        </ul>
                    );
                }
            },
            // {
            //     Header: 'MDR Report Key',
            //     accessor: 'MDR_REPORT_KEY',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Report Number',
            //     accessor: 'REPORT_NUMBER',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Event Type',
            //     accessor: 'EVENT_TYPE',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'PMA_PMN_NUM',
            //     accessor: 'PMA_PMN_NUM',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Date Received',
            //     accessor: 'DATE_RECEIVED',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Manufacturer Device Name',
            //     accessor: 'MANUFACTURER_D_NAME',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Model Number',
            //     accessor: 'MODEL_NUMBER',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Lot Number',
            //     accessor: 'LOT_NUMBER',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Catalog Number',
            //     accessor: 'CATALOG_NUMBER',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Brand Name',
            //     accessor: 'BRAND_NAME',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Generic Name',
            //     accessor: 'GENERIC_NAME',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // }, {
            //     Header: 'Device Report Product Code',
            //     accessor: 'DEVICE_REPORT_PRODUCT_CODE',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // }, {
            //     Header: 'MDR Text Key',
            //     accessor: 'MDR_TEXT_KEY',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // }, 
            // // {
            // //     Header: 'Event Description',
            // //     accessor: 'EVENT_DESCRIPTION',
            // //     filterable: true,
            // //     Cell: (cellProps) => {
            // //         return <ColName {...cellProps} />;
            // //     }
            // // }, 
            // {
            //     Header: 'MDR Text Key N',
            //     accessor: 'MDR_TEXT_KEY_N',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // // {
            // //     Header: 'MFG Narrative',
            // //     accessor: 'MFG_NARRATIVE',
            // //     filterable: true,
            // //     Cell: (cellProps) => {
            // //         return <ColName {...cellProps} />;
            // //     }
            // // },
            // {
            //     Header: 'Patient Problem Code',
            //     accessor: 'PATIENT_PROBLEM_CODE',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Device Problem Code',
            //     accessor: 'DEVICE_PROBLEM_CODE',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Device Problem Description',
            //     accessor: 'DEVICE_PROBLEM_DESCRIPTION',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Medical Specialty Code',
            //     accessor: 'MEDICAL_SPECIALTY_CODE',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Medical Specialty',
            //     accessor: 'MEDICALSPECIALTY',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },
            // {
            //     Header: 'Regulation No.',
            //     accessor: 'REGULATION_NO',
            //     filterable: true,
            //     Cell: (cellProps) => {
            //         return <ColName {...cellProps} />;
            //     }
            // },


            {
                Header: 'MDR Report Key',
                accessor: 'MDR_REPORT_KEY',
                // searchable : true,
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'Report Number',
                accessor: 'REPORT_NUMBER',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'Registration No.',
                accessor: 'REGISTRATION_NO',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'Event Type',
                accessor: 'EVENT_TYPE',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'PMA_PMN_NUM',
                accessor: 'PMA_PMN_NUM',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'Date Received',
                accessor: 'DATE_RECEIVED',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'Manufacturer Device Name',
                accessor: 'MANUFACTURER_D_NAME',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'Model Number',
                accessor: 'MODEL_NUMBER',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'Lot Number',
                accessor: 'LOT_NUMBER',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'Catalog Number',
                accessor: 'CATALOG_NUMBER',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'Brand Name',
                accessor: 'BRAND_NAME',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'Generic Name',
                accessor: 'GENERIC_NAME',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              }, {
                Header: 'Device Report Product Code',
                accessor: 'DEVICE_REPORT_PRODUCT_CODE',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              // {
              //     Header: 'Event Description',
              //     accessor: 'EVENT_DESCRIPTION',
              //     filterable: true,
              //     Cell: (cellProps) => {
              //         return <ColName {...cellProps} />;
              //     }
              // }, 
              {
                Header: 'Device Name',
                accessor: 'DEVICENAME',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'Submission Type',
                accessor: 'SUBMISSION_TYPE',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'Unclassified Reason',
                accessor: 'UNCLASSIFIED_REASON',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
              {
                Header: 'Medical Specialty',
                accessor: 'MEDICAL_SPECIALTY',
                filterable: true,
                Cell: (cellProps) => {
                  return <ColName {...cellProps} />;
                }
              },
           
        ],
        []
    );



    const handleAbortQuery= () => {
        // localStorage.removeItem('QueryDetails')
        dispatch(abortSmartQuery(true));
        setAbortModal(false)
        dispatch(setLoadingIndicatorSmartQuery(true))
        setTimeout(
            () => 
            {
                dispatch(setLoadingIndicatorSmartQuery(false))
            }, 
            2000
          );
      };
      

      const toggleChartCanvas = () => {
        //  console.log(queryDetails)
          setToggleCanvas(!toggleCanvas)
      
        };
      

    return (
        <React.Fragment>

<AbortModal
                show={abortModal}
                abortMsg={'Are you sure you want to abort the query?'}
                onAbortClick={handleAbortQuery}
                onCloseClick={() => setAbortModal(false)}
            />

            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletejob}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <div className="container-fluid">
                    {/* <Breadcrumbs title="MDR" breadcrumbItem="MDR List" /> */}
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">{queryDetails.title}</h5>
                                        {/* <p>{queryDetails.description}</p> */}

                                        <div className="flex-shrink-0">
                                            <div className="btn-group">
                                                {/* <button type="button" className="btn btn-outline-primary flex-center">
                                                    <i className="mdi mdi-play font-size-20 pr-07-icon" />
                                                    <span className="pl-7-text">Run Query</span></button> */}

<button type="button" className="btn btn-outline-primary flex-center" onClick={()=>{
    navigate(-1)
}}>
                                                    <i className="mdi mdi-arrow-left-bold-box-outline font-size-20 pr-07-icon" />
                                                    <span className="pl-7-text">Back</span></button>

                                                <button type="button" className="btn btn-outline-primary flex-center" 
                                                onClick={()=>{
                                                    let ddata = JSON.parse(localStorage.getItem('QueryDetails'));
                                                    // localStorage.setItem('QueryDetailsNav',JSON.stringify(ddata))
                                                    navigate('/smart_query', {state : ddata})
                                                }}
                                                >
                                                    <i className="mdi mdi-square-edit-outline font-size-20 pr-07-icon" />
                                                    <span className="pl-7-text">Edit Query</span></button>

                                                    <button 
                                                    disabled={JSON.parse(localStorage.getItem('QueryDetails')) != null ? JSON.parse(localStorage.getItem('QueryDetails')).total_count == query_result.length ? false : false : false}
                                                    type="button" className="btn btn-outline-primary flex-center" 
                                                    onClick={()=>{
                                                        toggleChartCanvas()
                                                        // navigate('/generate-charts', {state : queryResultList})
                                                      }}
                                                    >
                                    <i className="mdi mdi-square-edit-outline font-size-20 pr-07-icon" />
                                    <span className="pl-7-text">Generate Chart</span></button>

                                                <button type="button" className="btn btn-outline-primary flex-center">
                                                    <i className="mdi mdi-content-save font-size-20 pr-07-icon" />
                                                    <span className="pl-7-text">Save Query</span></button>

                                                <button type="button" className="btn btn-outline-danger flex-center" 
                                                onClick={()=>{
                                                    setAbortModal(true)
                                                    // localStorage.removeItem('QueryDetails')
                                                }}
                                                >
                                                    <i className="mdi mdi-cancel font-size-20 pr-07-icon" />
                                                    <span className="pl-7-text">Abort</span></button>
                                            </div>
                                        </div>

                                        
                                    </div>
                                    <p>{queryDetails.description}</p>

                                   {queryResultList.length < queryDetails.total_count && !abortStatus ? <Col lg="4" style={{float : 'right'}}>
                                    <Row>
                                   <Hourglass />
                                    <Progress color="success" 
                                    striped
                                    animated
                                    style={{margin : 'auto', paddingLeft : 0}}
                                     className="progress-lg col-lg-10"
                                    value={Math.ceil((queryResultList.length/queryDetails.total_count)*100) < 10 ? 10 : Math.ceil((queryResultList.length/queryDetails.total_count)*100)}>
                      {Math.ceil((queryResultList.length/queryDetails.total_count)*100)} %
                    </Progress>
                    <h6 style={{textAlign : 'end'}}>({queryResultList.length} records out of {queryDetails.total_count})</h6>
                    </Row>
                    </Col>
                    : null}
                                </CardBody>
                                <CardBody>
                                  {queryResultList.length > 0 ? 
                                    <TableContainerQueryResult
                                        columns={columns}
                                        data={queryResultList}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        // handleJobClicks={handleJobClicks}
                                        // isJobListGlobalFilter={true}
                                        customPageSize={10}
                                        totalCount = {queryDetails.total_count == undefined ? 100 : queryDetails.total_count}
                                    />
                                    : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {queryResultList.length > 0 || query_details != '' ?  <GenerateChartsOffCanvas isRight={toggleCanvas} toggleChartCanvas={toggleChartCanvas} mdrData={queryResultList} queryData={queryDetails}/> : null}

                    {/* <Modal isOpen={modal} toggle={toggle} className="model-width80">
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit MDR" : "Add MDR"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label className="form-label"> Job Id</Label>
                                            <Input
                                                name="jobId"
                                                type="text"
                                                placeholder="Insert Job Id"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.jobId || ""}
                                                invalid={
                                                    validation.touched.jobId && validation.errors.jobId ? true : false
                                                }
                                            />
                                            {validation.touched.jobId && validation.errors.jobId ? (
                                                <FormFeedback type="invalid">{validation.errors.jobId}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Job Title</Label>
                                            <Input
                                                name="jobTitle"
                                                type="text"
                                                placeholder="Insert Job Title"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.jobTitle || ""}
                                                invalid={
                                                    validation.touched.jobTitle && validation.errors.jobTitle ? true : false
                                                }
                                            />
                                            {validation.touched.jobTitle && validation.errors.jobTitle ? (
                                                <FormFeedback type="invalid">{validation.errors.jobTitle}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Company Name</Label>
                                            <Input
                                                name="companyName"
                                                type="text"
                                                placeholder="Insert Company Name"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.companyName || ""}
                                                invalid={
                                                    validation.touched.companyName && validation.errors.companyName ? true : false
                                                }
                                            />
                                            {validation.touched.companyName && validation.errors.companyName ? (
                                                <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Location</Label>
                                            <Input
                                                name="location"
                                                placeholder="Insert Location"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.location || ""}
                                                invalid={
                                                    validation.touched.location && validation.errors.location ? true : false
                                                }
                                            />
                                            {validation.touched.location && validation.errors.location ? (
                                                <FormFeedback type="invalid">{validation.errors.location}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Experience</Label>
                                            <Input
                                                name="experience"
                                                type="text"
                                                placeholder="Insert Experience"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.experience || ""
                                                }
                                                invalid={
                                                    validation.touched.experience && validation.errors.experience ? true : false
                                                }
                                            />
                                            {validation.touched.experience && validation.errors.experience ? (
                                                <FormFeedback type="invalid">{validation.errors.experience}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Position</Label>
                                            <Input
                                                name="position"
                                                type="text"
                                                placeholder="Insert Position"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.position || ""}
                                                invalid={
                                                    validation.touched.position && validation.errors.position ? true : false
                                                }
                                            />
                                            {validation.touched.position && validation.errors.position ? (
                                                <FormFeedback type="invalid">{validation.errors.position}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Type</Label>
                                            <Input
                                                name="type"
                                                type="select"
                                                className="form-select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.type || ""
                                                }
                                                invalid={
                                                    validation.touched.type && validation.errors.type
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <option>Full Time</option>
                                                <option>Part Time</option>
                                                <option>Freelance</option>
                                                <option>Internship</option>

                                            </Input>
                                            {validation.touched.type && validation.errors.type ? (
                                                <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                                name="status"
                                                type="select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.status || ""
                                                }
                                                invalid={
                                                    validation.touched.status && validation.errors.status ? true : false
                                                }
                                            >
                                                <option>Active</option>
                                                <option>New</option>
                                                <option>Close</option>
                                            </Input>
                                            {validation.touched.status && validation.errors.status ? (
                                                <FormFeedback status="invalid">{validation.errors.status}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <button
                                                type="submit"
                                                className="btn btn-success save-user"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal> */}
                </div>
            </div>
        </React.Fragment>
    );
}


export default QueryManager;
export const GET_RECENT_LIST = "GET_RECENT_LIST"
export const GET_RECENT_LIST_FAIL = "GET_RECENT_LIST_FAIL"
export const GET_RECENT_LIST_SUCCESS = "GET_RECENT_LIST_SUCCESS"

export const GET_SAVED_LIST = "GET_SAVED_LIST"
export const GET_SAVED_LIST_FAIL = "GET_SAVED_LIST_FAIL"
export const GET_SAVED_LIST_SUCCESS = "GET_SAVED_LIST_SUCCESS"

export const GET_SEARCH_FIELD_LIST = "GET_SEARCH_FIELD_LIST"
export const GET_SEARCH_FIELD_LIST_FAIL = "GET_SEARCH_FIELD_LIST_FAIL"
export const GET_SEARCH_FIELD_LIST_SUCCESS = "GET_SEARCH_FIELD_LIST_SUCCESS"

export const GET_MEDICAL_SPL_LIST = "GET_MEDICAL_SPL_LIST"
export const GET_MEDICAL_SPL_LIST_SUCCESS = "GET_MEDICAL_SPL_LIST_SUCCESS"
export const GET_MEDICAL_SPL_LIST_FAIL = "GET_MEDICAL_SPL_LIST_FAIL"

export const GET_DEVICE_PROB_CODE_LIST = "GET_DEVICE_PROB_CODE_LIST"
export const GET_DEVICE_PROB_CODE_LIST_SUCCESS = "GET_DEVICE_PROB_CODE_LIST_SUCCESS"
export const GET_DEVICE_PROB_CODE_LIST_FAIL = "GET_DEVICE_PROB_CODE_LIST_FAIL"

export const GET_PATIENT_PROB_CODE_LIST = "GET_PATIENT_PROB_CODE_LIST"
export const GET_PATIENT_PROB_CODE_LIST_SUCCESS = "GET_PATIENT_PROB_CODE_LIST_SUCCESS"
export const GET_PATIENT_PROB_CODE_LIST_FAIL = "GET_PATIENT_PROB_CODE_LIST_FAIL"

export const SAVE_SMART_QUERY = "SAVE_SMART_QUERY"
export const SAVE_SMART_QUERY_SUCCESS = "SAVE_SMART_QUERY_SUCCESS"
export const SAVE_SMART_QUERY_FAIL = "SAVE_SMART_QUERY_FAIL"

export const EXECUTE_SMART_QUERY = "EXECUTE_SMART_QUERY"
export const EXECUTE_SMART_QUERY_SUCCESS = "EXECUTE_SMART_QUERY_SUCCESS"
export const EXECUTE_SMART_QUERY_FAIL = "EXECUTE_SMART_QUERY_FAIL"

export const SET_SMART_QUERY_DETAILS = "SET_SMART_QUERY_DETAILS"
export const RESET_QUERY_RESULT = "RESET_QUERY_RESULT"

export const  GET_MDR_INITIAL_LIST = "GET_MDR_INITIAL_LIST"
export const GET_MDR_INITIAL_LIST_SUCCESS = "GET_MDR_INITIAL_LIST_SUCCESS"
export const GET_MDR_INITIAL_LIST_FAIL = "GET_MDR_INITIAL_LIST_FAIL"

export const SET_LOADING_INDICATOR_SMART_QUERY = "SET_LOADING_INDICATOR_SMART_QUERY"
export const GET_PATIENT_PROB_CODE_MDR_LIST = "GET_PATIENT_PROB_CODE_MDR_LIST"
export const GET_PATIENT_PROB_CODE_MDR_LIST_SUCCESS = "GET_PATIENT_PROB_CODE_MDR_LIST_SUCCESS"
export const GET_PATIENT_PROB_CODE_MDR_LIST_FAIL = "GET_PATIENT_PROB_CODE_MDR_LIST_FAIL"
export const EXECUTE_SMART_QUERY_RESULT_NO_DATA = "EXECUTE_SMART_QUERY_RESULT_NO_DATA"
export const EXECUTE_SMART_QUERY_RESULT_NO_DATA_MDRLIST = "EXECUTE_SMART_QUERY_RESULT_NO_DATA_MDRLIST"



export const EXECUTE_SMART_QUERY_MDR_LIST = "EXECUTE_SMART_QUERY_MDR_LIST"
export const EXECUTE_SMART_QUERY_MDR_LIST_SUCCESS = "EXECUTE_SMART_QUERY_MDR_LIST_SUCCESS"
export const EXECUTE_SMART_QUERY_MDR_LIST_FAIL = "EXECUTE_SMART_QUERY_MDR_LIST_FAIL"

export const SET_SMART_QUERY_MDR_LIST_DETAILS = "SET_SMART_QUERY_MDR_LIST_DETAILS"
export const RESET_QUERY_RESULT_MDR_LIST = "RESET_QUERY_RESULT_MDR_LIST"

export const ABORT_SMART_QUERY = "ABORT_SMART_QUERY"
export const ABORT_SMART_QUERY_MDR_LIST = "ABORT_SMART_QUERY_MDR_LIST"

export const GET_MONTHLY_CHART_SMART = "GET_MONTHLY_CHART_SMART"
export const GET_MONTHLY_CHART_SMART_SUCCESS = "GET_MONTHLY_CHART_SMART_SUCCESS"
export const GET_MONTHLY_CHART_SMART_FAIL = "GET_MONTHLY_CHART_SMART_FAIL"
export const GET_YEAR_CHART_SMART_SUCCESS = "GET_YEAR_CHART_SMART_SUCCESS"


export const GET_FIELD_CHART_SMART = "GET_FIELD_CHART_SMART"
export const GET_FIELD_CHART_SMART_FAIL = "GET_FIELD_CHART_SMART_FAIL"
export const GET_EVENT_FIELD_CHART_SMART_SUCCESS = "GET_EVENT_FIELD_CHART_SMART_SUCCESS"
export const GET_DEVICE_FIELD_CHART_SMART_SUCCESS = "GET_DEVICE_FIELD_CHART_SMART_SUCCESS"
export const GET_SUBMISSION_FIELD_CHART_SMART_SUCCESS = "GET_SUBMISSION_FIELD_CHART_SMART_SUCCESS"
export const GET_MANUFACTURER_FIELD_CHART_SMART_SUCCESS = "GET_MANUFACTURER_FIELD_CHART_SMART_SUCCESS"
export const GET_REASON_FIELD_CHART_SMART_SUCCESS = "GET_REASON_FIELD_CHART_SMART_SUCCESS"
export const GET_SPECIALTY_FIELD_CHART_SMART_SUCCESS = "GET_SPECIALTY_FIELD_CHART_SMART_SUCCESS"

export const GLOBAL_SEARCH_RESULT_MDR_LIST = "GLOBAL_SEARCH_RESULT_MDR_LIST"
export const GLOBAL_SEARCH_RESULT_MDR_LIST_SUCCESS = "GLOBAL_SEARCH_RESULT_MDR_LIST_SUCCESS"
export const GLOBAL_SEARCH_RESULT_MDR_LIST_FAIL = "GLOBAL_SEARCH_RESULT_MDR_LIST_FAIL"
export const SET_GLOBAL_SEARCH_RESULT_DETAILS = "SET_GLOBAL_SEARCH_RESULT_DETAILS"
export const ABORT_GLOBAL_SEARCH_RESULT = "ABORT_GLOBAL_SEARCH_RESULT"
export const RESET_GLOBAL_SEARCH = "RESET_GLOBAL_SEARCH"

export const GLOBAL_SEARCH_RESULT_NO_DATA = "GLOBAL_SEARCH_RESULT_NO_DATA"
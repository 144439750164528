import React, { Fragment, forwardRef, useImperativeHandle, useEffect } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import UserListGlobalFilter from "../../components/Common/UserGlobalSearchFilter";


// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isUserListGlobalFilter,
  resetTableFilter,
  corpFilter,
  resetCorpFilter
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  // const [corpValue, setCorpValue] = React.useState(globalCorpFilter);
  const [statusFilterValue, setStatusFilterValue] = React.useState('');
  const [approvalStatusFilterValue, setApprovalStatusFilterValue] = React.useState('');
  const [signupStatusFilterValue, setSignupStatusFilterValue] = React.useState('');



  useEffect(() => {
    if (corpFilter == 'All' || corpFilter == '') {
      // setFilteredData(preGlobalFilteredRows);
      setGlobalFilter('');
    } else {
      // console.log(corpFilter)
      const filteredRows = preGlobalFilteredRows
      .filter(row => row.original.FLD_COMPANY_NAME == corpFilter)
      .map(row => row.original);


      // console.log(filteredRows)
      // setValue(filteredRows)
      setGlobalFilter(corpFilter || undefined);
      // setGlobalFilter(filteredRows);
    }
  }, [preGlobalFilteredRows, corpFilter]);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  
  // if (globalCorpFilter === 'All') {
  //   setGlobalFilter('');
  // } else {
  //   setGlobalFilter(globalCorpFilter || undefined);
  // }

  // useImperativeHandle(globalCorpFilter, () => ({
  //   corpFilter() {
  //  console.log(globalCorpFilter)
  //   }
  // }));

 
  useImperativeHandle(resetTableFilter, () => ({
    resetTable() {
      setStatusFilterValue('');
      setGlobalFilter('');
      setApprovalStatusFilterValue('')
      setSignupStatusFilterValue('')
      resetCorpFilter()
    }
  }));



  const onHandleReset = () =>{
      setStatusFilterValue('');
      setGlobalFilter('');
      setApprovalStatusFilterValue('')
      setSignupStatusFilterValue('')
      resetCorpFilter()
  }

  // console.log(resetFilter)
  // if(resetFilter){
  //   setStatusFilterValue('');
  //   setGlobalFilter('');
  // }

  // const onStatusFilterChange = event => {
  //   // const statusFilterValue = event.target.value;
  //   // setStatusFilter(statusFilterValue);
  //   setStatusFilterValue(event.target.value)
  //   setGlobalFilter(event.target.value || undefined);
  //   console.log(event.target.value)
  // };


  const onStatusFilterChange = event => {
    const statusFilterValue = event.target.value;
    setStatusFilterValue(statusFilterValue);
    // setGlobalFilter(statusFilterValue);
    if (statusFilterValue === 'All') {
      setGlobalFilter('');
    } else {
      setGlobalFilter(statusFilterValue || undefined);
    }
  };

  const onApprovalStatusFilterChange = event => {
    const approvalStatusFilterValue = event.target.value;
    setApprovalStatusFilterValue(approvalStatusFilterValue);
    if (approvalStatusFilterValue === 'All') {
      setGlobalFilter('');
    } else {
      setGlobalFilter(approvalStatusFilterValue || undefined);
    }
  };


  const onSignupStatusFilterChange = event => {
    const signupStatusFilterValue = event.target.value;
    setSignupStatusFilterValue(signupStatusFilterValue);
    if (signupStatusFilterValue === 'All') {
      setGlobalFilter('');
    } else {
      setGlobalFilter(signupStatusFilterValue || undefined);
    }
  };

  
  return (
    <React.Fragment>
      
      <Col md={3}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>

      </Col>
      {isUserListGlobalFilter && (
        <UserListGlobalFilter onStatusChange={onStatusFilterChange}
        statusValue={statusFilterValue}
        approvalValue={approvalStatusFilterValue}
        onApprovalStatusChange={onApprovalStatusFilterChange}
        signupValue={signupStatusFilterValue}
        onSignupStatusChange={onSignupStatusFilterChange}
        handleReset={onHandleReset}
        />
      )}


    </React.Fragment>
  );
}

const TableContainerUsers = forwardRef(({
  columns,
  data,
  dataRef,
  isGlobalFilter,
  isUserListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  userType,
  corpList
}, ref) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data ,
      dataRef,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ]
      },
      userType,
      corpList,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const [selectedCorpValue, setSelectedCorpValue] = React.useState('');

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const onFilterCorpData = event =>{
    setSelectedCorpValue(event.target.value)
    // setGlobalFilter(customFilterFunction, 'FLD_COMPANYID', selectedCorpValue);

  }

  const onResetCorpFilter = () =>{
    setSelectedCorpValue('')
 
  }



  // const setResetFilter = () =>{
  //   console.log(state.statusFilterValue)
  // }

 

  return (
    <Fragment>
      <Row className="mb-2">
      <Col lg={12} className="mb-3" >
      
         
          <Col lg={4} className="mb-3" style={{float : 'right', display : userType == 'Corporate' ? '' : 'none'}}>
      <select className="form-control select2 mb-3 mb-xxl-0" 
            value={selectedCorpValue}
            defaultValue=""
            onChange={onFilterCorpData}
          >
             <option disabled value={''}>Select Coporate Name</option>
             <option key={'All'} value={'All'}>All</option>
             {corpList.map(corp => (
              <option key={corp} value={corp.label} label={corp.label}>
                {corp.label}
              </option>
            ))}
          </select>
          </Col>
        </Col>
        <Col md={customPageSizeOptions ? 2 : 2}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
       
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isUserListGlobalFilter={isUserListGlobalFilter}
            resetTableFilter={ref} 
            corpFilter={selectedCorpValue}
            resetCorpFilter={onResetCorpFilter}
          />
        )}
        {/* {isAddUserList && (
          <Col sm="6">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-account-multiple-plus me-2" />
                Add New User
              </Button>
            </div>
          </Col>
        )} */}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div className="" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {/* <Filter column={column} /> */}
                    {/* <div>{column.canFilter ? column.render("Filter") : null}</div> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
 );
});

TableContainerUsers.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};
TableContainerUsers.displayName = 'TableContainerUsers';

export default TableContainerUsers;
